// Libraries import
import { useState } from "react";

// services import
import { useAxiosPost } from "../useAxiosPost";
import { useAppDispatch } from "../../redux/store/hook";
import { setStudyInfoAction } from "../../redux/appActions";

interface Props {
  userToken: string | null;
  study_id?: string | null;
  latitude: number | null;
  longitude: number | null;
  zipcode: string | null;
}

export const useSaveLatitudeLongitude = () => {
  const dispatch = useAppDispatch();
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const saveLatitudeLongitude = async ({
    userToken,
    study_id,
    latitude,
    longitude,
    zipcode,
  }: Props) => {
    setErrorMessage("");

    const response = await axiosPostRequest(
      `${process.env.REACT_APP_EERO_URL}/oree2/latlong/${study_id}`,
      {
        latitude,
        longitude,
        zipcode,
      },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

    if (response && response.status === 200) {
      dispatch(
        setStudyInfoAction({
          studyInformation: {
            latitude,
            longitude,
            zipcode,
          },
        })
      );
    }
  };

  return {
    saveLatitudeLongitude,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
