import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";

import { IHeatLoss } from "../../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  userToken: string | null;
  study_id?: string | null;
}

export const useGetHeatLoss = () => {
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const { axiosGetRequest, isLoading } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getHeatLoss = async ({ userToken, study_id }: IProps) => {
    setErrorMessage("");

    const response = await axiosGetRequest<{ energy_losses: IHeatLoss[] }>(
      `${BACKEND_URL}/oree2/rg/heatLoss/${study_id}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      return response.energy_losses;
    }

    setErrorMessage("no data available");
    return null;
  };
  return {
    getHeatLoss,
    isLoading,
    errorMessage: errorMessage,
  };
};
