// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";

// Local interfaces declaration
export interface IForgotPasswordRequestAnswer {
  message: string;
}

// useResetPasswordSendEmail : return tools to send email to reset password
export const useResetForgotPasswordSendEmail = () => {
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // resetPasswordSendEmail : send Post request with email as payload
  const resetForgotPasswordSendEmail = async (email: string): Promise<boolean> => {
    setErrorMessage("");
    if (email) {
      const response = await axiosPostRequest<IForgotPasswordRequestAnswer>(
        `${BACKEND_URL}/user/forgotPassword`,
        {
          email,
        }
      );
      if (response && response.status === 200) {
        return true;
      }
    } else {
      setErrorMessage("Veuillez renseigner votre email.");
    }
    return false;
  };

  return {
    resetForgotPasswordSendEmail,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
