export const generatePDFId = ({
  type,
  formule_name,
  isDemoVersion = false,
}: {
  type: string;
  formule_name?: string | null;
  isDemoVersion?: boolean;
}) => {
  // Get current date
  var currentDate = new Date();

  // Extract year, month, and day components
  var year = currentDate.getFullYear().toString().slice(-2);
  var month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  var day = currentDate.getDate().toString().padStart(2, "0");

  // Concatenate the components
  var formattedDate = year + month + day;

  var random = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var numbers = "0123456789";

  for (var i = 0; i < 4; i++) {
    // Generate a random letter
    var randomLetter = characters.charAt(
      Math.floor(Math.random() * characters.length)
    );

    // Generate a random number
    var randomNumber = numbers.charAt(
      Math.floor(Math.random() * numbers.length)
    );

    // Append the letter and number to the result
    random += randomLetter + randomNumber;
  }

  let formuleName = "";

  if (formule_name === "Liberté+") {
    formuleName = "LiberteP";
  } else if (formule_name === "Liberté") {
    formuleName = "Liberte";
  } else if (formule_name === "Sérénité") {
    formuleName = "Serenite";
  } else if (formule_name === null) {
    formuleName = "Perso";
  } else {
    formuleName = formule_name ? formule_name : "";
  }

  let result =
    type.toUpperCase() +
    formattedDate +
    "-" +
    (formuleName ? formuleName + "-" : "") +
    random.toUpperCase();

  if (isDemoVersion) {
    return "SPECIMEN-" + result;
  }

  return result;
};
