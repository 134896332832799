// Services imports
import { useAppDispatch } from "../redux/store/hook";
import { setInitalStateAction } from "../redux/appActions";

// Local interface declaration
interface IFunctionReturn {
  disconnectApp: () => void;
}

/**
 * disconnectApp : disconnect user from the app by deleting/reseting all local storage and redux states.
 * If the user was authenticated before, we reload the page to force the reload of the last version of the application from the web server.
 */
export const useDisconnectApp = (): IFunctionReturn => {
  const dispatch = useAppDispatch();

  const disconnectApp = (): void => {
    const wasUserAuthenticated = !!localStorage.getItem("userAuthInfo");
    localStorage.removeItem("userAuthInfo");
    localStorage.removeItem("userProfileInfo");
    dispatch(setInitalStateAction());

    if (wasUserAuthenticated) {
      // We reload the page to force the reload of the last version of the application from the web server
      // Allow to have the last version of the application when the user reconnect
      // to avoid some bugs due to an old version already loaded in the browser
      // We do it only if the user was authenticated before, to avoid infinite reloading loop
      // as each time we load and we render the app, we check if the user is authenticated or not
      // and we call disconnectApp() him if he is not
      window.location.reload();
    }
  };
  return { disconnectApp };
};
