import "./authenticationCredentials.scss";

// Libraries import
import { useState, KeyboardEvent, ReactElement } from "react";
import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { TextInput } from "@web/shared/dist/components/Old/TextInput/TextInput";
import { PasswordInput } from "@web/shared/dist/components/Old/PasswordInput/PasswordInput";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// Service import
import { useLoginUser } from "../../../services/Login/useLoginUser";

// Interface import
import { IDataSendFromResetPasswordToLogin } from "../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  isForgotPasswordClicked: boolean;
  setIsForgotPasswordClicked: (value: boolean) => void;
}

export const AuthenticationCredentials = ({
  setIsForgotPasswordClicked,
}: Props): ReactElement => {
  // Hook calls
  const {
    requestLogin,
    isLoading: isAuthenticateUserIsLoading,
    errorMessage,
  } = useLoginUser();

  const [userName, setUserName] = useState<string>("");
  const [userPassword, setUserPassword] = useState<string>("");
  const { state } = useLocation() as {
    state: IDataSendFromResetPasswordToLogin;
  };

  // Events handlers
  const onTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const onPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserPassword(event.target.value);
  };

  // Connect when clicking on connect button. Debounce function : max 1 call every 350ms.
  const onClickConnectButton = debounce(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      requestLogin(userName, userPassword);
    },
    350,
    { leading: true }
  );

  const onClickForgotPassword = () => {
    setIsForgotPasswordClicked(true);
  };

  // Connect when pressing on enter key. Debounce function : max 1 call every 350ms.
  const handleConnectionOnPress = debounce(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.code === "Enter") {
        event.preventDefault();
        requestLogin(userName, userPassword);
      }
    },
    350,
    { leading: true }
  );

  return (
    <div className="authentication-credentials">
      {isAuthenticateUserIsLoading ? (
        <div className="loading-container">
          <LoadingComponent diameter={60} />
        </div>
      ) : (
        <form onSubmit={(event) => onClickConnectButton(event)}>
          <div className="authentication-inputs">
            <div className="login-notice">
              {state?.message && (
                <p className="register-successfull">{state.message}</p>
              )}
            </div>
            <div className="authentication-credentials__InputContainer">
              <TextInput
                value={userName}
                onChange={onTextInputChange}
                onKeyPress={handleConnectionOnPress}
                placeholder="Email *"
                autoComplete="username"
              />
            </div>
            <div className="authentication-credentials__InputContainer">
              <PasswordInput
                value={userPassword}
                onChange={onPasswordInputChange}
                onKeyPress={handleConnectionOnPress}
                placeholder="Mot de passe *"
              />
            </div>
          </div>
          <button
            className="go-to-forget-password"
            onClick={onClickForgotPassword}
            data-testid="button-forgot-password"
            type="button"
          >
            Mot de passe oublié ?
          </button>
          <div className="authentication-message">
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
          </div>
          <div className="authentication-buttons">
            <CTAButton
              name="Connexion"
              type="submit"
              isLoading={isAuthenticateUserIsLoading}
              isDisabled={isAuthenticateUserIsLoading}
            />
          </div>
        </form>
      )}
    </div>
  );
};
