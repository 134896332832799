// Styles import
import "./MesConsommationsAndEquipmentsRG.scss";

// Components import
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { Quantite } from "@web/shared/dist/components/DesignSystem/Boutons/Quantite/Quantite";
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import {
  Dropdown,
  manageDropdownSingleSelection,
} from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { TurningBlueStar } from "../../components/Animations/TurningBlueStar/TurningBlueStar";

// Images import
import Chauffage from "@web/shared/dist/assets/images/DesignSystemIllustrations/chauffage1.svg";
import ECS from "@web/shared/dist/assets/images/DesignSystemIllustrations/ECS1.svg";
import Fioul from "@web/shared/dist/assets/images/DesignSystemIllustrations/Fioul1.svg";

// Data import
import { mesConsommationsQuestionsData } from "./MesConsommationsQuestionsData";

// Interfaces import
import { IStudyInformation } from "../../interfaces/generalInterfaces";

// Local interfaces declaration
interface IProps {
  studyInformation: IStudyInformation;
  fioulConsommationEuros: string | null;
  isFioul: boolean;
  setFioulConsommationEuros: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  fioulPrincipauxEquipments: IOption[];
  setFioulPrincipauxEquipments: React.Dispatch<React.SetStateAction<IOption[]>>;
  fioulSurfaceChauffee: string | null;
  setFioulSurfaceChauffee: React.Dispatch<React.SetStateAction<string | null>>;
  fioulTemperatureConfort: number | null;
  setFioulTemperatureConfort: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  fioulTypeChauffage: IOption[];
  setFioulTypeChauffage: React.Dispatch<React.SetStateAction<IOption[]>>;
  fioulNombreEmetteur: number | null;
  setFioulNombreEmetteur: React.Dispatch<React.SetStateAction<number | null>>;
  fioulTypeEmetteur: IOption[];
  setFioulTypeEmetteur: React.Dispatch<React.SetStateAction<IOption[]>>;
  fioulStorageCapacity: IOption[];
  setFioulStorageCapacity: React.Dispatch<React.SetStateAction<IOption[]>>;
  fioulChauffageConsumptionRate: string | null;
  setFioulChauffageConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  fioulECSConsumptionRate: string | null;
  setFioulECSConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  sourceEnergie: IOption[];
  checkFioulEquipmentsDistribution: () => boolean;
  resetChauffageSection: () => void;
  resetECSSection: () => void;
}

interface IOption extends IImage {
  label: string | null;
  value: string | null;
}

interface IImage {
  imgSrc?: string;
  imgAltTxt?: string;
}

export const FioulPartForm = ({
  fioulConsommationEuros,
  isFioul,
  setFioulConsommationEuros,
  fioulPrincipauxEquipments,
  setFioulPrincipauxEquipments,
  fioulSurfaceChauffee,
  setFioulSurfaceChauffee,
  fioulTemperatureConfort,
  setFioulTemperatureConfort,
  fioulTypeChauffage,
  setFioulTypeChauffage,
  fioulNombreEmetteur,
  setFioulNombreEmetteur,
  fioulTypeEmetteur,
  setFioulTypeEmetteur,
  fioulStorageCapacity,
  setFioulStorageCapacity,
  fioulChauffageConsumptionRate,
  setFioulChauffageConsumptionRate,
  fioulECSConsumptionRate,
  setFioulECSConsumptionRate,
  sourceEnergie,
  checkFioulEquipmentsDistribution,
  resetChauffageSection,
  resetECSSection,
}: IProps) => {
  const allEquipmentsConsumption = [
    Number(fioulChauffageConsumptionRate),
    Number(fioulECSConsumptionRate),
  ];

  const inputErrorMessage = () => {
    const totalEquipmentsConsumptionPercent = allEquipmentsConsumption.reduce(
      (acc, curr) => acc + curr,
      0
    );

    return `Distribué : ${totalEquipmentsConsumptionPercent}% | Restant :
      ${100 - totalEquipmentsConsumptionPercent}%`;
  };

  return (
    <section
      className={`MesConsommationsAndEquipementsRG__SectionContent ${
        !sourceEnergie.some((item) => item.value === "fioul") && "Hidden"
      }`}
      id="caracteristiques_du_logement"
    >
      <div className="MesConsommationsAndEquipementsRG__TitleOne">
        <img src={Fioul} alt="Fioul" />
        <h3>Fioul</h3>
      </div>
      <section>
        <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
          <label className="label" htmlFor="consommationElec">
            Consommation de fioul annuelle :{" "}
          </label>
          <div style={{ maxWidth: "102px" }}>
            <TextInput
              inputLableHidden
              type="number"
              inputDescriptionHidden
              iconType="euros"
              iconHidden={false}
              placeholder="XXXX"
              value={fioulConsommationEuros ?? undefined}
              onChange={(e) => {
                setFioulConsommationEuros(e.currentTarget.value);
              }}
              required={isFioul}
            />
          </div>
        </div>
        <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
          <label className="label" htmlFor="equipments">
            Principaux équipements :{" "}
          </label>
          <ChoixMultiple
            options={
              mesConsommationsQuestionsData.fioul.principauxEquipements.options
            }
            value={fioulPrincipauxEquipments}
            onClick={(e) => {
              const equipmentToUnselected = [...fioulPrincipauxEquipments].find(
                (option) => option.label === e?.label
              );
              e &&
                setFioulPrincipauxEquipments((state) => {
                  const newState = [...state];
                  const foundIndex = newState.findIndex(
                    (option) => option.label === e.label
                  );
                  if (foundIndex !== -1) {
                    newState.splice(foundIndex, 1);
                  } else {
                    newState.push(e);
                  }
                  return newState;
                });
              //handle deselect option equipment
              switch (equipmentToUnselected?.label) {
                case "Chauffage":
                  resetChauffageSection();
                  break;
                case "Eau chaude sanitaire (ECS)":
                  resetECSSection();
                  break;
              }
            }}
            isRequired={isFioul}
          />
        </div>
      </section>
      {fioulPrincipauxEquipments.find((elem) => elem.value === "chauffage") ? (
        <section>
          <div className="MesConsommationsAndEquipementsRG__Trait" />
          <div className="MesConsommationsAndEquipementsRG__TitleTwo">
            <h3>Chauffage</h3>
          </div>
          <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
            <label className="label" htmlFor="surfaceChauffee">
              Surface chauffée :{" "}
            </label>
            <div style={{ maxWidth: "102px" }}>
              <TextInput
                inputLableHidden
                type="number"
                inputDescriptionHidden
                iconType="squaredMeters"
                iconHidden={false}
                placeholder="xxxx"
                value={fioulSurfaceChauffee ?? undefined}
                onChange={(e) => {
                  setFioulSurfaceChauffee(e.currentTarget.value);
                }}
                required={
                  isFioul &&
                  !!fioulPrincipauxEquipments.find(
                    (elem) => elem.value === "chauffage"
                  )
                }
              />
            </div>
          </div>
          <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
            <label className="label" htmlFor="temperatureConfort">
              Température de confort (°C):{" "}
            </label>
            <Quantite
              value={fioulTemperatureConfort ?? 0}
              onPlusMinusClick={(e) => setFioulTemperatureConfort(e)}
            />
          </div>

          <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
            <div
              className={"MesConsommationsAndEquipementsRG__imgLabelWrapper"}
            >
              <TurningBlueStar />
              <label className="label" htmlFor="typeChauffage">
                Type de chaudière :{" "}
              </label>
            </div>
            <ChoixMultiple
              options={
                mesConsommationsQuestionsData.fioul.typeDeChaudiere.options
              }
              value={fioulTypeChauffage}
              onClick={(e) => {
                e &&
                  setFioulTypeChauffage((state) => {
                    const newState = [...state];
                    const foundIndex = newState.findIndex(
                      (option) => option.label === e.label
                    );
                    if (foundIndex !== -1) {
                      newState.splice(foundIndex, 1);
                    } else {
                      return [e];
                    }
                    return newState;
                  });
              }}
              isRequired={
                isFioul &&
                !!fioulPrincipauxEquipments.find(
                  (elem) => elem.value === "chauffage"
                )
              }
            />
          </div>

          <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
            <label className="label" htmlFor="typeEmetteur">
              Type d’émetteur :{" "}
            </label>
            <ChoixMultiple
              options={
                mesConsommationsQuestionsData.fioul.typeDEmetteur.options
              }
              value={fioulTypeEmetteur}
              onClick={(e) => {
                if (!e) return;

                setFioulTypeEmetteur((state) => {
                  const newState = [...state];
                  const foundIndex = newState.findIndex(
                    (option) => option.label === e.label
                  );
                  if (foundIndex !== -1) {
                    newState.splice(foundIndex, 1);
                  } else {
                    return [e];
                  }
                  return newState;
                });

                if (e.value === "plancherChauffant") {
                  setFioulNombreEmetteur(1);
                }
              }}
              isRequired={
                isFioul &&
                !!fioulPrincipauxEquipments.find(
                  (elem) => elem.value === "chauffage"
                )
              }
            />
          </div>
          {fioulTypeEmetteur.length &&
          fioulTypeEmetteur[0].value !== "plancherChauffant" ? (
            <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
              <label className="label" htmlFor="nombreEmetteurs">
                Nombre d’émetteur(s) :{" "}
              </label>
              <Quantite
                value={fioulNombreEmetteur ?? 0}
                onPlusMinusClick={(e) => setFioulNombreEmetteur(e)}
              />
            </div>
          ) : null}
        </section>
      ) : null}
      {fioulPrincipauxEquipments.find(
        (elem) => elem.value === "eauChaudeSanitaire"
      ) ? (
        <section>
          <div className="MesConsommationsAndEquipementsRG__Trait" />
          <div className="MesConsommationsAndEquipementsRG__TitleTwo">
            <h3>Eau chaude sanitaire (ECS)</h3>
          </div>

          <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
            <label className="label" htmlFor="storageCapacity">
              Capacité de stockage :{" "}
            </label>
            <div className="MesConsommationsAndEquipementsRG__DropdowContainer">
              <Dropdown
                placeholder="Sélectionnez votre volume"
                options={
                  mesConsommationsQuestionsData.fioul.capaciteDeStockage.options
                }
                tags={false}
                optionsSelected={
                  fioulStorageCapacity.length > 0 ? fioulStorageCapacity : []
                }
                multipleChoices={false}
                onSelect={(option) => {
                  manageDropdownSingleSelection(
                    option,
                    setFioulStorageCapacity
                  );
                }}
                isRequired={
                  isFioul &&
                  !!fioulPrincipauxEquipments.find(
                    (elem) => elem.value === "eauChaudeSanitaire"
                  )
                }
              />
            </div>
          </div>
        </section>
      ) : null}
      {fioulPrincipauxEquipments.length > 1 ? (
        <section>
          <div className="MesConsommationsAndEquipementsRG__Trait" />
          <div className="MesConsommationsAndEquipementsRG__Synthese">
            <div className="MesConsommationsAndEquipementsRG__TitleSynthese">
              <h3>Synthèse</h3>
            </div>
            <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
              <label className="label" htmlFor="consommationElec">
                Consommation en fioul annuelle :{" "}
              </label>
              <div style={{ maxWidth: "102px" }}>
                <TextInput
                  inputLableHidden
                  type="number"
                  inputDescriptionHidden
                  iconType="euros"
                  iconHidden={false}
                  placeholder="XXXX"
                  value={fioulConsommationEuros ?? undefined}
                  onChange={(e) => {
                    setFioulConsommationEuros(e.currentTarget.value);
                  }}
                  required={isFioul && fioulPrincipauxEquipments.length > 1}
                />
              </div>
            </div>
            <div className="MesConsommationsAndEquipementsRG__SyntheseRow">
              {fioulPrincipauxEquipments.find(
                (elem) => elem.value === "chauffage"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={Chauffage} alt="Chauffage" />
                    <label className="label" htmlFor="chauffageEuros">
                      Chauffage{" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkFioulEquipmentsDistribution()}
                      inputLableHidden
                      type="number"
                      inputDescriptionHidden
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={fioulChauffageConsumptionRate ?? undefined}
                      onChange={(e) => {
                        setFioulChauffageConsumptionRate(e.currentTarget.value);
                      }}
                      required={
                        isFioul &&
                        fioulPrincipauxEquipments.length > 1 &&
                        !!fioulPrincipauxEquipments.find(
                          (elem) => elem.value === "chauffage"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(fioulConsommationEuros) *
                        Number(fioulChauffageConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
              {fioulPrincipauxEquipments.find(
                (elem) => elem.value === "eauChaudeSanitaire"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={ECS} alt="ECS" />
                    <label className="label" htmlFor="ECSEuros">
                      Eau chaude sanitaire :{" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkFioulEquipmentsDistribution()}
                      inputLableHidden
                      type="number"
                      inputDescriptionHidden
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={fioulECSConsumptionRate ?? undefined}
                      onChange={(e) => {
                        setFioulECSConsumptionRate(e.currentTarget.value);
                      }}
                      required={
                        isFioul &&
                        fioulPrincipauxEquipments.length > 1 &&
                        !!fioulPrincipauxEquipments.find(
                          (elem) => elem.value === "eauChaudeSanitaire"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(fioulConsommationEuros) *
                        Number(fioulECSConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionMessageContainer">
              <p>{inputErrorMessage()}</p>
            </div>
          </div>
        </section>
      ) : null}
    </section>
  );
};
