import { IndependenceLevelComponent } from "../PerformanceComponents/IndependenceLevelComponent/IndependenceLevelComponent";
import "./SolarPerformanceKPIsRG.scss";

// Interfaces import
import { IPerformanceRG } from "../../interfaces/renoglobaleInterface";

// Local interfaces declaration
interface Props {
  performanceRGData?: IPerformanceRG | null;
}

export const SolarPerformanceKPIsRG = ({ performanceRGData }: Props) => {
  const dataForIndependenceLevelComponent = {
    selfproductionRate: performanceRGData?.selfproduction_rate || null,
    solarProductionConsumed:
      performanceRGData?.solar_production_consumed_rate || null,
    solarProductionStored:
      performanceRGData?.solar_production_stored_rate || null,
    selfconsumptionRate: performanceRGData?.selfconsumption_rate || null,
  };

  return (
    <div className="SolarPerformanceKPIsRG">
      <IndependenceLevelComponent
        title={"Taux d'autoconsommation"}
        autoprod={false}
        color={"#0073E0"}
        legende={false}
        description={
          <>
            <strong>
              {dataForIndependenceLevelComponent.selfconsumptionRate &&
                Math.round(
                  dataForIndependenceLevelComponent.selfconsumptionRate * 100
                )}
              %
            </strong>{" "}
            de votre production solaire est utilisée pour votre propre
            consommation électrique. Le reste de votre production{" "}
            {dataForIndependenceLevelComponent.selfconsumptionRate
              ? `(${Math.round(
                  100 -
                    dataForIndependenceLevelComponent.selfconsumptionRate * 100
                )}%)`
              : ""}{" "}
            est injecté dans le réseau électrique.
          </>
        }
        selfproductionRate={
          dataForIndependenceLevelComponent.selfconsumptionRate
        }
        solarProductionConsumed={
          dataForIndependenceLevelComponent.selfconsumptionRate
        }
        solarProductionStored={0}
      />
      <IndependenceLevelComponent
        title={"Taux d’autoproduction"}
        autoprod={true}
        color={"#752BAF"}
        description={
          <>
            <strong>
              {dataForIndependenceLevelComponent.selfproductionRate &&
                Math.round(
                  Math.round(
                    dataForIndependenceLevelComponent.selfproductionRate * 100
                  )
                )}
              %
            </strong>{" "}
            de votre consommation est couverte par votre production solaire. Le
            reste de votre consommation
            {dataForIndependenceLevelComponent.selfproductionRate
              ? ` (${Math.round(
                  100 -
                    dataForIndependenceLevelComponent.selfproductionRate * 100
                )}%)`
              : ""}{" "}
            utilise l'électricité du réseau électrique.
          </>
        }
        selfproductionRate={
          dataForIndependenceLevelComponent.selfproductionRate
        }
        solarProductionConsumed={
          dataForIndependenceLevelComponent.solarProductionConsumed
        }
        solarProductionStored={0}
      />
    </div>
  );
};
