import React, { useState } from "react";
import { useAppSelector } from "../../../redux/store/hook";

//Style import
import "./monProjetRecapWrapper.scss";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { DetailOptionTabsRG } from "../../../components/MonInstallationOption/DetailOptionTabsRG/DetailOptionTabsRG";
import { ProfitabilityComponentRG } from "../../../containers/OptionChosenRG/ProfitabilityComponentRG/ProfitabilityComponentRG";
import { FinancingComponent } from "../../OptionChosenRG/FinancingComponent/FinancingComponent";
import { ImpactComponentRG } from "../../OptionChosenRG/ImpactComponentRG/ImpactComponentRG";
import { PerformanceComponentRG } from "../../OptionChosenRG/PerformanceComponentRG/PerformanceComponentRG";
import { SubHeaderRG } from "../../SubHeaderRG/SubHeaderRG";

// Data import
import { interestRateOptions } from "@web/shared/dist/datas/dataHypotethis";

// Interfaces import
import {
  HelpsOptions,
  IGoTo,
  LoanSimulationResult,
} from "../../../interfaces/generalInterfaces";

// Services import
import { useSaveFinancingInformation } from "../../../services/RenovationGlobale/MonProjet/useSaveFinancingInformation";
import { INavigateData } from "../../../services/useNavigation/useNavigation";

// Local interface declaration
interface IProps {
  goNext: (data?: INavigateData<any>) => void;
  goBack: (data?: INavigateData<boolean>) => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
  setIsGoToFunctionActive: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IOption {
  label: string | null;
  value: string | null;
}

export const MonProjetRecapWrapper = ({ goNext, goBack }: IProps) => {
  const [isTabOpen, setIsTabOpen] = useState<boolean[]>([true, false, false]);
  const { studyInformation, userAuthInfo, monInstallation, cartProducts } =
    useAppSelector((state) => state);
  const { saveFinancingInformation, isLoading } = useSaveFinancingInformation();

  const onTabClick = (index: number) => {
    setIsTabOpen((prevState) => {
      const newState = prevState.map(() => false);
      newState[index] = !prevState[index];
      return newState;
    });
  };

  // Financing component form state
  const financingOptionsList: IOption[] = [
    {
      label: "Organisme de financement",
      value: "Organisme de financement",
    },
    { label: "Comptant", value: "Comptant" },
  ];
  const [financingOptions, setfinancingOptions] = useState<IOption>(
    financingOptionsList.find(
      (financingOption) =>
        financingOption.value === studyInformation.type_of_payment
    ) || financingOptionsList[0]
  );
  const [fundingPeriod, setFundingPeriod] = useState<number>(
    studyInformation.loan_duration || 15
  );
  const [personalContributionAmount, setPersonalContributionAmount] =
    useState<number>(studyInformation.deposit || 0);

  const [interestRate, setInterestRate] = useState<string>(
    studyInformation.interest_rate || interestRateOptions[0]
  );

  const [isDeferralOption, setIsDeferralOption] = useState(
    studyInformation.is_deferral_option || false
  );

  const [hasBorrowerInsurance, setHasBorrowerInsurance] = useState(
    studyInformation.has_borrower_insurance ?? true
  );

  const [hasCoBorrower, setHasCoBorrower] = useState(
    studyInformation.has_co_borrower || false
  );

  const [helpsOptions, setHelpsOptions] = useState<HelpsOptions>(
    studyInformation.helps_options || {
      isHelpsDisplayed: false,
      isCEEHelpsDeducted: false,
      isMPRHelpsDeducted: false,
    }
  );

  const [loanSimulationResult, setLoanSimulationResult] =
    useState<LoanSimulationResult>({
      monthlyPayment: 0,
      TAEG: 0,
      tauxDebiteurFixe: 0,
      loanAmount: 0,
      deferralDurationMonth: 0,
    });

  const SubHeaderRGButtons = () => {
    return (
      <div className="MonProjetRecapWrapper__ButtonsContainer">
        <CTAButton
          name="Retour"
          onClick={() => {
            goBack({ isIncomeModaleOpen: false });
          }}
        />
        <CTAButton
          name="Suivant"
          onClick={onClickOnNext}
          isLoading={isLoading}
        />
      </div>
    );
  };

  const onClickOnNext = () => {
    saveFinancingInformation({
      deposit: personalContributionAmount,
      type_of_payment: financingOptions.value || "",
      interest_rate: interestRate,
      is_deferral_option: isDeferralOption,
      loan_duration: fundingPeriod,
      hasBorrowerInsurance,
      hasCoBorrower: hasCoBorrower,
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id || null,
      helps_options: helpsOptions,
    });
    goNext();
  };

  return (
    <div className="MonProjetRecapWrapper">
      <SubHeaderRG
        title="Mon projet de rénovation"
        studyInformation={studyInformation}
        SubHeaderRGButtons={SubHeaderRGButtons}
      />

      <DetailOptionTabsRG isTabOpen={isTabOpen} onTabClick={onTabClick} />
      {isTabOpen[0] && (
        <PerformanceComponentRG
          studyInformation={studyInformation}
          userAuthInfo={userAuthInfo}
          cartProducts={cartProducts}
        />
      )}
      {isTabOpen[1] && (
        <ProfitabilityComponentRG
          studyInformation={studyInformation}
          userAuthInfo={userAuthInfo}
          monInstallation={monInstallation}
          cartProducts={cartProducts}
        />
      )}
      {isTabOpen[2] && (
        <ImpactComponentRG
          userToken={userAuthInfo.userToken}
          monInstallation={monInstallation}
          study_id={studyInformation.study_id || null}
          city={studyInformation.city || null}
          postcode={studyInformation.zipcode || null}
        />
      )}
      {isTabOpen[3] && (
        <FinancingComponent
          userToken={userAuthInfo.userToken}
          study_id={studyInformation.study_id || null}
          financingOptions={financingOptions}
          setfinancingOptions={setfinancingOptions}
          fundingPeriod={fundingPeriod}
          setFundingPeriod={setFundingPeriod}
          personalContributionAmount={personalContributionAmount}
          setPersonalContributionAmount={setPersonalContributionAmount}
          interestRate={interestRate}
          setInterestRate={setInterestRate}
          financingOptionsList={financingOptionsList}
          interestRateOptions={interestRateOptions}
          isDeferralOption={isDeferralOption}
          setIsDeferralOption={setIsDeferralOption}
          loanSimulationResult={loanSimulationResult}
          setLoanSimulationResult={setLoanSimulationResult}
          hasBorrowerInsurance={hasBorrowerInsurance}
          setHasBorrowerInsurance={setHasBorrowerInsurance}
          hasCoBorrower={hasCoBorrower}
          setHasCoBorrower={setHasCoBorrower}
          helpsOptions={helpsOptions}
          setHelpsOptions={setHelpsOptions}
        />
      )}
    </div>
  );
};
