import React from "react";
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import "./SavingsGraph.scss";

interface IProps {
  savingsGraphData: {
    annee: string;
    economies: number;
    prix_kwh_reseau: number;
    prix_kwh_solaire: number;
  }[];
}

export const SavingsGraph = ({ savingsGraphData }: IProps) => {
  // we find the year with the highest economies value
  const maximumEconomieValue = savingsGraphData.reduce((prev, current) => {
    return prev.economies > current.economies ? prev : current;
  });

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <ul className="legendContainer">
        {payload &&
          payload.map((entry: any, index: any) => (
            <li
              key={`item-${index}`}
              className={`legendItem`}
              style={{ color: entry.color }}
            >
              <div
                className="colorPastille"
                style={{ backgroundColor: entry.color }}
              />
              <p>{entry.value.toUpperCase().replaceAll("_", " ")}</p>
            </li>
          ))}
      </ul>
    );
  };

  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className="customTooltip">
          <div className="labelPriceContainer">
            <div
              className="label colorPastille"
              style={{ backgroundColor: payload[0].color }}
            />
            <p className="price">{`${payload[0].payload.economies} €`}</p>
          </div>
          <div className="labelPriceContainer">
            <div
              className="label colorPastille"
              style={{ backgroundColor: payload[1].color }}
            />
            <p className="price">{`${payload[0].payload.prix_kwh_reseau} €`}</p>
          </div>
          <div className="labelPriceContainer">
            <div
              className="label colorPastille"
              style={{ backgroundColor: payload[2].color }}
            />
            <p className="price">{`${payload[0].payload.prix_kwh_solaire} €`}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  const dataKey =
    savingsGraphData[savingsGraphData.length - 1].prix_kwh_solaire >
    savingsGraphData[savingsGraphData.length - 1].prix_kwh_reseau
      ? "prix_kwh_solaire"
      : "prix_kwh_reseau";

  return (
    <div className="SavingsGraph">
      <section className="SavingsGraph__GraphContainer">
        <ResponsiveContainer>
          <ComposedChart width={100} height={350} data={savingsGraphData}>
            <XAxis
              dataKey="annee"
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#191B1E", fontSize: "0.6875rem" }}
            />
            <YAxis
              dataKey={dataKey}
              yAxisId="prix_kwh_reseau/prix_kwh_solaire"
              orientation="right"
              scale="linear"
              unit="€"
            />
            <YAxis
              dataKey="economies"
              yAxisId="economies"
              orientation="left"
              domain={[0, Math.round(maximumEconomieValue.economies * 1.2)]}
              scale="linear"
              unit="€"
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={renderLegend}
              verticalAlign="top"
              align="center"
              height={50}
            />
            <Bar
              dataKey="economies"
              fill="#46D1CA"
              barSize={24}
              yAxisId="economies"
              radius={[4, 4, 0, 0]}
            />
            <Line
              type="monotone"
              dataKey="prix_kwh_reseau"
              stroke="#0073E0"
              yAxisId="prix_kwh_reseau/prix_kwh_solaire"
            />
            <Line
              type="monotone"
              dataKey="prix_kwh_solaire"
              yAxisId="prix_kwh_reseau/prix_kwh_solaire"
              stroke="#FFC043"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </section>
      <section className="SavingsGraph__GraphDescription">
        <p className="Title">Économies sur la facture d&apos;électricité</p>
        <p className="Description">
          Nos prévisions donnent une forte augmentation du prix de
          l&apos;électricité à court terme (2026) d'au moins 15% par an, pour
          évoluer ensuite à un taux annuel de +3.4% jusqu&apos;en 2050.
          L&apos;énergie solaire vous permet ainsi d&apos;être moins dépendant
          des fluctuations des tarifs d&apos;électricité. Votre kWh solaire
          reste fixe pendant toute la durée de vie de votre installation.
        </p>
      </section>
    </div>
  );
};
