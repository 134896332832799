// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Services imports
import { IAvailableCalepinages } from "../../interfaces/generalInterfaces";

interface IProps {
  household_id: string;
  userToken: string;
  study_id: string;
  building_id: string;
}

export const useGetAvailableCalepinagesForPersonalizedScenarios = () => {
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getAvailableCalepinagesForPersonalizedScenarios = async ({
    household_id,
    userToken,
    study_id,
    building_id,
  }: IProps): Promise<IAvailableCalepinages | void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<IAvailableCalepinages>(
      `${process.env.REACT_APP_EERO_URL}/oree2/pv/getAvailableCalpinagesForPersonalizedScenarios/${study_id}?household_id=${household_id}&building_id=${building_id}`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );

    if (response) {
      return response;
    }
  };

  return {
    getAvailableCalepinagesForPersonalizedScenarios,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
