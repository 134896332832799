import { Text, Font, View, Image } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../../../assets/pdf/fonts/DMSansBold.ttf";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});

interface Props {
  prixActuel: string;
  prixMoyenReseau: string;
  prixMoyenSolaire: string;
  image: string;
}

export const PdfEvolutionPrix = ({
  prixActuel,
  prixMoyenReseau,
  prixMoyenSolaire,
  image,
}: Props) => {
  return (
    <View
      style={{
        fontSize: 9,
        padding: 15,
        backgroundColor: "#f8f8f8",
        borderRadius: 10,
        marginBottom: 40,
        marginTop: 10,
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <View
          style={{
            width: "50%",
          }}
        >
          <Text
            style={{
              fontFamily: "DMSansBold",
              marginBottom: 10,
            }}
          >
            Évolution du prix de l’électricité sur 25 ans :
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              style={{
                marginBottom: 10,
              }}
            >
              Nos prévisions donnent une forte augmentation du prix de
              l’électricité à court terme (2026) d’au moins 15% par an, pour
              évoluer ensuite à un taux annuel de +3.4% jusqu’en 2050.
            </Text>
            <Text>
              L’énergie solaire vous permet ainsi d’être moins dépendant des
              fluctuations des tarifs d’électricité. Votre kWh solaire reste
              fixe pendant toute la durée de vie de votre installation.
            </Text>
          </View>
        </View>
        <View
          style={{
            width: "45%",
            paddingLeft: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image style={{ width: "100%", objectFit: "contain" }} src={image} />
        </View>
      </View>
      <View
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          marginBottom: "-30px",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 8,
            backgroundColor: "white",
            border: "2px solid #e9eaeb",
            borderRadius: 10,
          }}
        >
          <Text>Prix actuel de l’électricité :</Text>
          <Text
            style={{ color: "black", marginTop: 5, fontFamily: "DMSansBold" }}
          >
            {prixActuel} €/kWh TTC
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 8,
            backgroundColor: "white",
            border: "2px solid #e9eaeb",
            borderRadius: 10,
          }}
        >
          <Text>Prix moyen du kWh réseau :</Text>
          <Text
            style={{ color: "#0073e2", marginTop: 5, fontFamily: "DMSansBold" }}
          >
            {prixMoyenReseau} €/kWh TTC sur 25 ans
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 8,
            backgroundColor: "white",
            border: "2px solid #e9eaeb",
            borderRadius: 10,
          }}
        >
          <Text>Prix moyen du kWh solaire :</Text>
          <Text
            style={{ color: "#ed8c19", marginTop: 5, fontFamily: "DMSansBold" }}
          >
            {prixMoyenSolaire} €/kWh TTC
          </Text>
        </View>
      </View>
    </View>
  );
};
