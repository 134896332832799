// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";

// Interfaces import
import { ISageDepot } from "../../../interfaces/generalInterfaces";

// Local interface declaration
interface IProps {
  userToken: string | null;
}

export const useGetDepots = () => {
  const { errorMessage: axiosErrorMessage, axiosGetRequest } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const getDepots = async ({ userToken }: IProps) => {
    setErrorMessage("");

    const response = await axiosGetRequest<{ depots: ISageDepot[] }>(
      `${process.env.REACT_APP_EERO_URL}/oree2/depots`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    return response?.depots || [];
  };

  return {
    getDepots,
    errorMessage: axiosErrorMessage || errorMessage,
  };
};
