//Styles import
import { DSColors } from "@web/shared/src/styles/variables";
import "./PACAirEauPage.scss";

// Libraries import
import find from "lodash.find";
import { useState } from "react";
import SVG from "react-inlinesvg";

// Components import
import { BoutonPicto } from "@web/shared/dist/components/DesignSystem/Boutons/BoutonPicto/BoutonPicto";
import {
  Dropdown,
  manageDropdownSingleSelection,
} from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { ExtraChargesCarrousel } from "../../../containers/ProductPageRG/ExtraChargesCarrousel/ExtraChargesCarrousel";
import { ExtraChargesListItem } from "../../../containers/ProductPageRG/ExtraChargesListItem/ExtraChargesListItem";
import { ExtraChargesModal } from "../../../containers/ProductPageRG/ExtraChargesModal/ExtraChargesModal";
import { ProductTotalPrice } from "../../../containers/ProductPageRG/ProductTotalPrice/ProductTotalPrice";
import { ProductFilterContainer } from "../filterComponent/ProductFilterContainer";

// Images import
import Trash from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";

// Interfaces import
import { ISageProduct } from "../../../interfaces/generalInterfaces";
import {
  IDataProduct,
  TFraisSupp,
  TProjectPages,
} from "../../../interfaces/renoglobaleInterface";

//Services import
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import { setCartProductsAction } from "../../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { formatDropdownOptionsFromSageProducts } from "../../../services/RenovationGlobale/formatDropdownOptionsFromSageProducts";
import { formatSageProductsForDropdown } from "../../../services/RenovationGlobale/formatProductSelectedForDropdown";

// Local interfaces declaration
interface IFilterSAGEProducts {
  sageProduct: ISageProduct[];
  sageProductCharacteristic?: keyof ISageProduct;
}

interface IOption {
  label: string | null;
  value: string | null;
}

interface IProps {
  dataProductsFromCategory: IDataProduct[];
  selectedProductIndex?: number | null;
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  fetchProjectKPI: ({
    cartProductsToDisplay,
  }: {
    cartProductsToDisplay: IDataProduct[];
  }) => Promise<void>;
  isEligibleRenoGlobal: boolean;
}
export const PACAirEauPage = ({
  dataProductsFromCategory,
  selectedProductIndex,
  setScreenSelected,
  fetchProjectKPI,
  isEligibleRenoGlobal,
}: IProps) => {
  // HOOKS CALLS
  const dispatch = useAppDispatch();
  const { SAGEItemsInfo: productsFromSage } = useAppSelector((state) => state);
  const cartProductsToDisplay = useAppSelector((state) => state.cartProducts);
  const selectedProduct =
    selectedProductIndex || selectedProductIndex === 0
      ? cartProductsToDisplay[selectedProductIndex]
      : null;

  // STATES DECLARATION
  const configurationTypeOption = [
    { label: "Remplacement", value: "remplacement" },
    { label: "Relève", value: "releve" },
  ];
  const [productSelected, setProductSelected] = useState<IOption[]>(
    selectedProduct && cartProductsToDisplay
      ? [
          {
            label:
              selectedProduct.product_alias || selectedProduct.product_name,
            value: selectedProduct.sage_reference,
          },
        ]
      : []
  );
  const [power, setPower] = useState<IOption[]>([]);
  const [alimentation, setAlimentation] = useState<IOption[]>([]);
  const [capacity, setCapacity] = useState<IOption[]>([]);
  const [brand, setBrand] = useState<IOption[]>([]);
  const [gamme, setGamme] = useState<IOption[]>([]);
  const [configurationType, setConfigurationType] = useState<
    IOption | undefined
  >(
    selectedProduct && cartProductsToDisplay
      ? configurationTypeOption.find(
          (elem) =>
            elem.value === selectedProduct.selected_characteristics?.config_type
        ) || undefined
      : { label: "Remplacement", value: "remplacement" }
  );
  const [fraisSupp, setFraisSupp] = useState<TFraisSupp[]>(
    selectedProduct && cartProductsToDisplay
      ? selectedProduct.extra_charges_added || []
      : []
  );

  const [isModaleFraisSuppOpen, setIsModaleFraisSuppOpen] =
    useState<boolean>(false);

  // FUNCTIONS DECLARATION
  const addProductToMySelection = () => {
    if (!productToAdd) {
      return; // If not product find we can not add it to the selection
    }

    productToAdd.selected_characteristics.config_type =
      configurationType?.value || undefined;
    productToAdd.isProductRecommended =
      find(cartProductsToDisplay, productToAdd)?.isProductRecommended || false;
    productToAdd.isSelected = true;

    //If we have an selectedProductIndex it means the product is already in the recommended products : we need to update it
    if (selectedProductIndex || selectedProductIndex === 0) {
      const newProductToDisplay = [...cartProductsToDisplay];
      newProductToDisplay.splice(selectedProductIndex, 1, productToAdd);
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    } else {
      // Otherwise we just add the new product to the recommended products
      const newProductToDisplay = [...cartProductsToDisplay, productToAdd];
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    }

    // We navigate back to the "my project" page
    setScreenSelected("project");
  };

  // For each product characteristics if there is a dropdown value in the local state we filter the product list to keep only
  // the products that have the dropdown value in their product characteristics
  // Note : the dropdown value should not filter its own product_characteristics to be able to select another product_characteristics after selecting a first one
  const filterProductsCharacteristicsListAccordingToDropdownsValue = ({
    sageProduct,
    sageProductCharacteristic,
  }: IFilterSAGEProducts) => {
    return sageProduct.filter((product) => {
      return (
        (sageProductCharacteristic === "Puissance de la PAC" ||
          product["Puissance de la PAC"]
            ?.toString()
            .toLocaleLowerCase()
            .trim() ===
            (power?.[0]?.value?.toLocaleLowerCase().trim() ??
              product["Puissance de la PAC"]
                ?.toString()
                .toLocaleLowerCase()
                .trim())) &&
        (sageProductCharacteristic === "Alimentation" ||
          product["Alimentation"]?.toString()?.toLocaleLowerCase().trim() ===
            (alimentation?.[0]?.value?.toLocaleLowerCase().trim() ??
              product["Alimentation"]
                ?.toString()
                .toLocaleLowerCase()
                .trim())) &&
        (sageProductCharacteristic === "Capacité (l)" ||
          product["Capacité (l)"]?.toString()?.toLocaleLowerCase().trim() ===
            (capacity?.[0]?.value?.toLocaleLowerCase().trim() ??
              product["Capacité (l)"]
                ?.toString()
                ?.toLocaleLowerCase()
                .trim())) &&
        (sageProductCharacteristic === "Marque" ||
          product.Marque?.toString()?.toLocaleLowerCase().trim() ===
            (brand?.[0]?.value?.toLocaleLowerCase().trim() ??
              product.Marque?.toString()?.toLocaleLowerCase().trim())) &&
        (sageProductCharacteristic === "Gamme" ||
          product.Gamme?.toString()?.toLocaleLowerCase().trim() ===
            (gamme?.[0]?.value?.toLocaleLowerCase().trim() ??
              product.Gamme?.toString()?.toLocaleLowerCase().trim()))
      );
    });
  };

  // VARIABLES DECLARATION

  // We filter the SAGE products to only keep the SAGE PAC air/eau products that are in the dataProductsFromCategory products list from data backend (= products that are elligible for the house)
  const productsCharacteristicsListForPACFromSAGE = productsFromSage.filter(
    (itemInfo) =>
      dataProductsFromCategory.some(
        (dataProduct) => dataProduct.sage_reference === itemInfo.AR_Ref
      )
  );

  const initialFilteredListFraisSuppValue: TFraisSupp[] = [];

  // Merge all frais supp of all products in one array and remove duplicate
  const filteredListPotentialFraisSupp = dataProductsFromCategory
    .reduce((acc, product) => {
      return [...acc, ...product.potential_products_to_add];
    }, initialFilteredListFraisSuppValue)
    .reduce((accTwo, fraisSupp) => {
      if (
        accTwo.find((elem) => elem.sage_reference === fraisSupp.sage_reference)
      ) {
        return accTwo;
      }
      return [...accTwo, fraisSupp];
    }, initialFilteredListFraisSuppValue);

  const productToAdd = (() => {
    const product = dataProductsFromCategory.find(
      (product) => product.sage_reference === productSelected?.[0]?.value
    );
    if (!product) {
      return undefined;
    }
    product.extra_charges_added = fraisSupp;
    product.selected_characteristics.config_type =
      configurationType?.value || undefined;
    return product;
  })();

  const sageProductFormatedForDropdown = formatSageProductsForDropdown({
    sageProducts: filterProductsCharacteristicsListAccordingToDropdownsValue({
      sageProduct: productsCharacteristicsListForPACFromSAGE,
    }),
    dataProducts: dataProductsFromCategory,
  });

  return productsCharacteristicsListForPACFromSAGE.length === 0 ? (
    <div>Pas de produits disponible</div>
  ) : (
    <div className="PACAirEauPage">
      <ExtraChargesModal
        isModaleFraisSuppOpen={isModaleFraisSuppOpen}
        setFraisSupp={setFraisSupp}
        setIsModaleFraisSuppOpen={setIsModaleFraisSuppOpen}
      />
      <section className="PACAirEauPage__FormContainer">
        <div className="PACAirEauPage__SubTitle">
          <h3>Produit : </h3>
        </div>
        <div className="PACAirEauPage__ProductContent">
          <div className="PACAirEauPage__Question">
            <div className="PACAirEauPage__Dropdown">
              <Dropdown
                options={sageProductFormatedForDropdown}
                optionsSelected={productSelected}
                onSelect={(option) => {
                  manageDropdownSingleSelection(option, setProductSelected);
                }}
                multipleChoices={false}
                tags={false}
                placeholder="Choisir pompe à chaleur"
                maxHeight="32vh"
                searchable={true}
              />
            </div>
            <div className="PACAirEauPage__SVG">
              {productSelected?.[0]?.value ? (
                <SVG src={Trash} onClick={() => setProductSelected([])} />
              ) : null}
            </div>
          </div>
        </div>
        <div className="PACAirEauPage__DualiteContainer">
          <label>Type de configuration : </label>
          <div className="PACAirEauPage__Dualite">
            <ChoixMultiple
              options={configurationTypeOption}
              value={configurationType ? [configurationType] : []}
              onClick={(option) => {
                option
                  ? setConfigurationType(option)
                  : setConfigurationType(undefined);
              }}
            />
          </div>
        </div>
        <ProductFilterContainer>
          <div className="PACAirEauPage__FiltersContent">
            <div className="PACAirEauPage__QuestionContainer">
              <div className="PACAirEauPage__Question">
                <div className="PACAirEauPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts:
                        filterProductsCharacteristicsListAccordingToDropdownsValue(
                          {
                            sageProduct:
                              productsCharacteristicsListForPACFromSAGE,
                            sageProductCharacteristic: "Puissance de la PAC",
                          }
                        ),
                      productCharacteristic: "Puissance de la PAC",
                    })}
                    optionsSelected={power}
                    onSelect={(option) => {
                      manageDropdownSingleSelection(option, setPower);
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Puissance"
                  />
                </div>
                <div className="PACAirEauPage__SVG">
                  {power?.[0]?.value ? (
                    <SVG src={Trash} onClick={() => setPower([])} />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="PACAirEauPage__QuestionContainer">
              <div className="PACAirEauPage__Question">
                <div className="PACAirEauPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts:
                        filterProductsCharacteristicsListAccordingToDropdownsValue(
                          {
                            sageProduct:
                              productsCharacteristicsListForPACFromSAGE,
                            sageProductCharacteristic: "Alimentation",
                          }
                        ),
                      productCharacteristic: "Alimentation",
                    })}
                    optionsSelected={alimentation}
                    onSelect={(option) => {
                      manageDropdownSingleSelection(option, setAlimentation);
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Alimentation"
                  />
                </div>
                <div className="PACAirEauPage__SVG">
                  {alimentation?.[0]?.value ? (
                    <SVG src={Trash} onClick={() => setAlimentation([])} />
                  ) : null}
                </div>
              </div>
            </div>

            <div className="PACAirEauPage__QuestionContainer">
              <div className="PACAirEauPage__Question">
                <div className="PACAirEauPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts:
                        filterProductsCharacteristicsListAccordingToDropdownsValue(
                          {
                            sageProduct:
                              productsCharacteristicsListForPACFromSAGE,
                            sageProductCharacteristic: "Capacité (l)",
                          }
                        ),
                      productCharacteristic: "Capacité (l)",
                    })}
                    optionsSelected={capacity}
                    onSelect={(option) => {
                      manageDropdownSingleSelection(option, setCapacity);
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Capacité de stockage"
                  />
                </div>
                <div className="PACAirEauPage__SVG">
                  {capacity?.[0]?.value ? (
                    <SVG src={Trash} onClick={() => setCapacity([])} />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="PACAirEauPage__QuestionContainer">
              <div className="PACAirEauPage__Question">
                <div className="PACAirEauPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts:
                        filterProductsCharacteristicsListAccordingToDropdownsValue(
                          {
                            sageProduct:
                              productsCharacteristicsListForPACFromSAGE,
                            sageProductCharacteristic: "Marque",
                          }
                        ),
                      productCharacteristic: "Marque",
                    })}
                    optionsSelected={brand}
                    onSelect={(option) => {
                      manageDropdownSingleSelection(option, setBrand);
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Marque"
                  />
                </div>
                <div className="PACAirEauPage__SVG">
                  {brand?.[0]?.value ? (
                    <SVG src={Trash} onClick={() => setBrand([])} />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="PACAirEauPage__QuestionContainer">
              <div className="PACAirEauPage__Question">
                <div className="PACAirEauPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts:
                        filterProductsCharacteristicsListAccordingToDropdownsValue(
                          {
                            sageProduct:
                              productsCharacteristicsListForPACFromSAGE,
                            sageProductCharacteristic: "Gamme",
                          }
                        ),
                      productCharacteristic: "Gamme",
                    })}
                    optionsSelected={gamme}
                    onSelect={(option) => {
                      manageDropdownSingleSelection(option, setGamme);
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Gamme"
                  />
                </div>
                <div className="PACAirEauPage__SVG">
                  {gamme?.[0]?.value ? (
                    <SVG src={Trash} onClick={() => setGamme([])} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </ProductFilterContainer>
      </section>
      {filteredListPotentialFraisSupp.length > 0 ? (
        <ExtraChargesCarrousel
          filteredListFraisSupp={filteredListPotentialFraisSupp}
          setFraisSupp={setFraisSupp}
        />
      ) : null}
      {fraisSupp.length > 0 ? (
        <section className="PACAirEauPage__ListFraisSuppContainer">
          {fraisSupp.map((extraCharge, index) => {
            return (
              <ExtraChargesListItem
                key={index + extraCharge.product_name}
                extraCharge={extraCharge}
                setFraisSupp={setFraisSupp}
              />
            );
          })}
        </section>
      ) : null}
      <section className="PACAirEauPage__ButtonPicto">
        {
          <BoutonPicto
            text="Ajouter une spécificité"
            color={DSColors.Mint}
            onClick={() => setIsModaleFraisSuppOpen(true)}
          />
        }
      </section>

      {productToAdd ? (
        <ProductTotalPrice
          addProductToMySelection={addProductToMySelection}
          fraisSupp={fraisSupp}
          productSelected={productToAdd}
          isUpdateproduct={selectedProductIndex !== undefined}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      ) : null}
    </div>
  );
};
