import { ExtraElectricitySalesGraphRG } from "../ExtraElectricitySalesGraphRG/ExtraElectricitySalesGraphRG";
import { SavingsGraphRG } from "../SavingsGraphRG/SavingsGraphRG";
import "./TotalGainGraphsRG.scss";

interface IProps {
  formattedEnergyPriceGraphData: {
    annee: string;
    electricite?: number;
    gaz?: number;
    fioul?: number;
    solaire?: number;
  }[];
  savingsGraphData: {
    annee: string;
    heating: number;
    ecs: number;
    autoconsumption: number;
    resale: number;
  }[];
}

export const TotalGainGraphsRG = ({
  savingsGraphData,
  formattedEnergyPriceGraphData,
}: IProps) => {
  return (
    <div className="TotalGainGraphsRG">
      <section className="TotalGainGraphsRG__SavingGraph">
        <SavingsGraphRG savingsGraphData={savingsGraphData} />
      </section>
      <section className="TotalGainGraphsRG__SavingGraph">
        <ExtraElectricitySalesGraphRG
          formattedEnergyPriceGraphData={formattedEnergyPriceGraphData}
        />
      </section>
    </div>
  );
};
