import { Page, Text, View } from "@react-pdf/renderer";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfTitleEncadre } from "../../components/Pdf/Pdf-Devis/PdfTitleEncadre/PdfTitleEncadre";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

//Interface import
import { ICommercialUserInformation } from "../../interfaces/generalInterfaces";

export const PdfRetractationForm = ({
  commercialUserInformation,
  isDemoVersion,
}: {
  commercialUserInformation: ICommercialUserInformation;
  isDemoVersion: boolean;
}) => {
  const companyFullAddress =
    commercialUserInformation.company_address +
    " " +
    commercialUserInformation.company_postal_code +
    " " +
    commercialUserInformation.company_city;
  return (
    <Page>
      <PdfBanner />
      <PdfLayout isDemoVersion={isDemoVersion}>
        <PdfHeader
          commercialUserInformation={commercialUserInformation}
          logoBase64={commercialUserInformation.company_logo_base_64}
        />
        <PdfTitleEncadre title="formulaire de rétractation :" />
        <View>
          <Text style={styles.preventInfo}>
            Veuillez compléter et renvoyer le présent formulaire uniquement si
            vous souhaitez vous rétracter du contrat.
          </Text>
        </View>

        <View style={styles.retractationDestinataireCText}>
          <Text>A l'attention de : </Text>
          <Text style={{ fontFamily: "DMSansBold" }}>
            {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
            , {companyFullAddress} -{" "}
            {commercialUserInformation.company_service_client_email}
          </Text>
        </View>
        <View style={styles.retractationDestinataireCBox}>
          <Text style={styles.retractationDestinataireTextBox}>
            Je vous notifie par la présente ma rétractation du contrat portant
            sur la vente du bien et sur les prestation de services ci-dessous :
          </Text>
          <Text style={styles.retractationDestinataireTextBox}>
            Commandé le :
          </Text>
          <Text style={styles.retractationDestinataireTextBox}>
            Nom du consommateur :
          </Text>
          <Text style={styles.retractationDestinataireTextBox}>
            Adresse du consommateur :
          </Text>
          <Text style={styles.retractationDestinataireTextBox}>
            Signature du consommateur uniquement en cas de notification du
            présent formulaire sur papier :
          </Text>
          <Text style={styles.retractationDestinataireTextBox}>Date :</Text>
        </View>

        <View>
          <Text style={[styles.preventInfo]}>
            Veuillez compléter et renvoyer le présent formulaire uniquement si
            vous souhaitez vous rétracter du contrat.
          </Text>
        </View>
      </PdfLayout>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
    </Page>
  );
};
