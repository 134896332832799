// Libraries import
import { useState } from "react";
import { useAxiosPut } from "../useAxiosPut";

// Local interface declaration
interface Props {
  userToken: string | null;
  study_id?: string | null;
  payload: IConsumptionAndEquipment;
}

export interface IConsumptionAndEquipment {
  source_energie?: (string | null)[];
  electric_installation_type?: string | null;
  subscribed_power?: number | null;
  tarification_option?: string | null;
  annual_consumption_in_euros?: number | null;
  annual_consumption_in_kwh?: number | null;
  electricite_principaux_equipments?: (string | null)[];
  electricite_surface_chauffee?: number | null;
  electricite_temperature_confort?: number | null;
  electricite_type_chauffage?: (string | null)[];
  electricite_nombre_emetteur?: number | null;
  electricite_type_emetteur?: (string | null)[];
  electricite_type_ballon_eau_chaude?: (string | null)[];
  electricite_storage_capacity?: number | null;
  electricite_installation_year?: string | null;
  electricite_chauffage_euros?: number | null;
  electricite_eclairage_euros?: number | null;
  electricite_ecs_euros?: number | null;
  electricite_autres_euros?: number | null;
  electricite_piscine_euros?: number | null;
  electricite_borne_de_recharge_euros?: number | null;
  electricite_climatisation_euros?: number | null;
  electricity_price_per_kwh?: number | null;
  electricite_pac_aireau_installation_year?: number | null;
  electricite_pac_airair_installation_year?: number | null;
  gaz_consommation_euros?: number | null;
  gaz_consommation_kwh?: number | null;
  gaz_principaux_equipments?: (string | null)[];
  gaz_surface_chauffee?: number | null;
  gaz_temperature_confort?: number | null;
  gaz_type_chauffage?: (string | null)[];
  gaz_nombre_emetteur?: number | null;
  gaz_type_emetteur?: (string | null)[];
  gaz_storage_capacity?: number | null;
  gaz_chauffage_euros?: number | null;
  gaz_ecs_euros?: number | null;
  gaz_autres_euros?: number | null;
  gaz_price_per_kwh?: number | null;
  is_gaz_ecs_integrated_to_heating_system?: boolean | null;
  bois_consommation_euros?: number | null;
  bois_surface_chauffee?: number | null;
  bois_temperature_confort?: number | null;
  bois_type_chauffage?: (string | null)[];
  bois_nombre_emetteur?: number | null;
  bois_type_emetteur?: (string | null)[];
  gpl_consommation_euros?: number | null;
  gpl_principaux_equipments?: (string | null)[];
  gpl_surface_chauffee?: number | null;
  gpl_temperature_confort?: number | null;
  gpl_type_chauffage?: (string | null)[];
  gpl_nombre_emetteur?: number | null;
  gpl_type_emetteur?: (string | null)[];
  gpl_storage_capacity?: number | null;
  gpl_chauffage_euros?: number | null;
  gpl_ecs_euros?: number | null;
  fioul_consommation_euros?: number | null;
  fioul_principaux_equipments?: (string | null)[];
  fioul_surface_chauffee?: number | null;
  fioul_temperature_confort?: number | null;
  fioul_nombre_emetteur?: number | null;
  fioul_type_emetteur?: (string | null)[];
  fioul_storage_capacity?: number | null;
  fioul_chauffage_euros?: number | null;
  fioul_ecs_euros?: number | null;
  fioul_type_chauffage?: (string | null)[];
}

export const useSaveConsumptionAndEquipment = () => {
  const {
    isLoading,
    errorMessage: axiosErrorMessage,
    axiosPutRequest,
  } = useAxiosPut();

  const [errorMessageSaveConso, setErrorMessageSaveConso] =
    useState<string>("");

  const saveConsumptionAndEquipment = async ({
    userToken,
    study_id,
    payload,
  }: Props) => {
    setErrorMessageSaveConso("");
    try {
      const response = await axiosPutRequest<{ message: string }>(
        `${process.env.REACT_APP_EERO_URL}/oree2/rg/saveConsumptionAndEquipment/${study_id}`,
        payload,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      setErrorMessageSaveConso("An error occurred while saving datas");
    }
  };
  return {
    saveConsumptionAndEquipment,
    isLoadingSaveConso: isLoading,
    errorMessageSaveConso: axiosErrorMessage || errorMessageSaveConso,
  };
};
