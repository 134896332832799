import "./ProfitabilityTabBlock.scss";

// Libraries import
import React, { ReactNode, useRef } from "react";

// Images import
import chevronDown from "../../../assets/images/chevron-down.svg";

// Local interface declaration
interface Props {
  children?: ReactNode;
  title: string;
  isOpen?: boolean;
  onClick: () => void;
  color?: string;
}

export const ProfitabilityTabBlock: React.FC<Props> = ({
  title,
  isOpen = false,
  color = "",
  onClick,
}) => {
  const nodeRef = useRef<HTMLImageElement>(null);

  return (
    <div
      className={`ProfitabilityTabBlock ${
        isOpen ? "ProfitabilityTabBlock__tabIsOpen" : ""
      } ${color}`}
      onClick={onClick}
    >
      <div className="ProfitabilityTabBlock__Header">
        <div className="ProfitabilityTabBlock__Title">
          <h4>{title}</h4>
        </div>
      </div>

      <div className="ProfitabilityTabBlock__BottomArrow">
        <img
          src={chevronDown}
          alt="arrow-down"
          ref={nodeRef}
          className={`arrow-image ${
            isOpen ? "arrow-active" : "arrow-unactive"
          }`}
        />
      </div>
    </div>
  );
};
