import { AnyAction } from "redux";

// Interfaces import
import { IAppStates } from "../../interfaces/generalInterfaces";

// Other imports
import * as actions from "../appActions";
import { initialState } from "../initialState";

// SHARED REDUCER FUNCTIONS USED ACCROSS THE WHOLE APP
export const setUserAuthInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setUserAuthInfoAction.match(action)) {
    return {
      ...state,
      userAuthInfo: action.payload.userAuthInfo,
    };
  }
  return state;
};

export const setStudyInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setStudyInfoAction.match(action)) {
    if (action.payload.studyInformation === null) {
      return {
        ...state,
        studyInformation: {},
      };
    }
    return {
      ...state,
      studyInformation: {
        ...state.studyInformation,
        ...action.payload.studyInformation,
      },
    };
  }
  return state;
};

export const setCommercialUserInformation = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setCommercialUserInfoAction.match(action)) {
    return {
      ...state,
      commercialUserInformation: action.payload.commercialUserInformation,
    };
  }
  return state;
};

export const setCalepinagesInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setMesScenariosInfoAction.match(action)) {
    return {
      ...state,
      monInstallation: {
        ...state.monInstallation,
        mesScenarios: action.payload.mesScenarios,
      },
    };
  }
  return state;
};

export const setIndexScenariosOptionChosen = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setIndexScenariosOptionChosenAction.match(action)) {
    return {
      ...state,
      monInstallation: {
        ...state.monInstallation,
        indexOptionChosen: action.payload.indexOptionChosen,
      },
    };
  }

  return state;
};

export const setPersonalizedScenario = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setPersonalizedScenarioAction.match(action)) {
    return {
      ...state,
      monInstallation: {
        ...state.monInstallation,
        personalizedScenario: action.payload.personalizedScenario,
      },
    };
  }

  return state;
};

export const setIsPersonalizedScenario = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setIsPersonalizedScenarioAction.match(action)) {
    return {
      ...state,
      monInstallation: {
        ...state.monInstallation,
        isPersonalizedScenario: action.payload.isPersonalizedScenario,
      },
    };
  }

  return state;
};

export const setDimensionnement = (state: IAppStates, action: AnyAction) => {
  if (actions.setDimensionnementAction.match(action)) {
    return {
      ...state,
      monInstallation: {
        ...state.monInstallation,
        dimensionnement: {
          ...state.monInstallation.dimensionnement,
          ...action.payload.dimensionnement,
        },
      },
    };
  }
  return state;
};

export const setPerformance = (state: IAppStates, action: AnyAction) => {
  if (actions.setPerformanceAction.match(action)) {
    return {
      ...state,
      monInstallation: {
        ...state.monInstallation,
        performance: action.payload.performance,
      },
    };
  }
  return state;
};

export const setProfitability = (state: IAppStates, action: AnyAction) => {
  if (actions.setProfitabilityAction.match(action)) {
    return {
      ...state,
      monInstallation: {
        ...state.monInstallation,
        profitability: action.payload.profitability,
      },
    };
  }
  return state;
};

export const setImpactEnvironnemental = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setImpactEnvironnementalAction.match(action)) {
    return {
      ...state,
      monInstallation: {
        ...state.monInstallation,
        impactEnvironnemental: action.payload.impactEnvironnemental,
      },
    };
  }
  return state;
};

export const setSAGEItemsInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setSAGEItemsInfoAction.match(action)) {
    // If we don't have sage items info we reset the sage items info
    if (action.payload.SAGEItemsInfo.length === 0) {
      return {
        ...state,
        SAGEItemsInfo: [],
      };
    }

    // Case we want to flush the previous items
    if (action.payload.isFlushed) {
      return {
        ...state,
        SAGEItemsInfo: action.payload.SAGEItemsInfo,
      };
    }

    // Case we want to add new items without deleting the previous ones
    const newSAGEItemsInfo = [...state.SAGEItemsInfo];
    action.payload.SAGEItemsInfo.forEach((newItem) => {
      // Some items have their reference in the NO_RefDet field
      if (newItem.NO_RefDet) {
        if (
          !newSAGEItemsInfo
            .map((item) => item.NO_RefDet)
            .includes(newItem.NO_RefDet)
        ) {
          newSAGEItemsInfo.push(newItem);
        }
        return {
          ...state,
          SAGEItemsInfo: newSAGEItemsInfo,
        };
      }

      // Others have it in the AR_Ref field
      if (
        !newSAGEItemsInfo.map((item) => item.AR_Ref).includes(newItem.AR_Ref)
      ) {
        newSAGEItemsInfo.push(newItem);
      }
    });
    return {
      ...state,
      SAGEItemsInfo: newSAGEItemsInfo,
    };
  }
  return state;
};

export const resetAllStudyInformation = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.resetAllStudyInformationAction.match(action)) {
    return {
      ...state,
      monInstallation: initialState.monInstallation,
      studyInformation: initialState.studyInformation,
      SAGEItemsInfo: initialState.SAGEItemsInfo,
      scenarioChosen: initialState.scenarioChosen,
      cartProducts: initialState.cartProducts,
      solarPotentialStepsStatus: initialState.solarPotentialStepsStatus,
      RGProjectKPI: initialState.RGProjectKPI,
    };
  }
  return state;
};

export const setInitalState = () => {
  return initialState;
};

export const setScenarioChosenInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setScenarioChosenAction.match(action)) {
    return {
      ...state,
      scenarioChosen: {
        ...state.scenarioChosen,
        ...action.payload.scenarioChosen,
      },
    };
  }
  return state;
};

export const setCartProducts = (state: IAppStates, action: AnyAction) => {
  if (actions.setCartProductsAction.match(action)) {
    return {
      ...state,
      cartProducts: action.payload.cartProducts,
    };
  }
  return state;
};

export const setRGProjectKPI = (state: IAppStates, action: AnyAction) => {
  if (actions.setRGProjectKPIAction.match(action)) {
    return {
      ...state,
      RGProjectKPI: action.payload.projectKPI,
    };
  }
  return state;
};

export const setSolarPotentialStepsStatus = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setSolarPotentialStepsStatusAction.match(action)) {
    return {
      ...state,
      solarPotentialStepsStatus: {
        ...state.solarPotentialStepsStatus,
        ...action.payload.solarPotentialStepsStatus,
      },
    };
  }
  return state;
};
