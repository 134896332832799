// Libraries import
import React from "react";
import styled from "styled-components";

// Styles import
import { stylesVariables } from "../../styles/variables";

const Container = styled.div`
  padding-top: 50px;
  padding-bottom: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: ${stylesVariables.mediumWindow}) {
    padding-top: 0;
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${stylesVariables.OffBlack};
  max-width: 300px;
  font-family: "PulpDisplay";
  @media (min-width: ${stylesVariables.mediumWindow}) {
    max-width: none;
  }
  h1 {
    font-size: 34px;
    font-family: PulpDisplay;
    font-weight: 700;
    margin-bottom: 2rem;

    @media (min-width: ${stylesVariables.mediumWindow}) {
      font-size: 34px;
    }
  }

  p {
    text-align: center;
    @media (min-width: ${stylesVariables.mediumWindow}) {
      font-size: 16px;
    }
  }
`;

// Interfaces declaration
export interface AuthenticationWelcomeProps {
  title: string;
  subTitle: string;
}

export const AuthenticationWelcome: React.FC<AuthenticationWelcomeProps> = ({
  title = "My title",
  subTitle = "My subtitle",
}) => {
  return (
    <Container>
      <Message>
        <h1>{title}</h1>
        <p>{subTitle}</p>
      </Message>
    </Container>
  );
};
