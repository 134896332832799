import { StyleSheet } from "@react-pdf/renderer";

export const PdfDevisRGStyle = StyleSheet.create({
  page: {
    color: "black",
    position: "relative",
  },
  pagePadding: {
    position: "relative",
    paddingBottom: 50,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  containerInfos: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    flexWrap: "wrap",
  },
  columnInfos: {
    width: 155,
  },
  containerTable: {
    marginTop: 20,
    color: "#525360",
    fontSize: 8,
    fontFamily: "DMSansRegular",
  },
  firstAbscisse: {
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    marginBottom: 10,
  },
  itemAbscisse: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5,
  },
  itemHelpContainer: {
    marginBottom: 10,
  },
  itemHelp: {
    display: "flex",
    flexDirection: "row",
  },
  itemAbscisseAidePrime: {
    color: "#08B77F",
  },

  AidePrime: {
    color: "#08B77F",
    fontFamily: "DMSansBold",
    marginLeft: 20,
  },
  ExtraCharge: {
    color: "#A8AAAF",
    fontFamily: "DMSansBold",
  },
  itemAbscisseExtraCharge: {
    color: "#A8AAAF",
  },
  descriptionProduits: {
    width: "36%",
  },
  descriptionAides: {
    width: "43%",
  },
  modeVersementAides: {
    width: "43%",
    textAlign: "center",
  },
  montantAides: {
    width: "14%",
    textAlign: "right",
  },
  quantite: {
    width: "13%",
    textAlign: "center",
  },
  prixUnitaireHT: {
    width: "21%",
    textAlign: "center",
  },
  tva: {
    width: "11%",
    textAlign: "center",
  },
  montantTTC: {
    width: "19%",
    textAlign: "right",
  },
  itemDescriptionProduits: {
    fontFamily: "DMSansBold",
  },
  itemDetailDescriptionProduits: {
    marginLeft: 10,
    marginTop: 2,
  },
  itemQuantite: {
    fontFamily: "DMSansBold",
  },
  itemPrixUnitaireHT: {},
  itemTva: {
    fontFamily: "DMSansBold",
  },
  itemMontantTTC: {
    fontFamily: "DMSansBold",
  },
  containerTotalRight: {
    alignSelf: "flex-end",
    width: "50%",
    borderTop: "2px solid #525360",
    padding: "10 0 0 4",
    position: "relative",
  },
  totalItem: {
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    justifyContent: "space-between",
    fontFamily: "DMSansRegular",
    fontSize: 8,
    padding: "4 6",
  },
  totalItemBonusBorder: {
    border: "2px solid #08B77F",
    borderRadius: 4,
    padding: "2 4",
    marginBottom: 5,
  },
  totalItemBonusBackground: {
    backgroundColor: "#08B77F",
    borderRadius: 4,
    padding: "4 6",
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    justifyContent: "space-between",
    fontFamily: "DMSansRegular",
    fontSize: 8,
  },

  totalItemTitle: {
    color: "#525360",
  },
  totalItemNumber: {
    color: "#525360",
    fontFamily: "DMSansBold",
  },
  totalItemTitleBonus: {
    color: "#08B77F",
  },
  totalItemNumberBonus: {
    color: "#08B77F",
    fontFamily: "DMSansBold",
  },
  totalItemTitleRestant: {
    color: "white",
  },
  totalItemNumberRestant: {
    color: "white",
    fontFamily: "DMSansBold",
  },

  containerTotal: {
    display: "flex",
    flexDirection: "row",
  },
  containerDelay: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
    justifyContent: "flex-start",
    fontSize: 8,
    fontFamily: "DMSansRegular",
    color: "#525360",
  },
  delayDate: {
    fontFamily: "DMSansBold",
  },
  textUnderline: {
    display: "flex",
    alignItems: "flex-start",
    textDecoration: "underline",
  },
  containerPagination: {
    position: "absolute",
    bottom: 15,
    right: 15,
  },
  preventInfo: {
    fontFamily: "DMSansRegular",
    fontSize: 8,
    color: "#525360",
    margin: "0 auto 20",
  },
  preventInfoBlack: {
    fontFamily: "DMSansRegular",
    fontSize: 8,
    color: "black",
    margin: "0 auto 20",
    paddingLeft: 10,
  },
  retractationDestinataireCText: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "DMSansRegular",
    fontSize: 8,
    color: "#525360",
  },
  retractationDestinataireCBox: {
    display: "flex",
    fontFamily: "DMSansRegular",
    fontSize: 8,
    color: "#525360",
    backgroundColor: "#f7f7f8",
    borderRadius: 15,
    padding: 15,
    margin: "15 0",
  },
  retractationDestinataireTextBox: {
    marginBottom: 10,
  },
  inlineComponents: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5,
  },
  columnComponents: {
    display: "flex",
    flexDirection: "column",
  },
  fontBold: {
    fontFamily: "DMSansBold",
  },
  RGEligibleContainer: {
    border: "1px solid #525360",
    padding: 15,
    fontSize: 10,
    color: "#525360",
    lineHeight: 1.5,
    fontFamily: "DMSansRegular",
  },
  RGEligibleTitle: {
    fontFamily: "DMSansBold",
    marginBottom: 10,
    fontSize: 12,
  },
  RGEligibleSubTitle: {
    fontFamily: "DMSansBold",
    marginBottom: 10,
    fontSize: 10,
  },
  textBold: {
    fontFamily: "DMSansBold",
  },
  RGEligibleParagraphe: {
    marginBottom: 5,
  },
  RGEligibleMarginLeft: {
    marginLeft: 5,
  },
  prochainesEtapes__containerCode: {
    marginTop: 25,
    backgroundColor: "#D1F3F2",
    display: "flex",
    flexDirection: "row",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    gap: 30,
  },
  prochainesEtapes__containerCodeLeft: {
    flex: 1.3,
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  prochainesEtapes__containerCodeTextOne: {
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: "DMSansBold",
  },
  prochainesEtapes__containerCodeTextTwo: {
    fontSize: 9,
    fontFamily: "DMSansRegular",
  },
  prochainesEtapes__containerCodeTextThree: {
    fontWeight: "bold",
    fontSize: 9,
    fontFamily: "DMSansBold",
  },
  prochainesEtapes__containerCodeRight: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  prochainesEtapes__containerCodeRightBorder: {
    border: "1px dashed #50545f",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    borderRadius: 5,
    marginLeft: 10,
  },
  prochainesEtapes__containerCodeTextRight: {
    fontSize: 9,
  },
  prochainesEtapes__containerCodeRightCode: {
    fontSize: 9,
    color: "#08B77F",
  },
});
