import pictoTuile from "@web/shared/dist/assets/images/DesignSystemIllustrations/Tuiles-canal-single.svg";
import pictoTuilePlate from "@web/shared/dist/assets/images/DesignSystemIllustrations/Tuiles_plates.svg";
import pictoTuileMecanique from "@web/shared/dist/assets/images/DesignSystemIllustrations/Tuiles-mécaniques.svg";
import pictoArdoise from "@web/shared/dist/assets/images/DesignSystemIllustrations/Tuiles ardoise.svg";
import pictoToleOndulee from "@web/shared/dist/assets/images/DesignSystemIllustrations/Tole ondulée.svg";
import pictoToleTrapezoidal from "@web/shared/dist/assets/images/DesignSystemIllustrations/Tole trapezoidale.svg";
import pictoToleJointDeboout from "@web/shared/dist/assets/images/DesignSystemIllustrations/Tole joint debout.svg";

export const roofMaterialOptions = [
  {
    label: "Tuile",
    value: "Tuile",
    imgSrc: pictoTuile,
    imageAlt: "Picto tuile",
  },
  {
    label: "Ardoise",
    value: "Ardoise",
    imgSrc: pictoArdoise,
    imageAlt: "Picto ardoise",
  },
  {
    label: "Tôle",
    value: "Tôle",
    imgSrc: pictoToleTrapezoidal,
    imageAlt: "Picto tôle",
  },
  {
    label: "Fibrociment",
    value: "Fibrociment",
    imgSrc: pictoToleOndulee,
    imageAlt: "Picto fibrociment",
  },
];

export const typeTuilesOptions = [
  {
    label: "Tuiles canal",
    value: "Tuiles canal",
    imgSrc: pictoTuile,
    imageAlt: "Picto tuile canal",
  },
  {
    label: "Tuiles mécaniques",
    value: "Tuiles mécaniques",
    imgSrc: pictoTuileMecanique,
    imageAlt: "Picto tuile mécanique",
  },
  {
    label: "Tuiles plates",
    value: "Tuiles plates",
    imgSrc: pictoTuilePlate,
    imageAlt: "Picto tuile plate",
  },
];

export const typeTolesOptions = [
  {
    label: "Tôle à joint debout",
    imgSrc: pictoToleJointDeboout,
    value: "Tôle à joint debout",
  },
  {
    label: "Tôle trapézoïdale",
    imgSrc: pictoToleTrapezoidal,
    value: "Tôle trapézoïdale",
  },
  { label: "Tôle ondulée", imgSrc: pictoToleOndulee, value: "Tôle ondulée" },
];

export const typeMaterialOptions = [
  { type: "Tuile", options: typeTuilesOptions },
  { type: "Tôle", options: typeTolesOptions },
];

export const expositionOptions = [
  { label: "Nord", value: "Nord" },
  { label: "Nord-Est", value: "Nord-Est" },
  { label: "Est", value: "Est" },
  { label: "Sud-Est", value: "Sud-Est" },
  { label: "Sud", value: "Sud" },
  { label: "Sud-Ouest", value: "Sud-Ouest" },
  { label: "Ouest", value: "Ouest" },
  { label: "Nord-Ouest", value: "Nord-Ouest" },
];

export const masqueSolaireOptions = [
  { label: "0%", value: "0" },
  { label: "5% - 10%", value: "7.5" },
  { label: "10 - 20%", value: "15" },
  { label: "20 - 30%", value: "25" },
];

//function to find the label given a value
export const findLabel = (value: string | number) => {
  const option = masqueSolaireOptions.find((option) => option.value === value);
  return option ? option.label : "";
};
