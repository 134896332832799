//Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";
import { IAvailableProducts } from "../../../interfaces/renoglobaleInterface";

//Local interface
interface LocalProps {
  userToken: string | null;
  study_id?: string | null;
  category: string;
}

export const useGetProductsFromCategory = () => {
  const { isLoading, axiosGetRequest } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getProductsFromCategory = async ({
    userToken,
    study_id,
    category,
  }: LocalProps) => {
    setErrorMessage("");

    const response = await axiosGetRequest<IAvailableProducts>(
      `${process.env.REACT_APP_EERO_URL}/oree2/rg/available_products_from_category/${study_id}?productCategory=${category}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) return response.available_products;

    setErrorMessage(
      "Une erreur est survenue lors de la récupération du catalogue produit"
    );
  };

  return {
    getProductsFromCategory,
    isLoading,
    errorMessage: errorMessage,
  };
};
