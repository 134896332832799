//Libraries import
import { useState } from "react";
import { useAxiosPut } from "../useAxiosPut";
import { AxiosResponse } from "axios";

// Interfaces import
import { IStudy, IStudyInformation } from "../../interfaces/generalInterfaces";

// Local interface declaration
interface IProps {
  studyInformation: IStudyInformation;
  userToken: string | null;
}

interface ICustomResponse extends AxiosResponse {
  study: IStudy;
  message: string;
  token: string;
}

export const useUpdateStudy = () => {
  const {
    axiosPutRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPut();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const updateStudy = async ({
    studyInformation,
    userToken,
  }: IProps): Promise<AxiosResponse<ICustomResponse> | void> => {
    setErrorMessage("");

    if (!studyInformation.study_id || !userToken) {
      setErrorMessage("Paramètre manquant.");
      return;
    }

    const response = await axiosPutRequest<ICustomResponse>(
      `${process.env.REACT_APP_EERO_URL}/oree2/pv/updateStudy`,
      studyInformation,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );

    if (!response) {
      setErrorMessage("Impossible de mettre à jour l'étude.");
      return;
    }
    return response;
  };

  return {
    updateStudy,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
