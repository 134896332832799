import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfSubTitleProduct } from "../../components/Pdf/Pdf-Devis/PdfSubTitleProduct/PdfSubTitleProduct";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
  ISageProduct,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  PACAirEauProduct: IDataProduct | undefined;
  PACAirEauSageProduct: ISageProduct | undefined;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  commercialUserInformation: ICommercialUserInformation;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
}

export const PdfPACAirEauPage = ({
  documentId,
  PACAirEauProduct,
  PACAirEauSageProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
  devisOptions,
}: IProps) => {
  const {
    extraChargeWithoutTax: PACAirEauExtraChargeWithoutTax,
    totalPriceWhithoutTax: PACAirEauTotalPriceWhithoutTax,
    totalTVA: PACAirEauTotalTVA,
    totalPriceWithTax: PACAirEauTotalPriceWithTax,
    totalHelp: PACAirEauTotalHelp,
    totalResteACharge: PACAirEauTotalResteACharge,
    extraChargeWithTax: PACAirEauExtraChargeWithTax,
    installationPriceWithoutTax: PACAirEauInstallationPriceWithoutTax,
    installationPriceWithTax: PACAirEauInstallationPriceWithTax,
    managementFeesTTC: PACmanagementFeesTTC,
    managementFeesHT: PacManagementFeesHT,
    totalDeductibleHelps: PACAirEauTotalDeductibleHelps,
    totalRefundableHelps: PACAirEauTotalRefundableHelps,
    productPriceWhithTaxe: PACAirEauProductPriceWithTax,
    productPriceWhithoutTaxe: PACAirEauProductPriceWithoutTax,
  } = productPrices({
    product: PACAirEauProduct,
    isEligibleRenoGlobal,
    devisOptions,
  });
  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              PACAirEauProduct?.product_alias ||
              PACAirEauProduct?.product_name ||
              "Produit NC"
            }
          />
          <PdfSubTitleProduct
            title={
              "Référence fabricant : " +
                PACAirEauSageProduct?.["Référence fabricant"] || "NC"
            }
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={1}
              prixUnitaire={PACAirEauProductPriceWithoutTax || 0}
              TVA={PACAirEauProduct?.TVA_percentage || 0}
              montantTTC={PACAirEauProductPriceWithTax || 0}
            />

            {/* PACAirEau */}
            {PACAirEauSageProduct ? (
              <PdfItemDetail
                key={PACAirEauSageProduct.AR_Ref}
                title={
                  (PACAirEauProduct?.product_alias ||
                    PACAirEauProduct?.product_name) &&
                  PACAirEauSageProduct.NO_Qte
                    ? PACAirEauSageProduct.NO_Qte +
                      " " +
                      (PACAirEauProduct?.product_alias ||
                        PACAirEauProduct?.product_name)
                    : ""
                }
                description={[
                  <>
                    {PACAirEauProduct?.product_name && (
                      <Text>{PACAirEauProduct?.product_name}</Text>
                    )}
                  </>,
                  <>
                    {PACAirEauProduct?.product_characteristics?.power && (
                      <Text>{`Puissance : ${PACAirEauProduct?.product_characteristics.power}`}</Text>
                    )}
                  </>,
                  <>
                    {PACAirEauSageProduct.Alimentation &&
                      PACAirEauSageProduct.Alimentation.length > 0 && (
                        <Text>{`Alimentation : ${PACAirEauSageProduct.Alimentation}`}</Text>
                      )}
                  </>,
                  <>
                    {PACAirEauSageProduct.Dimensions && (
                      <Text>
                        {PACAirEauSageProduct.Dimensions &&
                        PACAirEauSageProduct.Dimensions.length > 0
                          ? `Taille : ${PACAirEauSageProduct.Dimensions}`
                          : null}{" "}
                      </Text>
                    )}
                  </>,
                  <>
                    {PACAirEauSageProduct["Ref fluide frigorigène"] && (
                      <Text>
                        {PACAirEauSageProduct["Ref fluide frigorigène"].length >
                        0
                          ? `Référence fluide frigorigène : ${PACAirEauSageProduct["Ref fluide frigorigène"]}`
                          : null}
                      </Text>
                    )}
                  </>,
                  <>
                    {PACAirEauSageProduct["Profil de soutirage"] && (
                      <Text>
                        {PACAirEauSageProduct["Profil de soutirage"].length > 0
                          ? `Profil de soutirage : ${PACAirEauSageProduct["Profil de soutirage"]}`
                          : null}
                      </Text>
                    )}
                  </>,
                  <>
                    {PACAirEauSageProduct["Norme et Certification"] &&
                      PACAirEauSageProduct["Norme et Certification"]?.length >
                        0 && (
                        <Text>
                          {PACAirEauSageProduct["Norme et Certification"]
                            ? `Normes et certifications : ${PACAirEauSageProduct["Norme et Certification"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {PACAirEauSageProduct["Classe energétique (Froid/Chaud"] &&
                      PACAirEauSageProduct["Classe energétique (Froid/Chaud"]
                        ?.length > 0 && (
                        <Text>
                          {PACAirEauSageProduct[
                            "Classe energétique (Froid/Chaud"
                          ]
                            ? `Classe énergétique : ${PACAirEauSageProduct["Classe energétique (Froid/Chaud"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {PACAirEauProduct?.product_characteristics?.scop && (
                      <Text>
                        {`COP : ${PACAirEauProduct?.product_characteristics?.scop}`}
                      </Text>
                    )}
                  </>,
                  <>
                    {PACAirEauProduct?.product_characteristics?.etas && (
                      <Text>
                        {`Etas : ${PACAirEauProduct?.product_characteristics?.etas}`}
                      </Text>
                    )}
                  </>,

                  <>
                    {PACAirEauSageProduct["Garantie Fabricant"] &&
                      PACAirEauSageProduct["Garantie Fabricant"]?.length >
                        0 && (
                        <Text>
                          {PACAirEauSageProduct["Garantie Fabricant"]
                            ? `Garantie Fabriquant : ${PACAirEauSageProduct["Garantie Fabricant"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {PACAirEauSageProduct["Garantie main d'oeuvre"] &&
                      PACAirEauSageProduct["Garantie main d'oeuvre"]?.length >
                        0 && (
                        <Text>
                          {PACAirEauSageProduct["Garantie main d'oeuvre"]
                            ? `Garantie main d'oeuvre : ${PACAirEauSageProduct["Garantie main d'oeuvre"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    <Text>{`Régulateur de classe IV, V, VI, VII, ou VIII`}</Text>
                  </>,
                ]}
              />
            ) : null}
            <View wrap={false}>
              <PdfRowBackgroundProduit
                name="Forfait installation"
                quantity={1}
                prixUnitaire={PACAirEauInstallationPriceWithoutTax}
                TVA={PACAirEauProduct?.TVA_percentage || 0}
                montantTTC={PACAirEauInstallationPriceWithTax}
              />
              <View style={styles.inlineComponents}>
                <Text>Déplacement</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Dépose du matériel existant (mais n’inclut pas l’enlèvement et
                  l’évacuation de celui-ci)
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Mise en place d'une pompe à chaleur air/eau en remplacement
                  d'un système existant
                </Text>
              </View>
              <View style={[styles.columnComponents, { paddingLeft: 10 }]}>
                <Text>• Mise en place de l' /des unité(s)</Text>
                <Text>
                  • Installation des liaisons frigorifiques électriques
                </Text>
                <Text>
                  • Adaptation de la plomberie existante pour raccorder la
                  nouvelle installation
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text style={{ marginTop: 10 }}>
                  Fourniture des équipements nécessaires à la pose des matériels
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Mise en service de l’installation</Text>
              </View>
            </View>
            {(PACAirEauProduct?.extra_charges_added?.length || 0) > 0 && (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Spécificité du chantier"
                  quantity={PACAirEauProduct?.extra_charges_added?.length}
                  prixUnitaire={PACAirEauExtraChargeWithoutTax || 0}
                  TVA={PACAirEauProduct?.TVA_percentage || 0}
                  montantTTC={PACAirEauExtraChargeWithTax || 0}
                />
                {PACAirEauProduct?.extra_charges_added?.map((extraCharge) => {
                  return (
                    <PdfRowProduit
                      key={extraCharge.product_name}
                      name={extraCharge.product_name}
                      quantity={1}
                      prixUnitaire={extraCharge.price_without_tax}
                      TVA={extraCharge.TVA_percentage}
                      montantTTC={extraCharge.price_with_tax}
                      description={extraCharge.description}
                    />
                  );
                })}
              </View>
            )}
            <View wrap={false}>
              {PACmanagementFeesTTC !== 0 ? (
                <>
                  <PdfRowBackgroundProduit
                    name="Frais de gestion"
                    quantity={1}
                    prixUnitaire={PacManagementFeesHT}
                    TVA={PACAirEauProduct?.installation_tva_percentage}
                    montantTTC={PACmanagementFeesTTC}
                  />
                  <View style={styles.inlineComponents}>
                    <Text>
                      Prise en charge des démarches administratives et gestion
                      de projet/SAV
                    </Text>
                  </View>
                  <View style={styles.inlineComponents}>
                    <Text style={{ marginBottom: 10 }}>
                      Constitution des dossiers d’aides financières
                      éventuellement liées au projet
                    </Text>
                  </View>

                  <PdfRowBackgroundProduit
                    name="Frais de gestion offerts"
                    montantTTC={-PACmanagementFeesTTC}
                  />
                </>
              ) : null}
            </View>
            {PACAirEauTotalHelp && devisOptions.isHelpsDisplayed ? (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Aides"
                  aides={PACAirEauProduct?.help
                    .filter((help) => help.is_eligible)
                    .map((help) => {
                      return {
                        aide: help.name,
                        montant: help.value,
                        isDeducted:
                          help.help_type === "mpr"
                            ? devisOptions.isMPRHelpsDeducted
                            : help.help_type === "cee"
                            ? devisOptions.isCEEHelpsDeducted
                            : false,
                      };
                    })}
                />
              </View>
            ) : null}
            <PdfTotalContainer
              devisOptions={devisOptions}
              totalHT={PACAirEauTotalPriceWhithoutTax}
              totalTVA={PACAirEauTotalTVA}
              totalTTC={PACAirEauTotalPriceWithTax}
              totalHelp={PACAirEauTotalHelp}
              resteACharge={PACAirEauTotalResteACharge}
              deductibleHelp={PACAirEauTotalDeductibleHelps}
              refundableHelp={PACAirEauTotalRefundableHelps}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
