import { Text, StyleSheet, Font, View, Image } from "@react-pdf/renderer";

// Fonts
import DMSansMedium from "../../../../assets/pdf/fonts/DMSansMedium.ttf";

// Register font
Font.register({
  family: "DMSansMedium",
  src: DMSansMedium,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "DMSansMedium",
    fontSize: 14,
    marginBottom: 10,
    backgroundColor: "#f3f8fc",
    padding: "5 0 5 58",
    marginRight: 58,
  },
  container__image: {
    width: 20,
    objectFit: "contain",
    marginRight: 5,
  },
});

interface Props {
  text: string;
  logo: string;
}

export const PdfThirdTitle = ({ text, logo }: Props) => {
  return (
    <View style={styles.container}>
      <Image style={styles.container__image} src={logo} />
      <Text>{text}</Text>
    </View>
  );
};
