//Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

//Local interface

export const useGetSingleFileFromABS = () => {
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getSingleFileFromABS = async (
    userToken: string | null,
    document_id: string | null,
    study_id: string | null
  ): Promise<string | void> => {
    setErrorMessage("");

    // Returns the dataURL of the file
    const response = await axiosGetRequest<string>(
      `${process.env.REACT_APP_EERO_URL}/oree2/azureBlobStorage/getDevis/${study_id}/${document_id}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (!response) {
      setErrorMessage("Impossible d'envoyer les données du pdf.");
      return;
    }

    return response;
  };

  return {
    getSingleFileFromABS,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
