// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";

// Import services
import { useSetUserAuthInfo } from "./useSetUserAuthInfo";

// Interfaces import
import {
  ILoginRequestResponse,
  ILoginUserBody,
} from "../../interfaces/generalInterfaces";

// Local interfaces delcaration
interface IFunctionReturn {
  requestLogin: (email: string, password: string) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

// useLoginUser : return tools to send login request and handle error messages
export const useLoginUser = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { setUserAuthInfo } = useSetUserAuthInfo();

  // requestLogin : send login Post request and save user authentication info in redux store and in local storage if successfull
  const requestLogin = async (
    email: string,
    password: string
  ): Promise<void> => {
    setErrorMessage("");
    if (email && password) {
      const loginUserBody: ILoginUserBody = {
        email,
        password,
        serviceAccessValue: 2001,
      };
      const response = await axiosPostRequest<ILoginRequestResponse>(
        `${BACKEND_URL}/user/login`,
        loginUserBody
      );

      response &&
        response.status === 200 &&
        setUserAuthInfo({
          userToken: response.data.token || null,
          userId: response.data.userId || null,
          companyId: response.data.companyId || null,
          crm_user_id: response.data.crm_user_id || null,
          company_name: response.data.company_name || null,
        });
    } else {
      setErrorMessage("Veuillez renseigner tous les champs.");
    }
  };

  return {
    requestLogin,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage || errorMessage,
  };
};
