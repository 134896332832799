// Libraries import
import { AxiosResponse } from "axios";
import { useState } from "react";

// services import
import { setStudyInfoAction } from "../../redux/appActions";
import { useAppDispatch } from "../../redux/store/hook";
import { useAxiosPost } from "../useAxiosPost";

// Interfaces import
import {
  IFullDataCustomer,
  IOreeStudyType,
} from "../../interfaces/generalInterfaces";

export interface CreateOree2StudyProps {
  userToken: string | null;
  study_id: string | null;
  address?: string | null;
  email: string | null;
  zipcode?: string | null;
  city?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  phonenumber?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  customer_id?: string | null;
  becqe_firstname?: string | null;
  becqe_lastname?: string | null;
  how_did_you_know_us?: string | null;
  study_type: IOreeStudyType;
  existing_household_id?: string | null;
}

export interface CreateOree2StudyResponse {
  dataCustomer: IFullDataCustomer;
  isRedirectToExistingStudy?: boolean;
  study_id?: string;
}

export type CreateOree2Study = ReturnType<
  typeof useCreateOree2Study
>["createOree2Study"];

// Create or update a study

export const useCreateOree2Study = () => {
  const dispatch = useAppDispatch();
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const createOree2Study = async ({
    userToken,
    study_id,
    firstname,
    lastname,
    email,
    phonenumber,
    address,
    zipcode,
    city,
    latitude,
    longitude,
    customer_id,
    becqe_firstname,
    becqe_lastname,
    how_did_you_know_us,
    study_type,

    existing_household_id,
  }: CreateOree2StudyProps): Promise<AxiosResponse<{
    dataCustomer: IFullDataCustomer;
    isRedirectToExistingStudy?: boolean;
    study_id?: string;
  }> | void> => {
    setErrorMessage("");

    const response = await axiosPostRequest<CreateOree2StudyResponse>(
      `${process.env.REACT_APP_EERO_URL}/oree2/study`,
      {
        study_id,
        address,
        email,
        firstname,
        lastname,
        phonenumber,
        city,
        zipcode,
        latitude,
        longitude,
        customer_id,
        becqe_firstname,
        becqe_lastname,
        how_did_you_know_us,
        study_type,
        existing_household_id,
      },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

    if (response && response.data.study_id) {
      dispatch(
        setStudyInfoAction({
          studyInformation: {
            study_id: response?.data?.study_id,
            household_id: response?.data?.dataCustomer?.household_id || null,
            pdl: response?.data?.dataCustomer?.pdl || null,
            customer_id: response?.data?.dataCustomer?.user_id || null,
            firstname: response?.data?.dataCustomer?.firstname || null,
            lastname: response?.data?.dataCustomer?.lastname || null,
            email: response?.data?.dataCustomer?.email || null,
            phonenumber: response?.data?.dataCustomer?.phonenumber || null,
            city: response?.data?.dataCustomer?.city || null,
            zipcode: response?.data?.dataCustomer?.zipcode || null,
            address: response?.data?.dataCustomer?.address || null,
            latitude: response?.data?.dataCustomer?.latitude || null,
            longitude: response?.data?.dataCustomer?.longitude || null,
            becqe_firstname:
              response?.data?.dataCustomer?.becqe_firstname || null,
            becqe_lastname:
              response?.data?.dataCustomer?.becqe_lastname || null,
            becqe_email: response?.data?.dataCustomer?.becqe_email || null,
            becqe_phonenumber:
              response?.data?.dataCustomer?.becqe_phonenumber || null,
            consent: response?.data?.dataCustomer?.consent || null,
            date_consent: response?.data?.dataCustomer?.date_consent || null,
            how_did_you_know_us:
              response?.data?.dataCustomer?.how_did_you_know_us || null,
            study_type,
            membership_code:
              response?.data?.dataCustomer?.membership_code || null,
          },
        })
      );
      return response;
    }
  };

  return {
    createOree2Study,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
