import React, { useEffect, useRef } from "react";
import lottie from "lottie-web/build/player/lottie_light";
import animation from "../jsons/turning_blue_star.json";

interface IProps {
  diameter?: number;
}

export const TurningBlueStar: React.FC<IProps> = ({ diameter = 53 }) => {
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: element.current!,
      animationData: animation,
      renderer: "svg",
      loop: true,
      autoplay: false,
    });

    return () => {
      anim.destroy();
    };
  }, [element]);

  useEffect(() => {
    lottie.play();
  }, []);

  return <div style={{ width: diameter, height: diameter }} ref={element} />;
};
