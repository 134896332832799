// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Local interface declaration
interface Props {
  userToken: string | null;
  latitude?: number | null;
  longitude?: number | null;
  household_id: string | null;
  study_id: string;
}

interface ILaunchCalculusResponse {
  building_id: string;
}

export const useLaunchCalculus = () => {
  const {
    isLoading,
    errorMessage: axiosErrorMessage,
    axiosGetRequest,
  } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  // Start data backend automatic calepinage calculation
  const launchCalculus = async ({
    userToken,
    latitude,
    longitude,
    household_id,
    study_id,
  }: Props) => {
    setErrorMessage("");
    const response = await axiosGetRequest<ILaunchCalculusResponse>(
      `${process.env.REACT_APP_EERO_URL}/oree2/rg/launch_calculus/${study_id}?latitude=${latitude}&longitude=${longitude}&household_id=${household_id}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      return response;
    }
  };

  return {
    launchCalculus,
    isLoading,
    errorMessage: axiosErrorMessage || errorMessage,
  };
};
