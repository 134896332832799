import React, { ReactNode } from "react";

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  fallBack?: ReactNode;
  children: ReactNode;
}

/**
 * ErrorBoundary component uses to handle frontend errors.
 * Catch errors in its child components and display an error component.
 * Avoid crashing the app when a frontend error occurs.
 * @param fallBack The component to display when an error is caught.
 * @param children The children components to wrap with the ErrorBoundary.
 * @returns The wrapped children components or the fallback component if an error is caught.
 * C.f. https://legacy.reactjs.org/docs/error-boundaries.html
 */

// Legacy version of ErrorBoundary component but it seems to be the only one availble and is still recommended to use.
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallBack || <p>Une erreur frontend est survenue.</p>;
    }
    return this.props.children;
  }
}
