import { useAuthQuery } from "./useAuthQuery";
import eeroApi from "../eeroApiAxios";
import { IGetStudiesResponse } from "../../interfaces/generalInterfaces";

type GetStudiesErrorResponse = {
  message: string;
};

async function fetchStudiesList() {
  const response = await eeroApi.get<IGetStudiesResponse[]>(
    "/oree2/getStudies"
  );
  return response.data;
}

/**
 * Custom hook for fetching studies list from database using eero API.
 */
export function useGetStudiesListRq() {
  const query = useAuthQuery<IGetStudiesResponse[], GetStudiesErrorResponse>({
    queryKey: ["getStudiesList"],
    queryFn: fetchStudiesList,
  });

  // Priorization of error messages : first the expected request error message, then the generic error message, then default error message
  let errorMessage = "";
  if (query.isError) {
    const requestErrorMessage = query.error.response?.data?.message;

    errorMessage =
      requestErrorMessage ||
      query.genericErrorMessage ||
      "Impossible de récupérer la liste des études.";
  }

  return { ...query, errorMessage };
}
