import React, { useRef } from "react";
import "./profilePicture.scss";

export const ProfilePicture = () => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileInputClick = (
    event: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    hiddenFileInput.current?.click();
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const uploadedFile = event.target.files && event.target.files[0];
    // HandleFile here when needed
  };

  return (
    <div className="photoContainer">
      <img
        src="https://i.picsum.photos/id/353/200/200.jpg?hmac=0pI-jYBxEC3AHp_0G8YowhiQRtQdv6u1Kfvuf0c9VNQ"
        alt="random for now"
      />
      <input
        type="file"
        style={{ display: "none" }}
        onChange={(e) => handleFileInputChange(e)}
        ref={hiddenFileInput}
      />
      <p
        className="changePictureButton"
        onClick={(event) => handleFileInputClick(event)}
      >
        Changer de photo
      </p>
    </div>
  );
};
