//Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";

//Local interface
interface LocalProps {
  userToken: string | null;
  study_id?: string | null;
}

//Local interface
interface energyData {
  energy_type: string;
  ratio: number;
}

interface usageData {
  usage_type: string;
  ratio: number;
}

export interface IConsumptionsDataDonuts {
  inner_ring: energyData[];
  outer_ring: usageData[];
  total_bill: number;
}

export const useGetConsumptionDonuts = () => {
  const { isLoading, axiosGetRequest } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getConsumptionDonuts = async ({ userToken, study_id }: LocalProps) => {
    setErrorMessage("");

    const response = await axiosGetRequest<IConsumptionsDataDonuts>(
      `${process.env.REACT_APP_EERO_URL}/oree2/rg/donuts/${study_id}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) return response;

    setErrorMessage("No datas available");
    return null;
  };

  return {
    getConsumptionDonuts,
    isLoading,
    errorMessage: errorMessage,
  };
};
