import { StyleSheet, Text, View } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  itemAbscisse: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 10,
  },
  itemAbscisseNoMargeBottom: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 0,
  },
  descriptionProduits: {
    width: "36%",
  },

  quantite: {
    width: "13%",
    textAlign: "center",
  },
  prixUnitaireHT: {
    width: "21%",
    textAlign: "center",
  },
  tva: {
    width: "11%",
    textAlign: "center",
  },
  montantTTC: {
    width: "19%",
    textAlign: "right",
  },

  itemDescriptionProduits: {
    fontFamily: "DMSansBold",
  },
  itemDetailDescriptionProduits: {
    marginLeft: 10,
    marginTop: 2,
  },
  itemQuantite: {
    fontFamily: "DMSansBold",
  },
  itemPrixUnitaireHT: {},
  itemTva: {
    fontFamily: "DMSansBold",
  },
  itemMontantTTC: {
    fontFamily: "DMSansBold",
  },
  description: {
    width: "100%",
    marginTop: "-5px",
    marginBottom: "10px",
    paddingLeft: "10px",
    color: "#A8AAAF",
  },
});

interface IProps {
  name: string;
  quantity?: number;
  prixUnitaire?: number;
  TVA?: number;
  montantTTC?: number;
  textMontantTTC?: JSX.Element[];
  isMargeBottom?: boolean;
  description?: string;
}

export const PdfRowProduit = ({
  name,
  quantity,
  prixUnitaire,
  TVA,
  montantTTC,
  textMontantTTC,
  isMargeBottom = true,
  description = "",
}: IProps) => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    <>
      <View
        style={
          isMargeBottom ? styles.itemAbscisse : styles.itemAbscisseNoMargeBottom
        }
      >
        <View style={styles.descriptionProduits}>
          <Text>{name}</Text>
        </View>
        <Text style={[styles.itemQuantite, styles.quantite]}>{quantity}</Text>
        <Text style={[styles.itemPrixUnitaireHT, styles.prixUnitaireHT]}>
          {prixUnitaire && prixUnitaire + "€"}
        </Text>
        <Text style={[styles.itemTva, styles.tva]}>{TVA && TVA + "%"}</Text>
        {montantTTC && (
          <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
            {montantTTC + "€"}
          </Text>
        )}
        {textMontantTTC && (
          <View
            style={[
              styles.itemMontantTTC,
              styles.montantTTC,
              {
                fontSize: 10,
                alignSelf: "center",
              },
            ]}
          >
            {textMontantTTC.map((item, _key) => {
              return <View key={s4()}>{item}</View>;
            })}
          </View>
        )}
      </View>

      {description && (
        <View style={styles.description}>
          <Text>{description}</Text>
        </View>
      )}
    </>
  );
};
