import { Text, StyleSheet, Font, View } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../../../assets/pdf/fonts/DMSansBold.ttf";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  container: {
    fontSize: 9,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "#f8f8f8",
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
  },
  container__items: {
    display: "flex",
    flexDirection: "column",
  },
  container__items__item: {
    marginBottom: 10,
  },
});

interface Props {
  occupants: number | null | undefined;
  surface: number | null | undefined;
  chauffeEauElectrique: string | null | undefined;
  chauffageElectrique: string | null | undefined;
  autresEquipements: JSX.Element[];
}

export const PdfConsommationsElectriques = ({
  occupants,
  surface,
  chauffeEauElectrique,
  chauffageElectrique,
  autresEquipements,
}: Props) => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    <View style={styles.container}>
      <View style={styles.container__items}>
        <Text style={styles.container__items__item}>
          Nombre d'occupants :{" "}
          <Text style={{ fontFamily: "DMSansBold" }}>{occupants}</Text>
        </Text>
        <Text style={styles.container__items__item}>
          Surface du logement :{" "}
          <Text style={{ fontFamily: "DMSansBold" }}>{surface} m²</Text>
        </Text>
        <Text style={styles.container__items__item}>
          Chauffe-eau électrique :{" "}
          <Text style={{ fontFamily: "DMSansBold" }}>
            {chauffeEauElectrique}
          </Text>
        </Text>
      </View>
      <View style={styles.container__items}>
        <Text style={styles.container__items__item}>
          Chauffage électrique :{" "}
          <Text style={{ fontFamily: "DMSansBold" }}>
            {chauffageElectrique}
          </Text>
        </Text>
        <View style={styles.container__items__item}>
          <Text>Autres équipements : </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 10,
              fontFamily: "DMSansBold",
            }}
          >
            {autresEquipements.map((equipement, _key) => {
              return <Text key={s4()}>• {equipement}</Text>;
            })}
          </View>
        </View>
      </View>
    </View>
  );
};
