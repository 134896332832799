import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useDisconnectApp } from "../../services/useDisconnectApp";

// Set react query error to be AxiosError by default
declare module "@tanstack/react-query" {
  interface Register {
    defaultError: AxiosError;
  }
}

type GenericErrorMessage = {
  genericErrorMessage: string;
};

type UseMutationResultExtra<ReturnType, TVariables, ErrorResponse> =
  UseMutationResult<ReturnType, AxiosError<ErrorResponse>, TVariables> &
    GenericErrorMessage;

/**
 * Custom hook for making authenticated mutations.
 * Return the mutation result and a generic error message for some specific server error responses.
 * If the mutation returns a 401 error, it will disconnect the app.
 */
export function useAuthMutation<ReturnType, TVariables, ErrorResponse>(
  options: UseMutationOptions<ReturnType, AxiosError<ErrorResponse>, TVariables>
): UseMutationResultExtra<ReturnType, TVariables, ErrorResponse> {
  const { disconnectApp } = useDisconnectApp();

  const mutation = useMutation(options);

  let genericErrorMessage = "";
  // See Axios doccumentation on error handling : https://axios-http.com/docs/handling_errors
  if (mutation.isError) {
    if (mutation.error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      if (mutation.error.response?.status === 401) {
        genericErrorMessage = "Non autorisé";
        disconnectApp();
      }
      console.error(mutation.error.response);
    } else if (mutation.error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js

      genericErrorMessage = "Serveur injoignable.";
      console.error(mutation.error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error(mutation.error);
      genericErrorMessage = mutation.error.message || "Erreur inconnue";
    }
  }

  return { ...mutation, genericErrorMessage };
}
