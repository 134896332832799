import "./forgotPassword.scss";

// Libraries import
import React, { ReactElement, useState, KeyboardEvent } from "react";

// Components import
import { TextInput } from "@web/shared/dist/components/Old/TextInput/TextInput";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";

// Services import
import { useResetForgotPasswordSendEmail } from "../../../services/Login/useResetForgotPasswordSendEmail";

// Local interface declaration
interface Props {
  isForgotPasswordClicked: boolean;
  setIsForgotPasswordClicked: (value: boolean) => void;
}

export const ForgotPassword = ({
  setIsForgotPasswordClicked,
}: Props): ReactElement => {
  // Hook calls
  const { resetForgotPasswordSendEmail, isLoading, errorMessage } =
    useResetForgotPasswordSendEmail();
  // Local state declaration
  const [email, setEmail] = useState<string>("");
  const [isEmailRequestSuccessfull, setIsEmailRequestSuccessfull] =
    useState<boolean>(false);

  // Event handlers
  const onEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onClickBack = () => {
    setIsForgotPasswordClicked(false);
  };

  // On click on the "Réinitialiser" button, check user inputs and send reset password request.
  const onClickConnectButton = async () => {
    if (await resetForgotPasswordSendEmail(email)) {
      setIsEmailRequestSuccessfull(true);
    }
  };

  // On press on the "Enter" key, check user inputs and send password request
  const handleValidateOnPress = async (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.code === "Enter") {
      if (await resetForgotPasswordSendEmail(email)) {
        setIsEmailRequestSuccessfull(true);
      }
    }
  };

  return (
    <>
      <form className="forgot-password">
        <div className="forgot-password-inputs">
          {isLoading ? (
            <LoadingComponent diameter={60} />
          ) : isEmailRequestSuccessfull ? (
            <p className="reset-password-success">
              Email valide ! Veuillez consulter votre boite mail pour terminer
              la réinitialisation de votre mot de passe. Si vous n'avez rien
              reçu, vérifiez également vos spams.
            </p>
          ) : (
            <>
              <p>
                Veuillez renseigner l&apos;addresse mail de votre compte Becqe :
              </p>
              <TextInput
                value={email}
                onChange={onEmailInputChange}
                placeholder="Email"
                onKeyPress={handleValidateOnPress}
              />
            </>
          )}
        </div>
        <div className="forget-password-message">
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
        <div className="forgot-password-buttons">
          <button
            className="go-to-login"
            onClick={onClickBack}
            data-testid="button-go-back"
          >
            Retour
          </button>
          <CTAButton
            name="Valider"
            onClick={onClickConnectButton}
            isDisabled={isLoading || isEmailRequestSuccessfull}
            type="button"
          />
        </div>
      </form>
    </>
  );
};
