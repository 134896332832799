import { Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import { DSColors } from "@web/shared/src/styles/variables";

// Components
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfEtudeHeaderRG } from "./PdfEtudeHeaderRG";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";

// Interfaces import
import { IMonInstallation } from "../../interfaces/generalInterfaces";

// Images import
import impactEnvironnemental from "../../assets/pdf/images/impactEnvironnemental.png";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  documentId: string;
  monInstallation: IMonInstallation;
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  containerRecapitulatif: {
    height: "100%",
  },
  pageTitleContainer: {
    textAlign: "left",
    height: 30,
    marginBottom: 10,
  },
  pageTitleContent: {
    position: "relative",
    backgroundColor: DSColors.ABLight06,
    padding: 5,
  },
  pageTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },

  pageSubTitleContent: {
    display: "flex",
    flexDirection: "row",
    objectFit: "contain",
    alignItems: "center",
  },
  pageSubTitle: {
    fontSize: 12,
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },
  pageSubTitleImage: {
    width: 25,
    marginRight: 5,
  },
});

export const PdfEtudeRGPageImpactEnvironmental = ({
  firstNameInputValue,
  lastNameInputValue,
  documentId,
  monInstallation,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Page size="A4" style={styles.page}>
      <PdfEtudeHeaderRG />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View>
          {/* Page title */}
          <View style={localStyle.pageTitleContainer}>
            <View style={localStyle.pageTitleContent}>
              <Text style={localStyle.pageTitle}>IMPACT ENVIRONNEMENTAL</Text>
            </View>
          </View>
          <Text
            style={{
              fontSize: 8,
              color: DSColors.OffBlack,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Grâce à vos travaux, vous réduisez de façon notable votre empreinte
            carbone !
          </Text>
          <Text
            style={{ fontSize: 8, color: DSColors.OffBlack, marginBottom: 20 }}
          >
            À titre de comparaison, un français émet en moyenne 9 tCO2/an.
          </Text>
          {/* Page content */}
          <View
            style={{
              marginTop: 50,
            }}
          >
            <View
              style={{
                width: "80%",
                margin: "-50 auto 30",
              }}
            >
              <Image src={impactEnvironnemental} />
              <View
                style={{
                  position: "absolute",
                  top: 40,
                  left: 20,
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: "DMSansBold",
                    color: "white",
                  }}
                >
                  {monInstallation.impactEnvironnemental?.CO2_equivalents.CO2_savings_25years.toFixed(
                    0
                  )}{" "}
                  tCO2 évitées sur 25 ans
                </Text>
              </View>
              <View
                style={{
                  position: "absolute",
                  left: "51%",
                  bottom: 130,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontFamily: "DMSansBold",
                  }}
                >
                  {monInstallation.impactEnvironnemental?.CO2_equivalents.km_car.value.toFixed(
                    0
                  )}{" "}
                  {
                    monInstallation.impactEnvironnemental?.CO2_equivalents
                      .km_car.label
                  }
                </Text>
                <Text
                  style={{
                    fontSize: 9,
                  }}
                >
                  parcourus en voiture thermique.
                </Text>
              </View>
              <View
                style={{
                  position: "absolute",
                  left: "51%",
                  bottom: 25,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontFamily: "DMSansBold",
                  }}
                >
                  {monInstallation.impactEnvironnemental?.CO2_equivalents.heating_consumption.value.toFixed(
                    0
                  )}{" "}
                  {
                    monInstallation.impactEnvironnemental?.CO2_equivalents
                      .heating_consumption.label
                  }
                </Text>
                <Text
                  style={{
                    fontSize: 9,
                  }}
                >
                  de consommation de chauffage.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType="rénovation globale"
      />
    </Page>
  );
};
