// Rechart import
import {
  Bar,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  BarChart,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

// Style import
import "./predictedBills.scss";

//Interface import
import { IPretictedBills } from "../../interfaces/renoglobaleInterface";

//Components import
import { Layout } from "../Layout/Layout";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { euroShortFormat } from "../../services/euroFormatter";

export const PredictedBills = ({
  predictedBillsData,
  isLoading,
  errorMessage,
}: {
  predictedBillsData: IPretictedBills | null | undefined;
  isLoading: boolean;
  errorMessage: string;
}) => {
  const formatPredictedBillsData = (data: IPretictedBills) => {
    const newArray = [];
    for (let index in data?.heating) {
      newArray.push({
        annee: Number(index) + 1,
        heating: data?.heating?.[index] || 0,
        ecs: data?.ecs?.[index] || 0,
        other: data?.other?.[index] || 0,
      });
    }

    return newArray;
  };

  const dataForGraph =
    predictedBillsData && formatPredictedBillsData(predictedBillsData);

  const CustomLegend = (props: any) => {
    const { payload } = props;

    const legendDictionary = {
      heating: "Chauffage",
      ecs: "ECS",
      other: "Autre",
    };

    type keyOfDictionary = keyof typeof legendDictionary;

    return (
      <ul className="legendContainer">
        {payload &&
          payload
            .reverse()
            .map((entry: any, index: any) => (
              <li
                key={`item-${index}`}
                className={`legendItem`}
                style={{ color: entry.color }}
              >
                <div
                  className="colorPastille"
                  style={{ backgroundColor: entry.color }}
                />
                <p>
                  {legendDictionary?.[
                    entry.value as keyOfDictionary
                  ].toUpperCase()}
                </p>
              </li>
            ))
            .reverse()}
      </ul>
    );
  };

  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (!active || !payload || !payload.length) {
      return null;
    }

    const otherColor = payload[2].color;
    const ecsColor = payload[1].color;
    const heatingColor = payload[0].color;

    const otherValue = payload[2].value
      ? Math.round(Number(payload[2].value))
      : 0;
    const ecsValue = payload[1].value
      ? Math.round(Number(payload[1].value))
      : 0;
    const heatingValue = payload[0].value
      ? Math.round(Number(payload[0].value))
      : 0;

    return (
      <div className="customTooltip">
        <div className="labelPriceContainer">
          <div
            className="label colorPastille"
            style={{ backgroundColor: otherColor }}
          />
          <p className="price">{euroShortFormat(otherValue)}</p>
        </div>
        <div className="labelPriceContainer">
          <div
            className="label colorPastille"
            style={{ backgroundColor: ecsColor }}
          />
          <p className="price">{euroShortFormat(ecsValue)}</p>
        </div>
        <div className="labelPriceContainer">
          <div
            className="label colorPastille"
            style={{ backgroundColor: heatingColor }}
          />
          <p className="price">{euroShortFormat(heatingValue)}</p>
        </div>

        <div className="labelPriceContainer">
          <p className="label">{`Année ${payload[0].payload.annee}`} : </p>
          <p className="price">
            {euroShortFormat(otherValue + ecsValue + heatingValue)}
          </p>
        </div>
      </div>
    );
  };

  const totalPredictedBills = dataForGraph
    ? Math.round(
        dataForGraph.reduce((acc, current) => {
          return acc + current.ecs + current.heating + current.other;
        }, 0)
      )
    : 0;

  return (
    <div className="PredictedBills">
      <Layout>
        <div className="PredictedBills__container">
          <section className="PredictedBills__title">
            <h3>
              Dépenses énergétiques estimées : +
              {euroShortFormat(totalPredictedBills)}
            </h3>
          </section>
          {isLoading ? (
            <div className="PredictedBills__LoaderContainer">
              <LoadingComponent diameter={60} />
            </div>
          ) : errorMessage ? (
            <ErrorMessage errorMessage={errorMessage} />
          ) : dataForGraph ? (
            <>
              <section className="PredictedBills__GraphContainer">
                <ResponsiveContainer>
                  <BarChart width={100} height={350} data={dataForGraph}>
                    <XAxis
                      dataKey="annee"
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: "#191B1E", fontSize: "0.6875rem" }}
                    />
                    <YAxis unit="€" scale="linear" />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      content={CustomLegend}
                      verticalAlign="top"
                      align="center"
                      height={50}
                    />
                    <Bar
                      dataKey="heating"
                      fill="#BA95D7"
                      barSize={24}
                      stackId="a"
                    />
                    <Bar
                      dataKey="ecs"
                      fill="#80B9F0"
                      barSize={24}
                      stackId="a"
                    />
                    <Bar
                      dataKey="other"
                      fill="#F0A14E"
                      barSize={24}
                      stackId="a"
                      radius={[4, 4, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </section>
            </>
          ) : null}
        </div>
      </Layout>
    </div>
  );
};
