// Libraries import
import { FormEvent, useEffect, useRef, useState } from "react";

// Styles import
import "./MesConsommationsAndEquipmentsRG.scss";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { Layout } from "../../components/Layout/Layout";
import { LoadingScreen } from "../../containers/LoadingScreen/LoadingScreen";
import { SubHeader } from "../../containers/SubHeader/SubHeader";
import { BoisPartForm } from "./BoisPartForm";
import { ElectricitePartForm } from "./ElectricitePartForm";
import { EnedisKOPartForm } from "./EnedisKOPartForm";
import { EnedisOKPartForm } from "./EnedisOKPartForm";
import { FioulPartForm } from "./FioulPartForm";
import { GazPartForm } from "./GazPartForm";
import { GPLPartForm } from "./GPLPartForm";

// Services import
import {
  setCartProductsAction,
  setStudyInfoAction,
} from "../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import {
  IConsumptionAndEquipment,
  useSaveConsumptionAndEquipment,
} from "../../services/ConsommationAndEquipment/useSaveConsumptionAndEquipment";
import { useGetMesConsommations } from "../../services/MesConsommations/useGetMesConsommations";
import { useSaveLastPageVisited } from "../../services/Oree2/useSaveLastPageVisited";
import { INavigateData } from "../../services/useNavigation/useNavigation";

// Data import
import { energiePriceNov2023Data } from "@web/shared/dist/datas/dataHypotethis";
import { mesConsommationsQuestionsData } from "./MesConsommationsQuestionsData";

// local interfaces declaration
interface IOption extends IImage {
  label: string | null;
  value: string | null;
}

interface IImage {
  imgSrc?: string;
  imgAltTxt?: string;
}
interface Props {
  goNext: (data?: INavigateData<any>) => void;
  navigationData?: INavigateData<string> | null;
}

export const MesConsommationsAndEquipementsRG = ({
  goNext,
  navigationData,
}: Props) => {
  const dispatch = useAppDispatch();
  const {
    saveConsumptionAndEquipment,
    isLoadingSaveConso,
    errorMessageSaveConso,
  } = useSaveConsumptionAndEquipment();
  const { saveLastPageVisited } = useSaveLastPageVisited();
  const { getMesConsommations, isLoading } = useGetMesConsommations();

  const { userAuthInfo, studyInformation } = useAppSelector((state) => state);

  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  const [consumptionDataOk, setConsumptionDataOk] = useState<boolean>(true);

  const [isFetchGetMesConsommationsDone, isFetfetchGetMesConsommationsDone] =
    useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBadRequestModalOpen, setIsBadRequestModalOpen] = useState(false);

  // STATES ELECTRICITY PART
  const [sourceEnergie, setSourceEnergie] = useState<IOption[]>(
    (studyInformation.source_energie?.length || 0) > 0
      ? mesConsommationsQuestionsData.sourcesDEnergie.options.filter((option) =>
          studyInformation.source_energie?.some((item) => item === option.value)
        )
      : mesConsommationsQuestionsData.sourcesDEnergie.options.filter(
          (option) => option.value === "electricite"
        ) // By default electricity is selected as it is used at least for electromenager
  );
  const isElectricite = !!sourceEnergie.find(
    (elem) => elem.value === "electricite"
  );
  const isGaz = !!sourceEnergie.find((elem) => elem.value === "gaz");
  const isBois = !!sourceEnergie.find((elem) => elem.value === "bois");
  const isGPL = !!sourceEnergie.find((elem) => elem.value === "gpl");

  const isFioul = !!sourceEnergie.find((elem) => elem.value === "fioul");

  const findElecInstType = () => {
    const foundOption =
      mesConsommationsQuestionsData.electricite.typeDInstallation.options.find(
        (option) => option.value === studyInformation.electric_installation_type
      );
    if (foundOption !== undefined) {
      return [foundOption];
    }
    return [];
  };

  const [electriciteTypeInstallation, setElectriciteTypeInstallation] =
    useState<IOption[]>(findElecInstType());

  const [electriciteSuscribedPower, setElectriciteSuscribedPower] = useState<
    IOption[]
  >(
    studyInformation.subscribed_power
      ? [
          mesConsommationsQuestionsData.electricite.suscribedPower.options.find(
            (option) =>
              option.value === String(studyInformation.subscribed_power)
          ) as IOption,
        ]
      : []
  );

  const [electriciteOptionTarifaire, setElectriciteOptionTarifaire] = useState<
    IOption | undefined
  >(
    mesConsommationsQuestionsData.electricite.optionTarifaire.options.find(
      (option) => option.value === studyInformation.tarification_option
    )
  );

  const [electriciteConsommationEuros, setElectriciteConsommationEuros] =
    useState<string | null>(
      String(studyInformation.annual_consumption_in_euros)
    );

  const [electriciteConsommationKwh, setElectriciteConsommationKwh] = useState<
    string | null
  >(String(studyInformation.annual_consumption_in_kwh));

  const [electricitePrincipauxEquipments, setElectricitePrincipauxEquipments] =
    useState<IOption[]>(
      (studyInformation.electricite_principaux_equipments?.length || 0) > 0
        ? mesConsommationsQuestionsData.electricite.principauxEquipements.options.filter(
            (option) =>
              studyInformation.electricite_principaux_equipments?.some(
                (item) => item === option.value
              )
          )
        : mesConsommationsQuestionsData.electricite.principauxEquipements.options.filter(
            (option) => option.value === "autres"
          ) // By default  "Électroménager, éclairage" as it is always used
    );

  const [electriciteSurfaceChauffee, setElectriciteSurfaceChauffee] = useState<
    string | null
  >(studyInformation.electricite_surface_chauffee?.toString() ?? null);

  const [electriciteTemperatureConfort, setElectriciteTemperatureConfort] =
    useState<number | null>(
      studyInformation.electricite_temperature_confort ?? 19 // Defautl T° confort = 19 degrees
    );

  const [electriciteTypeChauffage, setElectriciteTypeChauffage] = useState<
    IOption[]
  >(
    mesConsommationsQuestionsData.electricite.typeDeChauffage.options.filter(
      (option) =>
        studyInformation.electricite_type_chauffage?.some(
          (item) => option.value === item
        )
    ) || []
  );

  const [electriciteNombreEmetteur, setElectriciteNombreEmetteur] = useState<
    number | null
  >(studyInformation.electricite_nombre_emetteur ?? null);

  const [electriciteTypeEmetteur, setElectriciteTypeEmetteur] = useState<
    IOption[]
  >(
    mesConsommationsQuestionsData.electricite.typeDEmetteur.options.filter(
      (option) =>
        studyInformation.electricite_type_emetteur?.some(
          (item) => option.value === item
        )
    ) || []
  );

  const [electriciteTypeBallonEauChaude, setElectriciteTypeBallonEauChaude] =
    useState<IOption[]>(
      mesConsommationsQuestionsData.electricite.typeDeBallon.options.filter(
        (option) =>
          studyInformation.electricite_type_ballon_eau_chaude?.some(
            (item) => option.value === item
          )
      ) || []
    );

  const [electriciteStorageCapacity, setElectriciteStorageCapacity] = useState<
    IOption[]
  >(
    mesConsommationsQuestionsData.electricite.capaciteDeStockage.options.filter(
      (option) =>
        studyInformation.electricite_storage_capacity?.toString() ===
        option.value
    ) || []
  );

  const [electriciteInstallationYear, setElectriciteInstallationYear] =
    useState<string | null>(
      studyInformation.electricite_installation_year ?? null
    );

  const [
    electricitePacAirEauInstallationYear,
    setElectricitePacAirEauInstallationYear,
  ] = useState<number | null>(
    studyInformation.electricite_pac_aireau_installation_year ?? null
  );
  const [
    electricitePacAirAirInstallationYear,
    setElectricitePacAirAirInstallationYear,
  ] = useState<number | null>(
    studyInformation.electricite_pac_airair_installation_year ?? null
  );

  const initiEquipmentsConsumptionEurosToPercentage = ({
    valueInEuros,
    totalAnnualConsumptionInEuros,
  }: {
    valueInEuros: number | null | undefined;
    totalAnnualConsumptionInEuros: number | null | undefined;
  }) => {
    if (!valueInEuros || !totalAnnualConsumptionInEuros) return null;

    return Math.round(
      (valueInEuros / totalAnnualConsumptionInEuros) * 100
    ).toString();
  };

  const [
    electriciteChauffageConsumptionRate,
    setElectriciteChauffageConsumptionRate,
  ] = useState<string | null>(
    initiEquipmentsConsumptionEurosToPercentage({
      totalAnnualConsumptionInEuros:
        studyInformation.annual_consumption_in_euros,
      valueInEuros: studyInformation.electricite_chauffage_euros,
    })
  );

  const [electriciteECSConsumptionRate, setElectriciteECSConsumptionRate] =
    useState<string | null>(
      initiEquipmentsConsumptionEurosToPercentage({
        totalAnnualConsumptionInEuros:
          studyInformation.annual_consumption_in_euros,
        valueInEuros: studyInformation.electricite_ecs_euros,
      })
    );

  const [
    electriciteAutresConsumptionRate,
    setElectriciteAutresConsumptionRate,
  ] = useState<string | null>(
    initiEquipmentsConsumptionEurosToPercentage({
      totalAnnualConsumptionInEuros:
        studyInformation.annual_consumption_in_euros,
      valueInEuros: studyInformation.electricite_autres_euros,
    })
  );

  const [
    electriciteEclairageConsumptionRate,
    setElectriciteEclairageConsumptionRate,
  ] = useState<string | null>(
    initiEquipmentsConsumptionEurosToPercentage({
      totalAnnualConsumptionInEuros:
        studyInformation.annual_consumption_in_euros,
      valueInEuros: studyInformation.electricite_eclairage_euros,
    })
  );

  const [
    electricitePiscineConsumptionRate,
    setElectricitePiscineConsumptionRate,
  ] = useState<string | null>(
    initiEquipmentsConsumptionEurosToPercentage({
      totalAnnualConsumptionInEuros:
        studyInformation.annual_consumption_in_euros,
      valueInEuros: studyInformation.electricite_piscine_euros,
    })
  );

  const [
    electriciteBorneDeRechargeConsumptionRate,
    setElectriciteBorneDeRechargeConsumptionRate,
  ] = useState<string | null>(
    initiEquipmentsConsumptionEurosToPercentage({
      totalAnnualConsumptionInEuros:
        studyInformation.annual_consumption_in_euros,
      valueInEuros: studyInformation.electricite_borne_de_recharge_euros,
    })
  );

  const [
    electriciteClimatisationConsumptionRate,
    setElectriciteClimatisationConsumptionRate,
  ] = useState<string | null>(
    initiEquipmentsConsumptionEurosToPercentage({
      totalAnnualConsumptionInEuros:
        studyInformation.annual_consumption_in_euros,
      valueInEuros: studyInformation.electricite_climatisation_euros,
    })
  );

  const [priceElectricityPerkWh, setPriceElectricityPerkWh] = useState<number>(
    studyInformation.electricity_price_per_kwh ??
      energiePriceNov2023Data["Electricité"] ??
      0
  );

  // STATES GAZ PART
  const [gazConsommationEuros, setGazConsommationEuros] = useState<
    string | null
  >(studyInformation.gaz_consommation_euros?.toString() ?? null);

  const [gazConsommationKwh, setGazConsommationKwh] = useState<string | null>(
    studyInformation.gaz_consommation_kwh?.toString() ?? null
  );

  const [gazPrincipauxEquipments, setGazPrincipauxEquipments] = useState<
    IOption[]
  >(
    mesConsommationsQuestionsData.gaz.principauxEquipements.options.filter(
      (option) =>
        studyInformation.gaz_principaux_equipments?.some(
          (item) => option.value === item
        )
    ) || []
  );

  const [gazSurfaceChauffee, setGazSurfaceChauffee] = useState<string | null>(
    studyInformation.gaz_surface_chauffee?.toString() ?? null
  );

  const [gazTemperatureConfort, setGazTemperatureConfort] = useState<
    number | null
  >(studyInformation.gaz_temperature_confort ?? 19);

  const [gazTypeChauffage, setGazTypeChauffage] = useState<IOption[]>(
    mesConsommationsQuestionsData.gaz.typeDeChaudiere.options.filter((option) =>
      studyInformation.gaz_type_chauffage?.some((item) => option.value === item)
    ) || []
  );

  const [gazNombreEmetteur, setGazNombreEmetteur] = useState<number | null>(
    studyInformation.gaz_nombre_emetteur ?? null
  );

  const [gazTypeEmetteur, setGazTypeEmetteur] = useState<IOption[]>(
    mesConsommationsQuestionsData.gaz.typeDEmetteur.options.filter((option) =>
      studyInformation.gaz_type_emetteur?.some((item) => option.value === item)
    ) || []
  );

  const [gazStorageCapacity, setGazStorageCapacity] = useState<IOption[]>(
    mesConsommationsQuestionsData.gaz.capaciteDeStockage.options.filter(
      (option) =>
        studyInformation.gaz_storage_capacity?.toString() === option.value
    ) || []
  );

  const [gazChauffageConsumptionRate, setGazChauffageConsumptionRate] =
    useState<string | null>(
      initiEquipmentsConsumptionEurosToPercentage({
        totalAnnualConsumptionInEuros: studyInformation.gaz_consommation_euros,
        valueInEuros: studyInformation.gaz_chauffage_euros,
      })
    );

  const [gazECSConsumptionRate, setGazECSConsumptionRate] = useState<
    string | null
  >(
    initiEquipmentsConsumptionEurosToPercentage({
      totalAnnualConsumptionInEuros: studyInformation.gaz_consommation_euros,
      valueInEuros: studyInformation.gaz_ecs_euros,
    })
  );

  const [gazAutresConsumptionRate, setGazAutresConsumptionRate] = useState<
    string | null
  >(
    initiEquipmentsConsumptionEurosToPercentage({
      totalAnnualConsumptionInEuros: studyInformation.gaz_consommation_euros,
      valueInEuros: studyInformation.gaz_autres_euros,
    })
  );

  const [
    gazIsECSIntegratedToHeatingSystem,
    setGazIsECSIntegratedToHeatingSystem,
  ] = useState<boolean | null>(
    studyInformation.is_gaz_ecs_integrated_to_heating_system ?? null
  );

  const [priceGazPerkWh, setPriceGazPerkWh] = useState<number>(
    studyInformation.gaz_price_per_kwh ?? energiePriceNov2023Data["Gaz"] ?? 0
  );

  // STATES BOIS PART
  const [boisConsommationEuros, setBoisConsommationEuros] = useState<
    string | null
  >(studyInformation.bois_consommation_euros?.toString() ?? null);

  const [boisSurfaceChauffee, setBoisSurfaceChauffee] = useState<string | null>(
    studyInformation.bois_surface_chauffee?.toString() ?? null
  );

  const [boisTemperatureConfort, setBoisTemperatureConfort] = useState<
    number | null
  >(studyInformation.bois_temperature_confort ?? 19);

  const [boisTypeChauffage, setBoisTypeChauffage] = useState<IOption[]>(
    mesConsommationsQuestionsData.bois.typeDeChauffage.options.filter(
      (option) =>
        studyInformation.bois_type_chauffage?.some(
          (item) => option.value === item
        )
    ) || []
  );

  const [boisNombreEmetteur, setBoisNombreEmetteur] = useState<number | null>(
    studyInformation.bois_nombre_emetteur ?? null
  );

  const [boisTypeEmetteur, setBoisTypeEmetteur] = useState<IOption[]>(
    mesConsommationsQuestionsData.bois.typeDEmetteur.options.filter((option) =>
      studyInformation.bois_type_emetteur?.some((item) => option.value === item)
    ) || []
  );

  // STATE Fioul PART
  const [fioulConsommationEuros, setFioulConsommationEuros] = useState<
    string | null
  >(studyInformation.fioul_consommation_euros?.toString() ?? null);

  const [fioulPrincipauxEquipments, setFioulPrincipauxEquipments] = useState<
    IOption[]
  >(
    mesConsommationsQuestionsData.fioul.principauxEquipements.options.filter(
      (option) =>
        studyInformation.fioul_principaux_equipments?.some(
          (item) => option.value === item
        )
    ) || []
  );

  const [fioulSurfaceChauffee, setFioulSurfaceChauffee] = useState<
    string | null
  >(studyInformation.fioul_surface_chauffee?.toString() ?? null);

  const [fioulTemperatureConfort, setFioulTemperatureConfort] = useState<
    number | null
  >(studyInformation.fioul_temperature_confort ?? 19);

  const [fioulNombreEmetteur, setFioulNombreEmetteur] = useState<number | null>(
    studyInformation.fioul_nombre_emetteur ?? null
  );

  const [fioulTypeEmetteur, setFioulTypeEmetteur] = useState<IOption[]>(
    mesConsommationsQuestionsData.fioul.typeDEmetteur.options.filter((option) =>
      studyInformation.fioul_type_emetteur?.some(
        (item) => option.value === item
      )
    ) || []
  );

  const [fioulStorageCapacity, setFioulStorageCapacity] = useState<IOption[]>(
    mesConsommationsQuestionsData.fioul.capaciteDeStockage.options.filter(
      (option) =>
        studyInformation.fioul_storage_capacity?.toString() === option.value
    ) || []
  );

  const [fioulChauffageConsumptionRate, setFioulChauffageConsumptionRate] =
    useState<string | null>(
      initiEquipmentsConsumptionEurosToPercentage({
        totalAnnualConsumptionInEuros:
          studyInformation.fioul_consommation_euros,
        valueInEuros: studyInformation.fioul_chauffage_euros,
      })
    );

  const [fioulECSConsumptionRate, setFioulECSConsumptionRate] = useState<
    string | null
  >(
    initiEquipmentsConsumptionEurosToPercentage({
      totalAnnualConsumptionInEuros: studyInformation.fioul_consommation_euros,
      valueInEuros: studyInformation.fioul_ecs_euros,
    })
  );

  const [fioulTypeChauffage, setFioulTypeChauffage] = useState<IOption[]>(
    mesConsommationsQuestionsData.fioul.typeDeChaudiere.options.filter(
      (option) =>
        studyInformation.fioul_type_chauffage?.some(
          (item) => option.value === item
        )
    ) || []
  );

  // STATES GPL PART
  const [GPLConsommationEuros, setGPLConsommationEuros] = useState<
    string | null
  >(studyInformation.gpl_consommation_euros?.toString() ?? null);

  const [GPLPrincipauxEquipments, setGPLPrincipauxEquipments] = useState<
    IOption[]
  >(
    mesConsommationsQuestionsData.gpl.principauxEquipements.options.filter(
      (option) =>
        studyInformation.gpl_principaux_equipments?.some(
          (item) => option.value === item
        )
    ) || []
  );

  const [GPLSurfaceChauffee, setGPLSurfaceChauffee] = useState<string | null>(
    studyInformation.gpl_surface_chauffee?.toString() ?? null
  );

  const [GPLTemperatureConfort, setGPLTemperatureConfort] = useState<
    number | null
  >(studyInformation.gpl_temperature_confort ?? 19);

  const [GPLTypeChauffage, setGPLTypeChauffage] = useState<IOption[]>(
    mesConsommationsQuestionsData.gpl.typeDeChaudiere.options.filter((option) =>
      studyInformation.gpl_type_chauffage?.some((item) => option.value === item)
    ) || []
  );

  const [GPLNombreEmetteur, setGPLNombreEmetteur] = useState<number | null>(
    studyInformation.gpl_nombre_emetteur ?? null
  );
  const [GPLTypeEmetteur, setGPLTypeEmetteur] = useState<IOption[]>(
    mesConsommationsQuestionsData.gpl.typeDEmetteur.options.filter((option) =>
      studyInformation.gpl_type_emetteur?.some((item) => option.value === item)
    ) || []
  );

  const [GPLStorageCapacity, setGPLStorageCapacity] = useState<IOption[]>(
    mesConsommationsQuestionsData.gpl.capaciteDeStockage.options.filter(
      (option) =>
        studyInformation.gpl_storage_capacity?.toString() === option.value
    ) || []
  );

  const [GPLChauffageConsumptionRate, setGPLChauffageConsumptionRate] =
    useState<string | null>(
      initiEquipmentsConsumptionEurosToPercentage({
        totalAnnualConsumptionInEuros: studyInformation.gpl_consommation_euros,
        valueInEuros: studyInformation.gpl_chauffage_euros,
      })
    );

  const [GPLECSConsumptionRate, setGPLECSConsumptionRate] = useState<
    string | null
  >(
    initiEquipmentsConsumptionEurosToPercentage({
      totalAnnualConsumptionInEuros: studyInformation.gpl_consommation_euros,
      valueInEuros: studyInformation.gpl_ecs_euros,
    })
  );

  //check for all equipments selected of each energy source, if consumptions distribution egual to 100%
  const checkElecEquipmentsDistribution = () => {
    //If no elec in energy source no need to check
    if (!sourceEnergie.some((elem) => elem.value === "electricite"))
      return false;

    const equipmentsNumber =
      electricitePrincipauxEquipments.length +
      (electricitePrincipauxEquipments.find(
        (option) => option.value === "autres"
      )
        ? 1
        : 0);

    const allElecEquipmentsConsumption = [
      Number(electriciteChauffageConsumptionRate),
      Number(electriciteECSConsumptionRate),
      Number(electriciteAutresConsumptionRate),
      Number(electriciteEclairageConsumptionRate),
      Number(electricitePiscineConsumptionRate),
      Number(electriciteBorneDeRechargeConsumptionRate),
      Number(electriciteClimatisationConsumptionRate),
    ];

    const numberEquipmentsConsumptionAlreadyFilled =
      allElecEquipmentsConsumption.filter(Boolean).length;

    const equipmentConsumptionDistribution =
      allElecEquipmentsConsumption.reduce((acc, curr) => acc + curr, 0);

    //If only one equipment no need to check
    if (electricitePrincipauxEquipments.length <= 1) return false;

    if (
      equipmentConsumptionDistribution !== 100 ||
      equipmentsNumber !== numberEquipmentsConsumptionAlreadyFilled
    ) {
      return true;
    }

    return false;
  };

  const checkGazEquipmentsDistribution = () => {
    //If no gaz in energy source no need to check
    if (!sourceEnergie.some((elem) => elem.value === "gaz")) return false;

    const equipmentsNumber = gazPrincipauxEquipments.length;
    const allGazEquipmentsConsumption = [
      Number(gazChauffageConsumptionRate),
      Number(gazECSConsumptionRate),
      Number(gazAutresConsumptionRate),
    ];
    const numberEquipmentsConsumptionAlreadyFilled =
      allGazEquipmentsConsumption.filter(Boolean).length;

    const EquipmentConsumptionDistribution = allGazEquipmentsConsumption.reduce(
      (acc, curr) => acc + curr,
      0
    );

    if (gazPrincipauxEquipments.length <= 1) return false;

    if (
      EquipmentConsumptionDistribution !== 100 ||
      equipmentsNumber !== numberEquipmentsConsumptionAlreadyFilled
    ) {
      return true;
    }

    return false;
  };

  const checkFioulEquipmentsDistribution = () => {
    //If no Fioul in energy source no need to check
    if (!sourceEnergie.some((elem) => elem.value === "fioul")) return false;

    if (fioulPrincipauxEquipments.length <= 1) return false;

    const EquipmentConsumptionDistribution =
      Number(fioulChauffageConsumptionRate) + Number(fioulECSConsumptionRate);

    if (EquipmentConsumptionDistribution !== 100) {
      return true;
    }

    return false;
  };

  const checkGPLEquipmentsDistribution = () => {
    //If no GPL in energy source no need to check
    if (!sourceEnergie.some((elem) => elem.value === "gpl")) return false;

    if (GPLPrincipauxEquipments.length <= 1) return false;

    const EquipmentConsumptionDistribution =
      Number(GPLChauffageConsumptionRate) + Number(GPLECSConsumptionRate);

    if (EquipmentConsumptionDistribution !== 100) {
      return true;
    }

    return false;
  };

  const checkMinimumParametersForCalculus = () => {
    //We need at least Elec source, one chauffage and one ECS to run data calculus
    const isAtLeastOneChauffage =
      electricitePrincipauxEquipments.some(
        (equipment) => equipment.label === "Chauffage"
      ) ||
      gazPrincipauxEquipments.some(
        (equipment) => equipment.label === "Chauffage"
      ) ||
      boisTypeChauffage.length !== 0 ||
      GPLPrincipauxEquipments.some(
        (equipment) => equipment.label === "Chauffage"
      ) ||
      fioulPrincipauxEquipments.some(
        (equipment) => equipment.label === "Chauffage"
      );

    const isAtLeastOneECS =
      electricitePrincipauxEquipments.some(
        (equipment) => equipment.label === "Eau chaude sanitaire (ECS)"
      ) ||
      gazPrincipauxEquipments.some(
        (equipment) => equipment.label === "Eau chaude sanitaire (ECS)"
      ) ||
      GPLPrincipauxEquipments.some(
        (equipment) => equipment.label === "Eau chaude sanitaire (ECS)"
      ) ||
      fioulPrincipauxEquipments.some(
        (equipment) => equipment.label === "Eau chaude sanitaire (ECS)"
      );

    if (!isElectricite || !isAtLeastOneChauffage || !isAtLeastOneECS) {
      setIsModalOpen(true);
      return false;
    }
    return true;
  };

  /**
   * Calculates and returns an array of price warnings
   * based on the given consumption for each equipment.
   */
  const priceWarnings = (payload: IConsumptionAndEquipment): string[] => {
    const warnings = [];

    const sumOfConsumptions =
      Number(electriciteConsommationEuros) +
      Number(gazConsommationEuros) +
      Number(boisConsommationEuros) +
      Number(GPLConsommationEuros) +
      Number(fioulConsommationEuros);
    if (sumOfConsumptions < 600) {
      warnings.push("Le total de vos consommations est anormalement bas.");
    }

    const {
      source_energie,
      annual_consumption_in_euros,
      bois_consommation_euros,
      fioul_consommation_euros,
      gaz_consommation_euros,
      gpl_consommation_euros,
    } = payload;

    if (
      source_energie?.includes("electricite") &&
      annual_consumption_in_euros != null &&
      annual_consumption_in_euros < 500
    ) {
      warnings.push("Votre consommation d'électricité est anormalement basse.");
    }

    if (
      source_energie?.includes("gaz") &&
      gaz_consommation_euros != null &&
      gaz_consommation_euros < 500
    ) {
      warnings.push("Votre consommation de gaz est anormalement basse.");
    }

    if (
      source_energie?.includes("bois") &&
      bois_consommation_euros != null &&
      bois_consommation_euros < 100
    ) {
      warnings.push("Votre consommation de bois est anormalement basse.");
    }

    if (
      source_energie?.includes("gpl") &&
      gpl_consommation_euros != null &&
      gpl_consommation_euros < 500
    ) {
      warnings.push("Votre consommation de GPL est anormalement basse.");
    }

    if (
      source_energie?.includes("fioul") &&
      fioul_consommation_euros != null &&
      fioul_consommation_euros < 500
    ) {
      warnings.push("Votre consommation de fioul est anormalement basse.");
    }

    return warnings;
  };

  const onSubmitFormConsommation = async (event: FormEvent) => {
    // it prevents page refresh
    event?.preventDefault();

    if (!checkMinimumParametersForCalculus()) return;

    // In case user go back to form and change some values, redux cartProducts should be reset to prevent user from having the previous cartProducts
    dispatch(
      setCartProductsAction({
        cartProducts: [],
      })
    );

    // build payload for state and eero
    const payloadForSateAndEero: IConsumptionAndEquipment = {
      /**
       * electricity part
       */
      source_energie: sourceEnergie.map((item) => {
        return item.value;
      }),
      electric_installation_type: electriciteTypeInstallation[0]?.value,
      subscribed_power: electriciteSuscribedPower[0]?.value
        ? Number(electriciteSuscribedPower[0]?.value)
        : null,
      tarification_option:
        electriciteOptionTarifaire && electriciteOptionTarifaire?.value,
      annual_consumption_in_euros: Number(electriciteConsommationEuros),
      annual_consumption_in_kwh: Number(electriciteConsommationKwh),
      electricite_principaux_equipments: electricitePrincipauxEquipments.map(
        (item) => {
          return item.value;
        }
      ),
      electricite_surface_chauffee: Number(electriciteSurfaceChauffee),
      electricite_temperature_confort: electriciteTemperatureConfort,
      electricite_type_chauffage: electriciteTypeChauffage.map((item) => {
        return item.value;
      }),
      electricite_nombre_emetteur: electriciteNombreEmetteur,
      electricite_type_emetteur: electriciteTypeEmetteur.map((item) => {
        return item.value;
      }),
      electricite_type_ballon_eau_chaude: electriciteTypeBallonEauChaude.map(
        (item) => {
          return item.value;
        }
      ),
      electricite_storage_capacity: Number(
        electriciteStorageCapacity[0]?.value
      ),
      electricite_installation_year: electriciteInstallationYear,
      electricite_chauffage_euros:
        (Number(electriciteChauffageConsumptionRate) *
          Number(electriciteConsommationEuros)) /
        100,
      electricite_ecs_euros:
        (Number(electriciteECSConsumptionRate) *
          Number(electriciteConsommationEuros)) /
        100,
      electricite_autres_euros:
        (Number(electriciteAutresConsumptionRate) *
          Number(electriciteConsommationEuros)) /
        100,

      electricite_eclairage_euros:
        (Number(electriciteEclairageConsumptionRate) *
          Number(electriciteConsommationEuros)) /
        100,

      electricite_piscine_euros:
        (Number(electricitePiscineConsumptionRate) *
          Number(electriciteConsommationEuros)) /
        100,
      electricite_borne_de_recharge_euros:
        (Number(electriciteBorneDeRechargeConsumptionRate) *
          Number(electriciteConsommationEuros)) /
        100,
      electricite_climatisation_euros:
        (Number(electriciteClimatisationConsumptionRate) *
          Number(electriciteConsommationEuros)) /
        100,
      electricity_price_per_kwh: priceElectricityPerkWh,
      electricite_pac_airair_installation_year:
        electricitePacAirAirInstallationYear,
      electricite_pac_aireau_installation_year:
        electricitePacAirEauInstallationYear,

      /**
       * gaz part
       */
      gaz_consommation_euros: Number(gazConsommationEuros),
      gaz_consommation_kwh: Number(gazConsommationKwh),
      gaz_principaux_equipments: gazPrincipauxEquipments.map((item) => {
        return item.value;
      }),
      gaz_surface_chauffee: Number(gazSurfaceChauffee),
      gaz_temperature_confort: gazTemperatureConfort,
      gaz_type_chauffage: gazTypeChauffage.map((item) => {
        return item.value;
      }),
      gaz_nombre_emetteur: gazNombreEmetteur,
      gaz_type_emetteur: gazTypeEmetteur.map((item) => {
        return item.value;
      }),
      gaz_storage_capacity: Number(gazStorageCapacity[0]?.value),
      gaz_chauffage_euros:
        (Number(gazChauffageConsumptionRate) * Number(gazConsommationEuros)) /
        100,
      gaz_ecs_euros:
        (Number(gazECSConsumptionRate) * Number(gazConsommationEuros)) / 100,
      gaz_autres_euros:
        (Number(gazAutresConsumptionRate) * Number(gazConsommationEuros)) / 100,
      is_gaz_ecs_integrated_to_heating_system:
        gazIsECSIntegratedToHeatingSystem,
      gaz_price_per_kwh: priceGazPerkWh,
      /**
       * bois part
       */
      bois_consommation_euros:
        boisConsommationEuros != null ? Number(boisConsommationEuros) : null,
      bois_surface_chauffee: Number(boisSurfaceChauffee),
      bois_temperature_confort: boisTemperatureConfort,
      bois_type_chauffage: boisTypeChauffage.map((item) => {
        return item.value;
      }),
      bois_nombre_emetteur: boisNombreEmetteur,
      bois_type_emetteur: boisTypeEmetteur.map((item) => {
        return item.value;
      }),

      /**
       * gpl part
       */
      gpl_consommation_euros: Number(GPLConsommationEuros),
      gpl_principaux_equipments: GPLPrincipauxEquipments.map((item) => {
        return item.value;
      }),
      gpl_surface_chauffee: Number(GPLSurfaceChauffee),
      gpl_temperature_confort: GPLTemperatureConfort,
      gpl_type_chauffage: GPLTypeChauffage.map((item) => {
        return item.value;
      }),
      gpl_nombre_emetteur: GPLNombreEmetteur,
      gpl_type_emetteur: GPLTypeEmetteur.map((item) => {
        return item.value;
      }),
      gpl_storage_capacity: Number(GPLStorageCapacity[0]?.value),
      gpl_chauffage_euros:
        (Number(GPLChauffageConsumptionRate) * Number(GPLConsommationEuros)) /
        100,
      gpl_ecs_euros:
        (Number(GPLECSConsumptionRate) * Number(GPLConsommationEuros)) / 100,

      /**
       * fioul part
       */
      fioul_consommation_euros: Number(fioulConsommationEuros),
      fioul_principaux_equipments: fioulPrincipauxEquipments.map((item) => {
        return item.value;
      }),
      fioul_surface_chauffee: Number(fioulSurfaceChauffee),
      fioul_temperature_confort: fioulTemperatureConfort,
      fioul_nombre_emetteur: fioulNombreEmetteur,
      fioul_type_emetteur: fioulTypeEmetteur.map((item) => {
        return item.value;
      }),
      fioul_storage_capacity: Number(fioulStorageCapacity[0]?.value),
      fioul_chauffage_euros:
        (Number(fioulChauffageConsumptionRate) *
          Number(fioulConsommationEuros)) /
        100,
      fioul_ecs_euros:
        (Number(fioulECSConsumptionRate) * Number(fioulConsommationEuros)) /
        100,
      fioul_type_chauffage: fioulTypeChauffage.map((item) => {
        return item.value;
      }),
    };

    const warnings = priceWarnings(payloadForSateAndEero);
    if (warnings.length > 0) {
      const formattedWarnings =
        warnings.join("\n") + "\n\nVoulez-vous continuer ?";

      const confirmed = window.confirm(formattedWarnings);
      if (!confirmed) {
        return;
      }
    }

    // save the infos in the state
    dispatch(
      setStudyInfoAction({
        studyInformation: payloadForSateAndEero,
      })
    );
    // save info in db
    const response = await saveConsumptionAndEquipment({
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id,
      payload: payloadForSateAndEero,
    });

    if (response && response.status === 200) {
      saveLastPageVisited({
        last_page_visited: "my_consumptions_equipments",
        study_id: studyInformation?.study_id || null,
        userToken: userAuthInfo.userToken,
      });

      goNext && goNext();
    }
  };

  const onGoNexsubHeader = () => {
    if (checkMinimumParametersForCalculus()) {
      return buttonSubmitRef.current?.click();
    }
    return;
  };

  const fetchGetMesConsommations = async () => {
    const mesConsommation = await getMesConsommations({
      userToken: userAuthInfo.userToken,
      household_id: studyInformation.household_id || null,
      building_id: studyInformation.building_id || "",
      study_id: studyInformation.study_id,
    });
    if (
      mesConsommation &&
      mesConsommation.subscribed_power &&
      mesConsommation.tarification_option &&
      mesConsommation.annual_electric_consumption
    ) {
      setConsumptionDataOk(true);
      // if we get the consumption data, we update the local states
      !sourceEnergie.find((item) => item.value === "electricite") &&
        setSourceEnergie((state) => [
          ...state,
          { label: "Electricité", value: "electricite" },
        ]);
      setElectriciteSuscribedPower([
        mesConsommationsQuestionsData.electricite.suscribedPower.options.find(
          (option) => option.value === String(mesConsommation.subscribed_power)
        )!,
      ]);
      setElectriciteOptionTarifaire(
        mesConsommationsQuestionsData.electricite.optionTarifaire.options.find(
          (option) =>
            option.value === String(mesConsommation.tarification_option) ||
            option.label === String(mesConsommation.tarification_option)
        )
      );
      setElectriciteConsommationKwh(
        String(mesConsommation.annual_electric_consumption)
      );
      setElectriciteConsommationEuros(
        String(
          Math.round(
            mesConsommation.annual_electric_consumption *
              energiePriceNov2023Data["Electricité"] *
              100
          ) / 100
        )
      );
      isFetfetchGetMesConsommationsDone(true);
      return;
    }
    isFetfetchGetMesConsommationsDone(true);
    setConsumptionDataOk(false);
    return;
  };

  // Put by default the electricity "surface chauffée" value to the "surface habitable" value only if user heat his house with eletricity
  // and if commercial has not yet enter a "surface chauffée" value
  useEffect(() => {
    if (
      !!electricitePrincipauxEquipments.find(
        (elem) => elem.value === "chauffage"
      ) &&
      electriciteSurfaceChauffee === null
    ) {
      setElectriciteSurfaceChauffee(navigationData?.surfaceHabitable ?? null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [electricitePrincipauxEquipments.length]);

  const resetElectriciteChauffageSection = () => {
    setElectriciteTemperatureConfort(19);
    setElectriciteTypeChauffage([]);
    setElectriciteNombreEmetteur(null);
    setElectriciteTypeEmetteur([]);
    setElectriciteChauffageConsumptionRate(null);
    setElectricitePacAirEauInstallationYear(null);
    setElectricitePacAirAirInstallationYear(null);
  };

  const resetElectriciteECSSection = () => {
    setElectriciteTypeBallonEauChaude([]);
    setElectriciteStorageCapacity([]);
    setElectriciteInstallationYear(null);
    setElectriciteECSConsumptionRate(null);
  };

  const resetEclectricitySection = () => {
    setElectriciteTypeInstallation([]);
    setElectriciteSuscribedPower([]);
    setElectriciteOptionTarifaire(undefined);
    setElectriciteConsommationEuros("0");
    setElectriciteConsommationKwh("0");
    setElectriciteTypeInstallation([]);
    setElectricitePrincipauxEquipments([]);
    resetElectriciteChauffageSection();
    resetElectriciteECSSection();
    setElectriciteAutresConsumptionRate(null);
    setElectriciteClimatisationConsumptionRate(null);
    setElectriciteBorneDeRechargeConsumptionRate(null);
    setElectricitePiscineConsumptionRate(null);
    setElectriciteEclairageConsumptionRate(null);
  };

  const resetFioulChauffageSection = () => {
    setFioulTemperatureConfort(19);
    setFioulTypeChauffage([]);
    setFioulNombreEmetteur(null);
    setFioulTypeEmetteur([]);
    setFioulChauffageConsumptionRate(null);
  };

  const resetFioulECSSection = () => {
    setFioulStorageCapacity([]);
    setFioulECSConsumptionRate(null);
  };

  const resetFioulSection = () => {
    setFioulConsommationEuros(null);
    setFioulPrincipauxEquipments([]);
    resetFioulChauffageSection();
    resetFioulECSSection();
  };

  const resetGazChauffageSection = () => {
    setGazTemperatureConfort(19);
    setGazTypeChauffage([]);
    setGazNombreEmetteur(null);
    setGazTypeEmetteur([]);
    setGazChauffageConsumptionRate(null);
  };

  const resetGazECSSection = () => {
    setGazIsECSIntegratedToHeatingSystem(null);
    setGazStorageCapacity([]);
    setGazECSConsumptionRate(null);
  };

  const resetGazSection = () => {
    setGazConsommationKwh(null);
    setGazConsommationEuros(null);
    setGazPrincipauxEquipments([]);
    resetGazChauffageSection();
    resetGazECSSection();
    setGazAutresConsumptionRate(null);
  };

  const resetGPLChauffageSection = () => {
    setGPLTemperatureConfort(19);
    setGPLTypeChauffage([]);
    setGPLNombreEmetteur(null);
    setGPLTypeEmetteur([]);
    setGPLChauffageConsumptionRate(null);
  };

  const resetGPLECSSection = () => {
    setGPLStorageCapacity([]);
    setGPLECSConsumptionRate(null);
  };

  const resetGPLSection = () => {
    setGPLConsommationEuros(null);
    setGPLPrincipauxEquipments([]);
    resetGPLChauffageSection();
    resetGPLECSSection();
  };

  const resetBoisSection = () => {
    setBoisConsommationEuros(null);
    setBoisTemperatureConfort(19);
    setBoisNombreEmetteur(null);
    setBoisTypeChauffage([]);
  };

  // Put by default the gaz "surface chauffée" value to the "surface habitable" value only if user heat his house with gaz
  // and if commercial has not yet enter a "surface chauffée" value
  useEffect(() => {
    if (
      !!gazPrincipauxEquipments.find((elem) => elem.value === "chauffage") &&
      gazSurfaceChauffee === null
    ) {
      setGazSurfaceChauffee(navigationData?.surfaceHabitable ?? null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gazPrincipauxEquipments.length]);

  // Put by default the fioul "surface chauffée" value to the "surface habitable" value only if user heat his house with fioul
  // and if commercial has not yet enter a "surface chauffée" value
  useEffect(() => {
    if (
      !!fioulPrincipauxEquipments.find((elem) => elem.value === "chauffage") &&
      fioulSurfaceChauffee === null
    ) {
      setFioulSurfaceChauffee(navigationData?.surfaceHabitable ?? null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fioulPrincipauxEquipments.length]);

  // Put by default the GPL "surface chauffée" value to the "surface habitable" value only if user heat his house with GPL
  // and if commercial has not yet enter a "surface chauffée" value
  useEffect(() => {
    if (
      !!GPLPrincipauxEquipments.find((elem) => elem.value === "chauffage") &&
      GPLSurfaceChauffee === null
    ) {
      setGPLSurfaceChauffee(navigationData?.surfaceHabitable ?? null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GPLPrincipauxEquipments.length]);

  // Put by default the bois "surface chauffée" value to the "surface habitable" value only if user heat his house with bois
  // and if commercial has not yet enter a "surface chauffée" value
  useEffect(() => {
    if (isBois && boisSurfaceChauffee === null) {
      setBoisSurfaceChauffee(navigationData?.surfaceHabitable ?? null);
    }

    // If no Bois we reset Bois form
    if (!isBois) {
      resetBoisSection();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBois]);

  useEffect(() => {
    // If no eletricite we reset electricite form
    if (!isElectricite) {
      resetEclectricitySection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isElectricite]);

  useEffect(() => {
    // If no gaz we reset gaz form
    if (!isGaz) {
      resetGazSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGaz]);

  useEffect(() => {
    // If no fioul we reset fioul form
    if (!isFioul) {
      resetFioulSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFioul]);

  useEffect(() => {
    // If no GPL we reset GPL form
    if (!isGPL) {
      resetGPLSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGPL]);

  useEffect(() => {
    fetchGetMesConsommations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorMessageSaveConso) {
      setIsBadRequestModalOpen(true);
    }
    return () => {
      setIsBadRequestModalOpen(false);
    };
  }, [errorMessageSaveConso]);

  const isNextButtonDisabled =
    checkElecEquipmentsDistribution() ||
    checkGazEquipmentsDistribution() ||
    checkFioulEquipmentsDistribution() ||
    checkGPLEquipmentsDistribution();

  return (
    <div className="MesConsommationsAndEquipementsRG">
      <Modale
        isModaleOpen={isModalOpen}
        textAlignMiddle
        text="La source d'énergie Electricité ainsi qu'un équipement de chauffage et un équipement d'eau chaude sanitaire sont obligatoires pour continuer"
      >
        <div className="MesConsommationsAndEquipementsRG__ButtonContainer">
          <CTAButton name="D'accord" onClick={() => setIsModalOpen(false)} />
        </div>
      </Modale>
      <Modale isModaleOpen={isBadRequestModalOpen} textAlignMiddle>
        {errorMessageSaveConso
          .split("\n")
          .map((error: string, index: number) => (
            <p key={index}>{error}</p>
          ))}
        <div className="MesConsommationsAndEquipementsRG__ButtonContainer">
          <CTAButton
            name="D'accord"
            onClick={() => setIsBadRequestModalOpen(false)}
          />
        </div>
      </Modale>

      <SubHeader
        title="Mes consommations et équipements"
        goNext={onGoNexsubHeader}
        isLoading={isLoadingSaveConso}
        clientInfo={`${
          studyInformation.lastname &&
          studyInformation.lastname?.charAt(0).toUpperCase() +
            studyInformation.lastname?.slice(1)
        } ${
          studyInformation.firstname &&
          studyInformation.firstname?.charAt(0).toUpperCase() +
            studyInformation.firstname?.slice(1)
        }, ${studyInformation.address || ""}`}
        canGoNext={!isNextButtonDisabled}
      />
      {isLoading || !isFetchGetMesConsommationsDone ? (
        <div className="MesConsommationsAndEquipementsRG__LoadingScreen">
          <LoadingScreen
            sentenceOne="Nous sommes en train de récupérer"
            sentenceTwo="vos données de consommation..."
          />
        </div>
      ) : (
        <Layout>
          <div className="MesConsommationsAndEquipementsRG__ErrorMessage">
            <ErrorMessage errorMessage={errorMessageSaveConso} />
          </div>
          <div className="MesConsommationsAndEquipementsRG__Container">
            <div className="MesConsommationsAndEquipementsRG__Content">
              <form onSubmit={onSubmitFormConsommation}>
                {/*section sources d’énergie when enedis ko (no consumption data)*/}
                {!consumptionDataOk ? (
                  <EnedisKOPartForm
                    sourceEnergie={sourceEnergie}
                    setSourceEnergie={setSourceEnergie}
                  />
                ) : null}

                {/*section electricite*/}
                {isElectricite && (
                  <ElectricitePartForm
                    studyInformation={studyInformation}
                    consumptionDataOk={consumptionDataOk}
                    isElectricite={isElectricite}
                    electriciteTypeInstallation={electriciteTypeInstallation}
                    setElectriciteTypeInstallation={
                      setElectriciteTypeInstallation
                    }
                    electriciteSuscribedPower={electriciteSuscribedPower}
                    setElectriciteSuscribedPower={setElectriciteSuscribedPower}
                    electriciteOptionTarifaire={electriciteOptionTarifaire}
                    setElectriciteOptionTarifaire={
                      setElectriciteOptionTarifaire
                    }
                    electriciteConsommationEuros={electriciteConsommationEuros}
                    setElectriciteConsommationEuros={
                      setElectriciteConsommationEuros
                    }
                    electriciteConsommationKwh={electriciteConsommationKwh}
                    setElectriciteConsommationKwh={
                      setElectriciteConsommationKwh
                    }
                    electricitePrincipauxEquipments={
                      electricitePrincipauxEquipments
                    }
                    setElectricitePrincipauxEquipments={
                      setElectricitePrincipauxEquipments
                    }
                    electriciteSurfaceChauffee={electriciteSurfaceChauffee}
                    setElectriciteSurfaceChauffee={
                      setElectriciteSurfaceChauffee
                    }
                    electriciteTemperatureConfort={
                      electriciteTemperatureConfort
                    }
                    setElectriciteTemperatureConfort={
                      setElectriciteTemperatureConfort
                    }
                    electriciteTypeChauffage={electriciteTypeChauffage}
                    setElectriciteTypeChauffage={setElectriciteTypeChauffage}
                    electriciteNombreEmetteur={electriciteNombreEmetteur}
                    setElectriciteNombreEmetteur={setElectriciteNombreEmetteur}
                    electriciteTypeEmetteur={electriciteTypeEmetteur}
                    setElectriciteTypeEmetteur={setElectriciteTypeEmetteur}
                    electriciteStorageCapacity={electriciteStorageCapacity}
                    setElectriciteStorageCapacity={
                      setElectriciteStorageCapacity
                    }
                    electriciteChauffageConsumptionRate={
                      electriciteChauffageConsumptionRate
                    }
                    setElectriciteChauffageConsumptionRate={
                      setElectriciteChauffageConsumptionRate
                    }
                    electriciteECSConsumptionRate={
                      electriciteECSConsumptionRate
                    }
                    setElectriciteECSConsumptionRate={
                      setElectriciteECSConsumptionRate
                    }
                    electriciteAutresConsumptionRate={
                      electriciteAutresConsumptionRate
                    }
                    setElectriciteAutresConsumptionRate={
                      setElectriciteAutresConsumptionRate
                    }
                    sourceEnergie={sourceEnergie}
                    setConsumptionDataOk={setConsumptionDataOk}
                    setElectriciteTypeBallonEauChaude={
                      setElectriciteTypeBallonEauChaude
                    }
                    electriciteInstallationYear={electriciteInstallationYear}
                    setElectriciteInstallationYear={
                      setElectriciteInstallationYear
                    }
                    electricitePiscineConsumptionRate={
                      electricitePiscineConsumptionRate
                    }
                    setElectricitePiscineConsumptionRate={
                      setElectricitePiscineConsumptionRate
                    }
                    electriciteBorneDeRechargeConsumptionRate={
                      electriciteBorneDeRechargeConsumptionRate
                    }
                    setElectriciteBorneDeRechargeConsumptionRate={
                      setElectriciteBorneDeRechargeConsumptionRate
                    }
                    electriciteClimatisationConsumptionRate={
                      electriciteClimatisationConsumptionRate
                    }
                    setElectriciteClimatisationConsumptionRate={
                      setElectriciteClimatisationConsumptionRate
                    }
                    electriciteTypeBallonEauChaude={
                      electriciteTypeBallonEauChaude
                    }
                    setPriceElectricityPerkWh={setPriceElectricityPerkWh}
                    priceElectricityPerkWh={priceElectricityPerkWh}
                    electriciteEclairageConsumptionRate={
                      electriciteEclairageConsumptionRate
                    }
                    setElectriciteEclairageConsumptionRate={
                      setElectriciteEclairageConsumptionRate
                    }
                    checkElecEquipmentsDistribution={
                      checkElecEquipmentsDistribution
                    }
                    resetChauffageSection={resetElectriciteChauffageSection}
                    resetECSSection={resetElectriciteECSSection}
                    setElectricitePacAirEauInstallationYear={
                      setElectricitePacAirEauInstallationYear
                    }
                    setElectricitePacAirAirInstallationYear={
                      setElectricitePacAirAirInstallationYear
                    }
                    electricitePacAirEauInstallationYear={
                      electricitePacAirEauInstallationYear
                    }
                    electricitePacAirAirInstallationYear={
                      electricitePacAirAirInstallationYear
                    }
                  />
                )}

                {/*section sources d’énergie when enedis ok (we have consumption data)*/}
                {consumptionDataOk ? (
                  <EnedisOKPartForm
                    sourceEnergie={sourceEnergie}
                    setSourceEnergie={setSourceEnergie}
                  />
                ) : null}

                {/*section gaz*/}
                {isGaz && (
                  <GazPartForm
                    studyInformation={studyInformation}
                    gazConsommationEuros={gazConsommationEuros}
                    isGaz={isGaz}
                    setGazConsommationEuros={setGazConsommationEuros}
                    gazConsommationKwh={gazConsommationKwh}
                    setGazConsommationKwh={setGazConsommationKwh}
                    gazPrincipauxEquipments={gazPrincipauxEquipments}
                    setGazPrincipauxEquipments={setGazPrincipauxEquipments}
                    gazSurfaceChauffee={gazSurfaceChauffee}
                    setGazSurfaceChauffee={setGazSurfaceChauffee}
                    gazTemperatureConfort={gazTemperatureConfort}
                    setGazTemperatureConfort={setGazTemperatureConfort}
                    gazTypeChauffage={gazTypeChauffage}
                    setGazTypeChauffage={setGazTypeChauffage}
                    gazNombreEmetteur={gazNombreEmetteur}
                    setGazNombreEmetteur={setGazNombreEmetteur}
                    gazTypeEmetteur={gazTypeEmetteur}
                    setGazTypeEmetteur={setGazTypeEmetteur}
                    gazStorageCapacity={gazStorageCapacity}
                    setGazStorageCapacity={setGazStorageCapacity}
                    gazChauffageConsumptionRate={gazChauffageConsumptionRate}
                    setGazChauffageConsumptionRate={
                      setGazChauffageConsumptionRate
                    }
                    gazECSConsumptionRate={gazECSConsumptionRate}
                    setGazECSConsumptionRate={setGazECSConsumptionRate}
                    gazAutresConsumptionRate={gazAutresConsumptionRate}
                    setGazAutresConsumptionRate={setGazAutresConsumptionRate}
                    sourceEnergie={sourceEnergie}
                    gazIsECSIntegratedToHeatingSystem={
                      gazIsECSIntegratedToHeatingSystem
                    }
                    setGazIsECSIntegratedToHeatingSystem={
                      setGazIsECSIntegratedToHeatingSystem
                    }
                    setPriceGazPerkWh={setPriceGazPerkWh}
                    priceGazPerkWh={priceGazPerkWh}
                    checkGazEquipmentsDistribution={
                      checkGazEquipmentsDistribution
                    }
                    resetChauffageSection={resetGazChauffageSection}
                    resetECSSection={resetGazECSSection}
                  />
                )}

                {/*section fioul*/}
                {isFioul && (
                  <FioulPartForm
                    studyInformation={studyInformation}
                    fioulConsommationEuros={fioulConsommationEuros}
                    isFioul={isFioul}
                    setFioulConsommationEuros={setFioulConsommationEuros}
                    fioulPrincipauxEquipments={fioulPrincipauxEquipments}
                    setFioulPrincipauxEquipments={setFioulPrincipauxEquipments}
                    fioulSurfaceChauffee={fioulSurfaceChauffee}
                    setFioulSurfaceChauffee={setFioulSurfaceChauffee}
                    fioulTemperatureConfort={fioulTemperatureConfort}
                    setFioulTemperatureConfort={setFioulTemperatureConfort}
                    fioulTypeChauffage={fioulTypeChauffage}
                    setFioulTypeChauffage={setFioulTypeChauffage}
                    fioulNombreEmetteur={fioulNombreEmetteur}
                    setFioulNombreEmetteur={setFioulNombreEmetteur}
                    fioulTypeEmetteur={fioulTypeEmetteur}
                    setFioulTypeEmetteur={setFioulTypeEmetteur}
                    fioulStorageCapacity={fioulStorageCapacity}
                    setFioulStorageCapacity={setFioulStorageCapacity}
                    fioulChauffageConsumptionRate={
                      fioulChauffageConsumptionRate
                    }
                    setFioulChauffageConsumptionRate={
                      setFioulChauffageConsumptionRate
                    }
                    fioulECSConsumptionRate={fioulECSConsumptionRate}
                    setFioulECSConsumptionRate={setFioulECSConsumptionRate}
                    sourceEnergie={sourceEnergie}
                    checkFioulEquipmentsDistribution={
                      checkFioulEquipmentsDistribution
                    }
                    resetChauffageSection={resetFioulChauffageSection}
                    resetECSSection={resetFioulECSSection}
                  />
                )}

                {/*section bois*/}
                {isBois && (
                  <BoisPartForm
                    studyInformation={studyInformation}
                    boisConsommationEuros={boisConsommationEuros}
                    isBois={isBois}
                    setBoisConsommationEuros={setBoisConsommationEuros}
                    boisSurfaceChauffee={boisSurfaceChauffee}
                    setBoisSurfaceChauffee={setBoisSurfaceChauffee}
                    boisTemperatureConfort={boisTemperatureConfort}
                    setBoisTemperatureConfort={setBoisTemperatureConfort}
                    boisTypeChauffage={boisTypeChauffage}
                    setBoisTypeChauffage={setBoisTypeChauffage}
                    boisNombreEmetteur={boisNombreEmetteur}
                    setBoisNombreEmetteur={setBoisNombreEmetteur}
                    boisTypeEmetteur={boisTypeEmetteur}
                    setBoisTypeEmetteur={setBoisTypeEmetteur}
                    sourceEnergie={sourceEnergie}
                  />
                )}

                {/*section gpl*/}

                {isGPL && (
                  <GPLPartForm
                    studyInformation={studyInformation}
                    GPLConsommationEuros={GPLConsommationEuros}
                    isGPL={isGPL}
                    setGPLConsommationEuros={setGPLConsommationEuros}
                    GPLPrincipauxEquipments={GPLPrincipauxEquipments}
                    setGPLPrincipauxEquipments={setGPLPrincipauxEquipments}
                    GPLSurfaceChauffee={GPLSurfaceChauffee}
                    setGPLSurfaceChauffee={setGPLSurfaceChauffee}
                    GPLTemperatureConfort={GPLTemperatureConfort}
                    setGPLTemperatureConfort={setGPLTemperatureConfort}
                    GPLTypeChauffage={GPLTypeChauffage}
                    setGPLTypeChauffage={setGPLTypeChauffage}
                    GPLNombreEmetteur={GPLNombreEmetteur}
                    setGPLNombreEmetteur={setGPLNombreEmetteur}
                    GPLTypeEmetteur={GPLTypeEmetteur}
                    setGPLTypeEmetteur={setGPLTypeEmetteur}
                    GPLStorageCapacity={GPLStorageCapacity}
                    setGPLStorageCapacity={setGPLStorageCapacity}
                    GPLChauffageConsumptionRate={GPLChauffageConsumptionRate}
                    setGPLChauffageConsumptionRate={
                      setGPLChauffageConsumptionRate
                    }
                    GPLECSConsumptionRate={GPLECSConsumptionRate}
                    setGPLECSConsumptionRate={setGPLECSConsumptionRate}
                    sourceEnergie={sourceEnergie}
                    checkGPLEquipmentsDistribution={
                      checkGPLEquipmentsDistribution
                    }
                    resetChauffageSection={resetGPLChauffageSection}
                    resetECSSection={resetGPLECSSection}
                  />
                )}

                <div className="MesConsommationsAndEquipementsRG__MainButton">
                  <CTAButton
                    type="submit"
                    ref={buttonSubmitRef}
                    name="Suivant"
                    isLoading={isLoadingSaveConso}
                    isDisabled={isNextButtonDisabled}
                  />
                </div>
              </form>
            </div>
          </div>
        </Layout>
      )}
    </div>
  );
};
