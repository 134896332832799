import { Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { DSColors } from "@web/shared/src/styles/variables";

// Components
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfReference } from "../../components/Pdf/Pdf-Etude/PdfReference/PdfReference";
import { PdfPerson } from "../../components/Pdf/Pdf-Etude/PdfPerson/PdfPerson";

// Interfaces import
import {
  IStudyInformation,
  ICommercialUserInformation,
} from "../../interfaces/generalInterfaces";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

// Images
import couvertureHaut from "../../assets/pdf/images/couvertureHaut.png";
import couvertureBasRG from "../../assets/pdf/images/couvertureBasRG.jpg";

interface IProps {
  studyInformation: IStudyInformation;
  emailInputValue: string;
  firstNameInputValue: string;
  lastNameInputValue: string;
  phoneNumberInputValue: string;
  commercialUserInformation: ICommercialUserInformation;
  documentId: string;
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  containerTitles: {
    marginTop: 50,
    marginBottom: 50,
  },
  titlePdf: {
    fontSize: 22,
    fontFamily: "DMSansBold",
    color: `${DSColors.OffBlack}`,
    marginBottom: 10,
  },
  subtitlePdf: {
    fontSize: 14,
    fontFamily: "DMSansMedium",
    color: `${DSColors.PlanetGreen}`,
  },

  imageCouvertureBas: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
  },
});

export const PdfEtudeRGHomePage = ({
  studyInformation,
  emailInputValue,
  firstNameInputValue,
  lastNameInputValue,
  phoneNumberInputValue,
  commercialUserInformation,
  documentId,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Page size="A4" style={styles.page}>
      <Image src={couvertureHaut} />

      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View>
          <View style={localStyle.containerTitles}>
            <Text style={localStyle.titlePdf}>Rénovation globale</Text>
            <Text style={localStyle.subtitlePdf}>
              Habitez plus confortablement
            </Text>
            <Text style={localStyle.subtitlePdf}>
              et économisez durablement
            </Text>
          </View>
          <View>
            <PdfReference reference={documentId} />
            <PdfPerson
              title={`${capitalize(firstNameInputValue)} ${capitalize(
                lastNameInputValue
              )}`}
              data={[
                <>
                  {studyInformation.address}, {studyInformation.zipcode}
                </>,
                <>{emailInputValue.toLowerCase()}</>,
                <>{phoneNumberInputValue}</>,
              ]}
            />
            <PdfPerson
              title={"Votre expert énergétique :"}
              data={[
                <>
                  {capitalize(commercialUserInformation.userFirstName)}{" "}
                  {capitalize(commercialUserInformation.userLastName)}
                </>,
                <>{commercialUserInformation.userEmail?.toLowerCase()}</>,
                <>{commercialUserInformation.userPhoneNumber}</>,
              ]}
            />
          </View>
        </View>
      </PdfLayoutEtude>
      <Image style={localStyle.imageCouvertureBas} src={couvertureBasRG} />
    </Page>
  );
};
