import { AxiosResponse } from "axios";
import {
  IScenario,
  IStudyInformation,
  IUserAuthInfo,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";
import { CreateOree2Study } from "../MesInformations/useCreateOree2Study";

// Interface delcaration
interface Props {
  studyInformation: IStudyInformation;
  userAuthInfo: IUserAuthInfo;
  scenarioChosen?: IScenario;
  createOree2Study: CreateOree2Study;
  saveCartProducts: ({
    userToken,
    study_id,
    cartProducts,
  }: {
    userToken: string | null;
    study_id?: string | null;
    cartProducts:
      | IDataProduct[]
      | {
          product_type: string;
          sage_reference: string;
          selected_characteristics: {
            kit_home_management: boolean;
            n_batteries: number;
          };
        }[];
  }) => Promise<void | AxiosResponse<unknown>>;
  saveLastPageVisited: ({
    userToken,
    study_id,
    last_page_visited,
  }: {
    userToken: string | null;
    study_id: string | null;
    last_page_visited: string;
  }) => Promise<void>;
}

// Allows to switch from a becqe study to "renovation-globale" study, taking with it all the current becqe data-> allowing upselling
export const switchToRenoGlobaleStudy = async ({
  studyInformation,
  saveCartProducts,
  createOree2Study,
  userAuthInfo,
  scenarioChosen,
  saveLastPageVisited,
}: Props) => {
  // Create new "renovation-globale" study
  const response = await createOree2Study({
    userToken: userAuthInfo?.userToken,
    study_id: null,
    study_type: "renovation-globale",
    customer_id: studyInformation.customer_id,
    firstname: studyInformation.firstname,
    lastname: studyInformation.lastname,
    email: studyInformation.email?.toLocaleLowerCase() || null,
    phonenumber: studyInformation.phonenumber,
    zipcode: studyInformation.zipcode,
    address: studyInformation.address,
    latitude: studyInformation.latitude,
    longitude: studyInformation.longitude,
    city: studyInformation.city,
    becqe_firstname: studyInformation.becqe_firstname,
    becqe_lastname: studyInformation.becqe_lastname,
    how_did_you_know_us: studyInformation.how_did_you_know_us,
    existing_household_id: studyInformation.household_id,
  });

  // Save PV product in "renovation-globale" cart while updated the last page visited to land to the "Mon logement" page in RG parcours
  if (response && response.data.study_id) {
    const [responseSaveCartProducts] = await Promise.all([
      saveCartProducts({
        userToken: userAuthInfo?.userToken,
        study_id: response.data.study_id,
        cartProducts: [
          // We don't need to save more information as each time we get the cart's products in RG we get all the updated product information from the data API using the sage reference as params
          {
            product_type: "PV",
            sage_reference: scenarioChosen?.sage_reference_PV || "",
            selected_characteristics: {
              kit_home_management: scenarioChosen?.kit_home_management || false,
              n_batteries: scenarioChosen?.number_of_batteries || 0,
            },
          },
        ],
      }),

      // If the study is redirecting to an existing study, we don't save the last page visited as there is already a last page visited saved
      response.data.isRedirectToExistingStudy
        ? null
        : saveLastPageVisited({
            userToken: userAuthInfo?.userToken,
            study_id: response.data.study_id,
            last_page_visited: "my_informations",
          }),
    ]);

    // If product is successfully saved, redirect to "renovation-globale" study
    if (responseSaveCartProducts && responseSaveCartProducts.status === 200) {
      window.location.href = `/etude/renovation-globale/${response.data.study_id}`;
    }
  }
};
