import "./PotentielSolaireKOForm.scss";

// Libaries import
import { useState, FormEvent } from "react";

// Images import
import HouseIcon from "../../assets/images/house-icon.svg";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { ChoixUnique } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixUnique/ChoixUnique";
import { Layout } from "../../components/Layout/Layout";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { DropdownV2 } from "@web/shared/dist/components/DesignSystem/Dropdowns/DropdownV2/DropdownV2";

// Services import
import { useAppSelector, useAppDispatch } from "../../redux/store/hook";
import {
  setSolarPotentialStepsStatusAction,
  setStudyInfoAction,
} from "../../redux/appActions";
import { ISavePotentielSolaireProps } from "../../services/PotentielSolaire/useSavePotentielSolaire";
import { useSaveLastPageVisited } from "../../services/Oree2/useSaveLastPageVisited";

// Data import
import {
  roofMaterialOptions,
  expositionOptions,
  masqueSolaireOptions,
  typeMaterialOptions,
} from "../../data/PotentielSolaireKOFormData";

// Interfaces import
import { INavigateData } from "../../services/useNavigation/useNavigation";
import { AxiosResponse } from "axios";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";

// Local interface declaration
interface Props {
  goNext: (data?: INavigateData<any>) => void;
  buttonSubmitRef: React.RefObject<HTMLButtonElement>;
  userToken: string | null;
  savePotentielSolaire: ({
    userToken,
    study_id,
    payload,
  }: ISavePotentielSolaireProps) => Promise<void | AxiosResponse<unknown>>;
  isLoadingSavePotentialSolar: boolean;
  errorMessage: string;
}

export const PotentielSolaireKOForm = ({
  goNext,
  buttonSubmitRef,
  userToken,
  savePotentielSolaire,
  isLoadingSavePotentialSolar,
  errorMessage,
}: Props) => {
  const { saveLastPageVisited } = useSaveLastPageVisited();

  const dispatch = useAppDispatch();

  const { studyInformation } = useAppSelector((state) => state);
  const {
    roof_type: roof_material,
    roof_subtype: type_material,
    roof_solar_mask: solar_mask,
    roof_inclinaison: inclinaison,
    roof_exposition: orientation,
    roof_area_to_equip: area_size,
  } = studyInformation;

  const [roofMaterial, setRoofMaterial] = useState<string | null>(
    roof_material || null
  );

  const [typeMaterial, setTypeMaterial] = useState<string | null>(
    type_material || null
  );
  const [roofOrientation, setRoofOrientation] = useState<string>(
    orientation || ""
  );

  const [solarMask, setSolarMask] = useState<number | null | undefined>(
    solar_mask
  );

  const [inclination, setInclination] = useState<number | null | undefined>(
    inclinaison
  );

  const [areaSize, setAreaSize] = useState<number | null | undefined>(
    area_size
  );

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);

  const onSubmitForm = async (event: FormEvent) => {
    event.preventDefault();

    dispatch(
      setStudyInfoAction({
        studyInformation: {
          roof_type: roofMaterial,
          roof_subtype: typeMaterial,
          roof_inclinaison: inclination,
          roof_exposition: roofOrientation,
          roof_solar_mask: solarMask,
          roof_area_to_equip: areaSize,
          solar_potential_ko: true,
        },
      })
    );
    const response = await savePotentielSolaire({
      userToken,
      study_id: studyInformation.study_id,
      payload: {
        roof_type: roofMaterial,
        roof_subtype: typeMaterial,
        roof_inclinaison: inclination,
        roof_exposition: roofOrientation,
        roof_solar_mask: solarMask,
        roof_area_to_equip: areaSize,
        latitude: studyInformation.latitude,
        longitude: studyInformation.longitude,
        isFormValues: true,
      },
    });

    if (response) {
      saveLastPageVisited({
        last_page_visited: "my_solar_potential",
        study_id: studyInformation?.study_id || null,
        userToken: userToken,
      });
      goNext && goNext();
      return;
    }
    if (errorMessage) {
      setIsErrorModalOpen(true);
    }
  };

  const typeMaterialOptionForDropdown =
    typeMaterialOptions.find((option) => option.type === roofMaterial)
      ?.options || [];

  const areAllFieldsFilled =
    roofMaterial &&
    inclination != null &&
    roofOrientation &&
    solarMask != null &&
    areaSize;

  return (
    <div className="PotentielSolaireKOForm">
      <Modale
        isModaleOpen={isErrorModalOpen}
        title="Erreur sur le formulaire potentiel solaire"
        setShowModal={setIsErrorModalOpen}
        textAlignMiddle
      >
        {errorMessage.split("\n").map((error: string, index: number) => (
          <p key={index}>{error}</p>
        ))}
        <div className="PotentielSolaireKOForm__ButtonContainer">
          <CTAButton
            name="D'accord"
            onClick={() => setIsErrorModalOpen(false)}
          />
        </div>
      </Modale>
      <Layout>
        <form onSubmit={onSubmitForm}>
          <section>
            <div className="PotentielSolaireKOForm__SectionTitle">
              <img src={HouseIcon} alt="Icone maison" />
              <h3>Caractéristiques du pan</h3>
            </div>
            <div className="PotentielSolaireKOForm__VerticalQuestionContainer">
              <label>Type de couverture : </label>
              <ChoixUnique
                optionsList={roofMaterialOptions}
                optionSelected={roofMaterialOptions.find(
                  (option) => option.value === roofMaterial
                )}
                onSelect={(option) => {
                  setRoofMaterial(option.value);
                  setTypeMaterial(null);
                }}
                isRequired={true}
                gap={true}
              />
            </div>
            {typeMaterialOptionForDropdown.length ? (
              <div className="PotentielSolaireKOForm__VerticalQuestionContainer">
                <label>
                  {`Type de ${roofMaterial === "Tôle" ? "tôle" : "tuiles"} :`}
                </label>
                <ChoixUnique
                  optionsList={typeMaterialOptionForDropdown}
                  optionSelected={typeMaterialOptionForDropdown.find(
                    (option) => option.value === typeMaterial
                  )}
                  onSelect={(option) => setTypeMaterial(option.value)}
                  isRequired={typeMaterialOptionForDropdown.length > 0}
                  gap={true}
                />
              </div>
            ) : null}
            <div className="PotentielSolaireKOForm__HorizontalQuestionContainer">
              <label>Surface du pan à équiper :</label>
              <div className="PotentielSolaireKOForm__InputNumber">
                <TextInput
                  value={areaSize?.toString() || ""}
                  type="number"
                  placeholder=""
                  iconType="m²"
                  iconHidden={false}
                  onChange={(event) => {
                    setAreaSize((state) => {
                      return event.target.valueAsNumber;
                    });
                  }}
                  required={true}
                />
              </div>
            </div>

            <div className="PotentielSolaireKOForm__HorizontalQuestionContainer">
              <label>Inclinaison :</label>
              <div className="PotentielSolaireKOForm__InputNumber">
                <TextInput
                  value={inclination?.toString() || ""}
                  type="number"
                  placeholder=""
                  iconType="°"
                  iconHidden={false}
                  onChange={(event) => {
                    setInclination((state) => {
                      return event.target.valueAsNumber;
                    });
                  }}
                  required={true}
                />
              </div>
            </div>

            <div className="PotentielSolaireKOForm__HorizontalQuestionContainer">
              <label>Exposition :</label>
              <div className="PotentielSolaireKOForm__DropDown">
                <DropdownV2
                  placeholder="Selectionner..."
                  id="optionTarifaire"
                  options={expositionOptions}
                  onSelect={(option) => {
                    setRoofOrientation(option.value?.toString() || "");
                  }}
                  values={[
                    expositionOptions.find(
                      (option) => option.value === roofOrientation
                    )?.value || "",
                  ]}
                  isRequired={true}
                />
              </div>
            </div>

            <div className="PotentielSolaireKOForm__HorizontalQuestionContainer">
              <label>Impact du masque solaire :</label>
              <div className="PotentielSolaireKOForm__DropDown">
                <DropdownV2
                  placeholder="Selectionner..."
                  options={masqueSolaireOptions}
                  onSelect={(option) => {
                    setSolarMask(Number(option.value));
                  }}
                  values={[
                    masqueSolaireOptions.find(
                      (option) => option.value === solarMask?.toString()
                    )?.value || "",
                  ]}
                  isRequired={true}
                />
              </div>
            </div>
          </section>
          <div className="PotentielSolaireKOForm__MainButton">
            {(studyInformation.solar_potential_ok ||
              studyInformation.solar_potential_ok === undefined) &&
              studyInformation.solar_potential_ko !== true && (
                <CTAButton
                  name={
                    studyInformation.solar_potential_ok === undefined
                      ? "Retour au chargement"
                      : "Mon potentiel solaire"
                  }
                  onClick={() => {
                    dispatch(
                      setSolarPotentialStepsStatusAction({
                        solarPotentialStepsStatus: {
                          isSolarPotentialKOForm: false,
                          isLoadingScreenDisplayed:
                            studyInformation.solar_potential_ok === undefined,
                        },
                      })
                    );
                  }}
                />
              )}

            <CTAButton
              type="submit"
              ref={buttonSubmitRef}
              name="Suivant"
              isLoading={isLoadingSavePotentialSolar}
              isDisabled={!areAllFieldsFilled}
            />
          </div>
        </form>
      </Layout>
    </div>
  );
};
