import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import { ICommercialUserInformation } from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  freeProduct: IDataProduct | undefined;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  commercialUserInformation: ICommercialUserInformation;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
}

export const PdfFreeProductPage = ({
  documentId,
  freeProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
}: IProps) => {
  const {
    extraChargeWithoutTax: freeProductExtraChargeWithoutTax,
    totalPriceWhithoutTax: freeProductTotalPriceWhithoutTax,
    totalTVA: freeProductTotalTVA,
    totalPriceWithTax: freeProductTotalPriceWithTax,
    totalResteACharge: freeProductTotalResteACharge,
    extraChargeWithTax: freeProductExtraChargeWithTax,
    installationPriceWithTax: freeProductInstallationPriceWithTax,
    managementFeesTTC: freeProductManagementFeesTTC,
    managementFeesHT: freeProductManagementFeesHT,
  } = productPrices({ product: freeProduct, isEligibleRenoGlobal });

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit title={"Produit Libre"} />

          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={1}
              prixUnitaire={freeProduct?.product_price_without_tax || 0}
              TVA={freeProduct?.TVA_percentage || 0}
              montantTTC={
                freeProductTotalPriceWithTax -
                freeProductInstallationPriceWithTax
              }
            />

            {/* ISO */}

            <PdfItemDetail
              title={freeProduct?.product_name || "Produit libre"}
              description={[
                <>
                  {freeProduct?.selected_characteristics
                    .free_product_description ? (
                    <Text>
                      Description +
                      {
                        freeProduct?.selected_characteristics
                          .free_product_description
                      }
                    </Text>
                  ) : null}
                </>,
              ]}
            />

            {(freeProduct?.extra_charges_added?.length || 0) > 0 && (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Spécificité du chantier"
                  quantity={freeProduct?.extra_charges_added?.length}
                  prixUnitaire={freeProductExtraChargeWithoutTax || 0}
                  TVA={freeProduct?.TVA_percentage || 0}
                  montantTTC={freeProductExtraChargeWithTax || 0}
                />
                {freeProduct?.extra_charges_added?.map((extraCharge) => {
                  return (
                    <PdfRowProduit
                      key={extraCharge.product_name}
                      name={extraCharge.product_name}
                      quantity={1}
                      prixUnitaire={extraCharge.price_without_tax}
                      TVA={extraCharge.TVA_percentage}
                      montantTTC={extraCharge.price_with_tax}
                      description={extraCharge.description}
                    />
                  );
                })}
              </View>
            )}
            <View wrap={false}>
              {freeProductManagementFeesTTC !== 0 ? (
                <>
                  <PdfRowBackgroundProduit
                    name="Frais de gestion"
                    quantity={1}
                    prixUnitaire={freeProductManagementFeesHT}
                    TVA={freeProduct?.installation_tva_percentage}
                    montantTTC={freeProductManagementFeesTTC}
                  />
                  <View style={styles.inlineComponents}>
                    <Text>
                      Prise en charge des démarches administratives et gestion
                      de projet/SAV
                    </Text>
                  </View>
                  <View style={styles.inlineComponents}>
                    <Text style={{ marginBottom: 10 }}>
                      Constitution des dossiers d’aides financières
                      éventuellement liées au projet
                    </Text>
                  </View>

                  <PdfRowBackgroundProduit
                    name="Frais de gestion offerts"
                    montantTTC={-freeProductManagementFeesTTC}
                  />
                </>
              ) : null}
            </View>

            <PdfTotalContainer
              totalHT={freeProductTotalPriceWhithoutTax}
              totalTVA={freeProductTotalTVA}
              totalTTC={freeProductTotalPriceWithTax}
              totalHelp={0}
              resteACharge={freeProductTotalResteACharge}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>

      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
