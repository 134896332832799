import { View, Text, StyleSheet, Font } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../../../assets/pdf/fonts/DMSansBold.ttf";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  containerInfos__containerReference: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: 9,
    marginBottom: 20,
  },
  containerInfos__containerReference__reference: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    borderRadius: 20,
    padding: "1 5",
    color: "white",
    fontFamily: "DMSansBold",
    marginRight: 5,
  },
  containerInfos__containerReference__number: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
  },
});

interface Props {
  reference: string;
}
export const PdfReference = ({ reference }: Props) => {
  return (
    <View style={styles.containerInfos__containerReference}>
      <Text style={styles.containerInfos__containerReference__reference}>
        Référence
      </Text>
      <Text style={styles.containerInfos__containerReference__number}>
        {reference}
      </Text>
    </View>
  );
};
