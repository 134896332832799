//Style import
import "./ChartComponentRG.scss";

//Libraries import
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

//Services import
import { useState } from "react";
import { addBatteryData } from "../../services/OptionChosen/addBatteryData";
import { hourlyConsumptionProductionDataFormat } from "../../services/OptionChosen/hourlyConsumptionProductionDataFormat";

//Interfaces import
import { IHourlyConsumptionProductionData } from "../../interfaces/generalInterfaces";

//Picto import
import { CustomIcon } from "./CustomLegendIcon/CustomLegendIcon";

//Local interface
interface IProps {
  dataChart: IHourlyConsumptionProductionData | null;
  batteryQuantity?: number | null;
  withoutText?: boolean;
  removeAnimation?: boolean;
  isKitHomeManagement?: boolean;
  isForPdf?: boolean;
  chartHeight?: string | number | undefined;
}

interface tooltipDataToDisplay {
  title: string;
  titleColor: string;
  text: string;
}

export const ChartComponentRG = ({
  dataChart,
  batteryQuantity = 0,
  isKitHomeManagement = false,
  withoutText,
  removeAnimation = false,
  isForPdf = false,
  chartHeight = 300,
}: IProps) => {
  const [isAreaClicked, setIsAreaClicked] = useState<boolean[]>([
    false,
    false,
    false,
  ]);

  const [tooltipAreaInformation, setTooltipAreaInformation] =
    useState<tooltipDataToDisplay | null>(null);

  const onGraphAreasClick = (index: number) => {
    setTooltipAreaInformation(possibleDataToDisplay[index]);
    setIsAreaClicked((prevState) => {
      const newState = prevState.map(() => false);
      newState[index] = !prevState[index];
      return newState;
    });
  };

  const dataChartFormated = hourlyConsumptionProductionDataFormat(dataChart);

  const totalProduction = dataChartFormated
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.production,
      0
    )
    .toFixed(1);

  const totalConsumption_shifted = dataChartFormated
    .reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.consumption_shifted,
      0
    )
    .toFixed(1);

  const totalConsumption = dataChartFormated.reduce(
    (accumulator, currentValue) => accumulator + currentValue.consumption,
    0
  );

  const totalAutoConso = dataChartFormated
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.autoconso,
      0
    )
    .toFixed(1);

  const possibleDataToDisplay = [
    {
      title: `${totalProduction} kWh`,
      titleColor: "#F0A14E",
      text: "Surplus de production photovoltaïque",
    },
    {
      title: `${totalConsumption_shifted} kWh`,
      titleColor: "#A8AAAF",
      text: isKitHomeManagement
        ? "Consommation électrique optimisée grâce au kit de Home Management"
        : "Consommation électrique",
    },
    {
      title: `${totalAutoConso} kWh`,
      titleColor: "#74DDD7",
      text: "Autoconsommation",
    },
  ];

  const dataWithBatterie = batteryQuantity
    ? addBatteryData(dataChartFormated, batteryQuantity)
    : null;

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  return (
    <div className="ChartComponentRG">
      {!isForPdf && !withoutText && (
        <p>
          Courbes de consommation électrique et de production solaire
          journalière
        </p>
      )}
      {!isForPdf && totalConsumption !== 0 && !withoutText && (
        <div className="ChartComponentRG__checkboxSection">
          <input
            id="checkboxConsuption"
            type="checkbox"
            checked={isCheckboxChecked}
            onChange={() => {
              setIsCheckboxChecked(!isCheckboxChecked);
            }}
          ></input>
          <label htmlFor="checkboxConsuption">
            Comparer avec ma courbe de consommation actuelle
          </label>
        </div>
      )}

      {isAreaClicked.includes(true) && (
        <div className="ChartComponentRG__onAreaClickTooltip">
          <p
            className="title"
            style={{ color: tooltipAreaInformation?.titleColor || "" }}
          >
            {tooltipAreaInformation?.title}
          </p>
          <p className="content">{tooltipAreaInformation?.text}</p>
        </div>
      )}
      <ResponsiveContainer width="95%" height={chartHeight}>
        <ComposedChart
          data={dataWithBatterie ? dataWithBatterie : dataChartFormated}
          margin={{
            left: 20,
            top: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="production"
            strokeOpacity={0}
            fillOpacity={isAreaClicked[1] || isAreaClicked[2] ? 0.5 : 1}
            fill={"#F0A14E"}
            onClick={() => onGraphAreasClick(0)}
            cursor="pointer"
          />

          <Area
            type="monotone"
            dataKey="consumption_shifted"
            strokeOpacity={0}
            fillOpacity={isAreaClicked[0] || isAreaClicked[2] ? 0.5 : 1}
            fill={"#A8AAAF"}
            onClick={() => onGraphAreasClick(1)}
            cursor="pointer"
          />
          {dataWithBatterie && (
            <Area
              type="monotone"
              dataKey="batteryStorage"
              fillOpacity={0.5}
              fill="#ffffff"
              stroke="#000000"
              strokeDasharray="5 5"
              legendType="none"
              isAnimationActive={!removeAnimation}
              animationDuration={5000}
              display={
                isAreaClicked[0] || isAreaClicked[1] || isAreaClicked[2]
                  ? "none"
                  : ""
              }
            />
          )}
          <Area
            type="monotone"
            dataKey="autoconso"
            strokeOpacity={0}
            fillOpacity={isAreaClicked[0] || isAreaClicked[1] ? 0.5 : 1}
            fill={"#74DDD7"}
            onClick={() => onGraphAreasClick(2)}
            cursor="pointer"
          />

          {isCheckboxChecked && (
            <Line
              type="monotone"
              dataKey="consumption"
              stroke="#50545F"
              strokeDasharray="3 3"
              dot={false}
            />
          )}
          <XAxis
            dataKey="hour"
            tick={<CustomIcon />}
            ticks={[2, 7, 12, 17, 21]}
            tickLine={false}
            interval="preserveEnd"
            allowDataOverflow={true}
          ></XAxis>
          <YAxis
            className="MesConsommationsChart__LabelYaxis"
            unit={" kWh"}
            label={{
              value: "Puissance",
              angle: -90,
              position: "insideLeft",
              dy: 20,
              dx: -10,
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
