import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import moment from "moment";
import logoCO2 from "../../../assets/images/logoCO2.svg";
import logoConso from "../../../assets/images/logoConso.svg";
import { Layout } from "../../Layout/Layout";

import "./mesInfosSupplementaires.scss";

//Local interface
interface IProps {
  data: {
    CO2_emissions_associated_to_consumptions: number;
    average_consumption_peak: {
      start: string;
      end: string;
    }[];
  };
}

export const MesInfosSupplementaires = ({ data }: IProps) => {
  return (
    <div className="MesInfosSupplementaires">
      <Layout>
        <div className="MesInfosSupplementaires__CardContainer">
          <div className="MesInfosSupplementaires__Card">
            <div className="MesInfosSupplementaires__Logo">
              <img src={logoCO2} alt="logo CO2" />
            </div>
            <div className="MesInfosSupplementaires__Text">
              <h5>émissions carbone liées à la consommation</h5>
              <p>{data.CO2_emissions_associated_to_consumptions} gCO2/kWh</p>
            </div>
          </div>
          <div className="MesInfosSupplementaires__Card">
            <div className="MesInfosSupplementaires__Logo">
              <img src={logoConso} alt="logo Conso" />
            </div>
            {data.average_consumption_peak.length === 0 && (
              <div className="MesInfosSupplementaires__Text">
                <h5>pic(s) de consommation moyen(s)</h5>
                <p>
                  Votre consommation est répartie uniformément tout au long de
                  la journée.
                </p>
              </div>
            )}
            {data.average_consumption_peak.length === 1 && (
              <div className="MesInfosSupplementaires__Text">
                <h5>pic(s) de consommation moyen(s)</h5>
                <p>
                  entre{" "}
                  {moment(data.average_consumption_peak[0].start, "H:mm:ss")
                    .format("H:mm")
                    .toString()}
                  h et{" "}
                  {moment(data.average_consumption_peak[0].end, "H:mm:ss")
                    .format("H:mm")
                    .toString()}
                  h
                </p>
              </div>
            )}
            {data.average_consumption_peak.length === 2 && (
              <div className="MesInfosSupplementaires__Text">
                <h5>pic(s) de consommation moyen(s)</h5>
                <p>
                  entre{" "}
                  {moment(data.average_consumption_peak[0].start, "H:mm:ss")
                    .format("H:mm")
                    .toString()}
                  h et{" "}
                  {moment(data.average_consumption_peak[0].end, "H:mm:ss")
                    .format("H:mm")
                    .toString()}
                  h et entre{" "}
                  {moment(
                    data.average_consumption_peak[1].start,
                    "H:mm:ss"
                  ).format("H:mm".toString())}
                  h et{" "}
                  {moment(data.average_consumption_peak[1].end, "H:mm:ss")
                    .format("H:mm")
                    .toString()}
                  h
                </p>
              </div>
            )}
          </div>
        </div>
      </Layout>
      <div className="MesInfosSupplementaires__ButtonContainer">
        <CTAButton name="Suivant" />
      </div>
    </div>
  );
};
