// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";

// Local interface declaration
interface IProps {
  userToken: string | null;
  projectId: string;
}

export interface IProjectImages {
  fileName: string;
  fileExtension: string;
  base64EncodedContent: string;
}

export const useGetProjectImages = () => {
  const {
    isLoading,
    errorMessage: axiosErrorMessage,
    axiosGetRequest,
  } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const getProjectImages = async ({ userToken, projectId }: IProps) => {
    setErrorMessage("");

    const response = await axiosGetRequest<{ projectImage: IProjectImages[] }>(
      `${process.env.REACT_APP_EERO_URL}/oree2/projects/${projectId}/images`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if ((response?.projectImage.length || 0) > 0) {
      return (
        // We filter the file response to only get images and remove .docx or .pdf
        response?.projectImage.filter(
          (image) =>
            image.fileExtension === ".png" ||
            image.fileExtension === ".jpg" ||
            image.fileExtension === ".jpeg" ||
            image.fileExtension === ".svg"
        ) || []
      );
    }
    setErrorMessage("Pas d'image disponible pour ce projet");
    return [];
  };

  return {
    getProjectImages,
    isLoading,
    errorMessage: axiosErrorMessage || errorMessage,
  };
};
