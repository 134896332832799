import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfSubTitleProduct } from "../../components/Pdf/Pdf-Devis/PdfSubTitleProduct/PdfSubTitleProduct";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
  ISageProduct,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  ISOPlancher: IDataProduct | undefined;
  ISOPlancherSageProduct: ISageProduct | undefined;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  commercialUserInformation: ICommercialUserInformation;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
}

export const PdfISOPlancherPage = ({
  documentId,
  ISOPlancher,
  ISOPlancherSageProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
  devisOptions,
}: IProps) => {
  const {
    extraChargeWithoutTax: ISOPlancherExtraChargeWithoutTax,
    totalPriceWhithoutTax: ISOPlancherTotalPriceWhithoutTax,
    totalTVA: ISOPlancherTotalTVA,
    totalPriceWithTax: ISOPlancherTotalPriceWithTax,
    totalHelp: ISOPlancherTotalHelp,
    totalResteACharge: ISOPlancherTotalResteACharge,
    extraChargeWithTax: ISOPlancherExtraChargeWithTax,
    installationPriceWithTax: ISOPlancherInstallationPriceWithTax,
    managementFeesTTC: ISOPlancherManagementFeesTTC,
    managementFeesHT: ISOPlancherManagementFeesHT,
    totalDeductibleHelps: ISOPlancherTotalDeductibleHelps,
    totalRefundableHelps: ISOPlancherTotalRefundableHelps,
  } = productPrices({
    product: ISOPlancher,
    isEligibleRenoGlobal,
    devisOptions,
  });

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              ISOPlancher?.product_alias ||
              ISOPlancher?.product_name ||
              "Produit NC"
            }
          />
          <PdfSubTitleProduct
            title={
              "Référence fabricant : " +
                ISOPlancherSageProduct?.["Référence fabricant"] || "NC"
            }
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={
                ISOPlancher?.selected_characteristics?.surface_to_isolate || 0
              }
              prixUnitaire={ISOPlancher?.product_price_without_tax || 0}
              TVA={ISOPlancher?.TVA_percentage || 0}
              montantTTC={
                ISOPlancherTotalPriceWithTax -
                ISOPlancherInstallationPriceWithTax
              }
            />

            {/* ISO */}
            {ISOPlancherSageProduct ? (
              <>
                <Text style={{ marginBottom: 5 }}>
                  Mise en place d’une isolation thermique d'un plancher bas
                </Text>
                <PdfItemDetail
                  key={ISOPlancherSageProduct.AR_Ref}
                  title={
                    "Isolant " +
                    ISOPlancherSageProduct.Marque +
                    " " +
                    ISOPlancherSageProduct.Gamme +
                    " " +
                    ISOPlancherSageProduct.Dimensions
                  }
                  description={[
                    <>
                      {ISOPlancher?.product_name && (
                        <Text>{ISOPlancher?.product_name}</Text>
                      )}
                    </>,
                    <>
                      {ISOPlancherSageProduct[
                        "Classe energétique (Froid/Chaud"
                      ] && (
                        <Text>
                          {ISOPlancherSageProduct[
                            "Classe energétique (Froid/Chaud"
                          ] &&
                          ISOPlancherSageProduct[
                            "Classe energétique (Froid/Chaud"
                          ].length > 0
                            ? `${ISOPlancherSageProduct["Classe energétique (Froid/Chaud"]}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ISOPlancherSageProduct.Puissance && (
                        <Text>
                          {ISOPlancherSageProduct.Puissance &&
                          ISOPlancherSageProduct.Puissance.length > 0
                            ? `${ISOPlancherSageProduct.Puissance}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ISOPlancherSageProduct.Description && (
                        <Text>
                          {ISOPlancherSageProduct.Description &&
                          ISOPlancherSageProduct.Description.length > 0
                            ? `${ISOPlancherSageProduct.Description}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ISOPlancherSageProduct.Conditionnement && (
                        <Text>
                          {ISOPlancherSageProduct.Conditionnement &&
                          ISOPlancherSageProduct.Conditionnement.length > 0
                            ? `Dimensions : ${ISOPlancherSageProduct.Conditionnement}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,

                    <>
                      {ISOPlancherSageProduct["Norme et Certification"] &&
                        ISOPlancherSageProduct["Norme et Certification"]
                          ?.length > 0 && (
                          <Text>
                            {ISOPlancherSageProduct["Norme et Certification"]
                              ? `Certifications : ${ISOPlancherSageProduct["Norme et Certification"]}`
                              : null}
                          </Text>
                        )}
                    </>,
                    <>
                      {ISOPlancherSageProduct["Résistance Thermique "] && (
                        <Text>
                          {ISOPlancherSageProduct["Résistance Thermique "] &&
                          ISOPlancherSageProduct["Résistance Thermique "]
                            .length > 0
                            ? `R = ${ISOPlancherSageProduct["Résistance Thermique "]}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                  ]}
                />
              </>
            ) : null}
            <View wrap={false}>
              <PdfRowBackgroundProduit
                name="Forfait installation"
                quantity={
                  ISOPlancher?.selected_characteristics?.surface_to_isolate || 0
                }
                prixUnitaire={ISOPlancher?.installation_price_without_tax || 0}
                TVA={ISOPlancher?.TVA_percentage || 0}
                montantTTC={ISOPlancherInstallationPriceWithTax}
              />
              <View style={styles.inlineComponents}>
                <Text>Déplacement</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Mise en place d'une isolation thermique des planchers bas
                  (avec respect des temps de séchage)
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Fourniture des équipements nécessaires à la pose des matériels
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Gestion des déchets</Text>
              </View>
            </View>
            {(ISOPlancher?.extra_charges_added?.length || 0) > 0 && (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Spécificité du chantier"
                  quantity={ISOPlancher?.extra_charges_added?.length}
                  prixUnitaire={ISOPlancherExtraChargeWithoutTax || 0}
                  TVA={ISOPlancher?.TVA_percentage || 0}
                  montantTTC={ISOPlancherExtraChargeWithTax || 0}
                />
                {ISOPlancher?.extra_charges_added?.map((extraCharge) => {
                  return (
                    <PdfRowProduit
                      key={extraCharge.product_name}
                      name={extraCharge.product_name}
                      quantity={1}
                      prixUnitaire={extraCharge.price_without_tax}
                      TVA={extraCharge.TVA_percentage}
                      montantTTC={extraCharge.price_with_tax}
                      description={extraCharge.description}
                    />
                  );
                })}
              </View>
            )}
            <View wrap={false}>
              {ISOPlancherManagementFeesTTC !== 0 ? (
                <>
                  <PdfRowBackgroundProduit
                    name="Frais de gestion"
                    quantity={1}
                    prixUnitaire={ISOPlancherManagementFeesHT}
                    TVA={ISOPlancher?.installation_tva_percentage}
                    montantTTC={ISOPlancherManagementFeesTTC}
                  />
                  <View style={styles.inlineComponents}>
                    <Text>
                      Prise en charge des démarches administratives et gestion
                      de projet/SAV
                    </Text>
                  </View>
                  <View style={styles.inlineComponents}>
                    <Text style={{ marginBottom: 10 }}>
                      Constitution des dossiers d’aides financières
                      éventuellement liées au projet
                    </Text>
                  </View>

                  <PdfRowBackgroundProduit
                    name="Frais de gestion offerts"
                    montantTTC={-ISOPlancherManagementFeesTTC}
                  />
                </>
              ) : null}
            </View>
            {ISOPlancherTotalHelp && devisOptions.isHelpsDisplayed ? (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Aides"
                  aides={ISOPlancher?.help.map((help) => {
                    return {
                      aide: help.name,
                      montant: help.value,
                      isDeducted:
                        help.help_type === "mpr"
                          ? devisOptions.isMPRHelpsDeducted
                          : help.help_type === "cee"
                          ? devisOptions.isCEEHelpsDeducted
                          : false,
                    };
                  })}
                />
              </View>
            ) : null}
            <PdfTotalContainer
              devisOptions={devisOptions}
              totalHT={ISOPlancherTotalPriceWhithoutTax}
              totalTVA={ISOPlancherTotalTVA}
              totalTTC={ISOPlancherTotalPriceWithTax}
              totalHelp={ISOPlancherTotalHelp}
              resteACharge={ISOPlancherTotalResteACharge}
              deductibleHelp={ISOPlancherTotalDeductibleHelps}
              refundableHelp={ISOPlancherTotalRefundableHelps}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>

      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
