// Libraries import
import { useState, useRef, WheelEventHandler } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

// Styles import
import { DSColors } from "../../../../styles/variables";

// Service import
import { useTriggerFunctionWhenClickOutsideOfElement } from "../../../../services/useTriggerFunctionWhenClickOutsideOfElement";

//Images import
import arrowDropdown from "../../../../assets/images/DesignSystemIcons/arrowDropdown.svg";
import checkboxDropdownBlue from "../../../../assets/images/DesignSystemIcons/checkboxDropdownBlue.svg";
import checkboxDropdownBlueFilled from "../../../../assets/images/DesignSystemIcons/checkboxDropdownBlueFilled.svg";
import checkboxDropdown from "../../../../assets/images/DesignSystemIcons/checkboxDropdown.svg";

// Local interfaces declaration
interface IOption {
  label: string | null;
  value: string | null | number;
  color?: string;
}

/** options, values and onSelect are mandatory **/
export interface SelectDropdownV2Props {
  /** list of options shown in the dropdown **/
  options: IOption[];
  /** list of options values selected by the user shown in the tag shared component - it is possible to deselect the single option shown **/
  values: string[] | number[];
  /** single option selected by the user shown in the input field **/
  id?: string;
  isRequired?: boolean;
  /** function to execute when the user selects a dropdown option (feeds the list of selected options) **/
  onSelect: (option: IOption) => void;
  /** tells if multiple selections are allowed  **/
  multipleChoices?: boolean;
  /** tells if the options list is searchable  **/
  searchable?: boolean;
  /** true for use outside tables - false for use in tables **/
  background?: boolean;
  /** true to show values instead of lables **/
  showValue?: boolean;
  placeholder?: string;
  fontSize?: number;
  maxHeight?: string;
  isDisabled?: boolean;
  messageIfDisabled?: string;
}

export interface SearchBoxProps {
  searchable?: boolean;
}

export interface BoxesContainerProps {
  values?: string[] | number[];
}

const WrapperButton = styled.div`
  position: relative;
  min-width: 110px;
`;

const InputContainer = styled.div<{
  isSelectOpen: boolean;
  isValueSelected: boolean;
  isSearchable: boolean;
  isBorder: boolean;
  isBackground: boolean;
  isRequired: boolean;
  isDisable?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "DM Sans", serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  background-color: ${(props) =>
    props.isBackground ? `${DSColors.white}` : `none`};
  cursor: ${(props) => (props.isDisable ? "not-allowed" : "pointer")};
  z-index: 1;
  height: 46px;
  padding-left: 1.25rem;
  box-sizing: border-box;
  color: ${(props) =>
    props.isValueSelected ? `${DSColors.OffBlack}` : `${DSColors.StableGrey}`};
  border: ${(props) =>
    props.isSelectOpen && props.isBorder
      ? `1px solid ${DSColors.Mint}`
      : props.isBorder === false
      ? "1px solid rgba(255, 0, 0, 0)"
      : props.isDisable
      ? `1px solid ${DSColors.SGDark01}`
      : `1px solid ${DSColors.SGLight02}`};
  overflow: hidden;
  position: relative;

  &:hover {
    border: ${(props) =>
      props.isDisable
        ? `1px solid ${DSColors.SGDark01}`
        : `1px solid ${DSColors.Mint}`};
    color: ${DSColors.Mint};

    path {
      fill: ${(props) =>
        props.isDisable ? `1px solid ${DSColors.SGDark01}` : DSColors.Mint};
    }
  }
  > input {
    all: unset;
    width: 1px;
    text-align: left;
    caret-color: transparent;
    color: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const DropdownV2Container = styled.ul<{
  isSelectOpen: boolean;
  maxHeight?: string;
}>`
  box-shadow: 0 1.25rem 80px rgba(14, 31, 63, 0.07);
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  display: ${(props) => (props.isSelectOpen ? `block` : `none`)};
  z-index: 2;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : `180px`)};
  overflow-y: scroll;
  position: absolute;
  background-color: ${DSColors.white};
  width: 100%;
`;
const Item = styled.li<{
  isSelectedItem: boolean;
  isMultipleChoices: boolean;
  color: string | undefined;
}>`
  display: flex;
  align-items: center;
  font-family: "DM Sans", serif;
  font-size: 16px;
  padding: 1.25rem 1.25rem 0.75rem 0.75rem;
  cursor: pointer;
  position: relative;
  width: 100%;
  color: ${(props) => (props.color ? props.color : DSColors.StableGrey)};
  display: -webkit-box;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:not(:last-child) {
    &::after {
      content: "";
      display: block;
      width: calc(100% - 24px);
      height: 1px;
      position: absolute;
      bottom: 0;
      background-color: ${DSColors.SGLight04};
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &:first-child {
    padding-top: 24px;
    margin-top: -4px;
  }

  &:hover {
    background-color: ${DSColors.SGLight05};

    &::before {
      background-image: ${(props) =>
        props.isSelectedItem
          ? `url${checkboxDropdownBlueFilled}`
          : `url${checkboxDropdownBlue}`};
    }
  }

  &::before {
    content: "";
    background-image: ${(props) =>
      props.isSelectedItem
        ? `url(${checkboxDropdownBlueFilled})`
        : `url(${checkboxDropdown})`};
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    display: ${(props) => (props.isMultipleChoices ? `block` : `none`)};
  }
`;
const SearchBoxWrapper = styled.div<SearchBoxProps>`
  display: ${(props) => (props.searchable ? `flex` : `none`)};
  justify-content: center;
`;
const SearchBox = styled.input<SearchBoxProps>`
  border: none;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  text-align: left;
  padding: 1.25rem 1.25rem 0.75rem 0.75rem;
  width: 100%;
  border-bottom: 2px solid ${DSColors.SGLight03};
  color: ${DSColors.StableGrey};
`;
const Title = styled.p<{ fontSize?: number; isDisable?: boolean }>`
  font-size: ${(props) => `${props.fontSize}px`};
  color: ${(props) =>
    props.isDisable ? DSColors.SGLight02 : DSColors.SGLight03};
  line-height: initial;
`;
const SingleOptionSelected = styled.p<{
  fontSize?: number;
  showValue?: boolean;
}>`
  font-size: ${(props) => `${props.fontSize}px`};
  color: ${DSColors.OffBlack};
`;
const BoxesContainer = styled.div<BoxesContainerProps>`
  display: flex;
  justify-content: flex-start;
  gap: 3px;
  flex: 0 0 auto;
  width: calc(100% - 60px);
  overflow-x: scroll; /* set overflow-x to scroll */
  overflow-y: hidden; /* hide vertical scroll */

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  scrollbar-width: none;
`;

const ArrowSvg = styled(SVG)<{ opened?: number; active?: number }>`
  position: absolute;
  right: 1.25rem;
  width: 14px;
  height: 7px;
  display: block;
  transform: ${(props) =>
    props.opened === 1 ? "rotate(180deg)" : "rotate(0deg)"};
  transition: all 0.1s ease-in;

  path {
    fill: ${(props) =>
      props.opened === 1
        ? `${DSColors.OffBlack}`
        : props.active === 1
        ? `${DSColors.OffBlack}`
        : `${DSColors.SGLight02}`};
  }
`;

export const DropdownV2 = ({
  options = [],
  values = [],
  onSelect,
  multipleChoices = false,
  searchable = false,
  background = true,
  placeholder = "",
  fontSize = 16,
  showValue = false,
  isRequired = false,
  maxHeight,
  isDisabled = false,
  messageIfDisabled = "",
}: SelectDropdownV2Props) => {
  // Hooks call
  const selectRef = useRef(null);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [optionSearched, setOptionSearched] = useState<string | null>(null);

  // Events handlers
  const handleToggleSelect = () => {
    if (isDisabled) return;
    setIsSelectOpen((state) => !state);
    setOptionSearched("");
  };
  const handleCloseSelect = () => {
    if (isDisabled) return;
    setIsSelectOpen(() => false);
    setOptionSearched("");
  };

  // Close the dropdown when click outside of the dropdown
  useTriggerFunctionWhenClickOutsideOfElement(selectRef, handleCloseSelect);

  const containerRef = useRef<HTMLDivElement>(null);
  const handleWheel: WheelEventHandler<HTMLDivElement> = (event) => {
    const delta = event.deltaX || event.deltaY;
    if (containerRef.current) {
      containerRef.current.scrollLeft += delta;
    }
  };

  return (
    <WrapperButton ref={selectRef} title={isDisabled ? messageIfDisabled : ""}>
      <InputContainer
        isSearchable={!!searchable}
        isValueSelected={!!values[0]}
        onClick={() => {
          if (isDisabled) return;
          handleToggleSelect();
        }}
        isSelectOpen={isSelectOpen}
        isBorder={background ? true : background === false && isSelectOpen}
        isBackground={!!background}
        isRequired={isRequired}
        isDisable={isDisabled}
      >
        {/* Input put here but invisible, to be able to use the "required" option of the browser*/}
        {isRequired ? (
          <input
            required={isRequired}
            onKeyDown={(event) => event.preventDefault()}
            autoComplete="off"
            value={values?.[0] ?? undefined}
            onChange={() => {}}
          />
        ) : null}
        {!isDisabled ? (
          <ArrowSvg
            active={!!values[0] ? 1 : 0}
            opened={isSelectOpen ? 1 : 0}
            src={arrowDropdown}
          />
        ) : null}
        <BoxesContainer
          ref={containerRef}
          onWheel={handleWheel}
          values={values}
        >
          {/*The placeholder is not shown if there is a value*/}
          <Title fontSize={fontSize} hidden={!!values[0]} isDisable>
            {placeholder}
          </Title>
          {values[0] && values.length === 1 ? (
            <SingleOptionSelected showValue={showValue} fontSize={fontSize}>{`${
              options.find((option) => option.value === values[0])?.label
            }`}</SingleOptionSelected>
          ) : values.length > 1 ? (
            "Choix multiple"
          ) : null}
        </BoxesContainer>
      </InputContainer>
      <DropdownV2Container isSelectOpen={isSelectOpen} maxHeight={maxHeight}>
        {/*if the dropdown list is searchble, an input field appears where to search an option*/}
        <SearchBoxWrapper searchable={searchable}>
          <SearchBox
            placeholder={"Rechercher"}
            onChange={(input) => {
              setOptionSearched(input.target.value.toLowerCase());
            }}
            value={optionSearched || ""}
          />
        </SearchBoxWrapper>
        {/*the options list is shown and filtered based on the user input*/}
        {options
          .filter((elem) =>
            elem.label?.toLowerCase()?.includes(optionSearched || "")
          )
          .map((option, index) => {
            return (
              <Item
                isSelectedItem={
                  !!(
                    multipleChoices &&
                    values?.some(
                      (valueSelected) => valueSelected === option.value
                    )
                  )
                }
                isMultipleChoices={!!multipleChoices}
                key={index}
                onClick={() => {
                  onSelect(option);
                  !multipleChoices && handleCloseSelect();
                }}
                color={option?.color}
              >
                {option.label}
              </Item>
            );
          })}
      </DropdownV2Container>
    </WrapperButton>
  );
};
