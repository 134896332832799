import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import moment from "moment"; // require

// Components
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfBlockSpace } from "../../components/Pdf/Pdf-Devis/PdfBlockSpace/PdfBlockSpace";
import { PdfComplexeParagraphMentions } from "../../components/Pdf/Pdf-Devis/PdfComplexeParagraphMentions/PdfComplexeParagraphMentions";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfList } from "../../components/Pdf/Pdf-Devis/PdfList/PdfList";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfParagraphMentions } from "../../components/Pdf/Pdf-Devis/PdfParagraphMentions/PdfParagraphMentions";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfSousTitreMentions } from "../../components/Pdf/Pdf-Devis/PdfSousTitreMentions/PdfSousTitreMentions";
import { PdfTitleEncadre } from "../../components/Pdf/Pdf-Devis/PdfTitleEncadre/PdfTitleEncadre";
import { PdfTitleWithLign } from "../../components/Pdf/Pdf-Devis/PdfTitleWithLign/PdfTitleWithLign";
import { PdfTitreListe } from "../../components/Pdf/Pdf-Devis/PdfTitreListe/PdfTitreListe";
import { PdfTitreMentions } from "../../components/Pdf/Pdf-Devis/PdfTitreMentions/PdfTitreMentions";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";

// Fonts
import DMSansBold from "../../assets/pdf/fonts/DMSansBold.ttf";
import DMSansMedium from "../../assets/pdf/fonts/DMSansMedium.ttf";
import DMSansRegular from "../../assets/pdf/fonts/DMSansRegular.ttf";

// Interfaces import
import {
  HelpsOptions,
  ICommercialUserInformation,
  IMonInstallation,
  ISageProduct,
  IScenario,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";
import { calculatePVpathPrices } from "../../services/PV/calculatePVpathPrices";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});
Font.register({
  family: "DMSansRegular",
  src: DMSansRegular,
  format: "truetype",
});
Font.register({
  family: "DMSansMedium",
  src: DMSansMedium,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    color: "black",
    position: "relative",
  },
  pagePadding: {
    position: "relative",
    paddingBottom: 50,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  containerInfos: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  containerTable: {
    marginTop: 20,
    color: "#525360",
    fontSize: 8,
    fontFamily: "DMSansRegular",
  },
  firstAbscisse: {
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    marginBottom: 10,
  },
  itemAbscisse: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  itemAbscisseAidePrime: {
    color: "#08B77F",
  },

  descriptionProduits: {
    width: "36%",
  },
  descriptionAides: {
    width: "43%",
  },
  modeVersementAides: {
    width: "43%",
    textAlign: "center",
  },
  montantAides: {
    width: "14%",
    textAlign: "right",
  },
  quantite: {
    width: "13%",
    textAlign: "center",
  },
  prixUnitaireHT: {
    width: "21%",
    textAlign: "center",
  },
  tva: {
    width: "11%",
    textAlign: "center",
  },
  montantTTC: {
    width: "19%",
    textAlign: "right",
  },
  itemDescriptionProduits: {
    fontFamily: "DMSansBold",
  },
  itemDetailDescriptionProduits: {
    marginLeft: 10,
    marginTop: 2,
  },
  itemQuantite: {
    fontFamily: "DMSansBold",
  },
  itemPrixUnitaireHT: {},
  itemTva: {
    fontFamily: "DMSansBold",
  },
  itemMontantTTC: {
    fontFamily: "DMSansBold",
  },
  containerTotalRight: {
    alignSelf: "flex-end",
    width: "50%",
    borderTop: "2px solid #525360",
    padding: "10 0 0 4",
    position: "relative",
  },
  totalItem: {
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    justifyContent: "space-between",
    fontFamily: "DMSansRegular",
    fontSize: 8,
    padding: "4 6",
  },
  totalItemBonusBorder: {
    border: "2px solid #08B77F",
    borderRadius: 4,
    padding: "2 4",
    marginBottom: 5,
  },
  totalItemBonusBackground: {
    backgroundColor: "#08B77F",
    borderRadius: 4,
    padding: "4 6",
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    justifyContent: "space-between",
    fontFamily: "DMSansRegular",
    fontSize: 8,
  },

  totalItemTitle: {
    color: "#525360",
  },
  totalItemNumber: {
    color: "#525360",
    fontFamily: "DMSansBold",
  },
  totalItemTitleBonus: {
    color: "#08B77F",
  },
  totalItemNumberBonus: {
    color: "#08B77F",
    fontFamily: "DMSansBold",
  },
  totalItemTitleRestant: {
    color: "white",
  },
  totalItemNumberRestant: {
    color: "white",
    fontFamily: "DMSansBold",
  },

  containerTotal: {
    display: "flex",
    flexDirection: "row",
  },
  containerDelay: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
    justifyContent: "flex-start",
    fontSize: 8,
    fontFamily: "DMSansRegular",
    color: "#525360",
  },
  delayDate: {
    fontFamily: "DMSansBold",
  },
  textUnderline: {
    display: "flex",
    alignItems: "flex-start",
    textDecoration: "underline",
  },
  containerPagination: {
    position: "absolute",
    bottom: 15,
    right: 15,
  },
  preventInfo: {
    fontFamily: "DMSansRegular",
    fontSize: 8,
    color: "#525360",
    margin: "0 auto 20",
  },
  preventInfoBlack: {
    fontFamily: "DMSansRegular",
    fontSize: 8,
    color: "black",
    margin: "0 auto 20",
    paddingLeft: 10,
  },
  retractationDestinataireCText: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "DMSansRegular",
    fontSize: 8,
    color: "#525360",
  },
  retractationDestinataireCBox: {
    display: "flex",
    fontFamily: "DMSansRegular",
    fontSize: 8,
    color: "#525360",
    backgroundColor: "#f7f7f8",
    borderRadius: 15,
    padding: 15,
    margin: "15 0",
  },
  retractationDestinataireTextBox: {
    marginBottom: 10,
  },
  inlineComponents: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  columnComponents: {
    display: "flex",
    flexDirection: "column",
  },
  fontBold: {
    fontFamily: "DMSansBold",
  },
  fontBoldLarge: {
    fontFamily: "DMSansBold",
    fontSize: 10,
  },
  prochainesEtapes__containerCode: {
    marginTop: 30,
    marginBottom: 30,
    backgroundColor: "#D1F3F2",
    display: "flex",
    flexDirection: "row",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    gap: 30,
  },
  prochainesEtapes__containerCodeLeft: {
    flex: 1.3,
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  prochainesEtapes__containerCodeTextOne: {
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: "DMSansBold",
  },
  prochainesEtapes__containerCodeTextTwo: {
    fontSize: 9,
    fontFamily: "DMSansRegular",
  },
  prochainesEtapes__containerCodeTextThree: {
    fontWeight: "bold",
    fontSize: 9,
    fontFamily: "DMSansBold",
  },
  prochainesEtapes__containerCodeRight: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  prochainesEtapes__containerCodeRightBorder: {
    border: "1px dashed #50545f",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    borderRadius: 5,
    marginLeft: 10,
  },
  prochainesEtapes__containerCodeTextRight: {
    fontSize: 9,
  },
  prochainesEtapes__containerCodeRightCode: {
    fontSize: 9,
    color: "#08B77F",
  },
  prochainesEtapes__premiereEtape__Image: {
    position: "absolute",
    width: 100,
    right: 25,
    top: "50%",
    transform: "translate(0%, -50%)",
  },
  prochainesEtapes__premiereEtape__Arrow: {
    position: "absolute",
    right: 165,
    top: 12,
    width: 70,
  },
});

interface IProps {
  studyInformation: IStudyInformation;
  monInstallation: IMonInstallation;
  scenarioChosen: IScenario;
  SAGEItemsInfo: ISageProduct[];
  commercialUserInformation: ICommercialUserInformation;
  emailInputValue: string;
  firstNameInputValue: string;
  lastNameInputValue: string;
  phoneNumberInputValue: string;
  coupons: { label: string; type: string; value: number }[];
  totalValueCoupon?: number | null;
  documentId: string;
  isDemoVersion?: boolean;
  devisOptions: HelpsOptions;
}
export const PdfDevis = ({
  studyInformation,
  emailInputValue,
  firstNameInputValue,
  lastNameInputValue,
  phoneNumberInputValue,
  commercialUserInformation,
  SAGEItemsInfo,
  scenarioChosen,
  coupons,
  totalValueCoupon,
  documentId,
  isDemoVersion = false,
  devisOptions,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function formatNumberWithSpacesAndDecimal(
    number: number,
    decimalPlaces: number
  ) {
    const parts = number.toFixed(decimalPlaces).split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const decimalPart = parts[1];
    return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
  }

  const priceAllCoupons = () => {
    let total = 0;
    for (let i = 0; i < coupons.length; i++) {
      total += coupons[i].value;
    }
    return total;
  };

  const {
    tvaGlobale,
    totalHelps,
    prixPVHTSansPose,
    prixPVHTAvecPose,
    prixPVTTCAvecPose,
    prixPVTTCSansPose,
    forfaitInstallationHTPV,
    forfaitInstallationTTCPV,
    prixKitHomeManagementHTSansPose,
    prixKitHomeManagmentHTAvecPose,
    prixKitHomeManagementTTCAvecPose,
    prixKitHomeManagementTTCSansPose,
    forfaitInstallationHTKitHomeManagement,
    forfaitInstallationTTCKitHomeManagement,
    batteryPriceWithoutInstallHT,
    batteryPriceWithInstallHT,
    allBatteriesPriceWhitInstallHT,
    allBatteriesPriceWhitInstallTTC,
    allBatteriesPriceWhitoutInstallTTC,
    allBatteriesPriceWhitoutInstallHT,
    batteryInstallPriceHT,
    batteryInstallPriceTTC,
    managementFeesTTC,
    managementFeesHT,
    totalResteACharge,
    resteAChargePhotovoltaique,
    extraFees,
    totalCostTva,
  } = calculatePVpathPrices({ scenarioChosen, coupons });

  const companyFullAddress =
    commercialUserInformation.company_address +
    " " +
    commercialUserInformation.company_postal_code +
    " " +
    commercialUserInformation.company_city;

  const kitHomeManagementSageProduct = SAGEItemsInfo.find(
    (item) => item.AR_Ref === scenarioChosen.kit_sage_reference
  );
  const batterySageProduct = SAGEItemsInfo.find(
    (item) => item.AR_Ref === scenarioChosen.battery_sage_reference
  );

  const pvName =
    scenarioChosen.pv_alias ||
    "Centrale Photovoltaïque " + scenarioChosen.power_needed + " Wc";

  const kitHomeManagementName =
    scenarioChosen.kit_alias ||
    kitHomeManagementSageProduct?.AR_Design ||
    "Kit Home Management";

  const batteryName =
    scenarioChosen.battery_alias ||
    batterySageProduct?.AR_Design ||
    "Batterie(s)";

  return (
    <Document>
      {/* render a page */}
      <Page size="A4" style={(styles.page, { paddingBottom: 40 })}>
        <View fixed>
          <PdfBanner isFondBleu />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <PdfHeader
            commercialUserInformation={commercialUserInformation}
            logoBase64={commercialUserInformation.company_logo_base_64}
          />
          <View>
            <PdfTitleWithLign devisNumero={documentId} />
            <View style={styles.containerInfos}>
              {isDemoVersion ? (
                <PdfTitreListe
                  title="Qualifications RGE :"
                  list={[
                    <>ABCD EFG</>,
                    <>Siret : 00000000000000</>,
                    <>QUALIFELEC</>,
                    <>PV 00-AA-00000-000</>,
                  ]}
                />
              ) : (
                <PdfTitreListe
                  title="Qualifications RGE :"
                  list={[
                    <>POSEO ENR</>,
                    <>Siret : 80945456400026</>,
                    <>QUALIFELEC RGE PV</>,
                    <>N° 108796</>,
                  ]}
                />
              )}
              <PdfTitreListe
                title="Conseiller :"
                list={[
                  <>
                    {capitalize(commercialUserInformation.userFirstName) +
                      " " +
                      capitalize(commercialUserInformation.userLastName)}
                  </>,
                  <>{commercialUserInformation.userPhoneNumber}</>,
                  <>{commercialUserInformation.userEmail?.toLowerCase()}</>,
                ]}
              />
              <PdfTitreListe
                title="Référence client :"
                list={[
                  <>
                    {capitalize(firstNameInputValue) +
                      " " +
                      capitalize(lastNameInputValue)}
                  </>,
                  <>{phoneNumberInputValue}</>,
                  <>{emailInputValue.toLowerCase()}</>,
                ]}
              />
            </View>
            <View style={styles.containerInfos}>
              <PdfTitreListe
                title="Date du devis :"
                list={[<>{moment().format("DD/MM/YYYY")}</>]}
              />
              <PdfTitreListe
                title="Service client :"
                list={[
                  <>{commercialUserInformation.company_service_client_phone}</>,
                  <>{commercialUserInformation.company_service_client_email}</>,
                ]}
              />
              <PdfTitreListe
                title="Adresse de l'installation :"
                list={[
                  <>{studyInformation.address?.toLowerCase()}</>,
                  <>{studyInformation.zipcode},</>,
                ]}
              />
            </View>
            <View style={styles.containerTable}>
              <View style={styles.firstAbscisse}>
                <Text style={styles.descriptionProduits}>
                  Description produit
                </Text>
                <Text style={styles.quantite}>Quantité</Text>
                <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
                <Text style={styles.tva}>Tva</Text>
                <Text style={styles.montantTTC}>Montant ttc</Text>
              </View>

              <View style={styles.itemAbscisse}>
                <View style={styles.descriptionProduits}>
                  <Text style={styles.itemDescriptionProduits}>{pvName}</Text>
                  <Text style={styles.itemDetailDescriptionProduits}>
                    Kit de fixation en surimposition IziPV (garantie fabricant 2
                    ans)
                  </Text>
                  {SAGEItemsInfo.map((item) => {
                    if (
                      (item.FA_CodeFamille === "PV" &&
                        item.AR_Design?.toLowerCase().includes("photo")) ||
                      (item.FA_CodeFamille === "ONDULEUR" &&
                        item.AR_Design?.toLowerCase().includes("onduleur")) ||
                      (item.FA_CodeFamille === "OPTIMISEUR" &&
                        item.AR_Design?.toLowerCase().includes("optimiseur")) ||
                      (item.FA_CodeFamille === "PARTIEELEC" &&
                        item.AR_Design?.toLowerCase().includes("protection"))
                    ) {
                      return (
                        <Text
                          key={item?.AR_Design}
                          style={styles.itemDetailDescriptionProduits}
                        >
                          {(item.NO_Qte ? item.NO_Qte + " " : "") +
                            item.AR_Design}
                        </Text>
                      );
                    }
                    return null;
                  })}
                </View>
                <Text style={[styles.itemQuantite, styles.quantite]}>1</Text>
                <Text
                  style={[styles.itemPrixUnitaireHT, styles.prixUnitaireHT]}
                >
                  {formatNumberWithSpacesAndDecimal(prixPVHTAvecPose, 2)} €
                </Text>
                <Text style={[styles.itemTva, styles.tva]}>{tvaGlobale} %</Text>
                <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
                  {formatNumberWithSpacesAndDecimal(prixPVTTCAvecPose, 2)} €
                </Text>
              </View>

              {scenarioChosen.extra_fees &&
                scenarioChosen.extra_fees.map((fee, index) => {
                  return (
                    <View key={index} style={styles.itemAbscisse}>
                      <View style={styles.descriptionProduits}>
                        <Text style={styles.itemDescriptionProduits}>
                          {fee.product_name}
                        </Text>
                        <Text style={styles.itemDetailDescriptionProduits}>
                          {fee.description}
                        </Text>
                      </View>
                      <Text style={[styles.itemQuantite, styles.quantite]}>
                        1
                      </Text>
                      <Text
                        style={[
                          styles.itemPrixUnitaireHT,
                          styles.prixUnitaireHT,
                        ]}
                      >
                        {formatNumberWithSpacesAndDecimal(
                          fee.price_without_tax || -1,
                          2
                        )}{" "}
                        €
                      </Text>
                      <Text style={[styles.itemTva, styles.tva]}>
                        {fee.TVA_percentage || -1} %
                      </Text>
                      <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
                        {formatNumberWithSpacesAndDecimal(
                          fee.price_with_tax || -1,
                          2
                        )}{" "}
                        €
                      </Text>
                    </View>
                  );
                })}

              {scenarioChosen.kit_home_management && (
                <View style={styles.itemAbscisse}>
                  <View style={styles.descriptionProduits}>
                    <View>
                      <Text style={styles.itemDescriptionProduits}>
                        Kit Home Management
                      </Text>
                    </View>
                    {SAGEItemsInfo.filter(
                      (item) => item.FA_CodeFamille === "DOMOTIQUE"
                    ).map((item) => {
                      return (
                        <View key={item.AR_Ref}>
                          <Text style={styles.itemDetailDescriptionProduits}>
                            {item.NO_Qte} {kitHomeManagementName}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                  <Text style={[styles.itemQuantite, styles.quantite]}>1</Text>
                  <Text
                    style={[styles.itemPrixUnitaireHT, styles.prixUnitaireHT]}
                  >
                    {formatNumberWithSpacesAndDecimal(
                      prixKitHomeManagmentHTAvecPose,
                      2
                    )}{" "}
                    €
                  </Text>
                  <Text style={[styles.itemTva, styles.tva]}>
                    {tvaGlobale} %
                  </Text>
                  <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
                    {formatNumberWithSpacesAndDecimal(
                      prixKitHomeManagementTTCAvecPose,
                      2
                    )}{" "}
                    €
                  </Text>
                </View>
              )}
              {scenarioChosen.is_storage_battery && (
                <View style={styles.itemAbscisse}>
                  <View style={styles.descriptionProduits}>
                    <View>
                      <Text style={styles.itemDescriptionProduits}>
                        Batterie(s)
                      </Text>
                    </View>
                    {SAGEItemsInfo.map((item) => {
                      if (item.FA_CodeFamille === "BATTERIE") {
                        return (
                          <View key={item.AR_Ref}>
                            <Text style={styles.itemDetailDescriptionProduits}>
                              {batteryName}
                            </Text>
                          </View>
                        );
                      }
                      return null;
                    })}
                  </View>
                  <Text style={[styles.itemQuantite, styles.quantite]}>
                    {scenarioChosen?.number_of_batteries || 1}
                  </Text>
                  <Text
                    style={[styles.itemPrixUnitaireHT, styles.prixUnitaireHT]}
                  >
                    {formatNumberWithSpacesAndDecimal(
                      batteryPriceWithInstallHT,
                      2
                    )}{" "}
                    €
                  </Text>
                  <Text style={[styles.itemTva, styles.tva]}>
                    {tvaGlobale} %
                  </Text>
                  <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
                    {formatNumberWithSpacesAndDecimal(
                      allBatteriesPriceWhitInstallTTC,
                      2
                    )}{" "}
                    €
                  </Text>
                </View>
              )}

              {devisOptions.isHelpsDisplayed ? (
                <View
                  wrap={false}
                  style={[styles.itemAbscisse, styles.itemAbscisseAidePrime]}
                >
                  <View style={styles.descriptionProduits}>
                    <Text style={[styles.itemDescriptionProduits]}>
                      Aide Prime à l'autoconsommation
                    </Text>
                  </View>
                  <Text style={[styles.itemQuantite, styles.quantite]}></Text>
                  <Text
                    style={[styles.itemPrixUnitaireHT, styles.prixUnitaireHT]}
                  ></Text>
                  <Text style={[styles.itemTva, styles.tva]}></Text>
                  <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
                    {formatNumberWithSpacesAndDecimal(totalHelps, 2)} €
                  </Text>
                </View>
              ) : null}
              {coupons.map((item) => {
                return (
                  <View
                    key={item.label + item.value}
                    style={styles.itemAbscisse}
                  >
                    <View style={styles.descriptionProduits}>
                      <Text style={styles.itemDescriptionProduits}>
                        Remise exceptionelle : {item.label}
                      </Text>
                    </View>
                    <Text style={[styles.itemQuantite, styles.quantite]}></Text>
                    <Text
                      style={[styles.itemPrixUnitaireHT, styles.prixUnitaireHT]}
                    ></Text>
                    <Text style={[styles.itemTva, styles.tva]}></Text>
                    <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
                      {formatNumberWithSpacesAndDecimal(item.value, 2)} €
                    </Text>
                  </View>
                );
              })}
            </View>
            <View style={styles.containerTotal}>
              <View style={styles.containerDelay}>
                <Text>Délai de validité : </Text>
                <Text style={styles.delayDate}>
                  {moment().add(30, "days").format("DD/MM/YYYY")}
                </Text>
              </View>
              <View style={styles.containerTotalRight}>
                <View style={styles.totalItem}>
                  <Text style={styles.totalItemTitle}>Total HT</Text>
                  <Text style={styles.totalItemNumber}>
                    {formatNumberWithSpacesAndDecimal(
                      prixKitHomeManagmentHTAvecPose +
                        allBatteriesPriceWhitInstallHT +
                        prixPVHTAvecPose +
                        extraFees.totalPriceHT,
                      2
                    )}{" "}
                    €
                  </Text>
                </View>
                <View style={styles.totalItem}>
                  <Text style={styles.totalItemTitle}>Total TVA</Text>
                  <Text style={styles.totalItemNumber}>
                    {tvaGlobale &&
                      formatNumberWithSpacesAndDecimal(
                        (prixKitHomeManagmentHTAvecPose +
                          allBatteriesPriceWhitInstallHT +
                          prixPVHTAvecPose) *
                          (tvaGlobale / 100) +
                          extraFees.totalTVA,
                        2
                      )}{" "}
                    €
                  </Text>
                </View>
                <View style={styles.totalItem}>
                  <Text style={styles.totalItemTitle}>Total TTC</Text>
                  <Text style={styles.totalItemNumber}>
                    {formatNumberWithSpacesAndDecimal(
                      allBatteriesPriceWhitInstallTTC +
                        prixPVTTCAvecPose +
                        prixKitHomeManagementTTCAvecPose +
                        extraFees.totalPriceTTC,
                      2
                    )}{" "}
                    €
                  </Text>
                </View>
                {devisOptions.isHelpsDisplayed ? (
                  <View style={[styles.totalItem, styles.totalItemBonusBorder]}>
                    <Text style={styles.totalItemTitleBonus}>Total aides</Text>
                    <Text style={styles.totalItemNumberBonus}>
                      {formatNumberWithSpacesAndDecimal(totalHelps, 2)} €
                    </Text>
                  </View>
                ) : null}
                {totalValueCoupon && (
                  <View style={[styles.totalItem, styles.totalItem]}>
                    <Text style={styles.totalItemTitleBonus}>Total remise</Text>
                    <Text style={styles.totalItemNumberBonus}>
                      {formatNumberWithSpacesAndDecimal(priceAllCoupons(), 2)} €
                    </Text>
                  </View>
                )}
                <View style={styles.totalItemBonusBackground}>
                  <Text style={styles.totalItemTitleRestant}>
                    Reste à charge
                  </Text>
                  <Text style={styles.totalItemNumberRestant}>
                    {formatNumberWithSpacesAndDecimal(totalResteACharge, 2)} €
                  </Text>
                </View>
              </View>
            </View>
            <View break style={styles.prochainesEtapes__containerCode}>
              <View style={styles.prochainesEtapes__containerCodeLeft}>
                <Text style={styles.prochainesEtapes__containerCodeTextOne}>
                  {"Parrainez un proche !"}
                </Text>
                <Text style={styles.prochainesEtapes__containerCodeTextTwo}>
                  {
                    "Vous connaissez quelqu'un qui envisage un projet de rénovation énergétique ?"
                  }
                </Text>
                <Text style={styles.prochainesEtapes__containerCodeTextThree}>
                  {
                    "Partagez votre code parrainage et bénéficiez de 200 euros après son installation !"
                  }
                </Text>
              </View>
              <View style={styles.prochainesEtapes__containerCodeRight}>
                <View style={styles.prochainesEtapes__containerCodeRightBorder}>
                  <Text style={styles.prochainesEtapes__containerCodeTextRight}>
                    {"VOTRE CODE"}
                  </Text>
                  <Text style={styles.prochainesEtapes__containerCodeRightCode}>
                    {studyInformation.membership_code
                      ? studyInformation.membership_code
                      : "Demandez à votre conseiller"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </PdfLayout>
        <View fixed style={styles.containerPagination}>
          <PdfPagination />
        </View>
        <PdfFooter
          fixed
          commercialUserInformation={commercialUserInformation}
        />
      </Page>

      <Page size="A4" style={styles.pagePadding}>
        <View>
          <View fixed>
            <PdfBanner />
          </View>

          <PdfLayout isDemoVersion={isDemoVersion}>
            <View fixed>
              <PdfHeader
                commercialUserInformation={commercialUserInformation}
                devis={documentId}
                dateDevis={`${moment().format("DD/MM/YYYY")}`}
                logoBase64={commercialUserInformation.company_logo_base_64}
              />
            </View>
            <PdfTitreProduit title={pvName} page={"1/2"} />
            <View style={styles.containerTable}>
              <View style={styles.firstAbscisse}>
                <Text style={styles.descriptionProduits}>Description</Text>
                <Text style={styles.quantite}>Quantité</Text>
                <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
                <Text style={styles.tva}>Tva</Text>
                <Text style={styles.montantTTC}>Montant ttc</Text>
              </View>

              <PdfRowBackgroundProduit
                name="Matériel"
                quantity={1}
                prixUnitaire={prixPVHTSansPose || 0}
                TVA={tvaGlobale || 0}
                montantTTC={prixPVTTCSansPose || 0}
              />

              <View style={styles.inlineComponents}>
                <Text>Type de raccordement : </Text>
                <Text style={styles.fontBold}>
                  autoconsommation avec revente de surplus
                </Text>
              </View>

              {SAGEItemsInfo.map((item) => {
                if (
                  item.FA_CodeFamille !== "BATTERIE" &&
                  item.FA_CodeFamille !== "DOMOTIQUE" &&
                  (item.AR_Design?.toLowerCase().includes("panneau") ||
                    item.AR_Design?.toLowerCase().includes("onduleur") ||
                    item.AR_Design?.toLowerCase().includes("optimiseur") ||
                    item.AR_Design?.toLowerCase().includes("coffret"))
                ) {
                  return (
                    <PdfItemDetail
                      key={item.AR_Ref}
                      title={
                        item.AR_Design && item.NO_Qte
                          ? item.NO_Qte + " x " + item.AR_Design
                          : ""
                      }
                      description={[
                        <>
                          {item.Dimensions && (
                            <Text>
                              {item.Dimensions && item.Dimensions.length > 0
                                ? `Taille : ${item.Dimensions}`
                                : null}{" "}
                            </Text>
                          )}
                        </>,
                        <>
                          {item.Poid && (
                            <Text>
                              {item.Poid && item.Poid.length > 0
                                ? `Poids : ${item.Poid}`
                                : null}
                            </Text>
                          )}
                        </>,
                        <>
                          {item["Norme et Certification"] &&
                            item["Norme et Certification"]?.length > 0 && (
                              <Text>
                                {item["Norme et Certification"]
                                  ? `Normes et certifications : ${item["Norme et Certification"]}`
                                  : null}
                              </Text>
                            )}
                        </>,
                        <>
                          {item["Garantie Fabricant"] &&
                            item["Garantie Fabricant"]?.length > 0 && (
                              <Text>
                                {item["Garantie Fabricant"]
                                  ? `Garantie Fabriquant : ${item["Garantie Fabricant"]}`
                                  : null}
                              </Text>
                            )}
                        </>,
                      ]}
                    />
                  );
                }
                return null;
              })}

              <PdfItemDetail
                title={"1  Kit de fixation en surimposition IziPV"}
                description={[
                  <>
                    <Text>Garantie Fabriquant : 2 ans</Text>
                  </>,
                ]}
              />

              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Forfait installation"
                  quantity={1}
                  prixUnitaire={forfaitInstallationHTPV || 0}
                  TVA={tvaGlobale || 0}
                  montantTTC={forfaitInstallationTTCPV || 0}
                />

                <View style={styles.inlineComponents}>
                  <Text>Déplacement</Text>
                </View>
                <View style={styles.columnComponents}>
                  <Text>Mise en place/installation des matériels</Text>
                  <View style={[styles.columnComponents, { paddingLeft: 10 }]}>
                    <Text>• Modules photovoltaïques en toiture</Text>
                    <Text>• Tirage des câbles DC et câbles de terre</Text>
                    <Text>
                      • Pose des optimiseurs de puissance, des coffrets, de
                      l’onduleur et du système de monitoring
                    </Text>
                    <Text>
                      • Câblage de la partie AC dans l’armoire électrique
                      existante
                    </Text>
                  </View>
                  <Text>
                    Fourniture des équipements nécessaires à la pose des
                    matériels
                  </Text>
                </View>
              </View>
            </View>
          </PdfLayout>
        </View>
        <View fixed style={styles.containerPagination}>
          <PdfPagination />
        </View>
        <PdfFooter commercialUserInformation={commercialUserInformation} />
      </Page>

      <Page size="A4" style={styles.pagePadding}>
        <View>
          <View fixed>
            <PdfBanner />
          </View>

          <PdfLayout isDemoVersion={isDemoVersion}>
            <View fixed>
              <PdfHeader
                commercialUserInformation={commercialUserInformation}
                devis={documentId}
                dateDevis={`${moment().format("DD/MM/YYYY")}`}
                logoBase64={commercialUserInformation.company_logo_base_64}
              />
            </View>
            <PdfTitreProduit title={pvName} page={"2/2"} />
            <View style={styles.containerTable}>
              <View style={styles.firstAbscisse}>
                <Text style={styles.descriptionProduits}>Description</Text>
                <Text style={styles.quantite}>Quantité</Text>
                <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
                <Text style={styles.tva}>Tva</Text>
                <Text style={styles.montantTTC}>Montant ttc</Text>
              </View>

              {scenarioChosen.extra_fees &&
                scenarioChosen.extra_fees.length > 0 && (
                  <View>
                    <PdfRowBackgroundProduit name="Frais supplémentaire" />

                    {scenarioChosen.extra_fees.map((fee, index) => {
                      return (
                        <View key={index} style={styles.itemAbscisse}>
                          <View style={styles.descriptionProduits}>
                            <Text style={styles.itemDescriptionProduits}>
                              {fee.product_name}
                            </Text>
                            <Text style={styles.itemDetailDescriptionProduits}>
                              {fee.description}
                            </Text>
                          </View>
                          <Text style={[styles.itemQuantite, styles.quantite]}>
                            1
                          </Text>
                          <Text
                            style={[
                              styles.itemPrixUnitaireHT,
                              styles.prixUnitaireHT,
                            ]}
                          >
                            {formatNumberWithSpacesAndDecimal(
                              fee.price_without_tax || -1,
                              2
                            )}{" "}
                            €
                          </Text>
                          <Text style={[styles.itemTva, styles.tva]}>
                            {fee.TVA_percentage} %
                          </Text>
                          <Text
                            style={[styles.itemMontantTTC, styles.montantTTC]}
                          >
                            {formatNumberWithSpacesAndDecimal(
                              fee.price_with_tax,
                              2
                            )}{" "}
                            €
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                )}

              <PdfRowBackgroundProduit
                name="Frais de gestion"
                quantity={1}
                prixUnitaire={managementFeesHT}
                TVA={tvaGlobale}
                montantTTC={managementFeesTTC}
              />
              <PdfRowProduit name="Prise en charge des démarches administratives et gestion de projet/SAV" />
              <PdfRowProduit name="Constitution des dossiers d’aides financières éventuellement liées au projet" />
              <PdfRowProduit name="Réalisation de la déclaration préalable en mairie" />
              <PdfRowProduit name="Demande de raccordement" />
              <PdfRowProduit name="Obtention de l’attestation CONSUEL" />
              <PdfRowBackgroundProduit
                name="Frais de gestion offerts"
                montantTTC={-managementFeesTTC}
              />
              {devisOptions.isHelpsDisplayed ? (
                <PdfRowBackgroundProduit
                  name="Aides"
                  aides={[
                    {
                      aide: `Prime à l'autoconsommation`,
                      montant: totalHelps,
                      isDeducted: false, // Prime à l'autoconsommation is never deductible
                    },
                  ]}
                />
              ) : null}
              <View style={styles.containerTotalRight}>
                <View style={styles.totalItem}>
                  <Text style={styles.totalItemTitle}>Total HT</Text>
                  <Text style={styles.totalItemNumber}>
                    {formatNumberWithSpacesAndDecimal(
                      prixPVHTSansPose +
                        forfaitInstallationHTPV +
                        extraFees.totalPriceHT,
                      2
                    )}{" "}
                    €
                  </Text>
                </View>
                <View style={styles.totalItem}>
                  <Text style={styles.totalItemTitle}>Total TVA</Text>
                  <Text style={styles.totalItemNumber}>
                    {totalCostTva &&
                      formatNumberWithSpacesAndDecimal(totalCostTva, 2)}{" "}
                    €
                  </Text>
                </View>
                <View style={styles.totalItem}>
                  <Text style={styles.totalItemTitle}>Total TTC</Text>
                  <Text style={styles.totalItemNumber}>
                    {formatNumberWithSpacesAndDecimal(
                      prixPVHTSansPose +
                        forfaitInstallationHTPV +
                        (prixPVHTSansPose + forfaitInstallationHTPV) *
                          (tvaGlobale / 100) +
                        extraFees.totalPriceTTC,
                      2
                    )}{" "}
                    €
                  </Text>
                </View>
                {devisOptions.isHelpsDisplayed ? (
                  <View style={[styles.totalItem, styles.totalItemBonusBorder]}>
                    <Text style={styles.totalItemTitleBonus}>Total aides</Text>
                    <Text style={styles.totalItemNumberBonus}>
                      {formatNumberWithSpacesAndDecimal(totalHelps, 2)} €
                    </Text>
                  </View>
                ) : null}
                {totalValueCoupon && (
                  <View style={[styles.totalItem, styles.totalItem]}>
                    <Text style={styles.totalItemTitleBonus}>Total remise</Text>
                    <Text style={styles.totalItemNumberBonus}>
                      {formatNumberWithSpacesAndDecimal(priceAllCoupons(), 2)} €
                    </Text>
                  </View>
                )}
                <View style={styles.totalItemBonusBackground}>
                  <Text style={styles.totalItemTitleRestant}>
                    Sous total produit
                  </Text>
                  <Text style={styles.totalItemNumberRestant}>
                    {formatNumberWithSpacesAndDecimal(
                      resteAChargePhotovoltaique + extraFees.totalPriceTTC,
                      2
                    )}{" "}
                    €
                  </Text>
                </View>
              </View>
            </View>
          </PdfLayout>
        </View>
        <View fixed style={styles.containerPagination}>
          <PdfPagination />
        </View>
        <PdfFooter commercialUserInformation={commercialUserInformation} />
      </Page>

      {scenarioChosen.kit_home_management && (
        <Page size="A4" style={styles.pagePadding}>
          <View>
            <View fixed>
              <PdfBanner />
            </View>

            <PdfLayout isDemoVersion={isDemoVersion}>
              <View fixed>
                <PdfHeader
                  commercialUserInformation={commercialUserInformation}
                  devis={documentId}
                  dateDevis={`${moment().format("DD/MM/YYYY")}`}
                  logoBase64={commercialUserInformation.company_logo_base_64}
                />
              </View>
              <PdfTitreProduit title={kitHomeManagementName} />;
              <View style={styles.containerTable}>
                <View style={styles.firstAbscisse}>
                  <Text style={styles.descriptionProduits}>Description</Text>
                  <Text style={styles.quantite}>Quantité</Text>
                  <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
                  <Text style={styles.tva}>Tva</Text>
                  <Text style={styles.montantTTC}>Montant ttc</Text>
                </View>

                <PdfRowBackgroundProduit
                  name="Matériel"
                  quantity={1}
                  prixUnitaire={prixKitHomeManagementHTSansPose || 0}
                  TVA={tvaGlobale || 0}
                  montantTTC={prixKitHomeManagementTTCSansPose || 0}
                />

                {SAGEItemsInfo.filter(
                  (item) => item.FA_CodeFamille === "DOMOTIQUE"
                ).map((item) => {
                  return (
                    <View key={item.AR_Ref}>
                      <PdfItemDetail
                        key={item.AR_Ref}
                        title={`${item.NO_Qte || ""} ${item.AR_Design || ""}`}
                        description={[
                          <>
                            {item.Marque && (
                              <PdfRowProduit
                                isMargeBottom={false}
                                name={"Marque : " + item.Marque}
                              />
                            )}
                          </>,
                          <>
                            {item.Gamme && (
                              <PdfRowProduit
                                isMargeBottom={false}
                                name={"Gamme : " + item.Gamme}
                              />
                            )}
                          </>,
                          <>
                            {item["Garantie Fabricant"] && (
                              <PdfRowProduit
                                isMargeBottom={false}
                                name={
                                  "Garantie fabriquant : " +
                                  item["Garantie Fabricant"]
                                }
                              />
                            )}
                          </>,
                          <>
                            {item.Dimensions && (
                              <PdfRowProduit
                                name={"Taille : " + item.Dimensions}
                              />
                            )}
                          </>,
                        ]}
                      />
                    </View>
                  );
                })}

                <PdfRowBackgroundProduit
                  name="Forfait installation"
                  quantity={1}
                  prixUnitaire={forfaitInstallationHTKitHomeManagement || 0}
                  TVA={tvaGlobale || 0}
                  montantTTC={forfaitInstallationTTCKitHomeManagement || 0}
                />

                <View style={styles.containerTotalRight}>
                  <View style={styles.totalItem}>
                    <Text style={styles.totalItemTitle}>Total HT</Text>
                    <Text style={styles.totalItemNumber}>
                      {formatNumberWithSpacesAndDecimal(
                        prixKitHomeManagmentHTAvecPose,
                        2
                      )}{" "}
                      €
                    </Text>
                  </View>
                  <View style={styles.totalItem}>
                    <Text style={styles.totalItemTitle}>Total TVA</Text>
                    <Text style={styles.totalItemNumber}>
                      {tvaGlobale &&
                        formatNumberWithSpacesAndDecimal(
                          prixKitHomeManagmentHTAvecPose * (tvaGlobale / 100),
                          2
                        )}{" "}
                      €
                    </Text>
                  </View>
                  <View style={styles.totalItem}>
                    <Text style={styles.totalItemTitle}>Total TTC</Text>
                    <Text style={styles.totalItemNumber}>
                      {formatNumberWithSpacesAndDecimal(
                        prixKitHomeManagementTTCAvecPose,
                        2
                      )}{" "}
                      €
                    </Text>
                  </View>
                  <View style={styles.totalItemBonusBackground}>
                    <Text style={styles.totalItemTitleRestant}>
                      Sous total produit
                    </Text>
                    <Text style={styles.totalItemNumberRestant}>
                      {formatNumberWithSpacesAndDecimal(
                        prixKitHomeManagementTTCAvecPose,
                        2
                      )}{" "}
                      €
                    </Text>
                  </View>
                </View>
              </View>
            </PdfLayout>
          </View>
          <View fixed style={styles.containerPagination}>
            <PdfPagination />
          </View>
          <PdfFooter commercialUserInformation={commercialUserInformation} />
        </Page>
      )}

      {/* Battery Page */}
      {scenarioChosen.is_storage_battery && (
        <Page size="A4" style={styles.pagePadding}>
          <View>
            <View fixed>
              <PdfBanner />
            </View>

            <PdfLayout isDemoVersion={isDemoVersion}>
              <View fixed>
                <PdfHeader
                  commercialUserInformation={commercialUserInformation}
                  devis={documentId}
                  dateDevis={`${moment().format("DD/MM/YYYY")}`}
                  logoBase64={commercialUserInformation.company_logo_base_64}
                />
              </View>
              <PdfTitreProduit title={batteryName} />;
              <View style={styles.containerTable}>
                <View style={styles.firstAbscisse}>
                  <Text style={styles.descriptionProduits}>Description</Text>
                  <Text style={styles.quantite}>Quantité</Text>
                  <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
                  <Text style={styles.tva}>Tva</Text>
                  <Text style={styles.montantTTC}>Montant ttc</Text>
                </View>

                <PdfRowBackgroundProduit
                  name="Matériel"
                  quantity={scenarioChosen.number_of_batteries || 1}
                  prixUnitaire={batteryPriceWithoutInstallHT || 0}
                  TVA={tvaGlobale || 0}
                  montantTTC={allBatteriesPriceWhitoutInstallTTC}
                />

                {SAGEItemsInfo.map((item) => {
                  if (
                    item.AR_Ref === scenarioChosen?.battery_sage_reference &&
                    item.AR_Design
                  ) {
                    return (
                      <PdfItemDetail
                        key={item.AR_Ref}
                        title={`${item.NO_Qte || ""} ${item.AR_Design || ""}`}
                        description={[
                          <>
                            {item.Marque && (
                              <PdfRowProduit
                                isMargeBottom={false}
                                name={"Marque : " + item.Marque}
                              />
                            )}
                          </>,
                          <>
                            {item.Gamme && (
                              <PdfRowProduit
                                isMargeBottom={false}
                                name={"Gamme : " + item.Gamme}
                              />
                            )}
                          </>,
                          <>
                            {item.Puissance && (
                              <PdfRowProduit
                                name={
                                  "Capacité totale  : " +
                                  item["Capacité de la batterie"]
                                }
                                isMargeBottom={false}
                              />
                            )}
                          </>,
                          <>
                            {item.Dimensions && (
                              <PdfRowProduit
                                name={"Taille : " + item.Dimensions}
                                isMargeBottom={false}
                              />
                            )}
                          </>,

                          <>
                            {item["Garantie Fabricant"] && (
                              <PdfRowProduit
                                name={
                                  "Garantie fabriquant : " +
                                  item["Garantie Fabricant"]
                                }
                                isMargeBottom={false}
                              />
                            )}
                          </>,
                          <>
                            {item["Norme et Certification"] && (
                              <PdfRowProduit
                                name={
                                  "Normes et certifications : " +
                                  item["Norme et Certification"]
                                }
                                isMargeBottom={false}
                              />
                            )}
                          </>,
                          <>
                            <PdfRowProduit
                              name={"Montage : sur socle ou support mural"}
                            />
                          </>,
                        ]}
                      />
                    );
                  }
                  return null;
                })}

                <PdfRowBackgroundProduit
                  name="Forfait installation"
                  quantity={1}
                  prixUnitaire={batteryInstallPriceHT || 0}
                  TVA={tvaGlobale || 0}
                  montantTTC={batteryInstallPriceTTC || 0}
                />

                <View style={styles.containerTotalRight}>
                  <View style={styles.totalItem}>
                    <Text style={styles.totalItemTitle}>Total HT</Text>
                    <Text style={styles.totalItemNumber}>
                      {formatNumberWithSpacesAndDecimal(
                        allBatteriesPriceWhitInstallHT,
                        2
                      )}{" "}
                      €
                    </Text>
                  </View>
                  <View style={styles.totalItem}>
                    <Text style={styles.totalItemTitle}>Total TVA</Text>
                    <Text style={styles.totalItemNumber}>
                      {tvaGlobale &&
                        formatNumberWithSpacesAndDecimal(
                          (allBatteriesPriceWhitoutInstallHT +
                            batteryInstallPriceHT) *
                            (tvaGlobale / 100),
                          2
                        )}{" "}
                      €
                    </Text>
                  </View>
                  <View style={styles.totalItem}>
                    <Text style={styles.totalItemTitle}>Total TTC</Text>
                    <Text style={styles.totalItemNumber}>
                      {formatNumberWithSpacesAndDecimal(
                        allBatteriesPriceWhitInstallTTC,
                        2
                      )}{" "}
                      €
                    </Text>
                  </View>
                  <View style={styles.totalItemBonusBackground}>
                    <Text style={styles.totalItemTitleRestant}>
                      Sous total produit
                    </Text>
                    <Text style={styles.totalItemNumberRestant}>
                      {formatNumberWithSpacesAndDecimal(
                        allBatteriesPriceWhitInstallTTC,
                        2
                      )}{" "}
                      €
                    </Text>
                  </View>
                </View>
              </View>
            </PdfLayout>
          </View>
          <View fixed style={styles.containerPagination}>
            <PdfPagination />
          </View>
          <PdfFooter commercialUserInformation={commercialUserInformation} />
        </Page>
      )}
      <Page size="A4" style={styles.pagePadding}>
        <View>
          <View fixed>
            <PdfBanner />
          </View>

          <PdfLayout isDemoVersion={isDemoVersion}>
            <View fixed>
              <PdfHeader
                commercialUserInformation={commercialUserInformation}
                logoBase64={commercialUserInformation.company_logo_base_64}
              />
            </View>

            <PdfTitleEncadre title="informations précontractuelles" />
            <PdfTitreMentions title="1. IDENTITÉ ET COORDONNÉES DU PRESTATAIRE" />
            <PdfParagraphMentions
              paragraph={[
                <Text>
                  La Société{" "}
                  {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                  , SAS agissant sous l’enseigne{" "}
                  {commercialUserInformation?.userCompany?.toLocaleUpperCase()},
                  au capital de {commercialUserInformation.company_capital}{" "}
                  euros, dont le siège social est situé {companyFullAddress},
                  est immatriculée au Registre du Commerce et des Sociétés sous
                  le numéro{" "}
                  {
                    commercialUserInformation.company_registre_du_commerce_numero
                  }{" "}
                  et identifiée par le numéro de TVA intracommunautaire{" "}
                  {
                    commercialUserInformation.company_tva_intracommunautaire_numero
                  }
                  . Elle peut être contactée par téléphone au{" "}
                  {commercialUserInformation.company_service_client_phone} ou
                  par courriel à l’adresse électronique «
                  {commercialUserInformation.company_service_client_email}».
                </Text>,
              ]}
            />
            <PdfTitreMentions title="2. INFORMATIQUES JURIDIQUES IMPORTANTES" />
            <PdfSousTitreMentions title="Objet de la prestation :" />
            {isDemoVersion ? (
              <PdfParagraphMentions
                paragraph={[
                  <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse congue consectetur viverra. Praesent euismod
                    nulla enim, et interdum augue pellentesque sit amet.
                    Pellentesque ut eros ex. Donec iaculis iaculis congue. In
                    euismod ac sapien sed ultricies. Morbi sed consequat nisl.
                    Proin sem orci, suscipit non lobortis sit amet, luctus quis
                    erat. Fusce molestie, diam eget ullamcorper hendrerit, neque
                    tellus pulvinar orci, sit amet eleifend nisi dui eu tellus.
                    Sed erat mauris, vestibulum ac eros vel, varius sodales
                    elit.{" "}
                  </Text>,
                  <Text>
                    Etiam rutrum, est at facilisis condimentum, enim felis
                    vehicula tortor, vel elementum justo nisl ut metus. Nunc
                    luctus felis convallis nulla ornare, non placerat est
                    imperdiet. Proin lorem orci, sagittis vitae velit sed,
                    facilisis bibendum velit. Morbi ac ante justo. Maecenas
                    tempus consectetur sollicitudin. Mauris sed nisl sagittis,
                    facilisis odio ac, tempor sem. Praesent euismod mauris in
                    lectus eleifend semper. Sed sagittis, nibh ac sollicitudin
                    sagittis, lorem risus rutrum felis, eu malesuada quam est
                    quis purus. Nullam ac tincidunt lectus, quis tempor magna.
                    Nam ut sem eu enim eleifend fringilla. Phasellus bibendum
                    sagittis nisl, sit amet varius turpis. Ut maximus risus
                    felis, quis commodo ipsum varius ut.{" "}
                  </Text>,
                ]}
              />
            ) : (
              <>
                <PdfParagraphMentions
                  paragraph={[
                    <Text>
                      Conformément à l’article L.221-5 du Code de la
                      Consommation,{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      informe le client que le service proposé porte, selon son
                      souhait, sur l’installation à son domicile de panneaux
                      photovoltaïques, et/ou d’une pompe à chaleur, et/ou d’un
                      ballon thermodynamique, et éventuellement d’autres
                      services, dont la nomenclature, les caractéristiques
                      essentielles, les prix et les délais de livraison sont
                      tous détaillés dans le devis remis avant la conclusion du
                      contrat.
                    </Text>,
                  ]}
                />
                <PdfSousTitreMentions title="Prix :" />
                <PdfParagraphMentions
                  paragraph={[
                    <Text>
                      Le matériel et les fournitures installés peuvent être
                      composés de plusieurs éléments qui doivent être
                      appréhendés comme formant un tout indivisible : un pack.
                      Le prix indiqué correspond au prix de vente du pack pris
                      comme un tout indissociable, ainsi qu’à la pose dudit
                      pack. La TVA de chaque élément composant le PAC et de sa
                      pose est inclue au prix TTC. Le prix de chacun des
                      éléments composant le pack peut être fourni au client, à
                      sa demande. Ce prix couvre également les démarches
                      administratives confiées à{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                      .
                    </Text>,
                  ]}
                />
                <PdfSousTitreMentions title="Accord du consommateur sur la remise des informations précontractuelles sur support électronique :" />
                <PdfParagraphMentions
                  paragraph={[
                    <Text>
                      Conformément à l’article L.221-8 du Code de la
                      Consommation, les informations précontractuelles peuvent
                      être fournies par le professionnel sur support
                      électronique, sous réserve de l’accord du client. Le cas
                      échéant, le client reconnaît avoir accepté que{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      lui fournisse le devis et les informations
                      précontractuelles sur support électronique.
                    </Text>,
                  ]}
                />
                <PdfSousTitreMentions title="Délais d’exécution de la prestation de service et modalités d’exécution du contrat :" />
                <PdfParagraphMentions
                  paragraph={[
                    <Text>
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      s'engage à assurer la réalisation des travaux de pose des
                      matériels, au plus tard, dans un délai de quatre mois à
                      compter de la signature du bon de commande, sous réserve
                      de l’obtention des accords administratifs nécessaires
                      susceptibles le cas échéant de rallonger ce délai et sous
                      réserve de la coopération du Client.{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      procédera à l’installation du matériel soit directement,
                      soit par l’intermédiaire d’un installateur qualifié. En
                      cas d’indisponibilité d’un matériel ou pour répondre à des
                      problèmes techniques,{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      se réserve le droit de fournir au Client un matériel
                      pouvant être de marque différente, mais de qualité
                      équivalente et présentant des caractéristiques et
                      performances équivalentes ou supérieures au matériel
                      décrit dans le bon de commande. Le Client accepte que{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      puisse modifier la puissance et les modèles d’onduleurs
                      installés, pour tenir compte des contraintes techniques de
                      l’installation (région, puissance de la centrale,
                      calepinage, configuration de la pièce).{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      ajuste alors le nombre d’onduleurs et la puissance
                      d’injection en respectant strictement les préconisations
                      fabricant. Les démarches d'assistance administratives et
                      financières sont exécutées dans le cadre de contrats de
                      mandat. Leur délai d'exécution débute à la signature des
                      contrats de mandat et se termine au parfaitement
                      achèvement des travaux ; notamment lorsque les équipements
                      sont mis en service ; qu'ils ont été audités par un
                      organisme de contrôle si nécessaire ; lorsque{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      a collecté les éventuelles aides financières liées au
                      projet pour lesquelles elle aurait été mandatée et aurait
                      la capacité à se substituer au client pour le faire. Le
                      délai moyen d'un projet étant de six mois, nous estimons
                      le délai d'exécution des prestations d'assistance
                      administratives et financières à six mois. Ce délai est
                      susceptible d'évolution compte-tenu de l'intervention
                      d'administrations tierces dont{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      ne pourrait être tenue responsable des délais de
                      traitements.
                    </Text>,
                  ]}
                />
                <PdfSousTitreMentions title="Modalités de paiement :" />
                <PdfComplexeParagraphMentions
                  title={`• Avec financement proposé par l’intermédiaire de ${commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()} :`}
                  paragraph={[
                    <Text>
                      Dans le cas où le Client aurait recours à un financement,
                      il autorise la société à conclure avec l’établissement
                      financier une délégation de paiement de manière à ce que
                      l’établissement financier règle directement à{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      le prix indiqué au bon de commande. L’établissement
                      financier adresse les fonds directement à{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      dès réception d’un document signé par le Client qui
                      atteste de l’achèvement complet et sans réserve des
                      prestations de livraison et d’installation des matériels,
                      et qui autorise le déblocage des fonds.
                    </Text>,
                  ]}
                />
                <PdfComplexeParagraphMentions
                  title="• Sans financement :"
                  paragraph={[
                    <Text>
                      Le Client a le choix de payer par chèque ou par virement
                      bancaire comportant les références précises du bon de
                      commande. Le Client règlera l’intégralité du prix lorsque{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      lui aura livré les matériels et aura procédé à leurs
                      travaux d’installation. En cas de règlement par chèque
                      bancaire, celui-ci doit être émis par une banque
                      domiciliée en France métropolitaine ou à Monaco. La mise à
                      l’encaissement du chèque est réalisée dès sa réception. Un
                      chèque de banque pourra être exigé par{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                      .
                    </Text>,
                  ]}
                />
                <PdfSousTitreMentions title="Modalités de paiement :" />
                <PdfParagraphMentions
                  paragraph={[
                    <Text>
                      Au-delà du prix fixé au bon de commande, des frais seront
                      à la charge du Client et feront l’objet d’une facture
                      complémentaire, notamment s’agissant d’éventuels frais de
                      terrassement, de dépose ou de pose de matériaux (terrasse,
                      carrelage, etc.), de peinture, papier peint, etc. Ce prix
                      ne comprend pas le prix des éventuelles fournitures et
                      prestations supplémentaires qui ne seraient pas prévues
                      dans le bon de commande, notamment les prestations
                      relatives à la mise en conformité de la toiture nécessaire
                      pour réaliser l’installation. Ce prix ne comprend pas
                      notamment les travaux permettant d’installer les panneaux
                      sans ombrages liés notamment à la végétation, arbres,
                      antennes, paraboles ou conduits de cheminée qui demeurent
                      à la charge du Client. Le Client procédera à ses frais
                      avant l’installation à tous travaux d’élagage ou de dépose
                      des éléments gênants, notamment pour le raccordement, le
                      cas échéant.
                    </Text>,
                  ]}
                />
                <PdfComplexeParagraphMentions
                  title="• Informations liées à la pompe à chaleur Air/Air ou Air/Eau :"
                  paragraph={[
                    <Text>
                      L’installation de cet équipement est susceptible de
                      nécessiter une augmentation de la puissance du compteur
                      électrique. Dans ce cas, il appartiendra au Client de se
                      rapprocher de son fournisseur d’électricité afin que le
                      nécessaire soit fait.{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      ne saurait être tenue responsable d’un manque de puissance
                      nécessaire au bon fonctionnement des équipements.
                    </Text>,
                  ]}
                />
                <PdfComplexeParagraphMentions
                  title="• Informations liées à la centrale solaire photovoltaïque :"
                  paragraph={[
                    <Text>
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      informe le Client que la productivité de l’installation
                      varie en fonction de son lieu d’implantation, de son
                      environnement et des aléas météorologiques. 
                    </Text>,
                  ]}
                />
                <PdfSousTitreMentions title="Garanties légales et conventionnelles :" />
                <PdfComplexeParagraphMentions
                  title="• Garantie légale de conformité et des vices cachés :"
                  paragraph={[
                    <Text>
                      Même si{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      n’est pas le fabricant des matériels vendus, le Client
                      bénéficie de la garantie légale de conformité de l’article
                      L.217-4 du Code de la Consommation. A ce titre, le vendeur
                      est tenu des défauts de conformité des biens au contrat
                      dans les conditions de l’article L.217-4 et suivants du
                      Code de la Consommation et des défauts cachés de la chose
                      vendue dans les conditions prévues par l’article 1641 et
                      suivants du Code Civil.
                    </Text>,
                    <PdfList
                      title="Lorsqu’il agit en garantie légale de conformité, le consommateur :"
                      paragraph={[
                        <>
                          • bénéficie d’un délai de deux ans à compter de la
                          délivrance du bien pour agir ;
                        </>,
                        <>
                          • peut choisir entre la réparation ou le remplacement
                          du bien, sous réserve des conditions de coût prévues
                          par l’article L. 217-9 du Code de la consommation ;
                        </>,
                        <>
                          • est dispensé de rapporter la preuve de l’existence
                          du défaut de conformité du bien durant les six mois
                          suivant la délivrance du bien. Ce délai est porté à
                          vingt-quatre mois à compter du 18 mars 2016, sauf pour
                          les biens d’occasion
                        </>,
                      ]}
                    />,
                  ]}
                />
                <PdfParagraphMentions
                  paragraph={[
                    <Text>
                      La garantie légale de conformité s’applique indépendamment
                      de la garantie commerciale éventuellement consentie. Le
                      consommateur peut décider de mettre en œuvre la garantie
                      contre les défauts cachés de la chose vendue au sens de
                      l’article 1641 du code civil et dans cette hypothèse, il
                      peut choisir entre la résolution de la vente ou une
                      réduction du prix de vente conformément à l’article 1644
                      du code civil.
                    </Text>,
                  ]}
                />
                <PdfComplexeParagraphMentions
                  title="• Garantie du fabricant :"
                  paragraph={[
                    <Text>
                      Le matériel est également couvert par la garantie délivrée
                      par son fabricant. Le détail des garanties de chaque
                      matériel est précisé dans le devis.{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      ne saurait être tenue responsable d’un refus de garantie
                      opposé par le fabricant au client. La garantie du
                      fabricant est notamment exclue en cas d’usure normale, de
                      défaut d’entretien ou en cas d’utilisation non conforme
                      aux prescriptions et recommandations du fabricant. La mise
                      en œuvre de cette garantie s’effectuera par
                      l’intermédiaire de{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      dans les conditions décrites ci-dessus. Les pièces
                      détachées indispensables à l’utilisation des équipements
                      seront disponibles sur le marché pendant une durée de deux
                      ans à compter de la signature du bon de commande.
                    </Text>,
                  ]}
                />
                <PdfComplexeParagraphMentions
                  title="• Garantie des travaux d’installation :"
                  paragraph={[
                    <Text>
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      a souscrit une police d’assurance au titre de sa
                      responsabilité civile professionnelle pour les travaux
                      d’installation en France et DOM. Cette assurance couvre la
                      conception et les travaux d’installation réalisés par{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      contre tout défaut de conformité pendant deux ans à
                      compter de l’établissement du procès-verbal de réception
                      de l’installation. La garantie est toutefois expressément
                      limitée à la réparation, ou au choix de{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                      , au remplacement à neuf, pièces de rechange y compris
                      main d’œuvre et déplacement. La garantie est exclue en cas
                      d’usure normale, de défaut d’entretien, ou en cas
                      d’utilisation non conforme aux recommandations de{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      et aux prescriptions des fabricants contenues dans les
                      brochures remises au Client lors de l’installation des
                      équipements.{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      met à disposition du Client les détails de cette police
                      d’assurance et les coordonnées de son assureur sur son
                      site internet et sur simple requête adressée par courriel
                      au Service Client.
                    </Text>,
                  ]}
                />
                <PdfComplexeParagraphMentions
                  title="• Garantie décennale :"
                  paragraph={[
                    <Text>
                      Conformément à la loi n°78-12 du 4 janvier 1978 concernant
                      l’assurance des travaux de bâtiment,{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      a souscrit pour ses installations d’économies ou de
                      production d’énergie en France métropolitaine et DOM, une
                      police d’assurance au titre de ses responsabilités
                      susceptibles d’être engagées sur le fondement des articles
                      1792 et suivants du Code Civil.
                    </Text>,
                    <Text>Mise en oeuvre des garanties :</Text>,
                    <Text>
                      En cas de sinistre survenu pendant la période de garantie,
                      le client en informe{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      dans les plus brefs délais, par écrit à l’adresse postale
                      «
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      – Service Client – {companyFullAddress} », ou à l’adresse
                      électronique « 
                      {commercialUserInformation.company_service_client_email}
                       », en joignant tout justificatif utile.
                    </Text>,
                  ]}
                />
                <PdfComplexeParagraphMentions
                  title="• Exclusions de garanties :"
                  paragraph={[
                    <PdfList
                      title={`La garantie est exclue et la responsabilité de ${commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()} ne peut être engagée dans les cas suivants`}
                      paragraph={[
                        <>
                          • Non-paiement partiel ou total du montant de la
                          commande;
                        </>,
                        <>
                          • Détérioration des appareils provenant directement ou
                          indirectement d’accidents de toutes sortes, chocs,
                          surtensions, foudre, inondations, incendies et d’une
                          manière générale, toutes causes autres que celles
                          résultant d’une utilisation normale ;
                        </>,
                        <>
                          • Mauvais fonctionnement résultant d’adjonction de
                          pièces ou de dispositifs ne provenant pas du
                          distributeur fabricant, ou non-agréés par lui ;
                        </>,
                        <>
                          • Intervention de quelque nature que ce soit par une
                          personne non agréée par le fabricant et sans avoir
                          recueilli l’autorisation préalable de{" "}
                          {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                          .
                        </>,
                        <>
                          • Variation du courant électrique, dérangement, panne
                          ou rupture des lignes téléphoniques ;
                        </>,
                        <>
                          • Dans le cadre de l’installation d’une pompe à
                          chaleur reliée au réseau hydraulique de l’habitation,
                          dysfonctionnements et usure du réseau hydraulique déjà
                          existant;
                        </>,
                        <>
                          • Modifications dommageables de l’environnement de
                          l’appareil (température, hygrométrie, poussière, etc.)
                          ;
                        </>,
                        <>
                          • Modifications des spécifications d’un appareil
                          utilisation non conforme à ses caractéristiques
                          techniques ;
                        </>,
                        <>
                          • Interférences et brouillages de toutes sortes,
                          radioélectriques ou électriques ;
                        </>,
                        <>
                          • Sous puissance du matériel que le client a choisi et
                          dont il assume seul la détermination ;
                        </>,
                        <>
                          • De manière générale, les perturbations de
                          fonctionnement dues à des causes relevant de la force
                          majeure ;
                        </>,
                        <>
                          • Non-respect des consignes d’utilisation des
                          matériels telles que contenues dans les notices
                          remises au client lors de l’installation des
                          matériels.
                        </>,
                        <>
                          • Utilisation des appareils dans les conditions non
                          conformes à leur usage ;
                        </>,
                        <>• Défaut d’entretien et de maintenance.</>,
                      ]}
                    />,
                  ]}
                />
                <PdfBlockSpace size={10} />
                <PdfSousTitreMentions title="Règlement des litiges – Réclamations – Droit applicable :" />
                <PdfParagraphMentions
                  paragraph={[
                    <View>
                      <Text>
                        Les opérations de vente susmentionnées sont soumises au
                        droit français. Tous les litiges auxquels les opérations
                        de vente conclues en application des conditions
                        générales de vente applicables pourraient donner lieu,
                        tant dans leur validité, leur interprétation, leur
                        exécution, leur résiliation, leurs conséquences et leurs
                        suites et qui n’auraient pu être résolues entre{" "}
                        {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                        et le Client seront soumis aux tribunaux compétents dans
                        les conditions de droit commun. Toutefois, les parties
                        s’efforceront de trouver une solution amiable en cas de
                        survenance d’un litige. En cas de contestation, le
                        client a la possibilité d’adresser une réclamation, par
                        écrit à l’adresse postale suivante : «
                        {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                        , Service Client, {companyFullAddress}» ou à l’adresse
                        électronique suivante :{" "}
                        {commercialUserInformation.company_service_client_email}
                        .{" "}
                        {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                        y répondra dans les meilleurs délais. Le Client a par
                        ailleurs la possibilité de recourir gratuitement à un
                        médiateur de la consommation, dans les conditions
                        prévues aux articles L.611-1 et suivants du Code de la
                        Consommation ou auprès des instances de médiation
                        sectorielles existantes. A ce titre, le consommateur a
                        la possibilité de saisir gratuitement le médiateur de la
                        consommation dont relève le professionnel, à savoir
                        l’Association des Médiateurs Européens (AME CONSO), dans
                        un délai d’un an à compter de la réclamation écrite
                        adressée au professionnel. La saisine du médiateur de la
                        consommation devra s’effectuer soit en complétant le
                        formulaire prévu à cet effet sur le site internet de
                        l’AME CONSO : www.mediationconso-ame.com; soit par
                        courrier adressé à l’AME CONSO, 197 Boulevard
                        Saint-Germain - 75007 PARIS.
                      </Text>
                    </View>,
                  ]}
                />
                <PdfSousTitreMentions title="Rétractation – Renonciation :" />
                <PdfParagraphMentions
                  paragraph={[
                    <Text>
                      Selon l’article 221-18 du Code de la Consommation, le
                      consommateur dispose d'un délai de quatorze jours pour
                      exercer son droit de rétractation d'un contrat conclu à
                      distance, à la suite d'un démarchage téléphonique ou hors
                      établissement, sans avoir à motiver sa décision ni à
                      supporter d'autres coûts que ceux prévus aux articles L.
                      221-23 à L. 221-25. Le délai mentionné au premier alinéa
                      court à compter du jour : 1° De la conclusion du contrat,
                      pour les contrats de prestation de services et ceux
                      mentionnés à l'article L. 221-4 ; 2° De la réception du
                      bien par le consommateur ou un tiers, autre que le
                      transporteur, désigné par lui, pour les contrats de vente
                      de biens. Pour les contrats conclus hors établissement, le
                      consommateur peut exercer son droit de rétractation à
                      compter de la conclusion du contrat. Dans le cas d'une
                      commande portant sur plusieurs biens livrés séparément ou
                      dans le cas d'une commande d'un bien composé de lots ou de
                      pièces multiples dont la livraison est échelonnée sur une
                      période définie, le délai court à compter de la réception
                      du dernier bien ou lot ou de la dernière pièce. Pour les
                      contrats prévoyant la livraison régulière de biens pendant
                      une période définie, le délai court à compter de la
                      réception du premier bien. Le Client exerce son droit de
                      rétractation en notifiant à{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                      , {companyFullAddress}{" "}
                      {commercialUserInformation.company_service_client_phone},{" "}
                      {commercialUserInformation.company_service_client_email},
                      sa décision de rétractation, avant l’expiration du délai
                      prévu à l’article L.221-18 du Code de la Consommation, au
                      moyen du formulaire de rétractation ci-joint ou de toute
                      autre déclaration dénuée d’ambiguïté par courrier postal
                      avec accusé de réception, ou par retour de courriel en cas
                      de remise et de signature électronique des documents
                      contractuels.  Effets de la rétractation : Le Client
                      renvoie ou restitue les matériels à{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      au plus tard dans les quatorze jours suivant la
                      notification de sa décision de rétractation. Le Client
                      supporte les coûts de renvoi des biens au tarif pratiqué
                      par un transporteur ainsi que les risques de retour
                      desdits biens. Le retour des matériels doit s’effectuer
                      dans leur emballage d’origine ou dans un emballage de
                      nature à préserver leur parfaite intégrité. La
                      responsabilité du Client sera engagée et il sera tenu à
                      une indemnité compensatrice en cas de dépréciation des
                      biens résultant de manipulations autres que celles
                      nécessaires pour établir leur nature, leurs
                      caractéristiques et leur bon fonctionnement, par exemple
                      en cas d’usage incompatible avec les principes de la bonne
                      foi. Pour organiser le retour des matériels, le Client
                      contacte préalablement{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      par écrit pour solliciter un accord de retour qu’il
                      joindra aux matériels renvoyés.{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      rembourse au Client la totalité des sommes versées sans
                      retard injustifié et au plus tard dans les quatorze jours
                      à compter de la date à laquelle elle est informée de la
                      décision du Client de se rétracter.{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      peut différer le remboursement jusqu’à récupération des
                      biens ou jusqu’à ce que le Client ait fourni une preuve de
                      l’expédition de ces biens, la date retenue étant celle du
                      premier de ces faits. Le remboursement s’effectuera par le
                      même moyen de paiement que celui utilisé pour la
                      transaction initiale, sauf accord exprès du client pour
                      que{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      utilise un autre moyen de paiement et dans la mesure où le
                      remboursement n’occasionne pas de frais pour le
                      consommateur.{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      n’est pas tenue de rembourser les frais supplémentaires si
                      le Client a expressément choisi un mode de livraison plus
                      coûteux que le mode de livraison utilisé par elle.
                      Conformément à l’article L.221-25 du Code de la
                      Consommation, si le Client souhaite que l’exécution de la
                      prestation de services commence avant la fin du délai de
                      rétractation,{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      recueille sa demande expresse sur papier ou sur support
                      durable. Le Client qui a exercé son droit de rétractation
                      alors que l’exécution du contrat a déjà commencé, à sa
                      demande expresse, avant la fin du délai de rétractation,
                      verse à{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      une somme correspondant au service fourni jusqu’à la
                      notification de sa rétractation ; ce montant est
                      proportionné au prix total de la prestation convenue dans
                      le contrat. Conformément à l’article L.221-28 du Code de
                      la Consommation, le droit de rétractation ne peut pas être
                      exercé pour les contrats de fourniture de services
                      pleinement exécutés avant la fin du délai de rétractation
                      et dont l’exécution a commencé après accord préalable
                      exprès du Client et renoncement exprès à son droit de
                      rétractation.
                    </Text>,
                  ]}
                />
              </>
            )}
          </PdfLayout>
        </View>
        <View fixed style={styles.containerPagination}>
          <PdfPagination />
        </View>
      </Page>
      <Page>
        <PdfBanner />
        <PdfLayout isDemoVersion={isDemoVersion}>
          <PdfHeader
            commercialUserInformation={commercialUserInformation}
            logoBase64={commercialUserInformation.company_logo_base_64}
          />
          <PdfTitleEncadre title="formulaire de rétractation :" />
          <View>
            <Text style={styles.preventInfo}>
              Veuillez compléter et renvoyer le présent formulaire uniquement si
              vous souhaitez vous rétracter du contrat.
            </Text>
          </View>

          <View style={styles.retractationDestinataireCText}>
            <Text>A l'attention de : </Text>
            <Text style={{ fontFamily: "DMSansBold" }}>
              {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
              , {companyFullAddress} -{" "}
              {commercialUserInformation.company_service_client_email}
            </Text>
          </View>
          <View style={styles.retractationDestinataireCBox}>
            <Text style={styles.retractationDestinataireTextBox}>
              Je vous notifie par la présente ma rétractation du contrat portant
              sur la vente du bien et sur les prestation de services ci-dessous
              :
            </Text>
            <Text style={styles.retractationDestinataireTextBox}>
              Commandé le :
            </Text>
            <Text style={styles.retractationDestinataireTextBox}>
              Nom du consommateur :
            </Text>
            <Text style={styles.retractationDestinataireTextBox}>
              Adresse du consommateur :
            </Text>
            <Text style={styles.retractationDestinataireTextBox}>
              Signature du consommateur uniquement en cas de notification du
              présent formulaire sur papier :
            </Text>
            <Text style={styles.retractationDestinataireTextBox}>Date :</Text>
          </View>

          <View>
            <Text style={[styles.preventInfo]}>
              Veuillez compléter et renvoyer le présent formulaire uniquement si
              vous souhaitez vous rétracter du contrat.
            </Text>
          </View>
        </PdfLayout>
        <View fixed style={styles.containerPagination}>
          <PdfPagination />
        </View>
      </Page>
    </Document>
  );
};
