import "./singleTab.scss";

// Libraries import
import React from "react";
import SVG from "react-inlinesvg";

// Local interface declaration
interface Props {
  title: string;
  imgSrc: string;
  isOpen?: boolean;
  onClick: () => void;
}

export const SingleTab: React.FC<Props> = ({
  title,
  imgSrc,
  isOpen = false,
  onClick,
}) => {
  return (
    <div className={`SingleTab ${isOpen ? "tabIsOpen" : ""}`} onClick={onClick}>
      <div className="SingleTab__Container">
        <div className="SingleTab__Logo">
          <SVG src={imgSrc} description={`logo ${title}`} />
        </div>
        <div className="SingleTab__Title">
          <h4>{title}</h4>
        </div>
      </div>
    </div>
  );
};
