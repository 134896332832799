//Style import
import "./consumptionComponent.scss";

//Componenent import
import { ModalityPopUp } from "@web/shared/dist/containers/ModalityPopUp/ModalityPopUp";

//local interface
interface IDataConso {
  picto: string;
  mainInfos: IInfo[];
  secondaryInfos: IInfo[];
}

interface IInfo {
  title: string;
  description?: string;
  number: number | undefined;
  isRight?: boolean;
  productionWhithoutShadowCasting?: number;
}
export const ConsumptionComponent = ({
  picto,
  mainInfos,
  secondaryInfos,
}: IDataConso) => {
  return (
    <div className="ConsumptionComponent">
      {mainInfos.map((info) => {
        return (
          <div
            className="ConsumptionComponent__MainInfoSection"
            key={info.title}
          >
            <div className="ConsumptionComponent__Title">
              <h4>{info.title}</h4>
              {info.description && (
                <ModalityPopUp
                  backgroundColor="black"
                  color="withe"
                  positionRight={true}
                  content={<p>{info.description}</p>}
                />
              )}
            </div>

            {info.number !== undefined ? (
              <>
                <p>
                  <strong>{info.number} kWh</strong>
                </p>
                {info.productionWhithoutShadowCasting && (
                  <p>
                    (sans masque solaire :{" "}
                    {info.productionWhithoutShadowCasting} kWh)
                  </p>
                )}
              </>
            ) : (
              <p>Donnée indisponible</p>
            )}
          </div>
        );
      })}

      <div className="ConsumptionComponent__SecondaryInfoSection">
        {secondaryInfos.map((info) => {
          return (
            <div
              className="ConsumptionComponent__SecondaryInfoBlock"
              key={info.title}
            >
              <div className="ConsumptionComponent__SubTitle">
                <h5>{info.title}</h5>
                {info.description && (
                  <ModalityPopUp
                    backgroundColor="black"
                    color="withe"
                    positionRight={info.isRight}
                    content={<p>{info.description}</p>}
                  />
                )}
              </div>
              {info.number !== undefined ? (
                <p>
                  <strong>{info.number} kWh</strong>
                </p>
              ) : (
                <p>Donnée indisponible</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
