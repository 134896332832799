import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";

// Images
import logoOreeEtude from "../../../../assets/pdf/images/oree_logo_etude.png";
import { ICommercialUserInformation } from "../../../../interfaces/generalInterfaces";

// Create styles
const styles = StyleSheet.create({
  logoTuco: {
    height: 45,
    marginBottom: 5,
    marginTop: "-5px",
    padding: 5,
    objectFit: "contain",
  },

  // Logo Tuco x Mister Menuiserie has a different format (wider) so it needs a specific style
  logoTucoMisterMenuiserie: {
    height: 60,
    marginBottom: 5,
    marginTop: "-5px",
    padding: 5,
    objectFit: "contain",
  },
  textDevis: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 12,
    fontFamily: "DMSansRegular",
    color: "#525360",
  },
  textDate: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 9,
    fontFamily: "DMSansRegular",
    color: "#525360",
  },
  textBold: {
    fontFamily: "DMSansBold",
  },
});

interface IProps {
  devis?: string;
  dateDevis?: string;
  logoBase64?: string | null;
  commercialUserInformation: ICommercialUserInformation;
}

export const PdfHeader = ({
  devis,
  dateDevis,
  logoBase64,
  commercialUserInformation,
}: IProps) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      fixed
    >
      <Image
        style={
          commercialUserInformation.userCompany?.includes("Mister Menuiserie")
            ? styles.logoTucoMisterMenuiserie
            : styles.logoTuco
        }
        src={logoBase64 ? `data:image/${logoBase64}` : logoOreeEtude}
      />
      {devis && dateDevis && (
        <View>
          <View style={styles.textDevis}>
            <Text style={styles.textBold}>{devis}</Text>
          </View>
          <View style={styles.textDate}>
            <Text>Date du devis : </Text>
            <Text style={styles.textBold}>{dateDevis}</Text>
          </View>
        </View>
      )}
    </View>
  );
};
