import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";

//Interface import
import { IProfitabilityRG } from "../../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IGetProfitabilityRGProps {
  userToken: string | null;
  study_id: string | null;
}

export const useGetProfitabilityRG = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const getProfitabilityRG = async ({
    userToken,
    study_id,
  }: IGetProfitabilityRGProps) => {
    setErrorMessage("");
    const response = await axiosGetRequest<IProfitabilityRG>(
      `${BACKEND_URL}/oree2/rg/profitability/${study_id}`,

      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      return response;
    }
  };
  return {
    getProfitabilityRG,
    isLoading,
    errorMessage: errorMessage || axiosErrorMessage,
  };
};
