import "./ExtraChargesModal.scss";

// Libraries import
import { useState } from "react";

// Components import
import {
  Dropdown,
  manageDropdownSingleSelection,
} from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { TextArea } from "@web/shared/dist/components/DesignSystem/Inputs/TextArea/TextArea";

// Interfaces import
import { TFraisSupp } from "../../../interfaces/renoglobaleInterface";

// Local interface delcaration
interface IOption {
  label: string | null;
  value: string | null;
}

interface IProps {
  isModaleFraisSuppOpen: boolean;
  setIsModaleFraisSuppOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFraisSupp: React.Dispatch<React.SetStateAction<TFraisSupp[]>>;
}

export const ExtraChargesModal = ({
  isModaleFraisSuppOpen,
  setIsModaleFraisSuppOpen,
  setFraisSupp,
}: IProps) => {
  const [modaleIntitulé, setModaleIntitulé] = useState<string>("");
  const [modaleDescription, setModaleDescription] = useState<string>("");
  const [modaleMontant, setModalMontant] = useState<string>("");
  const [modaleTVA, setModalTVA] = useState<IOption[]>([]);

  const addCustomFraisSupp = () => {
    setFraisSupp((state) => {
      return [
        ...state,
        {
          price_with_tax: Number(modaleMontant),
          product_name: modaleIntitulé,
          description: modaleDescription,
          sage_reference: "",
          TVA_percentage: Number(modaleTVA[0].value) || 20, // Default TVA : 20%
          price_without_tax:
            Math.round(
              (Number(modaleMontant) /
                (1 + (Number(modaleTVA[0].value) || 20) / 100)) *
                100
            ) / 100, // Default TVA : 20%
        },
      ];
    });
    setIsModaleFraisSuppOpen(false);
    setModaleIntitulé("");
    setModaleDescription("");
    setModalMontant("");
    setModalTVA([]);
  };

  const cancelAddCustomFraisSupp = () => {
    setIsModaleFraisSuppOpen(false);
    setModaleIntitulé("");
    setModaleDescription("");
    setModalMontant("");
    setModalTVA([]);
  };

  return (
    <Modale
      isModaleOpen={isModaleFraisSuppOpen}
      title="Ajouter une spécificité"
      setShowModal={setIsModaleFraisSuppOpen}
    >
      <div className="ExtraChargesModal__ModaleInputContainer">
        <TextInput
          placeholder="Exemple d'intitulé"
          inputLableText="Intitulé :"
          iconHidden={true}
          inputDescriptionHidden={true}
          value={modaleIntitulé}
          onChange={(e) => setModaleIntitulé(e.target.value)}
        />
      </div>
      <div className="ExtraChargesModal__ModaleTextAreaContainer">
        <TextArea
          inputLableText="Description :"
          placeholder="Exemple de description"
          inputLableHidden={false}
          value={modaleDescription}
          onChange={(e) => setModaleDescription(e.target.value)}
        />
      </div>
      <div className="ExtraChargesModal__ModaleInputContainer">
        <TextInput
          placeholder="Montant TTC"
          inputLableHidden={true}
          inputDescriptionHidden={true}
          value={modaleMontant}
          iconType="euros"
          type="number"
          onChange={(e) => setModalMontant(e.target.value)}
          iconHidden={false}
        />
      </div>
      <div className="ExtraChargesModal__ModaleInputContainer">
        <Dropdown
          options={[
            { label: "5.5%", value: "5.5" },
            { label: "10%", value: "10" },
            { label: "20%", value: "20" },
          ]}
          placeholder="TVA (%)"
          optionsSelected={modaleTVA}
          multipleChoices={false}
          searchable={false}
          tags={false}
          onSelect={(option) => {
            manageDropdownSingleSelection(option, setModalTVA);
          }}
        />
      </div>
      <div className="ExtraChargesModal__ModaleButtonsContainer">
        <CTAButton
          name="Annuler"
          category="secondary"
          family="vitee"
          onClick={cancelAddCustomFraisSupp}
        />{" "}
        <CTAButton
          name="Ajouter"
          category="primary"
          family="vitee"
          isDisabled={
            !modaleIntitulé || !modaleMontant || modaleTVA.length === 0
          }
          onClick={addCustomFraisSupp}
        />
      </div>
    </Modale>
  );
};
