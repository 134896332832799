import {
  Text,
  View,
  StyleSheet,
  //   PDFDownloadLink,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  containerTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: 6,
  },
  titleDevis: {
    display: "flex",
    fontSize: 18,
    fontFamily: "DMSansBold",
    color: "#525360",
  },
  titreDevisTrait: {
    display: "flex",
    flex: "1 1 auto",
    marginLeft: 10,
    height: 2,
    backgroundColor: "#525360",
  },
});

interface IProps {
  devisNumero: string;
}
export const PdfTitleWithLign = ({ devisNumero }: IProps) => {
  return (
    <View style={styles.containerTitle}>
      <Text style={styles.titleDevis}>{devisNumero}</Text>
      <Text style={styles.titreDevisTrait}></Text>
    </View>
  );
};
