//Style import
import "./selfSufficiencyComponent.scss";

//Picto import
import pictoSelfSufficiency from "../../../assets/images/picto-SelfSufficiency.svg";
import chevrondown from "../../../assets/images/chevron-down.svg";

//Service import
import { CSSTransition } from "react-transition-group";
import { useState } from "react";
import { SelfSufficiencyChartComponent } from "../SelfSufficiencyChartComponent/SelfSufficiencyChartComponent";

//interface import
import { IDailySelfConsumptionData } from "../../../interfaces/generalInterfaces";

//Local intefrace
interface Props {
  dataChart: IDailySelfConsumptionData | null;
  daysOfAutosufficiency: number | null;
}

export const SelfSufficiencyComponent = ({
  dataChart,
  daysOfAutosufficiency,
}: Props) => {
  const [isTabOpen, setisPageOpen] = useState(false);

  const [tabHeight, setTabHeight] = useState<number>(0);

  const calcHeight = () => {
    const height = 350;

    setTabHeight(height);
  };

  const onExit = () => {
    setTabHeight(0);
  };

  return (
    <div className="SelfSufficiencyComponent">
      <div
        className="SelfSufficiencyComponent__TitleContainer"
        onClick={() => {
          setisPageOpen((state) => !state);
        }}
      >
        <img src={pictoSelfSufficiency} alt="picto SelfSufficiency" />
        <p>
          <span className={'SelfSufficiencyComponent__TitleContainer__FirstPart'}>{daysOfAutosufficiency}</span> jours d'autosuffisance
          <span className={'SelfSufficiencyComponent__TitleContainer__LastPart'}>, sans recourir au réseau électrique.</span>
        </p>
        <div className="ChevronPicto">
          <CSSTransition
            in={isTabOpen}
            timeout={300}
            classNames={"tab-chevron"}
          >
            <img src={chevrondown} alt="picto SelfSufficiency" />
          </CSSTransition>
        </div>
      </div>
      <div
        className="SelfSufficiencyComponent__GraphSection"
        style={{ height: tabHeight }}
      >
        <CSSTransition
          in={isTabOpen}
          unmountOnExit
          timeout={300}
          onEnter={calcHeight}
          onExit={onExit}
        >
          <div className="GraphContainer">
            {/* dataMocked to replace by real */}
            <SelfSufficiencyChartComponent dataChart={dataChart} />
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};
