import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { ICommercialUserInformation } from "../../../../interfaces/generalInterfaces";

// Create styles
const styles = StyleSheet.create({
  footer: {
    fontFamily: "DMSansRegular",
    width: "100%",
    fontSize: 8,
    color: "#525360",
    position: "absolute",
    bottom: 10,
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

// Local interface declaration
interface IPdfFooter {
  commercialUserInformation: ICommercialUserInformation;
  fixed?: boolean;
}

export const PdfFooter = ({
  commercialUserInformation,
  fixed = false,
}: IPdfFooter) => {
  return (
    <View style={styles.footer} wrap={false} fixed={fixed}>
      <Text>
        {'"' +
          commercialUserInformation.userCompany?.toLocaleUpperCase() +
          '", une marque de ' +
          commercialUserInformation.company_raison_sociale?.toLocaleUpperCase()}
        , {commercialUserInformation.company_address},{" "}
        {commercialUserInformation.company_postal_code}{" "}
        {commercialUserInformation.company_city}
      </Text>
      <Text>
        Tel : {commercialUserInformation.company_service_client_phone} - Email :{" "}
        {commercialUserInformation.company_service_client_email} -{" "}
        {commercialUserInformation.company_website_url}
      </Text>
      <Text>
        SAS au capital de {commercialUserInformation.company_capital} euros -
        RCS {commercialUserInformation.company_registre_du_commerce_numero}
      </Text>
      <Text>
        SIRET : {commercialUserInformation.company_siret} – Numéro TVA
        Intracommunautaire :{" "}
        {commercialUserInformation.company_tva_intracommunautaire_numero}
      </Text>
    </View>
  );
};
