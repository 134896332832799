// Styles import
import "./projectsMap.scss";

// Libraries import
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { useRef } from "react";
import { SwiperRef } from "swiper/react";

// Component import
import { NavigationBarOutsideStudy } from "../../components/NavigationBarOutsideStudy/NavigationBarOutsideStudy";
import { ProjectsMarkersContainer } from "../../containers/ProjectsMap/ProjectsMarkersContainer/ProjectsMarkersContainer";
import { DepotsMarkersContainer } from "../../containers/ProjectsMap/DepotsMarkersContainer/DepotsMarkersContainer";

// Services import
import { useAppSelector } from "../../redux/store/hook";

export const ProjectsMap = () => {
  // Hooks declaration
  const { userAuthInfo } = useAppSelector((state) => state);

  // States declaration
  const swiperRef = useRef<SwiperRef>(null);

  // Variables declaration
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const mapCenter = {
    lat: 46.32,
    lng: 2.25,
  };

  return (
    <div className="ProjectsMap">
      <NavigationBarOutsideStudy title={"Carte des installations"} />
      <div className="ProjectsMap__Container">
        {process.env.REACT_APP_GOOGLE_API_KEY ? (
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              zoom={7}
              options={{
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: true,
                center: mapCenter,
                zoomControl: false,
              }}
            >
              <ProjectsMarkersContainer
                swiperRef={swiperRef}
                userAuthInfo={userAuthInfo}
              />

              <DepotsMarkersContainer userAuthInfo={userAuthInfo} />
            </GoogleMap>
          </LoadScript>
        ) : (
          <p>Map is not available</p>
        )}
      </div>
    </div>
  );
};
