import { Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import { DSColors } from "@web/shared/src/styles/variables";

// Components
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfEtudeHeaderRG } from "./PdfEtudeHeaderRG";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";

// Images import
import vousEtesIci from "../../assets/pdf/images/fleche.png";
import greenValue from "../../assets/pdf/images/greenValue.png";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  documentId: string;
  imageRentabilite: string | null;
  greenValueData: number[] | null;
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  containerRecapitulatif: {
    height: "100%",
  },
  pageTitleContainer: {
    textAlign: "left",
    height: 30,
    marginTop: 10,
  },
  pageTitleContent: {
    position: "relative",
    backgroundColor: DSColors.ABLight06,
    padding: 5,
  },
  pageTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },

  pageSubTitleContent: {
    display: "flex",
    flexDirection: "row",
    objectFit: "contain",
    alignItems: "center",
    marginTop: 15,
  },
  pageSubTitle: {
    fontSize: 12,
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },
  pageSubTitleImage: {
    width: 25,
    marginRight: 5,
  },
});

export const PdfEtudeRGPageRentabilité = ({
  firstNameInputValue,
  lastNameInputValue,
  documentId,
  imageRentabilite,
  greenValueData,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Page size="A4" style={styles.page}>
      <PdfEtudeHeaderRG />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.containerRecapitulatif}>
          {/* Page title */}
          <View style={localStyle.pageTitleContainer}>
            <View style={localStyle.pageTitleContent}>
              <Text style={localStyle.pageTitle}>RENTABILITÉ</Text>
            </View>
          </View>

          {/* Page subtitle */}

          <View style={localStyle.pageSubTitleContent}>
            <Image style={localStyle.pageSubTitleImage} source={vousEtesIci} />
            <Text style={localStyle.pageSubTitle}>Gains cumulés</Text>
          </View>

          {/* Evolution prix de l'energie */}
          <View>
            <Text
              style={{
                fontSize: 10,
                fontFamily: "DMSansBold",
                marginTop: 10,
                marginBottom: 10,
                color: DSColors.OffBlack,
              }}
            >
              Évolution du prix de l’énergie
            </Text>
            <Text style={{ fontSize: 8, color: DSColors.StableGrey }}>
              Nos prévisions donnent une forte augmentation du prix de
              l’électricité à court terme (2026) d'au moins 15% par an, pour
              évoluer ensuite à un taux annuel de +3.4% jusqu’en 2050 tandis que
              votre kWh solaire reste fixe pendant toute la durée de vie de
              votre installation.
            </Text>
          </View>
          {imageRentabilite ? (
            <View style={{ marginTop: 10 }}>
              <Image src={imageRentabilite} />
            </View>
          ) : null}
          {/* Page subtitle */}

          {/* Green value container */}
          {greenValueData?.[0] ? (
            <>
              {/* Page subtitle */}
              <View style={localStyle.pageSubTitleContent}>
                <Image
                  style={localStyle.pageSubTitleImage}
                  source={vousEtesIci}
                />
                <Text style={localStyle.pageSubTitle}>Valeur verte</Text>
              </View>
              <View
                style={{
                  padding: 20,
                  backgroundColor: DSColors.PGLight04,
                  borderRadius: 10,
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <Text style={{ fontSize: 8 }}>
                    La valeur verte représente la plus-value immobilière
                    réalisée par une meilleure performance énergétique et
                    environnementale de votre logement.
                  </Text>
                  <Text style={{ fontSize: 12, fontFamily: "DMSansBold" }}>
                    {"Entre " +
                      greenValueData?.[0] +
                      "% et " +
                      greenValueData?.[1] +
                      "%"}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Image src={greenValue} style={{ width: 150 }} />
                </View>
              </View>
            </>
          ) : null}
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType="rénovation globale"
      />
    </Page>
  );
};
