import "./CheckoutButtonsDemo.scss";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";

// Services import
import { openPDFInNewTab } from "../../../services/Recapitulatif/serviceFunctionsForPDF";

// Local interfaces delcaration
interface IProps {
  errorMessages?: string;
  generateQuotePDF: ({
    isDemoVersion,
  }: {
    isDemoVersion?: boolean;
  }) => Promise<{
    quotePDFId: string;
    quotePDFAsBase64: string;
    quotePDFAsBlob: Blob;
  }>;
  isGenerateQuotePDFLoading: boolean;
  generateStudyPDF: ({
    isDemoVersion,
  }: {
    isDemoVersion?: boolean;
  }) => Promise<{
    studyPDFId: string;
    studyPDFAsBase64: string;
    studyPDFAsBlob: Blob | undefined;
  }>;
  isGenerateStudyPDFLoading: boolean;
  isGetInformationForPDFEtudeLoading?: boolean;
}

export const CheckoutButtonsDemo = ({
  errorMessages,
  generateQuotePDF,
  generateStudyPDF,
  isGenerateQuotePDFLoading: isGeneratePDFDevisLoading,
  isGenerateStudyPDFLoading: isGeneratePDFEtudeLoading,
  isGetInformationForPDFEtudeLoading,
}: IProps) => {
  const generateAndOpenQuotePDF = async () => {
    const { quotePDFAsBlob } = await generateQuotePDF({ isDemoVersion: true });
    openPDFInNewTab(quotePDFAsBlob);
  };

  const generateAndOpenStudyPDF = async () => {
    const { studyPDFAsBlob } = await generateStudyPDF({ isDemoVersion: true });
    openPDFInNewTab(studyPDFAsBlob);
  };

  return (
    <div className="CheckoutButtonsDemo">
      <div className="CheckoutButtonsDemo__ButtonOne">
        <CTAButton
          name="Générer devis"
          category="secondary"
          isLoading={isGeneratePDFDevisLoading}
          onClick={() => generateAndOpenQuotePDF()}
        />
      </div>
      <div className="CheckoutButtonsDemo__ButtonTwo">
        <CTAButton
          name="Générer étude"
          category="secondary"
          isLoading={
            isGetInformationForPDFEtudeLoading || isGeneratePDFEtudeLoading
          }
          onClick={() => generateAndOpenStudyPDF()}
        />
      </div>
      <div className="CheckoutButtonsDemo__ErrorMessageContainer">
        {errorMessages && <ErrorMessage errorMessage={errorMessages} />}
      </div>
    </div>
  );
};
