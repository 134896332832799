import { Text, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  paragraphMentions: {
    fontSize: 9,
    padding: "0 10",
    color: "#525360",
    fontFamily: "DMSansBold",
    lineHeight: "1.7px",
    marginTop: 5,
    marginBottom: 2,
  },
});
interface IProps {
  title: string;
}

export const PdfSousTitreMentions = ({ title }: IProps) => {
  return <Text style={styles.paragraphMentions}>{title}</Text>;
};
