//Style import
import "./impactComponent.scss";

//Component import
import { Layout } from "../../../components/Layout/Layout";
import { useEffect, useRef } from "react";
import { useAppSelector } from "../../../redux/store/hook";
import { useGetImpactEnvironnemental } from "../../../services/OptionChosen/useGetImpactEnvironnemental";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";

//Images
import nuage1 from "../../../assets/images/impactEnvironnemental/nuage1.png";
import nuage2 from "../../../assets/images/impactEnvironnemental/nuage2.png";
import nuage3 from "../../../assets/images/impactEnvironnemental/nuage3.png";
import nuage4 from "../../../assets/images/impactEnvironnemental/nuage4.png";
import mountain1 from "../../../assets/images/impactEnvironnemental/mountain1.png";
import mountain2 from "../../../assets/images/impactEnvironnemental/mountain2.png";
import mountain3 from "../../../assets/images/impactEnvironnemental/mountain3.png";
import mountain4 from "../../../assets/images/impactEnvironnemental/mountain4.png";
import car from "../../../assets/images/impactEnvironnemental/car.svg";
import house from "../../../assets/images/impactEnvironnemental/house.svg";

interface Props {
  building_id?: string | null;
  household_id: string | null;
}

export const ImpactComponent = ({ building_id, household_id }: Props) => {
  const { monInstallation, userAuthInfo, studyInformation, scenarioChosen } =
    useAppSelector((state) => state);

  const { getImpactEnvironnemental, isLoading, errorMessage } =
    useGetImpactEnvironnemental();

  const mountain1ref = useRef<any>(null);
  const mountain2ref = useRef<HTMLImageElement>(null);
  const mountain3ref = useRef<HTMLImageElement>(null);
  const mountain4ref = useRef<HTMLImageElement>(null);
  const cloud1ref = useRef<HTMLImageElement>(null);
  const cloud2ref = useRef<HTMLImageElement>(null);
  const cloud3ref = useRef<HTMLImageElement>(null);
  const cloud4ref = useRef<HTMLImageElement>(null);
  const maisonref = useRef<HTMLImageElement>(null);
  const voitureref = useRef<HTMLImageElement>(null);
  const impactComponent = useRef<HTMLImageElement>(null);
  const bigTitle = useRef<any>(null);
  const modal1 = useRef<any>(null);

  // Animation to make the mountains move
  const animationMountains = () => {
    if (
      voitureref.current &&
      maisonref.current &&
      voitureref.current.children[0] &&
      mountain1ref.current &&
      mountain2ref.current &&
      mountain3ref.current &&
      mountain4ref.current
    ) {
      mountain1ref.current.style.transform = "translate(0px)";
      mountain2ref.current.style.transform = "translate(0px)";
      mountain3ref.current.style.transform = "translate(0px)";
      mountain4ref.current.style.transform = "translate(0px)";
      maisonref.current.style.transform = "translate(0px)";
    }
  };

  // Animation to make the car move
  const animationVoiture = () => {
    if (
      voitureref.current &&
      voitureref.current.children[0] &&
      modal1.current
    ) {
      voitureref.current.style.transform = "translate(-25vw, -1vw)";
      modal1.current.style.transform = "translate(22vw, 0vw)";
      voitureref.current.style.width = "12.889vw";
      voitureref.current.style.height = "8vw";
    }
  };

  // Animation to make the clouds move
  const animationClouds = () => {
    if (
      cloud1ref.current &&
      cloud2ref.current &&
      cloud3ref.current &&
      cloud4ref.current
    ) {
      cloud1ref.current.style.transform = "translate(0px)";
      cloud2ref.current.style.transform = "translate(0px)";
      cloud3ref.current.style.transform = "translate(0px)";
      cloud4ref.current.style.transform = "translate(0px)";
    }
  };

  useEffect(() => {
    if (
      monInstallation.isPersonalizedScenario ||
      monInstallation.indexOptionChosen !==
        monInstallation.impactEnvironnemental?.indexScenarioChosen
    ) {
      getImpactEnvironnemental({
        userToken: userAuthInfo.userToken,
        number_of_panels: scenarioChosen.number_of_panels ?? null,
        building_id: building_id,
        household_id: household_id,
        kit_home_management: scenarioChosen.kit_home_management ?? null,
        number_of_batteries: scenarioChosen.number_of_batteries || 0,
        indexScenarioChosen: monInstallation.indexOptionChosen,
        inverter_brand: scenarioChosen.inverter_brand || null,
        study_id: studyInformation?.study_id || "",
        single_battery_capacity: scenarioChosen.single_battery_capacity || -1,
        panel_indices: scenarioChosen.calepinage_chosen_indexes,
      });
      return;
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //Trigger animations with a delay
    setTimeout(() => {
      animationMountains();
      setTimeout(animationVoiture, 1200);
      animationClouds();
    }, 500);
  }, [isLoading]);

  return (
    <div ref={impactComponent} className="ImpactComponent">
      {isLoading ? (
        <div className="ProfitabilityComponent__LoadingContainer">
          <LoadingComponent diameter={60} />
        </div>
      ) : errorMessage ? (
        <ErrorMessage errorMessage={errorMessage} />
      ) : (
        <>
          <Layout>
            <div className="ImpactComponent__ContainerClouds">
              <div className="ImpactComponent__ContainerClouds__Clouds">
                <img
                  ref={cloud1ref}
                  className="cloud1"
                  src={nuage1}
                  alt="nuage"
                />
                <img
                  ref={cloud2ref}
                  className="cloud2"
                  src={nuage2}
                  alt="nuage"
                />
                <img
                  ref={cloud3ref}
                  className="cloud3"
                  src={nuage3}
                  alt="nuage"
                />
                <img
                  ref={cloud4ref}
                  className="cloud4"
                  src={nuage4}
                  alt="nuage"
                />
              </div>
            </div>
            <div className="ImpactComponent__Container">
              <p className="title">
                Émissions<span className="o2">CO</span>évitées
              </p>
              <p ref={bigTitle} className="bigTitle">
                {Math.round(
                  (monInstallation?.impactEnvironnemental?.CO2_equivalents
                    ?.CO2_savings_25years || 0) * 100
                ) / 100}{" "}
                <span className="o2">tCO</span> <span>sur 25 ans</span>
              </p>
              <p className="paragraph">
                Grâce à l'installation de panneaux photovoltaïques, vous
                réduisez de façon notable votre empreinte carbone&nbsp;!
                À&nbsp;titre de comparaison, un français émet en moyenne 9.5
                tCO2/an.
              </p>
            </div>
          </Layout>
          <div className="ImpactComponent__ContainerMountains">
            <div className="c-mountain1">
              <img
                ref={mountain1ref}
                className="mountain1"
                src={mountain1}
                alt="mountain"
              />
              <div ref={maisonref} className="c-maison">
                <img src={house} alt="house" />
                <div className="modal modal2">
                  <p className="title">
                    {(monInstallation.impactEnvironnemental?.CO2_equivalents
                      ?.heating_consumption.value || "") +
                      " " +
                      (monInstallation.impactEnvironnemental?.CO2_equivalents
                        ?.heating_consumption.label || "")}
                  </p>
                  <p className="subtitle">de consommation de chauffage.</p>
                </div>
              </div>
            </div>
            <div ref={mountain2ref} className="c-mountain2">
              <img className="mountain2" src={mountain2} alt="mountain" />
              <div ref={voitureref} className="c-voiture">
                <img className="car" src={car} alt="car" />
                <div ref={modal1} className="modal modal1">
                  <p className="title">
                    {(Intl.NumberFormat("fr-FR").format(
                      monInstallation.impactEnvironnemental?.CO2_equivalents
                        ?.km_car.value || 0
                    ) || "") +
                      " " +
                      (monInstallation.impactEnvironnemental?.CO2_equivalents
                        ?.km_car.label || "")}
                  </p>
                  <p className="subtitle">parcourus en voiture thermique.</p>
                </div>
              </div>
            </div>
            <img
              ref={mountain3ref}
              className="mountain3"
              src={mountain3}
              alt="mountain"
            />
            <img
              ref={mountain4ref}
              className="mountain4"
              src={mountain4}
              alt="mountain"
            />
          </div>
        </>
      )}
    </div>
  );
};
