//Styyles import
import "./monPotentielSolaireDesktop.scss";

//Libraries import
import React, { useState } from "react";

// Components import
import { Layout } from "../../../components/Layout/Layout";
import { PotentielSolaireImage } from "../../../components/MonPotentielSolaire/PotentielSolaireImage/PotentielSolaireImage";
import { PotentielSolaireLegendImage } from "../../../components/MonPotentielSolaire/PotentielSolaireLegendImage/PotentielSolaireLegendImage";
import { PotentielSolaireMainInfo } from "../../../components/MonPotentielSolaire/PotentielSolaireMainInfo/PotentielSolaireMainInfo";

// Images import
import pictoFilter from "@web/shared/src/assets/images/DesignSystemIllustrations/calques.svg";

// Interfaces import
import { IStudyInformation } from "../../../interfaces/generalInterfaces";

//Redux import
import { setSolarPotentialStepsStatusAction } from "../../../redux/appActions";
import { useAppDispatch } from "../../../redux/store/hook";

// Local interface declaration

interface IProps {
  studyInformation: IStudyInformation;
  isForPdf?: boolean;
}

export const MonPotentielSolaireDesktop = ({
  studyInformation,
  isForPdf = false,
}: IProps) => {
  const dispatch = useAppDispatch();
  const [isFilterClicked, setIsFilterClicked] = useState<boolean>(true);

  return (
    <div className="MonPotentielSolaireDesktop">
      <Layout>
        <div className="MonPotentielSolaireDesktop__Container">
          <div className="MonPotentielSolaireDesktop__LeftContainer">
            <PotentielSolaireMainInfo
              numberOfPans={studyInformation?.roof_sections?.length}
              totalAreaToEquip={studyInformation?.roof_area_to_equip}
              roofMaterial={studyInformation.roof_type}
            />
          </div>
          <div className="MonPotentielSolaireDesktop__CenterContainer">
            <PotentielSolaireImage
              potentielSolaireImageBase64={
                studyInformation.solar_potential_image
              }
              satelliteVueImageBase64={studyInformation.building_image}
              roofSections={studyInformation.roof_sections}
              isFilterClicked={isFilterClicked}
              isForPdf={isForPdf}
            />
          </div>
          <div className="MonPotentielSolaireDesktop__RightContainer">
            {!isForPdf ? (
              <div className="MonPotentielSolaireDesktop__FilterContainer">
                <div className="MonPotentielSolaireDesktop__Filter">
                  <img src={pictoFilter} alt="filter" />
                  <p>Filtre</p>
                </div>
                <div
                  className="MonPotentielSolaireDesktop__FilterList"
                  onClick={() => setIsFilterClicked((prevState) => !prevState)}
                >
                  <div className="MonPotentielSolaireDesktop__FilterBorder">
                    <div
                      className={`MonPotentielSolaireDesktop__ClickableButton ${
                        isFilterClicked &&
                        "MonPotentielSolaireDesktop__FilterClicked"
                      }`}
                    />
                  </div>
                  <p>Potentiel solaire</p>
                </div>
              </div>
            ) : null}
            <PotentielSolaireLegendImage />
          </div>
        </div>
        {!isForPdf ? (
          <div className="MonPotentielSolaireDesktop__ExitButton">
            <button
              onClick={() =>
                dispatch(
                  setSolarPotentialStepsStatusAction({
                    solarPotentialStepsStatus: {
                      isSolarPotentialKOForm: true,
                    },
                  })
                )
              }
            >
              Je souhaite renseigner moi-même le potentiel solaire
            </button>
          </div>
        ) : (
          false
        )}
      </Layout>
    </div>
  );
};
