import "./subHeaderOptionChosen.scss";

// Components import
import { Layout } from "../Layout/Layout";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

import pictoWhiteStar from "../../assets/images/picto-white-star.svg";

// Interfaces import
import { INavigateData } from "../../services/useNavigation/useNavigation";

// Local interfaces declaration
interface Props {
  title?: string;
  buttonGoBackName?: string;
  goNext: (data?: INavigateData<any>) => void;
  goBack?: () => void;
  form?: string;
  isLoading?: boolean;
  errorMessage?: string;
  buttonName?: string;
  isNextButtonVisible?: boolean;
  imgSubHeader?: string;
  subTitle?: string;
  isRecommended?: boolean | null;
}

export const SubHeaderOptionChosen = ({
  buttonGoBackName = "Go back",
  title = "Title",
  goNext,
  goBack,
  form,
  isLoading = false,
  errorMessage,
  buttonName = "Suivant",
  isNextButtonVisible = true,
  imgSubHeader,
  subTitle = "Libérez-vous du réseau grâce au solaire !",
  isRecommended = true,
}: Props) => {
  return (
    <Layout>
      <div className="SubHeaderOptionChosen">
        {!isLoading &&
          !errorMessage && ( // If there is an error message we don't allow to go to next step
            <>
              <div className="SubHeaderOptionChosen__MainContainer">
                <div className="SubHeaderOptionChosen__Image">
                  <img
                    src={imgSubHeader}
                    alt="option choisie"
                    width="121"
                    height="121"
                  />
                </div>
                <div className="SubHeaderOptionChosen__Container">
                  {isRecommended && (
                    <div className="SubHeaderOptionChosen__isRecommended">
                      <img src={pictoWhiteStar} alt="" />
                      <p>Recommandé</p>
                    </div>
                  )}
                  <div className="SubHeaderOptionChosen__ContainerButtonText">
                    <div className="SubHeaderOptionChosen__Title">
                      <h2>{title}</h2>
                    </div>
                    {isNextButtonVisible && (
                      <div className="SubHeaderOptionChosen__CallToAction">
                        <CTAButton
                          name={buttonName}
                          onClick={() => goNext()}
                          form={form}
                          isLoading={isLoading}
                        />
                      </div>
                    )}
                  </div>
                  <div className="SubHeaderOptionChosen__SubTitle">
                    <p>{subTitle}</p>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    </Layout>
  );
};
