//Stylesl import
import { DSColors } from "@web/shared/src/styles/variables";
import "./freeProduct.scss";

//Libraries import
import { useState } from "react";

// Components import
import { Dropdown } from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";

import { BoutonPicto } from "@web/shared/dist/components/DesignSystem/Boutons/BoutonPicto/BoutonPicto";
import { TextArea } from "@web/shared/dist/components/DesignSystem/Inputs/TextArea/TextArea";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { ExtraChargesCarrousel } from "../../../containers/ProductPageRG/ExtraChargesCarrousel/ExtraChargesCarrousel";
import { ExtraChargesListItem } from "../../../containers/ProductPageRG/ExtraChargesListItem/ExtraChargesListItem";
import { ExtraChargesModal } from "../../../containers/ProductPageRG/ExtraChargesModal/ExtraChargesModal";
import { ProductTotalPrice } from "../../../containers/ProductPageRG/ProductTotalPrice/ProductTotalPrice";

// Interfaces import
import {
  IDataProduct,
  TFraisSupp,
  TProjectPages,
} from "../../../interfaces/renoglobaleInterface";

//Services import
import { setCartProductsAction } from "../../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";

// Local interfaces declaration
interface IOption {
  label: string | null;
  value: string | null;
}

interface IProps {
  dataProductsFromCategory: IDataProduct[];
  selectedProductIndex?: number | null;
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  fetchProjectKPI: ({
    cartProductsToDisplay,
  }: {
    cartProductsToDisplay: IDataProduct[];
  }) => Promise<void>;
  isEligibleRenoGlobal: boolean;
}
export const FreeProduct = ({
  dataProductsFromCategory,
  selectedProductIndex,
  setScreenSelected,
  fetchProjectKPI,
  isEligibleRenoGlobal,
}: IProps) => {
  const dispatch = useAppDispatch();

  const cartProductsToDisplay = useAppSelector((state) => state.cartProducts);

  const existingProduct =
    (selectedProductIndex || selectedProductIndex === 0) &&
    cartProductsToDisplay
      ? cartProductsToDisplay[selectedProductIndex]
      : null;

  // States
  const [label, setLabel] = useState<string>(
    existingProduct?.product_name || ""
  );
  const [description, setDescription] = useState<string>(
    existingProduct?.selected_characteristics.free_product_description || ""
  );
  const [priceHT, setPriceHT] = useState<number>(
    existingProduct?.product_price_without_tax || 0
  );

  const TVAOptions = [
    { label: "5.5%", value: "5.5" },
    { label: "10%", value: "10" },
    { label: "20%", value: "20" },
  ];
  const [TVA, setTVA] = useState<IOption>(
    TVAOptions.find(
      (option) => Number(option.value) === existingProduct?.TVA_percentage
    ) || TVAOptions[0]
  );

  const [fraisSupp, setFraisSupp] = useState<TFraisSupp[]>(
    existingProduct?.extra_charges_added || []
  );
  const [isModaleFraisSuppOpen, setIsModaleFraisSuppOpen] =
    useState<boolean>(false);

  const initialFilteredListFraisSuppValue: TFraisSupp[] = [];

  // Merge all frais supp of all products in one array and remove duplicate
  const filteredListPotentialFraisSupp = dataProductsFromCategory
    .reduce((acc, product) => {
      return [...acc, ...product.potential_products_to_add];
    }, initialFilteredListFraisSuppValue)
    .reduce((accTwo, fraisSupp) => {
      if (
        accTwo.find((elem) => elem.sage_reference === fraisSupp.sage_reference)
      ) {
        return accTwo;
      }
      return [...accTwo, fraisSupp];
    }, initialFilteredListFraisSuppValue);

  const addProductToMySelection = () => {
    if (!productToAdd) {
      return; // If not product selected we can not add it to the selectin
    }

    productToAdd.isSelected = true;

    //If we have an selectedProductIndex it means the product is already in the recommended products : we need to update it
    if (selectedProductIndex || selectedProductIndex === 0) {
      const newProductToDisplay = [...cartProductsToDisplay];
      newProductToDisplay.splice(selectedProductIndex, 1, productToAdd);
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    } else {
      // Otherwise we just add the new product to the recommended products
      const newProductToDisplay = [...cartProductsToDisplay, productToAdd];
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    }

    // We navigate back to the "my project" page
    setScreenSelected("project");
  };

  const productToAdd: IDataProduct = {
    product_type: "AUTRE",
    product_name: label,
    product_price_without_tax: priceHT,
    product_price_with_tax: priceHT * (1 + Number(TVA.value) / 100),
    product_price_with_helps_deducted: 0,
    TVA_percentage: Number(TVA.value),
    help: [],
    recommendation_index: 0,
    product_characteristics: {},
    sage_reference: "",
    installation_price_with_tax: 0,
    installation_price_without_tax: 0,
    installation_tva_percentage: 0,
    sage_reference_installation: "",
    potential_products_to_add: [],
    selected_characteristics: {
      free_product_description: description,
    },
    extra_charges_added: fraisSupp,
    product_alias: label,
  };

  return (
    <div className="FreeProduct">
      <ExtraChargesModal
        isModaleFraisSuppOpen={isModaleFraisSuppOpen}
        setFraisSupp={setFraisSupp}
        setIsModaleFraisSuppOpen={setIsModaleFraisSuppOpen}
      />
      <section className="FreeProduct__FormContainer">
        <div className="FreeProduct__ProductContainer">
          <div className="FreeProduct__Textinput">
            <TextInput
              name="Label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              placeholder="Label"
              inputLableText="Label"
              inputLableHidden={false}
            />
          </div>
        </div>
        <div className="FreeProduct__ProductContainer">
          <div className="FreeProduct__Textinput">
            <TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
              inputLableText="Description"
              inputLableHidden={false}
            />
          </div>
        </div>
        <div className="FreeProduct__ProductContainer">
          <div className="FreeProduct__Textinput">
            <TextInput
              name="Montant TTC"
              value={priceHT?.toString()}
              type="number"
              placeholder="Montant TTC"
              iconHidden={false}
              iconType="euros"
              onChange={(e) => setPriceHT(e.target.valueAsNumber)}
            />
          </div>
        </div>
        <div className="FreeProduct__Dropdown">
          <Dropdown
            options={TVAOptions}
            optionsSelected={[TVA]}
            onSelect={(option) => {
              setTVA(() => option);
            }}
            multipleChoices={false}
            tags={false}
            placeholder="TVA (%)"
          />
        </div>
      </section>
      {filteredListPotentialFraisSupp.length > 0 ? (
        <ExtraChargesCarrousel
          filteredListFraisSupp={filteredListPotentialFraisSupp}
          setFraisSupp={setFraisSupp}
        />
      ) : null}
      {fraisSupp.length > 0 ? (
        <section className="FreeProduct__ListFraisSuppContainer">
          {fraisSupp.map((extraCharge, index) => {
            return (
              <ExtraChargesListItem
                key={index + extraCharge.product_name}
                extraCharge={extraCharge}
                setFraisSupp={setFraisSupp}
              />
            );
          })}
        </section>
      ) : null}
      <section className="FreeProduct__ButtonPicto">
        {
          <BoutonPicto
            text="Ajouter une spécificité"
            color={DSColors.Mint}
            onClick={() => setIsModaleFraisSuppOpen(true)}
          />
        }
      </section>

      {
        <ProductTotalPrice
          addProductToMySelection={addProductToMySelection}
          fraisSupp={fraisSupp}
          productSelected={productToAdd}
          isUpdateproduct={selectedProductIndex !== undefined}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      }
    </div>
  );
};
