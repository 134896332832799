//Stylesl import
import { DSColors } from "@web/shared/src/styles/variables";
import "./VMCPage.scss";

//Libraries import
import find from "lodash.find";
import { useState } from "react";
import SVG from "react-inlinesvg";

// Components import
import {
  Dropdown,
  manageDropdownSingleSelection,
} from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";

import { BoutonPicto } from "@web/shared/dist/components/DesignSystem/Boutons/BoutonPicto/BoutonPicto";
import { ExtraChargesCarrousel } from "../../../containers/ProductPageRG/ExtraChargesCarrousel/ExtraChargesCarrousel";
import { ExtraChargesListItem } from "../../../containers/ProductPageRG/ExtraChargesListItem/ExtraChargesListItem";
import { ExtraChargesModal } from "../../../containers/ProductPageRG/ExtraChargesModal/ExtraChargesModal";
import { ProductTotalPrice } from "../../../containers/ProductPageRG/ProductTotalPrice/ProductTotalPrice";
import { ProductFilterContainer } from "../filterComponent/ProductFilterContainer";

// Images import
import Trash from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";

// Interfaces import
import { ISageProduct } from "../../../interfaces/generalInterfaces";
import {
  IDataProduct,
  TFraisSupp,
  TProjectPages,
} from "../../../interfaces/renoglobaleInterface";

//Services import
import { setCartProductsAction } from "../../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { formatDropdownOptionsFromSageProducts } from "../../../services/RenovationGlobale/formatDropdownOptionsFromSageProducts";
import { formatSageProductsForDropdown } from "../../../services/RenovationGlobale/formatProductSelectedForDropdown";

// Local interfaces declaration
interface IFilterSAGEProducts {
  sageProduct: ISageProduct[];
  sageProductCharacteristic?: keyof ISageProduct;
}

interface IOption {
  label: string | null;
  value: string | null;
}

interface IProps {
  dataProductsFromCategory: IDataProduct[];
  selectedProductIndex?: number | null;
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  fetchProjectKPI: ({
    cartProductsToDisplay,
  }: {
    cartProductsToDisplay: IDataProduct[];
  }) => Promise<void>;
  isEligibleRenoGlobal: boolean;
}
export const VMCPage = ({
  dataProductsFromCategory,
  selectedProductIndex,
  setScreenSelected,
  fetchProjectKPI,
  isEligibleRenoGlobal,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { SAGEItemsInfo: productsFromSage } = useAppSelector((state) => state);

  // We filter the SAGE products to only keep the SAGE VMC products that are in the dataProductsFromCategory products list from data backend (= products that are elligible for the house)
  const productsCharacteristicsListForVMCFromSAGE = productsFromSage.filter(
    (itemInfo) =>
      dataProductsFromCategory.some(
        (dataProduct) => dataProduct.sage_reference === itemInfo.AR_Ref
      )
  );

  const cartProductsToDisplay = useAppSelector((state) => state.cartProducts);

  const selectedProduct =
    selectedProductIndex || selectedProductIndex === 0
      ? cartProductsToDisplay[selectedProductIndex]
      : null;

  // States
  const [productSelected, setProductSelected] = useState<IOption[]>(
    selectedProduct && cartProductsToDisplay
      ? [
          {
            label:
              selectedProduct.product_alias || selectedProduct.product_name,
            value: selectedProduct.sage_reference,
          },
        ]
      : []
  );

  const [brand, setBrand] = useState<IOption[]>([]);
  const [flowType, setFlowType] = useState<IOption[]>([]);

  const [fraisSupp, setFraisSupp] = useState<TFraisSupp[]>(
    selectedProduct && cartProductsToDisplay
      ? selectedProduct.extra_charges_added || []
      : []
  );
  const [isModaleFraisSuppOpen, setIsModaleFraisSuppOpen] =
    useState<boolean>(false);

  // For each product characteristics if there is a dropdown value in the local state we filter the product list to keep only
  // the products that have the dropdown value in their product characteristics
  // Note : the dropdown value should not filter its own product_characteristics to be able to select another product_characteristics after selecting a first one
  const filterProductsCharacteristicsListAccordingToDropdownsValue = ({
    sageProduct,
    sageProductCharacteristic,
  }: IFilterSAGEProducts) => {
    return sageProduct.filter((product) => {
      return (
        (sageProductCharacteristic === "Marque" ||
          product.Marque?.toString() ===
            (brand?.[0]?.value ?? product.Marque?.toString())) &&
        (sageProductCharacteristic === "Gamme" ||
          product.Gamme?.toString() ===
            (flowType?.[0]?.value ?? product.Gamme?.toString()))
      );
    });
  };

  const initialFilteredListFraisSuppValue: TFraisSupp[] = [];

  // Merge all frais supp of all products in one array and remove duplicate
  const filteredListPotentialFraisSupp = dataProductsFromCategory
    .reduce((acc, product) => {
      return [...acc, ...product.potential_products_to_add];
    }, initialFilteredListFraisSuppValue)
    .reduce((accTwo, fraisSupp) => {
      if (
        accTwo.find((elem) => elem.sage_reference === fraisSupp.sage_reference)
      ) {
        return accTwo;
      }
      return [...accTwo, fraisSupp];
    }, initialFilteredListFraisSuppValue);

  const productToAdd = (() => {
    const newProductList: IDataProduct[] = JSON.parse(
      JSON.stringify(dataProductsFromCategory)
    ); //Deep Copy avoid to updtate products_list

    const product = newProductList.find(
      (product) => product.sage_reference === productSelected?.[0]?.value
    );
    if (!product) {
      return undefined;
    }

    product.extra_charges_added = fraisSupp;

    return product;
  })();

  const addProductToMySelection = () => {
    if (!productToAdd) {
      return; // If not product selected we can not add it to the selectin
    }

    productToAdd.isProductRecommended =
      find(cartProductsToDisplay, productToAdd)?.isProductRecommended || false;
    productToAdd.isSelected = true;

    //If we have an selectedProductIndex it means the product is already in the recommended products : we need to update it
    if (selectedProductIndex || selectedProductIndex === 0) {
      const newProductToDisplay = [...cartProductsToDisplay];
      newProductToDisplay.splice(selectedProductIndex, 1, productToAdd);
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    } else {
      // Otherwise we just add the new product to the recommended products
      const newProductToDisplay = [...cartProductsToDisplay, productToAdd];
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    }

    // We navigate back to the "my project" page
    setScreenSelected("project");
  };

  const sageProductFormatedForDropdown = formatSageProductsForDropdown({
    sageProducts: filterProductsCharacteristicsListAccordingToDropdownsValue({
      sageProduct: productsCharacteristicsListForVMCFromSAGE,
    }),
    dataProducts: dataProductsFromCategory,
  });

  return productsCharacteristicsListForVMCFromSAGE.length === 0 ? (
    <div>Pas de produits disponible</div>
  ) : (
    <div className="VMCPage">
      <ExtraChargesModal
        isModaleFraisSuppOpen={isModaleFraisSuppOpen}
        setFraisSupp={setFraisSupp}
        setIsModaleFraisSuppOpen={setIsModaleFraisSuppOpen}
      />
      <section className="VMCPage__FormContainer">
        <div className="VMCPage__SubTitle">
          <h3>Produit : </h3>
        </div>
        <div className="VMCPage__ProductContainer">
          <div className="VMCPage__Dropdown">
            <Dropdown
              options={sageProductFormatedForDropdown}
              optionsSelected={productSelected}
              onSelect={(option) => {
                setProductSelected(() => [option]);
              }}
              multipleChoices={false}
              tags={false}
              placeholder="Choisir ma VMC"
              maxHeight="40vh"
              searchable={true}
            />
          </div>
          <div className="VMCPage__SVG">
            {productSelected?.[0]?.value ? (
              <SVG src={Trash} onClick={() => setProductSelected([])} />
            ) : null}
          </div>
        </div>

        <ProductFilterContainer>
          <div className="VMCPage__FiltersContent">
            <div className="VMCPage__QuestionContainer">
              <div className="VMCPage__Question">
                <div className="VMCPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts:
                        filterProductsCharacteristicsListAccordingToDropdownsValue(
                          {
                            sageProduct:
                              productsCharacteristicsListForVMCFromSAGE,
                            sageProductCharacteristic: "Marque",
                          }
                        ),
                      productCharacteristic: "Marque",
                    })}
                    optionsSelected={brand}
                    onSelect={(option) => {
                      manageDropdownSingleSelection(option, setBrand);
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Marque"
                  />
                </div>
                <div className="VMCPage__SVG">
                  {brand?.[0]?.value ? (
                    <SVG src={Trash} onClick={() => setBrand([])} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </ProductFilterContainer>
      </section>
      {filteredListPotentialFraisSupp.length > 0 ? (
        <ExtraChargesCarrousel
          filteredListFraisSupp={filteredListPotentialFraisSupp}
          setFraisSupp={setFraisSupp}
        />
      ) : null}
      {fraisSupp.length > 0 ? (
        <section className="VMCPage__ListFraisSuppContainer">
          {fraisSupp.map((extraCharge, index) => {
            return (
              <ExtraChargesListItem
                key={index + extraCharge.product_name}
                extraCharge={extraCharge}
                setFraisSupp={setFraisSupp}
              />
            );
          })}
        </section>
      ) : null}
      <section className="VMCPage__ButtonPicto">
        {
          <BoutonPicto
            text="Ajouter une spécificité"
            color={DSColors.Mint}
            onClick={() => setIsModaleFraisSuppOpen(true)}
          />
        }
      </section>

      {productToAdd ? (
        <ProductTotalPrice
          addProductToMySelection={addProductToMySelection}
          fraisSupp={fraisSupp}
          productSelected={productToAdd}
          isUpdateproduct={selectedProductIndex !== undefined}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      ) : null}
    </div>
  );
};
