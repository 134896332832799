import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  pagination: {
    fontFamily: "DMSansRegular",
    fontSize: 10,
  },
});

export const PdfPagination = () => {
  return (
    <View>
      <Text
        style={styles.pagination}
        render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
      ></Text>
    </View>
  );
};
