import "./SubHeaderRG.scss";

// Components import
import { Layout } from "../../components/Layout/Layout";

// Interfaces import
import { IStudyInformation } from "../../interfaces/generalInterfaces";

// Local interface declaration
interface IProps {
  title: string;
  studyInformation: IStudyInformation;
  SubHeaderRGButtons: () => JSX.Element;
}

export const SubHeaderRG = ({
  title,
  studyInformation,
  SubHeaderRGButtons,
}: IProps) => {
  return (
    <div className="SubHeaderRG">
      <Layout>
        <div className="SubHeaderRG__Header">
          <div className="SubHeaderRG__CustomerInfo">
            <p>
              {(studyInformation.becqe_firstname?.charAt(0).toUpperCase() ||
                "") +
                (studyInformation.becqe_firstname?.slice(1) || "") +
                " " +
                (studyInformation.becqe_lastname?.charAt(0).toUpperCase() ||
                  "") +
                (studyInformation.becqe_lastname?.slice(1) || "") +
                ", " +
                studyInformation.address}
            </p>
          </div>
          <div className="SubHeaderRG__Title">
            <h2>{title}</h2>
            <div className="SubHeaderRG__ButtonsContainer">
              {SubHeaderRGButtons()}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
