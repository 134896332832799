// Style Import
import "./singleScenario.scss";

//Interface import
import {
  IScenario,
  IDataScenarioPvExtended,
} from "../../../interfaces/generalInterfaces";

//Component import
import { ProductsList } from "../../../components/ScenariosComponents/ProductsList/ProductsList";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { DataInformationLine } from "../../../components/ScenariosComponents/DataInformationLine/DataInformationLine";

// image import
import pictoWhiteStar from "../../../assets/images/picto-white-star.svg";
import ArrowRight from "@web/shared/src/assets/images/DesignSystemIcons/arrow-right.svg";

//Reduc import
import { useAppDispatch } from "../../../redux/store/hook";
import {
  setIndexScenariosOptionChosenAction,
  setIsPersonalizedScenarioAction,
  setScenarioChosenAction,
} from "../../../redux/appActions";

//Services import
import { calculateMonthlyPayment } from "../../../services/calculateMonthlyPayment";

//Interfaces import
import { INavigateData } from "../../../services/useNavigation/useNavigation";

//Data import
import { standarInterestRate } from "@web/shared/dist/datas/dataHypotethis";

interface IProps {
  singleScenario: IDataScenarioPvExtended;
  index: number;
  goNext: (data?: INavigateData<any>) => void;
  company_id: string | null;
  scenario_id: string | null;
}

export const SingleScenario = ({
  singleScenario,
  index,
  goNext,
  company_id,
  scenario_id,
}: IProps) => {
  const dispatch = useAppDispatch();

  const handleClickEnSavoirPlus = (indexOptionChosen: number) => {
    const prices = singleScenario.prices;
    const scenario: IScenario = {
      scenario_id: scenario_id,
      power_needed: singleScenario.products.installed_power,
      kit_home_management: singleScenario.products.kit_home_management,
      number_of_batteries: singleScenario.products.number_of_batteries,
      number_of_panels: singleScenario.products.number_of_panels,
      recommended_calepinage: singleScenario.recommended_calepinage,
      sage_reference_PV: singleScenario.sage_reference_PV,
      selfconsumption_rate: singleScenario.selfconsumption_rate,
      selfproduction_rate: singleScenario.selfproduction_rate,
      total_cost_help_deducted: singleScenario.total_cost_help_deducted,
      total_cost_without_help: singleScenario.total_cost_without_help,
      total_helps: singleScenario.total_helps,
      pitch: singleScenario.pitch,
      internal_rate_return: singleScenario.internal_rate_return,
      formule_name: singleScenario.formule_name,
      "25_years_economies": singleScenario["25_years_economies"],
      calepinage_image: singleScenario.calepinage_image,
      tva: singleScenario.tva,
      is_storage_battery:
        singleScenario.products.number_of_batteries !== null &&
        singleScenario.products.number_of_batteries > 0
          ? true
          : false,
      battery_pose_price_without_tax:
        prices?.battery?.battery_pose_price_without_tax,
      battery_pose_sage_reference: prices?.battery?.battery_pose_sage_reference,
      battery_price_without_tax: prices?.battery?.battery_price_without_tax,
      battery_sage_reference: prices?.battery?.battery_sage_reference,
      kit_pose_price_without_tax:
        prices?.kit_home_management?.kit_pose_price_without_tax,
      kit_pose_sage_reference:
        prices?.kit_home_management?.kit_pose_sage_reference,
      kit_price_without_tax: prices?.kit_home_management?.kit_price_without_tax,
      kit_sage_reference: prices?.kit_home_management?.kit_sage_reference,
      pv_pose_price_without_tax: prices?.pv?.pose_price_without_tax,
      pv_pose_sage_reference: prices?.pv?.pose_sage_reference,
      pv_price_without_tax: prices?.pv?.pv_price_without_tax,
      pv_sage_reference: prices?.pv?.pv_sage_reference,
      inverter_brand: singleScenario.products.inverter_brand,
      single_battery_capacity: singleScenario.products.single_battery_capacity,
      calepinage_chosen_ortho_coordinates:
        singleScenario.calepinage_chosen_ortho_coordinates,
      calepinage_chosen_solar_coordinates:
        singleScenario.calepinage_chosen_solar_coordinates,
      calepinage_chosen_indexes: singleScenario.calepinage_chosen_indexes,
      solar_potential_image: singleScenario.solar_potential_image,
      extra_fees: [],
    };

    dispatch(
      setScenarioChosenAction({
        scenarioChosen: scenario,
      })
    );

    // Allow to track which scenario is chosen in the scenarios array, especially used for caching (not reloading) purpose
    dispatch(
      setIndexScenariosOptionChosenAction({
        indexOptionChosen: indexOptionChosen,
      })
    );

    dispatch(
      setIsPersonalizedScenarioAction({
        isPersonalizedScenario: false,
      })
    );
    goNext();
  };

  interface Dictionnary {
    [key: string]: string;
  }

  const dictionnary: Dictionnary = {
    selfproduction_rate: "Autoproduction",
    "25_years_economies": "Économies sur 25 ans",
    internal_rate_return: "Rentabilité annuelle",
    selfconsumption_rate: "Autoconsommation",
  };

  const monthlyPayment = calculateMonthlyPayment({
    borrowedCapital: singleScenario.total_cost_without_help || 0,
    fundingPeriod: 15,
    interestRate: standarInterestRate,
  });

  return (
    <div
      className={
        "SingleScenario SingleScenario__Scenario" +
        singleScenario.formule_name?.toLocaleLowerCase()
      }
    >
      <section className="SingleScenario__ImageContainer">
        <img
          src={"data:image/png;base64, " + singleScenario.calepinage_image}
          alt=""
          width={100}
          height={100}
        />
      </section>
      <section className={"SingleScenario__Title"}>
        <p
          className={
            "SingleScenario__Title" +
            singleScenario.formule_name?.toLocaleLowerCase()
          }
        >
          {singleScenario.formule_name}{" "}
          {singleScenario.products.installed_power &&
            singleScenario.products.installed_power / 1000}
          kWc
        </p>
      </section>
      <section className="SingleScenario__SubTitle">
        <p>
          <em>
            {singleScenario.pitch
              ? singleScenario.pitch
              : "Libérez-vous du réseau grâce au solaire\xa0!"}
          </em>
        </p>
      </section>
      <section className="SingleScenario__ProductList">
        <ProductsList
          products={singleScenario.products}
          formuleName={singleScenario.formule_name || ""}
        />
      </section>
      <section className="SingleScenario__DataInformation">
        {singleScenario.ordered_kpis.map((kpi, key) => (
          <DataInformationLine
            key={kpi.value}
            dataName={dictionnary[kpi.label]}
            dataNumber={
              kpi.label === "25_years_economies"
                ? new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(kpi.value)
                : (Math.round(kpi.value * 10000) / 100).toString() + " %"
            }
            isUnderlineVisible={
              (key === singleScenario.ordered_kpis.length - 1) === false
            }
            formuleName={key === 0 ? singleScenario.formule_name : ""}
          />
        ))}
      </section>
      {company_id === "b997f1a6-1bf0-e911-a812-000d3a4aabe1" ? (
        // b997f1a6-1bf0-e911-a812-000d3a4aabe1 => tucoenergie
        <section className="SingleScenario__Price">
          <p className="SingleScenario__PriceComptant">
            {singleScenario.total_cost_help_deducted &&
              new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(singleScenario.total_cost_help_deducted)}
            *
          </p>
          <p>
            {" "}
            Ou à partir de{" "}
            <strong>
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(monthlyPayment)}
            </strong>
            /mois
          </p>
        </section>
      ) : (
        <section className="SingleScenario__PriceMonthly">
          <p>
            A partir de{" "}
            <strong>
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(monthlyPayment)}
            </strong>
            /mois sur 15 ans
          </p>
        </section>
      )}
      <section className="SingleScenario__ButtonContainer">
        <CTAButton
          name={""}
          onClick={() => handleClickEnSavoirPlus(index)}
          iconSrc={ArrowRight}
          isGap={false}
        />
      </section>
      {singleScenario.recommended_calepinage && (
        <section className="SingleScenario__RecommandedMobile">
          <img
            src={pictoWhiteStar}
            alt="picto white star"
            width={17}
            height={16}
          />
          <p>Recommandé</p>
        </section>
      )}
    </div>
  );
};
