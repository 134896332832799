interface IColorsInIDF {
  [key: string]: {
    label: string;
    value: string;
    color: string;
  }[];
}
interface IColorsOutOfIDF {
  [key: string]: {
    label: string;
    value: string;
    color: string;
  }[];
}

export const colorsInIDF: IColorsInIDF = {
  "1": [
    {
      label: "Inférieur ou  égale à 23 541 €",
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: "Entre 23 542 € et 25 714 €",
      value: "jauneModeste",
      color: "#ED8C00",
    },
    {
      label: "Entre 25 715 € et 28 657 €",
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: "Entre 28 658 € et 40 018 €",
      value: "violet",
      color: "#752BAF",
    },
    {
      label: "Supérieur à 40 018 €",
      value: "rose",
      color: "#D61D98",
    },
  ],
  "2": [
    {
      label: "Inférieur ou  égale à 34 551 €",
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: "Entre 34 552 € et 37 739 €",
      value: "jauneModeste",
      color: "#ED8C00",
    },
    {
      label: "Entre 37 740 € et 42 058 €",
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: "Entre 42 059 € et 58 827 €",
      value: "violet",
      color: "#752BAF",
    },
    {
      label: "Supérieur à 58 827 €",
      value: "rose",
      color: "#D61D98",
    },
  ],
  "3": [
    {
      label: "Inférieur ou  égale à 41 493 €",
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: "Entre 41 494 € et 45 326 €",
      value: "jauneModeste",
      color: "#ED8C00",
    },
    {
      label: "Entre 45 327 € et 50 513 €",
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: "Entre 50 514 € et 70 382 €",
      value: "violet",
      color: "#752BAF",
    },
    {
      label: "Supérieur à 70 382 €",
      value: "rose",
      color: "#D61D98",
    },
  ],
  "4": [
    {
      label: "Inférieur ou  égale à 48 447 €",
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: "Entre 48 448 € et 52 925 €",
      value: "jauneModeste",
      color: "#ED8C00",
    },
    {
      label: "Entre 52 926 € et 58 981 €",
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: "Entre 58 982 € et 82 839 €",
      value: "violet",
      color: "#752BAF",
    },
    {
      label: "Supérieur à 82 839 €",
      value: "rose",
      color: "#D61D98",
    },
  ],
  "5": [
    {
      label: "Inférieur ou  égale à 55 427 €",
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: "Entre 55 428 € et 60 546 €",
      value: "jauneModeste",
      color: "#ED8C00",
    },
    {
      label: "Entre 60 547 € et 67 473 €",
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: "Entre 67 474 € et 94 844 €",
      value: "violet",
      color: "#752BAF",
    },
    {
      label: "Supérieur à 94 844 €",
      value: "rose",
      color: "#D61D98",
    },
  ],
};

export const colorsOutOfIDF: IColorsOutOfIDF = {
  "1": [
    {
      label: "Inférieur ou  égale à 17 009 €",
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: "Entre 17 010 € et 19 565 €",
      value: "jauneModeste",
      color: "#ED8C00",
    },
    {
      label: "Entre 19 566 € et 21 805 €",
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: "Entre 21 806 € et 30 549 €",
      value: "violet",
      color: "#752BAF",
    },
    {
      label: "Supérieur à 30 549 €",
      value: "rose",
      color: "#D61D98",
    },
  ],

  "2": [
    {
      label: "Inférieur ou  égale à 24 875 €",
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: "Entre 24 876 € et 28 614 €",
      value: "jauneModeste",
      color: "#ED8C00",
    },
    {
      label: "Entre 28 615 € et 31 889 €",
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: "Entre 31 890 € et 44 907 €",
      value: "violet",
      color: "#752BAF",
    },
    {
      label: "Supérieur à 44 907 €",
      value: "rose",
      color: "#D61D98",
    },
  ],

  "3": [
    {
      label: "Inférieur ou  égale à 29 917 €",
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: "Entre 29 918 € et 34 411 €",
      value: "jauneModeste",
      color: "#ED8C00",
    },
    {
      label: "Entre 34 412 € et 38 349 €",
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: "Entre 38 350 € et 54 071 €",
      value: "violet",
      color: "#752BAF",
    },
    {
      label: "Supérieur à 54 071 €",
      value: "rose",
      color: "#D61D98",
    },
  ],

  "4": [
    {
      label: "Inférieur ou  égale à 34 948 €",
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: "Entre 34 949 € et 40 201 €",
      value: "jauneModeste",
      color: "#ED8C00",
    },
    {
      label: "Entre 40 202 € et 44 802 €",
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: "Entre 44 803 € et 63 235 €",
      value: "violet",
      color: "#752BAF",
    },
    {
      label: "Supérieur à 63 235 €",
      value: "rose",
      color: "#D61D98",
    },
  ],

  "5": [
    {
      label: "Inférieur ou  égale à 40 002 €",
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: "Entre 40 003 € et 46 015 €",
      value: "jauneModeste",
      color: "#ED8C00",
    },
    {
      label: "Entre 46 016 € et 51 281 €",
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: "Entre 51 282 € et 72 400 €",
      value: "violet",
      color: "#752BAF",
    },
    {
      label: "Supérieur à 72 400 €",
      value: "rose",
      color: "#D61D98",
    },
  ],
};
