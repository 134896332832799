//Style import
import "./mesConsommationsSubHeader.scss";

//Image import
import logoContratElec from "../../../assets/images/logo_contratElec.svg";

import { Layout } from "../../Layout/Layout";

//Local interface
interface Iprops {
  data: {
    tarification_option: string;
    subscribed_power: number;
    annual_electric_consumption: number;
    monthly_electric_consumption: number;
  };
}
export const MesConsommationsSubHeader = ({ data }: Iprops) => {
  return (
    <div className="MesConsommationsSubHeader">
      <div className="MesConsommationsSubHeader__Container">
        <Layout>
          <div className="MesConsommationsSubHeader__ContainerFlex">
            <section className="MesConsommationsSubHeader__DataContainer">
              <div className="MesConsommationsSubHeader__TitleContainer">
                <img src={logoContratElec} alt="logo contrat elec" />
                <h2>Votre contrat d’électricité</h2>
              </div>
              <div className="MesConsommationsSubHeader__InfoContainer">
                <div className="MesConsommationsSubHeader__InfoSection">
                  <h3>Option tarifaire</h3>
                  <p>{data.tarification_option}</p>
                </div>
                <div className="MesConsommationsSubHeader__InfoSection">
                  <h3>Puissance souscrite</h3>
                    <div className={'MesConsommationsSubHeader__InfoSection__Wrapper'}>
                        <p>{data.subscribed_power}</p>
                        <p>kVA</p>
                    </div>
                </div>
              </div>
            </section>

            <section className="MesConsommationsSubHeader__CardContainer DesktopOnly">
              <div className="MesConsommationsSubHeader__Card">
                <p>Consommation électrique mensuelle</p>
                <p>
                  <span>{data.monthly_electric_consumption} kWh</span>
                </p>
              </div>
              <div className="MesConsommationsSubHeader__Card">
                <p>Consommation électrique annuelle</p>
                <p>
                  <span>{data.annual_electric_consumption} kWh</span>
                </p>
              </div>
            </section>
          </div>
        </Layout>
      </div>
      <div className="MesConsommationsSubHeader__CardSection MobileOnly">
        <Layout>
          <div className="MesConsommationsSubHeader__CardContainer">
            <div className="MesConsommationsSubHeader__Card">
              <p>Consommation électrique mensuelle</p>
              <p>
                <span>{data.subscribed_power} kWh</span>
              </p>
            </div>
            <div className="MesConsommationsSubHeader__Card">
              <p>Consommation électrique annuelle</p>
              <p>
                <span>{data.annual_electric_consumption} kWh</span>
              </p>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
};
