// Styles import
import "./MesConsommationsAndEquipmentsRG.scss";

// Components import
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import { Quantite } from "@web/shared/dist/components/DesignSystem/Boutons/Quantite/Quantite";
import {
  Dropdown,
  manageDropdownSingleSelection,
} from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";

// Images import
import Chauffage from "@web/shared/dist/assets/images/DesignSystemIllustrations/chauffage1.svg";
import clim from "@web/shared/dist/assets/images/DesignSystemIllustrations/clim1.svg";
import ECS from "@web/shared/dist/assets/images/DesignSystemIllustrations/ECS1.svg";
import electricite from "@web/shared/dist/assets/images/DesignSystemIllustrations/electricite1.svg";
import piscine from "@web/shared/dist/assets/images/DesignSystemIllustrations/piscine1.svg";
import voitureElec from "@web/shared/dist/assets/images/DesignSystemIllustrations/voiture-elec1.svg";

// Data import
import { mesConsommationsQuestionsData } from "./MesConsommationsQuestionsData";

// Interfaces import
import { IStudyInformation } from "../../interfaces/generalInterfaces";

// Local interfaces declaration
interface IProps {
  studyInformation: IStudyInformation;
  isElectricite: boolean;
  electriciteTypeInstallation: IOption[];

  setElectriciteTypeInstallation: React.Dispatch<
    React.SetStateAction<IOption[]>
  >;
  electriciteSuscribedPower: IOption[];
  setElectriciteSuscribedPower: React.Dispatch<React.SetStateAction<IOption[]>>;
  electriciteOptionTarifaire: IOption | undefined;
  setElectriciteOptionTarifaire: React.Dispatch<
    React.SetStateAction<IOption | undefined>
  >;
  electriciteConsommationEuros: string | null;
  setElectriciteConsommationEuros: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  electriciteConsommationKwh: string | null;
  setElectriciteConsommationKwh: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  electricitePrincipauxEquipments: IOption[];
  setElectricitePrincipauxEquipments: React.Dispatch<
    React.SetStateAction<IOption[]>
  >;
  electriciteSurfaceChauffee: string | null;
  setElectriciteSurfaceChauffee: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  electriciteTemperatureConfort: number | null;
  setElectriciteTemperatureConfort: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  electriciteTypeChauffage: IOption[];
  setElectriciteTypeChauffage: React.Dispatch<React.SetStateAction<IOption[]>>;
  electriciteNombreEmetteur: number | null;
  setElectriciteNombreEmetteur: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  electriciteTypeEmetteur: IOption[];
  setElectriciteTypeEmetteur: React.Dispatch<React.SetStateAction<IOption[]>>;
  electriciteStorageCapacity: IOption[];
  setElectriciteStorageCapacity: React.Dispatch<
    React.SetStateAction<IOption[]>
  >;
  electriciteChauffageConsumptionRate: string | null;
  setElectriciteChauffageConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  electriciteECSConsumptionRate: string | null;
  setElectriciteECSConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  electriciteAutresConsumptionRate: string | null;
  setElectriciteAutresConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  sourceEnergie: IOption[];
  consumptionDataOk: boolean;
  setConsumptionDataOk: React.Dispatch<React.SetStateAction<boolean>>;
  electriciteTypeBallonEauChaude: IOption[];
  setElectriciteTypeBallonEauChaude: React.Dispatch<
    React.SetStateAction<IOption[]>
  >;
  electriciteInstallationYear: string | null;
  setElectriciteInstallationYear: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  electricitePiscineConsumptionRate: string | null;
  setElectricitePiscineConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  electriciteBorneDeRechargeConsumptionRate: string | null;
  setElectriciteBorneDeRechargeConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  electriciteClimatisationConsumptionRate: string | null;
  setElectriciteClimatisationConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setPriceElectricityPerkWh: React.Dispatch<React.SetStateAction<number>>;
  priceElectricityPerkWh: number;
  electriciteEclairageConsumptionRate: string | null;
  setElectriciteEclairageConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  checkElecEquipmentsDistribution: () => boolean;
  resetChauffageSection: () => void;
  resetECSSection: () => void;
  setElectricitePacAirEauInstallationYear: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  electricitePacAirEauInstallationYear: number | null;
  setElectricitePacAirAirInstallationYear: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  electricitePacAirAirInstallationYear: number | null;
}

interface IOption extends IImage {
  label: string | null;
  value: string | null;
}

interface IImage {
  imgSrc?: string;
  imgAltTxt?: string;
}

export const ElectricitePartForm = (props: IProps) => {
  const {
    isElectricite = false,
    electriciteTypeInstallation,
    setElectriciteTypeInstallation,
    electriciteSuscribedPower,
    setElectriciteSuscribedPower,
    electriciteOptionTarifaire,
    setElectriciteOptionTarifaire,
    electriciteConsommationEuros,
    setElectriciteConsommationEuros,
    electriciteConsommationKwh,
    setElectriciteConsommationKwh,
    sourceEnergie,
    consumptionDataOk,
    setPriceElectricityPerkWh,
    priceElectricityPerkWh,
  } = props;

  const manageConsommationEuroChange = (
    annualConsumptionEuros: string,
    price: number
  ) => {
    let consommationElectriqueKwhAnnuelle = Math.trunc(
      Number(annualConsumptionEuros) / price
    );
    setElectriciteConsommationEuros(annualConsumptionEuros);
    setElectriciteConsommationKwh(String(consommationElectriqueKwhAnnuelle));
  };

  const manageConsommationKwhChange = (
    annualConsumptionKwh: string,
    price: number
  ) => {
    let consommationElectriqueEuroAnnuelle = Math.trunc(
      Number(annualConsumptionKwh) * price
    );
    setElectriciteConsommationKwh(annualConsumptionKwh);
    setElectriciteConsommationEuros(String(consommationElectriqueEuroAnnuelle));
  };

  return (
    <section
      className={`MesConsommationsAndEquipementsRG__SectionContent ${
        !sourceEnergie.some((item) => item.value === "electricite") && "Hidden"
      }`}
      id="caracteristiques_du_logement"
    >
      <div className="MesConsommationsAndEquipementsRG__TitleOne">
        <img src={electricite} alt="électricité" />
        <h3>Électricité</h3>
      </div>
      <div
        className={`MesConsommationsAndEquipementsRG__ElectricityInformationsEnedisOK ${
          !consumptionDataOk && "Hidden"
        }`}
      >
        <div
          className={`MesConsommationsAndEquipementsRG__ElectricityInformationsEnedisOK__titleContainer`}
        >
          <p>Votre contrat d’électricité</p>
        </div>
        <div
          className={`MesConsommationsAndEquipementsRG__ElectricityInformationsEnedisOK__dropdownContainer`}
        >
          <p>Type d’installation: </p>
          <div
            style={{
              minWidth: "16rem",
            }}
          >
            <Dropdown
              tags={false}
              searchable={false}
              multipleChoices={false}
              options={
                mesConsommationsQuestionsData.electricite.typeDInstallation
                  .options
              }
              optionsSelected={electriciteTypeInstallation}
              onSelect={(option) =>
                manageDropdownSingleSelection(
                  option,
                  setElectriciteTypeInstallation
                )
              }
              isRequired={isElectricite}
            />
          </div>
        </div>
        <div
          className={`MesConsommationsAndEquipementsRG__ElectricityInformationsEnedisOK__doubleDropdownContainer`}
        >
          <div
            className={`MesConsommationsAndEquipementsRG__ElectricityInformationsEnedisOK__dropdownContainer`}
          >
            <p>Puissance souscrite (kVA): </p>
            <div style={{ minWidth: "9rem" }}>
              <Dropdown
                options={
                  mesConsommationsQuestionsData.electricite.suscribedPower
                    .options
                }
                tags={false}
                optionsSelected={electriciteSuscribedPower}
                multipleChoices={false}
                onSelect={(option) => {
                  manageDropdownSingleSelection(
                    option,
                    setElectriciteSuscribedPower
                  );
                }}
                isRequired={false} // Not required as value can be null if user choose "Je ne sais pas"
                maxHeight="auto"
              />
            </div>
          </div>
          <div
            className={`MesConsommationsAndEquipementsRG__ElectricityInformationsEnedisOK__dropdownContainer`}
          >
            <p>Option tarifaire: </p>
            <div style={{ minWidth: "21rem" }}>
              <Dropdown
                options={
                  mesConsommationsQuestionsData.electricite.optionTarifaire
                    .options
                }
                tags={false}
                optionsSelected={
                  electriciteOptionTarifaire
                    ? [
                        {
                          label: electriciteOptionTarifaire?.label!,
                          value: electriciteOptionTarifaire?.value!,
                        },
                      ]
                    : []
                }
                multipleChoices={false}
                onSelect={(option) =>
                  setElectriciteOptionTarifaire({
                    label: option.label!,
                    value: option.value!,
                  })
                }
                isRequired={isElectricite}
              />
            </div>
          </div>
        </div>
        <div
          className={`MesConsommationsAndEquipementsRG__QuestionContainer__Bottom ${
            !consumptionDataOk && "Hidden"
          }`}
        >
          <label className="label" htmlFor="consommationElec">
            Consommation électrique annuelle :{" "}
          </label>
          <div style={{ maxWidth: "102px" }}>
            <TextInput
              inputLableHidden
              type="number"
              inputDescriptionHidden
              iconType="€"
              placeholder="XXXX"
              value={electriciteConsommationEuros ?? undefined}
              onChange={(e) => {
                manageConsommationEuroChange(
                  e.currentTarget.value,
                  priceElectricityPerkWh
                );
              }}
              required={isElectricite}
              iconHidden={false}
            />
          </div>
          <div style={{ maxWidth: "102px" }}>
            <TextInput
              inputLableHidden
              type="number"
              inputDescriptionHidden
              iconType="kWh"
              placeholder="XXXX"
              value={electriciteConsommationKwh ?? undefined}
              onChange={(e) => {
                manageConsommationKwhChange(
                  e.currentTarget.value,
                  priceElectricityPerkWh
                );
              }}
              required={isElectricite}
              iconHidden={false}
            />
          </div>
        </div>
        <div className={`MesConsommationsAndEquipementsRG__conversionBlock`}>
          <div className={`MesConsommationsAndEquipementsRG__line`} />
          <div className={`MesConsommationsAndEquipementsRG__PricePerWattHour`}>
            <p>Soit</p>{" "}
            <TextInput
              inputLableHidden
              type="number"
              inputDescriptionHidden
              iconType="€/kWh"
              placeholder="XXXX"
              value={priceElectricityPerkWh?.toString()}
              onChange={(e) => {
                setPriceElectricityPerkWh(e.currentTarget.valueAsNumber);
                manageConsommationEuroChange(
                  electriciteConsommationEuros ?? "",
                  e.currentTarget.valueAsNumber
                );
              }}
              iconHidden={false}
            />
          </div>
        </div>
      </div>

      <ElectriciteGeneralFormPart
        {...props}
        manageConsommationEuroChange={manageConsommationEuroChange}
        manageConsommationKwhChange={manageConsommationKwhChange}
      />

      <ElectriciteChauffageFormPart {...props} />

      <ElectriciteEcsFormPart {...props} />

      <ElectriciteSynthesisFormPart {...props} />
    </section>
  );
};

const ElectriciteGeneralFormPart = (
  props: IProps & {
    manageConsommationEuroChange: (
      annualConsumptionEuros: string,
      price: number
    ) => void;
    manageConsommationKwhChange: (
      annualConsumptionKwh: string,
      price: number
    ) => void;
  }
) => {
  const {
    isElectricite,
    electriciteTypeInstallation,
    setElectriciteTypeInstallation,
    electriciteSuscribedPower,
    setElectriciteSuscribedPower,
    electriciteOptionTarifaire,
    setElectriciteOptionTarifaire,
    electriciteConsommationEuros,
    electriciteConsommationKwh,
    electricitePrincipauxEquipments,
    setElectricitePrincipauxEquipments,
    setElectriciteAutresConsumptionRate,
    consumptionDataOk,
    setElectricitePiscineConsumptionRate,
    setElectriciteBorneDeRechargeConsumptionRate,
    setElectriciteClimatisationConsumptionRate,
    setPriceElectricityPerkWh,
    priceElectricityPerkWh,
    setElectriciteEclairageConsumptionRate,
    resetChauffageSection,
    resetECSSection,
    manageConsommationEuroChange,
    manageConsommationKwhChange,
  } = props;

  return (
    <>
      <div className="MesConsommationsAndEquipementsRG__Trait" />
      <div
        className={`MesConsommationsAndEquipementsRG__MultiChoiceContainer ${
          consumptionDataOk && "Hidden"
        }`}
      >
        <label className="label" htmlFor="typeInstallation">
          Type d'installation:{" "}
        </label>
        <ChoixMultiple
          options={
            mesConsommationsQuestionsData.electricite.typeDInstallation.options
          }
          value={electriciteTypeInstallation}
          onClick={(option) => {
            option
              ? setElectriciteTypeInstallation([option])
              : setElectriciteTypeInstallation([]);
          }}
          gap={true}
          isRequired={isElectricite}
        />
      </div>

      <div
        className={`MesConsommationsAndEquipementsRG__QuestionContainer ${
          consumptionDataOk && "Hidden"
        }`}
      >
        <label className="label" htmlFor="powerSuscribed">
          Puissance souscrite (kVA) :{" "}
        </label>
        <div className="MesConsommationsAndEquipementsRG__DropdowContainer">
          <Dropdown
            placeholder="Sélectionnez votre puissance"
            options={
              mesConsommationsQuestionsData.electricite.suscribedPower.options
            }
            tags={false}
            optionsSelected={electriciteSuscribedPower}
            multipleChoices={false}
            onSelect={(option) => {
              manageDropdownSingleSelection(
                option,
                setElectriciteSuscribedPower
              );
            }}
            isRequired={false} // Not required as value can be null if user choose "Je ne sais pas"
            maxHeight="auto"
          />
        </div>
      </div>

      <div
        className={`MesConsommationsAndEquipementsRG__UniqueChoiceContainer ${
          consumptionDataOk && "Hidden"
        }`}
      >
        <label className="label" htmlFor="optionTarifaire">
          Option tarifaire :{" "}
        </label>
        <ChoixMultiple
          options={
            mesConsommationsQuestionsData.electricite.optionTarifaire.options
          }
          value={electriciteOptionTarifaire ? [electriciteOptionTarifaire] : []}
          onClick={(option) => {
            option
              ? setElectriciteOptionTarifaire(option)
              : setElectriciteOptionTarifaire(undefined);
          }}
          isRequired={isElectricite}
          gap={false}
        />
      </div>

      <div
        className={`MesConsommationsAndEquipementsRG__QuestionContainer ${
          consumptionDataOk && "Hidden"
        }`}
      >
        <label className="label" htmlFor="consommationElec">
          Consommation électrique annuelle :{" "}
        </label>
        <div style={{ maxWidth: "130px" }}>
          <TextInput
            inputLableHidden
            type="number"
            inputDescriptionHidden
            iconType="euros"
            placeholder="XXXX"
            value={electriciteConsommationEuros ?? undefined}
            onChange={(e) => {
              manageConsommationEuroChange(
                e.currentTarget.value,
                priceElectricityPerkWh
              );
            }}
            required={isElectricite}
            iconHidden={false}
          />
        </div>
        <div style={{ maxWidth: "130px" }}>
          <TextInput
            inputLableHidden
            type="number"
            inputDescriptionHidden
            iconType="kWh"
            placeholder="XXXX"
            value={electriciteConsommationKwh ?? undefined}
            onChange={(e) => {
              manageConsommationKwhChange(
                e.currentTarget.value,
                priceElectricityPerkWh
              );
            }}
            required={isElectricite}
            iconHidden={false}
          />
        </div>
        <div className={`MesConsommationsAndEquipementsRG__conversionBlock`}>
          <div className={`MesConsommationsAndEquipementsRG__line`} />
          <div className={`MesConsommationsAndEquipementsRG__PricePerWattHour`}>
            <p>Soit</p>{" "}
            <TextInput
              inputLableHidden
              type="number"
              inputDescriptionHidden
              iconType="€/kWh"
              placeholder="XXXX"
              value={priceElectricityPerkWh?.toString()}
              onChange={(e) => {
                setPriceElectricityPerkWh(e.currentTarget.valueAsNumber);
                manageConsommationEuroChange(
                  electriciteConsommationEuros ?? "",
                  e.currentTarget.valueAsNumber
                );
              }}
              iconHidden={false}
            />
          </div>
        </div>
      </div>
      <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer__LimitWidth">
        <p
          className={`MesConsommationsAndEquipementsRG__Subtitle ${
            !consumptionDataOk && "Hidden"
          }`}
        >
          Nous avons détecté les équipements électriques principaux suivants.
        </p>
        {!consumptionDataOk ? (
          <label className="label" htmlFor="equipments">
            Principaux équipements :{" "}
          </label>
        ) : (
          <label className="label" htmlFor="equipments">
            Vous pouvez modifier la sélection :{" "}
          </label>
        )}
        <ChoixMultiple
          value={electricitePrincipauxEquipments}
          options={
            mesConsommationsQuestionsData.electricite.principauxEquipements
              .options
          }
          onClick={(e) => {
            const equipmentToUnselected = [
              ...electricitePrincipauxEquipments,
            ].find((option) => option.label === e?.label);

            e &&
              setElectricitePrincipauxEquipments((state) => {
                const newState = [...state];
                const foundIndex = newState.findIndex(
                  (option) => option.label === e.label
                );
                if (foundIndex !== -1) {
                  newState.splice(foundIndex, 1);
                } else {
                  newState.push(e);
                }
                return newState;
              });

            //handle deselect option equipment
            switch (equipmentToUnselected?.label) {
              case "Chauffage":
                resetChauffageSection();
                break;
              case "Eau chaude sanitaire (ECS)":
                resetECSSection();
                break;
              case "Électroménager, éclairage":
                setElectriciteAutresConsumptionRate(null);
                setElectriciteEclairageConsumptionRate(null);
                break;
              case "Climatisation":
                setElectriciteClimatisationConsumptionRate(null);
                break;
              case "Borne de recharge":
                setElectriciteBorneDeRechargeConsumptionRate(null);
                break;
              case "Piscine":
                setElectricitePiscineConsumptionRate(null);
                break;
            }
          }}
          isRequired={isElectricite}
        />
      </div>
    </>
  );
};

const ElectriciteChauffageFormPart = (props: IProps) => {
  const {
    isElectricite = false,
    electricitePrincipauxEquipments,
    electriciteSurfaceChauffee,
    setElectriciteSurfaceChauffee,
    electriciteTemperatureConfort,
    setElectriciteTemperatureConfort,
    electriciteTypeChauffage,
    setElectriciteTypeChauffage,
    electriciteNombreEmetteur,
    setElectriciteNombreEmetteur,
    electriciteTypeEmetteur,
    setElectriciteTypeEmetteur,
    consumptionDataOk,
    setElectricitePacAirEauInstallationYear,
    electricitePacAirEauInstallationYear,
    setElectricitePacAirAirInstallationYear,
    electricitePacAirAirInstallationYear,
  } = props;

  const isChauffageInPrincipauxEquipments =
    electricitePrincipauxEquipments.find((elem) => elem.value === "chauffage");

  if (!isChauffageInPrincipauxEquipments) {
    return null;
  }

  const isTypeEmetteurDisplayed = () => {
    // hide if no type of heating selected
    if (electriciteTypeChauffage.length === 0) return false;

    const isPacAAOrPlancherRayonnantSelected = electriciteTypeChauffage.some(
      (elem) => elem.value === "pacAA" || elem.value === "plancherRayonnant"
    );
    if (isPacAAOrPlancherRayonnantSelected) return false;

    return true;
  };

  const isNombreEmetteurDisplayed = () => {
    // hide if no type of heating selected
    if (electriciteTypeChauffage.length === 0) return false;

    // hide if plancher chauffant selected
    const isPlancherChauffantSelected = electriciteTypeEmetteur.some(
      (elem) => elem.value === "plancherChauffant"
    );
    if (isPlancherChauffantSelected) return false;

    return true;
  };

  const handleTypeChauffageClick = (e: IOption | null) => {
    setElectricitePacAirEauInstallationYear(null);
    setElectricitePacAirAirInstallationYear(null);

    if (!e) {
      return;
    }

    setElectriciteTypeChauffage((state) => {
      const newState = [...state];
      const foundIndex = newState.findIndex(
        (option) => option.label === e.label
      );
      if (foundIndex !== -1) {
        newState.splice(foundIndex, 1);
      } else {
        return [e];
      }
      return newState;
    });

    switch (e.value) {
      case "plancherRayonnant":
        const underfloorHeatingOption =
          mesConsommationsQuestionsData.electricite.typeDEmetteur.options.find(
            (elem) => elem.value === "plancherChauffant"
          );

        if (underfloorHeatingOption) {
          setElectriciteTypeEmetteur([underfloorHeatingOption]);
          setElectriciteNombreEmetteur(1);
        }
        break;
      case "radiateur":
        const convectorOption =
          mesConsommationsQuestionsData.electricite.typeDEmetteur.options.find(
            (elem) => elem.value === "convecteur"
          );

        if (convectorOption) {
          setElectriciteTypeEmetteur([convectorOption]);
          setElectriciteNombreEmetteur(1);
        }
        break;
      default:
        setElectriciteTypeEmetteur([]);
        setElectriciteNombreEmetteur(0);
    }
  };

  return (
    <section>
      <div className="MesConsommationsAndEquipementsRG__Trait" />
      <div className="MesConsommationsAndEquipementsRG__TitleTwo">
        <h3>Chauffage</h3>
      </div>
      <div
        className={`MesConsommationsAndEquipementsRG__QuestionContainer ${
          consumptionDataOk && "Hidden"
        }`}
      >
        <label className="label" htmlFor="surfaceChauffee">
          Surface chauffée :{" "}
        </label>
        <div style={{ maxWidth: "102px" }}>
          <TextInput
            inputLableHidden
            type="number"
            inputDescriptionHidden
            iconType="squaredMeters"
            iconHidden={false}
            placeholder="xxxx"
            value={electriciteSurfaceChauffee ?? undefined}
            onChange={(e) => {
              setElectriciteSurfaceChauffee(e.currentTarget.value);
            }}
            required={
              isElectricite &&
              !!electricitePrincipauxEquipments.find(
                (elem) => elem.value === "chauffage"
              )
            }
          />
        </div>
      </div>
      <div
        className={`MesConsommationsAndEquipementsRG__QuestionContainer  ${
          consumptionDataOk && "Hidden"
        }`}
      >
        <label className="label" htmlFor="temperatureConfort">
          Température de confort (°C):{" "}
        </label>
        <Quantite
          value={electriciteTemperatureConfort ?? 0}
          onPlusMinusClick={(e) => setElectriciteTemperatureConfort(e)}
        />
      </div>
      <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer__LimitWidth">
        <label className="label" htmlFor="typeChauffage">
          Type de chauffage :{" "}
        </label>
        <ChoixMultiple
          value={electriciteTypeChauffage}
          options={
            mesConsommationsQuestionsData.electricite.typeDeChauffage.options
          }
          onClick={handleTypeChauffageClick}
          isRequired={
            isElectricite &&
            !!electricitePrincipauxEquipments.find(
              (elem) => elem.value === "chauffage"
            )
          }
        />
      </div>
      {!!electriciteTypeChauffage.find((elem) => elem.value === "pacAE") ? (
        <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
          <label className="label" htmlFor="anneeDInstallationPacAirEau">
            Année d'installation pompe à chaleur air/eau:{" "}
          </label>
          <TextInput
            inputLableHidden
            type="number"
            inputDescriptionHidden
            iconHidden
            placeholder="AAAA"
            value={
              electricitePacAirEauInstallationYear?.toString() ?? undefined
            }
            onChange={(e) => {
              setElectricitePacAirEauInstallationYear(
                e.currentTarget.valueAsNumber
              );
            }}
            required={
              isElectricite &&
              !!electriciteTypeChauffage.find((elem) => elem.value === "pacAE")
            }
          />
        </div>
      ) : null}
      {!!electriciteTypeChauffage.find((elem) => elem.value === "pacAA") ? (
        <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
          <label className="label" htmlFor="anneeDInstallationPacAirAri">
            Année d'installation pompe à chaleur air/air:{" "}
          </label>
          <TextInput
            inputLableHidden
            type="number"
            inputDescriptionHidden
            iconHidden
            placeholder="AAAA"
            value={
              electricitePacAirAirInstallationYear?.toString() ?? undefined
            }
            onChange={(e) => {
              setElectricitePacAirAirInstallationYear(
                e.currentTarget.valueAsNumber
              );
            }}
            required={
              isElectricite &&
              !!electriciteTypeChauffage.find((elem) => elem.value === "pacAA")
            }
          />
        </div>
      ) : null}

      {isTypeEmetteurDisplayed() ? (
        <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
          <label className="label" htmlFor="typeEmetteur">
            Type d’émetteur :{" "}
          </label>
          <ChoixMultiple
            options={
              mesConsommationsQuestionsData.electricite.typeDEmetteur.options
            }
            value={electriciteTypeEmetteur}
            onClick={(e) => {
              e &&
                setElectriciteTypeEmetteur((state) => {
                  const newState = [...state];
                  const foundIndex = newState.findIndex(
                    (option) => option.label === e.label
                  );
                  if (foundIndex !== -1) {
                    newState.splice(foundIndex, 1);
                  } else {
                    return [e];
                  }
                  return newState;
                });
            }}
            isRequired={
              isElectricite &&
              !!electricitePrincipauxEquipments.find(
                (elem) => elem.value === "chauffage"
              ) &&
              !electriciteTypeChauffage.some((elem) => elem.value === "pacAA")
            }
          />
        </div>
      ) : null}

      {isNombreEmetteurDisplayed() ? (
        <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
          <label className="label" htmlFor="nombreEmetteurs">
            Nombre d’émetteur(s) :{" "}
          </label>
          <Quantite
            value={electriciteNombreEmetteur ?? 0}
            onPlusMinusClick={(e) => setElectriciteNombreEmetteur(e)}
          />
        </div>
      ) : null}
    </section>
  );
};

const ElectriciteEcsFormPart = (props: IProps) => {
  const {
    isElectricite = false,
    electricitePrincipauxEquipments,
    electriciteStorageCapacity,
    setElectriciteStorageCapacity,
    setElectriciteTypeBallonEauChaude,
    electriciteInstallationYear,
    setElectriciteInstallationYear,
    electriciteTypeBallonEauChaude,
  } = props;

  const isEcsInPrincipauxEquipments = electricitePrincipauxEquipments.find(
    (elem) => elem.value === "eauChaudeSanitaire"
  );

  if (!isEcsInPrincipauxEquipments) {
    return null;
  }

  return (
    <>
      <section>
        <div className="MesConsommationsAndEquipementsRG__Trait" />
        <div className="MesConsommationsAndEquipementsRG__TitleTwo">
          <h3>Eau chaude sanitaire (ECS)</h3>
        </div>
        <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
          <label className="label" htmlFor="typeBallonEauChaude">
            Type de ballon d’eau chaude :{" "}
          </label>
          <ChoixMultiple
            options={
              mesConsommationsQuestionsData.electricite.typeDeBallon.options
            }
            value={electriciteTypeBallonEauChaude}
            onClick={(e) => {
              e &&
                setElectriciteTypeBallonEauChaude((state) => {
                  const newState = [...state];
                  const foundIndex = newState.findIndex(
                    (option) => option.label === e.label
                  );
                  if (foundIndex !== -1) {
                    newState.splice(foundIndex, 1);
                  } else {
                    return [e];
                  }
                  return newState;
                });
            }}
            isRequired={
              isElectricite &&
              !!electricitePrincipauxEquipments.find(
                (elem) => elem.value === "eauChaudeSanitaire"
              )
            }
          />
        </div>
        <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
          <label className="label" htmlFor="storageCapacity">
            Capacité de stockage :{" "}
          </label>
          <div className="MesConsommationsAndEquipementsRG__DropdowContainer">
            <Dropdown
              placeholder="Sélectionnez votre volume"
              options={
                mesConsommationsQuestionsData.electricite.capaciteDeStockage
                  .options
              }
              tags={false}
              optionsSelected={
                electriciteStorageCapacity.length > 0
                  ? electriciteStorageCapacity
                  : []
              }
              multipleChoices={false}
              onSelect={(option) => {
                manageDropdownSingleSelection(
                  option,
                  setElectriciteStorageCapacity
                );
              }}
              isRequired={
                isElectricite &&
                !!electricitePrincipauxEquipments.find(
                  (elem) => elem.value === "eauChaudeSanitaire"
                )
              }
            />
          </div>
        </div>
        <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
          <label className="label" htmlFor="installationYear">
            Année d’installation :{" "}
          </label>
          <TextInput
            inputLableHidden
            type="number"
            inputDescriptionHidden
            iconHidden={true}
            placeholder="xxxx"
            value={electriciteInstallationYear ?? undefined}
            onChange={(e) => {
              setElectriciteInstallationYear(e.currentTarget.value);
            }}
            required={
              isElectricite &&
              !!electricitePrincipauxEquipments.find(
                (elem) => elem.value === "eauChaudeSanitaire"
              )
            }
          />
        </div>
      </section>
    </>
  );
};

const ElectriciteSynthesisFormPart = (props: IProps) => {
  const {
    isElectricite = false,
    electriciteConsommationEuros,
    setElectriciteConsommationEuros,
    electricitePrincipauxEquipments,
    electriciteChauffageConsumptionRate,
    setElectriciteChauffageConsumptionRate,
    electriciteECSConsumptionRate,
    setElectriciteECSConsumptionRate,
    electriciteAutresConsumptionRate,
    setElectriciteAutresConsumptionRate,
    electricitePiscineConsumptionRate,
    setElectricitePiscineConsumptionRate,
    electriciteBorneDeRechargeConsumptionRate,
    setElectriciteBorneDeRechargeConsumptionRate,
    electriciteClimatisationConsumptionRate,
    setElectriciteClimatisationConsumptionRate,
    electriciteEclairageConsumptionRate,
    setElectriciteEclairageConsumptionRate,
    checkElecEquipmentsDistribution,
  } = props;

  if (electricitePrincipauxEquipments.length < 2) {
    return null;
  }

  const allEquipmentsConsumption = [
    Number(electriciteChauffageConsumptionRate),
    Number(electriciteECSConsumptionRate),
    Number(electriciteAutresConsumptionRate),
    Number(electriciteEclairageConsumptionRate),
    Number(electricitePiscineConsumptionRate),
    Number(electriciteBorneDeRechargeConsumptionRate),
    Number(electriciteClimatisationConsumptionRate),
  ];

  const inputErrorMessage = () => {
    const totalEquipmentsConsumptionPercent = allEquipmentsConsumption.reduce(
      (acc, curr) => acc + curr,
      0
    );

    return `Distribué : ${totalEquipmentsConsumptionPercent}% | Restant :
      ${100 - totalEquipmentsConsumptionPercent}%`;
  };

  return (
    <>
      {electricitePrincipauxEquipments.length > 1 ? (
        <section>
          <div className={`MesConsommationsAndEquipementsRG__Trait`} />
          <div className={`MesConsommationsAndEquipementsRG__Synthese`}>
            <div className="MesConsommationsAndEquipementsRG__TitleSynthese">
              <h3>Synthèse</h3>
            </div>
            <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
              <label className="label" htmlFor="consommationElec">
                Consommation électrique annuelle :{" "}
              </label>
              <div style={{ maxWidth: "102px" }}>
                <TextInput
                  inputLableHidden
                  type="number"
                  inputDescriptionHidden
                  iconType="euros"
                  iconHidden={false}
                  placeholder="XXXX"
                  value={electriciteConsommationEuros ?? undefined}
                  onChange={(e) => {
                    setElectriciteConsommationEuros(e.currentTarget.value);
                  }}
                  required={
                    isElectricite && electricitePrincipauxEquipments.length > 1
                  }
                />
              </div>
            </div>
            <div className="MesConsommationsAndEquipementsRG__SyntheseRow">
              {electricitePrincipauxEquipments.find(
                (elem) => elem.value === "chauffage"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={Chauffage} alt="Chauffage" />
                    <label className="label" htmlFor="chauffageEuros">
                      Chauffage{" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkElecEquipmentsDistribution()}
                      inputLableHidden
                      type="number"
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={electriciteChauffageConsumptionRate ?? undefined}
                      onChange={(e) => {
                        setElectriciteChauffageConsumptionRate(
                          e.currentTarget.value
                        );
                      }}
                      required={
                        isElectricite &&
                        electricitePrincipauxEquipments.length > 1 &&
                        !!electricitePrincipauxEquipments.find(
                          (elem) => elem.value === "chauffage"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(electriciteConsommationEuros) *
                        Number(electriciteChauffageConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
              {electricitePrincipauxEquipments.find(
                (elem) => elem.value === "eauChaudeSanitaire"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={ECS} alt="ecs" />
                    <label className="label" htmlFor="ECSEuros">
                      Eau chaude sanitaire :{" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkElecEquipmentsDistribution()}
                      type="number"
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={electriciteECSConsumptionRate ?? undefined}
                      onChange={(e) => {
                        setElectriciteECSConsumptionRate(e.currentTarget.value);
                      }}
                      required={
                        isElectricite &&
                        electricitePrincipauxEquipments.length > 1 &&
                        !!electricitePrincipauxEquipments.find(
                          (elem) => elem.value === "eauChaudeSanitaire"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(electriciteConsommationEuros) *
                        Number(electriciteECSConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
              {electricitePrincipauxEquipments.find(
                (elem) => elem.value === "autres"
              ) ? (
                <>
                  <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                    <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                      <img src={electricite} alt="electricite" />
                      <label className="label" htmlFor="autresEuros">
                        Autres (électroménager...){" "}
                      </label>
                    </div>
                    <div
                      style={{ maxWidth: "102px" }}
                      className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                    >
                      <TextInput
                        error={checkElecEquipmentsDistribution()}
                        inputLableHidden
                        type="number"
                        iconType="%"
                        iconHidden={false}
                        placeholder="XX"
                        value={electriciteAutresConsumptionRate ?? undefined}
                        onChange={(e) => {
                          setElectriciteAutresConsumptionRate(
                            e.currentTarget.value
                          );
                        }}
                        required={
                          isElectricite &&
                          electricitePrincipauxEquipments.length > 1 &&
                          !!electricitePrincipauxEquipments.find(
                            (elem) => elem.value === "autres"
                          )
                        }
                      />
                      <p>
                        Soit{" "}
                        {(Number(electriciteConsommationEuros) *
                          Number(electriciteAutresConsumptionRate)) /
                          100}{" "}
                        €
                      </p>
                    </div>
                  </div>
                  <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                    <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                      <img src={electricite} alt="electricite" />
                      <label className="label" htmlFor="eclairageEuros">
                        Eclairage{" "}
                      </label>
                    </div>
                    <div
                      style={{ maxWidth: "102px" }}
                      className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                    >
                      <TextInput
                        error={checkElecEquipmentsDistribution()}
                        inputLableHidden
                        type="number"
                        iconType="%"
                        iconHidden={false}
                        placeholder="XX"
                        value={electriciteEclairageConsumptionRate ?? undefined}
                        onChange={(e) => {
                          setElectriciteEclairageConsumptionRate(
                            e.currentTarget.value
                          );
                        }}
                        required={
                          isElectricite &&
                          electricitePrincipauxEquipments.length > 1 &&
                          !!electricitePrincipauxEquipments.find(
                            (elem) => elem.value === "autres"
                          )
                        }
                      />
                      <p>
                        Soit{" "}
                        {(Number(electriciteConsommationEuros) *
                          Number(electriciteEclairageConsumptionRate)) /
                          100}{" "}
                        €
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
              {electricitePrincipauxEquipments.find(
                (elem) => elem.value === "piscine"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={piscine} alt="piscine" />
                    <label className="label" htmlFor="piscineEuros">
                      Piscine{" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkElecEquipmentsDistribution()}
                      inputLableHidden
                      type="number"
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={electricitePiscineConsumptionRate ?? undefined}
                      onChange={(e) => {
                        setElectricitePiscineConsumptionRate(
                          e.currentTarget.value
                        );
                      }}
                      required={
                        isElectricite &&
                        electricitePrincipauxEquipments.length > 1 &&
                        !!electricitePrincipauxEquipments.find(
                          (elem) => elem.value === "piscine"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(electriciteConsommationEuros) *
                        Number(electricitePiscineConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
              {electricitePrincipauxEquipments.find(
                (elem) => elem.value === "borneDeRecharge"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={voitureElec} alt="voiture-electric" />
                    <label className="label" htmlFor="borneDeRechargeEuros">
                      Borne de recharge{" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkElecEquipmentsDistribution()}
                      inputLableHidden
                      type="number"
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={
                        electriciteBorneDeRechargeConsumptionRate ?? undefined
                      }
                      onChange={(e) => {
                        setElectriciteBorneDeRechargeConsumptionRate(
                          e.currentTarget.value
                        );
                      }}
                      required={
                        isElectricite &&
                        electricitePrincipauxEquipments.length > 1 &&
                        !!electricitePrincipauxEquipments.find(
                          (elem) => elem.value === "borneDeRecharge"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(electriciteConsommationEuros) *
                        Number(electriciteBorneDeRechargeConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
              {electricitePrincipauxEquipments.find(
                (elem) => elem.value === "climatisation"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={clim} alt="clim" />
                    <label className="label" htmlFor="climatisationEuros">
                      Climatisation{" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkElecEquipmentsDistribution()}
                      inputLableHidden
                      type="number"
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={
                        electriciteClimatisationConsumptionRate ?? undefined
                      }
                      onChange={(e) => {
                        setElectriciteClimatisationConsumptionRate(
                          e.currentTarget.value
                        );
                      }}
                      required={
                        isElectricite &&
                        electricitePrincipauxEquipments.length > 1 &&
                        !!electricitePrincipauxEquipments.find(
                          (elem) => elem.value === "climatisation"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(electriciteConsommationEuros) *
                        Number(electriciteClimatisationConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionMessageContainer">
              <p>{inputErrorMessage()}</p>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};
