import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

//Interface import
import { IPerformance } from "../../interfaces/generalInterfaces";

//Service import
import { setPerformanceAction } from "../../redux/appActions";
import { useAppDispatch } from "../../redux/store/hook";
import { TExtraFee } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IGetPerformanceProps {
  userToken: string | null;
  number_of_panels: number | null;
  number_of_batteries: number | null;
  building_id?: string | null;
  household_id: string | null | undefined;
  kit_home_management: boolean | null;
  indexScenarioChosen: number | null;
  study_id: string;
  inverter_brand: string | null;
  single_battery_capacity: number | null;
  panel_indices?: number[] | null;
  extra_fees?: TExtraFee[] | null;
}

export const useGetPerformance = () => {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const getPerformance = async ({
    userToken,
    number_of_panels,
    number_of_batteries,
    building_id,
    household_id,
    kit_home_management,
    indexScenarioChosen,
    study_id,
    inverter_brand,
    single_battery_capacity,
    panel_indices,
    extra_fees,
  }: IGetPerformanceProps) => {
    const queryParams = new URLSearchParams();
    queryParams.append("building_id", String(building_id));
    queryParams.append("household_id", String(household_id));
    queryParams.append("number_of_panels", String(number_of_panels));
    queryParams.append("kit_home_management", String(kit_home_management));
    queryParams.append("number_of_batteries", String(number_of_batteries));
    queryParams.append("inverter_brand", String(inverter_brand));
    queryParams.append(
      "single_battery_capacity",
      String(single_battery_capacity)
    );
    queryParams.append(
      "panel_indices",
      (panel_indices?.length || 0) > 0 ? String(panel_indices) : ""
    );

    const total_extra_fees_with_tax =
      extra_fees?.reduce((acc, fee) => acc + fee.price_with_tax, 0) || 0;
    queryParams.append(
      "total_extra_fees_with_tax",
      JSON.stringify(total_extra_fees_with_tax)
    );

    const response = await axiosGetRequest<IPerformance>(
      `${BACKEND_URL}/oree2/pv/getPerformance/${study_id}?${queryParams}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    if (response) {
      dispatch(
        setPerformanceAction({
          performance: { ...response, indexScenarioChosen },
        })
      );
    } else {
      setErrorMessage("Erreur lors de la récupération de la performance");
    }
  };
  return {
    getPerformance,
    isLoading,
    errorMessage: errorMessage || axiosErrorMessage,
  };
};
