import React from "react";
import "./productsList.scss";
import dottedArrow from "../../../assets/images/picto-dotted-arrow.svg";

interface IProps {
  products: {
    installed_power: number | null;
    number_of_panels: number | null;
    kit_home_management: boolean | null;
    number_of_batteries: number | null;
    inverter_brand: string | null;
  };
  formuleName?: string;
}

export const ProductsList = ({ products, formuleName }: IProps) => {
  return (
    <div className="ProductsList">
      {products.number_of_panels && (
        <div className="ProductsList__SingleProduct">
          <img src={dottedArrow} alt="dotted arrow" width={13} height={8} />
          <p>
            <strong>{products.number_of_panels}</strong> panneaux
          </p>
        </div>
      )}
      {products.number_of_batteries !== null &&
        products.number_of_batteries > 0 && (
          <div className="ProductsList__SingleProduct">
            <img src={dottedArrow} alt="dotted arrow" width={13} height={8} />
            <p>
              <strong>{products.number_of_batteries}</strong>
              &nbsp;batterie
              {products.number_of_batteries > 1 && "s"}
              &nbsp;de&nbsp;stockage
            </p>
          </div>
        )}

      {/* We don't want to display whether if Kit home management is included in the Essential formula in order to differentiate it from the Sérénité formula */}
      {products.kit_home_management && formuleName !== "Essentielle" && (
        <div className="ProductsList__SingleProduct">
          <img src={dottedArrow} alt="dotted arrow" width={13} height={8} />
          <p>Home&nbsp;Management</p>
        </div>
      )}
    </div>
  );
};
