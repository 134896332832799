//Library import
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

//Style import
import "./independenceLevelComponent.scss";

//Picto import
import picto_independance from "../../../assets/images/picto_independance.svg";

//Component import
import { ModalityPopUp } from "@web/shared/dist/containers/ModalityPopUp/ModalityPopUp";
import { useEffect, useState } from "react";

//Local interface
interface IProps {
  selfproductionRate: number | null;
  solarProductionConsumed?: number | null;
  solarProductionStored?: number | null;
  title: string | null;
  description: JSX.Element;
  color?: string | null;
  legende?: boolean;
  autoprod?: boolean;
  isForPdf?: boolean;
}

interface ProgressProviderProps {
  valueStart: number;
  valueEnd: number;
  children: any;
}

//Compoenent to add animation to progressBar
const ProgressProvider = ({
  valueStart,
  valueEnd,
  children,
}: ProgressProviderProps) => {
  const [value, setValue] = useState(valueStart);
  useEffect(() => {
    setValue(valueEnd);
  }, [valueEnd]);

  return children(value);
};

export const IndependenceLevelComponent = ({
  selfproductionRate,
  solarProductionConsumed,
  solarProductionStored,
  title,
  description,
  color,
  legende,
  autoprod,
  isForPdf = false,
}: IProps) => {
  selfproductionRate =
    selfproductionRate && selfproductionRate > 1 ? 1 : selfproductionRate;

  return (
    <div
      className={
        title
          ? "IndependenceLevelComponent"
          : "IndependenceLevelComponent__Hide"
      }
    >
      {title && (
        <>
          <div className="IndependenceLevelComponent__chart">
            {solarProductionStored && solarProductionConsumed ? (
              <ProgressProvider
                valueStart={0}
                valueEnd={
                  (solarProductionStored + solarProductionConsumed) * 100
                }
              >
                {(value: number) => (
                  <CircularProgressbarWithChildren
                    value={value}
                    circleRatio={0.6}
                    strokeWidth={5}
                    styles={buildStyles({
                      rotation: 0.7,
                      pathColor: "#BA95D7",
                      trailColor: "#D3D4D7",
                      pathTransitionDuration: 0.5,
                    })}
                  >
                    <ProgressProvider
                      valueStart={0}
                      valueEnd={solarProductionConsumed * 100}
                    >
                      {(value: number) => (
                        <CircularProgressbarWithChildren
                          value={value}
                          circleRatio={0.6}
                          strokeWidth={5}
                          styles={buildStyles({
                            rotation: 0.7,
                            trailColor: "transparent",
                            pathColor: "#752BAF",
                            pathTransitionDuration: 0.5,
                          })}
                        >
                          <div className="IndependenceLevelComponent__CircularProgressBarContent">
                            <img src={picto_independance} alt="picto" />
                            <h3>{title}</h3>
                            <div className="IndependenceRate">
                              {selfproductionRate ? (
                                <p>
                                  {Math.round(selfproductionRate * 100)}
                                  <span>%</span>
                                </p>
                              ) : (
                                "information indisponible"
                              )}
                            </div>
                          </div>
                        </CircularProgressbarWithChildren>
                      )}
                    </ProgressProvider>
                  </CircularProgressbarWithChildren>
                )}
              </ProgressProvider>
            ) : (
              <ProgressProvider
                valueStart={0}
                valueEnd={selfproductionRate ? selfproductionRate * 100 : 0}
              >
                {(value: number) => (
                  <CircularProgressbarWithChildren
                    value={value}
                    circleRatio={0.6}
                    strokeWidth={5}
                    styles={buildStyles({
                      rotation: 0.7,
                      trailColor: "#D3D4D7",
                      pathColor: color ? color : "#752BAF",
                      pathTransitionDuration: 0.5,
                    })}
                  >
                    <div className="IndependenceLevelComponent__CircularProgressBarContent">
                      <img src={picto_independance} alt="picto indépendance" />
                      <p>{title}</p>
                      <div className="IndependenceLevelComponent__CircularProgressBarContent__independenceRate">
                        {selfproductionRate ? (
                          <p>
                            {(selfproductionRate * 100).toFixed()}
                            <span>%</span>
                          </p>
                        ) : (
                          "information indisponible"
                        )}
                      </div>
                    </div>
                  </CircularProgressbarWithChildren>
                )}
              </ProgressProvider>
            )}
            <div className="IndependenceLevelComponent__LegendeSection">
              <div className="PourcentSection">
                <p>0%</p>
                <p>100%</p>
              </div>
              {solarProductionStored && solarProductionConsumed && legende ? (
                <div className="IndependenceLevelComponent__LegendeContainer">
                  <div className="Legende">
                    <div
                      className="Bullet"
                      style={{ backgroundColor: "#752BAF" }}
                    ></div>
                    <p>Production solaire consommée directement</p>
                  </div>
                  <div className="Legende">
                    <div
                      className="Bullet"
                      style={{ backgroundColor: "#BA95D7" }}
                    ></div>
                    <p>Production solaire stockée</p>
                  </div>
                </div>
              ) : legende ? (
                <div className="IndependenceLevelComponent__LegendeContainer">
                  <div className="Legende">
                    <div
                      className="Bullet"
                      style={{ backgroundColor: "#752BAF" }}
                    ></div>
                    <p>Production solaire consommée directement</p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {!isForPdf ? (
            <div className="IndependenceLevelComponent__TextSection">
              <div className="IndependenceLevelComponent__Title">
                <h4>{title}</h4>
                <ModalityPopUp
                  backgroundColor="black"
                  color="withe"
                  positionRight={false}
                  content={
                    autoprod ? (
                      <>
                        {" "}
                        <div className="ModalitéPopUPText__FirstDivision">
                          <p
                            style={{
                              padding: "3px 0",
                              borderBottom: "1px solid white",
                            }}
                          >
                            Production solaire utilisée
                          </p>
                          <p
                            style={{
                              padding: "3px 0",
                            }}
                          >
                            Consommation électrique totale
                          </p>
                        </div>
                        <div style={{ padding: "0 5px" }}>
                          <p>=</p>
                        </div>
                        <div className="ModalitéPopUPText__secondDivision">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: " 3px 0",
                            }}
                          >
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#74ddd7",
                                borderRadius: " 8px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              padding: "3px 0",
                              borderTop: "1px solid white",
                            }}
                          >
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#a8aaaf",
                                borderRadius: " 8px",
                              }}
                            />
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#74ddd7",
                                borderRadius: " 8px",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="ModalitéPopUPText__FirstDivision">
                          <p
                            style={{
                              padding: "3px 0",
                              borderBottom: "1px solid white",
                            }}
                          >
                            Production solaire utilisée
                          </p>
                          <p
                            style={{
                              padding: "3px 0",
                            }}
                          >
                            Production solaire totale
                          </p>
                        </div>
                        <div style={{ padding: "0 5px" }}>
                          <p>=</p>
                        </div>
                        <div className="ModalitéPopUPText__secondDivision">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: " 3px 0",
                            }}
                          >
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#74ddd7",
                                borderRadius: " 8px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              padding: "3px 0",
                              borderTop: "1px solid white",
                            }}
                          >
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#F0A14E",
                                borderRadius: " 8px",
                              }}
                            />
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#74ddd7",
                                borderRadius: " 8px",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )
                  }
                />
              </div>
              <div className="IndependenceLevelComponent__Text">
                {selfproductionRate ? (
                  <p>{description}</p>
                ) : (
                  <p>
                    Votre consommation est couverte par votre production
                    solaire. Le reste de votre consommation utilise
                    l'électricité du réseau électrique.
                  </p>
                )}
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
