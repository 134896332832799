import mursPierre from "@web/shared/dist/assets/images/DesignSystemIllustrations/murs-pierre.svg";
import mursBeton from "@web/shared/dist/assets/images/DesignSystemIllustrations/murs-beton.svg";
import mursBrique from "@web/shared/dist/assets/images/DesignSystemIllustrations/murs-brique.svg";
import mursBois from "@web/shared/dist/assets/images/DesignSystemIllustrations/murs-bois.svg";
import simpleVitrage from "@web/shared/dist/assets/images/DesignSystemIllustrations/simple-vitrage.svg";
import doubleVitrage from "@web/shared/dist/assets/images/DesignSystemIllustrations/double-vitrage.svg";
import tripleVitrage from "@web/shared/dist/assets/images/DesignSystemIllustrations/triple-vitrage.svg";
import comblesPerdus from "@web/shared/dist/assets/images/DesignSystemIllustrations/combles-perdus.svg";
import comblesAmenages from "@web/shared/dist/assets/images/DesignSystemIllustrations/combles-amenages.svg";
import pasDeCombles from "@web/shared/dist/assets/images/DesignSystemIllustrations/pas-comble.svg";
import tuileCanal from "@web/shared/dist/assets/images/DesignSystemIllustrations/tuiles-canal.svg";
import ardoise from "@web/shared/dist/assets/images/DesignSystemIllustrations/ardoise.svg";
import tole from "@web/shared/dist/assets/images/DesignSystemIllustrations/tole-trapezoidale.svg";
import fibrociment from "@web/shared/dist/assets/images/DesignSystemIllustrations/tole-ondulee.svg";
import tuileMecanique from "@web/shared/dist/assets/images/DesignSystemIllustrations/tuiles-mecaniques.svg";
import tuilePlate from "@web/shared/dist/assets/images/DesignSystemIllustrations/tuiles-plates.svg";
import naturelle from "@web/shared/dist/assets/images/DesignSystemIllustrations/ventilation naturelle.svg";
import simpleFlux from "@web/shared/dist/assets/images/DesignSystemIllustrations/ventilation simple flux.svg";
import doubleFlux from "@web/shared/dist/assets/images/DesignSystemIllustrations/ventilation double flux.svg";
import caveOuSousSol from "@web/shared/dist/assets/images/DesignSystemIllustrations/cave.svg";
import videSanitaire from "@web/shared/dist/assets/images/DesignSystemIllustrations/vide sanitaire.svg";
import garage from "@web/shared/dist/assets/images/DesignSystemIllustrations/garage.svg";
import terrePlein from "@web/shared/dist/assets/images/DesignSystemIllustrations/terre plein.svg";
import garageInterieur from "@web/shared/dist/assets/images/DesignSystemIllustrations/garage intérieur.svg";
import garageAccole from "@web/shared/dist/assets/images/DesignSystemIllustrations/garage accolé.svg";
import garageIndependant from "@web/shared/dist/assets/images/DesignSystemIllustrations/garage indépendant.svg";
import pasDeGarage from "@web/shared/dist/assets/images/DesignSystemIllustrations/pas de garage.svg";

export const monLogementRGMultipleChoicesData = {
  anneeIsolation: {
    options: [
      { label: "Pas d'isolation", value: "None" },
      { label: "Avant 1975", value: "0-1975" },
      { label: "Entre 1975 et 1985", value: "1975-1985" },
      { label: "Entre 1985 et 2005", value: "1985-2005" },
      { label: "Entre 2005 et 2012", value: "2005-2012" },
      { label: "Entre 2012 et 2020", value: "2012-2020" },
      { label: "Après 2020", value: "2020-3000" },
    ],
  },
  hauteurSousPlafond: {
    options: [
      {
        label: "2 m",
        value: "200",
      },
      {
        label: "2,25 m",
        value: "225",
      },
      {
        label: "2,50 m",
        value: "250",
      },
      {
        label: "2,75 m",
        value: "275",
      },
      {
        label: "3 m",
        value: "300",
      },
    ],
  },
  typeDeMurs: {
    options: [
      {
        label: "Pierre",
        value: "pierre",
        imgSrc: mursPierre,
      },
      {
        label: "Béton",
        value: "beton",
        imgSrc: mursBeton,
      },
      {
        label: "Brique / Parpaing",
        value: "brique_parpaing",
        imgSrc: mursBrique,
      },
      {
        label: "Bois",
        value: "bois",
        imgSrc: mursBois,
      },
    ],
  },
  typeDeVitrages: {
    options: [
      {
        label: "Simple",
        value: "simple",
        imgSrc: simpleVitrage,
      },
      {
        label: "Double",
        value: "double",
        imgSrc: doubleVitrage,
      },
      {
        label: "Triple",
        value: "triple",
        imgSrc: tripleVitrage,
      },
    ],
  },
  typeDeCombles: {
    options: [
      {
        label: "Perdus",
        value: "perdus",
        imgSrc: comblesPerdus,
      },
      {
        label: "Aménagés",
        value: "amenages",
        imgSrc: comblesAmenages,
      },
      {
        label: "Pas de combles",
        value: "pasDeCombles",
        imgSrc: pasDeCombles,
      },
    ],
  },
  typeDeCouvertureDuToit: {
    options: [
      {
        label: "Tuile",
        value: "tuile",
        imgSrc: tuileCanal,
      },
      {
        label: "Ardoise",
        value: "ardoise",
        imgSrc: ardoise,
      },
      {
        label: "Tôle",
        value: "tole",
        imgSrc: tole,
      },
      {
        label: "Fibrociment",
        value: "fibrociment",
        imgSrc: fibrociment,
      },
    ],
  },
  typeDeTuile: {
    options: [
      {
        label: "Tuile canal",
        value: "tuileCanal",
        imgSrc: tuileCanal,
      },
      {
        label: "Tuile mécanique",
        value: "tuileMecanique",
        imgSrc: tuileMecanique,
      },
      {
        label: "tuilePlate",
        value: "tuilePlate",
        imgSrc: tuilePlate,
      },
    ],
  },
  typeDeVentilation: {
    options: [
      {
        label: "Naturelle",
        value: "naturelle",
        imgSrc: naturelle,
      },
      {
        label: "Simple flux",
        value: "simpleFlux",
        imgSrc: simpleFlux,
      },
      {
        label: "doubleFlux",
        value: "doubleFlux",
        imgSrc: doubleFlux,
      },
    ],
  },
  typeDeSousSol: {
    options: [
      {
        label: "Cave ou sous-sol",
        value: "caveOuSousSol",
        imgSrc: caveOuSousSol,
      },
      {
        label: "Vide sanitaire",
        value: "videSanitaire",
        imgSrc: videSanitaire,
      },
      {
        label: "Garage",
        value: "garage",
        imgSrc: garage,
      },
      {
        label: "Terre plein",
        value: "terrePlein",
        imgSrc: terrePlein,
      },
    ],
  },
  typeDeGarage: {
    options: [
      {
        label: "Intérieur",
        value: "interieur",
        imgSrc: garageInterieur,
      },
      {
        label: "Accolé",
        value: "accole",
        imgSrc: garageAccole,
      },
      {
        label: "indépendant",
        value: "independant",
        imgSrc: garageIndependant,
      },
      {
        label: "Pas de garage",
        value: "pasDeGarage",
        imgSrc: pasDeGarage,
      },
    ],
  },
};
