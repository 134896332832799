import React from "react";
import { ExtraElectricitySalesGraph } from "../ExtraElectricitySalesGraph/ExtraElectricitySalesGraph";
import { ProfitabilityTabBlock } from "../ProfitabilityTabBlock/ProfitabilityTabBlock";
import { SavingsGraph } from "../SavingsGraph/SavingsGraph";
import "./TotalGainGraphs.scss";

interface IProps {
  areTabsOpen: boolean[];
  onTabClick: (index: number) => void;
  savingsGraphData: {
    annee: string;
    economies: number;
    prix_kwh_reseau: number;
    prix_kwh_solaire: number;
  }[];
  extraElectricityGraphData: {
    annee: string;
    reventeSurplus: number;
    reventeEnergy: number;
    prixReventeEDF: number;
  }[];
}

export const TotalGainGraphs = ({
  areTabsOpen,
  onTabClick,
  savingsGraphData,
  extraElectricityGraphData,
}: IProps) => {
  return (
    <div className="TotalGainGraphs">
      <section className="TotalGainGraphs__Tabs">
        <ProfitabilityTabBlock
          onClick={() => onTabClick(0)}
          title={"Économies sur la facture"}
          isOpen={areTabsOpen[0]}
          color="oceanTurquoise"
        />
        <ProfitabilityTabBlock
          onClick={() => onTabClick(1)}
          title={"Revente du surplus"}
          isOpen={areTabsOpen[1]}
          color="zealOrange"
        />
      </section>
      {areTabsOpen[0] && (
        <section className="TotalGainGraphs__SavingGraph">
          <SavingsGraph savingsGraphData={savingsGraphData} />
        </section>
      )}
      {areTabsOpen[1] && (
        <section className="TotalGainGraphs__SavingGraph">
          <ExtraElectricitySalesGraph
            extraElectricityGraphData={extraElectricityGraphData}
          />
        </section>
      )}
    </div>
  );
};
