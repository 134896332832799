// Libraries import
// import React from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

// Styles import
// import { stylesVariables } from "../../../styles/variables";

export interface BoutonPictoProps {
  text: string;
  color: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}
export interface BoutonPictoProps {
  color: string;
}

// Image import
import plusCircle from "../../../../assets/images/DesignSystemIcons/plusCircle.svg";

const WrapperBoutonPicto = styled.div<{
  color: string;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    font-family: "DM Sans";
    font-weight: 700;
    color: ${(props) => `${props.color}`};
    font-size: 0.875rem;
  }
`;

const Image = styled(SVG)<{ color: string }>`
  width: 17px;
  height: 17px;
  margin-right: 5px;
  path {
    fill: ${(props) => `${props.color}`};
  }
`;

export const BoutonPicto: React.FC<BoutonPictoProps> = ({
  text,
  onClick,
  color,
}) => {
  return (
    <WrapperBoutonPicto onClick={onClick} color={color}>
      <Image color={color} src={plusCircle} />
      <p>{text}</p>
    </WrapperBoutonPicto>
  );
};
