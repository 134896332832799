//Style import
import "./potentialSolarLoadingComponent.scss";

//Libraries import
import { useEffect, useRef, useState } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";

//Redux import
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { setSolarPotentialStepsStatusAction } from "../../../redux/appActions";

// Component import
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { ValidationCheck } from "../../../components/Animations/ValidationCheck/ValidationCheck";
import { useGetMonPotentielSolaire } from "../../../services/PotentielSolaire/useGetMonPotentielSolaire";

//Local interface
interface IStepStatus {
  step:
    | "waiting"
    | "preprocessing"
    | "umep"
    | "roof_section_processing"
    | "calepinage_computation";
  isRunning: boolean;
  isSuccess: boolean | undefined;
}

interface IEvent {
  data:
    | "waiting"
    | "preprocessing"
    | "umep"
    | "roof_section_processing"
    | "calepinage_computation"
    | "failed"
    | "succeed"
    | "connectionFailed";
  lastEventId: string;
}

export const PotentialSolarLoadingComponent = () => {
  const { userAuthInfo, studyInformation, solarPotentialStepsStatus } =
    useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const serverEventRef = useRef<EventSourcePolyfill>();

  const [checkSolarPotentialStepsStatus, setCheckSolarPotentialStepsStatus] =
    useState<IStepStatus[]>([
      { step: "waiting", isRunning: false, isSuccess: undefined },
      { step: "preprocessing", isRunning: false, isSuccess: undefined },
      { step: "umep", isRunning: false, isSuccess: undefined },
      {
        step: "roof_section_processing",
        isRunning: false,
        isSuccess: undefined,
      },
      {
        step: "calepinage_computation",
        isRunning: false,
        isSuccess: undefined,
      },
    ]);

  const { getPotentielSolaire } = useGetMonPotentielSolaire();

  const getSolarPotentialFromDataBackend = async () => {
    if (solarPotentialStepsStatus.isSolarPotentialKOForm === true) return;

    const getPotentielSolaireResponse = await getPotentielSolaire({
      userToken: userAuthInfo.userToken,
      building_id: studyInformation.building_id || null,
      household_id: studyInformation.household_id || null,
      study_id: studyInformation?.study_id || "",
    });

    dispatch(
      setSolarPotentialStepsStatusAction({
        solarPotentialStepsStatus: {
          isSolarPotentialKOForm:
            !getPotentielSolaireResponse?.solar_potential_ok || false,
          isLoadingScreenDisplayed: false,
        },
      })
    );

    return;
  };

  const checkSolarPotentialAvailability = () => {
    if (solarPotentialStepsStatus.isSolarPotentialKOForm === true) return;

    dispatch(
      setSolarPotentialStepsStatusAction({
        solarPotentialStepsStatus: {
          isCheckSolarPotentialAvailabilityLoading: true,
        },
      })
    );

    const isCheckRunning = async (event: IEvent) => {
      const eventResponse = event.data;

      if (eventResponse === "failed" || eventResponse === "succeed") {
        return;
      }

      setCheckSolarPotentialStepsStatus((stepsStatus) => {
        const newStepsStatus = [...stepsStatus];
        for (let i = 0; i < newStepsStatus.length; i++) {
          if (newStepsStatus[i].step !== eventResponse) {
            newStepsStatus[i].isRunning = false;
            newStepsStatus[i].isSuccess = true;
            continue;
          }
          newStepsStatus[i].isRunning = true;
          break;
        }

        return newStepsStatus;
      });
    };

    const isFailed = async (event: IEvent) => {
      const eventResponse = event.data;
      const eventId = event.lastEventId;

      if (eventResponse === "connectionFailed") {
        setCheckSolarPotentialStepsStatus((stepsStatus) => {
          const newStepsStatus = [...stepsStatus];
          newStepsStatus.map((status) => {
            status.isRunning = false;
            status.isSuccess = false;
            return status;
          });
          return newStepsStatus;
        });
        serverEvent.close();

        dispatch(
          setSolarPotentialStepsStatusAction({
            solarPotentialStepsStatus: {
              isSolarPotentialKOForm: true,
              isCheckSolarPotentialAvailabilityLoading: false,
            },
          })
        );

        return;
      }

      if (eventResponse !== "failed") return;

      if (eventId === "0") {
        setCheckSolarPotentialStepsStatus((stepsStatus) => {
          const newStepsStatus = [...stepsStatus];
          newStepsStatus.map((status) => {
            status.isRunning = false;
            status.isSuccess = false;
            return status;
          });
          return newStepsStatus;
        });

        serverEvent.close();
        dispatch(
          setSolarPotentialStepsStatusAction({
            solarPotentialStepsStatus: {
              isSolarPotentialKOForm: true,
              isCheckSolarPotentialAvailabilityLoading: false,
            },
          })
        );
        return;
      }

      setCheckSolarPotentialStepsStatus((stepsStatus) => {
        const newStepsStatus = [...stepsStatus];

        const currentStepRunning = newStepsStatus.find(
          (status) => status.isRunning === true
        );
        currentStepRunning && (currentStepRunning.isSuccess = false);
        currentStepRunning && (currentStepRunning.isRunning = false);
        return newStepsStatus;
      });
      serverEvent.close();
      getSolarPotentialFromDataBackend();
      dispatch(
        setSolarPotentialStepsStatusAction({
          solarPotentialStepsStatus: {
            isCheckSolarPotentialAvailabilityLoading: false,
          },
        })
      );
    };

    const isSucced = async (event: IEvent) => {
      const eventResponse = event.data;
      if (eventResponse !== "succeed") return;

      setCheckSolarPotentialStepsStatus(() => [
        { step: "waiting", isRunning: false, isSuccess: true },
        { step: "preprocessing", isRunning: false, isSuccess: true },
        { step: "umep", isRunning: false, isSuccess: true },
        {
          step: "roof_section_processing",
          isRunning: false,
          isSuccess: true,
        },
        {
          step: "calepinage_computation",
          isRunning: false,
          isSuccess: true,
        },
      ]);

      serverEvent.close();
      getSolarPotentialFromDataBackend();
      dispatch(
        setSolarPotentialStepsStatusAction({
          solarPotentialStepsStatus: {
            isCheckSolarPotentialAvailabilityLoading: false,
          },
        })
      );
    };

    const serverEvent = new EventSourcePolyfill(
      `${process.env.REACT_APP_EERO_URL}/oree2/checkSolarPotentialAvailability/${studyInformation.study_id}?building_id=${studyInformation.building_id}`,
      {
        headers: {
          authorization: `Bearer ${userAuthInfo.userToken}`,
        },
      }
    );

    serverEventRef.current = serverEvent;

    serverEvent.onmessage = (event) => {
      isCheckRunning(event);
      isFailed(event);
      isSucced(event);
    };

    return;
  };

  const abortCheckPotentialSolar = () => {
    serverEventRef.current?.close();

    dispatch(
      setSolarPotentialStepsStatusAction({
        solarPotentialStepsStatus: {
          isSolarPotentialKOForm: true,
          isCheckSolarPotentialAvailabilityLoading: false,
          isLoadingScreenDisplayed: false,
        },
      })
    );

    return;
  };

  useEffect(() => {
    checkSolarPotentialAvailability();
    // eslint-disable-next-line
  }, []);

  const waitingStep = checkSolarPotentialStepsStatus.find(
    (status) => status.step === "waiting"
  );
  const preprocessingStep = checkSolarPotentialStepsStatus.find(
    (status) => status.step === "preprocessing"
  );
  const umepStep = checkSolarPotentialStepsStatus.find(
    (status) => status.step === "umep"
  );
  const roofSectionsProcessingStep = checkSolarPotentialStepsStatus.find(
    (status) => status.step === "roof_section_processing"
  );
  const calepinageComputationStep = checkSolarPotentialStepsStatus.find(
    (status) => status.step === "calepinage_computation"
  );
  return (
    <div className="PotentialSolarLoadingComponent">
      <div className="PotentialSolarLoadingComponent__Textcontainer">
        {waitingStep?.isRunning ? (
          <LoadingComponent diameter={30} />
        ) : waitingStep?.isSuccess === undefined ? (
          <div className="PotentialSolarLoadingComponent__EmptyIconeContainer"></div>
        ) : (
          <ValidationCheck diameter={30} success={waitingStep?.isSuccess} />
        )}
        <p>Lancement des calculs (durée estimée : 10s)</p>
      </div>
      <div className="PotentialSolarLoadingComponent__Textcontainer">
        {preprocessingStep?.isRunning ? (
          <LoadingComponent diameter={30} />
        ) : preprocessingStep?.isSuccess === undefined ? (
          <div className="PotentialSolarLoadingComponent__EmptyIconeContainer"></div>
        ) : (
          <ValidationCheck
            diameter={30}
            success={preprocessingStep?.isSuccess}
          />
        )}
        <p>Récupération des images et du nuage 3D (durée estimée : 20s)</p>
      </div>
      <div className="PotentialSolarLoadingComponent__Textcontainer">
        {umepStep?.isRunning ? (
          <LoadingComponent diameter={30} />
        ) : umepStep?.isSuccess === undefined ? (
          <div className="PotentialSolarLoadingComponent__EmptyIconeContainer"></div>
        ) : (
          <ValidationCheck diameter={30} success={umepStep?.isSuccess} />
        )}
        <p>Calcul du potentiel solaire (durée estimée : 1mn)</p>
      </div>
      <div className="PotentialSolarLoadingComponent__Textcontainer">
        {roofSectionsProcessingStep?.isRunning ? (
          <LoadingComponent diameter={30} />
        ) : roofSectionsProcessingStep?.isSuccess === undefined ? (
          <div className="PotentialSolarLoadingComponent__EmptyIconeContainer"></div>
        ) : (
          <ValidationCheck
            diameter={30}
            success={roofSectionsProcessingStep?.isSuccess}
          />
        )}
        <p>
          Segmentation de la toiture et mesure des caractéristiques des pans de
          toits (durée estimée : 15s)
        </p>
      </div>

      <div className="PotentialSolarLoadingComponent__Textcontainer">
        {calepinageComputationStep?.isRunning ? (
          <LoadingComponent diameter={30} />
        ) : calepinageComputationStep?.isSuccess === undefined ? (
          <div className="PotentialSolarLoadingComponent__EmptyIconeContainer"></div>
        ) : (
          <ValidationCheck
            diameter={30}
            success={calepinageComputationStep?.isSuccess}
          />
        )}
        <p>Calcul des calepinages (durée estimée : 2mn)</p>
      </div>
      <div className="PotentialSolarLoadingComponent__ExitButton">
        <button onClick={() => abortCheckPotentialSolar()}>
          Je souhaite renseigner moi-même le potentiel solaire
        </button>
      </div>
    </div>
  );
};
