// Services imports
import { isJwtTokenExpired } from "../isJwtTokenExpired";
import { useDisconnectApp } from "../useDisconnectApp";
// Interface imports
import { IUserAuthInfo } from "../../interfaces/generalInterfaces";
// Other imports
import { useAppDispatch } from "../../redux/store/hook";
import { setUserAuthInfoAction } from "../../redux/appActions";

//Interface
interface FunctionReturn {
  getUserAuthInfo: () => void;
}

// useGetUserAuthInfo : return function to get user authentication information from local storage and save it in redux store
export const useGetUserAuthInfo = (): FunctionReturn => {
  // Hooks calls
  const dispatch = useAppDispatch();
  const { disconnectApp } = useDisconnectApp();

  // getUserAuthInfo : function to get user authentication information from local storage and save it in redux store
  const getUserAuthInfo = () => {
    const stored = localStorage.getItem("userAuthInfo");
    const userAuthInfo: IUserAuthInfo = stored ? JSON.parse(stored) : null;
    if (userAuthInfo?.userToken && !isJwtTokenExpired(userAuthInfo.userToken)) {
      dispatch(setUserAuthInfoAction({ userAuthInfo: userAuthInfo }));
      return;
    }
    disconnectApp(); // If user is not authenticated or his JWT token is expired he must be disconnected from the app
    return;
  };
  return { getUserAuthInfo };
};
