import { Text, StyleSheet, Font, View } from "@react-pdf/renderer";
import { DSColors } from "@web/shared/src/styles/variables";

// Fonts
import DMSansMedium from "../../../../assets/pdf/fonts/DMSansMedium.ttf";

// Register font
Font.register({
  family: "DMSansMedium",
  src: DMSansMedium,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  containerPan: {
    width: "32%",
    padding: "10 10 0 10",
    borderRadius: 5,
    margin: "0 auto 10 auto",
    backgroundColor: DSColors.SGLight05,
  },
  containerPan__title: {
    fontSize: 9,
    fontFamily: "DMSansBold",
    marginBottom: 10,
    width: 200,
  },
  containerPan__textContainer: {
    display: "flex",
    flexDirection: "row",
  },
  containerPan__text: {
    fontSize: 9,
    marginBottom: 10,
  },
  containerPan__textStrong: {
    fontSize: 9,
    marginBottom: 10,
    fontFamily: "DMSansBold",
  },
});

interface Props {
  number: string;
  orientation: string | null;
  surface: number | null;
  pente: number | null;
}

export const PdfOnePan = ({ number, orientation, surface, pente }: Props) => {
  const orientationDictionnary: { [key: string]: string } = {
    North: "Nord",
    "North-West": "Nord-Ouest",
    West: "Ouest",
    "South-West": "Sud-Ouest",
    South: "Sud",
    "South-East": "Sud-Est",
    East: "Est",
    "North-East": "Nord-Est",
  };

  return (
    <View wrap={false} style={styles.containerPan}>
      <Text style={styles.containerPan__title}>Pan {number}</Text>
      <View style={styles.containerPan__textContainer}>
        <Text style={styles.containerPan__text}>Orientation : </Text>
        <Text style={styles.containerPan__textStrong}>
          {orientation
            ? orientationDictionnary?.[orientation] || orientation
            : "Non identifiée"}
        </Text>
      </View>
      <View style={styles.containerPan__textContainer}>
        <Text style={styles.containerPan__text}>Surface disponible : </Text>
        <Text style={styles.containerPan__textStrong}>{surface}</Text>
        <Text style={styles.containerPan__text}>{" m²"}</Text>
      </View>
      <View style={styles.containerPan__textContainer}>
        <Text style={styles.containerPan__text}>Pente : </Text>
        <Text style={styles.containerPan__textStrong}>{pente}</Text>
        <Text style={styles.containerPan__text}>{" °"}</Text>
      </View>
    </View>
  );
};
