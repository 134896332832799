// Styles import
import "./MesConsommationsAndEquipmentsRG.scss";

// Components import
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { Quantite } from "@web/shared/dist/components/DesignSystem/Boutons/Quantite/Quantite";
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import {
  Dropdown,
  manageDropdownSingleSelection,
} from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { TurningBlueStar } from "../../components/Animations/TurningBlueStar/TurningBlueStar";

// Images import
import Chauffage from "@web/shared/dist/assets/images/DesignSystemIllustrations/chauffage1.svg";
import ECS from "@web/shared/dist/assets/images/DesignSystemIllustrations/ECS1.svg";
import GPL from "@web/shared/dist/assets/images/DesignSystemIllustrations/GPL1.svg";

// Data import
import { mesConsommationsQuestionsData } from "./MesConsommationsQuestionsData";

// Interfaces import
import { IStudyInformation } from "../../interfaces/generalInterfaces";

// Local interfaces declaration
interface IProps {
  studyInformation: IStudyInformation;
  GPLConsommationEuros: string | null;
  isGPL: boolean;
  setGPLConsommationEuros: React.Dispatch<React.SetStateAction<string | null>>;
  GPLPrincipauxEquipments: IOption[];
  setGPLPrincipauxEquipments: React.Dispatch<React.SetStateAction<IOption[]>>;
  GPLSurfaceChauffee: string | null;
  setGPLSurfaceChauffee: React.Dispatch<React.SetStateAction<string | null>>;
  GPLTemperatureConfort: number | null;
  setGPLTemperatureConfort: React.Dispatch<React.SetStateAction<number | null>>;
  GPLTypeChauffage: IOption[];
  setGPLTypeChauffage: React.Dispatch<React.SetStateAction<IOption[]>>;
  GPLNombreEmetteur: number | null;
  setGPLNombreEmetteur: React.Dispatch<React.SetStateAction<number | null>>;
  GPLTypeEmetteur: IOption[];
  setGPLTypeEmetteur: React.Dispatch<React.SetStateAction<IOption[]>>;
  GPLStorageCapacity: IOption[];
  setGPLStorageCapacity: React.Dispatch<React.SetStateAction<IOption[]>>;
  GPLChauffageConsumptionRate: string | null;
  setGPLChauffageConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  GPLECSConsumptionRate: string | null;
  setGPLECSConsumptionRate: React.Dispatch<React.SetStateAction<string | null>>;
  sourceEnergie: IOption[];
  checkGPLEquipmentsDistribution: () => boolean;
  resetChauffageSection: () => void;
  resetECSSection: () => void;
}

interface IOption extends IImage {
  label: string | null;
  value: string | null;
}

interface IImage {
  imgSrc?: string;
  imgAltTxt?: string;
}

export const GPLPartForm = ({
  GPLConsommationEuros,
  isGPL,
  setGPLConsommationEuros,
  GPLPrincipauxEquipments,
  setGPLPrincipauxEquipments,
  GPLSurfaceChauffee,
  setGPLSurfaceChauffee,
  GPLTemperatureConfort,
  setGPLTemperatureConfort,
  GPLTypeChauffage,
  setGPLTypeChauffage,
  GPLNombreEmetteur,
  setGPLNombreEmetteur,
  GPLTypeEmetteur,
  setGPLTypeEmetteur,
  GPLStorageCapacity,
  setGPLStorageCapacity,
  GPLChauffageConsumptionRate,
  setGPLChauffageConsumptionRate,
  GPLECSConsumptionRate,
  setGPLECSConsumptionRate,
  sourceEnergie,
  checkGPLEquipmentsDistribution,
  resetChauffageSection,
  resetECSSection,
}: IProps) => {
  const allEquipmentsConsumption = [
    Number(GPLChauffageConsumptionRate),
    Number(GPLECSConsumptionRate),
  ];

  const inputErrorMessage = () => {
    const totalEquipmentsConsumptionPercent = allEquipmentsConsumption.reduce(
      (acc, curr) => acc + curr,
      0
    );

    return `Distribué : ${totalEquipmentsConsumptionPercent}% | Restant :
      ${100 - totalEquipmentsConsumptionPercent}%`;
  };

  return (
    <section
      className={`MesConsommationsAndEquipementsRG__SectionContent ${
        !sourceEnergie.some((item) => item.value === "gpl") && "Hidden"
      }`}
      id="caracteristiques_du_logement"
    >
      <div className="MesConsommationsAndEquipementsRG__TitleOne">
        <img src={GPL} alt="GPL" />
        <h3>GPL</h3>
      </div>
      <section>
        <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
          <label className="label" htmlFor="consommationElec">
            Consommation de GPL annuelle :{" "}
          </label>
          <div style={{ maxWidth: "102px" }}>
            <TextInput
              inputLableHidden
              type="number"
              inputDescriptionHidden
              iconType="euros"
              iconHidden={false}
              placeholder="XXXX"
              value={GPLConsommationEuros ?? undefined}
              onChange={(e) => {
                setGPLConsommationEuros(e.currentTarget.value);
              }}
              required={isGPL}
            />
          </div>
        </div>
        <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
          <label className="label" htmlFor="equipments">
            Principaux équipements :{" "}
          </label>
          <ChoixMultiple
            options={
              mesConsommationsQuestionsData.gpl.principauxEquipements.options
            }
            value={GPLPrincipauxEquipments}
            onClick={(e) => {
              const equipmentToUnselected = [...GPLPrincipauxEquipments].find(
                (option) => option.label === e?.label
              );

              e &&
                setGPLPrincipauxEquipments((state) => {
                  const newState = [...state];
                  const foundIndex = newState.findIndex(
                    (option) => option.label === e.label
                  );
                  if (foundIndex !== -1) {
                    newState.splice(foundIndex, 1);
                  } else {
                    newState.push(e);
                  }
                  return newState;
                });
              //handle deselect option equipment
              switch (equipmentToUnselected?.label) {
                case "Chauffage":
                  resetChauffageSection();
                  break;
                case "Eau chaude sanitaire (ECS)":
                  resetECSSection();
                  break;
              }
            }}
            isRequired={isGPL}
          />
        </div>
      </section>
      {GPLPrincipauxEquipments.find((elem) => elem.value === "chauffage") ? (
        <section>
          <div className="MesConsommationsAndEquipementsRG__Trait" />
          <div className="MesConsommationsAndEquipementsRG__TitleTwo">
            <h3>Chauffage</h3>
          </div>
          <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
            <label className="label" htmlFor="surfaceChauffee">
              Surface chauffée :{" "}
            </label>
            <div style={{ maxWidth: "102px" }}>
              <TextInput
                inputLableHidden
                type="number"
                inputDescriptionHidden
                iconType="squaredMeters"
                iconHidden={false}
                placeholder="xxxx"
                value={GPLSurfaceChauffee ?? undefined}
                onChange={(e) => {
                  setGPLSurfaceChauffee(e.currentTarget.value);
                }}
                required={
                  isGPL &&
                  !!GPLPrincipauxEquipments.find(
                    (elem) => elem.value === "chauffage"
                  )
                }
              />
            </div>
          </div>
          <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
            <label className="label" htmlFor="temperatureConfort">
              Température de confort (°C):{" "}
            </label>
            <Quantite
              value={GPLTemperatureConfort ?? 0}
              onPlusMinusClick={(e) => setGPLTemperatureConfort(e)}
            />
          </div>
          <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
            <div
              className={"MesConsommationsAndEquipementsRG__imgLabelWrapper"}
            >
              <TurningBlueStar />
              <label className="label" htmlFor="typeChauffage">
                Type de chaudière :{" "}
              </label>
            </div>
            <ChoixMultiple
              options={
                mesConsommationsQuestionsData.gpl.typeDeChaudiere.options
              }
              value={GPLTypeChauffage}
              onClick={(e) => {
                e &&
                  setGPLTypeChauffage((state) => {
                    const newState = [...state];
                    const foundIndex = newState.findIndex(
                      (option) => option.label === e.label
                    );
                    if (foundIndex !== -1) {
                      newState.splice(foundIndex, 1);
                    } else {
                      return [e];
                    }
                    return newState;
                  });
              }}
              isRequired={
                isGPL &&
                !!GPLPrincipauxEquipments.find(
                  (elem) => elem.value === "chauffage"
                )
              }
            />
          </div>
          <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
            <label className="label" htmlFor="typeEmetteur">
              Type d’émetteur :{" "}
            </label>
            <ChoixMultiple
              options={mesConsommationsQuestionsData.gpl.typeDEmetteur.options}
              value={GPLTypeEmetteur}
              onClick={(e) => {
                if (!e) return;

                setGPLTypeEmetteur((state) => {
                  const newState = [...state];
                  const foundIndex = newState.findIndex(
                    (option) => option.label === e.label
                  );
                  if (foundIndex !== -1) {
                    newState.splice(foundIndex, 1);
                  } else {
                    return [e];
                  }
                  return newState;
                });

                if (e.value === "plancherChauffant") {
                  setGPLNombreEmetteur(1);
                }
              }}
              isRequired={
                isGPL &&
                !!GPLPrincipauxEquipments.find(
                  (elem) => elem.value === "chauffage"
                )
              }
            />
          </div>
          {GPLTypeEmetteur.length &&
          GPLTypeEmetteur[0].value !== "plancherChauffant" ? (
            <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
              <label className="label" htmlFor="nombreEmetteurs">
                Nombre d’émetteur(s) :{" "}
              </label>
              <Quantite
                value={GPLNombreEmetteur ?? 0}
                onPlusMinusClick={(e) => setGPLNombreEmetteur(e)}
              />
            </div>
          ) : null}
        </section>
      ) : null}
      {GPLPrincipauxEquipments.find(
        (elem) => elem.value === "eauChaudeSanitaire"
      ) ? (
        <section>
          <div className="MesConsommationsAndEquipementsRG__Trait" />
          <div className="MesConsommationsAndEquipementsRG__TitleTwo">
            <h3>Eau chaude sanitaire (ECS)</h3>
          </div>

          <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
            <label className="label" htmlFor="storageCapacity">
              Capacité de stockage :{" "}
            </label>
            <div className="MesConsommationsAndEquipementsRG__DropdowContainer">
              <Dropdown
                placeholder="Sélectionnez votre volume"
                options={
                  mesConsommationsQuestionsData.gpl.capaciteDeStockage.options
                }
                tags={false}
                optionsSelected={
                  GPLStorageCapacity.length > 0 ? GPLStorageCapacity : []
                }
                multipleChoices={false}
                onSelect={(option) => {
                  manageDropdownSingleSelection(option, setGPLStorageCapacity);
                }}
                isRequired={
                  isGPL &&
                  !!GPLPrincipauxEquipments.find(
                    (elem) => elem.value === "eauChaudeSanitaire"
                  )
                }
              />
            </div>
          </div>
        </section>
      ) : null}
      {GPLPrincipauxEquipments.length > 1 ? (
        <section>
          <div className="MesConsommationsAndEquipementsRG__Trait" />
          <div className="MesConsommationsAndEquipementsRG__Synthese">
            <div className="MesConsommationsAndEquipementsRG__TitleSynthese">
              <h3>Synthèse</h3>
            </div>
            <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
              <label className="label" htmlFor="consommationElec">
                Consommation en GPL annuelle :{" "}
              </label>
              <div style={{ maxWidth: "102px" }}>
                <TextInput
                  inputLableHidden
                  type="number"
                  inputDescriptionHidden
                  iconType="euros"
                  iconHidden={false}
                  placeholder="XXXX"
                  value={GPLConsommationEuros ?? undefined}
                  onChange={(e) => {
                    setGPLConsommationEuros(e.currentTarget.value);
                  }}
                  required={isGPL && GPLPrincipauxEquipments.length > 1}
                />
              </div>
            </div>
            <div className="MesConsommationsAndEquipementsRG__SyntheseRow">
              {GPLPrincipauxEquipments.find(
                (elem) => elem.value === "chauffage"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={Chauffage} alt="Chauffage" />
                    <label className="label" htmlFor="chauffageEuros">
                      Chauffage{" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkGPLEquipmentsDistribution()}
                      inputLableHidden
                      type="number"
                      inputDescriptionHidden
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={GPLChauffageConsumptionRate ?? undefined}
                      onChange={(e) => {
                        setGPLChauffageConsumptionRate(e.currentTarget.value);
                      }}
                      required={
                        isGPL &&
                        !!GPLPrincipauxEquipments.find(
                          (elem) => elem.value === "chauffage"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(GPLConsommationEuros) *
                        Number(GPLChauffageConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
              {GPLPrincipauxEquipments.find(
                (elem) => elem.value === "eauChaudeSanitaire"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={ECS} alt="ecs" />
                    <label className="label" htmlFor="ECSEuros">
                      Eau chaude sanitaire :{" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkGPLEquipmentsDistribution()}
                      inputLableHidden
                      type="number"
                      inputDescriptionHidden
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={GPLECSConsumptionRate ?? undefined}
                      onChange={(e) => {
                        setGPLECSConsumptionRate(e.currentTarget.value);
                      }}
                      required={
                        isGPL &&
                        !!GPLPrincipauxEquipments.find(
                          (elem) => elem.value === "eauChaudeSanitaire"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(GPLConsommationEuros) *
                        Number(GPLECSConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionMessageContainer">
              <p>{inputErrorMessage()}</p>
            </div>
          </div>
        </section>
      ) : null}
    </section>
  );
};
