// Styles import
import "./MesConsommationsAndEquipmentsRG.scss";

// Components import
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";

// Data import
import { mesConsommationsQuestionsData } from "./MesConsommationsQuestionsData";

// Local interfaces declaration
interface IProps {
  sourceEnergie: IOption[];
  setSourceEnergie: React.Dispatch<React.SetStateAction<IOption[]>>;
}

interface IOption extends IImage {
  label: string | null;
  value: string | null;
}

interface IImage {
  imgSrc?: string;
  imgAltTxt?: string;
}

export const EnedisKOPartForm = ({
  sourceEnergie,
  setSourceEnergie,
}: IProps) => {
  return (
    <section
      className={`MesConsommationsAndEquipementsRG__SectionContent`}
      id="caracteristiques_du_logement"
    >
      <div className="MesConsommationsAndEquipementsRG__TitleOne">
        <h3>Quelles sont les sources d’énergie de votre logement ?</h3>
      </div>

      <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
        <ChoixMultiple
          value={sourceEnergie}
          options={mesConsommationsQuestionsData.sourcesDEnergie.options}
          onClick={(e) => {
            e &&
              setSourceEnergie((state) => {
                const newState = [...state];
                const foundIndex = newState.findIndex(
                  (option) => option.label === e.label
                );
                if (foundIndex !== -1) {
                  newState.splice(foundIndex, 1);
                } else {
                  newState.push(e);
                }
                return newState;
              });
          }}
        />
      </div>
    </section>
  );
};
