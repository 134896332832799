// Styles import
import "./financingGraphContainer.scss";

// Libraries import
import { CSSTransition } from "react-transition-group";

// Components import
import { FinancingGraph } from "../FinancingGraph/FinancingGraph";

// Services import
import { useAppSelector } from "../../redux/store/hook";

// Interfaces import
import {
  IPVCashflowGraphData,
  IRGCashflowGraphData,
} from "../../services/OptionChosen/profitabilityGraphDataCalculation";

// Images import
import chevrondown from "@web/shared/dist/assets/images/DesignSystemIcons/chevron-down.svg";

type FinancingGraphContainerProps = {
  isCashFlowGraphOpen: boolean;
  setIsCashFlowGraphOpen: React.Dispatch<React.SetStateAction<boolean>>;
  graphData: IRGCashflowGraphData[] | IPVCashflowGraphData[];
  fundingPeriod: number;
  isDeferralOption: boolean;
  displayHelps: boolean;
};

export const FinancingGraphContainer = ({
  isCashFlowGraphOpen,
  setIsCashFlowGraphOpen,
  graphData,
  fundingPeriod,
  isDeferralOption,
  displayHelps,
}: FinancingGraphContainerProps) => {
  const { studyInformation } = useAppSelector((state) => state);

  return (
    <section>
      <div
        onClick={() => setIsCashFlowGraphOpen((state) => !state)}
        className="FinancingGraphContainer__SectionTitle"
      >
        <p>Eco financement</p>
        <div className="ChevronPicto">
          <CSSTransition
            in={isCashFlowGraphOpen}
            timeout={300}
            classNames={"tab-chevron"}
          >
            <img src={chevrondown} alt="picto SelfSufficiency" />
          </CSSTransition>
        </div>
      </div>
      <div style={!isCashFlowGraphOpen ? { display: "none" } : {}}>
        {graphData.length > 0 ? (
          <FinancingGraph
            fundingPeriod={fundingPeriod}
            cashflowGraphData={graphData}
            isDeferralOption={isDeferralOption}
            studyType={studyInformation.study_type || "photovoltaique"}
            displayHelps={displayHelps}
          />
        ) : null}
      </div>
    </section>
  );
};
