import { Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  listMentions: {
    marginLeft: 10,
  },
});
interface IProps {
  title: string;
  paragraph: JSX.Element[];
}

export const PdfList = ({ title, paragraph }: IProps) => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    <View>
      <Text>{title}</Text>
      <View style={styles.listMentions}>
        {paragraph.map((item, _key) => {
          return <Text key={s4()}>{item}</Text>;
        })}
      </View>
    </View>
  );
};
