//Styles import
import "./monInstallationFormulePersonnalisable.scss";

// Components import
import { Layout } from "../../../components/Layout/Layout";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { FormulePersonnalisableForm } from "../../../containers/FormulePersonnalisableForm/FormulePersonnalisableForm";
import { Footer } from "../../../components/Footer/Footer";

interface IProps {
  goNext: (() => void) | undefined;
  setIsPageOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPageOpen: boolean;
  building_id: string;
  household_id: string;
  userToken: string;
  formulePersBtnVisible?: boolean;
}

export const MonInstallationFormulePersonnalisable = ({
  goNext,
  setIsPageOpen,
  isPageOpen,
  userToken,
  formulePersBtnVisible = false,
}: IProps) => {
  return (
    <div className="MonInstallationFormulePersonnalisable">
      <Layout>
        <div className="MonInstallationFormulePersonnalisable__Container">
          <section className="MonInstallationFormulePersonnalisable__Header">
            <div className="title">
              <h4>Formule personnalisable</h4>
              <p>
                <em>Créez vous-même votre propre installation !</em>
              </p>
            </div>
            <div
              className={
                formulePersBtnVisible
                  ? "MonInstallationFormulePersonnalisable__ButtonContainer"
                  : "MonInstallationFormulePersonnalisable__ButtonContainer__hideBtn"
              }
            >
              <CTAButton
                name="Configurer"
                onClick={() => setIsPageOpen(!isPageOpen)}
                category="secondary"
              />
            </div>
          </section>
          <section className="MonInstallationFormulePersonnalisable__FormContainer">
            {isPageOpen && (
              <div>
                <FormulePersonnalisableForm
                  goNext={goNext}
                  userToken={userToken}
                />
              </div>
            )}
          </section>
        </div>
      </Layout>
      <Footer />
    </div>
  );
};
