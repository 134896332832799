// Style import
import { DSColors } from "@web/shared/src/styles/variables";
import "./checkoutContainer.scss";

// Libraries import
import { useState } from "react";

//Components import
import { BoutonPicto } from "@web/shared/dist/components/DesignSystem/Boutons/BoutonPicto/BoutonPicto";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { CheckoutButtonsDefault } from "../CheckoutButtonsDefault/CheckoutButtonsDefault";
import { CheckoutButtonsDemo } from "../CheckoutButtonsDemo/CheckoutButtonsDemo";
import { SaveModale } from "../SaveModale/SaveModale";
import { SubscriptionModale } from "../SubscriptionModale/SubscriptionModale";

// images import
import pictoTrash from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";

// Interfaces import
import { FetchBdcStatusResponse } from "../../../api/hooks/useGetBdcStatus";
import {
  HelpsOptions,
  IOreeStudyType,
  IScenario,
  IStudyInformation,
  IUserAuthInfo,
  LoanSimulationResult,
} from "../../../interfaces/generalInterfaces";

// Services import
import { euroShortFormat } from "../../../services/euroFormatter";

// Local interface declaration
export interface CheckoutContainerProps {
  study_type: IOreeStudyType;
  isCouponValideErrorMessage: string;
  handleUseCouponButton: (couponName: string) => Promise<void>;
  handleDeleteCoupon: (indexCoupon: number) => void;
  totalDevisWithTaxes: number;
  totalValueCoupon: number;
  restToPay: number;
  coupons: {
    label: string;
    type: string;
    value: number;
  }[];
  subscribeFunction: () => Promise<void>;
  isSubscribeFunctionLoading: boolean;
  saveAndSendFunction: () => Promise<void>;
  isSaveAndSendFunctionLoading: boolean;
  saveFunction: () => Promise<void>;
  isSaveFunctionLoading: boolean;
  SubscribeAndSendDataToCRMErrorMessage: string;
  studyInformation: IStudyInformation;
  userAuthInfo: IUserAuthInfo;
  scenarioChosen?: IScenario;
  totalHelp?: number;
  isGetInformationForPDFEtudeLoading: boolean;
  generateQuotePDF: ({
    isDemoVersion,
  }: {
    isDemoVersion?: boolean;
  }) => Promise<{
    quotePDFId: string;
    quotePDFAsBase64: string;
    quotePDFAsBlob: Blob;
  }>;
  generateStudyPDF: ({
    isDemoVersion,
  }: {
    isDemoVersion?: boolean;
  }) => Promise<{
    studyPDFId: string;
    studyPDFAsBase64: string;
    studyPDFAsBlob: Blob | undefined;
  }>;
  isGenerateQuotePDFLoading: boolean;
  isGenerateStudyPDFLoading: boolean;
  devisOptions: HelpsOptions;
  setDevisOptions: React.Dispatch<React.SetStateAction<HelpsOptions>>;
  fundingPeriod: number;
  setFundingPeriod: React.Dispatch<React.SetStateAction<number>>;
  interestRate: string;
  setInterestRate: React.Dispatch<React.SetStateAction<string>>;
  personalContributionAmount: number;
  setPersonalContributionAmount: React.Dispatch<React.SetStateAction<number>>;
  isDeferralOption: boolean;
  setIsDeferralOption: React.Dispatch<React.SetStateAction<boolean>>;
  hasBorrowerInsurance: boolean;
  setHasBorrowerInsurance: React.Dispatch<React.SetStateAction<boolean>>;
  hasCoBorrower: boolean;
  setHasCoBorrower: React.Dispatch<React.SetStateAction<boolean>>;
  loanSimulationResult: LoanSimulationResult;
  setLoanSimulationResult: React.Dispatch<
    React.SetStateAction<LoanSimulationResult>
  >;
  isLoanOptionChosen: boolean;
  setIsLoanOptionChosen: React.Dispatch<React.SetStateAction<boolean>>;
  totalRefundableHelps: number;
  totalDeductibleHelps: number;
  isEligibleRenoGlobal?: boolean;
  totalMPRHelp?: number;
  totalCEEHelp?: number;
  totalProjectCostHelpsDeducted?: number;
  bdcStatus: FetchBdcStatusResponse | undefined;
  isLoadingGetBdcStatus?: boolean;
  isErrorGetBdcStatus?: boolean;
  errorMessageGetBdcStatus?: string;
}

export const CheckoutContainer = ({
  study_type,
  isCouponValideErrorMessage,
  handleUseCouponButton,
  handleDeleteCoupon,
  totalDevisWithTaxes,
  totalValueCoupon,
  restToPay,
  coupons,
  subscribeFunction,
  saveAndSendFunction,
  saveFunction,
  isSubscribeFunctionLoading,
  isSaveAndSendFunctionLoading,
  isSaveFunctionLoading,
  SubscribeAndSendDataToCRMErrorMessage,
  studyInformation,
  userAuthInfo,
  scenarioChosen,
  isGetInformationForPDFEtudeLoading,
  generateQuotePDF,
  generateStudyPDF,
  isGenerateQuotePDFLoading,
  isGenerateStudyPDFLoading,
  devisOptions,
  setDevisOptions,
  fundingPeriod,
  setFundingPeriod,
  interestRate,
  setInterestRate,
  personalContributionAmount,
  setPersonalContributionAmount,
  isDeferralOption,
  setIsDeferralOption,
  hasBorrowerInsurance,
  setHasBorrowerInsurance,
  hasCoBorrower,
  setHasCoBorrower,
  loanSimulationResult,
  setLoanSimulationResult,
  isLoanOptionChosen,
  setIsLoanOptionChosen,
  totalMPRHelp = 0,
  totalCEEHelp = 0,
  totalHelp,
  totalProjectCostHelpsDeducted,
  bdcStatus,
  isLoadingGetBdcStatus,
  isErrorGetBdcStatus,
  errorMessageGetBdcStatus,
}: CheckoutContainerProps) => {
  const isDemoVersion =
    userAuthInfo.companyId === "e72bf170-5328-465f-92ef-059129279edc"; // Id of the demo company. Same id in dev and prod.
  const [advantageCode, setAdvantageCode] = useState<string>("");
  const [promoCode, setPromoCode] = useState<string>("");

  const [isAdvantageCodeShown, setIsAdvantageCodeShown] = useState(false);
  const toggleisAdvantageCodeShown = () => {
    setIsAdvantageCodeShown(!isAdvantageCodeShown);
  };

  const [isPromoCodeShown, setIsPromoCodeShown] = useState(false);
  const toggleisPromoCodeShown = () => {
    setIsPromoCodeShown(!isPromoCodeShown);
  };

  const [isCodeDetailShown, setIsCodeDetailShown] = useState(false);
  const toggleClass3 = () => {
    setIsCodeDetailShown(!isCodeDetailShown);
  };

  const [isSubscriptionModaleOpen, setIsSubscriptionModaleOpen] =
    useState(false);
  const [isSaveModaleOpen, setIsSaveModaleOpen] = useState(false);

  return (
    <>
      {isSubscriptionModaleOpen ? (
        <SubscriptionModale
          devisOptions={devisOptions}
          setDevisOptions={setDevisOptions}
          isModalOpen={isSubscriptionModaleOpen}
          isSubscribeFunctionLoading={
            isSubscribeFunctionLoading || isGetInformationForPDFEtudeLoading
          }
          setIsModalOpen={setIsSubscriptionModaleOpen}
          subscribeFunction={async () => {
            await subscribeFunction();
            setIsSubscriptionModaleOpen(false);
          }}
          study_type={study_type}
          restToPay={restToPay}
          // Financing options states
          fundingPeriod={fundingPeriod}
          setFundingPeriod={setFundingPeriod}
          interestRate={interestRate}
          setInterestRate={setInterestRate}
          personalContributionAmount={personalContributionAmount}
          setPersonalContributionAmount={setPersonalContributionAmount}
          isDeferralOption={isDeferralOption}
          setIsDeferralOption={setIsDeferralOption}
          hasBorrowerInsurance={hasBorrowerInsurance}
          setHasBorrowerInsurance={setHasBorrowerInsurance}
          hasCoBorrower={hasCoBorrower}
          setHasCoBorrower={setHasCoBorrower}
          loanSimulationResult={loanSimulationResult}
          setLoanSimulationResult={setLoanSimulationResult}
          isLoanOptionChosen={isLoanOptionChosen}
          setIsLoanOptionChosen={setIsLoanOptionChosen}
          totalMPRHelp={totalMPRHelp}
          totalCEEHelp={totalCEEHelp}
          bdcStatus={bdcStatus?.state}
        />
      ) : null}
      {isSaveModaleOpen ? (
        <SaveModale
          devisOptions={devisOptions}
          setDevisOptions={setDevisOptions}
          saveAndSendFunction={saveAndSendFunction}
          saveFunction={saveFunction}
          isModalOpen={isSaveModaleOpen}
          setIsModalOpen={setIsSaveModaleOpen}
          isLoadingSaveFunction={
            isSaveFunctionLoading || isSaveAndSendFunctionLoading
          }
          study_type={study_type}
          totalMPRHelp={totalMPRHelp}
          totalCEEHelp={totalCEEHelp}
        />
      ) : null}
      <div className="CheckoutContainer">
        <div className="CheckoutContainer__CouponContainer">
          <h3
            onClick={toggleisAdvantageCodeShown}
            className={isAdvantageCodeShown ? "title svgTranslate" : "title"}
          >
            Vous avez un code parrainage ?
          </h3>
          <div className={isAdvantageCodeShown ? "wrapper" : "hide"}>
            <TextInput
              value={advantageCode}
              onChange={(event) => setAdvantageCode(event.target.value)}
              placeholder="Code avantage"
              iconHidden
              inputDescriptionHidden
              inputLableHidden
            />
            <CTAButton
              onClick={() => {
                handleUseCouponButton(advantageCode);
              }}
              name="Appliquer"
              category="tertiary"
            />
          </div>
          <div style={{ display: isAdvantageCodeShown ? "block" : "none" }}>
            <BoutonPicto
              text="Ajouter un code promo"
              onClick={toggleisPromoCodeShown}
              color={DSColors.Mint}
            />
          </div>
          <div
            className={
              isAdvantageCodeShown && isPromoCodeShown ? "wrapper" : "hide"
            }
          >
            <TextInput
              value={promoCode}
              onChange={(event) => setPromoCode(event.target.value)}
              placeholder="Code promo"
              iconHidden
              inputDescriptionHidden
              inputLableHidden
            />
            <CTAButton
              onClick={() => handleUseCouponButton(promoCode)}
              name="Appliquer"
              category="tertiary"
            />
          </div>
          {isCouponValideErrorMessage && (
            <ErrorMessage errorMessage={isCouponValideErrorMessage} />
          )}
        </div>
        <div className="CheckoutContainer__PricesContainer">
          <div className="CheckoutContainer__PricesDetails">
            <p>Sous total</p>
            <p>{euroShortFormat(totalDevisWithTaxes || 0)}</p>
          </div>

          {totalHelp ? (
            <div className="CheckoutContainer__HelpsDetails">
              <p>Total des aides</p>
              <p>{euroShortFormat(totalHelp)}</p>
            </div>
          ) : null}

          {coupons.length > 0 && (
            <div className="CheckoutContainer__priceWithDropdown">
              <div
                onClick={toggleClass3}
                className={
                  isCodeDetailShown
                    ? "CheckoutContainer__CouponsDetails svgTranslate"
                    : "CheckoutContainer__CouponsDetails"
                }
              >
                <p>Remise</p>
                <p>{euroShortFormat(Number(totalValueCoupon) || 0)}</p>
              </div>
              <div
                className={
                  isCodeDetailShown
                    ? "CheckoutContainer__dropdown"
                    : "dropdownhide"
                }
              >
                {coupons.map((coupon, index) => {
                  return (
                    <div className="item" key={index}>
                      <div className="name">
                        <p>{coupon.label}</p>
                        <img
                          src={pictoTrash}
                          alt="picto trash"
                          onClick={() => handleDeleteCoupon(index)}
                        />
                      </div>
                      {coupon.type === "percentage" ? (
                        <p>
                          {coupon.value}{" "}
                          {/* POURCENTAGE A GERER AVEC PRIX FINAL */}
                        </p>
                      ) : (
                        <p>{euroShortFormat(Number(coupon.value))}</p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="CheckoutContainer__RestToPay">
            <p>Coût du projet aides déduites</p>
            <p>{euroShortFormat(totalProjectCostHelpsDeducted || 0)}</p>
          </div>
        </div>
        {isDemoVersion ? (
          <CheckoutButtonsDemo
            errorMessages={SubscribeAndSendDataToCRMErrorMessage}
            generateQuotePDF={generateQuotePDF}
            generateStudyPDF={generateStudyPDF}
            isGenerateQuotePDFLoading={isGenerateQuotePDFLoading}
            isGenerateStudyPDFLoading={isGenerateStudyPDFLoading}
            isGetInformationForPDFEtudeLoading={
              isGetInformationForPDFEtudeLoading
            }
          />
        ) : (
          <CheckoutButtonsDefault
            errorMessages={SubscribeAndSendDataToCRMErrorMessage}
            studyInformation={studyInformation}
            userAuthInfo={userAuthInfo}
            scenarioChosen={scenarioChosen}
            isGetInformationForPDFEtudeLoading={
              isGetInformationForPDFEtudeLoading
            }
            setIsSubscriptionModaleOpen={setIsSubscriptionModaleOpen}
            setIsSaveModaleOpen={setIsSaveModaleOpen}
            bdcStatus={bdcStatus}
            isLoadingGetBdcStatus={isLoadingGetBdcStatus}
            isErrorGetBdcStatus={isErrorGetBdcStatus}
            errorMessageGetBdcStatus={errorMessageGetBdcStatus}
          />
        )}
      </div>
    </>
  );
};
