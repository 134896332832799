//Interface import
import { ISageProduct } from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

export const formatProductCharacteristicsToDisplay = ({
  productInfo,
  sageProduct,
}: {
  productInfo: IDataProduct;
  sageProduct: ISageProduct[];
}) => {
  const productCharacteristicsFromSAGE = sageProduct.filter(
    (productCharacteristics) =>
      productCharacteristics.AR_Ref === productInfo.sage_reference
  )[0];

  switch (productInfo.product_type) {
    case "PV":
      return (
        <>
          {productInfo.product_characteristics?.power ? (
            <>
              <p className="ProductCard__Description">{`Puissance : ${productInfo.product_characteristics?.power} kWC`}</p>
              <p className="ProductCard__Description">{`Nombre de panneaux : ${
                Number(productCharacteristicsFromSAGE?.Puissance || 0) / 500 ||
                productCharacteristicsFromSAGE?.number_of_panels
              }`}</p>
            </>
          ) : null}

          {/* type de racordement à ajouter ici -  a défirnir avec Ops */}

          {productInfo.selected_characteristics.kit_home_management === true ? (
            <p className="ProductCard__Description">
              {productInfo.product_characteristics.kit_home_management?.alias ||
                "Kit Home Management"}
            </p>
          ) : null}
          {productInfo.selected_characteristics?.n_batteries ? (
            <p className="ProductCard__Description">
              {`Batterie(s) : ${productInfo.selected_characteristics?.n_batteries}`}
            </p>
          ) : null}
        </>
      );

    case "CET":
    case "BALLON":
      return productInfo?.product_characteristics.ecs_storage_volume ? (
        <p className="ProductCard__Description">{`Capacité : ${productInfo?.product_characteristics.ecs_storage_volume} L`}</p>
      ) : null;

    case "ITE":
    case "ISOA":
    case "ISO":
    case "ISOP":
      return (
        <>
          {productInfo.selected_characteristics?.surface_to_isolate ? (
            <p className="ProductCard__Description">{`Surface à isoler : ${Math.round(
              productInfo.selected_characteristics.surface_to_isolate
            )} m²`}</p>
          ) : null}

          {productCharacteristicsFromSAGE?.Marque ? (
            <p className="ProductCard__Description">{`Marque : ${productCharacteristicsFromSAGE.Marque}`}</p>
          ) : null}
        </>
      );

    // type d'isolation à ajouter ici -  a défirnir avec Ops

    case "PACAIRO":
    case "PACAIRAIR":
      return (
        <>
          {productInfo.selected_characteristics?.surface_to_heat ? (
            <p className="ProductCard__Description">{`Surface à chauffer : ${Math.round(
              productInfo.selected_characteristics.surface_to_heat
            )} m²`}</p>
          ) : null}
          {productCharacteristicsFromSAGE?.["Puissance de la PAC"] ? (
            <p className="ProductCard__Description">{`Puissance : ${productCharacteristicsFromSAGE["Puissance de la PAC"]}kW`}</p>
          ) : null}
          {productCharacteristicsFromSAGE?.Marque ? (
            <p className="ProductCard__Description">{`Marque : ${productCharacteristicsFromSAGE.Marque}`}</p>
          ) : null}
          {productInfo.selected_characteristics.rooms_information
            ?.flatMap((room) => room.interior_units)
            .map((interiorUnit, index) => (
              <p key={index} className="ProductCard__Description">
                {"+ " + (interiorUnit.alias || interiorUnit.name)}
              </p>
            ))}
        </>
      );
    case "AUTRE":
      return productInfo.selected_characteristics.free_product_description ? (
        <p className="ProductCard__Description">{`Description : ${productInfo.selected_characteristics.free_product_description}`}</p>
      ) : null;
    default:
      return null;
  }
};
