import React from "react";
import "./dataInformationLine.scss";

interface IProps {
  dataName: string | undefined;
  dataNumber: string;
  isUnderlineVisible: boolean;
  formuleName: string | null;
}

export const DataInformationLine = ({
  dataName,
  dataNumber,

  isUnderlineVisible,
  formuleName,
}: IProps) => {
  return (
    <div className={"DataInformationLine"}>
      <div
        className={
          "DataInformationLine__Information DataInformationLine__Background" +
          formuleName?.toLocaleLowerCase()
        }
      >
        <p>{dataName}</p>
        <p>{dataNumber}</p>
      </div>
      {isUnderlineVisible && <div className="DataInformationLine__Underline" />}
    </div>
  );
};
