interface DictionaryObject {
  [key: number | string]: string;
}

interface Dictionary {
  implantationduPan: DictionaryObject;
  accessibiliteDesComnbles: DictionaryObject;
  typeDaccesDesCombles: DictionaryObject;
  nacelleaPrevoir: DictionaryObject;
  natureDuSupport: DictionaryObject;
  typedeSupport: DictionaryObject;
  masqueSolaires: DictionaryObject;
  orientation: DictionaryObject;
  etatdelaCharpente: DictionaryObject;
  elagageaPrevoir: DictionaryObject;
  puissanceSouscrite: DictionaryObject;
  portanceWifi: DictionaryObject;
  emplacementTableauElec: DictionaryObject;
  typeCompteur: DictionaryObject;
  typologie: DictionaryObject;
  accessibilityMax: DictionaryObject;
  trancheeaPrevoir: DictionaryObject;
  statutVt: DictionaryObject;
  presencedeFourreauxExterieurs: DictionaryObject;
  statutRapportVT: DictionaryObject;
  resultatVTStatus: DictionaryObject;
  typeAlimentation: DictionaryObject;
  planDisponible: DictionaryObject;
  emplacementCompteur: DictionaryObject;
  VTNonRealisable: DictionaryObject;
  civility: DictionaryObject;
  presencedeCheminee: DictionaryObject;
  presencedeVelux: DictionaryObject;
  presencedeChienAssis: DictionaryObject;
  typeEcsExistant: DictionaryObject;
  cetExistingLocation: DictionaryObject;
  installationZone: DictionaryObject;
  cetUsage: DictionaryObject;
  cetFutureLocation: DictionaryObject;
  cetInstallationType: DictionaryObject;
  changePressionReducer: DictionaryObject;
  changePumpLift: DictionaryObject;
  typedeTerrain: DictionaryObject;
  typeDOutilPourTranchee: DictionaryObject;
  bacsALesteraPrevoir: DictionaryObject;
  renforcementCharpenteaPrevoir: DictionaryObject;
  echelleaPrevoir: DictionaryObject;
  carrotageaPrevoir: DictionaryObject;
  deportDuBallon: DictionaryObject;
  pompeDeRelevageNecessaire: DictionaryObject;
  dalleBetonneeNecessaire: DictionaryObject;
  commentairesAutresFraisSupCET: DictionaryObject;
  typeDeChauffageExistant: DictionaryObject;
  fonctionChauffageECSintegree: DictionaryObject;
  emplacementactueldusystemedechauffage: DictionaryObject;
  typeDeReseauExistants: DictionaryObject;
  emmitterType: DictionaryObject;
  etatreseauradiateurs: DictionaryObject;
  radiateursVetustesFonctionnels: DictionaryObject;
  etatreseauplancherchauffant: DictionaryObject;
  pcVetuste: DictionaryObject;
  nombredezonedechauffage: DictionaryObject;
  reducteurdepression: DictionaryObject;
  desembouageNecessaire: DictionaryObject;
  ballonPrevu: DictionaryObject;
  ajoutIsolantLiaisonHydrauliqueChaufferie: DictionaryObject;
  necessiteKitBiZone: DictionaryObject;
  necessiteAjoutGaz: DictionaryObject;
  dimensionnementinitialcorrect: DictionaryObject;
  impactMasqueSolaire: DictionaryObject;
  typeDeRaccordement: DictionaryObject;
  alimentation: DictionaryObject;
  roofInclinaison: DictionaryObject;
  tvaCode: DictionaryObject;
  aideType: DictionaryObject;
  aideTypeDev: DictionaryObject;
  occupationType: DictionaryObject;
  wallType: DictionaryObject;
  isolatedWalls: DictionaryObject;
  glazingType: DictionaryObject;
  atticType: DictionaryObject;
  ventilationType: DictionaryObject;
  basementType: DictionaryObject;
  garageAttachedToTheHouse: DictionaryObject;
  garageConfiguration: DictionaryObject;
  productTypeCode: DictionaryObject;
  typeChauffage: DictionaryObject;
  sourceEnergie: DictionaryObject;
  boilertype: DictionaryObject;
  studyType: DictionaryObject;
  operationType: DictionaryObject;
  floorNumber: DictionaryObject;
  stockageCapacity: DictionaryObject;
}

const dictionary: Dictionary = {
  // PV section
  implantationduPan: {
    629950000: "Bâtiment principal",
    629950001: "Bâtiment secondaire",
    629950002: "Dépendance",
    629950003: "Pergola",
    629950004: "Jardin",
    629950005: "Toiture terrasse / Toiture plate",
  },
  accessibiliteDesComnbles: {
    0: "Non",
    1: "Oui",
  },
  // TO CHANGE WITH CRM NUMBERS
  typeDaccesDesCombles: {
    629950000: "Échelle ",
    629950001: "Éscaliers",
    629950002: "Trappe",
  },
  nacelleaPrevoir: {
    0: "Non",
    1: "Oui",
  },
  natureDuSupport: {
    629950000: "Tuiles mécaniques",
    629950001: "Ardoise",
    629950002: "Tuiles canal",
    629950003: "Tôle à joint debout",
    629950004: "Fibrociment",
    629950005: "Tôle trapézoïdale",
    629950006: "Tôle ondulée",
    629950007: "Tuiles plates",
    629950008: "Revêtement asphalté / goudron",
    629950009: "Tôle bac acier",
    629950010: "Lauze",
    629950011: "Chaume",
  },
  typedeSupport: {
    629950000: "Au sol",
    629950001: "Surimposition",
    629950002: "Intégration au bâtît",
  },
  masqueSolaires: {
    629950000: "Arbre(s)",
    629950001: "Bâtiment(s)/ maison(s)",
    629950002: "Topographie",
    629950003: "Aucun",
  },
  orientation: {
    629950000: "Sud",
    629950001: "Sud-Est",
    629950002: "Est",
    629950003: "Sud-Ouest",
    629950004: "Ouest",
    629950005: "Nord-Ouest",
    629950006: "Nord-Est",
    629950007: "Nord",
  },
  etatdelaCharpente: {
    629950000: "Bon",
    629950001: "Moyen",
    629950002: "Vétuste",
  },
  elagageaPrevoir: {
    0: "Non",
    1: "Oui",
  },
  puissanceSouscrite: {
    629950000: "6kVA",
    629950001: "9kVA",
    629950002: "12kVA",
    629950003: "18kVA",
    629950004: "15kVA",
    629950005: "24kVA",
    629950006: "30kVA",
    629950007: "36kVA",
  },
  portanceWifi: {
    629950000: "Suffisant",
    629950001: "Insuffisant",
  },
  emplacementTableauElec: {
    629950000: "Intérieur",
    629950001: "Mur de clôture",
  },
  typeCompteur: {
    629950000: "Linky",
    629950001: "Mécanique",
    629950002: "Analogique",
  },
  typologie: {
    629950000: "T1",
    629950001: "T2",
    629950002: "T3",
    629950003: "T4",
    629950004: "T5",
    629950005: "T6",
    629950006: ">T6",
  },
  accessibilityMax: {
    629950000: "Voiture",
    629950001: "Utilitaire",
    629950002: "Semi-remorque",
  },
  trancheeaPrevoir: {
    0: "Non",
    1: "Oui",
  },
  statutVt: {
    629950000: "Réalisée",
    629950001: "À planifier",
    629950002: "Planifiée",
    629950003: "En cours",
    629950004: "Annulée",
  },
  presencedeFourreauxExterieurs: {
    0: "Non",
    1: "Oui",
  },
  statutRapportVT: {
    629950000: "En cours",
    629950001: "Envoyé",
    629950002: "Client absent au RDV",
    629950003: "Problème d'accès",
    629950004: "Je n'ai pas pu me rendre au RDV",
    629950005: "Autre",
    629950006: "Envoyé - à contrôler",
  },
  resultatVTStatus: {
    629950000: "Validé",
    629950001: "Commande à modifier",
    629950002: "KO",
    629950003: "Commande modifiée",
  },
  typeAlimentation: {
    629950000: "Monophasé",
    629950001: "Triphasé",
  },
  planDisponible: {
    0: "Non",
    1: "Oui",
  },
  emplacementCompteur: {
    629950000: "Intérieur",
    629950001: "Mur de clôture",
    629950002: "Sur la façade de la maison",
  },
  VTNonRealisable: {
    629950000: "Client absent au RDV",
    629950001: "Problème d'accès",
    629950002: "Je n'ai pas pu me rendre au RDV",
    629950003: "Autre",
  },
  civility: {
    629950000: "Monsieur",
    629950001: "Madame",
  },
  presencedeCheminee: {
    0: "Non",
    1: "Oui",
  },
  presencedeVelux: {
    0: "Non",
    1: "Oui",
  },
  presencedeChienAssis: {
    0: "Non",
    1: "Oui",
  },

  //------------------
  //
  // CET section
  typeEcsExistant: {
    629950000: "Fioul",
    629950001: "Gaz",
    629950002: "Electrique",
    629950003: "Autre",
  },
  cetExistingLocation: {
    629950000: "Garage",
    629950001: "Buanderie",
    629950002: "Sous-sol",
    629950003: "Autre",
  },
  installationZone: {
    629950000: "Zone chauffée",
    629950001: "Zone non chauffée",
    629950002: "Extérieur",
  },
  cetUsage: {
    629950000: "Économique",
    629950001: "Modéré",
    629950002: "Intense",
  },
  cetFutureLocation: {
    629950000: "Identique à l’existant",
    629950001: "Différent",
  },
  cetInstallationType: {
    629950000: "Sur air ambiant (si > 20m3)",
    629950001: "Sur air extrait (gainé si < à 20m3) ",
    629950002: "Sur air extérieur (groupe ext.)",
  },
  changePressionReducer: {
    0: "Non",
    1: "Oui",
  },
  changePumpLift: {
    0: "Non",
    1: "Oui",
  },
  typedeTerrain: {
    629950002: "Terre",
    629950001: "Remblais",
    629950000: "Béton/Goudron",
  },
  typeDOutilPourTranchee: {
    629950000: "Minipelle",
    629950001: "Trancheuse",
  },
  bacsALesteraPrevoir: {
    0: "Non",
    1: "Oui",
  },
  renforcementCharpenteaPrevoir: {
    0: "Non",
    1: "Oui",
  },
  echelleaPrevoir: {
    0: "Non",
    1: "Oui",
  },
  carrotageaPrevoir: {
    0: "Non",
    1: "Oui",
  },
  deportDuBallon: {
    0: "Non",
    1: "Oui",
  },
  pompeDeRelevageNecessaire: {
    0: "Non",
    1: "Oui",
  },
  dalleBetonneeNecessaire: {
    0: "Non",
    1: "Oui",
  },
  commentairesAutresFraisSupCET: {
    0: "Non",
    1: "Oui",
  },

  typeDeChauffageExistant: {
    629950000: "Fioul",
    629950001: "Gaz",
    629950002: "Biomasse",
    629950003: "Electrique",
    629950004: "PAC",
    629950005: "Autres",
  },
  fonctionChauffageECSintegree: {
    0: "Non",
    1: "Oui",
  },
  // A FAIRE : Demander à YONG si les values sont correctes
  emplacementactueldusystemedechauffage: {
    629950000: "Bâtiment principal",
    629950001: "Bâtiment secondaire",
    629950002: "Dépendance",
    629950003: "Autres",
  },
  typeDeReseauExistants: {
    629950000: "Radiateurs",
    629950001: "Plancher chauffant",
    629950002: "Ventilo convecteur",
    629950003: "Monotube",
  },
  emmitterType: {
    629950000: "Fonte",
    629950001: "Aluminium",
    629950002: "Acier",
  },
  etatreseauradiateurs: {
    629950000: "Bon",
    629950001: "Moyen",
    629950002: "Vétuste",
  },
  radiateursVetustesFonctionnels: {
    0: "Non",
    1: "Oui",
  },
  etatreseauplancherchauffant: {
    629950000: "Bon",
    629950001: "Moyen",
    629950002: "Vétuste",
  },
  pcVetuste: {
    0: "Non",
    1: "Oui",
  },
  nombredezonedechauffage: {
    629950000: "1",
    629950001: "2",
    629950002: "3",
    629950003: "4",
  },
  reducteurdepression: {
    0: "Non",
    1: "Oui",
  },
  desembouageNecessaire: {
    0: "Non",
    1: "Oui",
  },
  ballonPrevu: {
    0: "Non",
    1: "Oui",
  },
  ajoutIsolantLiaisonHydrauliqueChaufferie: {
    0: "Non",
    1: "Oui",
  },
  necessiteKitBiZone: {
    0: "Non",
    1: "Oui",
  },
  necessiteAjoutGaz: {
    0: "Non",
    1: "Oui",
  },
  dimensionnementinitialcorrect: {
    0: "Non",
    1: "Oui",
  },
  impactMasqueSolaire: {
    629950000: "0",
    629950001: "7.5",
    629950002: "15",
    629950003: "25",
  },
  typeDeRaccordement: {
    0: "Autoconsommation",
    1: "Autoconsommation avec vente du surplus",
    2: "Revente totale",
  },
  alimentation: {
    0: "Triphasé",
    1: "Monophasé",
  },
  roofInclinaison: {
    629950000: "0",
    629950013: "1",
    629950016: "2",
    629950017: "3",
    629950018: "4",
    629950001: "5",
    629950019: "6",
    629950020: "7",
    629950021: "8",
    629950022: "9",
    629950002: "10",
    629950023: "11",
    629950024: "12",
    629950025: "13",
    629950026: "14",
    629950005: "15",
    629950027: "16",
    629950028: "17",
    629950029: "18",
    629950030: "19",
    629950004: "20",
    629950031: "21",
    629950032: "22",
    629950033: "23",
    629950034: "24",
    629950003: "25",
    629950035: "26",
    629950036: "27",
    629950037: "28",
    629950038: "29",
    629950006: "30",
    629950039: "31",
    629950040: "32",
    629950041: "33",
    629950042: "34",
    629950007: "35",
    629950043: "36",
    629950044: "37",
    629950045: "38",
    629950046: "39",
    629950008: "40",
    629950047: "41",
    629950048: "42",
    629950049: "43",
    629950050: "44",
    629950009: "45",
    629950051: "46",
    629950052: "47",
    629950053: "48",
    629950054: "49",
    629950010: "50",
    629950055: "51",
    629950056: "52",
    629950057: "53",
    629950058: "54",
    629950011: "55",
    629950059: "56",
    629950060: "57",
    629950061: "58",
    629950062: "59",
    629950014: "60",
    629950063: "61",
    629950064: "62",
    629950065: "63",
    629950066: "64",
    629950012: "65",
    629950067: "66",
    629950068: "67",
    629950069: "68",
    629950070: "69",
    629950015: "70",
    629950071: "71",
    629950072: "72",
    629950073: "73",
    629950074: "74",
    629950075: "75",
    629950076: "76",
    629950077: "77",
    629950078: "78",
    629950079: "79",
    629950080: "80",
    629950081: "81",
    629950082: "82",
    629950083: "83",
    629950084: "84",
    629950085: "85",
  },
  tvaCode: {
    629950000: "5.5",
    629950001: "10",
    629950002: "20",
  },

  aideTypeDev: {
    "460a5237-2f2a-ee11-bdf4-0022487fe6d0": "CEE BAR-EN-101",
    "87302e54-302a-ee11-bdf4-0022487fe6d0": "CEE BAR-EN-102",
    "20103b31-312a-ee11-bdf4-0022487fe6d0": "CEE BAR-EN-103",
    "4f03dc1c-513e-ec11-8c63-6045bd8ed6df": "CEE BAR-TH-104",
    "8d13fb76-79e5-ec11-bb3c-00224881a01f": "CEE BAR-TH-113",
    "d7529f64-412d-ee11-bdf4-0022487fea0c": "CEE BAR-TH-125",
    "68713cd9-4e3e-ec11-8c63-6045bd8ed6df": "CEE BAR-TH-129",
    "b0e9f464-fa8a-eb11-b1ac-000d3a2e77a9": "CEE BAR-TH-148",
    "675c3edc-07b1-ec11-9840-000d3a471a84": "CEE CDP BAR-TH-104",
    "9262709a-b0e5-ec11-bb3c-00224881a01f": "CEE CDP BAR-TH-113",
    "9de6f334-c041-ea11-a812-000d3aaac1e4": "Chèque Écolo",
    "9a07a01a-2e56-ee11-be6e-0022487fe6d0": "Coup de pouce Réno globale",
    "dc8a7f0e-c60a-ee11-8f6e-0022487fea0c": "MaPrimeRénov' Audit",
    "524b5df2-79e5-ec11-bb3c-00224881a01f": "MaPrimeRénov' CAG",
    "c492af23-08a6-ec11-983f-000d3abb96a2": "MaPrimeRénov' CET",
    "ab3a7ba2-09eb-eb11-bacb-000d3a4afb06": "MaPrimeRénov' ITE",
    "8de5ad81-3b41-ea11-a812-000d3aaac1e4": "MaPrimeRénov' PAC",
    "d72323b8-3b2a-ee11-bdf4-0022487fe6d0": "MaPrimeRénov' Rampants",
    "51e4d03c-082a-ee11-bdf4-0022487fee52": "MaPrimeRenov' VMC",
    "85d5d1bd-8b6e-ea11-a811-000d3aaac1e4": "Prime à l'investissement",
    "53654b63-14d0-ee11-9079-0022487fe6d0":
      "MaPrimeRénov' Rénovation d'ampleur",
    "ec6c939b-d334-ec11-b6e6-6045bd8e4d06": "TVA Déductible",
  },

  aideType: {
    // PAC
    "8de5ad81-3b41-ea11-a812-000d3aaac1e4": "MaPrimeRénov' PAC",
    // PAC Air/eau
    "2a1feab9-8ca5-ee11-be37-000d3ab6f72a": "CEE BAR-TH-171",
    "0fc069ed-95a5-ee11-be37-000d3ab6f4ac": "CEE CDP BAR-TH-171", // value in CRM "CEE CDP BAR-TH-171 Sonergia"

    // PAC air/air
    "c0d60646-334c-ec11-8c62-000d3a4a8731": "CEE BAR-TH-129",

    //ISO & ISOA
    "633228fe-a2ae-ec11-9840-6045bd8980d6": "CEE BAR-EN-101", // value in CRM "CEE BAR-EN-101 Sonergia",

    // ISOA
    "6d01139a-4526-ee11-9965-000d3aa903bd": "MaPrimeRénov' Rampants",

    // ITE
    "e8dbbe79-f4ea-eb11-bacb-000d3a4afb06": "CEE BAR-EN-102", // value in CRM "CEE BAR-EN-102 Sonergia",
    "ab3a7ba2-09eb-eb11-bacb-000d3a4afb06": "MaPrimeRénov' ITE",

    // ISOP
    "955873a2-74af-ec11-9840-6045bd898e4d": "CEE BAR-EN-103", // value in CRM "CEE BAR-EN-103 Sonergia",

    // CET
    "ade6f530-05ee-eb11-bacb-000d3abc2489": "CEE BAR-TH-148", // value in CRM "CEE BAR-TH-148 sonergia",
    "36ae1b84-8db9-ec11-983f-00224882760b": "MaPrimeRénov' CET",

    // Chaudière à granules
    "cd6c8d8a-c1ec-ec11-bb3d-6045bd8c1e2e": "CEE BAR-TH-113",
    "6edf30cb-c2ec-ec11-bb3d-6045bd8c1e2e": "CEE CDP BAR-TH-113", // value in CRM "CEE CDP BAR-TH-113 Sonergia",
    "2cff86c2-c0ec-ec11-bb3d-6045bd8c1e2e": "MaPrimeRénov' CAG",

    // VMC simple fluw
    "ef4734f1-3cd2-ee11-9079-000d3ab6f4ac": "CEE BAR-TH-127",

    // VMC double flux
    "ff46bb95-4a2d-ee11-bdf4-000d3a45bec5": "CEE BAR-TH-125",
    "ca7fff00-4b2d-ee11-bdf4-000d3a45bec5": "MaPrimeRénov' VMC",

    // PV
    "85d5d1bd-8b6e-ea11-a811-000d3aaac1e4": "Prime à l'investissement",

    //MPR Rénovation d'ampleur
    "b338e17e-a3d1-ee11-9079-000d3abd8f0e":
      "MaPrimeRénov' Rénovation d'ampleur",
    "f5b1a6e9-3cd2-ee11-9079-000d3ab6f4ac": "CEE BAR-TH-174",
  },

  //RG section
  occupationType: {
    629950004: "En cours d’achat",
    629950000: "Propriétaire occupant",
    629950001: "Propriétaire d'une résidence secondaire",
    629950002: "Propriétaire bailleur",
    629950003: "Locataire",
  },

  wallType: {
    629950000: "Pierre",
    629950001: "Béton",
    629950002: "Brique",
    629950003: "Bois",
  },

  isolatedWalls: {
    629950000: "Isolés",
    629950001: "A isoler",
    629950002: "Je ne sais pas",
  },

  glazingType: {
    629950000: "simple",
    629950001: "double",
    629950002: "triple",
  },

  atticType: {
    629950000: "amenages",
    629950001: "perdus",
    629950002: "pasDeCombles",
  },

  ventilationType: {
    629950000: "naturelle",
    629950001: "simpleFlux",
    629950002: "doubleFlux",
  },

  basementType: {
    629950001: "terrePlein",
    629950002: "videSanitaire",
    629950000: "caveOuSousSol",
    629950004: "garage",
  },

  garageAttachedToTheHouse: {
    0: "Non",
    1: "Oui",
  },

  garageConfiguration: {
    629950000: "Garage accolé",
    629950001: "Garage intérieur",
    629950002: "Garage indépendant",
  },

  productTypeCode: {
    1: "Chauffe - eau thermodynamique",
    2: "Chauffe - eau électrique",
    3: "Module Solaire",
    4: "Forfait Installation",
    629950000: "Pompe à chaleur Air/Air",
    629950001: "Pompe à chaleur Air/Eau",
    629950002: "Domotique",
    629950003: "Isolation",
    629950004: "Borne de recharge",
    629950005: "Onduleur",
    629950006: "Optimiseur",
    629950007: "LED",
    629950008: "Batterie de stockage",
    629950009: "Centrale PV",
    629950010: "ECS Intégrée",
    629950011: "Système de fixation en surimposition",
    629950012: "Boitier connecté",
    629950013: "Chaudière",
  },

  sourceEnergie: {
    629950000: "Electricité",
    629950001: "Gaz Naturel",
    629950002: "GPL",
    629950003: "Fioul",
    629950004: "Bois",
  },

  typeChauffage: {
    629950002: "Radiateur électrique à inertie",
    629950004: "pacAA",
    629950005: "pacAE",
    629950006: "Pompe à chaleur Eau/Eau",
    629950000: "radiateur",
    629950001: "plancherRayonnant",
    629950003: "chaudiereElectrique",
    629950007: "Pompe à chaleur géothermique",
    629950008: "chauffageSolaire",
  },

  boilertype: {
    629950000: "chauffeEauElectrique",
    629950001: "Chauffe-eau thermodynamique",
    629950002: "moduleEcsIntegre",
    629950003: "ballonSolaire",
  },

  studyType: {
    629950038: "photovoltaique",
    629950047: "renovation-globale",
  },

  operationType: {
    629950000: "ISOA", //"BAR-EN-101 Rampants",
    629950001: "ISO", //"BAR-EN-101 Combles",
    629950003: "BAR-EN-102 ITI",
    629950004: "ITE", //"BAR-EN-102 ITE",
    629950002: "ISOP", //"BAR-EN-103 Plancher bas",
  },

  floorNumber: {
    629950000: "2",
    629950001: "3",
    629950002: "3+",
    629950003: "1",
  },

  stockageCapacity: {
    629950000: "0",
    629950001: "150",
    629950002: "200",
    629950003: "250",
    629950004: "300",
    629950005: "80",
    629950006: "100",
    629950007: "120",
  },
};

export const CRMvalueToLabelDictionnary = (
  questionName: keyof Dictionary,
  value: string | number | null | undefined | (string | number)[]
): string | number | null | undefined | (string | number)[] => {
  if (typeof value === "number") {
    return dictionary?.[questionName]?.[value] ?? value;
  }
  if (typeof value === "string") {
    return dictionary?.[questionName]?.[parseInt(value)] ?? value;
  }
  if (Array.isArray(value)) {
    return value
      .map((elem) => {
        if (typeof elem === "number") {
          return dictionary?.[questionName]?.[elem] ?? elem;
        }
        if (typeof elem === "string") {
          return dictionary?.[questionName]?.[parseInt(elem)] ?? elem;
        }
        return elem;
      })
      ?.toString();
  }
  return value;
};

export const CRMlabelToValueDictionnary = (
  questionName: keyof Dictionary,
  label: string | null | undefined
): number | undefined => {
  const dictionaryKey = dictionary[questionName];

  if (dictionaryKey) {
    for (const [numericValue, valueLabel] of Object.entries(dictionaryKey)) {
      if (valueLabel === label) {
        return parseInt(numericValue);
      }
    }
  }
  return undefined; //entry not found
};

export const aideTypeToCRMaideId = ({
  aideType,
  isDev,
}: {
  aideType: string;
  isDev: boolean;
}) => {
  const dictionaryKey = isDev ? dictionary.aideTypeDev : dictionary.aideType;

  const valueToReturn = Object.keys(dictionaryKey).find(
    (key) => dictionaryKey[key] === aideType
  );

  if (!valueToReturn) return undefined;

  return valueToReturn;
};
