import { Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  containerTitle: {
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
    color: "#525360",
    padding: "2 2 2 0",
    fontFamily: "DMSansMedium",
  },
  page: {
    backgroundColor: "#525360",
    color: "white",
    padding: "2 4",
    borderRadius: 10,
    marginLeft: 5,
    fontSize: 10,
  },
});

interface IProps {
  title: string;
  page?: string;
}

export const PdfTitreProduit = ({ title, page }: IProps) => {
  return (
    <View style={styles.containerTitle}>
      <Text>{title}</Text>
      {page && <Text style={styles.page}>{page}</Text>}
    </View>
  );
};
