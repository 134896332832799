import "./authenticationWindow.scss";

// Libraries import
import React, { useState } from "react";

// Components import
import { AuthenticationWelcome } from "@web/shared/dist/containers/AuthenticationWelcome/AuthenticationWelcome";
import { AuthenticationCredentials } from "../AuthenticationCredentials/AuthenticationCredentials";
import { ForgotPassword } from "../ForgotPassword/ForgotPassword";

export const AuthenticationWindow = () => {
  const [isForgotPasswordClicked, setIsForgotPasswordClicked] =
    useState<boolean>(false);

  return (
    <div className="authentication-window">
      <AuthenticationWelcome
        title="Mon espace ÉTUDE"
        subTitle="Suivez facilement vos projets de travaux de rénovation énergétique"
      />
      {!isForgotPasswordClicked ? (
        <>
          <AuthenticationCredentials
            isForgotPasswordClicked={isForgotPasswordClicked}
            setIsForgotPasswordClicked={setIsForgotPasswordClicked}
          />
        </>
      ) : (
        <ForgotPassword
          isForgotPasswordClicked={isForgotPasswordClicked}
          setIsForgotPasswordClicked={setIsForgotPasswordClicked}
        />
      )}
    </div>
  );
};
