//import style
import "./performanceComponent.scss";

//Picto import
import picto_conso from "../../../assets/images/picto_conso.svg";
import picto_production from "../../../assets/images/picto_production.svg";

//Services import
import { useEffect } from "react";
import { useAppSelector } from "../../../redux/store/hook";
import { useGetPerformance } from "../../../services/OptionChosen/useGetPerformance";

//Composant import
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { Layout } from "../../../components/Layout/Layout";
import { ChartComponent } from "../../../components/PerformanceComponents/ChartComponent/ChartComponent";
import { ConsumptionComponent } from "../../../components/PerformanceComponents/ConsumptionComponent/ConsumptionComponent";
import { IndependenceLevelComponent } from "../../../components/PerformanceComponents/IndependenceLevelComponent/IndependenceLevelComponent";
import { SelfConsumptionComponent } from "../../../components/PerformanceComponents/SelfConsumptionComponent/SelfConsumptionComponent";
import { SelfSufficiencyComponent } from "../../../components/PerformanceComponents/SelfSufficiencyComponent/SelfSufficiencyComponent";

// Local interface declaration
interface Props {
  building_id?: string | null;
  household_id: string | null;
}

export const PerformanceComponent = ({ building_id, household_id }: Props) => {
  const { monInstallation, userAuthInfo, studyInformation, scenarioChosen } =
    useAppSelector((state) => state);

  const { errorMessage, getPerformance, isLoading } = useGetPerformance();

  useEffect(() => {
    if (
      monInstallation.isPersonalizedScenario ||
      (!monInstallation.isPersonalizedScenario &&
        monInstallation.indexOptionChosen !==
          monInstallation.performance?.indexScenarioChosen)
    ) {
      getPerformance({
        userToken: userAuthInfo.userToken,
        number_of_panels: scenarioChosen.number_of_panels || 0,
        building_id: building_id,
        household_id: household_id,
        kit_home_management: scenarioChosen.kit_home_management || false,
        number_of_batteries: scenarioChosen.number_of_batteries || 0,
        indexScenarioChosen: monInstallation.indexOptionChosen,
        study_id: studyInformation?.study_id || "",
        inverter_brand: scenarioChosen.inverter_brand || null,
        single_battery_capacity: scenarioChosen.single_battery_capacity || -1,
        panel_indices: scenarioChosen.calepinage_chosen_indexes || null,
        extra_fees: scenarioChosen?.extra_fees,
      });
      return;
    }
    // eslint-disable-next-line
  }, []);

  const dataForChartComponent = {
    dataForChart:
      monInstallation?.performance?.hourly_consumption_production || null,
    batteryQuantity: scenarioChosen?.number_of_batteries || null,
    chosenScenario: scenarioChosen,
  };

  const dataForIndependenceLevelComponent = {
    selfproductionRate:
      monInstallation?.performance?.selfproduction_rate || null,
    solarProductionConsumed:
      monInstallation?.performance?.solar_production_consumed_rate || null,
    solarProductionStored:
      monInstallation?.performance?.solar_production_stored_rate || null,
    selfconsumptionRate:
      monInstallation?.performance?.selfconsumption_rate || null,
  };

  const dataForSelfSufficiencyComponent = {
    dataChart: monInstallation?.performance?.daily_selfconsumption || null,
    daysOfAutosufficiency:
      monInstallation?.performance?.days_of_autosufficiency || null,
  };

  const dataForSelfConsumptionComponent = {
    selfconsumptionRate:
      monInstallation?.performance?.selfconsumption_rate || null,
    selfproductionRate:
      monInstallation?.performance?.selfproduction_rate || null,
  };

  const dataForConsumptionComponent = {
    annualConsumption:
      monInstallation?.performance?.annual_electric_consumption_kWh ??
      undefined,
    annualProduction:
      monInstallation?.performance?.annual_electric_production_kWh ?? undefined,
    solarConsumption:
      monInstallation?.performance?.solar_consumption ?? undefined,
    networkConsumption:
      monInstallation?.performance?.network_consumption ?? undefined,
    productionWhithoutShadowCasting:
      monInstallation?.performance
        ?.annual_electric_production_without_shadow_casting_kWh ?? undefined,
  };
  return (
    <div className="PerformanceComponent">
      <Layout>
        {isLoading ? (
          <div className="ProfitabilityComponent__LoadingContainer">
            <LoadingComponent diameter={60} />
          </div>
        ) : errorMessage ? (
          <ErrorMessage errorMessage={errorMessage} />
        ) : (
          <>
            <div
              className={
                scenarioChosen.formule_name === "Sérénité" ||
                scenarioChosen.formule_name === "Liberté"
                  ? "PerformanceComponent__TopSection"
                  : "PerformanceComponent__TopSection PerformanceComponent__TopSection__Column"
              }
            >
              <ChartComponent
                dataChart={dataForChartComponent.dataForChart}
                chosenScenario={dataForChartComponent.chosenScenario}
                batteryQuantity={dataForChartComponent.batteryQuantity}
              />
              <IndependenceLevelComponent
                title={
                  scenarioChosen.formule_name === "Liberté"
                    ? "Taux d'autoproduction"
                    : scenarioChosen.formule_name === "Sérénité"
                    ? "Taux d'autoconsommation"
                    : null
                }
                autoprod={
                  scenarioChosen.formule_name === "Liberté" ? true : false
                }
                color={
                  scenarioChosen.formule_name === "Sérénité"
                    ? "#0073E0"
                    : "#752BAF"
                }
                legende={
                  scenarioChosen.formule_name === "Liberté" ? true : false
                }
                description={
                  scenarioChosen.formule_name === "Liberté" ? (
                    <>
                      <strong>
                        {dataForIndependenceLevelComponent.selfproductionRate ===
                          1 &&
                        dataForSelfSufficiencyComponent.daysOfAutosufficiency &&
                        dataForSelfSufficiencyComponent.daysOfAutosufficiency <
                          365
                          ? 0.99 * 100
                          : dataForIndependenceLevelComponent.selfproductionRate &&
                            Math.round(
                              dataForIndependenceLevelComponent.selfproductionRate *
                                100 *
                                100
                            ) / 100}
                        %
                      </strong>{" "}
                      de votre consommation est couverte par votre production
                      solaire. Le reste de votre consommation (
                      {dataForIndependenceLevelComponent.selfproductionRate ===
                        1 &&
                      dataForSelfSufficiencyComponent.daysOfAutosufficiency &&
                      dataForSelfSufficiencyComponent.daysOfAutosufficiency <
                        365
                        ? 100 - 0.99 * 100
                        : dataForIndependenceLevelComponent.selfproductionRate &&
                          Math.round(
                            (100 -
                              dataForIndependenceLevelComponent.selfproductionRate *
                                100) *
                              100
                          ) / 100}
                      %) utilise l'électricité du réseau électrique.
                    </>
                  ) : scenarioChosen.formule_name === "Sérénité" ? (
                    <>
                      <strong>
                        {(
                          dataForIndependenceLevelComponent.selfconsumptionRate &&
                          Math.round(
                            dataForIndependenceLevelComponent.selfconsumptionRate *
                              100
                          )
                        )?.toFixed()}
                        %
                      </strong>{" "}
                      de votre production solaire est utilisée pour votre propre
                      consommation électrique. Le reste de votre production
                      {" ("}
                      {dataForIndependenceLevelComponent.selfconsumptionRate &&
                        Math.round(
                          100 -
                            dataForIndependenceLevelComponent.selfconsumptionRate *
                              100
                        ).toFixed()}
                      {"%) "}
                      est injecté dans le réseau électrique.
                    </>
                  ) : (
                    <></>
                  )
                }
                selfproductionRate={
                  scenarioChosen.formule_name === "Sérénité"
                    ? dataForIndependenceLevelComponent.selfconsumptionRate
                    : dataForIndependenceLevelComponent.selfproductionRate ===
                        1 &&
                      dataForSelfSufficiencyComponent.daysOfAutosufficiency &&
                      dataForSelfSufficiencyComponent.daysOfAutosufficiency <
                        365
                    ? 0.99
                    : dataForIndependenceLevelComponent.selfproductionRate
                }
                solarProductionConsumed={
                  dataForIndependenceLevelComponent.solarProductionConsumed
                }
                solarProductionStored={
                  dataForIndependenceLevelComponent.solarProductionStored
                }
              />
              {scenarioChosen.formule_name === "Essentielle" ? (
                <div className="PerformanceComponent__EssentiellePourcentage">
                  <IndependenceLevelComponent
                    title={"Taux d'autoconsommation"}
                    autoprod={false}
                    color={"#0073E0"}
                    legende={false}
                    description={
                      <>
                        <strong>
                          {dataForIndependenceLevelComponent.selfconsumptionRate &&
                            dataForIndependenceLevelComponent.selfconsumptionRate *
                              100}
                          %
                        </strong>{" "}
                        de votre production solaire est utilisée pour votre
                        propre consommation électrique. Le reste de votre
                        production{" "}
                        {dataForIndependenceLevelComponent.selfconsumptionRate
                          ? `(${Math.round(
                              100 -
                                dataForIndependenceLevelComponent.selfconsumptionRate *
                                  100
                            )}%)`
                          : ""}{" "}
                        est injecté dans le réseau électrique.
                      </>
                    }
                    selfproductionRate={
                      dataForIndependenceLevelComponent.selfconsumptionRate
                    }
                    solarProductionConsumed={
                      dataForIndependenceLevelComponent.solarProductionConsumed
                    }
                    solarProductionStored={
                      dataForIndependenceLevelComponent.solarProductionStored
                    }
                  />
                  <IndependenceLevelComponent
                    title={"Taux d’autoproduction"}
                    autoprod={true}
                    color={"#752BAF"}
                    description={
                      <>
                        <strong>
                          {dataForIndependenceLevelComponent.selfproductionRate &&
                            dataForIndependenceLevelComponent.selfproductionRate *
                              100}
                          %
                        </strong>{" "}
                        de votre consommation est couverte par votre production
                        solaire. Le reste de votre consommation
                        {dataForIndependenceLevelComponent.selfproductionRate
                          ? ` (${Math.round(
                              100 -
                                dataForIndependenceLevelComponent.selfproductionRate *
                                  100
                            )}%)`
                          : ""}{" "}
                        utilise l'électricité du réseau électrique.
                      </>
                    }
                    selfproductionRate={
                      dataForIndependenceLevelComponent.selfproductionRate
                    }
                    solarProductionConsumed={
                      dataForIndependenceLevelComponent.solarProductionConsumed
                    }
                    solarProductionStored={
                      dataForIndependenceLevelComponent.solarProductionStored
                    }
                  />
                </div>
              ) : scenarioChosen.formule_name !== "Liberté" &&
                scenarioChosen.formule_name !== "Sérénité" ? (
                <div className="PerformanceComponent__EssentiellePourcentage">
                  <IndependenceLevelComponent
                    title={"Taux d'autoconsommation"}
                    autoprod={false}
                    color={"#0073E0"}
                    description={
                      <>
                        <strong>
                          {dataForIndependenceLevelComponent.selfconsumptionRate &&
                            Math.round(
                              dataForIndependenceLevelComponent.selfconsumptionRate *
                                100
                            )}
                          %
                        </strong>{" "}
                        de votre production solaire est utilisée pour votre
                        propre consommation électrique. Le reste de votre
                        production{" "}
                        {dataForIndependenceLevelComponent.selfconsumptionRate
                          ? `(${Math.round(
                              100 -
                                dataForIndependenceLevelComponent.selfconsumptionRate *
                                  100
                            )}%)`
                          : ""}{" "}
                        est injecté dans le réseau électrique.
                      </>
                    }
                    selfproductionRate={
                      dataForIndependenceLevelComponent.selfconsumptionRate
                    }
                  />
                  <IndependenceLevelComponent
                    title={"Taux d’autoproduction"}
                    autoprod={true}
                    color={"#752BAF"}
                    description={
                      <>
                        <strong>
                          {dataForIndependenceLevelComponent.selfproductionRate &&
                            Math.round(
                              dataForIndependenceLevelComponent.selfproductionRate *
                                100 *
                                100
                            ) / 100}
                          %
                        </strong>{" "}
                        de votre consommation est couverte par votre production
                        solaire. Le reste de votre consommation{" "}
                        {dataForIndependenceLevelComponent.selfproductionRate
                          ? `(${Math.round(
                              100 -
                                dataForIndependenceLevelComponent.selfproductionRate *
                                  100
                            )}%)`
                          : ""}{" "}
                        utilise l'électricité du réseau électrique.
                      </>
                    }
                    selfproductionRate={
                      dataForIndependenceLevelComponent.selfproductionRate
                    }
                  />
                </div>
              ) : null}
            </div>

            {scenarioChosen.formule_name === "Liberté" && (
              <div className="PerformanceComponent__AutosiffisanceSection">
                <SelfSufficiencyComponent
                  dataChart={dataForSelfSufficiencyComponent.dataChart}
                  daysOfAutosufficiency={
                    dataForSelfSufficiencyComponent.daysOfAutosufficiency
                  }
                />
              </div>
            )}
            <div className="PerformanceComponent__BottomSection">
              {scenarioChosen.formule_name === "Liberté+" ? (
                <ConsumptionComponent
                  picto={picto_conso}
                  mainInfos={[
                    {
                      title: "Consommation annuelle du logement :",
                      description: "Besoins de votre logement en électricité.",
                      number:
                        dataForConsumptionComponent.annualConsumption &&
                        dataForConsumptionComponent.annualConsumption,
                    },
                    {
                      title: "Production solaire annuelle :",
                      description:
                        "Électricité produite annuellement grâce à votre installation.",
                      number:
                        dataForConsumptionComponent.annualProduction &&
                        dataForConsumptionComponent.annualProduction,
                      productionWhithoutShadowCasting:
                        dataForConsumptionComponent.productionWhithoutShadowCasting,
                    },
                  ]}
                  secondaryInfos={[
                    {
                      title: "Consommation solaire : ",
                      description:
                        "Besoins de votre logement en électricité couvert par votre installation.",
                      number:
                        dataForConsumptionComponent.solarConsumption &&
                        dataForConsumptionComponent.solarConsumption,
                    },
                    {
                      title: "Consommation réseau : ",
                      description:
                        "Besoins de votre logement en électricité couvert par le réseau.",
                      number:
                        dataForConsumptionComponent.networkConsumption &&
                        dataForConsumptionComponent.networkConsumption,
                    },
                  ]}
                />
              ) : scenarioChosen.formule_name === "Liberté" ? (
                <ConsumptionComponent
                  picto={picto_conso}
                  mainInfos={[
                    {
                      title: "Consommation annuelle du logement :",
                      description: "Besoins de votre logement en électricité.",
                      number:
                        dataForConsumptionComponent.annualConsumption &&
                        dataForConsumptionComponent.annualConsumption,
                    },
                    {
                      title: "Production solaire annuelle :",
                      description:
                        "Électricité produite annuellement grâce à votre installation.",
                      number:
                        dataForConsumptionComponent.annualProduction &&
                        dataForConsumptionComponent.annualProduction,
                      productionWhithoutShadowCasting:
                        dataForConsumptionComponent.productionWhithoutShadowCasting,
                    },
                  ]}
                  secondaryInfos={[
                    {
                      title: "Consommation solaire : ",
                      description:
                        "Besoin de votre logement en électricité couvert par votre installation.",
                      number:
                        dataForConsumptionComponent.solarConsumption &&
                        dataForConsumptionComponent.solarConsumption,
                    },
                    {
                      title: "Consommation réseau : ",
                      description:
                        "Besoins de votre logement en électricité couvert par le réseau.",
                      number:
                        dataForConsumptionComponent.networkConsumption &&
                        dataForConsumptionComponent.networkConsumption,
                    },
                  ]}
                />
              ) : scenarioChosen.formule_name === "Sérénité" ? (
                <ConsumptionComponent
                  picto={picto_production}
                  mainInfos={[
                    {
                      title: "Production solaire annuelle :",
                      description:
                        "Électricité produite annuellement grâce à votre installation.",
                      number:
                        dataForConsumptionComponent.annualProduction &&
                        dataForConsumptionComponent.annualProduction,
                      productionWhithoutShadowCasting:
                        dataForConsumptionComponent.productionWhithoutShadowCasting,
                    },
                  ]}
                  secondaryInfos={[
                    {
                      title: "Autoconsommation :",
                      number:
                        dataForConsumptionComponent.solarConsumption &&
                        dataForConsumptionComponent.solarConsumption,
                    },
                    {
                      title: "Revente : ",
                      number:
                        dataForConsumptionComponent.annualProduction &&
                        dataForConsumptionComponent.solarConsumption &&
                        dataForConsumptionComponent.annualProduction -
                          dataForConsumptionComponent.solarConsumption,
                    },
                  ]}
                />
              ) : scenarioChosen.formule_name === "Essentielle" ? (
                <ConsumptionComponent
                  picto={picto_production}
                  mainInfos={[
                    {
                      title: "Production solaire annuelle :",
                      description:
                        "Électricité produite annuellement grâce à votre installation.",
                      number:
                        dataForConsumptionComponent.annualProduction &&
                        dataForConsumptionComponent.annualProduction,
                      productionWhithoutShadowCasting:
                        dataForConsumptionComponent.productionWhithoutShadowCasting,
                    },
                  ]}
                  secondaryInfos={[
                    {
                      title: "Consommation du logement :",
                      isRight: true,
                      description: "Besoins de votre logement en électricité.",
                      number:
                        dataForConsumptionComponent.annualConsumption &&
                        dataForConsumptionComponent.annualConsumption,
                    },
                    {
                      title: "Consommation solaire :",
                      isRight: true,
                      description:
                        "Besoin de votre logement en électricité couvert par votre installation.",
                      number:
                        dataForConsumptionComponent.solarConsumption &&
                        dataForConsumptionComponent.solarConsumption,
                    },
                    {
                      title: "Consommation réseau :",
                      isRight: false,
                      description:
                        "Besoins de votre logement en électricité couvert par le réseau.",
                      number:
                        dataForConsumptionComponent.networkConsumption &&
                        dataForConsumptionComponent.networkConsumption,
                    },
                  ]}
                />
              ) : (
                <ConsumptionComponent
                  picto={picto_production}
                  mainInfos={[
                    {
                      title: "Production solaire annuelle :",
                      description:
                        "Électricité produite annuellement grâce à votre installation.",
                      number:
                        dataForConsumptionComponent.annualProduction &&
                        dataForConsumptionComponent.annualProduction,
                      productionWhithoutShadowCasting:
                        dataForConsumptionComponent.productionWhithoutShadowCasting,
                    },
                  ]}
                  secondaryInfos={[
                    {
                      title: "Consommation du logement :",
                      description: "Besoins de votre logement en électricité.",
                      isRight: false,
                      number:
                        dataForConsumptionComponent.annualConsumption &&
                        dataForConsumptionComponent.annualConsumption,
                    },
                    {
                      title: "Consommation solaire :",
                      description:
                        "Besoin de votre logement en électricité couvert par votre installation.",
                      isRight: true,
                      number:
                        dataForConsumptionComponent.solarConsumption &&
                        dataForConsumptionComponent.solarConsumption,
                    },
                    {
                      title: "Consommation réseau :",
                      description:
                        "Besoins de votre logement en électricité. couvert par le réseau.",
                      isRight: false,
                      number:
                        dataForConsumptionComponent.networkConsumption &&
                        dataForConsumptionComponent.networkConsumption,
                    },
                  ]}
                />
              )}

              {scenarioChosen.formule_name === "Liberté+" ? (
                <>
                  <SelfConsumptionComponent
                    infoBulle={
                      <>
                        {" "}
                        <div className="ModalitéPopUPText__FirstDivision">
                          <p
                            style={{
                              padding: "3px 0",
                              borderBottom: "1px solid white",
                            }}
                          >
                            Production solaire utilisée
                          </p>
                          <p
                            style={{
                              padding: "3px 0",
                            }}
                          >
                            Production solaire totale
                          </p>
                        </div>
                        <div style={{ padding: "0 5px" }}>
                          <p>=</p>
                        </div>
                        <div className="ModalitéPopUPText__secondDivision">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: " 3px 0",
                            }}
                          >
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#74ddd7",
                                borderRadius: " 8px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              padding: "3px 0",
                              borderTop: "1px solid white",
                            }}
                          >
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#F0A14E",
                                borderRadius: " 8px",
                              }}
                            />
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#74ddd7",
                                borderRadius: " 8px",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                    title="Taux d’autoconsommation"
                    number={
                      dataForSelfConsumptionComponent.selfconsumptionRate &&
                      Math.round(
                        dataForSelfConsumptionComponent.selfconsumptionRate *
                          100
                      )
                    }
                    description={
                      <>
                        <p>
                          de votre production solaire est utilisée pour votre
                          propre consommation électrique. Le reste de votre
                          production{" "}
                          {dataForSelfConsumptionComponent.selfconsumptionRate
                            ? `(${Math.round(
                                100 -
                                  dataForSelfConsumptionComponent.selfconsumptionRate *
                                    100
                              )}%)`
                            : ""}{" "}
                          est injecté dans le réseau électrique.
                        </p>
                      </>
                    }
                  />
                </>
              ) : scenarioChosen.formule_name === "Liberté" ? (
                <>
                  <SelfConsumptionComponent
                    infoBulle={
                      <>
                        {" "}
                        <div className="ModalitéPopUPText__FirstDivision">
                          <p
                            style={{
                              padding: "3px 0",
                              borderBottom: "1px solid white",
                            }}
                          >
                            Production solaire utilisée
                          </p>
                          <p
                            style={{
                              padding: "3px 0",
                            }}
                          >
                            Production solaire totale
                          </p>
                        </div>
                        <div style={{ padding: "0 5px" }}>
                          <p>=</p>
                        </div>
                        <div className="ModalitéPopUPText__secondDivision">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: " 3px 0",
                            }}
                          >
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#74ddd7",
                                borderRadius: " 8px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              padding: "3px 0",
                              borderTop: "1px solid white",
                            }}
                          >
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#F0A14E",
                                borderRadius: " 8px",
                              }}
                            />
                            <div
                              style={{
                                width: "14px",
                                height: " 14px",
                                background: "#74ddd7",
                                borderRadius: " 8px",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                    title="Taux d’autoconsommation"
                    number={
                      dataForSelfConsumptionComponent.selfconsumptionRate &&
                      Math.round(
                        dataForSelfConsumptionComponent.selfconsumptionRate *
                          100
                      )
                    }
                    description={
                      <>
                        <p>
                          de votre production solaire est utilisée pour votre
                          propre consommation électrique. Le reste de votre
                          production{" "}
                          {dataForSelfConsumptionComponent.selfconsumptionRate
                            ? `(${Math.round(
                                100 -
                                  dataForSelfConsumptionComponent.selfconsumptionRate *
                                    100
                              )}%)`
                            : ""}{" "}
                          est injecté dans le réseau électrique.
                        </p>
                      </>
                    }
                  />
                </>
              ) : scenarioChosen.formule_name === "Sérénité" ? (
                <SelfConsumptionComponent
                  infoBulle={
                    <>
                      {" "}
                      <div className="ModalitéPopUPText__FirstDivision">
                        <p
                          style={{
                            padding: "3px 0",
                            borderBottom: "1px solid white",
                          }}
                        >
                          Production solaire utilisée
                        </p>
                        <p
                          style={{
                            padding: "3px 0",
                          }}
                        >
                          Consommation électrique totale
                        </p>
                      </div>
                      <div style={{ padding: "0 5px" }}>
                        <p>=</p>
                      </div>
                      <div className="ModalitéPopUPText__secondDivision">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: " 3px 0",
                          }}
                        >
                          <div
                            style={{
                              width: "14px",
                              height: " 14px",
                              background: "#74ddd7",
                              borderRadius: " 8px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "3px",
                            padding: "3px 0",
                            borderTop: "1px solid white",
                          }}
                        >
                          <div
                            style={{
                              width: "14px",
                              height: " 14px",
                              background: "#a8aaaf",
                              borderRadius: " 8px",
                            }}
                          />
                          <div
                            style={{
                              width: "14px",
                              height: " 14px",
                              background: "#74ddd7",
                              borderRadius: " 8px",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                  title="Taux d’autoproduction"
                  number={
                    dataForSelfConsumptionComponent.selfproductionRate &&
                    Math.round(
                      dataForSelfConsumptionComponent.selfproductionRate * 100
                    )
                  }
                  description={
                    <>
                      <p>
                        de votre consommation est couverte par votre production
                        solaire. Le reste de votre consommation{" "}
                        {dataForIndependenceLevelComponent.selfproductionRate
                          ? `(${Math.round(
                              100 -
                                dataForIndependenceLevelComponent.selfproductionRate *
                                  100
                            )}%)`
                          : ""}{" "}
                        utilise l'électricité du réseau électrique.
                      </p>
                    </>
                  }
                />
              ) : null}
            </div>
          </>
        )}
      </Layout>
    </div>
  );
};
