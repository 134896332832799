import { Text, Font, View } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../../../assets/pdf/fonts/DMSansBold.ttf";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});

export const PdfDoubleCard = () => {
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        fontSize: 9,
        marginBottom: 5,
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 8,
          backgroundColor: "white",
          border: "2px solid #e9eaeb",
          borderRadius: 10,
          width: "49%",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: "#46d1ca",
            fontFamily: "DMSansBold",
            marginBottom: 10,
          }}
        >
          Économies sur la facture d’électricité
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={{ marginBottom: 10 }}>
            Les économies d’énergie réalisées résultent de la diminution de
            votre dépendance au réseau.
          </Text>
          <Text>
            En consommant votre propre énergie solaire, vous consommez moins
            d’électricité sur le réseau et ainsi réduisez vos factures d’énergie
          </Text>
        </View>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 8,
          backgroundColor: "white",
          border: "2px solid #e9eaeb",
          borderRadius: 10,
          width: "49%",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: "#ed8c19",
            fontFamily: "DMSansBold",
            marginBottom: 5,
          }}
        >
          Revente du surplus
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={{ marginBottom: 10 }}>
            Le prix de revente de vos kWh est fixé au moment de votre
            contractualisation et reste inchangé pendant 20 ans, malgré les
            fluctuations que peuvent subir les prix de l’énergie.
          </Text>
          <Text>
            C’est la Commission de Régulation de l’Énergie (CRE) qui fixe ce
            prix de rachat.
          </Text>
        </View>
      </View>
    </View>
  );
};
