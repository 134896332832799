import React from "react";
import "./MountainsFooter.scss";

// images import
import mountainsBackgroundTablet from "../../assets/images/mountains-background-tablet.svg";
import mountainsBackgroundDesktop from "../../assets/images/mountains-background-desktop.svg";

export const MountainsFooter = () => {
  return (
    <div className="MountainsFooter">
      <img
        className="imageBackground imageBackgroundTablet"
        src={mountainsBackgroundTablet}
        alt="Des belles collines toute vertes qui rappellent les vacances en Auvergne. On pourrait faire des supers belles randonnées dessus et voir de beaux paysages. De cette manière, on pourrait réfléchir à la vie, à notre mode de vie et à notre consommation d'énergie. Ainsi, on réaliserait que c'est très intéressant d'installer des panneaux solaires pour palier à la hausse des prix de l'énergie ! Alors qu'attendez vous, rejoignez nous ! :)"
      />
      <img
        className="imageBackground imageBackgroundDesktop"
        src={mountainsBackgroundDesktop}
        alt="Des belles collines toute vertes qui rappellent les vacances en Auvergne. On pourrait faire des supers belles randonnées dessus et voir de beaux paysages. De cette manière, on pourrait réfléchir à la vie, à notre mode de vie et à notre consommation d'énergie. Ainsi, on réaliserait que c'est très intéressant d'installer des panneaux solaires pour palier à la hausse des prix de l'énergie ! Alors qu'attendez vous, rejoignez nous ! :)"
      />
    </div>
  );
};
