// Libraries import
import { useState } from "react";
import { useAxiosPut } from "../useAxiosPut";

// Local interface declaration

export interface ISaveLogementRequest {
  annee_de_construction?: number | null;
  nombre_de_niveaux?: number | null;
  nombre_de_pieces?: number | null;
  hauteur_sous_plafond?: number | null;
  surface_au_sol?: number | null;
  house_surface?: number | null;
  annee_isolation_murs?: string | null;
  annee_isolation_combles?: string | null;
  annee_isolation_sous_sol?: string | null;
  annee_isolation_garage?: string | null;
  type_de_murs?: (string | null)[];
  type_de_vitrages?: (string | null)[];
  type_de_combles?: (string | null)[];
  type_de_ventilation?: (string | null)[];
  type_de_sous_sol?: (string | null)[];
  type_de_garage?: (string | null)[];
  number_of_occupants?: number | null;
  fiscally_french?: boolean | null;
  occupation_type?: string | null;
  income_color?: string | null;
}

interface Props {
  userToken: string | null;
  study_id?: string | null;
  payload: ISaveLogementRequest;
}

export const useSaveLogement = () => {
  const {
    isLoading,
    errorMessage: axiosErrorMessage,
    axiosPutRequest,
  } = useAxiosPut();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const saveLogement = async ({ userToken, study_id, payload }: Props) => {
    setErrorMessage("");

    const response = await axiosPutRequest(
      `${process.env.REACT_APP_EERO_URL}/oree2/rg/saveLogement/${study_id}`,
      payload,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response && response.status !== 200) {
      setErrorMessage("An error occurred while saving datas");
    }

    return response;
  };

  return {
    saveLogement,
    isLoading,
    errorMessage: axiosErrorMessage || errorMessage,
  };
};
