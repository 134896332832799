import "./TotalGainSummary.scss";

import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { euroShortFormat } from "../../../services/euroFormatter";

interface IProps {
  economies_on_bills: number | undefined;
  total_sale_euros: number | undefined;
  numberOfYears?: string | number | null;
}

export const TotalGainSummary = ({
  economies_on_bills,
  total_sale_euros,
  numberOfYears,
}: IProps) => {
  const pieChartData = [
    {
      name: "economies_on_bills",
      value: economies_on_bills,
      color: "#46D1CA",
    },
    { name: "total_sale_euros", value: total_sale_euros, color: "#ED8C00" },
  ];

  return (
    <div className="TotalGainSummary">
      <section className="TotalGainSummary__InformationBlockContainer">
        <p className="title">{"Économies sur la facture".toUpperCase()}</p>
        <p className="price" style={{ color: "#46D1CA" }}>
          {euroShortFormat(economies_on_bills || 0)}
        </p>
      </section>
      <section className="TotalGainSummary__PieChartContainer">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={pieChartData}
              dataKey="value"
              cy={340}
              startAngle={180}
              endAngle={0}
              innerRadius={260}
              outerRadius={300}
              fill="#8884d8"
              paddingAngle={0}
            >
              {pieChartData.map((entry, index) => {
                return <Cell key={`cell-${index}`} fill={entry.color} />;
              })}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </section>
      <section className="TotalGainSummary__InformationBlockContainer">
        <p className="title">{"Revente du surplus".toUpperCase()}</p>
        <p className="price" style={{ color: "#ED8C00" }}>
          {euroShortFormat(total_sale_euros || 0)}
        </p>
      </section>
      <section className="TotalGainSummary__TotalAmount">
        <p className="price">
          {(total_sale_euros !== undefined &&
            economies_on_bills !== undefined &&
            euroShortFormat(total_sale_euros + economies_on_bills)) ||
            0}
        </p>
        <p className="title">Gains cumulés sur {numberOfYears} ans</p>
      </section>
    </div>
  );
};
