import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfSubTitleProduct } from "../../components/Pdf/Pdf-Devis/PdfSubTitleProduct/PdfSubTitleProduct";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
  ISageProduct,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  PACAirAirProduct: IDataProduct | undefined;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  commercialUserInformation: ICommercialUserInformation;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
  sageProduct: ISageProduct[];
}

export const PdfPACAirAirPage = ({
  documentId,
  PACAirAirProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
  devisOptions,
  sageProduct,
}: IProps) => {
  const {
    extraChargeWithoutTax: PACAirAirExtraChargeWithoutTax,
    totalPriceWhithoutTax: PACAirAirTotalPriceWhithoutTax,
    totalTVA: PACAirAirTotalTVA,
    totalPriceWithTax: PACAirAirTotalPriceWithTax,
    totalHelp: PACAirAirTotalHelp,
    totalResteACharge: PACAirAirTotalResteACharge,
    extraChargeWithTax: PACAirAirExtraChargeWithTax,
    installationPriceWithoutTax: PACAirAirInstallationPriceWithoutTax,
    installationPriceWithTax: PACAirAirInstallationPriceWithTax,
    managementFeesTTC: PACmanagementFeesTTC,
    managementFeesHT: PacManagementFeesHT,
    totalDeductibleHelps: PACAirAirTotalDeductibleHelps,
    totalRefundableHelps: PACAirAirTotalRefundableHelps,
    productPriceWhithTaxe: PACAirAirProductPriceWithTax,
    productPriceWhithoutTaxe: PACAirAirProductPriceWithoutTax,
  } = productPrices({
    product: PACAirAirProduct,
    isEligibleRenoGlobal,
    devisOptions,
  });

  const PACAirAirExteriorUnitSageProduct = sageProduct.find(
    (item) => item.AR_Ref === PACAirAirProduct?.sage_reference
  );

  const interiorUnits =
    PACAirAirProduct?.selected_characteristics?.rooms_information?.flatMap(
      (room) => room.interior_units
    ) || [];

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              PACAirAirProduct?.product_alias ||
              PACAirAirProduct?.product_name ||
              "Produit NC"
            }
          />
          <PdfSubTitleProduct
            title={
              "Référence fabricant : " +
                PACAirAirExteriorUnitSageProduct?.["Référence fabricant"] ||
              "NC"
            }
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={1}
              prixUnitaire={PACAirAirProductPriceWithoutTax || 0}
              TVA={PACAirAirProduct?.TVA_percentage || 0}
              montantTTC={PACAirAirProductPriceWithTax || 0}
            />

            {/* PACAirAir */}
            {PACAirAirExteriorUnitSageProduct ? (
              <PdfItemDetail
                key={PACAirAirExteriorUnitSageProduct.AR_Ref}
                title={
                  PACAirAirProduct?.product_name
                    ? PACAirAirExteriorUnitSageProduct.NO_Qte
                      ? PACAirAirExteriorUnitSageProduct.NO_Qte +
                        " " +
                        PACAirAirProduct?.product_name
                      : PACAirAirProduct?.product_name
                    : ""
                }
                description={[
                  <>
                    {PACAirAirProduct?.product_characteristics?.power && (
                      <Text>{`Puissance : ${PACAirAirProduct?.product_characteristics.power}`}</Text>
                    )}
                  </>,
                  <>
                    {PACAirAirExteriorUnitSageProduct.Alimentation &&
                      PACAirAirExteriorUnitSageProduct.Alimentation.length >
                        0 && (
                        <Text>{`Alimentation : ${PACAirAirExteriorUnitSageProduct.Alimentation}`}</Text>
                      )}
                  </>,
                  <>
                    {PACAirAirExteriorUnitSageProduct.Dimensions && (
                      <Text>
                        {PACAirAirExteriorUnitSageProduct.Dimensions &&
                        PACAirAirExteriorUnitSageProduct.Dimensions.length > 0
                          ? `Taille : ${PACAirAirExteriorUnitSageProduct.Dimensions}`
                          : null}{" "}
                      </Text>
                    )}
                  </>,
                  <>
                    {PACAirAirExteriorUnitSageProduct[
                      "Ref fluide frigorigène"
                    ] && (
                      <Text>
                        {PACAirAirExteriorUnitSageProduct[
                          "Ref fluide frigorigène"
                        ].length > 0
                          ? `Référence fluide frigorigène : ${PACAirAirExteriorUnitSageProduct["Ref fluide frigorigène"]}`
                          : null}
                      </Text>
                    )}
                  </>,
                  <>
                    {PACAirAirExteriorUnitSageProduct[
                      "Profil de soutirage"
                    ] && (
                      <Text>
                        {PACAirAirExteriorUnitSageProduct["Profil de soutirage"]
                          .length > 0
                          ? `Profil de soutirage : ${PACAirAirExteriorUnitSageProduct["Profil de soutirage"]}`
                          : null}
                      </Text>
                    )}
                  </>,
                  <>
                    {PACAirAirExteriorUnitSageProduct[
                      "Norme et Certification"
                    ] &&
                      PACAirAirExteriorUnitSageProduct["Norme et Certification"]
                        ?.length > 0 && (
                        <Text>
                          {PACAirAirExteriorUnitSageProduct[
                            "Norme et Certification"
                          ]
                            ? `Normes et certifications : ${PACAirAirExteriorUnitSageProduct["Norme et Certification"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {PACAirAirExteriorUnitSageProduct[
                      "Classe energétique (Froid/Chaud"
                    ] &&
                      PACAirAirExteriorUnitSageProduct[
                        "Classe energétique (Froid/Chaud"
                      ]?.length > 0 && (
                        <Text>
                          {PACAirAirExteriorUnitSageProduct[
                            "Classe energétique (Froid/Chaud"
                          ]
                            ? `Classe énergétique : ${PACAirAirExteriorUnitSageProduct["Classe energétique (Froid/Chaud"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {PACAirAirProduct?.product_characteristics?.scop && (
                      <Text>
                        {`COP : ${PACAirAirProduct?.product_characteristics?.scop}`}
                      </Text>
                    )}
                  </>,
                  <>
                    {PACAirAirProduct?.product_characteristics?.etas && (
                      <Text>
                        {`Etas : ${PACAirAirProduct?.product_characteristics?.etas}`}
                      </Text>
                    )}
                  </>,

                  <>
                    {PACAirAirExteriorUnitSageProduct["Garantie Fabricant"] &&
                      PACAirAirExteriorUnitSageProduct["Garantie Fabricant"]
                        ?.length > 0 && (
                        <Text>
                          {PACAirAirExteriorUnitSageProduct[
                            "Garantie Fabricant"
                          ]
                            ? `Garantie Fabriquant : ${PACAirAirExteriorUnitSageProduct["Garantie Fabricant"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {PACAirAirExteriorUnitSageProduct[
                      "Garantie main d'oeuvre"
                    ] &&
                      PACAirAirExteriorUnitSageProduct["Garantie main d'oeuvre"]
                        ?.length > 0 && (
                        <Text>
                          {PACAirAirExteriorUnitSageProduct[
                            "Garantie main d'oeuvre"
                          ]
                            ? `Garantie main d'oeuvre : ${PACAirAirExteriorUnitSageProduct["Garantie main d'oeuvre"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {/* We add the interior units linked to the exterior unit */}
                    {interiorUnits.map((interiorUnit) => {
                      return (
                        <Text
                          key={interiorUnit.interior_unit_id}
                        >{`+ ${interiorUnit.name}`}</Text>
                      );
                    })}
                  </>,
                ]}
              />
            ) : null}

            <View wrap={false}>
              <PdfRowBackgroundProduit
                name="Forfait installation"
                quantity={1}
                prixUnitaire={PACAirAirInstallationPriceWithoutTax}
                TVA={PACAirAirProduct?.TVA_percentage || 0}
                montantTTC={PACAirAirInstallationPriceWithTax}
              />
              <View style={styles.inlineComponents}>
                <Text>Déplacement</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Dépose du matériel existant (mais n’inclut pas l’enlèvement et
                  l’évacuation de celui-ci)
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Mise en place d'une pompe à chaleur air/air</Text>
              </View>
              <View style={[styles.columnComponents, { paddingLeft: 10 }]}>
                <Text>
                  • Mise en place de l' /des unité(s) intérieure(s) et
                  extérieure(s)
                </Text>
                <Text>
                  • Installation des liaisons frigorifiques et électriques
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text style={{ marginTop: 10 }}>
                  Fourniture des équipements nécessaires à la pose des matériels
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Mise en service de l’installation</Text>
              </View>
            </View>
            {(PACAirAirProduct?.extra_charges_added?.length || 0) > 0 && (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Spécificité du chantier"
                  quantity={PACAirAirProduct?.extra_charges_added?.length}
                  prixUnitaire={PACAirAirExtraChargeWithoutTax || 0}
                  TVA={PACAirAirProduct?.TVA_percentage || 0}
                  montantTTC={PACAirAirExtraChargeWithTax || 0}
                />
                {PACAirAirProduct?.extra_charges_added?.map((extraCharge) => {
                  return (
                    <PdfRowProduit
                      key={extraCharge.product_name}
                      name={extraCharge.product_name}
                      quantity={1}
                      prixUnitaire={extraCharge.price_without_tax}
                      TVA={extraCharge.TVA_percentage}
                      montantTTC={extraCharge.price_with_tax}
                      description={extraCharge.description}
                    />
                  );
                })}
              </View>
            )}
            <View wrap={false}>
              {PACmanagementFeesTTC !== 0 ? (
                <>
                  <PdfRowBackgroundProduit
                    name="Frais de gestion"
                    quantity={1}
                    prixUnitaire={PacManagementFeesHT}
                    TVA={PACAirAirProduct?.installation_tva_percentage}
                    montantTTC={PACmanagementFeesTTC}
                  />
                  <View style={styles.inlineComponents}>
                    <Text>
                      Prise en charge des démarches administratives et gestion
                      de projet/SAV
                    </Text>
                  </View>
                  <View style={styles.inlineComponents}>
                    <Text style={{ marginBottom: 10 }}>
                      Constitution des dossiers d’aides financières
                      éventuellement liées au projet
                    </Text>
                  </View>

                  <PdfRowBackgroundProduit
                    name="Frais de gestion offerts"
                    montantTTC={-PACmanagementFeesTTC}
                  />
                </>
              ) : null}
            </View>
            {PACAirAirTotalHelp && devisOptions.isHelpsDisplayed ? (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Aides"
                  aides={PACAirAirProduct?.help
                    .filter((help) => help.is_eligible)
                    .map((help) => {
                      return {
                        aide: help.name,
                        montant: help.value,
                        isDeducted:
                          help.help_type === "mpr"
                            ? devisOptions.isMPRHelpsDeducted
                            : help.help_type === "cee"
                            ? devisOptions.isCEEHelpsDeducted
                            : false,
                      };
                    })}
                />
              </View>
            ) : null}
            <PdfTotalContainer
              devisOptions={devisOptions}
              totalHT={PACAirAirTotalPriceWhithoutTax}
              totalTVA={PACAirAirTotalTVA}
              totalTTC={PACAirAirTotalPriceWithTax}
              totalHelp={PACAirAirTotalHelp}
              resteACharge={PACAirAirTotalResteACharge}
              deductibleHelp={PACAirAirTotalDeductibleHelps}
              refundableHelp={PACAirAirTotalRefundableHelps}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
