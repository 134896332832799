// Styles import
import "./sofincoSimulator.scss";

// Libraries import
import { useEffect, useState } from "react";

// Components import
import { DropdownV2 } from "@web/shared/dist/components/DesignSystem/Dropdowns/DropdownV2/DropdownV2";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { Switch } from "@web/shared/dist/components/DesignSystem/Switch/Switch";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { financingPeriodInMonth } from "../../components/ProfitabilityComponents/ProfitabilityCashFlowContainer/dureeDeFinancementOptions";

// Data import
import { interestRateOptions } from "@web/shared/dist/datas/dataHypotethis";

// Services import
import {
  HelpsOptions,
  LoanSimulationResult,
} from "../../interfaces/generalInterfaces";
import { useGetSofincoLoanSimulation } from "../../services/RenovationGlobale/MonProjet/useGetSofincoLoanSimulation";
import { calculateAutofinancing } from "../../services/calculateMonthlyPayment";

// Local interfaces
interface IProps {
  fundingPeriod: number;
  setFundingPeriod: React.Dispatch<React.SetStateAction<number>>;
  interestRate: string;
  setInterestRate: React.Dispatch<React.SetStateAction<string>>;
  personalContributionAmount: number;
  setPersonalContributionAmount: React.Dispatch<React.SetStateAction<number>>;
  isDeferralOption: boolean;
  setIsDeferralOption: React.Dispatch<React.SetStateAction<boolean>>;
  hasBorrowerInsurance: boolean;
  setHasBorrowerInsurance: React.Dispatch<React.SetStateAction<boolean>>;
  hasCoBorrower: boolean;
  setHasCoBorrower: React.Dispatch<React.SetStateAction<boolean>>;
  loanSimulationResult: LoanSimulationResult;
  setLoanSimulationResult: React.Dispatch<
    React.SetStateAction<LoanSimulationResult>
  >;
  userToken: string | null;
  study_id: string | null;
  totalCashEffort?: number;
  loanAmount: number;
  totalProjectPrice: number;
  helpsOptions: HelpsOptions;
}

const useDebouncedValue = (inputValue: number, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);
  return debouncedValue;
};

export const SofincoSimulator = ({
  fundingPeriod,
  setFundingPeriod,
  interestRate,
  setInterestRate,
  personalContributionAmount,
  setPersonalContributionAmount,
  isDeferralOption,
  setIsDeferralOption,
  hasBorrowerInsurance,
  setHasBorrowerInsurance,
  hasCoBorrower,
  setHasCoBorrower,
  loanSimulationResult,
  setLoanSimulationResult,
  userToken,
  study_id,
  totalCashEffort,
  loanAmount,
  totalProjectPrice,
  helpsOptions,
}: IProps) => {
  // Format interestRateOptions for Dropdown component
  const interestRateOptionsFormated = interestRateOptions.map((option) => {
    return {
      label: option,
      value: option,
    };
  });

  const {
    getSofincoLoanSimulation,
    isLoading: getSofincoLoanSimulationIsLoading,
    errorMessage: getSofincoLoanSimulationErrorMessage,
  } = useGetSofincoLoanSimulation();

  const fetchSofincoLoanSimulation = async () => {
    const response = await getSofincoLoanSimulation({
      userToken: userToken,
      amount: loanAmount,
      personalContributionAmount: personalContributionAmount || 0,
      fundingPeriod: fundingPeriod * 12,
      rateCode: interestRate + (isDeferralOption ? "R6" : ""),
      hasBorrowerInsurance: hasBorrowerInsurance,
      hasCoBorrower: hasCoBorrower,
      study_id: study_id || "",
    });

    setLoanSimulationResult({
      monthlyPayment: response.monthlyAmountWithInsuranceAndServices || 0,
      TAEG: response.TAEG || 0,
      tauxDebiteurFixe: response.annualPercentageRate || 0,
      loanAmount: response.loanAmount || 0,
      deferralDurationMonth: response.deferralDurationMonth || 0,
    });
  };

  const debouncedSearchTerm = useDebouncedValue(
    personalContributionAmount,
    500
  );

  useEffect(() => {
    fetchSofincoLoanSimulation();
    // eslint-disable-next-line
  }, [
    debouncedSearchTerm,
    fundingPeriod,
    interestRate,
    isDeferralOption,
    hasBorrowerInsurance,
    hasCoBorrower,
    helpsOptions?.isCEEHelpsDeducted,
    helpsOptions?.isMPRHelpsDeducted,
  ]);

  return (
    <>
      <section className="SofincoSimulator">
        <div className="SofincoSimulator__InputsContainer">
          <div className="SofincoSimulator__Input">
            <p>Apport&nbsp;: </p>
            <TextInput
              onChange={(value) => {
                setPersonalContributionAmount(
                  value.currentTarget.valueAsNumber
                );
              }}
              value={
                personalContributionAmount
                  ? personalContributionAmount.toString()
                  : personalContributionAmount === 0
                  ? "0"
                  : ""
              }
              inputDescriptionHidden
              inputLableHidden
              iconType="euros"
              placeholder=""
              type="number"
              iconHidden={false}
            />
          </div>
          <div className="SofincoSimulator__Input">
            <p>Taux&nbsp;d'intérêt&nbsp;:&nbsp;</p>
            <div className="SofincoSimulator__DropDown">
              <DropdownV2
                multipleChoices={false}
                searchable={false}
                background
                options={interestRateOptionsFormated}
                onSelect={(option) => setInterestRate(option.value as string)}
                values={[interestRate]}
              />
            </div>
          </div>

          <div className="SofincoSimulator__Input">
            <p>Durée&nbsp;de&nbsp;financement&nbsp;: </p>
            <div className="SofincoSimulator__DropDown">
              <DropdownV2
                multipleChoices={false}
                searchable={false}
                background
                options={financingPeriodInMonth}
                onSelect={(option) => setFundingPeriod(option.value as number)}
                values={[fundingPeriod]}
              />
            </div>
          </div>

          <div className="SofincoSimulator__Input">
            <p>Option&nbsp;report&nbsp;6 mois&nbsp;: </p>
            <Switch
              dimension="M"
              onClick={() => setIsDeferralOption((state) => !state)}
              value={isDeferralOption}
              name="deferralOption"
            />
          </div>
          <div className="SofincoSimulator__Input">
            <p>Option&nbsp;assurance&nbsp;emprunteur&nbsp;: </p>
            <Switch
              dimension="M"
              onClick={() => {
                setHasBorrowerInsurance((state) => !state);
                setHasCoBorrower(false);
              }}
              value={hasBorrowerInsurance}
              name="hasBorrowerInsuranceOption"
            />
          </div>

          <div
            className="SofincoSimulator__Input"
            style={{
              visibility: hasBorrowerInsurance ? "visible" : "hidden",
            }}
          >
            <p>Option&nbsp;assurance&nbsp;co-emprunteur&nbsp;: </p>
            <Switch
              dimension="M"
              onClick={() => setHasCoBorrower((state) => !state)}
              value={hasCoBorrower}
              name="hasCoBorrowerOption"
            />
          </div>
        </div>

        <div className="SofincoSimulator__LoanInformation">
          <h3>Mensualité</h3>

          {getSofincoLoanSimulationIsLoading ? (
            <LoadingComponent diameter={30} />
          ) : (
            <p className="SofincoSimulator__Result">
              {loanSimulationResult.monthlyPayment} €
            </p>
          )}
        </div>

        {totalCashEffort ? (
          <div className="SofincoSimulator__LoanInformation">
            <h3>Autofinancement</h3>

            {getSofincoLoanSimulationIsLoading ? (
              <LoadingComponent diameter={30} />
            ) : (
              <p className="SofincoSimulator__Result">
                {calculateAutofinancing({
                  totalCashEffort,
                  totalAmountToPay: totalProjectPrice,
                })}
                %
              </p>
            )}
          </div>
        ) : null}
      </section>
      <ErrorMessage errorMessage={getSofincoLoanSimulationErrorMessage} />
    </>
  );
};
