//local Interface

import {
  IHourlyConsumptionProductionData,
  IHourlyConsumptionProductionSingleDataFormated,
} from "../../interfaces/generalInterfaces";

export const hourlyConsumptionProductionDataFormat = (
  dataToFormat: IHourlyConsumptionProductionData | null
) => {
  const dataFormat: IHourlyConsumptionProductionSingleDataFormated[] = [];

  if (!dataToFormat) return [];

  for (const key in dataToFormat) {
    dataFormat.push({
      hour: parseInt(key),
      consumption: Math.round(dataToFormat[key].consumption * 100) / 100,
      consumption_shifted:
        Math.round(dataToFormat[key].consumption_shifted * 100) / 100,
      production: Math.round(dataToFormat[key].production * 100) / 100,
      autoconso:
        dataToFormat[key].production < dataToFormat[key].consumption_shifted
          ? Math.round(dataToFormat[key].production * 100) / 100
          : Math.round(dataToFormat[key].consumption_shifted * 100) / 100,
    });
  }

  return dataFormat;
};
