import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  PVProduct: IDataProduct | undefined;
  commercialUserInformation: ICommercialUserInformation;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
}

export const PdfPVPageTwo = ({
  documentId,
  PVProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
  devisOptions,
}: IProps) => {
  const {
    extraChargeWithoutTax: PVExtraChargeWithoutTax,
    totalPriceWhithoutTax: PVTotalPriceWhithoutTax,
    totalTVA: PVTotalTVA,
    totalPriceWithTax: PVTotalPriceWithTax,
    totalHelp: PVTotalHelp,
    totalResteACharge: PVTotalResteACharge,
    extraChargeWithTax: PVExtraChargeWithTax,
    managementFeesTTC: PVmanagementFeesTTC,
    managementFeesHT: PVManagementFeesHT,
  } = productPrices({
    product: PVProduct,
    isEligibleRenoGlobal,
    devisOptions,
  });

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={PVProduct?.product_alias || PVProduct?.product_name || "NC"}
            page={"2/2"}
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            {(PVProduct?.extra_charges_added?.length || 0) > 0 ? (
              <PdfRowBackgroundProduit
                name="Spécifités du chantier"
                quantity={PVProduct?.extra_charges_added?.length}
                prixUnitaire={PVExtraChargeWithoutTax || 0}
                TVA={undefined}
                montantTTC={PVExtraChargeWithTax || 0}
              />
            ) : null}
            {PVProduct?.extra_charges_added?.map((extraCharge) => {
              return (
                <PdfRowProduit
                  key={extraCharge.product_name}
                  name={extraCharge.product_name}
                  quantity={1}
                  prixUnitaire={extraCharge.price_without_tax}
                  TVA={extraCharge.TVA_percentage}
                  montantTTC={extraCharge.price_with_tax}
                  description={extraCharge.description}
                />
              );
            })}

            {PVmanagementFeesTTC !== 0 ? (
              <>
                <PdfRowBackgroundProduit
                  name="Frais de gestion"
                  quantity={1}
                  prixUnitaire={PVManagementFeesHT}
                  TVA={PVProduct?.installation_tva_percentage}
                  montantTTC={PVmanagementFeesTTC}
                />
                <View style={styles.inlineComponents}>
                  <Text>
                    Prise en charge des démarches administratives et gestion de
                    projet/SAV
                  </Text>
                </View>
                <View style={styles.inlineComponents}>
                  <Text>
                    Constitution des dossiers d’aides financières éventuellement
                    liées au projet
                  </Text>
                </View>
                <View style={styles.inlineComponents}>
                  <Text>Réalisation de la déclaration préalable en mairie</Text>
                </View>

                <View style={styles.inlineComponents}>
                  <Text>Demande de raccordement</Text>
                </View>
                <View style={styles.inlineComponents}>
                  <Text style={{ marginBottom: 10 }}>
                    Obtention de l’attestation CONSUEL
                  </Text>
                </View>
                <PdfRowBackgroundProduit
                  name="Frais de gestion offerts"
                  montantTTC={-PVmanagementFeesTTC}
                />
              </>
            ) : null}

            {(PVProduct?.help.length || 0) > 0 &&
            devisOptions.isHelpsDisplayed ? (
              <PdfRowBackgroundProduit
                name="Aides"
                aides={PVProduct?.help
                  .filter((help) => help.is_eligible)
                  .map((help) => {
                    return {
                      aide: help.name,
                      montant: help.value,
                      isDeducted:
                        help.help_type === "mpr"
                          ? devisOptions.isMPRHelpsDeducted
                          : help.help_type === "cee"
                          ? devisOptions.isCEEHelpsDeducted
                          : false,
                    };
                  })}
              />
            ) : null}
            <PdfTotalContainer
              devisOptions={devisOptions}
              totalHT={PVTotalPriceWhithoutTax}
              totalTVA={PVTotalTVA}
              totalTTC={PVTotalPriceWithTax}
              totalHelp={PVTotalHelp}
              resteACharge={PVTotalResteACharge}
              refundableHelp={PVTotalHelp}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
