import "./LoadingScreen.scss";

// Components import
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { Layout } from "../../components/Layout/Layout";

// Image import
import HouseImage from "../../assets/images/footer/footerStartEtude.svg";
import Cloud from "../../assets/images/cloud.svg";

// Local interface declaration
interface Props {
  sentenceOne?: string;
  sentenceTwo?: string;
}

export const LoadingScreen = ({
  sentenceOne = "",
  sentenceTwo = "",
}: Props) => {
  return (
    <div className="LoadingScreen">
      <div className="LoadingScreen__LoadingSpinner">
        <LoadingComponent diameter={60} />
      </div>
      <Layout>
        <div className="LoadingScreen__CloudContainer">
          <div className="LoadingScreen__CloudsLeft">
            <div className="LoadingScreen__CloudOne">
              <img src={Cloud} alt="Cloud"></img>
            </div>
            <div className="LoadingScreen__CloudTwo">
              <img src={Cloud} alt="Cloud"></img>
            </div>
          </div>

          <div className="LoadingScreen__CloudsRight">
            <div className="LoadingScreen__CloudThree">
              <img src={Cloud} alt="Cloud"></img>
            </div>
            <div className="LoadingScreen__CloudFour">
              <img src={Cloud} alt="Cloud"></img>
            </div>
          </div>
        </div>
      </Layout>
      <div className="LoadingScreen__LoadingText">
        <p>{sentenceOne}</p>
        <p>{sentenceTwo}</p>
      </div>
      <div className="LoadingScreen__HouseContainer">
        <img src={HouseImage} alt="Landscape with an house"></img>
      </div>
    </div>
  );
};
