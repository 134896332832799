import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfSubTitleProduct } from "../../components/Pdf/Pdf-Devis/PdfSubTitleProduct/PdfSubTitleProduct";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
  ISageProduct,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  VMCProduct: IDataProduct | undefined;
  VMCSageProduct: ISageProduct | undefined;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  commercialUserInformation: ICommercialUserInformation;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
}

export const PdfVMCPage = ({
  documentId,
  VMCProduct,
  VMCSageProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
  devisOptions,
}: IProps) => {
  const {
    extraChargeWithoutTax: VMCExtraChargeWithoutTax,
    totalPriceWhithoutTax: VMCTotalPriceWhithoutTax,
    totalTVA: VMCTotalTVA,
    totalPriceWithTax: VMCTotalPriceWithTax,
    totalHelp: VMCTotalHelp,
    totalResteACharge: VMCTotalResteACharge,
    extraChargeWithTax: VMCExtraChargeWithTax,
    installationPriceWithoutTax: VMCInstallationPriceWithoutTax,
    installationPriceWithTax: VMCInstallationPriceWithTax,
    managementFeesTTC: VMCmanagementFeesTTC,
    managementFeesHT: VMCManagementFeesHT,
    totalDeductibleHelps: VMCTotalDeductibleHelps,
    totalRefundableHelps: VMCTotalRefundableHelps,
  } = productPrices({
    product: VMCProduct,
    isEligibleRenoGlobal,
    devisOptions,
  });

  const isDoubleFluxPAC = VMCProduct?.product_name
    ?.toLowerCase()
    .includes("double");

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              VMCProduct?.product_alias ||
              VMCProduct?.product_name ||
              "Produit NC"
            }
          />
          <PdfSubTitleProduct
            title={
              "Référence fabricant : " +
              (VMCSageProduct?.["Référence fabricant"] || "NC")
            }
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={1}
              prixUnitaire={VMCProduct?.product_price_without_tax || 0}
              TVA={VMCProduct?.TVA_percentage || 0}
              montantTTC={VMCProduct?.product_price_with_tax || 0}
            />

            {/* CET */}
            {VMCSageProduct ? (
              <PdfItemDetail
                key={VMCSageProduct.AR_Ref}
                title={
                  (VMCProduct?.product_alias || VMCProduct?.product_name) &&
                  VMCSageProduct.NO_Qte
                    ? VMCSageProduct.NO_Qte +
                      " " +
                      (VMCProduct?.product_alias || VMCProduct?.product_name)
                    : ""
                }
                description={[
                  <>
                    {VMCProduct?.product_name && (
                      <Text>{VMCProduct?.product_name}</Text>
                    )}
                  </>,
                  <>
                    <Text>Type d'installation : individuelle</Text>
                  </>,
                  <Text>Mode de fonctionnement VMC : hygroréglable</Text>,
                  <>
                    {VMCProduct?.product_characteristics
                      ?.ecs_storage_volume && (
                      <Text>{`Volume : ${VMCProduct?.product_characteristics.ecs_storage_volume} + L`}</Text>
                    )}
                  </>,
                  <>
                    {VMCSageProduct.Dimensions &&
                      VMCSageProduct.Dimensions.length > 0 && (
                        <Text>Taille : {VMCSageProduct.Dimensions}</Text>
                      )}
                  </>,
                  <>
                    {VMCSageProduct.Marque &&
                    VMCSageProduct.Marque?.length > 0 ? (
                      <>
                        <Text>Marque du caisson : {VMCSageProduct.Marque}</Text>
                        <Text>
                          Marque des bouches à extraction :{" "}
                          {VMCSageProduct.Marque}
                        </Text>
                        <Text>
                          Références des bouches d'extraction : BHC 10/45-135 +
                          BHB 10/45 + BAW 5-30 + BHBW 15/45-40
                        </Text>
                      </>
                    ) : null}
                  </>,
                  <>
                    {VMCSageProduct["Norme et Certification"] &&
                      VMCSageProduct["Norme et Certification"]?.length > 0 && (
                        <Text>
                          Normes et certifications :{" "}
                          {VMCSageProduct["Norme et Certification"]}
                        </Text>
                      )}
                  </>,
                  <>
                    {VMCSageProduct["Garantie Fabricant"] &&
                      VMCSageProduct["Garantie Fabricant"]?.length > 0 && (
                        <Text>
                          Garantie Fabriquant :{" "}
                          {VMCSageProduct["Garantie Fabricant"]}
                        </Text>
                      )}
                  </>,
                  <>
                    {VMCSageProduct.Description &&
                      VMCSageProduct.Description?.length > 0 && (
                        <Text>{VMCSageProduct.Description}</Text>
                      )}
                  </>,
                ]}
              />
            ) : null}
            <View wrap={false}>
              <PdfRowBackgroundProduit
                name="Forfait installation"
                quantity={1}
                prixUnitaire={VMCInstallationPriceWithoutTax}
                TVA={VMCProduct?.TVA_percentage || 0}
                montantTTC={VMCInstallationPriceWithTax}
              />
              <View style={styles.inlineComponents}>
                <Text>
                  Forfait installation d'un système de VMC{" "}
                  {isDoubleFluxPAC ? "double" : "simple"} flux composé d'un
                  caisson, d'un échangeur statique et de bouches d'extraction
                  hygroréglables
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Déplacement</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Mise en place d'une VMC</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Mise en service de l’installation</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Fournitures des équipements nécessaires à la pose des
                  matériels
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text style={{ marginBottom: 10 }}>Gestion des déchets</Text>
              </View>
            </View>
            {(VMCProduct?.extra_charges_added?.length || 0) > 0 ? (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Spécificité du chantier"
                  quantity={VMCProduct?.extra_charges_added?.length}
                  prixUnitaire={VMCExtraChargeWithoutTax || 0}
                  TVA={VMCProduct?.TVA_percentage || 0}
                  montantTTC={VMCExtraChargeWithTax || 0}
                />
                {VMCProduct?.extra_charges_added?.map((extraCharge) => {
                  return (
                    <PdfRowProduit
                      key={extraCharge.product_name}
                      name={extraCharge.product_name}
                      quantity={1}
                      prixUnitaire={extraCharge.price_without_tax}
                      TVA={extraCharge.TVA_percentage}
                      montantTTC={extraCharge.price_with_tax}
                      description={extraCharge.description}
                    />
                  );
                })}
              </View>
            ) : null}
            <View wrap={false}>
              {VMCmanagementFeesTTC !== 0 ? (
                <>
                  <PdfRowBackgroundProduit
                    name="Frais de gestion"
                    quantity={1}
                    prixUnitaire={VMCManagementFeesHT}
                    TVA={VMCProduct?.installation_tva_percentage}
                    montantTTC={VMCmanagementFeesTTC}
                  />
                  <View style={styles.inlineComponents}>
                    <Text>
                      Prise en charge des démarches administratives et gestion
                      de projet/SAV
                    </Text>
                  </View>
                  <View style={styles.inlineComponents}>
                    <Text style={{ marginBottom: 10 }}>
                      Constitution des dossiers d’aides financières
                      éventuellement liées au projet
                    </Text>
                  </View>

                  <PdfRowBackgroundProduit
                    name="Frais de gestion offerts"
                    montantTTC={-VMCmanagementFeesTTC}
                  />
                </>
              ) : null}
            </View>
            {VMCTotalHelp && devisOptions.isHelpsDisplayed ? (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Aides"
                  aides={VMCProduct?.help
                    .filter((help) => help.is_eligible)
                    .map((help) => {
                      return {
                        aide: help.name,
                        montant: help.value,
                        isDeducted:
                          help.help_type === "mpr"
                            ? devisOptions.isMPRHelpsDeducted
                            : help.help_type === "cee"
                            ? devisOptions.isCEEHelpsDeducted
                            : false,
                      };
                    })}
                />
              </View>
            ) : null}
            <PdfTotalContainer
              devisOptions={devisOptions}
              totalHT={VMCTotalPriceWhithoutTax}
              totalTVA={VMCTotalTVA}
              totalTTC={VMCTotalPriceWithTax}
              totalHelp={VMCTotalHelp}
              resteACharge={VMCTotalResteACharge}
              deductibleHelp={VMCTotalDeductibleHelps}
              refundableHelp={VMCTotalRefundableHelps}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
