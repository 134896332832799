import React from "react";
import "./potentielSolaireMainInfo.scss";
import pictoPans from "../../../assets/images/picto-maison-double-border.svg";
import pictoSurface from "../../../assets/images/picto-surface-arrows.svg";
import pictoMateriau from "../../../assets/images/picto-materiau-toiture-tuiles.svg";

interface IProps {
  numberOfPans?: number | null;
  totalAreaToEquip?: number | null;
  roofMaterial?: string | null;
}

export const PotentielSolaireMainInfo = ({
  numberOfPans,
  totalAreaToEquip,
  roofMaterial,
}: IProps) => {
  return (
    <div className="PotentielSolaireMainInfo">
      <section className="PotentielSolaireMainInfo__ItemContainer">
        <div className="PotentielSolaireMainInfo__ImageContainer">
          <img
            src={pictoPans}
            alt="pictograme nombre de pans de la toiture"
            width={46}
            height={33}
          />
        </div>
        <div className="PotentielSolaireMainInfo__TextContainer">
          <p>
            Nombre de pan(s) équipables : <strong>{numberOfPans}</strong>
          </p>
        </div>
      </section>
      <section className="PotentielSolaireMainInfo__ItemContainer">
        <div className="PotentielSolaireMainInfo__ImageContainer">
          <img
            src={pictoSurface}
            alt="pictograme surface total du toit"
            width={42}
            height={25}
          />
        </div>
        <div className="PotentielSolaireMainInfo__TextContainer">
          <p>Surface équipable</p>
          <p>
            <strong>{totalAreaToEquip} m²</strong>
          </p>
        </div>
      </section>
      {roofMaterial && (
        <section className="PotentielSolaireMainInfo__ItemContainer">
          <div className="PotentielSolaireMainInfo__ImageContainer">
            <img
              src={pictoMateriau}
              alt="pictograme materiau de la toiture"
              width={42}
              height={38}
            />
          </div>
          <div className="PotentielSolaireMainInfo__TextContainer">
            <p>Matériau</p>
            <p>
              <strong>{roofMaterial}</strong>
            </p>
          </div>
        </section>
      )}
    </div>
  );
};
