// import React from "react";
import React from "react";
// Styles import
import styled from "styled-components";
import { AtomeChoixMultiple } from "../AtomeChoixMultiple/AtomeChoixMultiple";
import { DSColors } from "../../../../styles/variables";

// interface declaration
interface IOption {
  label: string | null;
  value: string | null;
  imgSrc?: string;
  imgAltTxt?: string;
}

export interface ChoixMultipleProps {
  options: IOption[];
  onClick: (option: IOption | null) => void;
  color?: string;
  value?: IOption[];
  isRequired?: boolean;
  gap?: boolean;
}

const Container = styled.div<{ gap: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: ${(props) => (props.gap ? "24px" : 0)};
  position: relative;
  > input {
    all: unset;
    width: 1px;
    text-align: left;
    caret-color: transparent;
    color: transparent;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const ChoixMultiple: React.FC<ChoixMultipleProps> = ({
  options = [],
  onClick,
  color = DSColors.Mint,
  value = [],
  isRequired = false,
  gap = true,
}) => {
  return (
    <Container gap={gap}>
      {options &&
        options.map((option, index) => {
          return (
            <AtomeChoixMultiple
              option={option}
              onClick={onClick}
              key={index}
              iconSrc={options[index].imgSrc}
              color={color}
              isClicked={!!value.find((elem) => elem.value === option.value)}
              gap={gap}
              index={index}
              lastIndex={options.length - 1}
            />
          );
        })}
      {/* Input value only here to handle the is required message in the browser */}
      {value.length ? null : (
        <input
          required={isRequired}
          onKeyDown={(event) => event.preventDefault()}
          autoComplete="off"
          value={undefined}
          onChange={() => {}}
        />
      )}
    </Container>
  );
};
