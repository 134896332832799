import { Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import { DSColors } from "@web/shared/src/styles/variables";

// Components
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfEtudeHeaderRG } from "./PdfEtudeHeaderRG";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";

// Interfaces import
import {
  IPerformanceRG,
  IProjectKPI,
} from "../../interfaces/renoglobaleInterface";

// Images import
import vousEtesIci from "../../assets/pdf/images/fleche.png";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

// Images import
import tauxAutoconsommation from "../../assets/pdf/images/tauxAutoconsommation.png";
import tauxAutoproduction from "../../assets/pdf/images/tauxAutoproduction.png";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  documentId: string;
  RGProjectKPI: IProjectKPI | null;
  performanceRGData: IPerformanceRG | null | undefined;
  imageSolarPerformance: string | null;
  isPVProductPresentInCart: boolean;
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  containerRecapitulatif: {
    height: "100%",
  },
  pageTitleContainer: {
    textAlign: "left",
    height: 30,
    marginTop: 10,
  },
  pageTitleContent: {
    position: "relative",
    backgroundColor: DSColors.ABLight06,
    padding: 5,
  },
  pageTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },

  pageSubTitleContent: {
    display: "flex",
    flexDirection: "row",
    objectFit: "contain",
    alignItems: "center",
    marginTop: 10,
  },
  pageSubTitle: {
    fontSize: 12,
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },
  pageSubTitleImage: {
    width: 25,
    marginRight: 5,
  },
});

export const PdfEtudeRGPagePerformance = ({
  firstNameInputValue,
  lastNameInputValue,
  documentId,
  RGProjectKPI,
  performanceRGData,
  imageSolarPerformance,
  isPVProductPresentInCart,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const oldLabelImage = performanceRGData?.old_label
    ? require(`../../assets/images/energyLabel/label/DPE ${performanceRGData?.old_label}.png`)
    : null;
  const newLabelImage = performanceRGData?.new_label
    ? require(`../../assets/images/energyLabel/label/DPE ${performanceRGData?.new_label}.png`)
    : null;

  return (
    <Page size="A4" style={styles.page}>
      <PdfEtudeHeaderRG />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.containerRecapitulatif}>
          {/* Page title */}
          <View style={localStyle.pageTitleContainer}>
            <View style={localStyle.pageTitleContent}>
              <Text style={localStyle.pageTitle}>PERFORMANCE</Text>
            </View>
          </View>

          {/* Page subtitle */}
          <View style={localStyle.pageTitleContainer}>
            <View style={localStyle.pageSubTitleContent}>
              <Image
                style={localStyle.pageSubTitleImage}
                source={vousEtesIci}
              />
              <Text style={localStyle.pageSubTitle}>
                Performance énergétique
              </Text>
            </View>
          </View>

          {/* Efficacité énergétique container */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginBottom: 10,
                  fontFamily: "DMSansMedium",
                }}
              >
                Efficacité énergétique
              </Text>
              <Text style={{ fontSize: 8 }}>
                L'efficacité énergétique est un indicateur qui permet de mesurer
                l'impact des travaux de rénovation énergétique sur votre
                logement. Plus l'efficacité énergétique est élevée, plus les
                travaux de rénovation énergétique sont efficaces.
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 8, marginBottom: 5, marginTop: 10 }}>
                EFFICACITÉ ÉNERGÉTIQUE
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: "DMSansBold",
                  color: DSColors.PlanetGreen,
                }}
              >
                {RGProjectKPI?.energy_gain + " %"}
              </Text>
            </View>
          </View>

          {/* Changement d'étiquette container */}
          <View
            style={{
              marginTop: 30,
              marginBottom: 20,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                marginBottom: 10,
                fontFamily: "DMSansMedium",
              }}
            >
              Changement d’étiquette énergétique
            </Text>
            <Text style={{ fontSize: 8 }}>
              Grâce à vos travaux de rénovation, vous améliorez la performance
              énergétique de votre logement et changez ainsi d'étiquette.
            </Text>
            {performanceRGData && oldLabelImage && newLabelImage ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 20,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 30,
                    flex: 1,
                  }}
                >
                  <View
                    style={{ display: "flex", flexDirection: "column", gap: 5 }}
                  >
                    <Text
                      style={{
                        fontFamily: "DMSansBold",
                        color: DSColors.SGLight01,
                        fontSize: 10,
                      }}
                    >
                      AVANT
                    </Text>
                    <Text
                      style={{
                        color: DSColors.SGLight01,
                        fontSize: 10,
                      }}
                    >
                      {performanceRGData?.old_energy_value?.toFixed(0) +
                        " kWh/m²/an"}
                    </Text>
                    <Text
                      style={{
                        color: DSColors.SGLight01,
                        fontSize: 10,
                      }}
                    >
                      {performanceRGData?.old_ges_emissions?.toFixed(0) +
                        " kg CO2/m²/an"}
                    </Text>
                  </View>
                  <View>
                    <Image source={oldLabelImage} style={{ width: 50 }} />
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 30,
                    flex: 1,
                  }}
                >
                  <View
                    style={{ display: "flex", flexDirection: "column", gap: 5 }}
                  >
                    <Text
                      style={{
                        fontFamily: "DMSansBold",
                        color: DSColors.OffBlack,
                        fontSize: 10,
                      }}
                    >
                      APRÈS
                    </Text>
                    <Text
                      style={{
                        color: DSColors.OffBlack,
                        fontSize: 10,
                      }}
                    >
                      {performanceRGData?.new_energy_value?.toFixed(0) +
                        " kWh/m²/an"}
                    </Text>
                    <Text
                      style={{
                        color: DSColors.OffBlack,
                        fontSize: 10,
                      }}
                    >
                      {performanceRGData?.new_ges_emissions?.toFixed(0) +
                        " kg CO2/m²/an"}
                    </Text>
                  </View>
                  <View>
                    <Image source={newLabelImage} style={{ width: 50 }} />
                  </View>
                </View>
              </View>
            ) : (
              <Text>Informations non disponibles</Text>
            )}
          </View>

          {/* Page subtitle */}
          {isPVProductPresentInCart ? (
            <>
              <View style={localStyle.pageTitleContainer}>
                <View style={localStyle.pageSubTitleContent}>
                  <Image
                    style={localStyle.pageSubTitleImage}
                    source={vousEtesIci}
                  />
                  <Text style={localStyle.pageSubTitle}>
                    Performance solaire
                  </Text>
                </View>
              </View>
              {imageSolarPerformance && (
                <View style={{ display: "flex", flexDirection: "row" }}>
                  {/* Image */}
                  <View style={{ marginTop: 10, flex: 3 }}>
                    <Image src={imageSolarPerformance} />
                  </View>

                  {/* Legende */}
                  <View
                    style={{
                      flex: 1,
                      marginLeft: 10,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      paddingBottom: 20,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "DMSansMedium",
                        fontSize: 10,
                        marginBottom: 10,
                      }}
                    >
                      Légende
                    </Text>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 5,
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: DSColors.OTLight01,
                          height: 10,
                          width: 10,
                          borderRadius: "50%",
                          marginRight: 5,
                        }}
                      ></View>
                      <Text style={{ fontSize: 8, color: DSColors.OffBlack }}>
                        Autoconsommation
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 5,
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: DSColors.ZOLight01,
                          height: 10,
                          width: 10,
                          borderRadius: "50%",
                          marginRight: 5,
                        }}
                      ></View>
                      <Text style={{ fontSize: 8, color: DSColors.OffBlack }}>
                        Surplus de production photovoltaïque
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 5,
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: DSColors.SGLight02,
                          height: 10,
                          width: 10,
                          borderRadius: "50%",
                          marginRight: 5,
                        }}
                      ></View>
                      <Text style={{ fontSize: 8, color: DSColors.OffBlack }}>
                        Consommation électrique optimisée grâce au kit de Home
                        Management
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              {/* Taux d'autoconsommation & taux d'autoproduction */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <View style={{ display: "flex", flexDirection: "column" }}>
                  <Text
                    style={{
                      fontFamily: "DMSansMedium",
                      fontSize: 9,
                    }}
                  >
                    Taux d’autoconsommation
                  </Text>
                  <View style={{ marginTop: 10 }}>
                    <Image src={tauxAutoconsommation} style={{ width: 200 }} />
                  </View>
                </View>
                <View style={{ display: "flex", flexDirection: "column" }}>
                  <Text
                    style={{
                      fontFamily: "DMSansMedium",
                      fontSize: 9,
                      color: DSColors.OffBlack,
                    }}
                  >
                    Taux d’autoproduction (Niveau d’indépendance)
                  </Text>
                  <View style={{ marginTop: 10 }}>
                    <Image src={tauxAutoproduction} style={{ width: 220 }} />
                  </View>
                </View>
              </View>
            </>
          ) : null}
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType="rénovation globale"
      />
    </Page>
  );
};
