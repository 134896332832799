import "./EnergyGain.scss";

// Interfaces import
import { IPerformanceRG } from "../../interfaces/renoglobaleInterface";

//Local interface
interface IProps {
  performanceRGData?: IPerformanceRG | null;
}

export const EnergyGain = ({ performanceRGData }: IProps) => {
  return (
    <div className="EnergyGain">
      <section className="EnergyGain__Title">
        <h3>Efficacité énergétique</h3>
      </section>
      <div className="EnergyGain__Container">
        <div className="EnergyGain__Subtitle">
          <p>
            L'efficacité énergétique est un indicateur qui permet de mesurer
            l'impact des travaux de rénovation énergétique sur votre logement.
            Plus l'efficacité énergétique est élevée, plus les travaux de
            rénovation énergétique sont efficaces.
          </p>
        </div>
        {performanceRGData?.energy_gain ? (
          <section className="EnergyGain__GainContainer">
            <div className="EnergyGain__Gain">
              <div className="EnergyGain__GainInformation">
                <p style={{ textTransform: "uppercase" }}>
                  Efficacité énergétique
                </p>
                <h5>
                  <strong>{performanceRGData.energy_gain + " %"}</strong>
                </h5>
              </div>
            </div>
          </section>
        ) : (
          <p className="EnergyGain__NoDataAvailable">
            Pas de données disponibles
          </p>
        )}
      </div>
    </div>
  );
};
