import React, { useEffect, useState } from "react";
import "./monCompte.scss";

// images import
import closingCross from "../../assets/images/ClosingCross.svg";
import pictoPen from "../../assets/images/picto-pen.svg";

// Services import
import { useAppSelector } from "../../redux/store/hook";
import { useGetCommercialInformation } from "../../services/useGetCommercialInformation";
import { useUpdatePassword } from "../../services/Login/useUpdatePassword";
import { useDisconnectApp } from "../../services/useDisconnectApp";

// Components import
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { PasswordInput } from "@web/shared/dist/components/Old/PasswordInput/PasswordInput";
import { MountainsFooter } from "../../components/MountainsFooter/MountainsFooter";
import { ProfilePicture } from "../../containers/MonCompte/profilePicture/ProfilePicture";
import { NavigationBarOutsideStudy } from "../../components/NavigationBarOutsideStudy/NavigationBarOutsideStudy";

export const MonCompte = () => {
  const [isPasswordModalOpen, setIsPasswordModalOpen] =
    useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] =
    useState<string>("");

  const { userAuthInfo } = useAppSelector((state) => state);
  const { commercialUserInformation } = useAppSelector((state) => state);

  const {
    UpdatePassword,
    isLoading: isUpdatePasswordLoading,
    errorMessage: updatePasswordErrorMessage,
  } = useUpdatePassword();
  const {
    getCommercialInformation,
    isLoading: isCommercialInformationLoading,
    errorMessage: commercialInformationErrorMessage,
  } = useGetCommercialInformation();

  const { disconnectApp } = useDisconnectApp();

  const onClickChangePassword = () => {
    setIsPasswordModalOpen(!isPasswordModalOpen);
  };

  const onConfirmPasswordUpdate = async () => {
    await UpdatePassword(
      userAuthInfo.userId || "",
      userAuthInfo.userToken || "",
      oldPassword,
      newPassword,
      newPasswordConfirmation
    );
    setTimeout(disconnectApp, 5000);
  };

  useEffect(() => {
    if (userAuthInfo.userId && userAuthInfo.userToken) {
      getCommercialInformation({
        userId: userAuthInfo.userId,
        userToken: userAuthInfo.userToken,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="monCompte">
      <NavigationBarOutsideStudy title={"Mon compte"} />
      <div className="monCompte__Layout">
        {isCommercialInformationLoading ? (
          <section className="monCompte__LoadingContainer">
            <LoadingComponent diameter={60} />
          </section>
        ) : commercialInformationErrorMessage ? (
          <ErrorMessage errorMessage={commercialInformationErrorMessage} />
        ) : (
          <section className="monCompte__UserInformationContainer">
            <ProfilePicture />
            <div className="userInformation">
              <div className="singleInformation">
                <p className="singleInformation__Title">Prénom Nom</p>
                <p className="singleInformation__Value">
                  {commercialUserInformation.userFirstName}{" "}
                  {commercialUserInformation.userLastName}
                </p>
              </div>
              <div className="singleInformation">
                <p className="singleInformation__Title">Email</p>
                <p className="singleInformation__Value">
                  {commercialUserInformation.userEmail}
                </p>
              </div>
              <div className="singleInformation">
                <p className="singleInformation__Title">Entreprise</p>
                <p className="singleInformation__Value">
                  {commercialUserInformation.userCompany}
                </p>
              </div>
              <div className="singleInformation">
                <p className="singleInformation__Title">Téléphone</p>
                <p className="singleInformation__Value">
                  {commercialUserInformation.userPhoneNumber}
                </p>
              </div>
              <div className="singleInformation">
                <p className="singleInformation__Title">Rôle</p>
                <p className="singleInformation__Value">
                  {commercialUserInformation.userRole}
                </p>
              </div>
              <div className="singleInformation">
                <p className="singleInformation__Title">Mot de passe</p>
                <p className="singleInformation__Value">***************</p>
                <div
                  className="singleInformation__ModificableIcon"
                  onClick={() => onClickChangePassword()}
                >
                  <img src={pictoPen} alt="picto modify input" />
                </div>
              </div>
            </div>
          </section>
        )}
        {isPasswordModalOpen && (
          <>
            <div className="modalBackground" />
            <section className="monCompte__PasswordModal">
              <div
                className="closeModal"
                onClick={() => onClickChangePassword()}
              >
                <img src={closingCross} alt="closingCross" />
              </div>
              <div className="modalHeader">
                <p>Modifier mon mot de passe</p>
              </div>
              <div className="modifyPasswordInputsContainer">
                <div className="singleInputContainer">
                  <p>Ancien mot de passe</p>
                  <PasswordInput
                    value={oldPassword || ""}
                    onChange={(e) => setOldPassword(e.currentTarget.value)}
                  />
                </div>
                <div className="singleInputContainer">
                  <p>Nouveau mot de passe</p>
                  <PasswordInput
                    value={newPassword || ""}
                    onChange={(e) => setNewPassword(e.currentTarget.value)}
                  />
                </div>
                <div className="singleInputContainer">
                  <p>Confirmez le nouveau mot de passe</p>
                  <PasswordInput
                    value={newPasswordConfirmation || ""}
                    onChange={(e) =>
                      setNewPasswordConfirmation(e.currentTarget.value)
                    }
                  />
                </div>
              </div>
              {updatePasswordErrorMessage && (
                <div className="errorMessageContainer">
                  <ErrorMessage errorMessage={updatePasswordErrorMessage} />
                </div>
              )}
              {isUpdatePasswordLoading ? (
                <LoadingComponent diameter={60} />
              ) : (
                <div className="buttonContainer">
                  <CTAButton
                    name="Confirmer"
                    onClick={() => onConfirmPasswordUpdate()}
                  />
                </div>
              )}
            </section>
          </>
        )}
      </div>
      <MountainsFooter />
    </div>
  );
};
