import { Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  titleEncadre: {
    fontSize: 10,
    textTransform: "uppercase",
    padding: "0 10",
    marginTop: 5,
    marginBottom: 5,
    color: "#525360",
    fontFamily: "DMSansMedium",
  },
});

interface IProps {
  title: string;
}

export const PdfTitreMentions = ({ title }: IProps) => {
  return (
    <View style={styles.titleEncadre}>
      <Text>{title}</Text>
    </View>
  );
};
