//Libraries import
import { useState } from "react";
import { useAxiosPost } from "../../useAxiosPost";

//Local interface
interface LocalProps {
  userToken: string | null;
  study_id?: string | null;
  incomeData: {
    domiciled_in_france: boolean;
    owning_type: string;
    household_type: string;
  };
}

//Local interface

export const useSendIncomeData = () => {
  const { isLoading, axiosPostRequest } = useAxiosPost();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const sendIncomeData = async ({
    userToken,
    study_id,
    incomeData,
  }: LocalProps) => {
    setErrorMessage("");

    const response = await axiosPostRequest(
      `${process.env.REACT_APP_EERO_URL}/oree2/rg/sendIncomeData/${study_id}`,
      incomeData,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response && response.status !== 200) {
      setErrorMessage("Send income to dataBackend has failed.");
    }

    return;
  };

  return {
    sendIncomeData,
    isLoading,
    errorMessage: errorMessage,
  };
};
