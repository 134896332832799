//Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";

// Interfaces import
import { IRenoGlobaleProductCategory } from "../../../interfaces/renoglobaleInterface";

//Local interface
interface LocalProps {
  userToken: string | null;
  study_id: string | null;
}

interface IAvailableProductCategoriesResponse {
  available_categories: IRenoGlobaleProductCategory[];
}

export const useGetProductsCategories = () => {
  const { isLoading, axiosGetRequest } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getProductsCategories = async ({ userToken, study_id }: LocalProps) => {
    setErrorMessage("");

    const response = await axiosGetRequest<IAvailableProductCategoriesResponse>(
      `${process.env.REACT_APP_EERO_URL}/oree2/rg/available_product_categories/${study_id}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) return response;

    setErrorMessage("No datas available");
  };

  return {
    getProductsCategories,
    isLoading,
    errorMessage: errorMessage,
  };
};
