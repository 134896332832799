//Style import
import "./RecapTitle.scss";

//Local interface
interface Props {
    image: string;
    title: string;
}

export const RecapTitle = ({ image, title }: Props) => {
    return (
        <div className="RecapTitle__Title">
            <img src={image} alt="logo" />
            <h3 className="title">{title}</h3>
        </div>
    );
};
