// Libraries import
import { useState } from "react";
import { useAxiosPut } from "../useAxiosPut";
import { useNavigate } from "react-router-dom";

// Local interfaces declaration
export interface sendResetClientPasswordRequestProps {
  resetToken: string;
  userPassword: string;
}

export interface IResetPasswordPayload {
  resetToken: string;
  password: string;
}

export interface IResetPasswordRequestAnswer {
  message: string;
}

// useResetClientPassword : return tools to send reset password request
export const useResetClientPassword = () => {
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const navigate = useNavigate();
  const {
    axiosPutRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPut();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // sendResetClientPasswordRequest : send reset password Post request
  const sendResetClientPasswordRequest = async ({
    resetToken,
    userPassword,
  }: sendResetClientPasswordRequestProps): Promise<void> => {
    setErrorMessage("");
    if (resetToken && userPassword) {
      const requestPayload: IResetPasswordPayload = {
        resetToken: resetToken,
        password: userPassword,
      };
      const response = await axiosPutRequest<IResetPasswordRequestAnswer>(
        `${BACKEND_URL}/user/resetPassword`,
        requestPayload
      );

      if (response?.status === 200) {
        navigate("/", {
          state: {
            message: response.data.message,
          },
        });
      }
    } else {
      setErrorMessage("Champs manquants.");
    }
  };

  return {
    sendResetClientPasswordRequest,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
