import React from "react";
import { View, Text } from "@react-pdf/renderer";

// Interface declaration
interface PdfLayoutProps {
  children: React.ReactNode;
  isDemoVersion?: boolean;
}

export const PdfLayout: React.FC<PdfLayoutProps> = ({
  children,
  isDemoVersion,
}) => {
  return (
    <>
      {isDemoVersion ? (
        <View
          fixed
          style={{
            position: "absolute",
            marginTop: "300px",
            marginLeft: "150px",
          }}
        >
          <Text
            fixed
            style={{
              fontSize: "80px",
              color: "rgba(240,128,128, 0.3)",
              transform: "rotate(45deg)",
            }}
          >
            Specimen
          </Text>
        </View>
      ) : null}

      <View
        style={{
          padding: "0 58px",
          position: "relative",
          zIndex: -1,
        }}
      >
        {children}
      </View>
    </>
  );
};
