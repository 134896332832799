import React, { useCallback, useRef, useState } from "react";
import "./mesInformationsMap.scss";
import { GoogleMap } from "@react-google-maps/api";

// redux imports
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import {
  setSolarPotentialStepsStatusAction,
  setStudyInfoAction,
} from "../../../redux/appActions";

// component imports
import { MesInformationsPopup } from "../../../containers/MesInformationsPopup/MesInformationsPopup";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";

// service imports
import { fetchGeoData } from "../../../services/apiCallsIndex";
import { useSaveLatitudeLongitude } from "../../../services/MesInformations/useSaveLatitudeLongitude";
import { useLaunchCalculus } from "../../../services/RenovationGlobale/useLaunchCalculus";

// Interfaces import
import { IGoTo } from "../../../interfaces/generalInterfaces";
import { INavigateData } from "../../../services/useNavigation/useNavigation";

// Image import
import logoGreenTech from "../../../assets/pdf/images/greenTechLogo.png";
import logoIGN from "../../../assets/pdf/images/logoIgnFab.png";

const containerStyle = {
  width: "100%",
  height: "100%",
};

interface Props {
  goNext: (data?: INavigateData<any>) => void;
  goBack: () => void;
  goTo: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
  setIsGoToFunctionActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MesInformationsMap = ({
  goNext,
  goBack,
  goTo,
  setIsGoToFunctionActive,
}: Props) => {
  // redux
  const { studyInformation, userAuthInfo } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  // custom hooks
  const { saveLatitudeLongitude } = useSaveLatitudeLongitude();
  const { launchCalculus, isLoading: isLoadingLaunchCalculus } =
    useLaunchCalculus(); // Start automatic calepinage calculation

  // local states
  const [map, setMap] = useState<
    google.maps.Map | google.maps.StreetViewPanorama | null
  >(null);
  const [mapCenter, setMapCenter] = useState<{
    lat: number;
    lng: number;
  } | null>({
    lat: Number(studyInformation.latitude) || 47.9,
    lng: Number(studyInformation.longitude) || 1.906,
  }); // map is centered on Orleans by default
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const marker = useRef(
    new google.maps.Marker({
      position: mapCenter,
    })
  );

  // Functions
  const handleMarkerAndGeoData = (marker: google.maps.Marker) => {
    if (marker) {
      const latLng = marker.getPosition();
      const markerLat = latLng && latLng.lat();
      const markerLng = latLng && latLng.lng();
      // Create an scoped async function in the hook

      const asyncFetchGeoData = async () => {
        setErrorMessage("");

        if (markerLat && markerLng) {
          const geoInfoFromApi = await fetchGeoData(markerLat, markerLng);
          geoInfoFromApi.data.length > 0 &&
            saveLatitudeLongitude({
              userToken: userAuthInfo.userToken,
              study_id: studyInformation.study_id,
              latitude: Number(markerLat),
              longitude: Number(markerLng),
              zipcode: geoInfoFromApi.data[0].codesPostaux[0],
            });
          if (geoInfoFromApi.data.length < 1) {
            setErrorMessage(
              "Veuillez nous excusez, nous ne sommes pas encore présents hors de France."
            );
          }
        } // Execute the created function directly
      };
      asyncFetchGeoData();
    }
  };

  const onLoad = useCallback(function (
    map: google.maps.Map | google.maps.StreetViewPanorama | null
  ) {
    setMap(map);
    marker.current.setMap(map);
  },
  []);

  const placeMarker = (event: google.maps.MapMouseEvent) => {
    const location = event.latLng;

    setMapCenter(null);

    marker.current.setPosition(location);
    marker.current.setMap(map);

    handleMarkerAndGeoData(marker.current);
  };

  const handleNextButtonClick = async () => {
    const responseLaunchCalculus = await launchCalculus({
      userToken: userAuthInfo.userToken,
      latitude: Number(studyInformation.latitude),
      longitude: Number(studyInformation.longitude),
      household_id: studyInformation.household_id || null,
      study_id: studyInformation?.study_id || "",
    });

    const isNewBuilding =
      studyInformation.building_id !== responseLaunchCalculus?.building_id;

    // If the building_id has changed, we need to reset the solar potential data
    responseLaunchCalculus &&
      dispatch(
        setStudyInfoAction({
          studyInformation: {
            building_id: responseLaunchCalculus.building_id,
            solar_potential_ko: isNewBuilding
              ? undefined
              : studyInformation.solar_potential_ko,
            solar_potential_ok: undefined,
            roof_area_to_equip: isNewBuilding
              ? undefined
              : studyInformation.roof_area_to_equip,
            roof_type: isNewBuilding ? undefined : studyInformation.roof_type,
            roof_subtype: isNewBuilding
              ? undefined
              : studyInformation.roof_subtype,
            roof_solar_mask: isNewBuilding
              ? undefined
              : studyInformation.roof_solar_mask,
            roof_inclinaison: isNewBuilding
              ? undefined
              : studyInformation.roof_inclinaison,
            roof_exposition: isNewBuilding
              ? undefined
              : studyInformation.roof_exposition,
          },
        })
      );

    dispatch(
      setSolarPotentialStepsStatusAction({
        solarPotentialStepsStatus: {
          isLoadingScreenDisplayed: true,
          isSolarPotentialKOForm: false,
        },
      })
    );

    setIsModalOpen(true);
  };

  return (
    <div className="MesInformationsMap">
      <div className="MesInformationsMap__ImagesContainer">
        <img src={logoGreenTech} alt="maison" />
        <img src={logoIGN} alt="maison" />
      </div>
      <div className="MesInformationsMap__Map">
        {process.env.REACT_APP_GOOGLE_API_KEY && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={19}
            onLoad={onLoad}
            onClick={placeMarker}
            clickableIcons={false}
            options={{
              streetViewControl: false,
              mapTypeId: "satellite",
              fullscreenControl: false,
              mapTypeControl: false,
              center: mapCenter,
              tilt: 0,
              zoomControl: false,
            }}
          >
            {/* Child components, such as markers, info windows, etc. */}
          </GoogleMap>
        )}
      </div>
      {!isModalOpen && (
        <div className="MesInformationsMap__NextPageController">
          <p>Est-ce bien votre logement ?</p>
          <p>Si ce n'est pas le cas, cliquez sur votre logement</p>
          <p>
            Cette information nous permet d’estimer
            <br />
            le potentiel solaire de votre logement.
          </p>
          {errorMessage && (
            <p className="MesInformationsMap__ErrorMessage">{errorMessage}</p>
          )}
          <section className="MesInformationsMap__ButtonsContainer">
            <CTAButton
              name="Précédent"
              category="secondary"
              onClick={async () => {
                goBack && goBack();
              }}
            />
            <CTAButton
              name="Suivant"
              onClick={handleNextButtonClick}
              isLoading={isLoadingLaunchCalculus}
              isDisabled={
                !studyInformation.latitude ||
                !studyInformation.longitude ||
                errorMessage !== ""
              }
            />
          </section>
        </div>
      )}
      {isModalOpen && !isLoadingLaunchCalculus && (
        <>
          <div className="backgroundModalBlur" />
          <Modale isModaleOpen>
            <MesInformationsPopup goNext={goNext} />
          </Modale>
        </>
      )}
    </div>
  );
};
