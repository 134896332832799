import React from "react";
import "./potentielSolairePanInformation.scss";

// images import
import pictoSurface from "../../../assets/images/picto-surface-arrows.svg";
import pictoOrientation from "../../../assets/images/picto-orientation.svg";
import pictoSlope from "../../../assets/images/picto-slope.svg";
import closingCross from "../../../assets/images/ClosingCross.svg";

// services import
import { panOrientationLetterToFullString } from "../../../services/panOrientationLetterToFullString";

interface IProps {
  panSurface: number | null;
  panSlope: number | null;
  panOrientation: string | null;
  panIndex: number;
  setClickedPan: React.Dispatch<React.SetStateAction<number | null>>;
  roofAzimuth: number;
}

export const PotentielSolairePanInformation = ({
  panSurface,
  panSlope,
  panOrientation,
  panIndex,
  setClickedPan,
  roofAzimuth,
}: IProps) => {
  return (
    <div className="PotentielSolairePanInformation">
      <div className="PotentielSolairePanInformation__Container">
        <section className="PotentielSolairePanInformation__ComponentHeader">
          <h4>Pan {panIndex + 1}</h4>
          <img
            src={closingCross}
            alt="Close the window"
            width={20}
            height={20}
            onClick={() => setClickedPan(null)}
          />
        </section>
        <section className="PotentielSolairePanInformation__Content">
          {panSurface && (
            <div className="PotentielSolairePanInformation__Item">
              <div className="PotentielSolairePanInformation__ImageContainer">
                <img
                  src={pictoSurface}
                  alt={`La surface disponible du pan numéro ${panIndex + 1}`}
                  width={42}
                  height={25}
                />
              </div>
              <div className="PotentielSolairePanInformation__ItemText">
                <p>Surface</p>
                <p>
                  <strong>{panSurface}&nbsp;m²</strong>
                </p>
              </div>
            </div>
          )}
          {panOrientation && (
            <div className="PotentielSolairePanInformation__Item">
              <div className="PotentielSolairePanInformation__ImageContainer">
                <img
                  src={pictoOrientation}
                  alt={`L'exposition du pan numéro ${panIndex + 1}`}
                  width={49}
                  height={49}
                />
              </div>
              <div className="PotentielSolairePanInformation__ItemText">
                <p>Exposition</p>
                <p>
                  <strong>
                    {panOrientationLetterToFullString(
                      panOrientation.toLowerCase()
                    )}
                  </strong>
                </p>

                <p>
                  <strong>({roofAzimuth}°)</strong>
                </p>
              </div>
            </div>
          )}
          {panSlope && (
            <div className="PotentielSolairePanInformation__Item">
              <div className="PotentielSolairePanInformation__ImageContainer">
                <img
                  src={pictoSlope}
                  alt={`La pente du pan numéro ${panIndex + 1}`}
                  width={43}
                  height={47}
                />
              </div>
              <div className="PotentielSolairePanInformation__ItemText">
                <p>Pente</p>
                <p>
                  <strong>{panSlope}°</strong>
                </p>
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};
