// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Services import
import { setSAGEItemsInfoAction } from "../../redux/appActions";
import { useAppDispatch } from "../../redux/store/hook";

// Interfaces imports
import { ISageProduct } from "../../interfaces/generalInterfaces";

export const useGetSAGEArticleInfo = () => {
  const {
    isLoading,
    errorMessage: axiosErrorMessage,
    axiosGetRequest,
  } = useAxiosGet();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const getSAGEArticleInfo = async ({
    userToken,
    SAGE_reference,
    study_id,
  }: {
    userToken: string | null;
    SAGE_reference?: string | null;
    study_id: string;
  }) => {
    setErrorMessage("");

    const response = await axiosGetRequest<ISageProduct[]>(
      `${process.env.REACT_APP_EERO_URL}/oree2/getSAGEArticleInfo/${study_id}?SAGE_reference=${SAGE_reference}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      dispatch(setSAGEItemsInfoAction({ SAGEItemsInfo: response }));
      return response;
    }
  };

  return {
    getSAGEArticleInfo,
    isLoading,
    errorMessage: axiosErrorMessage || errorMessage,
  };
};
