// images import
import PVIllustration from "@web/shared/dist/assets/images/DesignSystemIllustrations/PV.svg";
import renoGlobaleIllustration from "@web/shared/dist/assets/images/DesignSystemIllustrations/rénovation.svg";

type TAccessNames = "oree2_photovoltaique" | "oree2_renovationglobale";

export type Oree2HomeDataDetails = {
  authorizationName: TAccessNames;
  pollName: string;
  href: string;
  imageSource: string;
  pollDescription: string;
};

export type Oree2HomeData = Oree2HomeDataDetails[];

export const oree2HomeData: Oree2HomeData = [
  {
    authorizationName: "oree2_renovationglobale",
    pollName: "Étude énergétique",
    href: "/start-etude-rg",
    imageSource: renoGlobaleIllustration,
    pollDescription: "Pour une étude complète du logement.",
  },
  {
    authorizationName: "oree2_photovoltaique",
    pollName: "Étude photovoltaïque",
    href: "/start-etude-pv",
    imageSource: PVIllustration,
    pollDescription: "Pour une proposition solaire sur mesure.",
  },
];
