import { useEffect, useState } from "react";
import "./ProfitabilityComponentRG.scss";

// Components import
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { Layout } from "../../../components/Layout/Layout";
import { TopSectionMainInformationRG } from "../../../components/ProfitabilityRGComponents/TopSectionMainInformationRG/TopSectionMainInformationRG";
import { ChartsContainerRG } from "../../../components/ProfitabilityRGComponents/ChartsContainerRG/ChartsContainerRG";
import { GreenValueComponent } from "../../../components/ProfitabilityRGComponents/GreenValueComponent/GreenValueComponent";

// Services import
import { useGetProfitabilityRG } from "../../../services/RenovationGlobale/MonProjet/useGetProfitabilityRG";

// Interfaces import
import {
  IStudyInformation,
  IUserAuthInfo,
  IMonInstallation,
} from "../../../interfaces/generalInterfaces";
import {
  IProfitabilityRG,
  IDataProduct,
} from "../../../interfaces/renoglobaleInterface";
import { useGetImpactEnvironnementalRG } from "../../../services/RenovationGlobale/useGetImpactEnvironnementalRG";

// Local interface declaration
interface IProps {
  studyInformation: IStudyInformation;
  userAuthInfo: IUserAuthInfo;
  monInstallation: IMonInstallation;
  cartProducts: IDataProduct[];
}

export const ProfitabilityComponentRG = ({
  studyInformation,
  userAuthInfo,
  cartProducts,
}: IProps) => {
  const { getProfitabilityRG, isLoading, errorMessage } =
    useGetProfitabilityRG();

  const { getImpactEnvironnementalRG, isLoading: isLoadingGreenValue } =
    useGetImpactEnvironnementalRG();

  const [profitabilityRGData, setProfitabilityRGData] =
    useState<IProfitabilityRG>();

  const [greenValue, setGreenValue] = useState<number[] | null>(null);

  const fetchGetProfitabilityRG = async () => {
    const response = await getProfitabilityRG({
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id || null,
    });

    if (response) setProfitabilityRGData(response);
  };

  const fetchGreenValue = async () => {
    const response = await getImpactEnvironnementalRG({
      city: studyInformation.city || null,
      postcode: studyInformation.zipcode || null,
      study_id: studyInformation.study_id || null,
      userToken: userAuthInfo.userToken,
    });

    response &&
      setGreenValue(() => {
        if (!response.green_value) return null;

        const min = response.green_value < 4 ? 0 : response.green_value - 4;
        const max = response.green_value > 96 ? 100 : response.green_value + 4;
        return [min, max];
      });
  };

  useEffect(() => {
    fetchGetProfitabilityRG();
    fetchGreenValue();
    // eslint-disable-next-line
  }, []);

  const topSectionData = {
    remaining_amount_to_pay: profitabilityRGData?.remaining_amount_to_pay,
    total_net_gain: profitabilityRGData?.cumulated_gain,
  };

  return (
    <div className="ProfitabilityComponentRG">
      <Layout>
        {isLoading && isLoadingGreenValue ? (
          <div className="ProfitabilityComponentRG__LoadingContainer">
            <LoadingComponent diameter={60} />
          </div>
        ) : errorMessage ? (
          <ErrorMessage errorMessage={errorMessage} />
        ) : (
          <>
            <section className="ProfitabilityComponentRG__TopSection">
              <TopSectionMainInformationRG topSectionData={topSectionData} />
            </section>
            <section className="ProfitabilityComponentRG__TotalGainContainer">
              {profitabilityRGData && (
                <div>
                  <ChartsContainerRG
                    profitabilityRGData={profitabilityRGData}
                    studyInformation={studyInformation}
                    cartProducts={cartProducts}
                  />
                </div>
              )}
            </section>

            {greenValue && (
              <section className="ProfitabilityComponentRG__TotalGainContainer">
                <p className="SectionTitle">Valeur verte</p>
                <GreenValueComponent greenValue={greenValue} />
              </section>
            )}
          </>
        )}
      </Layout>
    </div>
  );
};
