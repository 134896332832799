import React from "react";
import "./scenariosListContainer.scss";
import { Layout } from "../../components/Layout/Layout";
import { IDataScenarioPvExtended } from "../../interfaces/generalInterfaces";
import { SingleScenario } from "./SingleScenario/SingleScenario";
import { INavigateData } from "../../services/useNavigation/useNavigation";

interface IProps {
  scenariosList: IDataScenarioPvExtended[];
  goNext: (data?: INavigateData<any>) => void;
  aidesDeduitesVisible: boolean;
  company_id: string | null;
  scenario_id: string | null;
}

export const ScenariosListContainer = ({
  scenariosList,
  goNext,
  aidesDeduitesVisible,
  company_id,
  scenario_id,
}: IProps) => {
  return (
    <div className="ScenariosListContainer">
      <Layout>
        <div className="ScenariosListContainer__Container">
          {scenariosList
            .filter((scenario) => {
              if (company_id === "e77c7d77-f2cf-4d27-aab4-92d8bb684e42") {
                //For now we hide Essentielle for Vecteur as they don't sell Huawei
                return (
                  scenario.formule_name !== "Liberté+" &&
                  scenario.formule_name !== "Essentielle"
                );
              }
              return scenario.formule_name !== "Liberté+"; // Finally we keep only 3 scnearios, by removing the scenario "Liberté"
            })
            .map((singleScenarioData, index) => {
              return (
                <SingleScenario
                  singleScenario={singleScenarioData}
                  key={index}
                  index={index}
                  goNext={goNext}
                  company_id={company_id}
                  scenario_id={scenario_id}
                />
              );
            })}
        </div>
        <div
          hidden={!aidesDeduitesVisible}
          className="ScenariosListContainer__AidesDeduites"
        >
          <p>*Prime à l’autoconsommation déduite</p>
        </div>
      </Layout>
    </div>
  );
};
