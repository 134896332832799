import { StyleSheet, Image, View } from "@react-pdf/renderer";

// Images
import logoTucoLittle from "../../assets/pdf/images/oree_logo_etude.png";

// Create styles
const styles = StyleSheet.create({
  headerContainer: {
    height: 50,
    width: "100%",
    textAlign: "left",
    marginBottom: 20,
    position: "relative",
  },
  image: {
    width: "60",
    objectFit: "contain",
    position: "absolute",
    right: 50,
    top: 20,
    padding: "5 0",
  },
});
export const PdfEtudeHeaderRG = () => {
  return (
    <View fixed style={styles.headerContainer}>
      <Image src={logoTucoLittle} style={styles.image} />
    </View>
  );
};
