//Style import
import "./home.scss";

//Library import
import { useNavigate } from "react-router-dom";

//Component import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

//Service import
import { useDisconnectApp } from "../../services/useDisconnectApp";
import { useAppSelector } from "../../redux/store/hook";

//Img import
import { FooterHills } from "../../components/FooterHills/FooterHills";
import { NavigationBarOutsideStudy } from "../../components/NavigationBarOutsideStudy/NavigationBarOutsideStudy";

export const Home = () => {
  const navigate = useNavigate();
  const { userAuthInfo } = useAppSelector((state) => state);

  const { disconnectApp } = useDisconnectApp();

  return (
    <div className="Home">
      <NavigationBarOutsideStudy title={"Accueil"} />
      <div className="Home__ButtonsContainer">
        <CTAButton
          name="Mon compte"
          onClick={() => navigate("/mon-compte")}
          category="secondary"
        />
        {userAuthInfo.companyId === "b997f1a6-1bf0-e911-a812-000d3a4aabe1" ? (
          // b997f1a6-1bf0-e911-a812-000d3a4aabe1 => tucoenergie
          <>
            <CTAButton
              name="Orée learning"
              category="secondary"
              onClick={() => {
                window.open("http://moodle.tucoenergie.fr/", "_blank");
              }}
            />
            <CTAButton
              name="Carte des installations"
              category="secondary"
              onClick={() => {
                navigate(`/projetGedeon`);
              }}
            />
          </>
        ) : null}

        <CTAButton
          name="Mes études"
          onClick={() => navigate("/mes-etudes")}
          category="secondary"
        />
        <CTAButton
          name="Nouvelle étude"
          category="primary"
          onClick={() => navigate("/oree-home")}
        />
        <button
          className="Deconnexion"
          onClick={() => {
            disconnectApp();
            navigate("/");
          }}
          type="button"
        >
          Se déconnecter
        </button>
      </div>

      <FooterHills />
    </div>
  );
};
