// Styles import
import "./MesConsommationsAndEquipmentsRG.scss";

// Components import
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { Quantite } from "@web/shared/dist/components/DesignSystem/Boutons/Quantite/Quantite";
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import {
  Dropdown,
  manageDropdownSingleSelection,
} from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { TurningBlueStar } from "../../components/Animations/TurningBlueStar/TurningBlueStar";
import { Dualite } from "@web/shared/dist/components/DesignSystem/Boutons/Dualite/Dualite";

// Images import
import Chauffage from "@web/shared/dist/assets/images/DesignSystemIllustrations/chauffage1.svg";
import ECS from "@web/shared/dist/assets/images/DesignSystemIllustrations/ECS1.svg";
import GazCuisine from "@web/shared/dist/assets/images/DesignSystemIllustrations/gaz-cuisine1.svg";
import gaz from "@web/shared/dist/assets/images/DesignSystemIllustrations/gaz1.svg";

// Data import
import { mesConsommationsQuestionsData } from "./MesConsommationsQuestionsData";

// Interfaces import
import { IStudyInformation } from "../../interfaces/generalInterfaces";

// Local interfaces declaration
interface IProps {
  studyInformation: IStudyInformation;
  gazConsommationEuros: string | null;
  isGaz: boolean;
  setGazConsommationEuros: React.Dispatch<React.SetStateAction<string | null>>;
  gazConsommationKwh: string | null;
  setGazConsommationKwh: React.Dispatch<React.SetStateAction<string | null>>;
  gazPrincipauxEquipments: IOption[];
  setGazPrincipauxEquipments: React.Dispatch<React.SetStateAction<IOption[]>>;
  gazSurfaceChauffee: string | null;
  setGazSurfaceChauffee: React.Dispatch<React.SetStateAction<string | null>>;
  gazTemperatureConfort: number | null;
  setGazTemperatureConfort: React.Dispatch<React.SetStateAction<number | null>>;
  gazTypeChauffage: IOption[];
  setGazTypeChauffage: React.Dispatch<React.SetStateAction<IOption[]>>;
  gazNombreEmetteur: number | null;
  setGazNombreEmetteur: React.Dispatch<React.SetStateAction<number | null>>;
  gazTypeEmetteur: IOption[];
  setGazTypeEmetteur: React.Dispatch<React.SetStateAction<IOption[]>>;
  gazStorageCapacity: IOption[];
  setGazStorageCapacity: React.Dispatch<React.SetStateAction<IOption[]>>;
  gazChauffageConsumptionRate: string | null;
  setGazChauffageConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  gazECSConsumptionRate: string | null;
  setGazECSConsumptionRate: React.Dispatch<React.SetStateAction<string | null>>;
  gazAutresConsumptionRate: string | null;
  setGazAutresConsumptionRate: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  sourceEnergie: IOption[];
  gazIsECSIntegratedToHeatingSystem: boolean | null;
  setGazIsECSIntegratedToHeatingSystem: React.Dispatch<
    React.SetStateAction<boolean | null>
  >;
  setPriceGazPerkWh: React.Dispatch<React.SetStateAction<number>>;
  priceGazPerkWh: number;
  checkGazEquipmentsDistribution: () => boolean;
  resetChauffageSection: () => void;
  resetECSSection: () => void;
}

interface IOption extends IImage {
  label: string | null;
  value: string | null;
}

interface IImage {
  imgSrc?: string;
  imgAltTxt?: string;
}

export const GazPartForm = ({
  gazConsommationEuros,
  isGaz,
  setGazConsommationEuros,
  gazConsommationKwh,
  setGazConsommationKwh,
  gazPrincipauxEquipments,
  setGazPrincipauxEquipments,
  gazSurfaceChauffee,
  setGazSurfaceChauffee,
  gazTemperatureConfort,
  setGazTemperatureConfort,
  gazTypeChauffage,
  setGazTypeChauffage,
  gazNombreEmetteur,
  setGazNombreEmetteur,
  gazTypeEmetteur,
  setGazTypeEmetteur,
  gazStorageCapacity,
  setGazStorageCapacity,
  gazChauffageConsumptionRate,
  setGazChauffageConsumptionRate,
  gazECSConsumptionRate,
  setGazECSConsumptionRate,
  gazAutresConsumptionRate,
  setGazAutresConsumptionRate,
  sourceEnergie,
  gazIsECSIntegratedToHeatingSystem,
  setGazIsECSIntegratedToHeatingSystem,
  setPriceGazPerkWh,
  priceGazPerkWh,
  checkGazEquipmentsDistribution,
  resetChauffageSection,
  resetECSSection,
}: IProps) => {
  const allEquipmentsConsumption = [
    Number(gazChauffageConsumptionRate),
    Number(gazAutresConsumptionRate),
    Number(gazECSConsumptionRate),
  ];

  const inputErrorMessage = () => {
    const totalEquipmentsConsumptionPercent = allEquipmentsConsumption.reduce(
      (acc, curr) => acc + curr,
      0
    );

    return `Distribué : ${totalEquipmentsConsumptionPercent}% | Restant :
      ${100 - totalEquipmentsConsumptionPercent}%`;
  };

  return (
    <section
      className={`MesConsommationsAndEquipementsRG__SectionContent ${
        !sourceEnergie.some((item) => item.value === "gaz") && "Hidden"
      }`}
      id="caracteristiques_du_logement"
    >
      <div className="MesConsommationsAndEquipementsRG__TitleOne">
        <img src={gaz} alt="gaz" />
        <h3>Gaz</h3>
      </div>
      <section>
        <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
          <label className="label" htmlFor="consommationElec">
            Consommation de gaz annuelle :{" "}
          </label>
          <div style={{ maxWidth: "130px" }}>
            <TextInput
              inputLableHidden
              type="number"
              inputDescriptionHidden
              iconType="euros"
              placeholder="XXXX"
              value={gazConsommationEuros ?? undefined}
              onChange={(e) => {
                let consommationKwhAnnuelle = Math.trunc(
                  Number(e.currentTarget.value) / priceGazPerkWh
                );
                setGazConsommationEuros(e.currentTarget.value);
                setGazConsommationKwh(String(consommationKwhAnnuelle));
              }}
              required={isGaz}
              iconHidden={false}
            />
          </div>
          <div style={{ maxWidth: "130px" }}>
            <TextInput
              inputLableHidden
              type="number"
              inputDescriptionHidden
              iconType="kWh"
              placeholder="XXXX"
              value={gazConsommationKwh ?? undefined}
              onChange={(e) => {
                let consommationEuroAnnuelle = Math.trunc(
                  Number(e.currentTarget.value) * priceGazPerkWh
                );
                setGazConsommationKwh(e.currentTarget.value);
                setGazConsommationEuros(String(consommationEuroAnnuelle));
              }}
              required={isGaz}
              iconHidden={false}
            />
          </div>
          <div className={`MesConsommationsAndEquipementsRG__conversionBlock`}>
            <div className={`MesConsommationsAndEquipementsRG__line`} />
            <div
              className={`MesConsommationsAndEquipementsRG__PricePerWattHour`}
            >
              <p>Soit</p>{" "}
              <TextInput
                inputLableHidden
                type="number"
                inputDescriptionHidden
                iconType="€/kWh"
                placeholder="XXXX"
                value={priceGazPerkWh?.toString()}
                onChange={(e) => {
                  const newConsommationInkWh =
                    Number(gazConsommationEuros) /
                    e.currentTarget.valueAsNumber;
                  setPriceGazPerkWh(e.currentTarget.valueAsNumber);
                  setGazConsommationKwh(newConsommationInkWh.toFixed());
                }}
                iconHidden={false}
              />
            </div>
          </div>
        </div>
        <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
          <label className="label" htmlFor="equipments">
            Principaux équipements :{" "}
          </label>
          <ChoixMultiple
            options={
              mesConsommationsQuestionsData.gaz.principauxEquipements.options
            }
            value={gazPrincipauxEquipments}
            onClick={(e) => {
              const equipmentToUnselected = [...gazPrincipauxEquipments].find(
                (option) => option.label === e?.label
              );
              e &&
                setGazPrincipauxEquipments((state) => {
                  const newState = [...state];
                  const foundIndex = newState.findIndex(
                    (option) => option.value === e.value
                  );
                  if (foundIndex !== -1) {
                    newState.splice(foundIndex, 1);
                  } else {
                    newState.push(e);
                  }
                  return newState;
                });

              //handle deselect option equipment
              switch (equipmentToUnselected?.label) {
                case "Chauffage":
                  resetChauffageSection();
                  break;
                case "Eau chaude sanitaire (ECS)":
                  resetECSSection();
                  break;
                case "Autres (cuisine)":
                  setGazAutresConsumptionRate(null);
                  break;
              }
            }}
            isRequired={isGaz}
          />
        </div>
      </section>
      {gazPrincipauxEquipments.find((elem) => elem.value === "chauffage") ? (
        <section>
          <div className="MesConsommationsAndEquipementsRG__Trait" />
          <div className="MesConsommationsAndEquipementsRG__TitleTwo">
            <h3>Chauffage</h3>
          </div>
          <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
            <label className="label" htmlFor="surfaceChauffee">
              Surface chauffée :{" "}
            </label>
            <div style={{ maxWidth: "102px" }}>
              <TextInput
                inputLableHidden
                type="number"
                inputDescriptionHidden
                iconType="squaredMeters"
                iconHidden={false}
                placeholder="xxxx"
                value={gazSurfaceChauffee ?? undefined}
                onChange={(e) => {
                  setGazSurfaceChauffee(e.currentTarget.value);
                }}
                required={
                  isGaz &&
                  !!gazPrincipauxEquipments.find(
                    (elem) => elem.value === "chauffage"
                  )
                }
              />
            </div>
          </div>
          <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
            <label className="label" htmlFor="temperatureConfort">
              Température de confort (°C):{" "}
            </label>
            <Quantite
              value={gazTemperatureConfort ?? 0}
              onPlusMinusClick={(e) => setGazTemperatureConfort(e)}
            />
          </div>
          <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
            <div
              className={"MesConsommationsAndEquipementsRG__imgLabelWrapper"}
            >
              <TurningBlueStar />
              <label className="label" htmlFor="typeChauffage">
                Type de chaudière :{" "}
              </label>
            </div>
            <ChoixMultiple
              options={
                mesConsommationsQuestionsData.gaz.typeDeChaudiere.options
              }
              value={gazTypeChauffage}
              onClick={(e) => {
                e &&
                  setGazTypeChauffage((state) => {
                    const newState = [...state];
                    const foundIndex = newState.findIndex(
                      (option) => option.label === e.label
                    );
                    if (foundIndex !== -1) {
                      newState.splice(foundIndex, 1);
                    } else {
                      return [e];
                    }
                    return newState;
                  });
              }}
              isRequired={
                isGaz &&
                !!gazPrincipauxEquipments.find(
                  (elem) => elem.value === "chauffage"
                )
              }
            />
          </div>
          <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
            <label className="label" htmlFor="typeEmetteur">
              Type d’émetteur :{" "}
            </label>
            <ChoixMultiple
              options={mesConsommationsQuestionsData.gaz.typeDEmetteur.options}
              value={gazTypeEmetteur}
              onClick={(e) => {
                if (!e) return;

                setGazTypeEmetteur((state) => {
                  const newState = [...state];
                  const foundIndex = newState.findIndex(
                    (option) => option.label === e.label
                  );
                  if (foundIndex !== -1) {
                    newState.splice(foundIndex, 1);
                  } else {
                    return [e];
                  }
                  return newState;
                });

                if (e.value === "plancherChauffant") {
                  setGazNombreEmetteur(1);
                }
              }}
              isRequired={
                isGaz &&
                !!gazPrincipauxEquipments.find(
                  (elem) => elem.value === "chauffage"
                )
              }
            />
          </div>
          {gazTypeEmetteur.length &&
            gazTypeEmetteur[0].value !== "plancherChauffant" && (
              <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
                <label className="label" htmlFor="nombreEmetteurs">
                  Nombre d’émetteur(s) :{" "}
                </label>
                <Quantite
                  value={gazNombreEmetteur ?? 0}
                  onPlusMinusClick={(e) => setGazNombreEmetteur(e)}
                />
              </div>
            )}
        </section>
      ) : null}
      {gazPrincipauxEquipments.find(
        (elem) => elem.value === "eauChaudeSanitaire"
      ) ? (
        <section>
          <div className="MesConsommationsAndEquipementsRG__Trait" />
          <div className="MesConsommationsAndEquipementsRG__TitleTwo">
            <h3>Eau chaude (ECS)</h3>
          </div>
          <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
            <label className="label" htmlFor="storageCapacity">
              ECS intégré au système de chauffage :{" "}
            </label>
            <div className="MesConsommationsAndEquipementsRG__DropdowContainer">
              <Dualite
                binaryValue={gazIsECSIntegratedToHeatingSystem}
                onSelect={(value) =>
                  setGazIsECSIntegratedToHeatingSystem(value)
                }
                isRequired={
                  isGaz &&
                  !!gazPrincipauxEquipments.find(
                    (elem) => elem.value === "eauChaudeSanitaire"
                  )
                }
              />
            </div>
          </div>
          <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
            <label className="label" htmlFor="storageCapacity">
              Capacité de stockage :{" "}
            </label>
            <div className="MesConsommationsAndEquipementsRG__DropdowContainer">
              <Dropdown
                placeholder="Sélectionnez votre volume"
                options={
                  mesConsommationsQuestionsData.gaz.capaciteDeStockage.options
                }
                tags={false}
                optionsSelected={
                  gazStorageCapacity.length > 0 ? gazStorageCapacity : []
                }
                multipleChoices={false}
                onSelect={(option) => {
                  manageDropdownSingleSelection(option, setGazStorageCapacity);
                }}
                isRequired={
                  isGaz &&
                  !!gazPrincipauxEquipments.find(
                    (elem) => elem.value === "eauChaudeSanitaire"
                  )
                }
              />
            </div>
          </div>
        </section>
      ) : null}
      {gazPrincipauxEquipments.length > 1 ? (
        <section>
          <div className="MesConsommationsAndEquipementsRG__Trait" />
          <div className="MesConsommationsAndEquipementsRG__Synthese">
            <div className="MesConsommationsAndEquipementsRG__TitleSynthese">
              <h3>Synthèse</h3>
            </div>
            <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
              <label className="label" htmlFor="consommationElec">
                Consommation de gaz annuelle :{" "}
              </label>
              <div style={{ maxWidth: "102px" }}>
                <TextInput
                  inputLableHidden
                  type="number"
                  inputDescriptionHidden
                  iconType="euros"
                  iconHidden={false}
                  placeholder="XXXX"
                  value={gazConsommationEuros ?? undefined}
                  onChange={(e) => {
                    setGazConsommationEuros(e.currentTarget.value);
                  }}
                  required={isGaz && gazPrincipauxEquipments.length > 1}
                />
              </div>
            </div>
            <div className="MesConsommationsAndEquipementsRG__SyntheseRow">
              {gazPrincipauxEquipments.find(
                (elem) => elem.value === "chauffage"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={Chauffage} alt="electricite" />
                    <label className="label" htmlFor="chauffageEuros">
                      Chauffage{" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkGazEquipmentsDistribution()}
                      inputLableHidden
                      type="number"
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={gazChauffageConsumptionRate ?? undefined}
                      onChange={(e) => {
                        setGazChauffageConsumptionRate(e.currentTarget.value);
                      }}
                      required={
                        isGaz &&
                        gazPrincipauxEquipments.length > 1 &&
                        !!gazPrincipauxEquipments.find(
                          (elem) => elem.value === "chauffage"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(gazConsommationEuros) *
                        Number(gazChauffageConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
              {gazPrincipauxEquipments.find(
                (elem) => elem.value === "eauChaudeSanitaire"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={ECS} alt="electricite" />
                    <label className="label" htmlFor="ECSEuros">
                      Eau chaude sanitaire :{" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkGazEquipmentsDistribution()}
                      inputLableHidden
                      type="number"
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={gazECSConsumptionRate ?? undefined}
                      onChange={(e) => {
                        setGazECSConsumptionRate(e.currentTarget.value);
                      }}
                      required={
                        isGaz &&
                        gazPrincipauxEquipments.length > 1 &&
                        !!gazPrincipauxEquipments.find(
                          (elem) => elem.value === "eauChaudeSanitaire"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(gazConsommationEuros) *
                        Number(gazECSConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
              {gazPrincipauxEquipments.find(
                (elem) => elem.value === "autres"
              ) ? (
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionContainer">
                  <div className="MesConsommationsAndEquipementsRG__SyntheseLabelContainer">
                    <img src={GazCuisine} alt="electricite" />
                    <label className="label" htmlFor="autresEuros">
                      Autres (électroménager...){" "}
                    </label>
                  </div>
                  <div
                    style={{ maxWidth: "102px" }}
                    className="MesConsommationsAndEquipementsRG__SyntheseTextInputContainer"
                  >
                    <TextInput
                      error={checkGazEquipmentsDistribution()}
                      inputLableHidden
                      type="number"
                      iconType="%"
                      iconHidden={false}
                      placeholder="XX"
                      value={gazAutresConsumptionRate ?? undefined}
                      onChange={(e) => {
                        setGazAutresConsumptionRate(e.currentTarget.value);
                      }}
                      required={
                        isGaz &&
                        gazPrincipauxEquipments.length > 1 &&
                        !!gazPrincipauxEquipments.find(
                          (elem) => elem.value === "autres"
                        )
                      }
                    />
                    <p>
                      Soit{" "}
                      {(Number(gazConsommationEuros) *
                        Number(gazAutresConsumptionRate)) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionMessageContainer">
              <p>{inputErrorMessage()}</p>
            </div>
          </div>
        </section>
      ) : null}
    </section>
  );
};
