import { Text, StyleSheet, View, Image, Font } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../../../assets/pdf/fonts/DMSansBold.ttf";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  containerToit: {
    backgroundColor: "#f8f8f8",
    borderRadius: 5,
    marginBottom: 15,
  },
  containerToit__containerImages: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 5,
  },
  containerToit__containerImages__image: {
    width: 175,
    height: 175,
    borderRadius: 5,
    objectFit: "cover",
  },
  containerToit__containerImages__legend: {
    width: 80,
    objectFit: "contain",
  },
  containerToit__containerDescription: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 9,
    padding: "15",
  },
});

interface Props {
  image1: string;
  image2: string;
  image3: string;
  pans: number;
  surface: number | null | undefined;
  material: string | null | undefined;
}

export const PdfCaracteristiqueToit = ({
  image1,
  image2,
  image3,
  pans,
  surface,
  material,
}: Props) => {
  return (
    <View style={styles.containerToit}>
      <View style={styles.containerToit__containerImages}>
        <Image
          style={styles.containerToit__containerImages__image}
          src={image1}
        />
        <Image
          style={styles.containerToit__containerImages__image}
          src={image2}
        />
        <Image
          style={styles.containerToit__containerImages__legend}
          src={image3}
        />
      </View>
      <View style={styles.containerToit__containerDescription}>
        <Text>
          Nombre de pan(s) :{" "}
          <Text style={{ fontFamily: "DMSansBold" }}>{pans}</Text>
        </Text>
        <Text>
          Surface totale du toit :{" "}
          <Text style={{ fontFamily: "DMSansBold" }}>{surface} m²</Text>
        </Text>
        <Text>
          Matériau :{" "}
          <Text style={{ fontFamily: "DMSansBold" }}>{material}</Text>
        </Text>
      </View>
    </View>
  );
};
