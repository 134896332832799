// Style import
import "./DepotsMarkersContainer.scss";

// Libraries import
import { useEffect, useRef, useState } from "react";

// Components import
import { DepotMarker } from "../../../pages/ProjectsMap/DepotMarker/DepotMarker";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { Switch } from "@web/shared/dist/components/DesignSystem/Switch/Switch";

// Services import
import { useGetDepots } from "../../../services/Oree2/ProjectsMap/useGetDepots";

// Interfaces import
import {
  ISageDepot,
  IUserAuthInfo,
} from "../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface Props {
  userAuthInfo: IUserAuthInfo;
}

export const DepotsMarkersContainer = ({ userAuthInfo }: Props) => {
  // Hooks declaration
  const { getDepots } = useGetDepots();

  // States declaration
  const [depots, setDepots] = useState<ISageDepot[]>([]);
  const [isDisplayDepots, setIsDisplayDepots] = useState(false);
  const isMarkerClicked = useRef(false);
  const [isFetchingLoading, setIsFetchingLoading] = useState(false);

  // Functions declaration
  const fetchDepots = async () => {
    setIsFetchingLoading(true);
    const depots = await getDepots({ userToken: userAuthInfo.userToken });
    setDepots(depots);
    setIsFetchingLoading(false);
  };

  useEffect(() => {
    depots.length === 0 && fetchDepots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="DepotsMarkersContainer">
      <div className="DepotsMarkersContainer__Switch">
        {isFetchingLoading ? (
          <div className="ProjectsMarkersContainer__Loader">
            <LoadingComponent diameter={20} />
            <p>Chargement des entrepots...</p>{" "}
          </div>
        ) : (
          <>
            <Switch
              value={isDisplayDepots}
              onClick={() => setIsDisplayDepots((state) => !state)}
              name="depots"
            />
            <p>Afficher les entrepots</p>
          </>
        )}
      </div>
      {isDisplayDepots
        ? depots.map((depot, index) => {
            return (
              <DepotMarker
                depot={depot}
                index={index}
                userToken={userAuthInfo?.userToken || ""}
                key={"MarkerWhitInfoWindow" + index}
                isMarkerClicked={isMarkerClicked}
              />
            );
          })
        : null}
    </div>
  );
};
