import styled from "styled-components";

// Styles import
import { stylesVariables } from "../../styles/variables";

//Local interface

// Local interface declaration
export interface ModalityPopUpProps {
  backgroundColor?: string;
  color?: string;
  content?: string | JSX.Element;
  positionRight?: boolean;
}

const PopUPContainer = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border: 1px solid ${stylesVariables.Stablegrey};
  position: relative;
  display: inline-block;
  top: 0;
  > p {
    padding-top: 1px;
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 0.8125rem;
    color: ${stylesVariables.Stablegrey};
    text-align: center;
  }
  &:hover {
    .ModalitéPopUPText {
      visibility: visible;
      p {
        font-size: 13px;
        font-family: "DM Sans", serif;
        font-weight: 400;
        text-align: left;
      }
    }
  }
`;

const PopUPText = styled.div<ModalityPopUpProps>`
  visibility: hidden;
  width: 266px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  text-align: center;
  border-radius: 6px;
  padding: 15px 10px;

  position: absolute;
  z-index: 1;
  top: 180%;

  ${(props) =>
    props.positionRight === true ? "left : -320%;" : "right : -10%;"};

  margin-right: -50px;
  align-items: center;

  &::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    ${(props) => (props.positionRight === true ? "right" : "left")}: 80%;
    margin-left: -5px;
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent ${(props) => props.backgroundColor}
      transparent;
  }
`;

export const ModalityPopUp: React.FC<ModalityPopUpProps> = (props) => {
  const {
    content,
    backgroundColor = "black",
    color = "white",
    positionRight = true,
  } = props;

  return (
    <PopUPContainer>
      <p>?</p>

      <PopUPText
        backgroundColor={backgroundColor}
        color={color}
        positionRight={positionRight}
        className="ModalitéPopUPText"
      >
        {content}
      </PopUPText>
    </PopUPContainer>
  );
};
