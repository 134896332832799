//Style import
import "./selfConsumptionComponent.scss";

//picto import
import picto_autoconso from "../../../assets/images/picto_autoconso.svg";

//Component import
import { ModalityPopUp } from "@web/shared/dist/containers/ModalityPopUp/ModalityPopUp";

//Local interface
interface IProps {
  infoBulle: JSX.Element | string;
  title: string;
  description: JSX.Element;
  number: number | null;
}

export const SelfConsumptionComponent = ({
  infoBulle,
  title,
  description,
  number,
}: IProps) => {
  return (
    <div className="SelfConsumptionComponent">
      <div className="SelfConsumptionComponent__Picto">
        <img src={picto_autoconso} alt="picto conso" />
      </div>
      <div className="SelfConsumptionComponent__Title">
        <h4>{title}</h4>
        <ModalityPopUp
          backgroundColor="black"
          color="withe"
          positionRight={false}
          content={infoBulle}
        />
      </div>
      <div className="SelfConsumptionComponent__Rate">
        {number ? <span>{number}%</span> : <p>Donnée indisponible</p>}
      </div>
      {number ? description : null}
    </div>
  );
};
