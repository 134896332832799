import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

//Interface import
import { IImpactEnvironnemental } from "../../interfaces/generalInterfaces";

//Service import
import { useAppDispatch } from "../../redux/store/hook";
import { setImpactEnvironnementalAction } from "../../redux/appActions";

// Local interface declaration
interface IGetImpactEnvironnementalProps {
  userToken: string | null;
  study_id: string | null;
  city: string | null;
  postcode: string | null;
}

export const useGetImpactEnvironnementalRG = () => {
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const getImpactEnvironnementalRG = async ({
    userToken,
    study_id,
    city,
    postcode,
  }: IGetImpactEnvironnementalProps) => {
    const response = await axiosGetRequest<IImpactEnvironnemental>(
      `${BACKEND_URL}/oree2/rg/get_impact_environnemental/${study_id}?city=${city}&postcode=${postcode}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      dispatch(
        setImpactEnvironnementalAction({
          impactEnvironnemental: { ...response },
        })
      );
      return response;
    } else {
      setErrorMessage(
        "Erreur lors de la récupération des données d'impact environnemental"
      );
    }
  };
  return {
    getImpactEnvironnementalRG,
    isLoading,
    errorMessage: errorMessage || axiosErrorMessage,
  };
};
