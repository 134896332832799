// Libraries import
import { useState } from "react";
import { useAxiosPut } from "../useAxiosPut";

// Local interface declaration
export interface ISavePotentielSolaireProps {
  userToken: string | null;
  study_id?: string | null;
  payload: ISaveFormPotentielSolaireInfo;
}

interface ISaveFormPotentielSolaireInfo {
  building_id?: string | null;
  roof_type?: string | null;
  roof_subtype?: string | null;
  roof_inclinaison?: number | null;
  roof_exposition?: string | null;
  roof_solar_mask?: number | null;
  roof_area_to_equip?: number | null;
  roof_sections?: {
    orientation?: string | null;
    area_m2?: number | null;
    slope_degrees?: number | null;
  }[];
  solar_potential_image?: string | null;
  building_image?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  isFormValues?: boolean;
}

export const useSavePotentielSolaire = () => {
  const {
    isLoading,
    errorMessage: axiosErrorMessage,
    axiosPutRequest,
  } = useAxiosPut();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const savePotentielSolaire = async ({
    userToken,
    study_id,
    payload,
  }: ISavePotentielSolaireProps) => {
    setErrorMessage("");

    const response = await axiosPutRequest(
      `${process.env.REACT_APP_EERO_URL}/oree2/saveSolarPotential/${study_id}`,
      payload,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response && response.status !== 200) {
      setErrorMessage("Erreur de la suavegarde des données.");
    }

    return response;
  };

  return {
    savePotentielSolaire,
    isLoading,
    errorMessage: axiosErrorMessage || errorMessage,
  };
};
