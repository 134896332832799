// Libraries import
import { useState } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

// Service import
import { useSetUserAuthInfo } from "./Login/useSetUserAuthInfo";

// useAxiosPut : return tools to send Put request and handle error messages
export const useAxiosPut = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setUserAuthInfo } = useSetUserAuthInfo();

  // axiosPutRequest : send Put request and handle error cases
  const axiosPutRequest = async <T>(
    URL: string,
    body: unknown,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse<T> | void> => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const response = await axios.put<T>(URL, body, config);
      if (response.status.toString()[0] === "2") {
        setIsLoading(false);
        return response;
      }
      setErrorMessage("Une erreur est survenue, merci de réessayer.");
      setIsLoading(false);
      return;
    } catch (error: unknown) {
      const axiosError = error as AxiosError;
      // client received an error response (5xx, 4xx)
      if (axiosError.response) {
        if (axiosError.response.status === 401) {
          setErrorMessage(
            axiosError.response?.data?.message || "Non autorisé."
          );
          // If response is unauthorized we reset user authorization info -> redirect the user to the login page
          setUserAuthInfo(null);
          setIsLoading(false);
          return;
        }
        if (axiosError.response?.data?.details?.length > 0) {
          // If we recieve an error from zod
          const message = axiosError.response?.data?.details
            ?.map((detail: { message: string }) => detail.message)
            .join("\n");
          setErrorMessage(message || "Information incorrect.");
          setIsLoading(false);
          return;
        }
        setErrorMessage(
          axiosError.response?.data?.message ||
            "Une erreur est survenue coté serveur."
        );
        setIsLoading(false);
        return;
      } else if (axiosError.request) {
        // client never received a response, or request never left
        if (typeof axiosError.request.data === "string") {
          setErrorMessage(axiosError.request.data);
          setIsLoading(false);
          return;
        }
        setErrorMessage("Erreur serveur, merci de réessayer.");
        setIsLoading(false);
        return;
      } else {
        setErrorMessage("Une erreur est survenue, merci de réessayer.");
      }
      setIsLoading(false);
    }
  };

  return { axiosPutRequest, isLoading, errorMessage, setErrorMessage };
};
