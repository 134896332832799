//Libraries import
import { useState } from "react";
import { useAxiosPost } from "../../useAxiosPost";
import { IDataProduct } from "../../../interfaces/renoglobaleInterface";

//Local interface
interface LocalProps {
  userToken: string | null;
  study_id?: string | null;
  cartProducts:
    | IDataProduct[]
    | {
        product_type: string;
        sage_reference: string;
        selected_characteristics: {
          kit_home_management: boolean;
          n_batteries: number;
        };
      }[];
}

//Local interface

export const useSaveCartProducts = () => {
  const { isLoading, axiosPostRequest } = useAxiosPost();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const saveCartProducts = async ({
    userToken,
    study_id,
    cartProducts,
  }: LocalProps) => {
    setErrorMessage("");

    const response = await axiosPostRequest(
      `${process.env.REACT_APP_EERO_URL}/oree2/rg/saveCartProducts/${study_id}`,
      { cartProducts },
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response && response.status !== 200) {
      setErrorMessage("Save cart products has failed.");
    }

    return response;
  };

  return {
    saveCartProducts,
    isLoading,
    errorMessage: errorMessage,
  };
};
