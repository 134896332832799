import "./SubHeader.scss";

// Components import
import { Layout } from "../../components/Layout/Layout";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

// Interfaces import
import { INavigateData } from "../../services/useNavigation/useNavigation";

// Local interfaces declaration
interface Props {
  title?: string;
  goNext: (data?: INavigateData<any>) => void;
  canGoNext?: boolean;
  form?: string;
  isLoading?: boolean;
  errorMessage?: string;
  buttonName?: string;
  isNextButtonVisible?: boolean;
  isTitleVisible?: boolean;
  clientInfo?: string;
}

export const SubHeader = ({
  title = "Title",
  goNext,
  canGoNext = true,
  form,
  isLoading = false,
  errorMessage,
  buttonName = "Suivant",
  isNextButtonVisible = true,
  isTitleVisible = true,
  clientInfo,
}: Props) => {
  return (
    <Layout>
      <div className="SubHeader">
        {clientInfo && <p className="clientInfo">{clientInfo}</p>}
        <div className="SubHeader__Container">
          {isTitleVisible && (
            <div className="SubHeader__Title">
              <h2>{title}</h2>
            </div>
          )}
          {isNextButtonVisible && (
            <div className="SubHeader__CallToAction">
              <CTAButton
                name={buttonName}
                onClick={() => goNext()}
                isDisabled={!canGoNext}
                form={form}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
        <div>
          <ErrorMessage errorMessage={errorMessage || " "} />
        </div>
      </div>
    </Layout>
  );
};
