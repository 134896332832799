import { Text, StyleSheet, Font, View, Image } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../../../assets/pdf/fonts/DMSansBold.ttf";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#f8f8f8",
    fontSize: 9,
    borderRadius: 5,
    marginBottom: 20,
  },
  container__image: {
    width: "30%",
    borderRadius: 5,
  },
  container__containerTexts: {
    paddingLeft: 20,
  },
  container__containerTexts__text: {
    marginBottom: 10,
  },
});

interface Props {
  couverture: string | null | undefined;
  inclinaison: number | null | undefined;
  exposition: string | null | undefined;
  impactMasqueSolaire: number | null | undefined;
  image1: string;
}
export const PdfCaracteristiqueToitOneImage = ({
  couverture,
  inclinaison,
  exposition,
  impactMasqueSolaire,
  image1,
}: Props) => {
  return (
    <View style={styles.container}>
      <View style={styles.container__containerTexts}>
        {couverture && (
          <Text style={styles.container__containerTexts__text}>
            Type de couverture :{" "}
            <Text style={{ fontFamily: "DMSansBold" }}>{couverture}</Text>
          </Text>
        )}
        {inclinaison && (
          <Text style={styles.container__containerTexts__text}>
            Inclinaison :{" "}
            <Text style={{ fontFamily: "DMSansBold" }}>{inclinaison}°</Text>
          </Text>
        )}
        {exposition && (
          <Text style={styles.container__containerTexts__text}>
            Exposition :{" "}
            <Text style={{ fontFamily: "DMSansBold" }}>{exposition}</Text>
          </Text>
        )}
        {impactMasqueSolaire && (
          <Text style={styles.container__containerTexts__text}>
            Impact du masque solaire :{" "}
            <Text style={{ fontFamily: "DMSansBold" }}>
              {impactMasqueSolaire} %
            </Text>
          </Text>
        )}
      </View>
      <Image style={styles.container__image} src={image1} />
    </View>
  );
};
