//import style
import "./SolarPerformanceComponentRG.scss";

//Picto import
import picto_production from "../../../assets/images/picto_production.svg";

//Composant import
import { ConsumptionComponent } from "../../../components/PerformanceComponents/ConsumptionComponent/ConsumptionComponent";
import { IndependenceLevelComponent } from "../../../components/PerformanceComponents/IndependenceLevelComponent/IndependenceLevelComponent";
import { ChartComponentRG } from "../../../components/ChartComponentRG/ChartComponentRG";
// Interfaces import
import { IPerformanceRG } from "../../../interfaces/renoglobaleInterface";
import { SolarPerformanceKPIsRG } from "../../../components/SolarPerformanceKPIsRG/SolarPerformanceKPIsRG";

// Local interface declaration
interface IProps {
  performanceRGData?: IPerformanceRG | null;
}

export const SolarPerformanceComponentRG = ({ performanceRGData }: IProps) => {
  const dataForChartComponent = {
    dataForChart: performanceRGData?.hourly_consumption_production || null,
  };

  const dataForConsumptionComponent = {
    annualConsumption:
      performanceRGData?.annual_electric_consumption_kWh ?? undefined,
    annualProduction:
      performanceRGData?.annual_electric_production_kWh ?? undefined,
    solarConsumption: performanceRGData?.solar_consumption ?? undefined,
    networkConsumption: performanceRGData?.network_consumption ?? undefined,
    productionWhithoutShadowCasting:
      performanceRGData?.annual_electric_production_without_shadow_casting_kWh ??
      undefined,
  };
  return (
    <div className="SolarPerformanceComponentRG">
      <div
        className={
          "SolarPerformanceComponentRG__TopSection SolarPerformanceComponentRG__TopSection__Column"
        }
      >
        <ChartComponentRG dataChart={dataForChartComponent.dataForChart} />
        <div className="SolarPerformanceComponentRG__EssentiellePourcentage">
          <SolarPerformanceKPIsRG performanceRGData={performanceRGData} />
        </div>
      </div>

      <div className="SolarPerformanceComponentRG__BottomSection">
        <ConsumptionComponent
          picto={picto_production}
          mainInfos={[
            {
              title: "Production solaire annuelle :",
              description:
                "Électricité produite annuellement grâce à votre installation.",
              number:
                dataForConsumptionComponent.annualProduction &&
                dataForConsumptionComponent.annualProduction,
              productionWhithoutShadowCasting:
                dataForConsumptionComponent.productionWhithoutShadowCasting,
            },
          ]}
          secondaryInfos={[
            {
              title: "Consommation du logement :",
              isRight: true,
              description: "Besoins de votre logement en électricité.",
              number:
                dataForConsumptionComponent.annualConsumption &&
                dataForConsumptionComponent.annualConsumption,
            },
            {
              title: "Consommation solaire :",
              isRight: true,
              description:
                "Besoin de votre logement en électricité couvert par votre installation.",
              number:
                dataForConsumptionComponent.solarConsumption &&
                dataForConsumptionComponent.solarConsumption,
            },
            {
              title: "Consommation réseau :",
              isRight: false,
              description:
                "Besoins de votre logement en électricité couvert par le réseau.",
              number:
                dataForConsumptionComponent.networkConsumption &&
                dataForConsumptionComponent.networkConsumption,
            },
          ]}
        />
      </div>
    </div>
  );
};
