// Libraries import
import { AxiosResponse } from "axios";
import { useState } from "react";

// services import
import { useAxiosPost } from "../useAxiosPost";

interface Props {
  userToken: string | null;
  becqeUser_id?: string | null;
  email?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  phonenumber?: string | null;
  study_id: string;
}

interface ICustomResponse extends AxiosResponse {
  message: string;
  token: string;
  modifiedUserId: string;
}

export const useUpdateUserBecqe = () => {
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const updateUserBecqe = async ({
    userToken,
    becqeUser_id,
    firstname,
    lastname,
    email,
    phonenumber,
    study_id,
  }: Props): Promise<AxiosResponse<ICustomResponse> | void> => {
    setErrorMessage("");

    const body = {
      becqeUser_id,
      email,
      firstname,
      lastname,
      phonenumber,
    };

    const response = await axiosPostRequest<ICustomResponse>(
      `${process.env.REACT_APP_EERO_URL}/${study_id}`,
      body,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
    return response;
  };

  return {
    updateUserBecqe,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
