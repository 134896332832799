//Style import
import "./StartEtude.scss";

//Library import
import { useNavigate } from "react-router-dom";

//Component import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { NavigationBarOutsideStudy } from "../../components/NavigationBarOutsideStudy/NavigationBarOutsideStudy";
import { useAppDispatch } from "../../redux/store/hook";

//Img import
import { FooterHills } from "../../components/FooterHills/FooterHills";

//Redux import
import { resetAllStudyInformationAction } from "../../redux/appActions";

export const StartEtude = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const resetRedux = () => {
    dispatch(resetAllStudyInformationAction(null));

    return;
  };
  return (
    <div className="StartEtude">
      <NavigationBarOutsideStudy title={"Nouvelle étude"} />
      {/* Header to add when it will be done  */}
      <div className="StartEtude__ButtonsContainer">
        <h1>Nouvelle étude photovoltaïque :</h1>

        <div className={"StartEtude__Buttons"}>
          <CTAButton
            name="Visio"
            category={"secondary"}
            onClick={() => {
              resetRedux();
              navigate(`/etude/photovoltaique/new`);
            }}
          />
          <CTAButton
            name="Terrain"
            category={"secondary"}
            onClick={() => {
              resetRedux();
              navigate(`/etude/photovoltaique/new`);
            }}
          />
        </div>
      </div>

      <FooterHills />
    </div>
  );
};
