//Style import
import "./RepartitionEnergetiqueDonut.scss";

//Libraries import
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

//Services import

//Interface import
import { IConsumptionsDataDonuts } from "../../services/RenovationGlobale/MaSituationEnergetique/useGetConsumptionDonuts";

//Local interface
interface LocalProps {
  consumptionDonutsData: IConsumptionsDataDonuts | null;
  pieChartWidth?: number;
  pieChartHeight?: number;
}

export const RepartitionEnergetiqueDonut = ({
  consumptionDonutsData,
  pieChartWidth = 330,
  pieChartHeight = 330,
}: LocalProps) => {
  const innerColor = ["#FECC97", "#FEF8F1", "#FAE0C4"];
  const outerColor = ["#46D1CA", "#74DDD7", "#A3E8E4", "#D1F3F2", "#3DA5A0"];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    payload,
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number;
    payload: any;
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        fill="#50545F"
        textAnchor={"middle"}
        dominantBaseline="central"
        className="RepartitionEnergetiqueDonut__Label"
      >
        <tspan x={x} y={y - 15} width={2}>{`${
          payload?.usage_type || payload.energy_type
        }`}</tspan>
        {consumptionDonutsData?.total_bill && (
          <tspan
            x={x}
            y={y}
            className="RepartitionEnergetique__SpecialLabel"
          >{`${(consumptionDonutsData.total_bill * percent).toFixed(
            0
          )} €`}</tspan>
        )}

        <tspan x={x} y={y + 15}>{`${(percent * 100).toFixed(0)}%`}</tspan>
      </text>
    );
  };

  return (
    <div className="RepartitionEnergetiqueDonut">
      <div className="RepartitionEnergetiqueDonut__GraphContainer">
        <ResponsiveContainer>
          <PieChart width={pieChartWidth} height={pieChartHeight}>
            <Pie
              data={consumptionDonutsData?.inner_ring}
              dataKey="ratio"
              nameKey="energy_type"
              cx="50%"
              cy="50%"
              outerRadius={90}
              startAngle={90}
              endAngle={450}
              label={renderCustomizedLabel}
            >
              {consumptionDonutsData?.inner_ring?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={innerColor[index]}
                  style={{ outline: "none" }}
                />
              ))}
            </Pie>
            <Pie
              data={consumptionDonutsData?.outer_ring}
              dataKey="ratio"
              nameKey="usage_type"
              cx="50%"
              cy="50%"
              innerRadius={90}
              outerRadius={164}
              startAngle={90}
              endAngle={450}
              fill="#FF3800"
              label={renderCustomizedLabel}
              labelLine={false}
            >
              {consumptionDonutsData?.outer_ring?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={outerColor[index]}
                  style={{ outline: "none" }}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
