import styled from "styled-components";

// Library import
import { useState, FC } from "react";
import { Swiper } from "swiper/react";
import { Swiper as SwiperInterface } from "swiper";

// Images import
import ChevronRightSVG from "../../assets/images/DesignSystemIcons/chevron-right.svg";

// Component import
import { Dots } from "../../components/DesignSystem/Pagination/Dots/Dots";

// HOW TO USE THE CARROUSEL WRAPPER
// - import the wrapper : import { CarrouselWrapper } from '@web/shared/dist/containers/CarrouselWrapper/CarrouselWrapper'
// - import the swipper slide from the swipper library : import {  SwiperSlide } from 'swiper/react'
// - import the swipper css from the swipper library : import 'swiper/css' & import 'swiper/css/navigation'

// Local interface declaration
export interface CarrouselWrapperProps {
  numberOfElements?: number;
  children: React.ReactNode;
  slidePerViewMobile?: number;
  slidePerViewDesktop?: number;
  isMobile?: boolean;
  swiperStyle?: React.CSSProperties;
  isDisplayChevrons?: boolean;
  isDisplayPaginationDesktop?: boolean;
  spaceBetween?: number;
  backgroundColor?: string;
  initialSlide?: number;
  swiperRef?: React.RefObject<any>; // Must put any due to incompatible Swiper type due to different versions of swiper used in the shared libraries and in the other project
}

export interface SwiperButtonProps {
  swiperInstance?: SwiperInterface;
  children: React.ReactNode;
  isMobile?: boolean;
  isLastSlide?: boolean;
  isFirstSlide?: boolean;
  backgroundColor?: string;
}

const CarrouselContainer = styled.div`
  height: 100%;
`;

const CarrouselContent = styled.div<{ isMobile?: boolean }>`
  display: ${(props) => (props.isMobile ? "block" : "flex")};
  height: 100%;
`;

const SwipperButtonNext = styled.div<{
  isMobile?: boolean;
  isLastSlide?: boolean;
  backgroundColor?: string;
}>`
  margin-right: -2rem;
  display: none;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  cursor: ${(props) => (props.isLastSlide ? "default" : "pointer")};
  display: ${(props) => (props.isMobile ? "none" : "flex")};
  align-items: ${(props) => (props.isMobile ? "start" : "center")};
`;

const SwiperButtonPrevious = styled.div<{
  isMobile?: boolean;
  isFirstSlide?: boolean;
  backgroundColor?: string;
}>`
  margin-left: -2rem;
  display: none;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  cursor: ${(props) => (props.isFirstSlide ? "default" : "pointer")};
  display: ${(props) => (props.isMobile ? "none" : "flex")};
  align-items: ${(props) => (props.isMobile ? "start" : "center")};
`;

const ChevronLeft = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  transform: rotate(180deg);
  margin-right: 0.3rem;
  > img {
    height: 100%;
    width: 100%;
  }
`;

const ChevronRight = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  margin-left: 0.3rem;
  > img {
    height: 100%;
    width: 100%;
  }
`;

const Pagination = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;

const SwiperButtonNextComponent: React.FC<SwiperButtonProps> = ({
  swiperInstance,
  children,
  isMobile,
  isLastSlide,
  backgroundColor,
}) => {
  return (
    <SwipperButtonNext
      onClick={() => swiperInstance?.slideNext()}
      isMobile={isMobile}
      isLastSlide={isLastSlide}
      backgroundColor={backgroundColor}
    >
      {children}
    </SwipperButtonNext>
  );
};

const SwiperButtonPreviousComponent: React.FC<SwiperButtonProps> = ({
  swiperInstance,
  children,
  isMobile,
  isFirstSlide,
  backgroundColor,
}) => {
  return (
    <SwiperButtonPrevious
      onClick={() => swiperInstance?.slidePrev()}
      isMobile={isMobile}
      isFirstSlide={isFirstSlide}
      backgroundColor={backgroundColor}
    >
      {children}
    </SwiperButtonPrevious>
  );
};

export const CarrouselWrapper: FC<CarrouselWrapperProps> = ({
  children,
  numberOfElements,
  slidePerViewMobile = 1,
  slidePerViewDesktop = 1,
  isMobile = false,
  swiperStyle,
  isDisplayChevrons = true,
  isDisplayPaginationDesktop = false,
  spaceBetween = 32,
  backgroundColor,
  initialSlide,
  swiperRef,
}) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const numberOfPages = isMobile
    ? numberOfElements
      ? numberOfElements - (slidePerViewMobile || 1) + 1
      : slidePerViewMobile
    : numberOfElements
    ? numberOfElements - (slidePerViewDesktop || 1) + 1
    : slidePerViewDesktop;
  const [pagination, setPagination] = useState<boolean[]>(
    Array.from(Array(numberOfPages > 0 ? numberOfPages : 0).keys()).map(
      (_elem, index) => (index === 0 ? true : false)
    )
  );

  return (
    <CarrouselContainer>
      <CarrouselContent isMobile={isMobile}>
        {isDisplayChevrons ? (
          <SwiperButtonPreviousComponent
            swiperInstance={swiperInstance}
            isMobile={isMobile}
            isFirstSlide={pagination[0]}
            backgroundColor={backgroundColor}
          >
            <ChevronLeft>
              {!pagination[0] ? (
                <img src={ChevronRightSVG} alt="Chevron right" />
              ) : null}
            </ChevronLeft>
          </SwiperButtonPreviousComponent>
        ) : null}
        <Swiper
          style={swiperStyle}
          slidesPerView={isMobile ? slidePerViewMobile : slidePerViewDesktop}
          spaceBetween={spaceBetween}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          onSlideChange={(swiper) => {
            setPagination(() => {
              const newState = pagination.map(() => false);
              newState[swiper.activeIndex] = true;
              return newState;
            });
          }}
          initialSlide={initialSlide}
          ref={swiperRef}
        >
          {children}
        </Swiper>
        {isDisplayChevrons ? (
          <SwiperButtonNextComponent
            swiperInstance={swiperInstance}
            isMobile={isMobile}
            isLastSlide={pagination[pagination.length - 1]}
            backgroundColor={backgroundColor}
          >
            <ChevronRight>
              {pagination[pagination.length - 1] ? null : (
                <img src={ChevronRightSVG} alt="Chevron right" />
              )}
            </ChevronRight>
          </SwiperButtonNextComponent>
        ) : null}
      </CarrouselContent>
      {isMobile || isDisplayPaginationDesktop ? (
        <Pagination>
          <Dots pagination={pagination} />
        </Pagination>
      ) : null}
    </CarrouselContainer>
  );
};
