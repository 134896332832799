import { View, Text, StyleSheet, Font } from "@react-pdf/renderer";

// Fonts
import DMSansRegular from "../../../../assets/pdf/fonts/DMSansRegular.ttf";

// Register font
Font.register({
  family: "DMSansRegular",
  src: DMSansRegular,
  format: "truetype",
  fontStyle: "italic",
});

// Create styles
const styles = StyleSheet.create({
  pagination: {
    position: "absolute",
    bottom: 15,
    padding: "0 58",
    fontFamily: "DMSansRegular",
    fontSize: 7,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: "row",
    width: "100%",
    color: "#50545f",
  },
  white: {
    color: "white",
  },
});

interface Props {
  isWhite?: boolean;
  date: string | null;
  prenom: string | null;
  nom: string | null;
  reference: string;
  studyType?: string;
}

export const PdfPagination = ({
  isWhite = false,
  date,
  prenom,
  nom,
  reference,
  studyType = "photovoltaïque",
}: Props) => {
  return (
    <View fixed style={styles.pagination}>
      {isWhite ? (
        <>
          <Text style={styles.white}>
            Étude {studyType} du {date} - {prenom} {nom} - {reference}
          </Text>
          <Text
            style={styles.white}
            render={({ pageNumber }) => `${pageNumber}`}
          ></Text>
        </>
      ) : (
        <>
          <Text>
            Étude {studyType} du {date} - {prenom} {nom} - {reference}
          </Text>
          <Text render={({ pageNumber }) => `${pageNumber}`}></Text>
        </>
      )}
    </View>
  );
};
