import "./MesConsommationsForm.scss";
// Libraries import
import { useState, useRef, FormEvent, useEffect } from "react";
import { energiePriceNov2023Data } from "@web/shared/dist/datas/dataHypotethis";

// Images import
import ConctractIcon from "../../assets/images/contract-icon.svg";
import HouseIcon from "../../assets/images/house-icon.svg";

// Components import
import { SubHeader } from "../../containers/SubHeader/SubHeader";
import { Layout } from "../../components/Layout/Layout";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { DropdownV2 } from "@web/shared/dist/components/DesignSystem/Dropdowns/DropdownV2/DropdownV2";
import { ChoixUnique } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixUnique/ChoixUnique";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { Quantite } from "@web/shared/dist/components/DesignSystem/Boutons/Quantite/Quantite";
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import { mesConsommationsQuestionsData } from "../MesConsommationsAndEquipmentsRG/MesConsommationsQuestionsData";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";

// Services import
import {
  IPostConsumptionsPayload,
  usePostConsumptionsForm,
} from "../../services/MesConsommations/usePostConsumptionsForm";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { setStudyInfoAction } from "../../redux/appActions";
import { useSaveLastPageVisited } from "../../services/Oree2/useSaveLastPageVisited";
import { INavigateData } from "../../services/useNavigation/useNavigation";

// Data import
import { myConsumptionsFormOptions } from "../../data/myConsumptionsFormOptions";

// Interfaces import
import { IGoTo } from "../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  goNext: (data?: INavigateData<any>) => void;
  goBack?: () => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
  setIsGoToFunctionActive?: React.Dispatch<React.SetStateAction<boolean>>;
}

// local interfaces declaration
interface IOption extends IImage {
  label: string | null;
  value: string | null;
}

interface IImage {
  imgSrc?: string;
  imgAltTxt?: string;
}

type EnergySource = "electricite" | "gaz" | "bois" | "gpl" | "fioul";
type ConsumptionEurosBySource = {
  [key in EnergySource]: null | string;
};

export const MesConsommationsForm = ({ goNext, goBack }: Props) => {
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const {
    postConsumptionFrom,
    isLoading: isPostConsumptionLoading,
    errorMessage,
  } = usePostConsumptionsForm();
  const { saveLastPageVisited } = useSaveLastPageVisited();

  const { studyInformation, userAuthInfo } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const [isBadRequestModalOpen, setIsBadRequestModalOpen] =
    useState<boolean>(false);

  // Form states
  const [princingOptions, setPrincingOptions] = useState<string>(
    studyInformation.tarification_option ??
      myConsumptionsFormOptions.optionsTarifaire[0].value
  );
  const [subscribedPower, setSubscribedPower] = useState<number>(
    studyInformation?.subscribed_power ||
      Number(myConsumptionsFormOptions.puissanceSouscrite[0].value)
  );
  const [annualConsumptionEuros, setAnnualConsumptionEuros] = useState<number>(
    studyInformation?.annual_consumption_in_euros ?? 0
  );
  const [priceEletricityPerKwh, setPriceEletricityPerKwh] = useState<number>(
    studyInformation?.electricity_price_per_kwh ||
      energiePriceNov2023Data["Electricité"] ||
      0
  );
  const [annualConsumptionkWh, setAnnualConsumptionkWh] = useState<number>(
    studyInformation?.annual_consumption_in_kwh ?? 0
  );
  const [constructionYear, setConstructionYear] = useState<number | null>(
    studyInformation?.annee_de_construction || null
  );
  const [nombreOccupants, setNombreOccupants] = useState<number | null>(
    studyInformation?.number_of_occupants || 1
  );
  const [buildingArea, setBuildingArea] = useState<number | null>(
    studyInformation?.house_surface || null
  );
  const [installationType, steInstallationType] = useState<string | null>(
    studyInformation.electric_installation_type || null
  );
  const [equipments, setEquipments] = useState<string[]>(
    studyInformation.other_equipments ?? []
  );

  const ernergySourcesOption =
    mesConsommationsQuestionsData.sourcesDEnergie.options;

  // Heating enrgy sources
  const [heatingEnergySources, setHeatingEnergySources] = useState<
    EnergySource[]
  >(() => {
    const initialState = [];
    studyInformation.electricite_principaux_equipments?.includes("chauffage") &&
      initialState.push("electricite");
    studyInformation.gaz_principaux_equipments?.includes("chauffage") &&
      initialState.push("gaz");
    studyInformation.gpl_principaux_equipments?.includes("chauffage") &&
      initialState.push("gpl");
    studyInformation.fioul_principaux_equipments?.includes("chauffage") &&
      initialState.push("fioul");
    return initialState as EnergySource[];
  });

  // Water heater energy sources
  const [waterHeaterEnergySources, setWaterHeaterEnergySources] = useState<
    EnergySource[]
  >(() => {
    const initialState = [];
    studyInformation.electricite_principaux_equipments?.includes(
      "eauChaudeSanitaire"
    ) && initialState.push("electricite");
    studyInformation.gaz_principaux_equipments?.includes(
      "eauChaudeSanitaire"
    ) && initialState.push("gaz");
    studyInformation.gpl_principaux_equipments?.includes(
      "eauChaudeSanitaire"
    ) && initialState.push("gpl");
    studyInformation.fioul_principaux_equipments?.includes(
      "eauChaudeSanitaire"
    ) && initialState.push("fioul");
    return initialState as EnergySource[];
  });

  // Initial state used to initialize heatingConsumptionForEnergySource
  const heatingConsumptionForEnergySourceInitialState: ConsumptionEurosBySource =
    {
      electricite:
        studyInformation.electricite_chauffage_euros?.toString() || null,
      gaz: studyInformation.gaz_chauffage_euros?.toString() || null,
      bois: studyInformation.bois_consommation_euros?.toString() || null,
      gpl: studyInformation.gpl_chauffage_euros?.toString() || null,
      fioul: studyInformation.fioul_chauffage_euros?.toString() || null,
    };
  // Heating consumption related to energy source
  const [
    heatingConsumptionForEnergySource,
    setHeatingConsumptionForEnergySource,
  ] = useState<ConsumptionEurosBySource>(() => {
    const newState = { ...heatingConsumptionForEnergySourceInitialState };

    // As there is no column in db to save total consumption in euro for heating, we calculate it with the amount of each energy source
    const total = Object.values(newState).reduce(
      (acc, value) => acc + Number(value),
      0
    );

    // Calculate percentage of consumption for each energy source
    let key: keyof ConsumptionEurosBySource;
    for (key in newState) {
      newState[key] =
        total === 0 ? null : ((Number(newState[key]) * 100) / total).toFixed(0);
    }

    return newState;
  });

  // Water heater consumption related to energy source
  const waterHeaterConsumptionForEnergySourceInitialState: ConsumptionEurosBySource =
    {
      electricite: studyInformation.electricite_ecs_euros?.toString() || null,
      gaz: studyInformation.gaz_ecs_euros?.toString() || null,
      bois: null,
      gpl: studyInformation.gpl_ecs_euros?.toString() || null,
      fioul: studyInformation.fioul_ecs_euros?.toString() || null,
    };

  const [
    waterHeaterConsumptionForEnergySource,
    setWaterHeaterConsumptionForEnergySource,
  ] = useState<ConsumptionEurosBySource>(() => {
    // As there is no column in db to save total consumption in euro for water heater, we calculate it with the amount of each energy source

    const newState = {
      ...waterHeaterConsumptionForEnergySourceInitialState,
    };

    const total = Object.values(
      waterHeaterConsumptionForEnergySourceInitialState
    ).reduce((acc, value) => acc + Number(value), 0);

    // Calculate percentage of consumption for each energy source
    let key: keyof ConsumptionEurosBySource;
    for (key in waterHeaterConsumptionForEnergySourceInitialState) {
      newState[key] =
        total === 0 ? null : ((Number(newState[key]) * 100) / total).toFixed(2);
    }

    return newState;
  });

  // Total heating consumption in euros
  const [heatingConsumptionEuros, setHeatingConsumptionEuros] =
    useState<string>(
      Object.values(heatingConsumptionForEnergySourceInitialState)
        .reduce((acc, value) => acc + Number(value), 0)
        .toString()
    );

  // Total water heater consumption in euros
  const [waterHeaterConsumptionEuros, setWaterHeaterConsumptionEuros] =
    useState<string>(
      Object.values(waterHeaterConsumptionForEnergySourceInitialState)
        .reduce((acc, value) => acc + Number(value), 0)
        .toString()
    );

  const findEquipmentForEnergySource = (source: EnergySource) => {
    const isHeating = heatingEnergySources.includes(source);
    const isWaterHeater = waterHeaterEnergySources.includes(source);

    if (isHeating && isWaterHeater) return ["chauffage", "eauChaudeSanitaire"];
    if (isHeating) return ["chauffage"];
    if (isWaterHeater) return ["eauChaudeSanitaire"];
    return [];
  };

  // const getConsumptionForEnergySource = (

  const onGoNext = () => {
    buttonSubmitRef.current?.click();
  };

  const onSubmitForm = async (event: FormEvent) => {
    event.preventDefault();

    if (annualConsumptionEuros < 600) {
      const confirmed = window.confirm(
        `Vous avez entré une consommation annuelle totale de ${annualConsumptionEuros}€.\nVoulez-vous continuer ?`
      );
      if (!confirmed) {
        return;
      }
    }

    if (
      princingOptions &&
      annualConsumptionkWh &&
      annualConsumptionEuros &&
      nombreOccupants &&
      buildingArea &&
      installationType &&
      constructionYear !== null
    ) {
      const formatConsumptionsDataForDataBackend: IPostConsumptionsPayload = {
        household_id: studyInformation.household_id ?? "",
        building_id: studyInformation.building_id ?? "",
        tarification_option: princingOptions,
        subscribed_power: subscribedPower,
        annual_electric_consumption_kWh: annualConsumptionkWh,
        annual_electric_consumption_euros: annualConsumptionEuros,
        electricity_price_per_kwh: priceEletricityPerKwh,
        number_of_occupants: nombreOccupants,
        house_area_m2: buildingArea,
        electric_installation_type: installationType,
        owned_equipments: {
          climatisation: equipments?.find(
            (equipment) => equipment === "climatisation"
          )
            ? true
            : false,
          pool: equipments?.find((equipment) => equipment === "piscine")
            ? true
            : false,
          charging_point: equipments?.find(
            (equipment) => equipment === "borne de recharge"
          )
            ? true
            : false,
        },
        electric_heater: heatingEnergySources.includes("electricite"),
        electric_water_heater: waterHeaterEnergySources.includes("electricite"),
        construction_year: constructionYear,
        source_energie: [
          ...new Set([...heatingEnergySources, ...waterHeaterEnergySources]),
        ], // merge heating and waterHeater energy sources and remove duplicates
        electricite_principaux_equipments:
          findEquipmentForEnergySource("electricite"),
        gaz_principaux_equipments: findEquipmentForEnergySource("gaz"),
        gpl_principaux_equipments: findEquipmentForEnergySource("gpl"),
        fioul_principaux_equipments: findEquipmentForEnergySource("fioul"),
        electricite_chauffage_euros:
          (Number(heatingConsumptionForEnergySource.electricite) *
            Number(heatingConsumptionEuros)) /
          100,
        gaz_chauffage_euros:
          (Number(heatingConsumptionForEnergySource.gaz) *
            Number(heatingConsumptionEuros)) /
          100,
        bois_consommation_euros:
          (Number(heatingConsumptionForEnergySource.bois) *
            Number(heatingConsumptionEuros)) /
          100,
        gpl_chauffage_euros:
          (Number(heatingConsumptionForEnergySource.gpl) *
            Number(heatingConsumptionEuros)) /
          100,
        fioul_chauffage_euros:
          (Number(heatingConsumptionForEnergySource.fioul) *
            Number(heatingConsumptionEuros)) /
          100,
        electricite_ecs_euros:
          (Number(waterHeaterConsumptionForEnergySource.electricite) *
            Number(waterHeaterConsumptionEuros)) /
          100,
        gaz_ecs_euros:
          (Number(waterHeaterConsumptionForEnergySource.gaz) *
            Number(waterHeaterConsumptionEuros)) /
          100,
        gpl_ecs_euros:
          (Number(waterHeaterConsumptionForEnergySource.gpl) *
            Number(waterHeaterConsumptionEuros)) /
          100,
        fioul_ecs_euros:
          (Number(waterHeaterConsumptionForEnergySource.fioul) *
            Number(waterHeaterConsumptionEuros)) /
          100,
      };

      dispatch(
        setStudyInfoAction({
          studyInformation: {
            tarification_option: princingOptions,
            subscribed_power: subscribedPower,
            annual_consumption_in_kwh: annualConsumptionkWh,
            annual_consumption_in_euros: annualConsumptionEuros,
            number_of_occupants: nombreOccupants,
            house_surface: buildingArea,
            electric_installation_type: installationType,
            other_equipments: equipments,
            is_electric_heater: heatingEnergySources.includes("electricite"),
            is_electric_water_heater:
              waterHeaterEnergySources.includes("electricite"),
            electricity_price_per_kwh: priceEletricityPerKwh,
            annee_de_construction: constructionYear,
            source_energie: formatConsumptionsDataForDataBackend.source_energie,
            electricite_principaux_equipments:
              formatConsumptionsDataForDataBackend.electricite_principaux_equipments,
            gaz_principaux_equipments:
              formatConsumptionsDataForDataBackend.gaz_principaux_equipments,
            gpl_principaux_equipments:
              formatConsumptionsDataForDataBackend.gpl_principaux_equipments,
            fioul_principaux_equipments:
              formatConsumptionsDataForDataBackend.fioul_principaux_equipments,
            electricite_chauffage_euros:
              formatConsumptionsDataForDataBackend.electricite_chauffage_euros,
            gaz_chauffage_euros:
              formatConsumptionsDataForDataBackend.gaz_chauffage_euros,
            bois_consommation_euros:
              formatConsumptionsDataForDataBackend.bois_consommation_euros,
            gpl_chauffage_euros:
              formatConsumptionsDataForDataBackend.gpl_chauffage_euros,
            fioul_chauffage_euros:
              formatConsumptionsDataForDataBackend.fioul_chauffage_euros,
            electricite_ecs_euros:
              formatConsumptionsDataForDataBackend.electricite_ecs_euros,
            gaz_ecs_euros: formatConsumptionsDataForDataBackend.gaz_ecs_euros,
            gpl_ecs_euros: formatConsumptionsDataForDataBackend.gpl_ecs_euros,
            fioul_ecs_euros:
              formatConsumptionsDataForDataBackend.fioul_ecs_euros,
          },
        })
      );

      const response = await postConsumptionFrom({
        postConsumptionsPayload: formatConsumptionsDataForDataBackend,
        userToken: userAuthInfo.userToken,
        study_id: studyInformation.study_id,
      });

      response && goNext && goNext();

      response &&
        saveLastPageVisited({
          last_page_visited: "my_consumptions",
          study_id: studyInformation?.study_id || null,
          userToken: userAuthInfo.userToken,
        });
    }
  };

  const handleSelectHeatingEnergySources = (option: IOption) => {
    setHeatingEnergySources((state) => {
      const heatingEnergySourceNewState = [...state];
      const foundIndex = heatingEnergySourceNewState.findIndex(
        (e) => option.value === e
      );

      // CASE the energy source is already in the list
      if (foundIndex !== -1) {
        // Delete the energy source already in the list
        heatingEnergySourceNewState.splice(foundIndex, 1);

        // Reset the consumption for this source
        setHeatingConsumptionForEnergySource((state) => {
          const newState = { ...state };
          newState[option.value as EnergySource] = null;
          return newState;
        });

        // Case there is only one energy left source in the list
        if (heatingEnergySourceNewState.length === 1) {
          // we set the consumption to 100% for the only source left
          setHeatingConsumptionForEnergySource((state) => {
            const newState = {
              ...state,
            };
            newState[heatingEnergySourceNewState[0]] = "100";
            return newState;
          });
        }

        return heatingEnergySourceNewState;
      }

      // CASE the energy source is not in the list

      heatingEnergySourceNewState.push(option.value as EnergySource);

      // Case there is only one energy source in the list
      if (heatingEnergySourceNewState.length === 1) {
        // we set the consumption to 100% for the only source
        setHeatingConsumptionForEnergySource((state) => {
          const newState = {
            ...state,
          };
          newState[heatingEnergySourceNewState[0]] = "100";
          return newState;
        });
      }

      return heatingEnergySourceNewState;
    });
  };

  const handleSelectWhaterHeaterEnergySources = (option: IOption) => {
    setWaterHeaterEnergySources((state) => {
      const waterHeaterEnergySourceNewState = [...state];
      const foundIndex = waterHeaterEnergySourceNewState.findIndex(
        (e) => option.value === e
      );

      // CASE the energy source is already in the list
      if (foundIndex !== -1) {
        // Delete the energy source already in the list
        waterHeaterEnergySourceNewState.splice(foundIndex, 1);

        // Reset the consumption for this source
        setWaterHeaterConsumptionForEnergySource((state) => {
          const newState = { ...state };
          newState[option.value as EnergySource] = null;
          return newState;
        });

        // Case there is only one energy left source in the list
        if (waterHeaterEnergySourceNewState.length === 1) {
          // we set the consumption to 100% for the only source left
          setWaterHeaterConsumptionForEnergySource((state) => {
            const newState = {
              ...state,
            };
            newState[waterHeaterEnergySourceNewState[0]] = "100";
            return newState;
          });
        }

        return waterHeaterEnergySourceNewState;
      }

      // CASE the energy source is not in the list

      waterHeaterEnergySourceNewState.push(option.value as EnergySource);

      // Case there is only one energy source in the list
      if (waterHeaterEnergySourceNewState.length === 1) {
        // we set the consumption to 100% for the only source
        setWaterHeaterConsumptionForEnergySource((state) => {
          const newState = {
            ...state,
          };
          newState[waterHeaterEnergySourceNewState[0]] = "100";
          return newState;
        });
      }

      return waterHeaterEnergySourceNewState;
    });
  };

  const maxPercentCalculus = (
    equipmentConsumption: ConsumptionEurosBySource
  ) => {
    return Object.values(equipmentConsumption).reduce(
      (acc, value) => acc + Number(value),
      0
    );
  };

  const inputErrorMessage = (
    inputValue: number,
    equipmentConsumption: ConsumptionEurosBySource,
    energySource: EnergySource[]
  ) => {
    const equipmentsNumber = energySource.length;

    if (equipmentsNumber <= 1) return;

    const equipmentsConsumptionAlreadyFilled = Object.values(
      equipmentConsumption
    ).reduce((acc, value) => (value && value !== "0" ? acc + 1 : acc), 0);

    if (equipmentsNumber !== equipmentsConsumptionAlreadyFilled)
      return "Champ manquant";
    if (inputValue === 100) return;

    return "Restant : " + (100 - inputValue) + "%";
  };

  const isNextButtonDisabled =
    waterHeaterConsumptionEuros === "0" ||
    !waterHeaterConsumptionEuros ||
    heatingConsumptionEuros === "0" ||
    !heatingConsumptionEuros ||
    !!inputErrorMessage(
      maxPercentCalculus(heatingConsumptionForEnergySource),
      heatingConsumptionForEnergySource,
      heatingEnergySources
    ) ||
    !!inputErrorMessage(
      maxPercentCalculus(waterHeaterConsumptionForEnergySource),
      waterHeaterConsumptionForEnergySource,
      waterHeaterEnergySources
    );

  useEffect(() => {
    if (errorMessage) {
      setIsBadRequestModalOpen(true);
    }
    return () => {
      setIsBadRequestModalOpen(false);
    };
  }, [errorMessage]);

  return (
    <div className="MesConsommationsForm">
      <SubHeader
        title="Mes consommations"
        goNext={onGoNext}
        isLoading={isPostConsumptionLoading}
        canGoNext={!isNextButtonDisabled}
      />
      <Modale
        isModaleOpen={isBadRequestModalOpen}
        textAlignMiddle
        text={errorMessage}
      >
        <div className="MesConsommationsForm__ButtonContainer">
          <CTAButton
            name="D'accord"
            onClick={() => setIsBadRequestModalOpen(false)}
          />
        </div>
      </Modale>
      <Layout>
        {errorMessage && (
          <div className="MesConsommationsForm__ErrorContainer">
            <ErrorMessage errorMessage={errorMessage} />
          </div>
        )}
        <form onSubmit={onSubmitForm}>
          <section>
            <div className="MesConsommationsForm__SectionTitle">
              <img src={ConctractIcon} alt="Icone contrat d'électricité" />
              <h3>Votre consommation électrique</h3>
            </div>
            <div className="MesConsommationsForm__HorizontalQuestionContainer">
              <label>Consommation électrique annuelle :</label>
              <div className="MesConsommationsForm__InputNumber">
                <TextInput
                  value={annualConsumptionEuros.toString()}
                  type="number"
                  iconType="€"
                  iconHidden={false}
                  placeholder=""
                  required={true}
                  onChange={(event) => {
                    setAnnualConsumptionEuros((state) => {
                      return event.target.valueAsNumber;
                    });
                    setAnnualConsumptionkWh((state) => {
                      return Math.round(
                        event.target.valueAsNumber / priceEletricityPerKwh
                      );
                    });
                  }}
                />
              </div>
              <div className="MesConsommationsForm__InputNumber">
                <TextInput
                  value={annualConsumptionkWh.toString()}
                  type="number"
                  iconType="kWh"
                  placeholder=""
                  iconHidden={false}
                  onChange={(event) => {
                    setAnnualConsumptionkWh((state) => {
                      return event.target.valueAsNumber;
                    });
                    setAnnualConsumptionEuros((state) => {
                      return Math.round(
                        event.target.valueAsNumber * priceEletricityPerKwh
                      );
                    });
                  }}
                  required={true}
                />
              </div>
              <p>soit</p>
              <div className="MesConsommationsForm__InputNumber">
                <TextInput
                  value={priceEletricityPerKwh.toString()}
                  type="number"
                  iconType="€/kWh"
                  iconHidden={false}
                  onChange={(event) => {
                    setPriceEletricityPerKwh(event.target.valueAsNumber);
                    setAnnualConsumptionkWh(
                      Math.round(
                        (annualConsumptionEuros || 0) /
                          event.target.valueAsNumber
                      )
                    );
                  }}
                  required={true}
                />
              </div>
            </div>

            <div className="MesConsommationsForm__InputSection">
              <div className="MesConsommationsForm__HorizontalQuestionContainer">
                <label>Option tarifaire :</label>
                <div className="MesConsommationsForm__InputContainer">
                  <DropdownV2
                    id="optionTarifaire"
                    options={myConsumptionsFormOptions.optionsTarifaire}
                    onSelect={(option) => {
                      setPrincingOptions(option.value?.toString() || "");
                    }}
                    values={[
                      myConsumptionsFormOptions.optionsTarifaire.find(
                        (option) => option.value === princingOptions
                      )?.value || "",
                    ]}
                    isRequired={true}
                  />
                </div>
              </div>

              <div className="MesConsommationsForm__HorizontalQuestionContainer">
                <label>Puissance souscrite :</label>
                <div className="MesConsommationsForm__InputContainer">
                  <DropdownV2
                    id="puissanceSouscrite"
                    options={myConsumptionsFormOptions.puissanceSouscrite}
                    onSelect={(option) => {
                      setSubscribedPower(Number(option.value));
                    }}
                    values={[
                      myConsumptionsFormOptions.puissanceSouscrite.find(
                        (option) => option.value === subscribedPower.toString()
                      )?.value || "",
                    ]}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="MesConsommationsForm__VerticalQuestionContainer">
                <label>Type d'installation électrique : </label>
                <ChoixUnique
                  optionsList={
                    myConsumptionsFormOptions.TypeInstallationElectrique
                  }
                  optionSelected={myConsumptionsFormOptions.TypeInstallationElectrique.find(
                    (option) => option.value === installationType
                  )}
                  onSelect={(option) => steInstallationType(option.value)}
                  isRequired={true}
                />
              </div>
            </div>
          </section>
          <section>
            <div className="MesConsommationsForm__SectionTitle">
              <img src={HouseIcon} alt="Icone maison" />
              <h3>Caractéristiques du logement</h3>
            </div>

            <div className="MesConsommationsForm__InputSection">
              <div className="MesConsommationsForm__HorizontalQuestionContainer">
                <label>Année de construction :</label>
                <div className="MesConsommationsForm__InputNumber">
                  <TextInput
                    value={constructionYear?.toString()}
                    type="number"
                    placeholder=""
                    onChange={(event) => {
                      setConstructionYear((state) => {
                        return event.target.valueAsNumber;
                      });
                    }}
                    required={true}
                  />
                </div>
              </div>

              <div className="MesConsommationsForm__HorizontalQuestionContainer">
                <label>Nombre d'occupants :</label>
                <Quantite
                  value={nombreOccupants ?? 1}
                  onPlusMinusClick={(number) => {
                    setNombreOccupants((state) => {
                      return number;
                    });
                  }}
                  minvalue={1}
                />
              </div>
              <div className="MesConsommationsForm__HorizontalQuestionContainer">
                <label>Surface habitable du logement :</label>
                <div className="MesConsommationsForm__InputNumber">
                  <TextInput
                    value={buildingArea?.toString()}
                    type="number"
                    placeholder=""
                    iconType="m²"
                    iconHidden={false}
                    onChange={(event) => {
                      setBuildingArea((state) => {
                        return event.target.valueAsNumber;
                      });
                    }}
                    required={true}
                  />
                </div>
              </div>

              <div className="MesConsommationsForm__EquipmentSection">
                <div className="MesConsommationsForm__Trait" />
                <div className="MesConsommationsForm__TitleTwo">
                  <h3>Chauffage</h3>
                </div>
                <div className="MesConsommationsForm__VerticalQuestionContainer">
                  <label>
                    Quelles sont la ou les sources d'énergie pour votre
                    chauffage ?
                  </label>
                  <ChoixMultiple
                    options={ernergySourcesOption}
                    value={ernergySourcesOption.filter((option) =>
                      heatingEnergySources?.includes(
                        option.value as EnergySource
                      )
                    )}
                    onClick={(option) => {
                      option && handleSelectHeatingEnergySources(option);
                    }}
                    isRequired={true}
                  />
                </div>
                <div className="MesConsommationsForm__HorizontalQuestionContainer">
                  <label>Consommation totale en euros :</label>
                  <div className="MesConsommationsForm__InputNumber">
                    <TextInput
                      error={
                        heatingConsumptionEuros === "0" ||
                        !heatingConsumptionEuros
                      }
                      inputLableHidden
                      type="number"
                      inputDescriptionHidden
                      iconType="euros"
                      placeholder="XXXX"
                      value={heatingConsumptionEuros}
                      onChange={(e) => {
                        if (heatingEnergySources.length === 1) {
                          setHeatingConsumptionForEnergySource((state) => {
                            const newState = { ...state };
                            newState[heatingEnergySources[0]] = "100";
                            return newState;
                          });
                        }
                        setHeatingConsumptionEuros(e.target.value);
                      }}
                      required={true}
                      iconHidden={false}
                    />
                  </div>
                </div>
                <div className="MesConsommationsForm__SyntheseRow">
                  {heatingEnergySources.length > 1 &&
                    heatingEnergySources.map((source) => (
                      <div className="MesConsommationsForm__SyntheseQuestionContainer">
                        <div className="MesConsommationsForm__SyntheseLabelContainer">
                          <img
                            src={
                              ernergySourcesOption.find(
                                (option) => option.value === source
                              )?.imgSrc
                            }
                            alt="electricite"
                          />
                          <label className="label" htmlFor="ECSEuros">
                            {
                              ernergySourcesOption.find(
                                (option) => option.value === source
                              )?.label
                            }{" "}
                            :
                          </label>
                        </div>
                        <div
                          style={{ maxWidth: "102px" }}
                          className="MesConsommationsForm__SyntheseTextInputContainer"
                        >
                          <TextInput
                            error={
                              !!inputErrorMessage(
                                maxPercentCalculus(
                                  heatingConsumptionForEnergySource
                                ),
                                heatingConsumptionForEnergySource,
                                heatingEnergySources
                              )
                            }
                            inputLableHidden
                            type="number"
                            iconType="%"
                            iconHidden={false}
                            placeholder="XX"
                            value={
                              heatingConsumptionForEnergySource[source] || ""
                            }
                            onChange={(e) => {
                              setHeatingConsumptionForEnergySource((state) => {
                                return {
                                  ...state,
                                  [source]: e?.target?.value || "",
                                };
                              });
                            }}
                          />
                          <p>
                            Soit{" "}
                            {(Number(
                              heatingConsumptionForEnergySource[source] ?? 0
                            ) *
                              Number(heatingConsumptionEuros ?? 0)) /
                              100}{" "}
                            €
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionMessageContainer">
                  <p>
                    {inputErrorMessage(
                      maxPercentCalculus(heatingConsumptionForEnergySource),
                      heatingConsumptionForEnergySource,
                      heatingEnergySources
                    )}
                  </p>
                </div>
              </div>

              <div className="MesConsommationsForm__EquipmentSection">
                <div className="MesConsommationsForm__Trait" />
                <div className="MesConsommationsForm__TitleTwo">
                  <h3>Eau chaude sanitaire (ECS)</h3>
                </div>
                <div className="MesConsommationsForm__VerticalQuestionContainer">
                  <label>
                    Quelles sont la ou les sources d'énergie pour votre système
                    de production d'eau chaude sanitaire ?
                  </label>
                  <ChoixMultiple
                    options={ernergySourcesOption}
                    value={ernergySourcesOption.filter((option) =>
                      waterHeaterEnergySources?.includes(
                        option.value as EnergySource
                      )
                    )}
                    onClick={(option) =>
                      option && handleSelectWhaterHeaterEnergySources(option)
                    }
                    isRequired={true}
                  />
                </div>
                <div className="MesConsommationsForm__HorizontalQuestionContainer">
                  <label>Consommation totale en euros :</label>
                  <div className="MesConsommationsForm__InputNumber">
                    <TextInput
                      error={
                        waterHeaterConsumptionEuros === "0" ||
                        !waterHeaterConsumptionEuros
                      }
                      inputLableHidden
                      type="number"
                      inputDescriptionHidden
                      iconType="euros"
                      placeholder="XXXX"
                      value={waterHeaterConsumptionEuros}
                      onChange={(e) => {
                        setWaterHeaterConsumptionEuros(e.currentTarget.value);
                      }}
                      required={true}
                      iconHidden={false}
                    />
                  </div>
                </div>
                <div className="MesConsommationsForm__SyntheseRow">
                  {waterHeaterEnergySources.length > 1 &&
                    waterHeaterEnergySources.map((source) => (
                      <div className="MesConsommationsForm__SyntheseQuestionContainer">
                        <div className="MesConsommationsForm__SyntheseLabelContainer">
                          <img
                            src={
                              ernergySourcesOption.find(
                                (option) => option.value === source
                              )?.imgSrc
                            }
                            alt="electricite"
                          />
                          <label className="label" htmlFor="ECSEuros">
                            {
                              ernergySourcesOption.find(
                                (option) => option.value === source
                              )?.label
                            }{" "}
                            :
                          </label>
                        </div>
                        <div
                          style={{ maxWidth: "102px" }}
                          className="MesConsommationsForm__SyntheseTextInputContainer"
                        >
                          <TextInput
                            error={
                              !!inputErrorMessage(
                                maxPercentCalculus(
                                  waterHeaterConsumptionForEnergySource
                                ),
                                waterHeaterConsumptionForEnergySource,
                                waterHeaterEnergySources
                              )
                            }
                            inputLableHidden
                            type="number"
                            iconType="%"
                            iconHidden={false}
                            placeholder="XX"
                            value={
                              waterHeaterConsumptionForEnergySource[source] ||
                              ""
                            }
                            onChange={(e) => {
                              setWaterHeaterConsumptionForEnergySource(
                                (state) => {
                                  return {
                                    ...state,
                                    [source]: e?.target?.value || "",
                                  };
                                }
                              );
                            }}
                          />
                          <p>
                            Soit{" "}
                            {(Number(
                              waterHeaterConsumptionForEnergySource[source] ?? 0
                            ) *
                              Number(waterHeaterConsumptionEuros ?? 0)) /
                              100}{" "}
                            €
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="MesConsommationsAndEquipementsRG__SyntheseQuestionMessageContainer">
                  <p>
                    {inputErrorMessage(
                      maxPercentCalculus(waterHeaterConsumptionForEnergySource),
                      waterHeaterConsumptionForEnergySource,
                      waterHeaterEnergySources
                    )}
                  </p>
                </div>
              </div>

              <div className="MesConsommationsForm__Trait" />
              <div className="MesConsommationsForm__VerticalQuestionContainer">
                <label>
                  Possédez-vous un ou plusieurs de ces équipements ?{" "}
                </label>
                <ChoixMultiple
                  options={myConsumptionsFormOptions.PossedezVousUnEquipement}
                  value={myConsumptionsFormOptions.PossedezVousUnEquipement.filter(
                    (option) => equipments?.includes(option.value)
                  )}
                  onClick={(option) =>
                    option &&
                    setEquipments((state) => {
                      const newState = [...state];
                      const foundIndex = newState.findIndex(
                        (e) => option.value === e
                      );

                      if (foundIndex !== -1) {
                        newState.splice(foundIndex, 1);
                        return newState;
                      }

                      newState.push(option.value || "");
                      return newState;
                    })
                  }
                />
              </div>
            </div>
          </section>
          <div className="MesConsommationsForm__MainButton">
            <CTAButton
              type="submit"
              ref={buttonSubmitRef}
              name="Suivant"
              isLoading={isPostConsumptionLoading}
              isDisabled={isNextButtonDisabled}
            />
          </div>
        </form>
      </Layout>
    </div>
  );
};
