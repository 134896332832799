import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DSColors } from "@web/shared/src/styles/variables";
import moment from "moment";

// Components
import { PdfHeader } from "../../components/Pdf/Pdf-Etude/PdfHeader/PdfHeader";
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

// Images
import aides from "../../assets/pdf/images/aides.png";
import installation from "../../assets/pdf/images/installation.png";
import certirenov from "../../assets/pdf/images/logo-certirenov.png";
import logoFrenchtech from "../../assets/pdf/images/logoFrenchtech.png";
import logoGreenTech from "../../assets/pdf/images/logoGreenTech.png";
import logoIgnFab from "../../assets/pdf/images/logoIgnFab.png";
import logoIso from "../../assets/pdf/images/logoIso.png";
import partenaires from "../../assets/pdf/images/partenaires.png";
import poseurs from "../../assets/pdf/images/poseurs.png";
import rge from "../../assets/pdf/images/rge.png";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  documentId: string;
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  containerPoseurs: {
    display: "flex",
    flexDirection: "row",
    marginTop: 100,
    alignItems: "center",
  },
  containerPoseurs__left: {
    width: "55%",
  },
  containerPoseurs__right: {
    width: "50%",
    position: "absolute",
    right: -58,
  },

  containerPoseurs__left__titles: {
    marginBottom: 20,
  },
  containerPoseurs__left__titles__title: {
    fontSize: 28,
    color: `${DSColors.OffBlack}`,
    fontFamily: "DMSansBold",
    width: 400,
  },
  containerPoseurs__left__titles__subtitle: {
    fontSize: 20,
    color: `${DSColors.PlanetGreen}`,
    fontFamily: "DMSansBold",
  },
  containerPoseurs__left__descriptions: {
    fontSize: 9,
  },
  containerPoseurs__left__descriptions__description: {
    marginBottom: 5,
  },
  containerStatistiques: {
    backgroundColor: "#e0e7fa",
    padding: "20 68",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 40,
    marginBottom: 20,
  },
  containerStatistiques__element: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 9,
    width: "47%",
    height: "auto",
    padding: 10,
  },
  containerStatistiques__element__image: {
    width: 40,
    height: 40,
    objectFit: "contain",
    marginRight: 8,
  },
  containerStatistiques__element__text: {
    display: "flex",
    width: "100%",
  },
  certifications: {
    marginTop: 20,
  },
  certifications__title: {
    fontSize: 12,
    color: "#50545f",
    fontFamily: "DMSansBold",
    marginBottom: 10,
  },
  certifications__containerImages: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  certifications__containerImages__image: {
    width: "20%",
    maxHeight: "80",
    objectFit: "contain",
  },
});

export const PdfEtudePresentationMisterMenuiserie = ({
  firstNameInputValue,
  lastNameInputValue,
  documentId,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Page size="A4" style={styles.page}>
      <PdfHeader />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.containerPoseurs}>
          <View style={localStyle.containerPoseurs__left}>
            <View style={localStyle.containerPoseurs__left__titles}>
              <Text style={localStyle.containerPoseurs__left__titles__title}>
                Mister Menuiserie & Tuco
              </Text>
              <Text style={localStyle.containerPoseurs__left__titles__subtitle}>
                Économisez sur vos factures d’énergie
              </Text>
            </View>
            <View style={localStyle.containerPoseurs__left__descriptions}>
              <Text
                style={
                  localStyle.containerPoseurs__left__descriptions__description
                }
              >
                Mister Menuiserie et ses 150 magasins partout en France,
                spécialisés dans la vente d'équipements extérieur et intérieur
                de la maison (portail, fenêtre, pergola...), s’associent à Tuco,
                spécialiste des panneaux solaires et des projets de rénovation
                énergétique depuis plus de 15 ans.
              </Text>
              <Text
                style={
                  localStyle.containerPoseurs__left__descriptions__description
                }
              >
                Mister Menuiserie vous accompagne sur le dimensionnement de
                votre projet photovoltaïque et les équipes Tuco vous
                l’installent.
              </Text>
              <Text
                style={
                  localStyle.containerPoseurs__left__descriptions__description
                }
              >
                Mister Menuiserie vous accompagne sur le dimensionnement de
                votre projet photovoltaïque et les équipes Tuco vous
                l’installent.
              </Text>
              <Text
                style={
                  localStyle.containerPoseurs__left__descriptions__description
                }
              >
                Retrouvez désormais Mister Menuiserie également dans certaines
                enseignes BRICOMARCHE, BRICORAMA et BRICOCASH, et bénéficiez de
                notre savoir-faire pour transformer votre maison selon vos
                envies.
              </Text>
              <Text
                style={
                  localStyle.containerPoseurs__left__descriptions__description
                }
              >
                Avec notre partenaire Tuco, bénéficiez d'une expertise de 15 ans
                en efficacité énergétique : visite technique, gestion des
                démarches administratives, installations avec poseurs RGE,
                service client.
              </Text>
            </View>
          </View>
          <View style={localStyle.containerPoseurs__right}>
            <Image src={poseurs} />
          </View>
        </View>
      </PdfLayoutEtude>
      <View style={localStyle.containerStatistiques}>
        <View style={localStyle.containerStatistiques__element}>
          <Image
            style={localStyle.containerStatistiques__element__image}
            src={installation}
          />
          <View style={localStyle.containerStatistiques__element__text}>
            <Text>
              <Text style={{ fontFamily: "DMSansBold" }}>
                3 000 installations{" "}
              </Text>
              par an.
            </Text>
          </View>
        </View>
        <View style={localStyle.containerStatistiques__element}>
          <Image
            style={localStyle.containerStatistiques__element__image}
            src={aides}
          />
          <View style={localStyle.containerStatistiques__element__text}>
            <Text>
              <Text style={{ fontFamily: "DMSansBold" }}>Gestion </Text>des
              démarches administratives et des aides.
            </Text>
          </View>
        </View>
        <View style={localStyle.containerStatistiques__element}>
          <Image
            style={localStyle.containerStatistiques__element__image}
            src={partenaires}
          />
          <View style={localStyle.containerStatistiques__element__text}>
            <Text>
              Partenaire des{" "}
              <Text style={{ fontFamily: "DMSansBold" }}>
                meilleurs fabricants européens.
              </Text>
            </Text>
          </View>
        </View>
        <View style={localStyle.containerStatistiques__element}>
          <Image
            style={localStyle.containerStatistiques__element__image}
            src={rge}
          />
          <View style={localStyle.containerStatistiques__element__text}>
            <Text>
              Installation partout en France par nos{" "}
              <Text style={{ fontFamily: "DMSansBold" }}>
                professionnels RGE.
              </Text>
            </Text>
          </View>
        </View>
      </View>
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.certifications}>
          <Text style={localStyle.certifications__title}>
            LEs certifications et labels de Tuco :
          </Text>
          <View style={localStyle.certifications__containerImages}>
            <Image
              style={localStyle.certifications__containerImages__image}
              src={logoGreenTech}
            />
            <Image
              style={localStyle.certifications__containerImages__image}
              src={logoIso}
            />
            <Image
              style={localStyle.certifications__containerImages__image}
              src={logoIgnFab}
            />
            <Image
              style={localStyle.certifications__containerImages__image}
              src={logoFrenchtech}
            />
            <Image
              style={localStyle.certifications__containerImages__image}
              src={certirenov}
            />
          </View>
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType="rénovation globale"
      />
    </Page>
  );
};
