import React from "react";
import { View, Text, StyleSheet, Font } from "@react-pdf/renderer";

// Fonts
import DMSansMedium from "../../../../assets/pdf/fonts/DMSansMedium.ttf";

// Colors
import { DSColors } from "@web/shared/src/styles/variables";

// Register font
Font.register({
  family: "DMSansMedium",
  src: DMSansMedium,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  containerTitleDetail: {
    fontFamily: "DMSansMedium",
    marginBottom: 12,
  },
  containerTitleDetail__title: {
    fontSize: 20,
    color: "#191b1e",
  },
  containerTitleDetail__border: {
    height: 3,
    color: `${DSColors.PlanetGreen}`,
    width: 100,
    marginTop: 4,
  },
});

interface Props {
  titleData: React.ReactElement[];
}

export const PdfTitreDetail = ({ titleData }: Props) => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    <View style={styles.containerTitleDetail} wrap={false}>
      {titleData.map((title, _key) => {
        return (
          <Text key={s4()} style={styles.containerTitleDetail__title}>
            {title}
          </Text>
        );
      })}
      <View style={styles.containerTitleDetail__border}></View>
    </View>
  );
};
