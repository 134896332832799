import { Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import { DSColors } from "@web/shared/src/styles/variables";

// Components
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfEtudeHeaderRG } from "./PdfEtudeHeaderRG";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";

// Images import
import vousEtesIci from "../../assets/pdf/images/fleche.png";

// Interfaces import
import { IStudyInformation } from "../../interfaces/generalInterfaces";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

// Data import
import { monLogementRGMultipleChoicesData } from "../../pages/MonLogementRG/monLogementRGMultipleChoicesData";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  documentId: string;
  studyInformation: IStudyInformation;
  imageHeatLoss: string | null;
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  containerVotreLogement: {
    height: "100%",
  },
  pageTitleContainer: {
    textAlign: "left",
    height: 30,
  },
  pageTitleContent: {
    position: "relative",
  },
  pageTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },
  pageSubTitleContent: {
    display: "flex",
    flexDirection: "row",
    objectFit: "contain",
    alignItems: "center",
  },
  pageSubTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },
  pageSubTitleImage: {
    width: 25,
    marginRight: 5,
  },
  pageTitleUnderline: {
    backgroundColor: DSColors.ZOLight02,
    position: "absolute",
    width: 105,
    height: "45%",
    bottom: 0,
  },

  containerCaracteristiques: {},
  blockCaracteristiques: {
    border: "1px solid #D3D4D7",
    borderRadius: 5,
  },
  blockCaracteristiquesHeader: {
    backgroundColor: DSColors.ABLight06,
    padding: 10,
  },
  blockCaracteristiquesHeaderText: {
    fontFamily: "DMSansBold",
    color: DSColors.OffBlack,
    fontSize: 12,
  },
  blockCaracteristiquesContent: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  blockCaracteristiquesColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  blockCaracteristiquesValueContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 5,
    flexWrap: "wrap",
  },
  blockCaracteristiquesLabel: {
    fontFamily: "DMSansRegular",
    color: DSColors.StableGrey,
    fontSize: 8,
  },
  blockCaracteristiquesValue: {
    fontFamily: "DMSansBold",
    color: DSColors.StableGrey,
    fontSize: 8,
  },

  containerDiagnostique: {
    marginTop: 20,
  },
  blockDiagnostique: {
    border: "1px solid #D3D4D7",
    borderRadius: 5,
    padding: 10,
  },
  containerTitle: {
    fontFamily: "DMSansBold",
    fontSize: 13,
    color: DSColors.OffBlack,
    marginBottom: 5,
  },
  containerSubTitle: {
    fontSize: 9,
    color: DSColors.OffBlack,
    fontFamily: "DMSansRegular",
  },
});

export const PdfEtudeRGPageVotreLogement = ({
  firstNameInputValue,
  lastNameInputValue,
  documentId,
  studyInformation,
  imageHeatLoss,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Page size="A4" style={styles.page}>
      <PdfEtudeHeaderRG />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.containerVotreLogement}>
          <View style={localStyle.pageTitleContainer}>
            <View style={localStyle.pageTitleContent}>
              <View style={localStyle.pageTitleUnderline} />
              <Text style={localStyle.pageTitle}>Votre logement</Text>
            </View>
          </View>
          <View style={localStyle.containerCaracteristiques}>
            <View style={localStyle.blockCaracteristiques}>
              <View style={localStyle.blockCaracteristiquesHeader}>
                <View style={localStyle.blockCaracteristiquesHeaderText}>
                  <Text>Caractéristiques : </Text>
                </View>
              </View>
              <View style={localStyle.blockCaracteristiquesContent}>
                <View style={localStyle.blockCaracteristiquesColumn}>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Année de construction :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.annee_de_construction ||
                        "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Nombre d’occupant(s) :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.number_of_occupants || "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Nombre de niveau(x) :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.nombre_de_niveaux || "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Hauteur sous plafond :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.hauteur_sous_plafond
                        ? monLogementRGMultipleChoicesData.hauteurSousPlafond.options.find(
                            (option) =>
                              option.value ===
                              studyInformation.hauteur_sous_plafond?.toString()
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Surface au sol (m²) :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.surface_au_sol || "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Surface habitable (m²) :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.house_surface || "non renseigné"}
                    </Text>
                  </View>
                </View>
                <View style={localStyle.blockCaracteristiquesColumn}>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Type de murs :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.type_de_murs
                        ? monLogementRGMultipleChoicesData.typeDeMurs.options.find(
                            (option) =>
                              studyInformation.type_de_murs?.includes(
                                option.value
                              )
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Murs isolés depuis :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.annee_isolation_murs
                        ? monLogementRGMultipleChoicesData.anneeIsolation.options.find(
                            (option) =>
                              option.value ===
                              studyInformation.annee_isolation_murs
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Type de vitrage :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.type_de_vitrages
                        ? monLogementRGMultipleChoicesData.typeDeVitrages.options.find(
                            (option) =>
                              studyInformation.type_de_vitrages?.includes(
                                option.value
                              )
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Type de combles :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.type_de_combles
                        ? monLogementRGMultipleChoicesData.typeDeCombles.options.find(
                            (option) =>
                              studyInformation.type_de_combles?.includes(
                                option.value
                              )
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Combles isolés depuis :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.annee_isolation_murs
                        ? monLogementRGMultipleChoicesData.anneeIsolation.options.find(
                            (option) =>
                              option.value ===
                              studyInformation.annee_isolation_combles
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Type de ventilation :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.type_de_ventilation
                        ? monLogementRGMultipleChoicesData.typeDeVentilation.options.find(
                            (option) =>
                              studyInformation.type_de_ventilation?.includes(
                                option.value
                              )
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Type de sous-sol :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.type_de_sous_sol
                        ? monLogementRGMultipleChoicesData.typeDeSousSol.options.find(
                            (option) =>
                              studyInformation.type_de_sous_sol?.includes(
                                option.value
                              )
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Sous-sol isolés depuis :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.annee_isolation_sous_sol
                        ? monLogementRGMultipleChoicesData.anneeIsolation.options.find(
                            (option) =>
                              option.value ===
                              studyInformation.annee_isolation_sous_sol
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                </View>
                <View style={localStyle.blockCaracteristiquesColumn}>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Type de garage :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.type_de_garage
                        ? monLogementRGMultipleChoicesData.typeDeGarage.options.find(
                            (option) =>
                              studyInformation.type_de_garage?.includes(
                                option.value
                              )
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Type de couverture du toit :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.roof_type
                        ? monLogementRGMultipleChoicesData.typeDeCouvertureDuToit.options.find(
                            (option) =>
                              studyInformation.roof_type === option.value
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Type de tuile :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.roof_subtype
                        ? monLogementRGMultipleChoicesData.typeDeTuile.options.find(
                            (option) =>
                              studyInformation.roof_subtype === option.value
                          )?.label
                        : "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Surface équipable (m²):{" "}
                    </Text>{" "}
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.roof_area_to_equip || "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Inclinaison :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.roof_inclinaison || "non renseigné"}
                    </Text>
                  </View>
                  <View style={localStyle.blockCaracteristiquesValueContainer}>
                    <Text style={localStyle.blockCaracteristiquesLabel}>
                      Masque solaire :{" "}
                    </Text>
                    <Text style={localStyle.blockCaracteristiquesValue}>
                      {studyInformation.roof_solar_mask || "non renseigné"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={localStyle.containerDiagnostique}>
            <View style={localStyle.pageTitleContainer}>
              <View style={localStyle.pageSubTitleContent}>
                <Image
                  style={localStyle.pageSubTitleImage}
                  source={vousEtesIci}
                />
                <Text style={localStyle.pageSubTitle}>Notre diagnostic</Text>
              </View>
            </View>
            <View style={localStyle.blockDiagnostique}>
              <Text style={localStyle.containerTitle}>
                Déperditions thermiques
              </Text>
              <Text style={localStyle.containerSubTitle}>
                Les déperditions thermiques sont les pertes de chaleur que subit
                votre logement notamment en raison d'une isolation insuffisante.
                Elles nuisent à votre confort et augmentent vos dépenses
                d'énergie.
              </Text>
              {imageHeatLoss ? (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Image src={imageHeatLoss} style={{ width: 400 }} />
                </View>
              ) : (
                "Image non disponible"
              )}
            </View>
          </View>
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType="rénovation globale"
      />
    </Page>
  );
};
