import { StyleSheet, View, Text } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  containerEtape: {
    width: "350px",
    padding: "10 35 20",
    borderRadius: "10",
  },
  containerEtapeBackgrounded: {
    backgroundColor: "#f3f8fc",
    width: "350px",
    padding: "5 35 10",
    borderRadius: "10",
  },
  containerEtape__containerTitle: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "DMSansBold",
    alignItems: "flex-end",
    marginBottom: 10,
  },
  containerEtape__containerTitle__title: {
    fontSize: 23,
    marginRight: 5,
  },
  containerEtape__containerTitle__subtitle: {
    fontSize: 14,
    marginBottom: 3.5,
  },
  containerEtape__description: {
    fontSize: 9,
    width: "270px",
  },
});

interface Props {
  number: string;
  title: string;
  description: string;
  isBlue?: boolean;
}

export const PdfEtape = ({
  number,
  title,
  description,
  isBlue = false,
}: Props) => {
  return (
    <View
      style={
        !isBlue ? styles.containerEtape : styles.containerEtapeBackgrounded
      }
    >
      <View style={styles.containerEtape__containerTitle}>
        <Text style={styles.containerEtape__containerTitle__title}>
          {number}
        </Text>
        <Text style={styles.containerEtape__containerTitle__subtitle}>
          {title}
        </Text>
      </View>
      <Text style={styles.containerEtape__description}>{description}</Text>
    </View>
  );
};
