import React, { useEffect, useRef } from "react";
import lottie from "lottie-web/build/player/lottie_light";
import animationSuccess from "../jsons/ValidationCheck.json";
import animationFail from "../jsons/animationFail.json";

interface IProps {
  diameter?: number;
  success: boolean;
}

export const ValidationCheck: React.FC<IProps> = ({
  diameter = 53,
  success,
}) => {
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: element.current!,
      animationData: success ? animationSuccess : animationFail,
      renderer: "svg",
      loop: false,
      autoplay: false,
    });

    return () => {
      anim.destroy();
    };
  }, [element]);

  useEffect(() => {
    lottie.play();
  }, []);

  return <div style={{ width: diameter, height: diameter }} ref={element} />;
};
