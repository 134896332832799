// Librairies import
import { CSSTransition } from "react-transition-group";
import SVG from "react-inlinesvg";
import { useState } from "react";

// Styles import
import "./productFilterContainer.scss";

// Images import
import ArrowTab from "@web/shared/dist/assets/images/DesignSystemIcons/chevron-down.svg";

export const ProductFilterContainer = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [isTabOpen, setisPageOpen] = useState(false);
  const [tabHeight, setTabHeight] = useState<number>(0);
  const [isTabFinishedToOpen, setIsTabFinishedToOpen] = useState(false); // Usefull to set overflow to visible or to hidden

  const calcHeight = (DOMelement: HTMLElement) => {
    const height = DOMelement.offsetHeight;
    // const height = 300;
    setTabHeight(height + 30);
  };

  const onExit = () => {
    setTabHeight(0);
  };

  return (
    <>
      <div
        className="FilterComponent"
        onClick={() => setisPageOpen((state) => !state)}
      >
        <CSSTransition in={isTabOpen} timeout={300} classNames={"tab-chevron"}>
          <SVG src={ArrowTab} />
        </CSSTransition>
        <h3>Filtres de recherche produit : </h3>
      </div>
      <div
        className="FilterComponent__Content"
        style={{
          height: tabHeight,
          overflow: isTabFinishedToOpen ? "visible" : "hidden",
        }}
      >
        <CSSTransition
          in={isTabOpen}
          unmountOnExit
          timeout={300}
          onEnter={calcHeight}
          onExit={onExit}
          classNames={"tab-content"}
          onEntered={() => setIsTabFinishedToOpen(true)}
          onExiting={() => setIsTabFinishedToOpen(false)}
        >
          {children}
        </CSSTransition>
      </div>
    </>
  );
};
