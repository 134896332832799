//import style
import "./PerformanceComponentRG.scss";

// Library import
import { useState, useEffect } from "react";

// Components import
import { Layout } from "../../../components/Layout/Layout";
import { EnergyNewLabel } from "../../../components/EnergyNewLabel/EnergyNewLabel";
import { EnergyGain } from "../../../components/EnergyGain/EnergyGain";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { SolarPerformanceComponentRG } from "../SolarPerformanceComponentRG/SolarPerformanceComponentRG";

// Services import
import { useGetPerformanceRG } from "../../../services/RenovationGlobale/MonProjet/useGetPerformanceRG";

// Interfaces import
import {
  IStudyInformation,
  IUserAuthInfo,
} from "../../../interfaces/generalInterfaces";
import {
  IPerformanceRG,
  IDataProduct,
} from "../../../interfaces/renoglobaleInterface";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";

// Local interfaces delcaration
interface IProps {
  studyInformation: IStudyInformation;
  userAuthInfo: IUserAuthInfo;
  cartProducts: IDataProduct[];
}

export const PerformanceComponentRG = ({
  studyInformation,
  userAuthInfo,
  cartProducts,
}: IProps) => {
  const [performanceRGData, setPerformanceRGData] =
    useState<IPerformanceRG | null>();
  const { getPerformanceRG, isLoading, errorMessage } = useGetPerformanceRG();

  const fetchPerformanceRGData = async () => {
    const data = await getPerformanceRG({
      userToken: userAuthInfo.userToken || "",
      study_id: studyInformation.study_id,
    });

    setPerformanceRGData(data);
  };

  useEffect(() => {
    fetchPerformanceRGData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="PerformanceComponentRG">
      <Layout>
        <div className="PerformanceComponentRG__Container">
          {isLoading ? (
            <div className="PerformanceComponentRG__LoaderContainer">
              <LoadingComponent diameter={60} />
            </div>
          ) : errorMessage ? (
            <div className="PerformanceComponentRG__LoaderContainer">
              <ErrorMessage errorMessage={errorMessage} />
            </div>
          ) : (
            <>
              <section className="PerformanceComponentRG__EnergyPerformance">
                <div className="PerformanceComponentRG__Title">
                  <h4>Performance énergétique</h4>
                </div>
                <section className="PerformanceComponentRG__EnergySaving">
                  <EnergyGain performanceRGData={performanceRGData} />
                </section>
                <section className="PerformanceComponentRG__EnergyLabel">
                  <EnergyNewLabel performanceRGData={performanceRGData} />
                </section>
                <section className="PerformanceComponentRG__SolarPerformance"></section>
              </section>
              {/* We display the solar section only if we have a solar product in the cart */}
              {cartProducts.find((elem) => elem.product_type === "PV") ? (
                <section className="PerformanceComponentRG__SolarPerformance">
                  <div className="PerformanceComponentRG__Title">
                    <h4>Performance solaire</h4>
                  </div>
                  <section className="PerformanceComponentRG__SolarPerformanceRG">
                    <SolarPerformanceComponentRG
                      performanceRGData={performanceRGData}
                    />
                  </section>
                </section>
              ) : null}
            </>
          )}
        </div>
      </Layout>
    </div>
  );
};
