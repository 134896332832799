import "./InputAddressRG.scss";

// Libraries import
import React, { useEffect, useState } from "react";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";

// Local interface declaration
interface Props {
  onSelectLocation: ({
    markerLat,
    markerLng,
    zipCode,
    description,
  }: {
    markerLat: number;
    markerLng: number;
    zipCode: string;
    city: string;
    description: string;
  }) => void;
  addressInRedux: string;
  isDisabled?: boolean;
}

export const InputAddressRG = ({
  onSelectLocation,
  addressInRedux,
  isDisabled = false,
}: Props) => {
  const [loaded, setLoaded] = useState(false);
  const {
    value,
    suggestions: { status, data, loading },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "fr",
      },
    },
    debounce: 300,
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoaded(true);
    const value = e.target.value;
    setValue(value);
  };

  const handleSelect =
    ({ description }: google.maps.places.AutocompletePrediction) =>
    async () => {
      try {
        setValue(description, false);
        clearSuggestions();
        const results = await getGeocode({ address: description });

        const values = await Promise.all([
          getLatLng(results[0]),
          getZipCode(results[0], false),
        ]);
        const { address_components } = results[0];
        const city = (() => {
          for (let component of address_components) {
            if (component.types.includes("locality")) {
              return component.long_name; // Return the selected city
            }
          }
          return "";
        })();
        const { lat, lng } = values[0];
        const zipCode = values[1] || "";
        onSelectLocation({
          markerLat: lat,
          markerLng: lng,
          zipCode,
          city,
          description,
        });
      } catch (error) {
        console.error(error);
      }
    };
  const NoResult = () => {
    if (status === "ZERO_RESULTS") {
      return (
        <div className="inputAddress__List">
          <div className="inputAddress__ListElement">
            <strong>Aucun résultat</strong>
          </div>
        </div>
      );
    }
    if (loading) {
      return (
        <div className="inputAddress__List">
          <div className="inputAddress__ListElement">
            <strong>Chargement...</strong>
          </div>
        </div>
      );
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div key={index} className="inputAddress__RenderSuggestions">
          <div
            className="inputAddress__ListElement"
            key={place_id}
            onClick={handleSelect(suggestion)}
          >
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </div>
        </div>
      );
    });

  useEffect(() => {
    setValue(addressInRedux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="inputAddress">
      <TextInput
        value={value}
        type="text"
        onChange={handleInput}
        placeholder="ex : 6 Rue d'Italie, 49300 Cholet"
        isDisabled={isDisabled}
        iconHidden={true}
        inputLableHidden={true}
        inputDescriptionHidden={true}
        customInputWrapperStyle={{ maxWidth: "none" }}
      />
      {status === "OK" && loaded ? (
        <div className="inputAddress__Form">
          <div className="inputAddress__List">{renderSuggestions()}</div>
        </div>
      ) : (
        <div className="inputAddress__Form">{NoResult()}</div>
      )}
    </div>
  );
};
