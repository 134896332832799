import "./solarPotentialLoadingScreenWrapper.scss";

// Components import
import { Layout } from "../../components/Layout/Layout";
import { PotentialSolarLoadingComponent } from "./PotentialSolarLoadingComponent/PotentialSolarLoadingComponent";

// Image import
import HouseImage from "../../assets/images/footer/footerStartEtude.svg";
import Cloud from "../../assets/images/cloud.svg";

export const SolarPotentialLoadingScreenWrapper = () => {
  return (
    <div className="SolarPotentialLoadingScreenWrapper">
      <Layout>
        <div className="SolarPotentialLoadingScreenWrapper__CloudContainer">
          <div className="SolarPotentialLoadingScreenWrapper__CloudsLeft">
            <div className="SolarPotentialLoadingScreenWrapper__CloudOne">
              <img src={Cloud} alt="Cloud"></img>
            </div>
            <div className="SolarPotentialLoadingScreenWrapper__CloudTwo">
              <img src={Cloud} alt="Cloud"></img>
            </div>
          </div>
          <PotentialSolarLoadingComponent />
          <div className="SolarPotentialLoadingScreenWrapper__CloudsRight">
            <div className="SolarPotentialLoadingScreenWrapper__CloudThree">
              <img src={Cloud} alt="Cloud"></img>
            </div>
            <div className="SolarPotentialLoadingScreenWrapper__CloudFour">
              <img src={Cloud} alt="Cloud"></img>
            </div>
          </div>
        </div>
      </Layout>
      <div className="SolarPotentialLoadingScreenWrapper__HouseContainer">
        <img src={HouseImage} alt="Landscape with an house"></img>
      </div>
    </div>
  );
};
