import axios from "axios";

const eeroApi = axios.create({
  baseURL: process.env.REACT_APP_EERO_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

eeroApi.interceptors.request.use((config) => {
  const userAuthInfo = localStorage.getItem("userAuthInfo");

  if (userAuthInfo) {
    const userAuthInfoObj = JSON.parse(userAuthInfo);
    config.headers.Authorization = `Bearer ${userAuthInfoObj.userToken}`;
  }

  return config;
});

export default eeroApi;
