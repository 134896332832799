interface IProps {
  number: number;
  decimalPlaces: number;
  isDeductible?: boolean;
}

export const formatNumberWithSpacesAndDecimal = ({
  number,
  decimalPlaces,
  isDeductible,
}: IProps) => {
  const parts = Number(number).toFixed(decimalPlaces).split(".");
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const decimalPart = parts[1];
  return (
    (isDeductible ? "-" : "") +
    (decimalPart ? `${integerPart},${decimalPart}` : integerPart)
  );
};
