// Interfaces import
import { IScenario } from "../../interfaces/generalInterfaces";

// Local interface declaration
interface IProps {
  scenarioChosen: IScenario;
  coupons?: { label: string; type: string; value: number }[];
}

export const calculatePVpathPrices = ({
  scenarioChosen,
  coupons = [],
}: IProps) => {
  const roudNumber = (number: number) => {
    return Math.round(number * 100) / 100;
  };
  const priceAllCoupons = () => {
    let total = 0;
    for (let i = 0; i < coupons.length; i++) {
      total += coupons[i].value;
    }
    return total;
  };

  // ------------ TVA && AIDE
  const tvaGlobale = scenarioChosen.tva ? roudNumber(scenarioChosen.tva) : 0;

  const totalHelps = scenarioChosen.total_helps
    ? roudNumber(scenarioChosen.total_helps)
    : 0;

  // ------------ PRIX PV
  const prixPVHTSansPose = scenarioChosen.pv_price_without_tax
    ? roudNumber(scenarioChosen.pv_price_without_tax)
    : 0;

  const prixPVHTAvecPose =
    scenarioChosen.pv_price_without_tax &&
    scenarioChosen.pv_pose_price_without_tax
      ? roudNumber(
          scenarioChosen.pv_price_without_tax +
            scenarioChosen.pv_pose_price_without_tax
        )
      : 0;

  const prixPVTTCAvecPose =
    prixPVHTAvecPose && tvaGlobale
      ? roudNumber(prixPVHTAvecPose * ((100 + tvaGlobale) / 100))
      : 0;

  const prixPVTTCSansPose =
    prixPVHTSansPose && tvaGlobale
      ? roudNumber(prixPVHTSansPose * ((100 + tvaGlobale) / 100))
      : 0;

  const forfaitInstallationHTPV = scenarioChosen.pv_pose_price_without_tax
    ? roudNumber(scenarioChosen.pv_pose_price_without_tax)
    : 0;

  const forfaitInstallationTTCPV =
    forfaitInstallationHTPV &&
    tvaGlobale &&
    roudNumber(forfaitInstallationHTPV * ((100 + tvaGlobale) / 100));

  const totalTvaPv = prixPVTTCAvecPose - prixPVHTAvecPose;

  // ------------ PRIX KIT
  const prixKitHomeManagementHTSansPose = scenarioChosen.kit_price_without_tax
    ? roudNumber(scenarioChosen.kit_price_without_tax)
    : 0;

  const prixKitHomeManagmentHTAvecPose =
    scenarioChosen.kit_price_without_tax &&
    scenarioChosen.kit_home_management &&
    scenarioChosen.kit_pose_price_without_tax
      ? roudNumber(
          scenarioChosen.kit_price_without_tax +
            scenarioChosen.kit_pose_price_without_tax
        )
      : 0;

  const prixKitHomeManagementTTCAvecPose =
    prixKitHomeManagmentHTAvecPose &&
    tvaGlobale &&
    scenarioChosen.kit_home_management
      ? roudNumber(prixKitHomeManagmentHTAvecPose * ((100 + tvaGlobale) / 100))
      : 0;

  const prixKitHomeManagementTTCSansPose =
    prixKitHomeManagementHTSansPose &&
    tvaGlobale &&
    scenarioChosen.kit_home_management
      ? roudNumber(prixKitHomeManagementHTSansPose * ((100 + tvaGlobale) / 100))
      : 0;

  const forfaitInstallationHTKitHomeManagement =
    scenarioChosen.kit_pose_price_without_tax &&
    scenarioChosen.kit_home_management
      ? roudNumber(scenarioChosen.kit_pose_price_without_tax)
      : 0;

  const forfaitInstallationTTCKitHomeManagement =
    forfaitInstallationHTKitHomeManagement &&
    tvaGlobale &&
    scenarioChosen.kit_home_management
      ? roudNumber(
          forfaitInstallationHTKitHomeManagement * ((100 + tvaGlobale) / 100)
        )
      : 0;

  const totalTvaKitHomeManagement =
    prixKitHomeManagementTTCAvecPose - prixKitHomeManagmentHTAvecPose;

  // ------------ PRIX BATTERY

  const batteryPriceWithoutInstallHT =
    scenarioChosen.is_storage_battery &&
    scenarioChosen.battery_price_without_tax
      ? roudNumber(scenarioChosen.battery_price_without_tax)
      : 0;

  const batteryPriceWithInstallHT =
    scenarioChosen.is_storage_battery &&
    scenarioChosen.battery_price_without_tax &&
    scenarioChosen.battery_pose_price_without_tax
      ? roudNumber(
          scenarioChosen.battery_price_without_tax +
            scenarioChosen.battery_pose_price_without_tax /
              (scenarioChosen?.number_of_batteries || 1) // price is devide by number of batteries because battery_pose_price_without_tax is calculated for all batteries
        )
      : 0;

  const allBatteriesPriceWhitInstallHT =
    batteryPriceWithInstallHT && scenarioChosen.is_storage_battery
      ? roudNumber(
          batteryPriceWithInstallHT * (scenarioChosen?.number_of_batteries || 1)
        )
      : 0;

  const allBatteriesPriceWhitInstallTTC =
    batteryPriceWithInstallHT && tvaGlobale && scenarioChosen.is_storage_battery
      ? roudNumber(
          batteryPriceWithInstallHT *
            ((100 + tvaGlobale) / 100) *
            (scenarioChosen?.number_of_batteries || 1)
        )
      : 0;

  const allBatteriesPriceWhitoutInstallTTC =
    batteryPriceWithoutInstallHT &&
    tvaGlobale &&
    scenarioChosen.is_storage_battery
      ? roudNumber(
          batteryPriceWithoutInstallHT *
            ((100 + tvaGlobale) / 100) *
            (scenarioChosen?.number_of_batteries || 1)
        )
      : 0;

  const allBatteriesPriceWhitoutInstallHT =
    batteryPriceWithoutInstallHT &&
    tvaGlobale &&
    scenarioChosen.is_storage_battery
      ? roudNumber(
          batteryPriceWithoutInstallHT *
            (scenarioChosen?.number_of_batteries || 1)
        )
      : 0;

  const batteryInstallPriceHT =
    scenarioChosen.battery_pose_price_without_tax &&
    scenarioChosen.is_storage_battery
      ? roudNumber(scenarioChosen.battery_pose_price_without_tax)
      : 0;

  const batteryInstallPriceTTC =
    scenarioChosen.is_storage_battery && batteryInstallPriceHT && tvaGlobale
      ? roudNumber(batteryInstallPriceHT * ((100 + tvaGlobale) / 100))
      : 0;

  const totalTvaBatteries =
    allBatteriesPriceWhitInstallTTC - allBatteriesPriceWhitInstallHT;

  // ------------ PRIX FRAIS DE GESTION ---------

  const managementFeesTTC = 486;
  const managementFeesHT = 486 / (1 + tvaGlobale / 100);

  // ------------ PRIX FRAIS SUPPLEMENTAIRES ---------
  const totalPriceExtraFeesTTC = scenarioChosen.extra_fees
    ? scenarioChosen.extra_fees.reduce(
        (acc, fee) => acc + fee.price_with_tax,
        0
      )
    : 0;

  const totalPriceExtraFeesHT = scenarioChosen.extra_fees
    ? scenarioChosen.extra_fees.reduce(
        (acc, fee) => acc + fee.price_without_tax,
        0
      )
    : 0;

  const totalTVAExtraFees = totalPriceExtraFeesTTC - totalPriceExtraFeesHT;

  // ------------ TOTAL ----------
  const totalProjectCost = scenarioChosen?.total_cost_without_help || 0;

  const totalCostTva =
    totalTvaPv +
    totalTvaKitHomeManagement +
    totalTvaBatteries +
    totalTVAExtraFees;

  // For PV there is only one help (prime autoconsommation) wich is always refundable
  const totalRefundableHelps = totalHelps;
  const totalDeductibleHelps = 0;

  //  ------------ RESTE A CHARGE  ------------
  const totalResteACharge =
    allBatteriesPriceWhitInstallTTC +
    prixPVTTCAvecPose +
    totalPriceExtraFeesTTC +
    prixKitHomeManagementTTCAvecPose -
    priceAllCoupons();

  const totalProjectCostHelpsDeducted = totalResteACharge - totalHelps;

  const resteAChargePhotovoltaique =
    prixPVHTSansPose +
    forfaitInstallationHTPV +
    (prixPVHTSansPose + forfaitInstallationHTPV) * (tvaGlobale / 100) -
    priceAllCoupons();

  return {
    tvaGlobale,
    totalHelps,
    prixPVHTSansPose,
    prixPVHTAvecPose,
    prixPVTTCAvecPose,
    prixPVTTCSansPose,
    forfaitInstallationHTPV,
    forfaitInstallationTTCPV,
    prixKitHomeManagementHTSansPose,
    prixKitHomeManagmentHTAvecPose,
    prixKitHomeManagementTTCAvecPose,
    prixKitHomeManagementTTCSansPose,
    forfaitInstallationHTKitHomeManagement,
    forfaitInstallationTTCKitHomeManagement,
    batteryPriceWithoutInstallHT,
    batteryPriceWithInstallHT,
    allBatteriesPriceWhitInstallHT,
    allBatteriesPriceWhitInstallTTC,
    allBatteriesPriceWhitoutInstallTTC,
    allBatteriesPriceWhitoutInstallHT,
    batteryInstallPriceHT,
    batteryInstallPriceTTC,
    managementFeesTTC,
    managementFeesHT,
    totalResteACharge,
    resteAChargePhotovoltaique,
    totalProjectCost,
    totalRefundableHelps,
    totalDeductibleHelps,
    totalProjectCostHelpsDeducted,
    totalCostTva,
    extraFees: {
      totalPriceTTC: totalPriceExtraFeesTTC,
      totalPriceHT: totalPriceExtraFeesHT,
      totalTVA: totalTVAExtraFees,
    },
  };
};
