// Style import
import "./ChartsContainerRG.scss";

// Interface import
import {
  IProfitabilityRG,
  IDataProduct,
} from "../../../interfaces/renoglobaleInterface";
import { IStudyInformation } from "../../../interfaces/generalInterfaces";

// Component import
import { TotalGainGraphsRG } from "../TotalGainGraphsRG/TotalGainGraphsRG";

interface IProps {
  profitabilityRGData: IProfitabilityRG;
  studyInformation: IStudyInformation;
  cartProducts: IDataProduct[];
}

export const ChartsContainerRG = ({
  profitabilityRGData,
  studyInformation,
  cartProducts,
}: IProps) => {
  const formattedSavingsGraphData = () => {
    const newArray = [];
    for (let index in profitabilityRGData?.savings?.heating) {
      newArray.push({
        annee: (Number(index) + 1).toString(),
        heating: profitabilityRGData?.savings.heating?.[index] || 0,
        ecs: profitabilityRGData?.savings.ecs?.[index] || 0,
        autoconsumption:
          profitabilityRGData?.savings.autoconsumption?.[index] || 0,
        resale: profitabilityRGData?.savings.resale?.[index] || 0,
      });
    }

    return newArray;
  };

  return (
    <div className="ChartsContainer">
      <section className="ChartsContainer__TotalGainGraphs">
        <TotalGainGraphsRG
          savingsGraphData={formattedSavingsGraphData()}
          formattedEnergyPriceGraphData={formattedEnergyPriceGraphData({
            studyInformation,
            profitabilityRGData,
            cartProducts,
          })}
        />
      </section>
    </div>
  );
};

interface IFormattedEnergyPriceGraphData {
  profitabilityRGData: IProfitabilityRG;
  studyInformation: IStudyInformation;
  cartProducts: IDataProduct[];
}

export const formattedEnergyPriceGraphData = ({
  profitabilityRGData,
  studyInformation,
  cartProducts,
}: IFormattedEnergyPriceGraphData) => {
  const newArray = [];
  for (let index in profitabilityRGData?.energy_prices?.electricite) {
    newArray.push({
      annee: (Number(index) + 1).toString(),
      ...(studyInformation.source_energie?.includes("electricite") && {
        electricite:
          profitabilityRGData?.energy_prices.electricite?.[index] || 0,
      }),
      ...(studyInformation.source_energie?.includes("gaz") && {
        gaz: profitabilityRGData?.energy_prices.gaz?.[index] || 0,
      }),
      ...(studyInformation.source_energie?.includes("fioul") && {
        fioul: profitabilityRGData?.energy_prices.fioul?.[index] || 0,
      }),

      ...(cartProducts.some((product) => product.product_type === "PV") && {
        solaire: profitabilityRGData?.energy_prices.solaire?.[index] || 0,
      }),
    });
  }

  return newArray;
};
