//Style import
import "./detailOptionTabs.scss";

//component import
import { SingleTab } from "../SingleTab/SingleTab";
import { Layout } from "../../Layout/Layout";

//img import
import logo_dimensionnement from "@web/shared/src/assets/images/DesignSystemIcons/expand.svg";
import logo_impactEnvironnement from "@web/shared/src/assets/images/DesignSystemIcons/globe-2.svg";
import logo_performence from "@web/shared/src/assets/images/DesignSystemIcons/timer.svg";
import logo_rentabilite from "@web/shared/src/assets/images/DesignSystemIcons/pie-chart.svg";
import logo_euro from "@web/shared/src/assets/images/DesignSystemIcons/euro.svg";

//local interface
interface IProps {
  isTabOpen: boolean[];
  onTabClick: (index: number) => void;
}

export const DetailOptionTabs = ({ isTabOpen, onTabClick }: IProps) => {
  return (
    <Layout>
      <div className="DetailOptionTabs">
        <>
          <SingleTab
            title="Dimensionnement"
            imgSrc={logo_dimensionnement}
            onClick={() => onTabClick(0)}
            isOpen={isTabOpen[0]}
          />
          <SingleTab
            title="Performance"
            imgSrc={logo_performence}
            onClick={() => onTabClick(1)}
            isOpen={isTabOpen[1]}
          />
          <SingleTab
            title="Rentabilité"
            imgSrc={logo_rentabilite}
            onClick={() => onTabClick(2)}
            isOpen={isTabOpen[2]}
          />
          <SingleTab
            title="Impact environnemental"
            imgSrc={logo_impactEnvironnement}
            onClick={() => onTabClick(3)}
            isOpen={isTabOpen[3]}
          />
          <SingleTab
            title="Financement"
            imgSrc={logo_euro}
            onClick={() => onTabClick(4)}
            isOpen={isTabOpen[4]}
          />
        </>
      </div>
    </Layout>
  );
};
