// Librairies import
import { useNavigate } from "react-router-dom";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { NavigationBarOutsideStudy } from "../../components/NavigationBarOutsideStudy/NavigationBarOutsideStudy";
import { FooterHills } from "../../components/FooterHills/FooterHills";

// Services import
import { useAppSelector } from "../../redux/store/hook";

export const Ressources = () => {
  const { userAuthInfo } = useAppSelector((state) => state);
  const navigate = useNavigate();
  return (
    <div className="StartEtude">
      <NavigationBarOutsideStudy title={"Nouvelle étude"} />
      <div className="StartEtude__ButtonsContainer">
        <h1>Ressources :</h1>

        {userAuthInfo.companyId === "b997f1a6-1bf0-e911-a812-000d3a4aabe1" ? (
          // b997f1a6-1bf0-e911-a812-000d3a4aabe1 => tucoenergie
          <CTAButton
            name="Carte des installations"
            category="secondary"
            onClick={() => {
              navigate(`/projetGedeon`);
            }}
          />
        ) : null}
      </div>

      <FooterHills />
    </div>
  );
};
