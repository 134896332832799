import "./TopSectionMainInformation.scss";

// images import
import pictoChart from "../../../assets/images/picto-chart.svg";
import pictoResteACharge from "../../../assets/images/picto-main-euros.svg";
import pictoProdGarantee from "../../../assets/images/picto-main-pouce-etoiles.svg";

// Interface import
import { IProfitability } from "../../../interfaces/generalInterfaces";

// Services import
import { euroShortFormat } from "../../../services/euroFormatter";

interface IProps {
  profitability: IProfitability | null;
}

export const TopSectionMainInformation = ({ profitability }: IProps) => {
  return (
    <div className="TopSectionMainInfo">
      <div className="TopSectionMainInfo__SingleInfoBlock">
        <section className="imageContainer">
          <img
            src={pictoResteACharge}
            alt={"Reste à charge"}
            width={36}
            height={35}
          />
        </section>
        <section className="textContainer">
          <p className="title">PRIX TTC (hors aides)</p>
          <p className="value">
            {profitability?.total_net_cost &&
              euroShortFormat(profitability.total_net_cost)}
          </p>
        </section>
      </div>
      <div className="TopSectionMainInfo__SingleInfoBlock">
        <section className="imageContainer">
          <img
            src={pictoProdGarantee}
            alt="garantie de performance"
            width={43}
            height={39}
          />
        </section>
        <section className="textContainer">
          <p className="title">{"Total des aides".toUpperCase()}</p>
          <p className="value">
            {profitability?.total_help &&
              euroShortFormat(profitability.total_help)}
          </p>
        </section>
      </div>
      <div className="TopSectionMainInfo__SingleInfoBlock">
        <section className="imageContainer">
          <img src={pictoChart} alt="gains cumulés" width={39} height={37} />
        </section>
        <section className="textContainer">
          <p className="title">{"rentabilité annuelle".toUpperCase()}</p>
          <p className="value">
            {profitability?.internal_rate_return &&
              new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 0,
              }).format(profitability.internal_rate_return * 100) + " %"}
          </p>
        </section>
      </div>
    </div>
  );
};
