export const blobToBase64 = (blob: Blob | undefined) => {
  if (!blob) return;
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    blob && reader.readAsDataURL(blob);
  });
};

export const openPDFInNewTab = (PDFAsBlob?: Blob) => {
  if (PDFAsBlob) {
    //Build a URL from the file
    const fileURL = URL.createObjectURL(PDFAsBlob);
    //Open the URL on new Window
    const pdfWindow = window.open();
    if (pdfWindow) {
      pdfWindow.location.href = fileURL;
    }
  }
};
