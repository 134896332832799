export const financingPeriodInMonth = [
  { value: 1, label: "12 mois" },
  { value: 2, label: "24 mois" },
  { value: 3, label: "36 mois" },
  { value: 4, label: "48 mois" },
  { value: 5, label: "60 mois" },
  { value: 6, label: "72 mois" },
  { value: 7, label: "84 mois" },
  { value: 8, label: "96 mois" },
  { value: 9, label: "108 mois" },
  { value: 10, label: "120 mois" },
  { value: 11, label: "132 mois" },
  { value: 12, label: "144 mois" },
  { value: 13, label: "156 mois" },
  { value: 14, label: "168 mois" },
  { value: 15, label: "180 mois" },
];
