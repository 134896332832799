import {
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import "./ExtraElectricitySalesGraphRG.scss";

interface IProps {
  formattedEnergyPriceGraphData: {
    annee: string;
    electricite?: number;
    gaz?: number;
    fioul?: number;
    solaire?: number;
  }[];
  isForPdf?: boolean;
}

export const ExtraElectricitySalesGraphRG = ({
  formattedEnergyPriceGraphData,
  isForPdf = false,
}: IProps) => {
  const legendDictionnary = {
    ...(formattedEnergyPriceGraphData[0].electricite !== undefined && {
      electricite: "kwh réseau",
    }),
    ...(formattedEnergyPriceGraphData[0].solaire !== undefined && {
      solaire: "kwh solaire",
    }),
    ...(formattedEnergyPriceGraphData[0].gaz !== undefined && {
      gaz: "kwh gaz",
    }),
    ...(formattedEnergyPriceGraphData[0].fioul !== undefined && {
      fioul: "kwh fioul",
    }),
  };
  const renderLegend = (props: any) => {
    const { payload } = props;

    type keyOfDictionnary = keyof typeof legendDictionnary;

    return (
      <ul className="legendContainer">
        {payload &&
          payload.map((entry: any, index: any) => (
            <li
              key={`item-${index}`}
              className={`legendItem`}
              style={{ color: entry.color }}
            >
              <div
                className="colorPastille"
                style={{ backgroundColor: entry.color }}
              />
              <p>
                {legendDictionnary &&
                  legendDictionnary?.[
                    entry?.value as keyOfDictionnary
                  ]?.toUpperCase()}
              </p>
            </li>
          ))}
      </ul>
    );
  };

  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className="customTooltip">
          {payload.map((elem) => {
            return (
              <div className="labelPriceContainer">
                <div
                  className="label colorPastille"
                  style={{ backgroundColor: elem.color }}
                />
                <p className="price">{`${
                  elem.payload?.[elem?.name || ""]
                } €`}</p>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };
  return (
    <div className="ExtraElectricitySalesGraphRG">
      <p className="ExtraElectricitySalesGraphRG__Title">
        Évolution du prix de l'énergie
      </p>
      <section className="ExtraElectricitySalesGraphRG__GraphContainer">
        <ResponsiveContainer>
          <ComposedChart
            width={100}
            height={350}
            data={formattedEnergyPriceGraphData}
            margin={{ bottom: 20, left: 10, right: 10 }}
          >
            <XAxis
              dataKey="annee"
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
              label={{
                value: "Année",
                position: "insideBottom",
                offset: -10,
                fontSize: "1.2rem",
              }}
            />
            <YAxis
              dataKey="electricite"
              yAxisId="prix_kwh_reseau/prix_kwh_solaire"
              orientation="left"
              fontSize={30}
              style={{ fontSize: "1rem", fontWeight: "bold" }}
              name="Prix du kWh"
              label={{
                value: "Prix du kWh",
                angle: -90,
                position: "insideLeft",
                offset: 10,
                fontSize: "1.2rem",
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={renderLegend}
              verticalAlign="top"
              align="center"
              height={50}
            />
            {formattedEnergyPriceGraphData[0].electricite !== undefined && (
              <Line
                type="monotone"
                dataKey="electricite"
                stroke="#0073E0"
                yAxisId="prix_kwh_reseau/prix_kwh_solaire"
                style={{ strokeWidth: 3 }}
              />
            )}
            {formattedEnergyPriceGraphData[0].solaire !== undefined && (
              <Line
                type="monotone"
                dataKey="solaire"
                yAxisId="prix_kwh_reseau/prix_kwh_solaire"
                stroke="#FFC043"
                style={{ strokeWidth: 3 }}
              />
            )}
            {formattedEnergyPriceGraphData[0].gaz !== undefined && (
              <Line
                type="monotone"
                dataKey="gaz"
                stroke="#46D1CA"
                yAxisId="prix_kwh_reseau/prix_kwh_solaire"
                style={{ strokeWidth: 3 }}
              />
            )}
            {formattedEnergyPriceGraphData[0].fioul !== undefined && (
              <Line
                type="monotone"
                dataKey="fioul"
                stroke="#E02819"
                yAxisId="prix_kwh_reseau/prix_kwh_solaire"
                style={{ strokeWidth: 3 }}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </section>
      {!isForPdf ? (
        <section className="ExtraElectricitySalesGraphRG__GraphDescription">
          <p className="Title">Évolution du prix de l’énergie</p>
          <p className="Description">
            Nous nous basons sur une hypothèse d'augmentation des prix de 5% par
            an pour les différentes énergies{" "}
            {formattedEnergyPriceGraphData[0].solaire !== undefined
              ? ", tandis que votre kWh solaire reste fixe pendant toute la durée de vie de votre installation."
              : "."}
          </p>
        </section>
      ) : null}
    </div>
  );
};
