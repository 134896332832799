import { SetStateAction, Dispatch } from "react";
import "./arrayHeader.scss";

// Library import
import SVG from "react-inlinesvg";

// Components import
import Sort from "@web/shared/dist/assets/images/DesignSystemIcons/sort.svg";

interface IProps {
  setSortedColumn: Dispatch<
    SetStateAction<"lastname" | "created_at" | "modified_at">
  >;
  setSortType: Dispatch<SetStateAction<"ascendant" | "descendant">>;
  sortType: "ascendant" | "descendant";
}

export const ArrayHeader = ({
  setSortedColumn,
  setSortType,
  sortType,
}: IProps) => {
  const sortColumn = ({
    sortedColumn,
  }: {
    sortedColumn: "lastname" | "created_at" | "modified_at";
  }) => {
    setSortedColumn(sortedColumn);
    setSortType((state) => {
      if (state === "ascendant") {
        return "descendant";
      }
      return "ascendant";
    });
  };

  return (
    <div className="arrayHeader">
      <div className="arrayHeader__Container">
        <p className="flex-item size-0">Type</p>
        <div
          className="flex-item size-1"
          onClick={() => sortColumn({ sortedColumn: "lastname" })}
        >
          <SVG src={Sort} />
          <p>Nom Prénom</p>
        </div>
        <p className="flex-item size-2">CP</p>
        <p className="flex-item size-3">Adresse</p>
        <p className="flex-item size-4">Devis</p>
        <p className="flex-item size-5">BDC</p>
        <p className="flex-item size-6">Montant dernier BDC</p>
        <div
          className="flex-item size-7"
          onClick={() => sortColumn({ sortedColumn: "created_at" })}
        >
          <SVG src={Sort} />
          <p>Création d'étude</p>
        </div>
        <div
          className="flex-item size-8"
          onClick={() => sortColumn({ sortedColumn: "modified_at" })}
        >
          <SVG src={Sort} />
          <p>Dernière action</p>
        </div>

        {/* last item of array to match the arrow in the singleVisit Item */}
      </div>
    </div>
  );
};
