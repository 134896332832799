import { StyleSheet, Image, View } from "@react-pdf/renderer";

// Images
import logoTucoLittle from "../../../../assets/pdf/images/oree_logo_etude.png";

// Create styles
const styles = StyleSheet.create({
  image: {
    width: "60",
    objectFit: "contain",
    position: "absolute",
    right: 58,
    top: 5,
    padding: "5 0",
  },
});

interface Props {
  isMargeTop?: boolean;
  isMargeBottom?: boolean;
}

export const PdfHeader = ({
  isMargeTop = false,
  isMargeBottom = false,
}: Props) => {
  return (
    <View
      fixed
      style={{
        marginTop: 10,
        height: isMargeTop ? 35 : 0,
        marginBottom: isMargeBottom ? 60 : 0,
      }}
    >
      <Image src={logoTucoLittle} style={styles.image} />
    </View>
  );
};
