//Styles import
import "./ProductsCatalogue.scss";

//Liraries import
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/store/hook";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Layout } from "../../components/Layout/Layout";

// Services import
import { productsImagesDictionnary } from "../../services/RenovationGlobale/productsImagesDictionnary";
import { productsNamesDictionnary } from "../../services/RenovationGlobale/productsNamesDictionnary";
import { useGetProductsCategories } from "../../services/RenovationGlobale/MonProjet/useGetProductsCategories";

// Interfaces import
import { TProjectPages } from "../../interfaces/renoglobaleInterface";

// images import
import chevron from "@web/shared/dist/assets/images/DesignSystemIcons/chevron-right.svg";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// Local interfaces declaration
interface IProps {
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  setProductCategorySelected: React.Dispatch<React.SetStateAction<string>>;
}

export const ProductsCatalogue = ({
  setProductCategorySelected,
  setScreenSelected,
}: IProps) => {
  const { studyInformation, userAuthInfo } = useAppSelector((state) => state);
  const { getProductsCategories, isLoading } = useGetProductsCategories();

  const [productsCategories, setProductsCategories] = useState<string[]>([]);

  const fetchProductsCategories = async () => {
    const productsTypesResponse = await getProductsCategories({
      userToken: userAuthInfo.userToken,
      study_id: studyInformation?.study_id || "",
    });

    setProductsCategories(productsTypesResponse?.available_categories || []);
  };

  const onClick = (product_type: string) => {
    setScreenSelected("product_page_from_catalogue");
    setProductCategorySelected(product_type);
  };

  useEffect(() => {
    fetchProductsCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ProductsCatalogue">
      <Layout>
        <div className="ProductsCatalogue__LayoutContent">
          <section className="ProductsCatalogue__Header">
            <div
              className="ProductsCatalogue__GoBack"
              onClick={() => setScreenSelected("project")}
            >
              <img src={chevron} alt="" />
              <p>Mon projet</p>
            </div>
            <div className="ProductsCatalogue__Title">
              <h4>Ajouter un produit du catalogue</h4>
            </div>
          </section>
          {isLoading ? (
            <div className="ProductsCatalogue__LoaderContainer">
              <p>Chargement des catégories...</p>
              <LoadingComponent diameter={60} />
            </div>
          ) : (
            <section className="ProductsCatalogue__products_container">
              {productsCategories.map((product_type, index) => {
                return (
                  <div className="ProductsCatalogue__product_box" key={index}>
                    <div className="image_container">
                      <img
                        src={productsImagesDictionnary({ product_type })}
                        alt="produit"
                      />
                    </div>
                    <h2>{productsNamesDictionnary({ product_type })}</h2>
                    <div className="button_container">
                      <CTAButton
                        name="Configurer"
                        category="secondary"
                        onClick={() => onClick(product_type)}
                      />
                    </div>
                  </div>
                );
              })}
            </section>
          )}
        </div>
      </Layout>
    </div>
  );
};
