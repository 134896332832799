import React from "react";
import { Image, StyleSheet } from "@react-pdf/renderer";

// Images
import fondBleu from "../../../../assets/pdf/images/fondBleu.png";
import fondGray from "../../../../assets/pdf/images/fondGray.png";

// Create styles
const styles = StyleSheet.create({
  fondBleu: {
    width: "100vw",
  },
});

// Interfaces declaration
interface Props {
  isFondBleu?: boolean;
}

export const PdfBanner: React.FC<Props> = ({ isFondBleu }: Props) => {
  return (
    <Image style={styles.fondBleu} src={isFondBleu ? fondBleu : fondGray} />
  );
};
