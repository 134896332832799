import React from "react";
import "./potentielSolaireLegendImage.scss";

// Images import
import potSolImgDktp from "../../../assets/images/pot-sol-img-dktp.svg";

//local interface declaration
interface IProps {
  imageDesktop?: string;
  imageDesktopWidth?: number;
  imageDesktopHeight?: number;
  imageMobile?: string;
  imageMobileWidth?: number;
  imageMobileHeight?: number;
}

export const PotentielSolaireLegendImage = ({
  imageDesktopHeight = 210,
  imageDesktopWidth = 124,
}: IProps) => {
  return (
    <div className="PotentielSolaireLegendImage">
      <div className="PotentielSolaireLegendImage__Container">
        <div className="PotentielSolaireLegendImage__ImageDesktop">
          <img
            src={potSolImgDktp}
            alt="Légende du potentiel solaire"
            width={imageDesktopWidth}
            height={imageDesktopHeight}
          />
        </div>
      </div>
    </div>
  );
};
