import { IEnergyLabel, ILabel } from "../../../interfaces/renoglobaleInterface";

export const energyLabelImageNameFormatter = ({
  energy_label,
  ges_label,
}: Pick<IEnergyLabel, "energy_label" | "ges_label">) => {
  const wortsLabel = [energy_label || "", ges_label || ""].sort()[1];

  const labelDPEImageName = `label/DPE ${wortsLabel}`;
  const barDPEImageName = `dpeBar/DPE ${energy_label}`;
  const GESImageName = `gesBar/GES ${ges_label}`;
  return {
    labelDPEImageName: labelDPEImageName,
    barDPEImageName: barDPEImageName,
    GESImageName: GESImageName,
  };
};

const labelDictionary: ILabel[] = ["A", "B", "C", "D", "E", "F", "G"];

export const getCurrentLabelFromEstimatedLabel = ({
  estimatedLabel,
  gain,
}: {
  estimatedLabel: string;
  gain: number;
}): ILabel => {
  return labelDictionary[
    labelDictionary.findIndex((elem) => elem === estimatedLabel) +
      Math.abs(gain)
  ];
};
