// Libraries import
import React from "react";
import styled from "styled-components";

// Styles import

// Interfaces declaration
export interface TooltipProps {
  content?: string | number;
  backgroundColor?: string | number;
  color?: string;
  position?: string;
  sizeOfParent?: number;
}

const PopUPText = styled.div<TooltipProps>`
  visibility: visible;
  width: 266px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  text-align: center;
  border-radius: 6px;
  padding: 15px 10px;
  align-items: center;
  position: absolute;
  z-index: 100;

  ${(props) =>
    props.position === "bottom"
      ? "top : calc(100% + 9px)"
      : props.position === "top"
      ? "bottom : calc(100% + 9px)"
      : props.position === "left"
      ? "right: calc(100% + 9px);"
      : props.position === "right"
      ? "left: calc(100% + 9px);"
      : null};

  ${(props) =>
    props.position === "bottom"
      ? "left : 0;"
      : props.position === "top"
      ? "left : 0;"
      : props.position === "left"
      ? "top : 0;"
      : props.position === "right"
      ? "top: 0;"
      : null};

  ${(props) =>
    props.position === "bottom"
      ? `transform: translateX(
    calc(-50% + ${props.sizeOfParent && props.sizeOfParent / 2}px)
  );`
      : props.position === "top"
      ? `transform: translateX(
    calc(-50% + ${props.sizeOfParent && props.sizeOfParent / 2}px)
  );`
      : props.position === "left"
      ? `transform: translateY(
    calc(-50% + ${props.sizeOfParent && props.sizeOfParent / 2}px)
  );`
      : props.position === "right"
      ? `transform: translateY(
    calc(-50% + ${props.sizeOfParent && props.sizeOfParent / 2}px)
  );`
      : null};

  p {
    font-size: 13px;
    font-family: "DM Sans", serif;
    font-weight: 400;
    text-align: center;
  }
  &::after {
    content: " ";
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent ${(props) => props.backgroundColor}
      transparent;
    position: absolute;
    ${(props) =>
      props.position === "bottom"
        ? "bottom : calc(100% - 1px);"
        : props.position === "top"
        ? "top : 100%;"
        : props.position === "left"
        ? "top : 50%;"
        : props.position === "right"
        ? "top : 50%;"
        : null};

    ${(props) =>
      props.position === "bottom"
        ? "left : calc(50% + 0.22px);"
        : props.position === "top"
        ? "left : calc(50% + 0.22px);"
        : props.position === "left"
        ? "right : calc(0% - 17.78px);"
        : props.position === "right"
        ? "left : calc(0% - 16.78px);"
        : null};

    ${(props) =>
      props.position === "bottom"
        ? "transform: translate(-50%, 0%)"
        : props.position === "top"
        ? "transform: translate(-50%, 0%) rotate(180deg)"
        : props.position === "left"
        ? "transform: translate(0%, -50%) rotate(90deg)"
        : props.position === "right"
        ? "transform: translate(0%, -50%) rotate(270deg)"
        : null};
  }
`;

export const Tooltip: React.FC<TooltipProps> = ({
  content = "Header 1",
  backgroundColor = "black",
  color = "white",
  position = "left",
  sizeOfParent = 20,
}) => {
  return (
    <PopUPText
      sizeOfParent={sizeOfParent}
      backgroundColor={backgroundColor}
      color={color}
      position={position}
    >
      <p>{content}</p>
    </PopUPText>
  );
};
