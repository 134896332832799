import "./HeatLossesSchema.scss";

// Components import
import { Layout } from "../Layout/Layout";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";

// Services import
import { useAppSelector } from "../../redux/store/hook";

// Interfaces import
import { IHeatLoss } from "../../interfaces/renoglobaleInterface";

// images import
import houseWithGarage from "../../assets/images/heatLosses/maison_garage.svg";
import houseWithoutGarage from "../../assets/images/heatLosses/maison_sans_garage.svg";
import orangeFlag from "../../assets/images/heatLosses/orange flag.svg";
import redFlag from "../../assets/images/heatLosses/red flag.svg";
import greenFlag from "../../assets/images/heatLosses/green flag.svg";
import roofArrow from "../../assets/images/heatLosses/arrow_roof.svg";
import windowsArrow from "../../assets/images/heatLosses/arrow_windows.svg";
import garageArrow from "../../assets/images/heatLosses/arrow_garage.svg";
import plancherBasArrow from "../../assets/images/heatLosses/arrow_plancher_bas.svg";
import wallsArrow from "../../assets/images/heatLosses/arrow_walls.svg";

interface IProps {
  heatLossesData: IHeatLoss[];
  errorMessage: string;
  isLoading: boolean;
}

export const HeatLossesSchema = ({
  heatLossesData,
  errorMessage,
  isLoading,
}: IProps) => {
  const { type_de_garage } = useAppSelector((state) => state).studyInformation;

  const isItemIncluded = (itemName: string): boolean => {
    return heatLossesData.some((item) => item.name === itemName);
  };

  const isGarageIncluded = (): boolean =>
    isItemIncluded("garage") &&
    (!type_de_garage?.some((typeDeGarage) => typeDeGarage === "pasDeGarage") ||
      false);
  const isRoofIncluded = (): boolean => isItemIncluded("roof");
  const areWindowsIncluded = (): boolean => isItemIncluded("windows");
  const isFloorIncluded = (): boolean => isItemIncluded("floor");
  const areWallsIncluded = (): boolean => isItemIncluded("walls");

  const svgFilterGenerator = (name: string) => {
    const heatLossItem = heatLossesData.find((item) => item.name === name);
    const itemValue = heatLossItem ? heatLossItem.value : 0;

    if (itemValue > 66) {
      return "brightness(0) saturate(100%) invert(26%) sepia(61%) saturate(2962%) hue-rotate(347deg) brightness(86%) contrast(106%)";
    }
    if (itemValue > 33) {
      return "brightness(0) saturate(100%) invert(55%) sepia(74%) saturate(2058%) hue-rotate(6deg) brightness(99%) contrast(101%)";
    }
    return "brightness(0) saturate(100%) invert(37%) sepia(50%) saturate(3898%) hue-rotate(134deg) brightness(97%) contrast(96%)";
  };

  const flagGenerator = (name: string) => {
    const heatLossItem = heatLossesData.find((item) => item.name === name);
    const itemValue = heatLossItem ? heatLossItem.value : 0;

    if (itemValue > 66) {
      return redFlag;
    }
    if (itemValue > 33) {
      return orangeFlag;
    }
    return greenFlag;
  };

  return (
    <div className="HeatLossesSchema">
      <Layout>
        <div className="HeatLossesSchema__Container">
          {isLoading ? (
            <div className="HeatLossesSchema__loader">
              <LoadingComponent diameter={60} />
            </div>
          ) : errorMessage ? (
            <ErrorMessage errorMessage={errorMessage} />
          ) : isGarageIncluded() ||
            isRoofIncluded() ||
            areWallsIncluded() ||
            isFloorIncluded() ||
            areWindowsIncluded() ? (
            <>
              <section className="HeatLossesSchema__house_details">
                <div className="image_container">
                  <img
                    src={`${
                      isGarageIncluded() ? houseWithGarage : houseWithoutGarage
                    }`}
                    alt=""
                  />
                  {isRoofIncluded() && (
                    <div className="arrowRoof">
                      <img
                        src={flagGenerator("roof")}
                        alt=""
                        className="flag"
                      />
                      <div className="text_arrow">
                        <p>Déperditions par les combles</p>
                        <img
                          src={roofArrow}
                          alt=""
                          style={{ filter: svgFilterGenerator("roof") }}
                        />
                      </div>
                    </div>
                  )}
                  {areWindowsIncluded() && (
                    <div className="arrowWindows">
                      <img
                        src={flagGenerator("windows")}
                        alt=""
                        className="flag"
                      />
                      <div className="text_arrow">
                        <p>Déperditions par les fenêtres</p>
                        <img
                          src={windowsArrow}
                          alt=""
                          style={{ filter: svgFilterGenerator("windows") }}
                        />
                      </div>
                    </div>
                  )}
                  {isGarageIncluded() && (
                    <div className="arrowGarage">
                      <img
                        src={flagGenerator("garage")}
                        alt=""
                        className="flag"
                      />
                      <div className="text_arrow">
                        <p>Déperditions par le garage</p>
                        <img
                          src={garageArrow}
                          alt=""
                          style={{ filter: svgFilterGenerator("garage") }}
                        />
                      </div>
                    </div>
                  )}
                  {isFloorIncluded() && (
                    <div className="arrowFloor">
                      <img
                        src={flagGenerator("floor")}
                        alt=""
                        className="flag"
                      />
                      <div className="text_arrow">
                        <p>Déperditions par le plancher bas</p>
                        <img
                          src={plancherBasArrow}
                          alt=""
                          style={{ filter: svgFilterGenerator("floor") }}
                        />
                      </div>
                    </div>
                  )}
                  {areWallsIncluded() && (
                    <div className="arrowWalls">
                      <img
                        src={flagGenerator("walls")}
                        alt=""
                        className="flag"
                      />
                      <div className="text_arrow">
                        <p>Déperditions par les murs</p>
                        <img
                          src={wallsArrow}
                          alt=""
                          style={{ filter: svgFilterGenerator("walls") }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </section>
              <section className="HeatLossesSchema__legend">
                <div className="singleLegend">
                  <img src={greenFlag} alt="" />
                  <p>Déperditions faibles</p>
                </div>
                <div className="singleLegend">
                  <img src={orangeFlag} alt="" />
                  <p>Déperditions moyennes</p>
                </div>
                <div className="singleLegend">
                  <img src={redFlag} alt="" />
                  <p>Déperditions élevées</p>
                </div>
              </section>
            </>
          ) : (
            // Si il n'y a pas de message d'erreur mais que les données sont vides ou mal retrounées
            <ErrorMessage errorMessage="Pas de données disponibles" />
          )}
        </div>
      </Layout>
    </div>
  );
};
