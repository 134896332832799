// Libraries import
import { useState } from "react";
import SVG from "react-inlinesvg";

// Style import
import "./PacAirAirInteriorUnit.scss";

// Components import
import Trash2 from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { DropdownV2 } from "@web/shared/dist/components/DesignSystem/Dropdowns/DropdownV2/DropdownV2";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { H2 } from "@web/shared/dist/components/DesignSystem/Typographie/H2/H2";
import { ISageProduct } from "../../interfaces/generalInterfaces";
import {
  IDataProduct,
  InteriorUnitsPacAirAir,
} from "../../interfaces/renoglobaleInterface";
import { formatSageProductsForDropdown } from "../../services/RenovationGlobale/formatProductSelectedForDropdown";
import { PacAirAirInputContainer } from "../PacAirAirInputContainer/PacAirAirInputContainer";

// Interfaces import

// Local interfaces declaration
interface Props {
  roomIndex: number;
  interiorUnit: InteriorUnitsPacAirAir;
  interiorUnitIndex: number;
  onChangePower: (props: {
    roomIndex: number;
    interiorUnitIndex: number;
    interior_unit_sage_reference?: string | null;
  }) => void;
  onChangeRoomSurface: ({
    roomIndex,
    interiorUnitIndex,
    surface_to_heat,
  }: {
    roomIndex: number;
    interiorUnitIndex: number;
    surface_to_heat: number;
  }) => void;
  listOfInteriorUnitsAvailables: ISageProduct[];
  onDeleteInteriorUnit: ({
    interior_unit_id,
  }: {
    interior_unit_id: string;
  }) => void;
  products_list: IDataProduct[];
}

export const PacAirAirInteriorUnit = ({
  roomIndex,
  interiorUnit,
  interiorUnitIndex,
  onChangePower,
  onChangeRoomSurface,
  listOfInteriorUnitsAvailables,
  onDeleteInteriorUnit,
  products_list,
}: Props) => {
  const [isDeleteModaleOpen, setIsDeleteModaleOpen] = useState<boolean>(false);

  const sageProductFormatedForDropdown = formatSageProductsForDropdown({
    sageProducts: listOfInteriorUnitsAvailables,
    dataProducts: products_list,
  });

  return (
    <>
      <Modale
        isModaleOpen={isDeleteModaleOpen}
        title={`Êtes-vous sûr de vouloir supprimer l'unité intérieure ${
          interiorUnitIndex + 1
        } ?`}
        maxWidth="550px"
      >
        <div className="PacAirAirInteriorUnit__Modale">
          <CTAButton
            name="Non"
            family="vitee"
            category="secondary"
            onClick={() => setIsDeleteModaleOpen(false)}
          />
          <CTAButton
            name="Oui"
            family="vitee"
            onClick={() => {
              setIsDeleteModaleOpen(false);
              onDeleteInteriorUnit({
                interior_unit_id: interiorUnit.interior_unit_id,
              });
            }}
          />
        </div>
      </Modale>
      <div className="PacAirAirInteriorUnit__WindowContainer">
        <div className="PacAirAirInteriorUnit__SubTitle">
          <div className="PacAirAirInteriorUnit__DuplicateContainer">
            <H2 title={`Unité intérieure ${interiorUnitIndex + 1}`} />
          </div>
          <div
            className="PacAirAirInteriorUnit__Trash"
            onClick={() => setIsDeleteModaleOpen((state) => !state)}
          >
            <SVG src={Trash2} />
          </div>
        </div>
        <div className="PacAirAirInteriorUnit__Question">
          <PacAirAirInputContainer
            title="Surface chauffée :"
            iconType="squaredMeters"
            placeholder="XXXX"
            type="number"
            value={interiorUnit.surface_to_heat?.toString()}
            onChange={(value) => {
              onChangeRoomSurface({
                roomIndex,
                interiorUnitIndex,
                surface_to_heat: Number(value),
              });
            }}
          />
        </div>
        <div className="PacAirAirInteriorUnit__Question">
          <p className="PacAirAirInteriorUnit__QuestionTitle">Puissance :</p>
          <div className="PacAirAirInteriorUnit__Dropdown">
            <DropdownV2
              options={sageProductFormatedForDropdown}
              values={[interiorUnit.interior_unit_sage_reference || ""]}
              onSelect={(option) => {
                onChangePower({
                  roomIndex,
                  interiorUnitIndex,
                  interior_unit_sage_reference: option.value as string,
                });
              }}
              multipleChoices={false}
              placeholder="Choisir"
              maxHeight="32vh"
              searchable={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
