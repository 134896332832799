// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";

// Local interfaces delcaration
export interface IPostConsumptionsPayload {
  // See Data Swagger : https://admin.tucoenergie.fr/swagger
  household_id: string;
  building_id: string;
  tarification_option: string;
  subscribed_power: number | null;
  annual_electric_consumption_kWh: number;
  annual_electric_consumption_euros: number;
  electricity_price_per_kwh: number;
  number_of_occupants: number;
  house_area_m2: number;
  electric_installation_type: string;
  owned_equipments: {
    climatisation: boolean;
    pool: boolean;
    charging_point: boolean;
  };
  electric_heater: boolean;
  electric_water_heater: boolean;
  construction_year?: number;
  source_energie: string[];
  electricite_principaux_equipments: string[];
  gaz_principaux_equipments: string[];
  gpl_principaux_equipments: string[];
  fioul_principaux_equipments: string[];
  electricite_chauffage_euros: number | null;
  gaz_chauffage_euros: number | null;
  bois_consommation_euros: number | null;
  gpl_chauffage_euros: number | null;
  fioul_chauffage_euros: number | null;
  electricite_ecs_euros: number | null;
  gaz_ecs_euros: number | null;
  gpl_ecs_euros: number | null;
  fioul_ecs_euros: number | null;
}

interface Props {
  postConsumptionsPayload: IPostConsumptionsPayload;
  userToken: string | null;
  study_id?: string | null;
}

// Send consumptions data filled in the form to backend
export const usePostConsumptionsForm = () => {
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const postConsumptionFrom = async ({
    postConsumptionsPayload,
    userToken,
    study_id,
  }: Props): Promise<{ message: string } | void> => {
    setErrorMessage("");
    if (postConsumptionsPayload) {
      const response = await axiosPostRequest<{ message: string }>(
        `${process.env.REACT_APP_EERO_URL}/oree2/pv/postConsumptions/${study_id}`,
        { postConsumptionsPayload },
        { headers: { Authorization: `Bearer ${userToken}` } }
      );
      return response && response.data;
    } else {
      setErrorMessage("Missing payload");
    }
  };

  return {
    postConsumptionFrom,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
