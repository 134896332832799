import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";

Font.registerHyphenationCallback((word: any) => [word]);

// Create styles
const styles = StyleSheet.create({
  itemAbscisseBackground: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
    backgroundColor: "#f4f4f5",
    padding: 10,
    marginLeft: "-2%",
    marginRight: "-2%",
    borderRadius: 10,
    width: "104%",
  },
  descriptionProduits: {
    minWidth: "36%",
  },

  quantite: {
    width: "13%",
    textAlign: "center",
  },
  prixUnitaireHT: {
    width: "21%",
    textAlign: "center",
  },
  tva: {
    width: "11%",
    textAlign: "center",
  },
  montantTTC: {
    width: "19%",
    textAlign: "right",
  },
  montantTTCAide: {
    width: "19%",
    textAlign: "right",
    color: "#08B77F",
  },

  itemDescriptionProduits: {
    fontFamily: "DMSansBold",
    whiteSpace: "nowrap",
  },
  itemDescriptionProduitsAide: {
    fontFamily: "DMSansRegular",
    color: "#08B77F",
    paddingLeft: 10,
  },
  itemDetailDescriptionProduits: {
    marginLeft: 10,
    marginTop: 2,
  },
  itemQuantite: {
    fontFamily: "DMSansBold",
  },
  itemPrixUnitaireHT: {},
  itemTva: {
    fontFamily: "DMSansBold",
  },
  itemMontantTTC: {
    fontFamily: "DMSansBold",
  },
});

interface IProps {
  name: string;
  quantity?: number | null;
  prixUnitaire?: number | 0 | null | undefined;
  TVA?: number | null | undefined;
  montantTTC?: number | null | 0 | undefined;
  textMontantTTC?: JSX.Element[];
  aides?: {
    aide: string;
    montant: number | 0 | undefined | null;
    isDeducted?: boolean;
  }[];
}

export const PdfRowBackgroundProduit = ({
  name,
  quantity,
  prixUnitaire,
  TVA,
  montantTTC,
  textMontantTTC,
  aides,
}: IProps) => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  function formatNumberWithSpacesAndDecimal(
    number: number,
    decimalPlaces: number
  ) {
    const parts = Number(number).toFixed(decimalPlaces).split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const decimalPart = parts[1];
    return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
  }
  return (
    <View style={styles.itemAbscisseBackground}>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View style={styles.descriptionProduits}>
          <Text style={styles.itemDescriptionProduits}>{name}</Text>
        </View>
        <Text style={[styles.itemQuantite, styles.quantite]}>{quantity}</Text>
        <Text style={[styles.itemPrixUnitaireHT, styles.prixUnitaireHT]}>
          {typeof prixUnitaire === "number" &&
            formatNumberWithSpacesAndDecimal(prixUnitaire, 2) + " €"}
        </Text>
        <Text style={[styles.itemTva, styles.tva]}>{TVA && TVA + " %"}</Text>
        {montantTTC && (
          <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
            {formatNumberWithSpacesAndDecimal(montantTTC, 2) + " €"}
          </Text>
        )}
        {textMontantTTC && (
          <View
            style={[
              styles.itemMontantTTC,
              styles.montantTTC,
              {
                fontSize: 10,
                alignSelf: "center",
              },
            ]}
          >
            {textMontantTTC.map((item, _key) => {
              return <View key={s4()}>{item}</View>;
            })}
          </View>
        )}
      </View>
      {aides?.map((item, _key) => {
        return (
          <View
            key={s4()}
            style={{ display: "flex", flexDirection: "row", margin: "5 0 0 0" }}
          >
            <View style={styles.descriptionProduits}>
              <Text style={styles.itemDescriptionProduitsAide}>
                {item.aide}
              </Text>
            </View>
            <Text style={[styles.itemQuantite, styles.quantite]}></Text>
            <Text
              style={[styles.itemPrixUnitaireHT, styles.prixUnitaireHT]}
            ></Text>
            <Text style={[styles.itemTva, styles.tva]}></Text>
            <Text style={[styles.itemMontantTTC, styles.montantTTCAide]}>
              {item.isDeducted ? "- " : ""}
              {formatNumberWithSpacesAndDecimal(Number(item.montant), 2) + " €"}
            </Text>
          </View>
        );
      })}
    </View>
  );
};
