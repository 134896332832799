//Style import
import "./DetailOptionTabsRG.scss";

//component import
import { SingleTabRG } from "../SingleTabRG/SingleTabRG";
import { Layout } from "../../Layout/Layout";

//img import
import logo_impactEnvironnement from "@web/shared/src/assets/images/DesignSystemIcons/globe-2.svg";
import logo_performence from "@web/shared/src/assets/images/DesignSystemIcons/timer.svg";
import logo_rentabilite from "@web/shared/src/assets/images/DesignSystemIcons/pie-chart.svg";
import logo_euro from "@web/shared/src/assets/images/DesignSystemIcons/euro.svg";

//local interface
interface IProps {
  isTabOpen: boolean[];
  onTabClick: (index: number) => void;
}

export const DetailOptionTabsRG = ({ isTabOpen, onTabClick }: IProps) => {
  return (
    <Layout>
      <div className="DetailOptionTabsRG">
        <>
          <SingleTabRG
            title="Performance"
            imgSrc={logo_performence}
            onClick={() => onTabClick(0)}
            isOpen={isTabOpen[0]}
          />
          <SingleTabRG
            title="Rentabilité"
            imgSrc={logo_rentabilite}
            onClick={() => onTabClick(1)}
            isOpen={isTabOpen[1]}
          />
          <SingleTabRG
            title="Impact environnemental"
            imgSrc={logo_impactEnvironnement}
            onClick={() => onTabClick(2)}
            isOpen={isTabOpen[2]}
          />
          <SingleTabRG
            title="Financement"
            imgSrc={logo_euro}
            onClick={() => onTabClick(3)}
            isOpen={isTabOpen[3]}
          />
        </>
      </div>
    </Layout>
  );
};
