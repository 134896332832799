import { Page, Text, View } from "@react-pdf/renderer";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfBlockSpace } from "../../components/Pdf/Pdf-Devis/PdfBlockSpace/PdfBlockSpace";
import { PdfComplexeParagraphMentions } from "../../components/Pdf/Pdf-Devis/PdfComplexeParagraphMentions/PdfComplexeParagraphMentions";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfList } from "../../components/Pdf/Pdf-Devis/PdfList/PdfList";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfParagraphMentions } from "../../components/Pdf/Pdf-Devis/PdfParagraphMentions/PdfParagraphMentions";
import { PdfSousTitreMentions } from "../../components/Pdf/Pdf-Devis/PdfSousTitreMentions/PdfSousTitreMentions";
import { PdfTitleEncadre } from "../../components/Pdf/Pdf-Devis/PdfTitleEncadre/PdfTitleEncadre";
import { PdfTitreMentions } from "../../components/Pdf/Pdf-Devis/PdfTitreMentions/PdfTitreMentions";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

//Interface import
import { ICommercialUserInformation } from "../../interfaces/generalInterfaces";

export const PdfInformationContractuelle = ({
  commercialUserInformation,
  isDemoVersion,
}: {
  commercialUserInformation: ICommercialUserInformation;
  isDemoVersion: boolean;
}) => {
  const companyFullAddress =
    commercialUserInformation.company_address +
    " " +
    commercialUserInformation.company_postal_code +
    " " +
    commercialUserInformation.company_city;

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>

          <PdfTitleEncadre title="informations précontractuelles" />
          <PdfTitreMentions title="1. IDENTITÉ ET COORDONNÉES DU PRESTATAIRE" />
          {isDemoVersion ? (
            <PdfParagraphMentions
              paragraph={[
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse congue consectetur viverra. Praesent euismod nulla
                  enim, et interdum augue pellentesque sit amet. Pellentesque ut
                  eros ex. Donec iaculis iaculis congue. In euismod ac sapien
                  sed ultricies. Morbi sed consequat nisl. Proin sem orci,
                  suscipit non lobortis sit amet, luctus quis erat. Fusce
                  molestie, diam eget ullamcorper hendrerit, neque tellus
                  pulvinar orci, sit amet eleifend nisi dui eu tellus. Sed erat
                  mauris, vestibulum ac eros vel, varius sodales elit.{" "}
                </Text>,
              ]}
            />
          ) : (
            <>
              <PdfParagraphMentions
                paragraph={[
                  <Text>
                    La Société{" "}
                    {commercialUserInformation.company_raison_sociale?.toLocaleUpperCase()}
                    , SAS agissant sous l’enseigne{" "}
                    {commercialUserInformation.userCompany?.toLocaleUpperCase()}
                    , au capital de 1 000 000€, dont le siège social est situé 2
                    rue Mozart 92110 CLICHY, est immatriculée au Registre du
                    Commerce et des Sociétés de Nanterre sous le numéro 514 315
                    522 et identifiée par le numéro de TVA intracommunautaire
                    FR73514315522. Elle peut être contactée par téléphone au 01
                    83 62 84 81 ou par courriel à l’adresse électronique «
                    serviceclient@tucoenergie.fr ».
                  </Text>,
                ]}
              />
              <PdfTitreMentions title="2. INFORMATIQUES JURIDIQUES IMPORTANTES" />
              <PdfSousTitreMentions title="Objet de la prestation :" />
              <PdfParagraphMentions
                paragraph={[
                  <Text>
                    Conformément à l’article L.221-5 du Code de la Consommation,{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    informe le client que le service proposé porte, selon son
                    souhait, sur l’installation à son domicile de panneaux
                    photovoltaïques, et/ou d’une pompe à chaleur, et/ou d’un
                    ballon thermodynamique, et éventuellement d’autres services,
                    dont la nomenclature, les caractéristiques essentielles, les
                    prix et les délais de livraison sont tous détaillés dans le
                    devis remis avant la conclusion du contrat.
                  </Text>,
                ]}
              />
              <PdfSousTitreMentions title="Prix :" />
              <PdfParagraphMentions
                paragraph={[
                  <Text>
                    Le matériel et les fournitures installés peuvent être
                    composés de plusieurs éléments qui doivent être appréhendés
                    comme formant un tout indivisible : un pack. Le prix indiqué
                    correspond au prix de vente du pack pris comme un tout
                    indissociable, ainsi qu’à la pose dudit pack. La TVA de
                    chaque élément composant le PAC et de sa pose est inclue au
                    prix TTC. Le prix de chacun des éléments composant le pack
                    peut être fourni au client, à sa demande. Ce prix couvre
                    également les démarches administratives confiées à{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                    .
                  </Text>,
                ]}
              />
              <PdfSousTitreMentions title="Accord du consommateur sur la remise des informations précontractuelles sur support électronique :" />
              <PdfParagraphMentions
                paragraph={[
                  <Text>
                    Conformément à l’article L.221-8 du Code de la Consommation,
                    les informations précontractuelles peuvent être fournies par
                    le professionnel sur support électronique, sous réserve de
                    l’accord du client. Le cas échéant, le client reconnaît
                    avoir accepté que{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    lui fournisse le devis et les informations précontractuelles
                    sur support électronique.
                  </Text>,
                ]}
              />
              <PdfSousTitreMentions title="Délais d’exécution de la prestation de service et modalités d’exécution du contrat :" />
              <PdfParagraphMentions
                paragraph={[
                  <Text>
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    s'engage à assurer la réalisation des travaux de pose des
                    matériels, au plus tard, dans un délai de quatre mois à
                    compter de la signature du bon de commande, sous réserve de
                    l’obtention des accords administratifs nécessaires
                    susceptibles le cas échéant de rallonger ce délai et sous
                    réserve de la coopération du Client.{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    procédera à l’installation du matériel soit directement,
                    soit par l’intermédiaire d’un installateur qualifié. En cas
                    d’indisponibilité d’un matériel ou pour répondre à des
                    problèmes techniques,{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    se réserve le droit de fournir au Client un matériel pouvant
                    être de marque différente, mais de qualité équivalente et
                    présentant des caractéristiques et performances équivalentes
                    ou supérieures au matériel décrit dans le bon de commande.
                    Le Client accepte que{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    puisse modifier la puissance et les modèles d’onduleurs
                    installés, pour tenir compte des contraintes techniques de
                    l’installation (région, puissance de la centrale,
                    calepinage, configuration de la pièce).{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    ajuste alors le nombre d’onduleurs et la puissance
                    d’injection en respectant strictement les préconisations
                    fabricant. Les démarches d'assistance administratives et
                    financières sont exécutées dans le cadre de contrats de
                    mandat. Leur délai d'exécution débute à la signature des
                    contrats de mandat et se termine au parfaitement achèvement
                    des travaux ; notamment lorsque les équipements sont mis en
                    service ; qu'ils ont été audités par un organisme de
                    contrôle si nécessaire ; lorsque{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    a collecté les éventuelles aides financières liées au projet
                    pour lesquelles elle aurait été mandatée et aurait la
                    capacité à se substituer au client pour le faire. Le délai
                    moyen d'un projet étant de six mois, nous estimons le délai
                    d'exécution des prestations d'assistance administratives et
                    financières à six mois. Ce délai est susceptible
                    d'évolution compte-tenu de l'intervention d'administrations
                    tierces dont{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    ne pourrait être tenue responsable des délais de
                    traitements.
                  </Text>,
                ]}
              />
              <PdfSousTitreMentions title="Modalités de paiement :" />
              <PdfComplexeParagraphMentions
                title="• Avec financement proposé par l’intermédiaire de TUCOENERGIE :"
                paragraph={[
                  <Text>
                    Dans le cas où le Client aurait recours à un financement, il
                    autorise la société à conclure avec l’établissement
                    financier une délégation de paiement de manière à ce que
                    l’établissement financier règle directement à{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    le prix indiqué au bon de commande. L’établissement
                    financier adresse les fonds directement à{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    dès réception d’un document signé par le Client qui atteste
                    de l’achèvement complet et sans réserve des prestations de
                    livraison et d’installation des matériels, et qui autorise
                    le déblocage des fonds.
                  </Text>,
                ]}
              />
              <PdfComplexeParagraphMentions
                title="• Sans financement :"
                paragraph={[
                  <Text>
                    Le Client a le choix de payer par chèque ou par virement
                    bancaire comportant les références précises du bon de
                    commande. Le Client règlera l’intégralité du prix lorsque{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    lui aura livré les matériels et aura procédé à leurs travaux
                    d’installation. En cas de règlement par chèque bancaire,
                    celui-ci doit être émis par une banque domiciliée en France
                    métropolitaine ou à Monaco. La mise à l’encaissement du
                    chèque est réalisée dès sa réception. Un chèque de banque
                    pourra être exigé par{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                  </Text>,
                ]}
              />
              <PdfSousTitreMentions title="Modalités de paiement :" />
              <PdfParagraphMentions
                paragraph={[
                  <Text>
                    Au-delà du prix fixé au bon de commande, des frais seront à
                    la charge du Client et feront l’objet d’une facture
                    complémentaire, notamment s’agissant d’éventuels frais de
                    terrassement, de dépose ou de pose de matériaux (terrasse,
                    carrelage, etc.), de peinture, papier peint, etc. Ce prix ne
                    comprend pas le prix des éventuelles fournitures et
                    prestations supplémentaires qui ne seraient pas prévues dans
                    le bon de commande, notamment les prestations relatives à la
                    mise en conformité de la toiture nécessaire pour réaliser
                    l’installation. Ce prix ne comprend pas notamment les
                    travaux permettant d’installer les panneaux sans ombrages
                    liés notamment à la végétation, arbres, antennes, paraboles
                    ou conduits de cheminée qui demeurent à la charge du Client.
                    Le Client procédera à ses frais avant l’installation à tous
                    travaux d’élagage ou de dépose des éléments gênants,
                    notamment pour le raccordement, le cas échéant.
                  </Text>,
                ]}
              />
              <PdfComplexeParagraphMentions
                title="• Informations liées à la pompe à chaleur Air/Air ou Air/Eau :"
                paragraph={[
                  <Text>
                    L’installation de cet équipement est susceptible de
                    nécessiter une augmentation de la puissance du compteur
                    électrique. Dans ce cas, il appartiendra au Client de se
                    rapprocher de son fournisseur d’électricité afin que le
                    nécessaire soit fait.{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    ne saurait être tenue responsable d’un manque de puissance
                    nécessaire au bon fonctionnement des équipements.
                  </Text>,
                ]}
              />
              <PdfComplexeParagraphMentions
                title="• Informations liées à la centrale solaire photovoltaïque :"
                paragraph={[
                  <Text>
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    informe le Client que la productivité de l’installation
                    varie en fonction de son lieu d’implantation, de son
                    environnement et des aléas météorologiques. 
                  </Text>,
                ]}
              />
              <PdfSousTitreMentions title="Garanties légales et conventionnelles :" />
              <PdfComplexeParagraphMentions
                title="• Garantie légale de conformité et des vices cachés :"
                paragraph={[
                  <Text>
                    Même si{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    n’est pas le fabricant des matériels vendus, le Client
                    bénéficie de la garantie légale de conformité de l’article
                    L.217-4 du Code de la Consommation. A ce titre, le vendeur
                    est tenu des défauts de conformité des biens au contrat dans
                    les conditions de l’article L.217-4 et suivants du Code de
                    la Consommation et des défauts cachés de la chose vendue
                    dans les conditions prévues par l’article 1641 et suivants
                    du Code Civil.
                  </Text>,
                  <PdfList
                    title="Lorsqu’il agit en garantie légale de conformité, le consommateur :"
                    paragraph={[
                      <>
                        • bénéficie d’un délai de deux ans à compter de la
                        délivrance du bien pour agir ;
                      </>,
                      <>
                        • peut choisir entre la réparation ou le remplacement du
                        bien, sous réserve des conditions de coût prévues par
                        l’article L. 217-9 du Code de la consommation ;
                      </>,
                      <>
                        • est dispensé de rapporter la preuve de l’existence du
                        défaut de conformité du bien durant les six mois suivant
                        la délivrance du bien. Ce délai est porté à vingt-quatre
                        mois à compter du 18 mars 2016, sauf pour les biens
                        d’occasion
                      </>,
                    ]}
                  />,
                ]}
              />
              <PdfParagraphMentions
                paragraph={[
                  <Text>
                    La garantie légale de conformité s’applique indépendamment
                    de la garantie commerciale éventuellement consentie. Le
                    consommateur peut décider de mettre en œuvre la garantie
                    contre les défauts cachés de la chose vendue au sens de
                    l’article 1641 du code civil et dans cette hypothèse, il
                    peut choisir entre la résolution de la vente ou une
                    réduction du prix de vente conformément à l’article 1644 du
                    code civil.
                  </Text>,
                ]}
              />
              <PdfComplexeParagraphMentions
                title="• Garantie du fabricant :"
                paragraph={[
                  <Text>
                    Le matériel est également couvert par la garantie délivrée
                    par son fabricant. Le détail des garanties de chaque
                    matériel est précisé dans le devis.{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    ne saurait être tenue responsable d’un refus de garantie
                    opposé par le fabricant au client. La garantie du fabricant
                    est notamment exclue en cas d’usure normale, de défaut
                    d’entretien ou en cas d’utilisation non conforme aux
                    prescriptions et recommandations du fabricant. La mise en
                    œuvre de cette garantie s’effectuera par l’intermédiaire de{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    dans les conditions décrites ci-dessus. Les pièces détachées
                    indispensables à l’utilisation des équipements seront
                    disponibles sur le marché pendant une durée de deux ans à
                    compter de la signature du bon de commande.
                  </Text>,
                ]}
              />
              <PdfComplexeParagraphMentions
                title="• Garantie des travaux d’installation :"
                paragraph={[
                  <Text>
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    a souscrit une police d’assurance au titre de sa
                    responsabilité civile professionnelle pour les travaux
                    d’installation en France et DOM. Cette assurance couvre la
                    conception et les travaux d’installation réalisés par{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    contre tout défaut de conformité pendant deux ans à compter
                    de l’établissement du procès-verbal de réception de
                    l’installation. La garantie est toutefois expressément
                    limitée à la réparation, ou au choix de{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                    , au remplacement à neuf, pièces de rechange y compris main
                    d’œuvre et déplacement. La garantie est exclue en cas
                    d’usure normale, de défaut d’entretien, ou en cas
                    d’utilisation non conforme aux recommandations de{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    et aux prescriptions des fabricants contenues dans les
                    brochures remises au Client lors de l’installation des
                    équipements.{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    met à disposition du Client les détails de cette police
                    d’assurance et les coordonnées de son assureur sur son site
                    internet et sur simple requête adressée par courriel au
                    Service Client.
                  </Text>,
                ]}
              />
              <PdfComplexeParagraphMentions
                title="• Garantie décennale :"
                paragraph={[
                  <Text>
                    Conformément à la loi n°78-12 du 4 janvier 1978 concernant
                    l’assurance des travaux de bâtiment,{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    a souscrit pour ses installations d’économies ou de
                    production d’énergie en France métropolitaine et DOM, une
                    police d’assurance au titre de ses responsabilités
                    susceptibles d’être engagées sur le fondement des articles
                    1792 et suivants du Code Civil.
                  </Text>,
                  <Text>Mise en oeuvre des garanties :</Text>,
                  <Text>
                    En cas de sinistre survenu pendant la période de garantie,
                    le client en informe{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    dans les plus brefs délais, par écrit à l’adresse postale «
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    – Service Client – {companyFullAddress} », ou à l’adresse
                    électronique « 
                    {commercialUserInformation.company_service_client_email}
                     », en joignant tout justificatif utile.
                  </Text>,
                ]}
              />
              <PdfComplexeParagraphMentions
                title="• Exclusions de garanties :"
                paragraph={[
                  <PdfList
                    title="La garantie est exclue et la responsabilité de TUCOENERGIE ne peut être engagée dans les cas suivants "
                    paragraph={[
                      <>
                        • Non-paiement partiel ou total du montant de la
                        commande;
                      </>,
                      <>
                        • Détérioration des appareils provenant directement ou
                        indirectement d’accidents de toutes sortes, chocs,
                        surtensions, foudre, inondations, incendies et d’une
                        manière générale, toutes causes autres que celles
                        résultant d’une utilisation normale ;
                      </>,
                      <>
                        • Mauvais fonctionnement résultant d’adjonction de
                        pièces ou de dispositifs ne provenant pas du
                        distributeur fabricant, ou non-agréés par lui ;
                      </>,
                      <>
                        • Intervention de quelque nature que ce soit par une
                        personne non agréée par le fabricant et sans avoir
                        recueilli l’autorisation préalable de{" "}
                        {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                      </>,
                      <>
                        • Variation du courant électrique, dérangement, panne ou
                        rupture des lignes téléphoniques ;
                      </>,
                      <>
                        • Dans le cadre de l’installation d’une pompe à chaleur
                        reliée au réseau hydraulique de l’habitation,
                        dysfonctionnements et usure du réseau hydraulique déjà
                        existant;
                      </>,
                      <>
                        • Modifications dommageables de l’environnement de
                        l’appareil (température, hygrométrie, poussière, etc.) ;
                      </>,
                      <>
                        • Modifications des spécifications d’un appareil
                        utilisation non conforme à ses caractéristiques
                        techniques ;
                      </>,
                      <>
                        • Interférences et brouillages de toutes sortes,
                        radioélectriques ou électriques ;
                      </>,
                      <>
                        • Sous puissance du matériel que le client a choisi et
                        dont il assume seul la détermination ;
                      </>,
                      <>
                        • De manière générale, les perturbations de
                        fonctionnement dues à des causes relevant de la force
                        majeure ;
                      </>,
                      <>
                        • Non-respect des consignes d’utilisation des matériels
                        telles que contenues dans les notices remises au client
                        lors de l’installation des matériels.
                      </>,
                      <>
                        • Utilisation des appareils dans les conditions non
                        conformes à leur usage ;
                      </>,
                      <>• Défaut d’entretien et de maintenance.</>,
                    ]}
                  />,
                ]}
              />
              <PdfBlockSpace size={10} />
              <PdfSousTitreMentions title="Règlement des litiges – Réclamations – Droit applicable :" />
              <PdfParagraphMentions
                paragraph={[
                  <View>
                    <Text>
                      Les opérations de vente susmentionnées sont soumises au
                      droit français. Tous les litiges auxquels les opérations
                      de vente conclues en application des conditions générales
                      de vente applicables pourraient donner lieu, tant dans
                      leur validité, leur interprétation, leur exécution, leur
                      résiliation, leurs conséquences et leurs suites et qui
                      n’auraient pu être résolues entre{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      et le Client seront soumis aux tribunaux compétents dans
                      les conditions de droit commun. Toutefois, les parties
                      s’efforceront de trouver une solution amiable en cas de
                      survenance d’un litige. En cas de contestation, le client
                      a la possibilité d’adresser une réclamation, par écrit à
                      l’adresse postale suivante : «
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                      , Service Client, {companyFullAddress}» ou à l’adresse
                      électronique suivante :{" "}
                      {commercialUserInformation.company_service_client_email}.{" "}
                      {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                      y répondra dans les meilleurs délais. Le Client a par
                      ailleurs la possibilité de recourir gratuitement à un
                      médiateur de la consommation, dans les conditions prévues
                      aux articles L.611-1 et suivants du Code de la
                      Consommation ou auprès des instances de médiation
                      sectorielles existantes. A ce titre, le consommateur a la
                      possibilité de saisir gratuitement le médiateur de la
                      consommation dont relève le professionnel, à savoir
                      l’Association des Médiateurs Européens (AME CONSO), dans
                      un délai d’un an à compter de la réclamation écrite
                      adressée au professionnel. La saisine du médiateur de la
                      consommation devra s’effectuer soit en complétant le
                      formulaire prévu à cet effet sur le site internet de l’AME
                      CONSO : www.mediationconso-ame.com; soit par courrier
                      adressé à l’AME CONSO, 197 Boulevard Saint-Germain - 75007
                      PARIS.
                    </Text>
                  </View>,
                ]}
              />
              <PdfSousTitreMentions title="Rétractation – Renonciation :" />
              <PdfParagraphMentions
                paragraph={[
                  <Text>
                    Selon l’article 221-18 du Code de la Consommation, le
                    consommateur dispose d'un délai de quatorze jours pour
                    exercer son droit de rétractation d'un contrat conclu à
                    distance, à la suite d'un démarchage téléphonique ou hors
                    établissement, sans avoir à motiver sa décision ni à
                    supporter d'autres coûts que ceux prévus aux articles L.
                    221-23 à L. 221-25. Le délai mentionné au premier alinéa
                    court à compter du jour : 1° De la conclusion du contrat,
                    pour les contrats de prestation de services et ceux
                    mentionnés à l'article L. 221-4 ; 2° De la réception du bien
                    par le consommateur ou un tiers, autre que le transporteur,
                    désigné par lui, pour les contrats de vente de biens. Pour
                    les contrats conclus hors établissement, le consommateur
                    peut exercer son droit de rétractation à compter de la
                    conclusion du contrat. Dans le cas d'une commande portant
                    sur plusieurs biens livrés séparément ou dans le cas d'une
                    commande d'un bien composé de lots ou de pièces multiples
                    dont la livraison est échelonnée sur une période définie, le
                    délai court à compter de la réception du dernier bien ou lot
                    ou de la dernière pièce. Pour les contrats prévoyant la
                    livraison régulière de biens pendant une période définie, le
                    délai court à compter de la réception du premier bien. Le
                    Client exerce son droit de rétractation en notifiant à{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}
                    , {companyFullAddress}{" "}
                    {commercialUserInformation.company_service_client_phone},{" "}
                    {commercialUserInformation.company_service_client_email}, sa
                    décision de rétractation, avant l’expiration du délai prévu
                    à l’article L.221-18 du Code de la Consommation, au moyen du
                    formulaire de rétractation ci-joint ou de toute autre
                    déclaration dénuée d’ambiguïté par courrier postal avec
                    accusé de réception, ou par retour de courriel en cas de
                    remise et de signature électronique des documents
                    contractuels.  Effets de la rétractation : Le Client renvoie
                    ou restitue les matériels à{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    au plus tard dans les quatorze jours suivant la notification
                    de sa décision de rétractation. Le Client supporte les coûts
                    de renvoi des biens au tarif pratiqué par un transporteur
                    ainsi que les risques de retour desdits biens. Le retour des
                    matériels doit s’effectuer dans leur emballage d’origine ou
                    dans un emballage de nature à préserver leur parfaite
                    intégrité. La responsabilité du Client sera engagée et il
                    sera tenu à une indemnité compensatrice en cas de
                    dépréciation des biens résultant de manipulations autres que
                    celles nécessaires pour établir leur nature, leurs
                    caractéristiques et leur bon fonctionnement, par exemple en
                    cas d’usage incompatible avec les principes de la bonne foi.
                    Pour organiser le retour des matériels, le Client contacte
                    préalablement{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    par écrit pour solliciter un accord de retour qu’il joindra
                    aux matériels renvoyés.{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    rembourse au Client la totalité des sommes versées sans
                    retard injustifié et au plus tard dans les quatorze jours à
                    compter de la date à laquelle elle est informée de la
                    décision du Client de se rétracter.{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    peut différer le remboursement jusqu’à récupération des
                    biens ou jusqu’à ce que le Client ait fourni une preuve de
                    l’expédition de ces biens, la date retenue étant celle du
                    premier de ces faits. Le remboursement s’effectuera par le
                    même moyen de paiement que celui utilisé pour la transaction
                    initiale, sauf accord exprès du client pour que{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    utilise un autre moyen de paiement et dans la mesure où le
                    remboursement n’occasionne pas de frais pour le
                    consommateur.{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    n’est pas tenue de rembourser les frais supplémentaires si
                    le Client a expressément choisi un mode de livraison plus
                    coûteux que le mode de livraison utilisé par elle.
                    Conformément à l’article L.221-25 du Code de la
                    Consommation, si le Client souhaite que l’exécution de la
                    prestation de services commence avant la fin du délai de
                    rétractation,{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    recueille sa demande expresse sur papier ou sur support
                    durable. Le Client qui a exercé son droit de rétractation
                    alors que l’exécution du contrat a déjà commencé, à sa
                    demande expresse, avant la fin du délai de rétractation,
                    verse à{" "}
                    {commercialUserInformation?.company_raison_sociale?.toLocaleUpperCase()}{" "}
                    une somme correspondant au service fourni jusqu’à la
                    notification de sa rétractation ; ce montant est
                    proportionné au prix total de la prestation convenue dans le
                    contrat. Conformément à l’article L.221-28 du Code de la
                    Consommation, le droit de rétractation ne peut pas être
                    exercé pour les contrats de fourniture de services
                    pleinement exécutés avant la fin du délai de rétractation et
                    dont l’exécution a commencé après accord préalable exprès du
                    Client et renoncement exprès à son droit de rétractation.
                  </Text>,
                ]}
              />
            </>
          )}
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
    </Page>
  );
};
