export const panOrientationLetterToFullString = (orientation: string) => {
  switch (orientation) {
    case "south":
      return "Sud";
    case "north":
      return "Nord";
    case "east":
      return "Est";
    case "west":
      return "Ouest";
    case "north-west":
      return "Nord-Ouest";
    case "north-east":
      return "Nord-Est";
    case "south-west":
      return "Sud-Ouest";
    case "south-east":
      return "Sud-Est";
    default:
      break;
  }
};
