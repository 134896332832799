// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../../useAxiosPost";

// Local interface declaration
interface Props {
  userToken: string | null;
  building_id: string | null;
  study_id?: string | null;
}

export const useSendSelectedCartProducts = () => {
  const {
    isLoading,
    errorMessage: axiosErrorMessage,
    axiosPostRequest,
  } = useAxiosPost();

  const [errorMessageSendCartProducts, setErrorMessageSendCartProducts] =
    useState<string>("");

  const sendSelectedCartProducts = async ({
    userToken,
    study_id,
    building_id,
  }: Props) => {
    setErrorMessageSendCartProducts("");

    try {
      const response = await axiosPostRequest(
        `${process.env.REACT_APP_EERO_URL}/oree2/rg/postCartProductsToData/${study_id}`,
        { building_id },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response && response.status !== 200) {
        setErrorMessageSendCartProducts(
          "Send cart products to dataBackend has failed."
        );
      }

      return response;
    } catch (error) {
      setErrorMessageSendCartProducts("An error occurred while sending datas");
    }
  };
  return {
    sendSelectedCartProducts,
    isLoadingSendCartProducts: isLoading,
    errorMessageSendCartProducts:
      axiosErrorMessage || errorMessageSendCartProducts,
  };
};
