// imgs import
import electricite from "@web/shared/dist/assets/images/DesignSystemIllustrations/electricite1.svg";
import gaz from "@web/shared/dist/assets/images/DesignSystemIllustrations/gaz1.svg";
import GPL from "@web/shared/dist/assets/images/DesignSystemIllustrations/GPL1.svg";
import Fioul from "@web/shared/dist/assets/images/DesignSystemIllustrations/Fioul1.svg";
import bois from "@web/shared/dist/assets/images/DesignSystemIllustrations/bois1.svg";
import monophase from "@web/shared/dist/assets/images/DesignSystemIllustrations/Monophase1.svg";
import triphase from "@web/shared/dist/assets/images/DesignSystemIllustrations/Triphase1.svg";
import ChauffageElec from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chauffage-elec1.svg";
import ECS from "@web/shared/dist/assets/images/DesignSystemIllustrations/ECS1.svg";
import clim from "@web/shared/dist/assets/images/DesignSystemIllustrations/clim1.svg";
import voitureElec from "@web/shared/dist/assets/images/DesignSystemIllustrations/voiture-elec1.svg";
import piscine from "@web/shared/dist/assets/images/DesignSystemIllustrations/piscine1.svg";
import ChaudiereElec from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chaudiere-elec1.svg";
import PacAirEau from "@web/shared/dist/assets/images/DesignSystemIllustrations/Pac-air-eau1.svg";
import PacAirAir from "@web/shared/dist/assets/images/DesignSystemIllustrations/Pac-air-air1.svg";
import ChauffageSolaire from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chauffage-solaire1.svg";
import PlancherRayonnant from "@web/shared/dist/assets/images/DesignSystemIllustrations/Plancher-rayonnant1.svg";
import RadiateurAluminium from "@web/shared/dist/assets/images/DesignSystemIllustrations/Radiateur-aluminium1.svg";
import Convecteur from "@web/shared/dist/assets/images/DesignSystemIllustrations/Convecteur1.svg";
import RadiateurFonte from "@web/shared/dist/assets/images/DesignSystemIllustrations/Radiateur-fonte1.svg";
import RadiateurAcier from "@web/shared/dist/assets/images/DesignSystemIllustrations/Radiateur-acier1.svg";
import ChauffeEauElec from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chauffe-eau-elec1.svg";
import CET from "@web/shared/dist/assets/images/DesignSystemIllustrations/CET1.svg";
import ModuleECSAndPAC from "@web/shared/dist/assets/images/DesignSystemIllustrations/Module-ECS-PAC1.svg";
import BallonSolaire from "@web/shared/dist/assets/images/DesignSystemIllustrations/Ballon-solaire1.svg";
import GazCuisine from "@web/shared/dist/assets/images/DesignSystemIllustrations/gaz-cuisine1.svg";
import Chauffage from "@web/shared/dist/assets/images/DesignSystemIllustrations/chauffage1.svg";
import ChaudiereGazCondensation from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chaudiere-gaz-condensation1.svg";
import ChaudiereGazHorsCcondensation from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chaudiere-gaz-horsccondensation1.svg";
import ChaudiereBois from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chaudiere-a-bois1.svg";
import Cheminee from "@web/shared/dist/assets/images/DesignSystemIllustrations/Cheminee1.svg";
import PoeleBois from "@web/shared/dist/assets/images/DesignSystemIllustrations/Poele-a-bois1.svg";
import ChaudiereFioulCondensation from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chaudiere-fioul-condensation1.svg";
import ChaudiereFioulHorsCondensation from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chaudiere-fioul-hors-condensation1.svg";
import ChaudiereGPLCondensation from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chaudiere-GPL-condensation1.svg";
import ChaudiereGPLHorsCondensation from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chaudiere-GPL-hors-condensation1.svg";

export const mesConsommationsQuestionsData = {
  sourcesDEnergie: {
    options: [
      { label: "Electricité", value: "electricite", imgSrc: electricite },
      { label: "Gaz", value: "gaz", imgSrc: gaz },
      { label: "Fioul", value: "fioul", imgSrc: Fioul },
      { label: "Bois", value: "bois", imgSrc: bois },
      { label: "GPL", value: "gpl", imgSrc: GPL },
    ],
  },
  electricite: {
    typeDInstallation: {
      options: [
        {
          label: "Monophasé",
          value: "Monophase",
          imgSrc: monophase,
          imgAltTxt: "monophase",
        },
        {
          label: "Triphasé",
          value: "Triphase",
          imgSrc: triphase,
          imgAltTxt: "triphase",
        },
      ],
    },

    suscribedPower: {
      options: [
        { label: "3 kVA", value: "3" },
        { label: "6 kVA", value: "6" },
        { label: "9 kVA", value: "9" },
        { label: "12 kVA", value: "12" },
        { label: "15 kVA", value: "15" },
        { label: "18 kVA", value: "18" },
        { label: "Je ne sais pas", value: null },
      ],
    },
    optionTarifaire: {
      options: [
        {
          label: "Tarif de base",
          value: "Base",
        },
        {
          label: "Heures pleines / Heures creuses",
          value: "HPHC",
        },
      ],
    },

    principauxEquipements: {
      options: [
        {
          label: "Électroménager, éclairage",
          value: "autres",
          imgSrc: electricite,
        },
        {
          label: "Chauffage",
          value: "chauffage",
          imgSrc: ChauffageElec,
        },
        {
          label: "Eau chaude sanitaire (ECS)",
          value: "eauChaudeSanitaire",
          imgSrc: ECS,
        },
        {
          label: "Climatisation",
          value: "climatisation",
          imgSrc: clim,
        },
        {
          label: "Borne de recharge",
          value: "borneDeRecharge",
          imgSrc: voitureElec,
        },
        {
          label: "Piscine",
          value: "piscine",
          imgSrc: piscine,
        },
      ],
    },

    typeDeChauffage: {
      options: [
        {
          label: "Chaudière électrique",
          value: "chaudiereElectrique",
          imgSrc: ChaudiereElec,
        },
        {
          label: "Pompe à chaleur air eau",
          value: "pacAE",
          imgSrc: PacAirEau,
        },
        {
          label: "Pompe à chaleur air air",
          value: "pacAA",
          imgSrc: PacAirAir,
        },
        {
          label: "Chauffage solaire",
          value: "chauffageSolaire",
          imgSrc: ChauffageSolaire,
        },
        {
          label: "Plancher rayonnant",
          value: "plancherRayonnant",
          imgSrc: PlancherRayonnant,
        },
        {
          label: "Radiateur",
          value: "radiateur",
          imgSrc: RadiateurAluminium,
        },
      ],
    },

    typeDEmetteur: {
      options: [
        {
          label: "Convecteur",
          value: "convecteur",
          imgSrc: Convecteur,
        },
        {
          label: "Plancher chauffant",
          value: "plancherChauffant",
          imgSrc: PlancherRayonnant,
        },
        {
          label: "Radiateur en fonte",
          value: "radiateurEnFonte",
          imgSrc: RadiateurFonte,
        },
        {
          label: "Radiateur en acier",
          value: "radiateurEnAcier",
          imgSrc: RadiateurAcier,
        },
        {
          label: "Radiateur en aluminium",
          value: "radiateurEnAluminium",
          imgSrc: RadiateurAluminium,
        },
      ],
    },

    typeDeBallon: {
      options: [
        {
          label: "Chauffe-eau éléctrique",
          value: "chauffeEauElectrique",
          imgSrc: ChauffeEauElec,
        },
        {
          label: "Chauffe-eau thermodynamique",
          value: "chauffeEauThermodynamique",
          imgSrc: CET,
        },
        {
          label: "Module ECS intégré au système de chauffage",
          value: "moduleEcsIntegre",
          imgSrc: ModuleECSAndPAC,
        },
        {
          label: "Ballon solaire",
          value: "ballonSolaire",
          imgSrc: BallonSolaire,
        },
      ],
    },

    capaciteDeStockage: {
      options: [
        {
          label: "Instantanée",
          value: "0",
        },
        {
          label: "80 L",
          value: "80",
        },
        {
          label: "100 L",
          value: "100",
        },
        {
          label: "120 L",
          value: "120",
        },
        {
          label: "150 L",
          value: "150",
        },
        {
          label: "200 L",
          value: "200",
        },
        {
          label: "250 L",
          value: "250",
        },
        {
          label: "300 L",
          value: "300",
        },
      ],
    },
  },

  gaz: {
    principauxEquipements: {
      options: [
        {
          label: "Chauffage",
          value: "chauffage",
          imgSrc: Chauffage,
        },
        {
          label: "Eau chaude sanitaire (ECS)",
          value: "eauChaudeSanitaire",
          imgSrc: ECS,
        },
        {
          label: "Autres (cuisine)",
          value: "autres",
          imgSrc: GazCuisine,
        },
      ],
    },
    typeDeChaudiere: {
      options: [
        {
          label: "Chaudière gaz à condensation",
          value: "chaudiereGazACondensation",
          imgSrc: ChaudiereGazCondensation,
        },
        {
          label: "Chaudière gaz hors condensation",
          value: "chaudiereGazHorsCondensation",
          imgSrc: ChaudiereGazHorsCcondensation,
        },
      ],
    },
    typeDEmetteur: {
      options: [
        {
          label: "Plancher chauffant",
          value: "plancherChauffant",
          imgSrc: PlancherRayonnant,
        },
        {
          label: "Radiateur en fonte",
          value: "radiateurEnFonte",
          imgSrc: RadiateurFonte,
        },
        {
          label: "Radiateur en acier",
          value: "radiateurEnAcier",
          imgSrc: RadiateurAcier,
        },
        {
          label: "Radiateur en aluminium",
          value: "radiateurEnAluminium",
          imgSrc: RadiateurAluminium,
        },
      ],
    },

    capaciteDeStockage: {
      options: [
        {
          label: "Instantanée",
          value: "0",
        },
        {
          label: "80 L",
          value: "80",
        },
        {
          label: "100 L",
          value: "100",
        },
        {
          label: "120 L",
          value: "120",
        },
        {
          label: "150 L",
          value: "150",
        },
        {
          label: "200 L",
          value: "200",
        },
        {
          label: "250 L",
          value: "250",
        },
        {
          label: "300 L",
          value: "300",
        },
      ],
    },
  },
  bois: {
    typeDeChauffage: {
      options: [
        {
          label: "Chaudière à bois",
          value: "chaudiereABois",
          imgSrc: ChaudiereBois,
        },
        {
          label: "Cheminée",
          value: "cheminee",
          imgSrc: Cheminee,
        },
        {
          label: "Poêle à bois",
          value: "poeleABois",
          imgSrc: PoeleBois,
        },
      ],
    },
    typeDEmetteur: {
      options: [
        {
          label: "Plancher chauffant",
          value: "plancherChauffant",
          imgSrc: PlancherRayonnant,
        },
        {
          label: "Radiateur en fonte",
          value: "radiateurEnFonte",
          imgSrc: RadiateurFonte,
        },
        {
          label: "Radiateur en acier",
          value: "radiateurEnAcier",
          imgSrc: RadiateurAcier,
        },
        {
          label: "Radiateur en aluminium",
          value: "radiateurEnAluminium",
          imgSrc: RadiateurAluminium,
        },
      ],
    },
  },
  fioul: {
    principauxEquipements: {
      options: [
        {
          label: "Chauffage",
          value: "chauffage",
          imgSrc: Chauffage,
        },
        {
          label: "Eau chaude sanitaire (ECS)",
          value: "eauChaudeSanitaire",
          imgSrc: ECS,
        },
      ],
    },
    typeDeChaudiere: {
      options: [
        {
          label: "Chaudière fioul à condensation",
          value: "chaudiereFioulACondensation",
          imgSrc: ChaudiereFioulCondensation,
        },
        {
          label: "Chaudière fioul hors condensation",
          value: "chaudiereFioulHorsCondensation",
          imgSrc: ChaudiereFioulHorsCondensation,
        },
      ],
    },
    typeDEmetteur: {
      options: [
        {
          label: "Plancher chauffant",
          value: "plancherChauffant",
          imgSrc: PlancherRayonnant,
        },
        {
          label: "Radiateur en fonte",
          value: "radiateurEnFonte",
          imgSrc: RadiateurFonte,
        },
        {
          label: "Radiateur en acier",
          value: "radiateurEnAcier",
          imgSrc: RadiateurAcier,
        },
        {
          label: "Radiateur en aluminium",
          value: "radiateurEnAluminium",
          imgSrc: RadiateurAluminium,
        },
      ],
    },
    capaciteDeStockage: {
      options: [
        {
          label: "Instantanée",
          value: "0",
        },
        {
          label: "80 L",
          value: "80",
        },
        {
          label: "100 L",
          value: "100",
        },
        {
          label: "120 L",
          value: "120",
        },
        {
          label: "150 L",
          value: "150",
        },
        {
          label: "200 L",
          value: "200",
        },
        {
          label: "250 L",
          value: "250",
        },
        {
          label: "300 L",
          value: "300",
        },
      ],
    },
  },
  gpl: {
    principauxEquipements: {
      options: [
        {
          label: "Chauffage",
          value: "chauffage",
          imgSrc: Chauffage,
        },
        {
          label: "Eau chaude sanitaire (ECS)",
          value: "eauChaudeSanitaire",
          imgSrc: ECS,
        },
      ],
    },
    typeDeChaudiere: {
      options: [
        {
          label: "Chaudière GPL à condensation",
          value: "chaudiereGPLACondensation",
          imgSrc: ChaudiereGPLCondensation,
        },
        {
          label: "Chaudière GPL hors condensation",
          value: "chaudiereGPLHorsCondensation",
          imgSrc: ChaudiereGPLHorsCondensation,
        },
      ],
    },
    typeDEmetteur: {
      options: [
        {
          label: "Plancher chauffant",
          value: "plancherChauffant",
          imgSrc: PlancherRayonnant,
        },
        {
          label: "Radiateur en fonte",
          value: "radiateurEnFonte",
          imgSrc: RadiateurFonte,
        },
        {
          label: "Radiateur en acier",
          value: "radiateurEnAcier",
          imgSrc: RadiateurAcier,
        },
        {
          label: "Radiateur en aluminium",
          value: "radiateurEnAluminium",
          imgSrc: RadiateurAluminium,
        },
      ],
    },
    capaciteDeStockage: {
      options: [
        {
          label: "Instantanée",
          value: "0",
        },
        {
          label: "80 L",
          value: "80",
        },
        {
          label: "100 L",
          value: "100",
        },
        {
          label: "120 L",
          value: "120",
        },
        {
          label: "150 L",
          value: "150",
        },
        {
          label: "200 L",
          value: "200",
        },
        {
          label: "250 L",
          value: "250",
        },
        {
          label: "300 L",
          value: "300",
        },
      ],
    },
  },
};
