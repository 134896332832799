// Style import
import "./ProjectMarker.scss";
import "swiper/css";
import "swiper/css/navigation";

// Libraries import
import { useState } from "react";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { SwiperRef, SwiperSlide } from "swiper/react";

// Services import
import {
  IProjectImages,
  useGetProjectImages,
} from "../../../services/Oree2/ProjectsMap/useGetProjectImage";

// Components import
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { CarrouselWrapper } from "@web/shared/dist/containers/CarrouselWrapper/CarrouselWrapper";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { ImageSwiper } from "../../../components/ImageSwiper/ImageSwiper";

// Interfaces import
import { IChantier } from "../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface IProps {
  project: IChantier;
  userToken: string;
  index: number;
  projectImages: IProjectImages[];
  setProjectImages: React.Dispatch<React.SetStateAction<IProjectImages[]>>;
  swiperRef: React.MutableRefObject<SwiperRef | null>;
  setIsModaleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMarkerClicked: React.MutableRefObject<boolean>;
}

export const ProjectMarker = ({
  project,
  userToken,
  index,
  projectImages,
  setProjectImages,
  swiperRef,
  setIsModaleOpen,
  isMarkerClicked,
}: IProps) => {
  const { getProjectImages, isLoading, errorMessage } = useGetProjectImages();
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

  const fetchProjectImages = async () => {
    const projectImages = await getProjectImages({
      projectId: project.chantierID,
      userToken,
    });

    setProjectImages(projectImages);
  };

  const position = {
    lat: Number(project.latitude),
    lng: Number(project.longitude),
  };

  const installedProductsFiltered = project.listProduit
    .split("\r\n")
    ?.filter(
      (product: string) =>
        !product.toLowerCase().includes("pose") &&
        !product.toLowerCase().includes("pack") &&
        product !== ""
    );

  const onMouseOverMarker = async () => {
    isMarkerClicked.current === false && setIsInfoWindowOpen(() => true);
  };

  const onMouseOutMarker = () => {
    isMarkerClicked.current === false && setIsInfoWindowOpen(() => false);
  };

  const onClickOpenModal = () => {
    isMarkerClicked.current = true;
    setIsInfoWindowOpen(() => true);
    fetchProjectImages();
  };

  const onClickCloseModal = () => {
    isMarkerClicked.current = false;
    setIsInfoWindowOpen(() => false);
    setProjectImages([]);
    setIsModaleOpen(false);
  };

  return (
    <>
      <MarkerF
        position={position}
        options={{ optimized: true }}
        key={"Marker" + index}
        clickable={true}
        onMouseOver={onMouseOverMarker}
        onMouseOut={onMouseOutMarker}
        onClick={onClickOpenModal}
      >
        {isInfoWindowOpen ? (
          <InfoWindowF onCloseClick={onClickCloseModal}>
            <div className="ProjectMarker__windowContainer">
              <div className="ProjectMarker__Installations">
                <h2>Installations : </h2>
                {installedProductsFiltered.length > 0 ? (
                  <ul>
                    {installedProductsFiltered.map(
                      (product: string, index: number) => {
                        return <li key={index}>{product}</li>;
                      }
                    )}
                  </ul>
                ) : null}
              </div>
              {project.kwhProduits ? (
                <h2>
                  kWh produits : <span>{project.kwhProduits}</span> kWh
                </h2>
              ) : null}

              {isLoading ? (
                <div className="ProjectMarker__loadingContainer">
                  <LoadingComponent diameter={30} />
                </div>
              ) : projectImages.length > 0 ? (
                <>
                  <h2>Images du chantier : </h2>
                  <div className="ProjectMarker__imagesContainer">
                    <CarrouselWrapper
                      numberOfElements={projectImages.length}
                      isMobile={false}
                      slidePerViewDesktop={3}
                      isDisplayChevrons={true}
                      swiperStyle={{
                        width: "300px",
                        height: "100px",
                      }}
                    >
                      {projectImages.map((projectImage, index) => {
                        return (
                          <SwiperSlide
                            data-hash={`slide${index}`}
                            key={index}
                            className={"ProjectMarker__SwiperSlide"}
                          >
                            <div className="ProjectMarker__imageContainer">
                              <ImageSwiper
                                src={`data:image/png;base64,${projectImage.base64EncodedContent}`}
                                alt="projectImage"
                                onClick={() => {
                                  if (swiperRef.current) {
                                    swiperRef.current.swiper.slideTo(index);
                                  }
                                  setIsModaleOpen(true);
                                }}
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </CarrouselWrapper>
                  </div>
                </>
              ) : errorMessage ? (
                <ErrorMessage errorMessage={errorMessage} />
              ) : null}
            </div>
          </InfoWindowF>
        ) : null}
      </MarkerF>
    </>
  );
};
