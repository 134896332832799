//Libraries import
import { useState } from "react";
import { useAxiosPut } from "../useAxiosPut";
import { AxiosResponse } from "axios";

// Local interface declaration
interface IProps {
  documentPayload: {
    document_id?: string | null;
    bdc_id?: string | null;
    bdc_file_name?: string | null;
    bdc_url?: string | null;
    comments?: string[] | null;
  };
  userToken: string | null;
  study_id: string;
}

interface IUpdateDocumentResponse {
  message: string;
}

export const useUpdateDocument = () => {
  const {
    axiosPutRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPut();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const updateDocument = async ({
    documentPayload: document,
    study_id,
    userToken,
  }: IProps): Promise<AxiosResponse<IUpdateDocumentResponse> | void> => {
    setErrorMessage("");

    if (!document.document_id || !userToken) {
      setErrorMessage("Paramètre manquant.");
      return;
    }

    const response = await axiosPutRequest<IUpdateDocumentResponse>(
      `${process.env.REACT_APP_EERO_URL}/oree2/updateDocument/${study_id}`,
      { documentPayload: document },
      { headers: { Authorization: `Bearer ${userToken}` } }
    );

    if (!response) {
      setErrorMessage("Impossible de mettre à jour le document.");
      return;
    }

    return response;
  };

  return {
    updateDocument,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
