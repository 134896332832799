//Libraries import
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

//Style import
import "react-datepicker/dist/react-datepicker.css";
import "./mesConsommationsChart.scss";

//Chart component import
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
  Cell,
  Tooltip,
} from "recharts";

//Component import
import { Layout } from "../../Layout/Layout";

//function import
import {
  filterBetweenTwoDate,
  findeDateMinMax,
  getLastYearDaily,
  groupByDay,
  groupByMonth,
  groupByWeek,
} from "../../../services/ChartFilterFunctions";

// Interface import
import { IConsumptionUnit } from "../../../interfaces/generalInterfaces";

//moment setting
moment.updateLocale("fr", {
  months: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  weekdays: [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ],
});

//Local interface
interface Iprop {
  data: IConsumptionUnit[];
}

export const MesConsommationsChart = ({ data = [] }: Iprop) => {
  const dataInitial = data;

  const [dataToShow, setDataToShow] = useState<IConsumptionUnit[]>(() => {
    return getLastYearDaily(dataInitial);
  });

  const { dateMin: dateMinAllData, dateMax: dateMaxAllData } =
    findeDateMinMax(dataInitial);

  const { dateMin: dateMinInit, dateMax: dateMaxInit } =
    findeDateMinMax(dataToShow);

  const [dateMin, SetDateMin] = useState<Date>(dateMinInit);
  const [dateMax, SetDateMax] = useState<Date>(dateMaxInit);

  const [activeIndex, setActiveIndex] = useState(0);
  const activeItem = dataToShow[activeIndex];

  const [ButtonData, setButtonData] = useState([
    { value: "Hour", Label: "Heure", hiden: false },
    { value: "Day", Label: "Jour", hiden: false },
    { value: "Week", Label: "Semaine", hiden: false },
    { value: "Month", Label: "Mois", hiden: false },
  ]);

  const [formatSelected, setFormatSelected] = useState(() => {
    return "Day";
  });

  useEffect(() => {
    const dataToShowFiltered = filterBetweenTwoDate(
      dataInitial,
      dateMin,
      dateMax
    );

    setButtonData((state) => {
      const newState = [...state];
      if (dataToShowFiltered.length <= 168) {
        newState[0].hiden = false;
        return newState;
      }
      if (dataToShowFiltered.length > 168) {
        newState[0].hiden = true;
        return newState;
      }
      return state;
    });

    setActiveIndex(0);
    switch (formatSelected) {
      case "Month":
        setDataToShow(groupByMonth(dataToShowFiltered));
        break;
      case "Week":
        setDataToShow(groupByWeek(dataToShowFiltered));
        break;
      case "Day":
        setDataToShow(groupByDay(dataToShowFiltered));
        break;
      case "Hour":
        if (dataToShowFiltered.length <= 168) {
          setDataToShow(dataToShowFiltered);
          break;
        }
        setDataToShow(groupByDay(dataToShowFiltered));
        setFormatSelected("Day");
        break;
      default:
        setDataToShow(groupByMonth(dataToShowFiltered));
    }
  }, [formatSelected, dateMin, dateMax, dataInitial]);

  //Function to get data for Legend

  const handleClick = useCallback(
    (entry: any, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  // FUnction to custom X axis
  const dateFormatXAxis = (tickItem: number) => {
    if (formatSelected === "Hour") return moment(tickItem).format("H:mm");
    if (formatSelected === "Month") return moment(tickItem).format("MMM YY");
    return moment(tickItem).format("DD/MM/YY");
  };

  return (
    <div className="MesConsommationsChart">
      <Layout>
        <div className="MesConsommationsChart__FilterSection">
          <div className="MesConsommationsChart__DateSelectionSection">
            <p>Du</p>
            <DatePicker
              selected={dateMin}
              onChange={(date: Date) => {
                if (moment(date).isAfter(moment(dateMax))) {
                  SetDateMin(date);
                  SetDateMax(moment(date).add(1, "day").toDate());
                  return;
                }
                SetDateMin(date);
              }}
              minDate={dateMinAllData}
              maxDate={dateMaxAllData}
              dateFormat={"dd/MM/yyyy"}
              //Hide keyboard on iPad version
              onFocus={(e) => e.target.blur()}
              className="MesConsommationsChart__DatePickerInput"
              // @ts-ignore
              dayClassName={(date: Date) => {
                return moment(date).isSame(moment(dateMax), "day")
                  ? "MesConsommationsChart__DatePickerSecondDate"
                  : undefined;
              }}
            />
            <p>Au</p>

            <DatePicker
              selected={dateMax}
              onChange={(date: Date) => {
                if (moment(date).isBefore(moment(dateMin))) {
                  SetDateMin(moment(date).subtract(1, "day").toDate());
                  SetDateMax(date);
                  return;
                }
                SetDateMax(date);
              }}
              minDate={dateMinAllData}
              maxDate={dateMaxAllData}
              dateFormat={"dd/MM/yyyy"}
              //Hide keyboard on iPad version
              onFocus={(e) => e.target.blur()}
              className="MesConsommationsChart__DatePickerInput"
              // @ts-ignore
              dayClassName={(date: Date) => {
                return moment(date).isSame(moment(dateMin), "day")
                  ? "MesConsommationsChart__DatePickerSecondDate"
                  : undefined;
              }}
            />
          </div>
          <div className="MesConsommationsChart__FormatSelectionSection">
            <div className={"MesConsommationsChart__ButtonContainer"}>
              {ButtonData.map((Button, index) => {
                return (
                  <button
                    hidden={Button.hiden}
                    className={
                      formatSelected === Button.value
                        ? "MesConsommationsChart__ButtonActive"
                        : "MesConsommationsChart__ButtonNotActive"
                    }
                    onClick={() => {
                      setFormatSelected(Button.value);
                    }}
                    key={index}
                  >
                    <p>{Button.Label}</p>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div className="MesConsommationsChart__ChartSection">
          <ResponsiveContainer width="90%" height={350}>
            {dataToShow.length < 30 ? (
              <BarChart
                data={dataToShow}
                margin={{
                  left: 11,
                  bottom: 25,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis
                  dataKey="datetime"
                  tickFormatter={dateFormatXAxis}
                  dy={15}
                  angle={-45}
                />
                <YAxis
                  tickFormatter={(value) => value.toFixed(0)}
                  className="MesConsommationsChart__LabelYaxis"
                  label={{
                    value: "Conso. électricité (kWh)",
                    angle: -90,
                    position: "insideLeft",
                    dy: 100,
                    dx: -10,
                  }}
                ></YAxis>
                <Bar
                  dataKey="electric_consumption"
                  fill="#0073e0"
                  onClick={handleClick}
                >
                  {dataToShow.map((entry, index) => (
                    <Cell
                      cursor="pointer"
                      fill={index === activeIndex ? "#A3E8E4" : "#0073e0"}
                      key={`cell-${index}`}
                    />
                  ))}
                </Bar>
              </BarChart>
            ) : (
              <LineChart
                data={dataToShow}
                margin={{
                  left: 11,
                  bottom: 25,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis
                  dataKey="datetime"
                  tickFormatter={dateFormatXAxis}
                  dy={15}
                  angle={-45}
                />
                <YAxis
                  type="number"
                  domain={[
                    0,
                    dataToShow.reduce((prev, current) =>
                      prev.electric_consumption > current.electric_consumption
                        ? prev
                        : current
                    ).electric_consumption +
                      (formatSelected === "Day"
                        ? 10
                        : formatSelected === "Week"
                        ? 200
                        : 2),
                  ]}
                  tickFormatter={(value) => value.toFixed(0)}
                  className="MesConsommationsChart__LabelYaxis"
                  label={{
                    value: "Conso. électricité (kWh)",
                    angle: -90,
                    position: "insideLeft",
                    dy: 100,
                    dx: -10,
                  }}
                ></YAxis>
                <Tooltip content={<></>} cursor={false} />
                <Line
                  dataKey="electric_consumption"
                  fill="#0073e0"
                  type="monotone"
                  dot={false}
                  activeDot={{
                    onClick: (entry, payload: any) =>
                      handleClick(entry, payload.index),
                  }}
                ></Line>
              </LineChart>
            )}
          </ResponsiveContainer>
        </div>
        <div className="MesConsommationsChart__Legende">
          <h5>
            {formatSelected === "Month" &&
              moment(activeItem?.datetime).format("MMMM YYYY").toString()}
            {formatSelected === "Week" &&
              `Semaine
              ${moment(activeItem?.datetime).format("WW YYYY").toString()}`}
            {formatSelected === "Day" &&
              moment(activeItem?.datetime)
                .format("dddd DD MMM YYYY")
                .toString()}
            {formatSelected === "Hour" &&
              moment(activeItem?.datetime)
                .format("dddd DD MMM YYYY à H:mm")
                .toString()}
          </h5>
          <p>
            {activeItem?.electric_consumption?.toFixed(2)}
            kWh
          </p>
        </div>
      </Layout>
    </div>
  );
};
