import { useState } from "react";

//Services import
import { useAppSelector } from "../../redux/store/hook";
import { useAxiosGet } from "../useAxiosGet";

//Local interface
interface CustomResponse {
  label: string;
  type: string;
  value: number;
  isMembershipCode: boolean;
}

export const useIsCouponValide = () => {
  const { userAuthInfo } = useAppSelector((state) => state);
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const isCouponValide = async ({
    couponName,
    study_id,
  }: {
    couponName: string;
    study_id: string;
  }): Promise<{
    label: string;
    type: string;
    value: number;
    isMembershipCode: boolean;
  } | void> => {
    setErrorMessage("");

    if (!couponName) {
      setErrorMessage("Code manquant");
      return;
    }

    if (couponName.includes("%")) {
      couponName = couponName.replace("%", "%25");
    }

    const response = await axiosGetRequest<CustomResponse>(
      `${process.env.REACT_APP_EERO_URL}/portaladmin/iscouponvalide/${couponName}/${study_id}`,
      {
        headers: {
          authorization: `Bearer ${userAuthInfo.userToken}`,
        },
      }
    );

    return response && response;
  };

  return {
    isCouponValide,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
