import { Text, StyleSheet, Font, View } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../../../assets/pdf/fonts/DMSansBold.ttf";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  container: {
    fontSize: 9,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "#f8f8f8",
    padding: "10 10 5 10",

    marginBottom: 5,
    borderRadius: 10,
  },
  container__items: {
    display: "flex",
    flexDirection: "column",
  },
  container__items__item: {
    marginBottom: 10,
  },
});

interface Item {
  question: string;
  reponse: string | 0 | null | undefined;
}
interface Props {
  items: Item[];
}
export const PdfEncadreBlancText = ({ items }: Props) => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    <View style={styles.container}>
      <View style={styles.container__items}>
        {items.map((item, _key) => {
          return (
            <Text key={s4()} style={styles.container__items__item}>
              {item.question}
              <Text style={{ fontFamily: "DMSansBold" }}> {item.reponse}</Text>
            </Text>
          );
        })}
      </View>
    </View>
  );
};
