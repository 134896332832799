// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";

// Interfaces import
import { IChantier } from "../../../interfaces/generalInterfaces";

// Local interface declaration
interface IProps {
  userToken: string | null;
}

export const useGetProjects = () => {
  const { errorMessage: axiosErrorMessage, axiosGetRequest } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const getProjects = async ({ userToken }: IProps) => {
    setErrorMessage("");

    const response = await axiosGetRequest<{ projects: IChantier[] }>(
      `${process.env.REACT_APP_EERO_URL}/oree2/projects`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response?.projects.length === 0) {
      setErrorMessage("No projects found.");
    }

    return response?.projects || [];
  };

  return {
    getProjects,
    errorMessage: axiosErrorMessage || errorMessage,
  };
};
