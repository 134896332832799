import { useAxiosPost } from "../useAxiosPost";
import { useState } from "react";

interface ISendEmailRequestBody {
  email: string;
  firstName: string;
  lastName: string;
  documentIDDevis: string | null;
  documentIDEtude: string | null;
  commercialFirstName: string | null;
  commercialLastName: string | null;
  commercialEmail: string | null;
  commercialPhone: string | null;
  company_id: string | null;
}
interface IResponseBody {
  message?: string;
  response?: unknown;
}

interface IProps {
  email: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  userToken: string | null;
  documentIDDevis: string | null;
  documentIDEtude: string | null;
  commercialFirstName: string | null;
  commercialLastName: string | null;
  commercialEmail: string | null;
  commercialPhone: string | null;
  company_id: string | null;
  study_id: string;
}

export const useSendEmailDevisEtude = () => {
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const sendEmailDevisEtude = async ({
    email,
    firstName,
    lastName,
    userToken,
    documentIDDevis,
    documentIDEtude,
    commercialFirstName,
    commercialLastName,
    commercialEmail,
    commercialPhone,
    company_id,
    study_id,
  }: IProps): Promise<void> => {
    setErrorMessage("");
    if (email && firstName && lastName && documentIDDevis && documentIDEtude) {
      const sendEmailBody: ISendEmailRequestBody = {
        email,
        firstName,
        lastName,
        documentIDDevis: documentIDDevis,
        documentIDEtude: documentIDEtude,
        commercialFirstName,
        commercialLastName,
        commercialEmail,
        commercialPhone,
        company_id,
      };
      await axiosPostRequest<IResponseBody>(
        `${BACKEND_URL}/oree2/pv/sendEmailDevisEtude/${study_id}`,
        sendEmailBody,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
    } else {
      setErrorMessage("Veuillez renseigner tous les champs.");
    }
  };

  return {
    sendEmailDevisEtude,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
