import React from "react";
import "./FooterHills.scss";

//Img import
import footerHomeDesktop from "../../assets/images/footer/footerHomeDesktop.svg";
import footerStartEtude from "../../assets/images/footer/footerStartEtude.svg";

interface IProps {
  isHouseVisible?: boolean;
}

export const FooterHills = ({ isHouseVisible = false }: IProps) => {
  return (
    <div className="FooterHills">
      {isHouseVisible ? (
        <img src={footerStartEtude} alt="Landscape with an house" />
      ) : (
        <img
          src={footerHomeDesktop}
          alt="Des belles colines vertes"
          width="1745"
          height="262"
        />
      )}
    </div>
  );
};
