import { ISageProduct } from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

/**
 * Format Sage products to match the dropdown format.
 * If alias exits in the price list, replace product's Sage name by its alias in the dropdown.
 *
 * @param {ISageProduct[]} sageProducts - Array of Sage products following Sage product format.
 * @param {keyof ISageProduct} dataProducts - Array Data products following Data backend format.
 *
 */
export const formatSageProductsForDropdown = ({
  sageProducts,
  dataProducts,
}: {
  sageProducts: ISageProduct[];
  dataProducts: IDataProduct[];
}) => {
  return sageProducts.map((SageProduct) => {
    // If we have a product alias from the price list (i.e. from Data backend product information) we want to display it instead of the product name from Sage
    const dataProduct = dataProducts.find(
      (product) => product.sage_reference === SageProduct.AR_Ref
    );

    return {
      label: dataProduct?.product_alias || SageProduct.AR_Design || null,
      value: SageProduct.AR_Ref || null,
    };
  });
};
