// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Services imports
import { useAppDispatch } from "../../redux/store/hook";
import { setStudyInfoAction } from "../../redux/appActions";

// Local interface declaration
interface Props {
  userToken: string | null;
  study_id?: string;
}

interface IGetCustomerInformationResponse {
  firstname: string | null;
  lastname: string | null;
  phonenumber: string | null;
  email: string | null;
  address: string | null;
  zipcode: string | null;
  latitude: number | null;
  longitude: number | null;
}

export const useGetCustomerInformation = () => {
  const {
    isLoading,
    errorMessage: axiosErrorMessage,
    axiosGetRequest,
  } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  const getCustomerInformation = async ({ userToken, study_id }: Props) => {
    setErrorMessage("");

    const response = await axiosGetRequest<IGetCustomerInformationResponse>(
      `${process.env.REACT_APP_EERO_URL}/oree2/getCustomerInfo/${study_id}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      dispatch(
        setStudyInfoAction({
          studyInformation: {
            firstname: response.firstname,
            lastname: response.lastname,
            email: response.email,
            phonenumber: response.phonenumber,
            address: response.address,
            latitude: response.latitude,
            longitude: response.longitude,
          },
        })
      );
      return response;
    } else {
      setErrorMessage("Erreur lors du calcul du potentiel solaire");
    }
  };

  return {
    getCustomerInformation,
    isLoading,
    errorMessage: axiosErrorMessage || errorMessage,
  };
};
