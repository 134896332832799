// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Services imports
import { useAppDispatch } from "../../redux/store/hook";
import {
  setSolarPotentialStepsStatusAction,
  setStudyInfoAction,
} from "../../redux/appActions";

// Interfaces imports
import { IMonPotentielSolaireAPIResponse } from "../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  userToken: string | null;
  building_id: string | null;
  household_id: string | null;
  study_id: string;
}

export const useGetMonPotentielSolaire = () => {
  const {
    isLoading,
    errorMessage: axiosErrorMessage,
    axiosGetRequest,
  } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  const getPotentielSolaire = async ({
    userToken,
    building_id,
    household_id,
    study_id,
  }: Props) => {
    setErrorMessage("");
    dispatch(
      setSolarPotentialStepsStatusAction({
        solarPotentialStepsStatus: {
          isGetSolarPotentialLoading: true,
        },
      })
    );
    const response = await axiosGetRequest<IMonPotentielSolaireAPIResponse>(
      `${process.env.REACT_APP_EERO_URL}/oree2/getSolarPotential/${study_id}?building_id=${building_id}&household_id=${household_id}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      const responseToRedux = {
        solar_potential_image: response.solar_potential_image,
        building_image: response.building_image,
        roof_sections: response.roof_sections,
        solar_potential_ok: response.solar_potential_ok,
        ...(response.roof_total_area_m2 && {
          roof_area_to_equip: response.roof_total_area_m2,
        }),
        ...(response.roof_material && { roof_type: response.roof_material }),
      };

      dispatch(
        setStudyInfoAction({
          studyInformation: responseToRedux,
        })
      );

      dispatch(
        setSolarPotentialStepsStatusAction({
          solarPotentialStepsStatus: {
            isGetSolarPotentialLoading: false,
          },
        })
      );

      return response;
    } else {
      setErrorMessage("Erreur lors du calcul du potentiel solaire");
      dispatch(
        setSolarPotentialStepsStatusAction({
          solarPotentialStepsStatus: {
            isGetSolarPotentialLoading: false,
          },
        })
      );
      return { solar_potential_ok: false };
    }
  };

  return {
    getPotentielSolaire,
    isLoading,
    errorMessage: errorMessage || axiosErrorMessage,
  };
};
