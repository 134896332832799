import { StyleSheet, Font } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../assets/pdf/fonts/DMSansBold.ttf";
import DMSansMedium from "../../assets/pdf/fonts/DMSansMedium.ttf";
import DMSansRegular from "../../assets/pdf/fonts/DMSansRegular.ttf";
import DMSansItalic from "../../assets/pdf/fonts/DMSansItalic.ttf";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});
Font.register({
  family: "DMSansRegular",
  src: DMSansRegular,
  format: "truetype",
});
Font.register({
  family: "DMSansMedium",
  src: DMSansMedium,
  format: "truetype",
});
Font.register({
  family: "DMSansItalic",
  src: DMSansItalic,
  format: "truetype",
});

// Create styles
export const PdfEtudeRGStyles = StyleSheet.create({
  page: {
    color: "black",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    fontFamily: "DMSansRegular",
  },
  pagePadding: {
    color: "black",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    fontFamily: "DMSansRegular",
    paddingBottom: 50,
  },
  pagePaddingZero: {
    color: "black",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    fontFamily: "DMSansRegular",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },

  arrowNext: {
    marginTop: 30,
    width: 40,
  },
});
