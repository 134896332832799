import SnowFlake from "../assets/images/snowflake.svg";
import Swimmingpool from "../assets/images/swimmingpool.svg";
import BorneRecharge from "../assets/images/borne-recharge.svg";

export const myConsumptionsFormOptions = {
  optionsTarifaire: [
    { label: "Base", value: "Base" },
    {
      label: "Heures pleines / Heures creuses",
      value: "HPHC",
    },
  ],
  puissanceSouscrite: [
    { label: "3 kVA", value: "3" },
    { label: "6 kVA", value: "6" },
    { label: "9 kVA", value: "9" },
    { label: "12 kVA", value: "12" },
    { label: "15 kVA", value: "15" },
    { label: "18 kVA", value: "18" },
    { label: "Je ne sais pas", value: "" },
  ],
  TypeInstallationElectrique: [
    { label: "Monophasé", value: "Monophase" },
    { label: "Triphasé", value: "Triphase" },
  ],

  PossedezVousUnChauffageElec: [
    { label: "Oui", value: "true" },
    { label: "Non", value: "false" },
  ],

  PossedezVousUnEquipement: [
    { label: "Climatisation", value: "climatisation", imgSrc: SnowFlake },
    { label: "Piscine", value: "piscine", imgSrc: Swimmingpool },
    {
      label: "Borne de recharge",
      value: "borne de recharge",
      imgSrc: BorneRecharge,
    },
  ],

  PossedezVousUnChauffeEauElec: [
    { label: "Oui", value: "true" },
    { label: "Non", value: "false" },
  ],
};
