import { Text, View } from "@react-pdf/renderer";

interface Props {
  text: string | undefined | null | 0;
}

export const PdfText = ({ text }: Props) => {
  return (
    <View>
      <Text
        style={{
          fontSize: 9,
          marginBottom: 10,
        }}
      >
        {text}
      </Text>
    </View>
  );
};
