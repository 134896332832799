import "./ButtonInternalLink.scss";

// Local interface declaration
interface Props {
  text: string;
  onClick?: React.MouseEventHandler<HTMLParagraphElement>;
  style?: React.CSSProperties;
}

export const ButtonInternalLink = ({ text, onClick, style }: Props) => {
  return (
    <p className="ButtonInternalLink" onClick={onClick} style={style}>
      {text}
    </p>
  );
};
