import { HTMLProps, useState } from "react";

type ImgProps = HTMLProps<HTMLImageElement>;

export const ImageSwiper = (props: ImgProps) => {
  const [isBroken, setIsBroken] = useState(false);

  function handleError() {
    setIsBroken(true);
  }

  if (isBroken) {
    return <></>;
  }

  return <img onError={handleError} {...props} alt={props.alt} />;
};
