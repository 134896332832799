import { Text, Font } from "@react-pdf/renderer";

// Fonts
import DMSansItalic from "../../../../assets/pdf/fonts/DMSansItalic.ttf";

// Register font
Font.register({
  family: "DMSansItalic",
  src: DMSansItalic,
  format: "truetype",
});

interface Props {
  text: string;
  noMargin?: boolean;
}

export const PdfTextItalic = ({ text, noMargin }: Props) => {
  return (
    <Text
      style={{
        fontSize: 7,
        color: "#50545f",
        fontFamily: "DMSansItalic",
        marginBottom: noMargin ? 0 : 10,
        borderRadius: 10,
      }}
    >
      {text}
    </Text>
  );
};
