export const CustomIcon = (props: any) => {
  const { x, y, payload } = props;

  if (payload.value === 2 || payload.value === 21)
    return (
      <>
        <g transform={`translate(${x},${y})`}>
          <text x={-4} y={0} dy={12} textAnchor="end" fill="#666" fontSize={12}>
            {payload.value}h
          </text>
        </g>
        <svg
          x={x}
          y={y}
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.87028 13.7849H6.78987C2.98753 13.716 -0.0566376 10.5799 0.000799608 6.77758C0.0697242 3.02119 3.13687 0 6.88177 0C6.91623 0 6.96218 0 6.99665 0C7.31829 0 7.57102 0.264211 7.55953 0.585859C7.55953 0.907507 7.26086 1.16023 6.97367 1.14874C6.93921 1.14874 6.90475 1.14874 6.88177 1.14874C3.76868 1.14874 1.20698 3.66449 1.14954 6.80056C1.10359 9.9596 3.64232 12.5787 6.80136 12.6362C7.59399 12.6362 8.37514 12.4983 9.08736 12.1997C6.22699 11.3151 4.19371 8.62706 4.23966 5.51397C4.25115 4.56051 4.45792 3.64152 4.85998 2.77996C4.98634 2.49277 5.33097 2.36641 5.61815 2.49277C5.90534 2.61913 6.0317 2.96376 5.90534 3.25094C5.58369 3.97465 5.41138 4.73282 5.38841 5.53694C5.34246 8.5926 7.68589 11.1428 10.7415 11.3611C10.9828 11.3726 11.1781 11.5449 11.247 11.7631C11.3159 11.9814 11.247 12.2341 11.0517 12.3835C9.8685 13.291 8.38662 13.7964 6.8588 13.7964L6.87028 13.7849Z"
            fill="#50545F"
          />
        </svg>
      </>
    );

  if (payload.value === 7 || payload.value === 17)
    return (
      <>
        <g transform={`translate(${x},${y})`}>
          <text x={-4} y={0} dy={12} textAnchor="end" fill="#666" fontSize={12}>
            {payload.value}h
          </text>
        </g>

        <svg
          x={x}
          y={y}
          width="18"
          height="9"
          viewBox="0 0 18 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_906_88246)">
            <path
              d="M5.00881 3.77996C5.1122 3.95227 5.296 4.04417 5.49129 4.04417C5.59467 4.04417 5.69806 4.00971 5.80145 3.95227C6.06566 3.77996 6.14607 3.43534 5.97376 3.17113L4.39998 0.724304C4.22767 0.460093 3.88305 0.379681 3.61884 0.551992C3.35462 0.724304 3.27421 1.06893 3.44652 1.33314L5.00881 3.77996Z"
              fill="#ED8C00"
            />
            <path
              d="M16.6333 6.65137H13.7269C13.4168 6.65137 13.1641 6.90409 13.1641 7.21425C13.1641 7.52441 13.4168 7.78862 13.7269 7.78862H16.6333C16.9434 7.78862 17.1962 7.5359 17.1962 7.22574C17.1962 6.91558 16.9434 6.65137 16.6333 6.65137Z"
              fill="#ED8C00"
            />
            <path
              d="M3.4692 6.65114H0.574372C0.252723 6.63965 0 6.89237 0 7.20253C0 7.51269 0.252723 7.7769 0.562884 7.7769H3.4692C3.77937 7.7769 4.03209 7.52418 4.03209 7.21402C4.03209 6.90386 3.77937 6.63965 3.4692 6.63965V6.65114Z"
              fill="#ED8C00"
            />
            <path
              d="M10.6972 3.49211C10.7891 3.54955 10.8925 3.58401 11.0074 3.58401C11.1912 3.58401 11.3749 3.49211 11.4898 3.3198L13.0521 0.872976C13.2244 0.608765 13.144 0.252655 12.8798 0.091831C12.6156 -0.068993 12.2595 -6.84522e-05 12.0987 0.264142L10.5364 2.71097C10.3641 2.97518 10.4445 3.33129 10.7087 3.49211H10.6972Z"
              fill="#ED8C00"
            />
            <path
              d="M11.6358 8.29415C11.6358 8.29415 11.6358 8.3401 11.6244 8.36307C11.5554 8.60431 11.3372 8.77662 11.073 8.77662C10.7628 8.77662 10.4986 8.52389 10.4986 8.20225C10.4986 8.16778 10.4986 8.13332 10.4986 8.09886C10.4986 8.08737 10.4986 8.0644 10.4986 8.05291C10.5675 7.80019 10.579 7.52449 10.5445 7.24879C10.3952 6.26087 9.54513 5.56014 8.5687 5.56014C8.46531 5.56014 8.36193 5.56014 8.25854 5.58311C7.73012 5.66352 7.27062 5.95071 6.96046 6.37575C6.6503 6.80078 6.51245 7.3292 6.59286 7.85762C6.59286 7.91506 6.61584 7.98399 6.62733 8.04142C6.62733 8.05291 6.62733 8.07588 6.63881 8.08737C6.63881 8.12183 6.63881 8.1563 6.63881 8.19076C6.63881 8.50092 6.38609 8.76513 6.06444 8.76513C5.81172 8.76513 5.59346 8.59282 5.52453 8.36307C5.52453 8.36307 5.52453 8.35158 5.52453 8.3401C5.52453 8.32861 5.52453 8.30563 5.51304 8.29415C5.49007 8.21373 5.47858 8.12183 5.4671 8.04142C5.34073 7.21433 5.53602 6.38723 6.02998 5.70947C6.52394 5.03172 7.24765 4.59519 8.07474 4.46883C9.78637 4.20462 11.3831 5.37634 11.6473 7.08797C11.7163 7.50151 11.6933 7.90357 11.6014 8.29415H11.6358Z"
              fill="#ED8C00"
            />
          </g>
          <defs>
            <clipPath id="clip0_906_88246">
              <rect width="17.1967" height="8.7764" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </>
    );

  if (payload.value === 12)
    return (
      <>
        <g transform={`translate(${x},${y})`}>
          <text x={-4} y={0} dy={12} textAnchor="end" fill="#666" fontSize={12}>
            {payload.value}h
          </text>
        </g>

        <svg x={x} y={y} width="18" height="16" viewBox="0 0 18 16" fill="none">
          <g clipPath="url(#clip0_906_88228)">
            <path
              d="M11.751 7.1226C11.4868 5.41098 9.87852 4.22777 8.16689 4.49198C7.33979 4.61834 6.6046 5.06635 6.11064 5.74411C5.61668 6.42187 5.40991 7.24896 5.53627 8.08755C5.66263 8.91464 6.11064 9.64984 6.7884 10.1438C7.32831 10.5459 7.9716 10.7526 8.63787 10.7526C8.7987 10.7526 8.95952 10.7411 9.13183 10.7182C10.8435 10.454 12.0267 8.84572 11.7625 7.13409L11.751 7.1226ZM8.94803 9.58091C8.41961 9.66133 7.89119 9.53496 7.45467 9.21332C7.01815 8.89167 6.74245 8.43217 6.66204 7.90375C6.58162 7.37533 6.70799 6.8469 7.02963 6.41038C7.35128 5.97386 7.81078 5.69816 8.3392 5.61775C8.44259 5.60626 8.54597 5.59477 8.64936 5.59477C9.61431 5.59477 10.4759 6.307 10.6252 7.29491C10.7975 8.38622 10.0393 9.4086 8.94803 9.58091Z"
              fill="#ED8C00"
            />
            <path
              d="M5.03202 3.79145C5.13541 3.96376 5.33069 4.05566 5.51449 4.05566C5.61788 4.05566 5.72127 4.0212 5.82465 3.96376C6.08886 3.79145 6.16928 3.43534 5.99696 3.17113L4.42319 0.724304C4.25088 0.460093 3.89476 0.379681 3.63055 0.551992C3.36634 0.724304 3.28593 1.08041 3.45824 1.34463L5.03202 3.80294V3.79145Z"
              fill="#ED8C00"
            />
            <path
              d="M12.2474 11.4069C12.0751 11.1427 11.719 11.0623 11.4548 11.2346C11.1906 11.4069 11.1101 11.763 11.2825 12.0272L12.8562 14.4856C12.9596 14.6579 13.1549 14.7498 13.3387 14.7498C13.4421 14.7498 13.5455 14.7153 13.6489 14.6579C13.9131 14.4856 13.9935 14.1294 13.8212 13.8652L12.2474 11.4069Z"
              fill="#ED8C00"
            />
            <path
              d="M0.562884 8.52337L3.48069 8.54635C3.79085 8.54635 4.04358 8.29363 4.05506 7.98347C4.05506 7.6733 3.80234 7.40909 3.49218 7.40909L0.574372 7.38612C0.252723 7.37463 0 7.62736 0 7.93752C0 8.24768 0.252723 8.51189 0.562884 8.51189V8.52337Z"
              fill="#ED8C00"
            />
            <path
              d="M16.7148 6.68508L13.797 6.66211C13.4869 6.66211 13.2341 6.91483 13.2227 7.22499C13.2227 7.53515 13.4754 7.79936 13.7855 7.79936L16.7033 7.82234C17.0135 7.82234 17.2662 7.56962 17.2777 7.25946C17.2777 6.9493 17.025 6.68508 16.7148 6.68508Z"
              fill="#ED8C00"
            />
            <path
              d="M4.36493 15.1178C4.45683 15.1753 4.5717 15.2097 4.67509 15.2097C4.85889 15.2097 5.05417 15.1178 5.15756 14.9455L6.71985 12.4872C6.89216 12.223 6.81175 11.8669 6.54754 11.6946C6.28333 11.5223 5.92722 11.6027 5.75491 11.8669L4.19262 14.3252C4.02031 14.5894 4.10072 14.9455 4.36493 15.1178Z"
              fill="#ED8C00"
            />
            <path
              d="M10.7516 3.51529C10.8435 3.57272 10.9584 3.60719 11.0618 3.60719C11.2456 3.60719 11.4409 3.51529 11.5443 3.34297L13.1066 0.884664C13.2789 0.620453 13.1985 0.264343 12.9343 0.0920315C12.67 -0.08028 12.3139 0.000132048 12.1416 0.264343L10.5793 2.72265C10.407 2.98686 10.4874 3.34297 10.7516 3.51529Z"
              fill="#ED8C00"
            />
          </g>
          <defs>
            <clipPath id="clip0_906_88228">
              <rect width="17.2886" height="15.2094" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </>
    );

  return null;
};
