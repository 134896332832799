//Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";
import { IPretictedBills } from "../../../interfaces/renoglobaleInterface";

//Local interface
interface IProps {
  userToken: string;
  study_id?: string | null;
}

export const useGetPredictedBills = () => {
  const { isLoading, axiosGetRequest } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getPredictedBills = async ({ userToken, study_id }: IProps) => {
    setErrorMessage("");

    const response = await axiosGetRequest<IPretictedBills>(
      `${process.env.REACT_APP_EERO_URL}/oree2/rg/getpredictedbills/${study_id}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) return response;

    setErrorMessage("No datas available");
    return null;
  };

  return {
    getPredictedBills,
    isLoading,
    errorMessage: errorMessage,
  };
};
