import { Page, Text, View, Image } from "@react-pdf/renderer";
import moment from "moment";
import { DSColors } from "@web/shared/src/styles/variables";

// Components
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfEtudeHeaderRG } from "./PdfEtudeHeaderRG";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";

// Interfaces import
import { IPerformanceRG } from "../../interfaces/renoglobaleInterface";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  documentId: string;

  performanceRGData: IPerformanceRG | null | undefined;
  imageAutoconsommation: string | null;
  imageAutoproduction: string | null;
  isDemoVersion: boolean;
}

export const PdfEtudeRGPagePerformancePart2 = ({
  firstNameInputValue,
  lastNameInputValue,
  documentId,
  performanceRGData,
  imageAutoconsommation,
  imageAutoproduction,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Page size="A4" style={styles.page}>
      <PdfEtudeHeaderRG />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <Text
          style={{
            fontSize: 8,
            marginBottom: 10,
          }}
        >
          Découvrez les performances de votre projet.
        </Text>

        {/* KPIs */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: 30,
            marginBottom: 30,
          }}
        >
          <View
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #D3D4D7",
              padding: 15,
              borderRadius: 5,
            }}
          >
            {imageAutoconsommation ? (
              <Image src={imageAutoconsommation} style={{ width: 160 }} />
            ) : null}
            <Text
              style={{
                fontSize: 12,
                fontFamily: "DMSansBold",
                marginBottom: 10,
              }}
            >
              Taux d'autoconsommation
            </Text>
            <View>
              <Text style={{ fontSize: 8 }}>
                {performanceRGData?.selfconsumption_rate &&
                  Math.round(performanceRGData?.selfconsumption_rate * 100)}
                % de votre production solaire est utilisée pour votre propre
                consommation électrique. Le reste de votre production{" "}
                {performanceRGData?.selfconsumption_rate
                  ? `(${Math.round(
                      100 - performanceRGData?.selfconsumption_rate * 100
                    )}%)`
                  : ""}{" "}
                est injecté dans le réseau électrique.
              </Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #D3D4D7",
              padding: 15,
              borderRadius: 5,
            }}
          >
            {imageAutoproduction ? (
              <Image src={imageAutoproduction} style={{ width: 160 }} />
            ) : null}
            <Text
              style={{
                fontSize: 12,
                fontFamily: "DMSansBold",
                marginBottom: 15,
              }}
            >
              Taux d'autoproduction
            </Text>
            <View>
              <Text style={{ fontSize: 8 }}>
                {performanceRGData?.selfproduction_rate &&
                  Math.round(
                    Math.round(performanceRGData.selfproduction_rate * 100)
                  )}
                % de votre consommation est couverte par votre production
                solaire. Le reste de votre consommation
                {performanceRGData?.selfproduction_rate
                  ? ` (${Math.round(
                      100 - performanceRGData.selfproduction_rate * 100
                    )}%)`
                  : ""}{" "}
                utilise l'électricité du réseau électrique.
              </Text>
            </View>
          </View>
        </View>

        {/* Other KPIs */}

        <View style={{ marginBottom: 10 }}>
          <Text
            style={{ fontSize: 13, fontFamily: "DMSansBold", marginBottom: 5 }}
          >
            Consommation annuelle du logement :{" "}
            {performanceRGData?.annual_electric_consumption_kWh} kWh
          </Text>
          <Text style={{ fontSize: 7, color: DSColors.StableGrey }}>
            Besoin de votre logement en électricité.
          </Text>
        </View>
        <View style={{ marginBottom: 10 }}>
          <Text
            style={{ fontSize: 13, fontFamily: "DMSansBold", marginBottom: 5 }}
          >
            Production solaire annuelle :{" "}
            {performanceRGData?.annual_electric_production_kWh} kWh
          </Text>
          <Text style={{ fontSize: 7, color: DSColors.StableGrey }}>
            Électricité produite annuellement grâce à votre installation.
          </Text>
        </View>

        <View style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
          <View
            style={{ display: "flex", flexDirection: "column", maxWidth: 200 }}
          >
            <Text style={{ fontSize: 10, color: DSColors.StableGrey }}>
              Consommation solaire
            </Text>
            <Text
              style={{
                fontSize: 13,
                color: DSColors.StableGrey,
                marginTop: 10,
                marginBottom: 10,
                fontFamily: "DMSansBold",
              }}
            >
              {performanceRGData?.solar_consumption} kWh
            </Text>
            <Text style={{ fontSize: 7, color: DSColors.StableGrey }}>
              Besoin de votre logement en électricité couvert par votre
              installation.
            </Text>
          </View>
          <View
            style={{
              borderLeft: "2px solid #D3D4D7",
              marginRight: 20,
              marginLeft: 20,
            }}
          />
          <View
            style={{ display: "flex", flexDirection: "column", maxWidth: 200 }}
          >
            <Text style={{ fontSize: 10, color: DSColors.StableGrey }}>
              Consommation réseau
            </Text>
            <Text
              style={{
                fontSize: 13,
                color: DSColors.StableGrey,
                marginTop: 10,
                marginBottom: 10,
                fontFamily: "DMSansBold",
              }}
            >
              {performanceRGData?.network_consumption} kWh
            </Text>
            <Text style={{ fontSize: 7, color: DSColors.StableGrey }}>
              Besoins de votre logement en électricité, couvert par le réseau.
            </Text>
          </View>
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType="rénovation globale"
      />
    </Page>
  );
};
