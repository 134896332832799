// Style import
import "./potentielSolaireRG.scss";

//Libraries import
import { useEffect, useRef } from "react";

// Interfaces import
import {
  IStudyInformation,
  IUserAuthInfo,
} from "../../interfaces/generalInterfaces";

// Redux import
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { setSolarPotentialStepsStatusAction } from "../../redux/appActions";

// Component import
import { PotentielSolaireKOForm } from "../../containers/PotentielSolaireKOForm/PotentielSolaireKOForm";
import { MonPotentielSolaireDesktop } from "../../containers/MonPotentielSolaireContainers/MonPotentielSolaireDesktop/MonPotentielSolaireDesktop";
import { Layout } from "../Layout/Layout";
import { PotentialSolarLoadingComponent } from "../../containers/PotentialSolarLoadingScreen/PotentialSolarLoadingComponent/PotentialSolarLoadingComponent";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

// Services import
import { useSavePotentielSolaire } from "../../services/PotentielSolaire/useSavePotentielSolaire";

// Interfaces import
import { INavigateData } from "../../services/useNavigation/useNavigation";

interface IProps {
  studyInformation: IStudyInformation;
  userAuthInfo: IUserAuthInfo;
  goNext: (data?: INavigateData<any>) => void;
}

export const PotentielSolaireRG = ({
  studyInformation,
  userAuthInfo,
  goNext,
}: IProps) => {
  const { solarPotentialStepsStatus } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const {
    savePotentielSolaire,
    isLoading: isLoadingSavePotentialSolar,
    errorMessage: errorMessageSaveSolarPotential,
  } = useSavePotentielSolaire();
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  const handlePotentialSolarLoadingComponentButtonCLick = () => {
    dispatch(
      setSolarPotentialStepsStatusAction({
        solarPotentialStepsStatus: {
          isLoadingScreenDisplayed: false,
        },
      })
    );
  };

  useEffect(() => {
    studyInformation.solar_potential_ko &&
      dispatch(
        setSolarPotentialStepsStatusAction({
          solarPotentialStepsStatus: {
            isLoadingScreenDisplayed: false,
            isSolarPotentialKOForm: true,
            isCheckSolarPotentialAvailabilityLoading: false,
            isGetSolarPotentialLoading: false,
          },
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="MonPotentielSolaireRGDesktop">
      <div className={"MonPotentielSolaireRGDesktop__Title"}>
        Potentiel solaire estimé du logement
      </div>

      {!studyInformation.solar_potential_ko &&
      solarPotentialStepsStatus.isLoadingScreenDisplayed ? (
        <div className="MonPotentielSolaireRGDesktop__LoaderContainer">
          <PotentialSolarLoadingComponent />

          {!solarPotentialStepsStatus.isCheckSolarPotentialAvailabilityLoading && (
            <div className="MonPotentielSolaireRGDesktop__ButtonContainer">
              <CTAButton
                onClick={handlePotentialSolarLoadingComponentButtonCLick}
                name={
                  solarPotentialStepsStatus.isSolarPotentialKOForm
                    ? "Remplir le formulaire"
                    : "Afficher mon potentiel solaire"
                }
              />
            </div>
          )}
        </div>
      ) : solarPotentialStepsStatus.isSolarPotentialKOForm ? (
        <PotentielSolaireKOForm
          goNext={goNext}
          buttonSubmitRef={buttonSubmitRef}
          userToken={userAuthInfo.userToken}
          savePotentielSolaire={savePotentielSolaire}
          isLoadingSavePotentialSolar={isLoadingSavePotentialSolar}
          errorMessage={errorMessageSaveSolarPotential}
        />
      ) : (
        <>
          <div className="MonPotentielSolaire__Desktop">
            <Layout>
              <MonPotentielSolaireDesktop studyInformation={studyInformation} />
            </Layout>
          </div>
        </>
      )}
    </div>
  );
};
