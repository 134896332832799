//Libraries import
import { useState } from "react";
import { useAxiosPost } from "../../useAxiosPost";
import {
  IProjectKPI,
  IDataProduct,
} from "../../../interfaces/renoglobaleInterface";

//Local interface
interface LocalProps {
  userToken: string | null;
  study_id?: string | null;
  building_id: string | null;
  cartProducts: IDataProduct[];
}

//Local interface

export const useGetProjectKPI = () => {
  const { isLoading, axiosPostRequest } = useAxiosPost();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getProjectKPI = async ({
    userToken,
    study_id,
    cartProducts,
    building_id,
  }: LocalProps) => {
    setErrorMessage("");

    const response = await axiosPostRequest<IProjectKPI>(
      `${process.env.REACT_APP_EERO_URL}/oree2/rg/projectKPI/${study_id}?building_id=${building_id}`,
      { cartProducts },
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response && response.status !== 200) {
      setErrorMessage("Save cart products has failed.");
    }

    return response && response.data;
  };

  return {
    getProjectKPI,
    isLoading,
    errorMessage: errorMessage,
  };
};
