import React from "react";
import { IGetStudiesResponse } from "../../../interfaces/generalInterfaces";
import "./SingleEtudeLine.scss";
import moment from "moment";

// image import
import Chevron from "@web/shared/dist/assets/images/DesignSystemIcons/chevron-right.svg";
import { useNavigate } from "react-router-dom";

interface IProps {
  singleEtudeData: IGetStudiesResponse;
}

export const SingleEtudeLine = ({ singleEtudeData }: IProps) => {
  const navigate = useNavigate();

  const studyTypeDictionnary: { [keys: string]: string } = {
    "renovation-globale": "RG",
    photovoltaique: "PV",
  };

  return (
    <div
      className="singleEtude"
      onClick={() => {
        navigate(`/mes-etudes/${singleEtudeData.study_id}`);
      }}
    >
      <div className="singleEtude__Container">
        <div className="singleEtude__Content">
          <p className="flex-item size-0" style={{ maxWidth: "171px" }}>
            {studyTypeDictionnary?.[singleEtudeData.study_type || ""] || "NC"}
          </p>
          <p className="flex-item size-1" style={{ maxWidth: "171px" }}>
            {singleEtudeData.lastname?.toLocaleUpperCase() +
              " " +
              singleEtudeData.firstname}
          </p>
          <p className="flex-item size-2">{singleEtudeData.zipcode}</p>
          <p className="flex-item size-3">
            {singleEtudeData.address?.split(",")[0]}
          </p>
          <p className="flex-item size-4">{singleEtudeData.devis_count}</p>
          <p className="flex-item size-5">{singleEtudeData.bdc_count}</p>
          {/* "Montant dernier BD"C not retrieved yet */}
          <p className="flex-item size-6">{""}</p>
          <p className="flex-item size-7">
            {singleEtudeData.status &&
              moment(singleEtudeData.created_at).format("DD/MM/YYYY")}
          </p>
          <p className="flex-item size-8 chevron-right">
            {singleEtudeData.status &&
              moment(singleEtudeData.modified_at).format("DD/MM/YYYY")}
            <img src={Chevron} alt="chevron" />
          </p>
        </div>
      </div>
    </div>
  );
};
