import { Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  title: {
    fontFamily: "DMSansBold",
    fontSize: 9,
  },
  container: {
    marginLeft: 20,
    marginBottom: 10,
  },
});

interface IProps {
  title: string;
  description: JSX.Element[];
}

export const PdfItemDetail = ({ title, description }: IProps) => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <View
        style={{
          marginLeft: 10,
        }}
      >
        {description.map((elem, _key) => {
          return <View key={s4()}>{elem}</View>;
        })}
      </View>
    </View>
  );
};
