interface Props {
  interestRate: number;
  borrowedCapital: number;
  fundingPeriod: number;
}

// See https://www.inc-conso.fr/content/banque/comment-sont-calculees-les-mensualites-de-votre-emprunt
// https://www.lafinancepourtous.com/outils/calculateurs/calculateur-de-credit-immobilier/

export const calculateMonthlyPayment = ({
  interestRate,
  borrowedCapital,
  fundingPeriod,
}: Props) => {
  if (interestRate === 0) {
    return Math.round((borrowedCapital / (fundingPeriod * 12)) * 100) / 100;
  }

  const interestRateMonthly = Math.pow(1 + interestRate / 100, 1 / 12) - 1;

  const a = Math.pow(1 + interestRateMonthly, fundingPeriod * 12);

  const mensuality = (borrowedCapital * interestRateMonthly * a) / (a - 1);

  return Math.round(mensuality * 100) / 100;
};

export const calculateAutofinancing = ({
  totalCashEffort,
  totalAmountToPay,
}: {
  totalCashEffort: number;
  totalAmountToPay: number;
}) => {
  if (totalCashEffort === 0) {
    return 100;
  }

  const autoFiancancing =
    100 - Math.round((totalCashEffort / totalAmountToPay) * 100);

  if (autoFiancancing >= 100) {
    return 99;
  }

  if (autoFiancancing <= 0) {
    return 0;
  }

  return autoFiancancing;
};
