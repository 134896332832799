import "./HeatLosses.scss";

// Components import
import { Layout } from "../Layout/Layout";
import { HeatLossesSchema } from "../HeatLossesSchema/HeatLossesSchema";

// Interfaces import
import { IHeatLoss } from "../../interfaces/renoglobaleInterface";

interface IProps {
  heatLossesData: IHeatLoss[];
  errorMessage: string;
  isLoading: boolean;
}

export const HeatLosses = ({
  heatLossesData,
  errorMessage,
  isLoading,
}: IProps) => {
  return (
    <div className="HeatLosses">
      <Layout>
        <div className="HeatLosses__container">
          <section className="HeatLosses__title">
            <h3>Déperditions thermiques</h3>
          </section>

          <>
            <section className="HeatLosses__article">
              <article>
                <p>
                  Les déperditions thermiques sont les pertes de chaleur que
                  subit votre logement notamment en raison d'une isolation
                  insuffisante. Elles nuisent à votre confort et augmentent vos
                  dépenses d'énergie.
                </p>
              </article>
            </section>
            <HeatLossesSchema
              heatLossesData={heatLossesData}
              errorMessage={errorMessage}
              isLoading={isLoading}
            />
          </>
        </div>
      </Layout>
    </div>
  );
};
