import styled from "styled-components";
import { FC } from "react";

// Styles import
import { Dot } from "../Dot/Dot";

// Local interface declaration
export interface DotsProps {
  pagination?: boolean[];
}

const Pagination = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  gap: 0.3rem;
`;

export const Dots: FC<DotsProps> = ({ pagination = [] }) => {
  return (
    <Pagination>
      {pagination.map((boolean, index) => (
        <Dot isActive={boolean} key={index} />
      ))}
    </Pagination>
  );
};
