//Libraries import
import { useAxiosGet } from "../useAxiosGet";

//local interface
interface LocalProps {
  userToken: string | null;
  study_id: string | null;
  last_page_visited: string;
}

export const useSaveLastPageVisited = () => {
  const { isLoading, axiosGetRequest } = useAxiosGet();

  const saveLastPageVisited = async ({
    userToken,
    study_id,
    last_page_visited,
  }: LocalProps) => {
    await axiosGetRequest(
      `${process.env.REACT_APP_EERO_URL}/oree2/saveLastPageVisited/${study_id}?last_page_visited=${last_page_visited}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    return;
  };

  return {
    saveLastPageVisited,
    isLoading,
  };
};
