import { Image, View } from "@react-pdf/renderer";

interface Props {
  image: string;
}
export const PdfContainerGraphRentabilite = ({ image }: Props) => {
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginBottom: 20,
      }}
    >
      <Image
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          objectFit: "contain",
          width: "100%",
          height: "auto",
        }}
        src={image}
      />
    </View>
  );
};
