import { useState } from "react";

// Components import
import { DetailOptionTabs } from "../../../components/MonInstallationOption/DetailOptionTabs/DetailOptionTabs";
import { SubHeaderOptionChosen } from "../../../components/SubHeaderOptionChosen/SubHeaderOptionChosen";
import { DimensionnementComponent } from "../../../containers/OptionChosen/DimensionnementComponent/DimensionnementComponent";
import { ImpactComponent } from "../../../containers/OptionChosen/ImpactComponent/ImpactComponent";
import { PerformanceComponent } from "../../../containers/OptionChosen/PerformanceComponent/PerformanceComponent";
import { ProfitabilityComponent } from "../../../containers/OptionChosen/ProfitabilityComponent/ProfitabilityComponent";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { useSaveScenario } from "../../../services/OptionChosen/useSaveScenario";
import { useSaveLastPageVisited } from "../../../services/Oree2/useSaveLastPageVisited";
import { useSaveFinancingInformation } from "../../../services/RenovationGlobale/MonProjet/useSaveFinancingInformation";

// Interfaces import
import { ProfitabilityCashFlow } from "../../../components/ProfitabilityComponents/ProfitabilityCashFlowContainer/ProfitabilityCashFlow";
import {
  HelpsOptions,
  IGoTo,
  LoanSimulationResult,
} from "../../../interfaces/generalInterfaces";

// Interfaces import
import { INavigateData } from "../../../services/useNavigation/useNavigation";

// Data import
import { interestRateOptions } from "@web/shared/dist/datas/dataHypotethis";

// Local interface declaration
interface IProps {
  goNext: (data?: INavigateData<any>) => void;
  goBack?: () => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
}

interface IOption {
  label: string | null;
  value: string | null;
}

export const MonInstallationDetailFormule = ({ goNext, goBack }: IProps) => {
  const { saveFinancingInformation } = useSaveFinancingInformation();

  const { studyInformation, userAuthInfo, scenarioChosen } = useAppSelector(
    (state) => state
  );
  const { saveScenario } = useSaveScenario();
  const { saveLastPageVisited } = useSaveLastPageVisited();

  const [isTabOpen, setIsTabOpen] = useState<boolean[]>([
    true,
    false,
    false,
    false,
    false,
  ]);

  // Financing option state
  const financingOptionsList: IOption[] = [
    {
      label: "Organisme de financement",
      value: "Organisme de financement",
    },
    { label: "Comptant", value: "Comptant" },
  ];
  const [financingOptions, setfinancingOptions] = useState<IOption>(
    financingOptionsList.find(
      (financingOption) =>
        financingOption.value === studyInformation.type_of_payment
    ) || financingOptionsList[0]
  );
  const [fundingPeriod, setFundingPeriod] = useState<number>(
    studyInformation.loan_duration || 15
  );
  const [personalContributionAmount, setPersonalContributionAmount] =
    useState<number>(studyInformation.deposit || 0);
  const [isDeferralOption, setIsDeferralOption] = useState(
    studyInformation.is_deferral_option || false
  );
  const [interestRate, setInterestRate] = useState<string>(
    studyInformation.interest_rate || interestRateOptions[0]
  );

  const [hasBorrowerInsurance, setHasBorrowerInsurance] = useState(
    studyInformation.has_borrower_insurance || false
  );

  const [hasCoBorrower, setHasCoBorrower] = useState(
    studyInformation.has_co_borrower || false
  );

  const [helpsOptions, setHelpsOptions] = useState<HelpsOptions>(
    studyInformation.helps_options || {
      isHelpsDisplayed: false,
      isCEEHelpsDeducted: false,
      isMPRHelpsDeducted: false,
    }
  );

  const [loanSimulationResult, setLoanSimulationResult] =
    useState<LoanSimulationResult>({
      monthlyPayment: 0,
      TAEG: 0,
      tauxDebiteurFixe: 0,
      loanAmount: 0,
      deferralDurationMonth: 0,
    });

  const onTabClick = (index: number) => {
    setIsTabOpen((prevState) => {
      const newState = prevState.map(() => false);
      newState[index] = !prevState[index];
      return newState;
    });
  };

  const onClickChooseThisScenario = () => {
    goNext && goNext();
    saveFinancingInformation({
      deposit: personalContributionAmount,
      type_of_payment: financingOptions.value || "",
      interest_rate: interestRate,
      is_deferral_option: isDeferralOption,
      loan_duration: fundingPeriod,
      hasBorrowerInsurance,
      hasCoBorrower: hasCoBorrower,
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id || null,
      helps_options: helpsOptions,
    });
    saveLastPageVisited({
      last_page_visited: "my_scenario",
      study_id: studyInformation?.study_id || null,
      userToken: userAuthInfo.userToken,
    });
    saveScenario({
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id,
      scenario: scenarioChosen,
    });
  };

  return (
    <>
      <SubHeaderOptionChosen
        title={`${scenarioChosen?.formule_name || "Formule personnalisable"} ${
          scenarioChosen?.power_needed && scenarioChosen?.power_needed / 1000
        } kWc `}
        buttonGoBackName="Mes installation"
        buttonName="Selectionner cette formule"
        goNext={onClickChooseThisScenario}
        goBack={goBack}
        isNextButtonVisible={true}
        isRecommended={scenarioChosen?.recommended_calepinage}
        imgSubHeader={
          "data:image/png;base64, " + scenarioChosen?.calepinage_image
        }
        subTitle={
          scenarioChosen?.pitch || "Libérez-vous du réseau grâce au solaire !"
        }
      />
      <DetailOptionTabs isTabOpen={isTabOpen} onTabClick={onTabClick} />

      {isTabOpen[0] && (
        <DimensionnementComponent
          building_id={studyInformation.building_id}
          household_id={studyInformation.household_id || null}
          scenarioChosen={scenarioChosen}
        />
      )}
      {isTabOpen[1] && (
        <PerformanceComponent
          building_id={studyInformation.building_id}
          household_id={studyInformation.household_id || null}
        />
      )}
      {isTabOpen[2] && (
        <ProfitabilityComponent
          building_id={studyInformation.building_id}
          household_id={studyInformation.household_id || null}
        />
      )}
      {isTabOpen[3] && (
        <ImpactComponent
          building_id={studyInformation.building_id}
          household_id={studyInformation.household_id || null}
        />
      )}
      {isTabOpen[4] && (
        <ProfitabilityCashFlow
          totalPrice={scenarioChosen.total_cost_without_help}
          building_id={studyInformation.building_id}
          household_id={studyInformation.household_id || null}
          fundingPeriod={fundingPeriod}
          setFundingPeriod={setFundingPeriod}
          personalContributionAmount={personalContributionAmount}
          setPersonalContributionAmount={setPersonalContributionAmount}
          isDeferralOption={isDeferralOption}
          setIsDeferralOption={setIsDeferralOption}
          interestRate={interestRate}
          setInterestRate={setInterestRate}
          hasBorrowerInsurance={hasBorrowerInsurance}
          setHasBorrowerInsurance={setHasBorrowerInsurance}
          hasCoBorrower={hasCoBorrower}
          setHasCoBorrower={setHasCoBorrower}
          loanSimulationResult={loanSimulationResult}
          setLoanSimulationResult={setLoanSimulationResult}
          financingOptionsList={financingOptionsList}
          financingOptions={financingOptions}
          setfinancingOptions={setfinancingOptions}
          helpsOptions={helpsOptions}
          setHelpsOptions={setHelpsOptions}
          scenarioChosen={scenarioChosen}
        />
      )}
    </>
  );
};
