//Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";
import { IDataProduct } from "../../../interfaces/renoglobaleInterface";

//Local interface
interface LocalProps {
  userToken: string | null;
  study_id?: string | null;
}

export const useGetProductsSavedInCart = () => {
  const { isLoading, axiosGetRequest } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getProductsSavedInCart = async ({
    userToken,
    study_id,
  }: LocalProps) => {
    setErrorMessage("");

    const response = await axiosGetRequest<{
      cartProducts: IDataProduct[] | null;
    }>(
      `${process.env.REACT_APP_EERO_URL}/oree2/rg/getProductsSavedInCart/${study_id}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response && response.cartProducts) {
      return response.cartProducts;
    }
    return null;
  };

  return {
    getProductsSavedInCart,
    isLoading,
    errorMessage: errorMessage,
  };
};
