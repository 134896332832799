// Interfaces import
import { ISageProduct } from "../../interfaces/generalInterfaces";

// Local interface declaration
interface IOption {
  label: string | null;
  value: string | null;
}

interface IGetProductCharacteristicsOptions {
  sageProducts: ISageProduct[];
  productCharacteristic: keyof ISageProduct;
}

/**
 * From a list of Sage products and a product characteristic,
 * retrieve all the values possible from the Sage products list for this product characteristic
 * and format them to match the dropdowns format.
 * Ex : retrieve all the Sage products's possible brands.
 *
 * @param {ISageProduct[]} sageProducts - The array of Sage product. Each product contains n keys (product characteristics) which can take different values.
 * @param {keyof ISageProduct} productCharacteristic - The product key from which we want to display all the values possible.
 *
 * @returns {IOption[]} - The formatted dropdown options.
 */
export const formatDropdownOptionsFromSageProducts = ({
  sageProducts,
  productCharacteristic,
}: IGetProductCharacteristicsOptions) => {
  if (sageProducts.length === 0) {
    return [];
  }

  const optionsFormattedForDropdown: IOption[] = sageProducts
    .filter((product) => !!product[productCharacteristic])
    .map((product) => {
      const value = product[productCharacteristic] || "";
      return {
        label: value.toString(),
        value: value.toString(),
      };
    });

  // Remove duplicate
  const optionsWithoutDuplicate = optionsFormattedForDropdown
    .reduce((acc: IOption[], curr: IOption) => {
      if (
        acc.findIndex(
          (elem) =>
            elem.value?.toLocaleLowerCase().trim() ===
            curr.value?.toLocaleLowerCase().trim()
        ) === -1
      ) {
        acc.push(curr);
        return acc;
      }
      return acc;
    }, []) // Sort in alphabetical order
    .sort((productA, productB) => {
      if (Number(productA?.value) && Number(productB?.value)) {
        return (Number(productA?.value) ?? 0) - (Number(productB?.value) ?? 0);
      }
      if (
        (productB?.value?.toLowerCase() ?? "") >
        (productA?.value?.toLowerCase() ?? "")
      ) {
        return -1;
      }
      return 1;
    });

  return optionsWithoutDuplicate;
};
