//Style import
import "./oree2Home.scss";

//Library import
import { Link } from "react-router-dom";

//Img import
import { FooterHills } from "../../components/FooterHills/FooterHills";

//components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { NavigationBarOutsideStudy } from "../../components/NavigationBarOutsideStudy/NavigationBarOutsideStudy";
import { useAppSelector } from "../../redux/store/hook";
import { AUTHORIZATION_RIGHTS_LIST } from "../../data/authorization_rights_list";
import { useEffect } from "react";
import { useGetCommercialInformation } from "../../services/useGetCommercialInformation";

// data imports
import { oree2HomeData, Oree2HomeDataDetails } from "../../data/oree2HomeData";

export const Oree2Home = () => {
  const { getCommercialInformation } = useGetCommercialInformation();
  const { userAuthInfo, commercialUserInformation } = useAppSelector(
    (states) => states
  );
  useEffect(() => {
    if (
      userAuthInfo.userId &&
      userAuthInfo.userToken &&
      !commercialUserInformation.authorization_rights
    ) {
      getCommercialInformation({
        userId: userAuthInfo.userId,
        userToken: userAuthInfo.userToken,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Checks if the user is authorized to access a specific button poll.
   * @param buttonPoll - The button poll to check authorization for.
   * @returns A boolean indicating whether the user is authorized.
   */
  const isUserAuthorized = (buttonPoll: Oree2HomeDataDetails) => {
    return commercialUserInformation.authorization_rights?.includes(
      AUTHORIZATION_RIGHTS_LIST[buttonPoll.authorizationName]
    );
  };

  return (
    <div className="Oree2Home">
      <NavigationBarOutsideStudy title={"Nouvelle étude"} />

      <div className="Oree2Home__ButtonsContainer">
        {oree2HomeData.map((buttonPoll) => {
          return (
            isUserAuthorized(buttonPoll) && (
              <div className="Oree2Home__HitBox" key={buttonPoll.href}>
                <img src={buttonPoll.imageSource} alt="" />
                <h2>{buttonPoll.pollName}</h2>
                <p>{buttonPoll.pollDescription}</p>
                <div className="Oree2Home__Button">
                  <Link to={buttonPoll.href}>
                    <CTAButton
                      name={"Démarrer"}
                      family={"vitee"}
                      category={"secondary"}
                    />
                  </Link>
                </div>
              </div>
            )
          );
        })}
      </div>

      <FooterHills isHouseVisible={true} />
    </div>
  );
};
