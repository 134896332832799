import { useEffect, useState } from "react";
//Library import
import { useNavigate } from "react-router-dom";
import { useDisconnectApp } from "../../services/useDisconnectApp";
import { useAppSelector } from "../../redux/store/hook";

//Style import
import "./navigationBarOutsideStudy.scss";

//Images import
import oreeLogo from "../../assets/images/Navbar/oreeLogo.svg";
import quit from "@web/shared/dist/assets/images/DesignSystemIcons/log-out.svg";
import doubleArrow from "@web/shared/dist/assets/images/DesignSystemIcons/arrowhead-right.svg";
import home from "@web/shared/dist/assets/images/DesignSystemIcons/home.svg";
import start from "@web/shared/dist/assets/images/DesignSystemIcons/play-circle.svg";
import mesEtude from "@web/shared/dist/assets/images/DesignSystemIcons/save.svg";
import localisation from "@web/shared/dist/assets/images/DesignSystemIcons/localisation.svg";

//Local interface
interface Props {
  title?: string;
}

export const NavigationBarOutsideStudy = ({ title }: Props) => {
  const navigate = useNavigate();
  const { disconnectApp } = useDisconnectApp();
  const { commercialUserInformation, userAuthInfo } = useAppSelector(
    (state) => state
  );
  const [urlPage, setUrlPage] = useState(window.location.pathname);
  const [arrowClicked, setArrowClicked] = useState<boolean>(false);
  useEffect(() => {
    setUrlPage(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={"arrowButton" + (arrowClicked ? " clicked" : "")}
        onClick={() => {
          setArrowClicked(!arrowClicked);
        }}
      >
        <img
          className={arrowClicked ? "open" : "close"}
          src={doubleArrow}
          alt="doubleArrow"
          height={18}
          width={18}
        />
      </div>
      <div className="NavigationBarOutsideStudy__Layout">
        <div className="NavigationBarOutsideStudy__Container">
          <div className="NavigationBarOutsideStudy__Title">{title}</div>
          {commercialUserInformation.userFirstName &&
            commercialUserInformation.userLastName && (
              <div
                className="NavigationBarOutsideStudy__Profile"
                onClick={() => navigate("/mon-compte")}
              >
                {commercialUserInformation.userFirstName
                  ?.charAt(0)
                  .toUpperCase() +
                  commercialUserInformation.userLastName
                    ?.charAt(0)
                    .toUpperCase()}
              </div>
            )}
        </div>
      </div>
      <div className={"NavigationBarOutsideStudy__containerMenu"}>
        <div
          className={
            "NavigationBarOutsideStudy__containerMenu__menu" +
            (arrowClicked ? " clicked" : "")
          }
        >
          <div className={"Profile"}>
            <img src={oreeLogo} alt="profil" onClick={() => navigate("/")} />
          </div>

          <ul>
            <li className={"listElement"} onClick={() => navigate("/")}>
              <img src={home} alt="maison" />
              <p
                className={
                  "menuEntryTitle" + (arrowClicked ? " titleClicked" : "")
                }
              >
                Accueil
              </p>
            </li>
            <li className="Separate"></li>
            <li
              className={
                urlPage === "/oree-home" || urlPage === "/start-etude-pv"
                  ? "Selected"
                  : "listElement"
              }
              onClick={() => navigate("/oree-home")}
            >
              <img src={start} alt="start" />
              <p
                className={
                  "menuEntryTitle" + (arrowClicked ? " titleClicked" : "")
                }
              >
                Nouvelle étude
              </p>
            </li>
            <li
              className={urlPage === "/mes-etudes" ? "Selected" : "listElement"}
              onClick={() => navigate("/mes-etudes")}
            >
              <img src={mesEtude} alt="my studies" />
              <p
                className={
                  "menuEntryTitle" + (arrowClicked ? " titleClicked" : "")
                }
              >
                Mes études
              </p>
            </li>
            {userAuthInfo.companyId ===
            "b997f1a6-1bf0-e911-a812-000d3a4aabe1" ? (
              // b997f1a6-1bf0-e911-a812-000d3a4aabe1 => tucoenergie
              <li
                className={
                  urlPage === "/ressources" ? "Selected" : "listElement"
                }
                onClick={() => navigate("/projetGedeon")}
              >
                <img src={localisation} alt="Carte des installations" />
                <p
                  className={
                    "menuEntryTitle" + (arrowClicked ? " titleClicked" : "")
                  }
                >
                  Ressources
                </p>
              </li>
            ) : null}
            <li
              className="Quit"
              onClick={() => {
                disconnectApp();
                navigate("/");
              }}
            >
              <img src={quit} alt="quit" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
