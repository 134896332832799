import { ICommandeData } from "../../interfaces/generalInterfaces";
import eeroApi from "../eeroApiAxios";
import { useAuthMutation } from "./useAuthMutation";

type PostCommandVariables = {
  commandeDataRequest: ICommandeData;
  studyId: string;
};

type BdcInformation = {
  bdcId: string | null;
  bdcFileName: string | null;
  bdcUrl: string | null;
  opportunityId?: string | null;
  quoteId?: string | null;
};

type PostCommandResponse = {
  status: number;
  message: string;
  bdcInformation: BdcInformation;
};

type PostCommandErrorResponse = {
  status: number;
  message: string;
  errors: string[];
};

/**
 * Sends a POST request to submit a command to the CRM .
 */
async function postCommand({
  commandeDataRequest,
  studyId,
}: PostCommandVariables) {
  const response = await eeroApi.post<PostCommandResponse>(
    `${process.env.REACT_APP_EERO_URL}/oree2/postCommandeData/${studyId}`,
    { commonInfo: commandeDataRequest }
  );
  return response.data;
}

export const usePostCommandeDataRq = () => {
  const mutation = useAuthMutation<
    PostCommandResponse,
    PostCommandVariables,
    PostCommandErrorResponse
  >({
    mutationFn: postCommand,
  });

  // Priorization of error messages : first the expected request error message, then the generic error message, then default error message
  let errorMessage = "";
  if (mutation.isError) {
    const requestErrorMessage = Array.isArray(
      mutation.error.response?.data?.errors
    )
      ? mutation.error.response?.data?.errors?.join("\n")
      : mutation.error.response?.data?.message;

    errorMessage =
      requestErrorMessage ||
      mutation.genericErrorMessage ||
      "L'envoi au CRM a échoué";
  }

  /**
   * Custom mutateAsync function that does not throw an error.
   * For mutation, react query recommands to use its mutate function, that is not a promise and that handles the error cycle itself.
   * To manipulate promises they recommand to use mutateAsync but they delegate the error handling (i.e. throw of an error).
   * To match the functionning of the former hooks used in the App, we needed to provide a promise function that does not throw an error.
   *
   */
  const mutateAsyncNoThrowError = async ({
    commandeDataRequest,
    studyId: study_id,
  }: PostCommandVariables) => {
    // We needed to catch the error here to avoid the throw of an error in the app
    try {
      const response = await mutation.mutateAsync({
        commandeDataRequest,
        studyId: study_id,
      });

      return response;
    } catch (error) {
      // Nothing to do here, as the errror will be catch by the useMutation hook and retrieved in the mutation object
    }
  };

  return { ...mutation, mutateAsyncNoThrowError, errorMessage };
};
