const IDFDepartments = ["75", "92", "93", "94", "77", "91", "78", "95"];

// check if a given department is part of Ile de France or not
export const isDepartmentInIDF = ({ zipcode }: { zipcode: string | null }) => {
  if (!zipcode) return true;

  const userDepartment = zipcode.slice(0, 2);

  return IDFDepartments.some((department) => department === userDepartment);
};
