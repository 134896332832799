import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import {
  ICommercialUserInformation,
  ISageProduct,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  PVProduct: IDataProduct | undefined;
  SolarPannelsSageProduct: ISageProduct | undefined;
  OnduleurSageProduct: ISageProduct | undefined;
  OptimiseurSageProduct: ISageProduct | undefined;
  CoffretACDCSageProduct: ISageProduct | undefined;
  commercialUserInformation: ICommercialUserInformation;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
}

export const PdfPVPageOne = ({
  documentId,
  PVProduct,
  SolarPannelsSageProduct,
  OnduleurSageProduct,
  OptimiseurSageProduct,
  CoffretACDCSageProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
}: IProps) => {
  const {
    installationPriceWithoutTax: PVInstallationPriceWithoutTax,
    installationPriceWithTax: PVInstallationPriceWithTax,
  } = productPrices({ product: PVProduct, isEligibleRenoGlobal });

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={PVProduct?.product_alias || PVProduct?.product_name || "NC"}
            page={"1/2"}
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={1}
              prixUnitaire={PVProduct?.product_price_without_tax || 0}
              TVA={PVProduct?.TVA_percentage || 0}
              montantTTC={PVProduct?.product_price_with_tax || 0}
            />
            <View style={styles.inlineComponents}>
              <Text>Type de raccordement : </Text>
              <Text style={styles.fontBold}>
                autoconsommation avec revente de surplus
              </Text>
            </View>

            {/* Solar pannels */}
            {SolarPannelsSageProduct ? (
              <PdfItemDetail
                key={SolarPannelsSageProduct.AR_Ref}
                title={PVProduct?.product_name || ""}
                description={[
                  <>
                    {SolarPannelsSageProduct.Dimensions && (
                      <Text>
                        {SolarPannelsSageProduct.Dimensions &&
                        SolarPannelsSageProduct.Dimensions.length > 0
                          ? `Taille : ${SolarPannelsSageProduct.Dimensions}`
                          : null}{" "}
                      </Text>
                    )}
                  </>,
                  <>
                    {SolarPannelsSageProduct.Poid && (
                      <Text>
                        {SolarPannelsSageProduct.Poid &&
                        SolarPannelsSageProduct.Poid.length > 0
                          ? `Poids : ${SolarPannelsSageProduct.Poid}`
                          : null}
                      </Text>
                    )}
                  </>,
                  <>
                    {SolarPannelsSageProduct["Norme et Certification"] &&
                      SolarPannelsSageProduct["Norme et Certification"]
                        ?.length > 0 && (
                        <Text>
                          {SolarPannelsSageProduct["Norme et Certification"]
                            ? `Normes et certifications : ${SolarPannelsSageProduct["Norme et Certification"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {SolarPannelsSageProduct["Garantie Fabricant"] &&
                      SolarPannelsSageProduct["Garantie Fabricant"]?.length >
                        0 && (
                        <Text>
                          {SolarPannelsSageProduct["Garantie Fabricant"]
                            ? `Garantie Fabriquant : ${SolarPannelsSageProduct["Garantie Fabricant"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                ]}
              />
            ) : null}

            {/* Onduleur(s) */}
            {OnduleurSageProduct ? (
              <PdfItemDetail
                key={OnduleurSageProduct.AR_Ref}
                title={
                  OnduleurSageProduct.AR_Design && OnduleurSageProduct.NO_Qte
                    ? OnduleurSageProduct.NO_Qte +
                      " x " +
                      OnduleurSageProduct.AR_Design
                    : ""
                }
                description={[
                  <>
                    {OnduleurSageProduct.Dimensions && (
                      <Text>
                        {OnduleurSageProduct.Dimensions &&
                        OnduleurSageProduct.Dimensions.length > 0
                          ? `Taille : ${OnduleurSageProduct.Dimensions}`
                          : null}{" "}
                      </Text>
                    )}
                  </>,
                  <>
                    {OnduleurSageProduct.Poid && (
                      <Text>
                        {OnduleurSageProduct.Poid &&
                        OnduleurSageProduct.Poid.length > 0
                          ? `Poids : ${OnduleurSageProduct.Poid}`
                          : null}
                      </Text>
                    )}
                  </>,
                  <>
                    {OnduleurSageProduct.Alimentation && (
                      <Text>
                        {OnduleurSageProduct.Alimentation &&
                        OnduleurSageProduct.Alimentation.length > 0
                          ? `Alimentation : ${OnduleurSageProduct.Alimentation}`
                          : null}
                      </Text>
                    )}
                  </>,
                  <>
                    {OnduleurSageProduct["Norme et Certification"] &&
                      OnduleurSageProduct["Norme et Certification"]?.length >
                        0 && (
                        <Text>
                          {OnduleurSageProduct["Norme et Certification"]
                            ? `Normes et certifications : ${OnduleurSageProduct["Norme et Certification"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {OnduleurSageProduct["Garantie Fabricant"] &&
                      OnduleurSageProduct["Garantie Fabricant"]?.length > 0 && (
                        <Text>
                          {OnduleurSageProduct["Garantie Fabricant"]
                            ? `Garantie Fabriquant : ${OnduleurSageProduct["Garantie Fabricant"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                ]}
              />
            ) : null}

            {/* Optimiseur(s) */}
            {OptimiseurSageProduct ? (
              <PdfItemDetail
                key={OptimiseurSageProduct.AR_Ref}
                title={
                  OptimiseurSageProduct.AR_Design &&
                  OptimiseurSageProduct.NO_Qte
                    ? OptimiseurSageProduct.NO_Qte +
                      " x " +
                      OptimiseurSageProduct.AR_Design
                    : ""
                }
                description={[
                  <>
                    {OptimiseurSageProduct.Dimensions && (
                      <Text>
                        {OptimiseurSageProduct.Dimensions &&
                        OptimiseurSageProduct.Dimensions.length > 0
                          ? `Taille : ${OptimiseurSageProduct.Dimensions}`
                          : null}{" "}
                      </Text>
                    )}
                  </>,
                  <>
                    {OptimiseurSageProduct.Poid && (
                      <Text>
                        {OptimiseurSageProduct.Poid &&
                        OptimiseurSageProduct.Poid.length > 0
                          ? `Poids : ${OptimiseurSageProduct.Poid}`
                          : null}
                      </Text>
                    )}
                  </>,
                  <>
                    {OptimiseurSageProduct["Norme et Certification"] &&
                      OptimiseurSageProduct["Norme et Certification"]?.length >
                        0 && (
                        <Text>
                          {OptimiseurSageProduct["Norme et Certification"]
                            ? `Normes et certifications : ${OptimiseurSageProduct["Norme et Certification"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {OptimiseurSageProduct["Garantie Fabricant"] &&
                      OptimiseurSageProduct["Garantie Fabricant"]?.length >
                        0 && (
                        <Text>
                          {OptimiseurSageProduct["Garantie Fabricant"]
                            ? `Garantie Fabriquant : ${OptimiseurSageProduct["Garantie Fabricant"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                ]}
              />
            ) : null}
            {/* Coffret(s) de protection AC/DC */}
            {CoffretACDCSageProduct ? (
              <PdfItemDetail
                key={CoffretACDCSageProduct.AR_Ref}
                title={
                  CoffretACDCSageProduct.AR_Design &&
                  CoffretACDCSageProduct.NO_Qte
                    ? CoffretACDCSageProduct.NO_Qte +
                      " x " +
                      CoffretACDCSageProduct.AR_Design
                    : ""
                }
                description={[
                  <>
                    {CoffretACDCSageProduct["Norme et Certification"] &&
                      CoffretACDCSageProduct["Norme et Certification"]?.length >
                        0 && (
                        <Text>
                          {CoffretACDCSageProduct["Norme et Certification"]
                            ? `Normes et certifications : ${CoffretACDCSageProduct["Norme et Certification"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {CoffretACDCSageProduct["Garantie Fabricant"] &&
                      CoffretACDCSageProduct["Garantie Fabricant"]?.length >
                        0 && (
                        <Text>
                          {CoffretACDCSageProduct["Garantie Fabricant"]
                            ? `Garantie Fabriquant : ${CoffretACDCSageProduct["Garantie Fabricant"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                ]}
              />
            ) : null}
            <View wrap={false}>
              <PdfRowBackgroundProduit
                name="Forfait installation"
                quantity={1}
                prixUnitaire={PVInstallationPriceWithoutTax}
                TVA={PVProduct?.TVA_percentage || 0}
                montantTTC={PVInstallationPriceWithTax}
              />
              <View style={styles.inlineComponents}>
                <Text>Déplacement</Text>
              </View>
              <View style={styles.columnComponents}>
                <Text>Mise en place/installation des matériels</Text>
                <View style={[styles.columnComponents, { paddingLeft: 10 }]}>
                  <Text>• Modules photovoltaïques en toiture</Text>
                  <Text>• Tirage des câbles DC et câbles de terre</Text>
                  <Text>
                    • Pose des optimiseurs de puissance, des coffrets, de
                    l’onduleur et du système de monitoring
                  </Text>
                  <Text>
                    • Câblage de la partie AC dans l’armoire électrique
                    existante
                  </Text>
                </View>
                <Text>
                  Fourniture des équipements nécessaires à la pose des matériels
                </Text>
              </View>
            </View>
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
