import "./ExtraChargesListItem.scss";

// Libraries import
import SVG from "react-inlinesvg";

// Images import
import Trash from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";
import ParametreMaison from "@web/shared/dist/assets/images/DesignSystemIllustrations/parametres-maison.svg";

// Interfaces import
import { TFraisSupp } from "../../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  extraCharge: TFraisSupp;
  setFraisSupp: React.Dispatch<React.SetStateAction<TFraisSupp[]>>;
}

export const ExtraChargesListItem = ({ extraCharge, setFraisSupp }: IProps) => {
  return (
    <div className="ExtraChargesListItem">
      <div className="ExtraChargesListItem__Left">
        <div className="ExtraChargesListItem__Image">
          <img src={ParametreMaison} alt="frais supplémentaire" />
        </div>
        <div className="ExtraChargesListItem__InfoText">
          <div className="ExtraChargesListItem__Name">
            <p>{extraCharge.product_name}</p>
          </div>
          <div className="ExtraChargesListItem__Description">
            {extraCharge.description ? (
              <p>{extraCharge.description}</p>
            ) : (
              <p>Pas de description disponible</p>
            )}
          </div>
        </div>
      </div>
      <div className="ExtraChargesListItem__Price">
        <p>
          {" "}
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(extraCharge.price_with_tax)}
        </p>
        <div className="ExtraChargesListItem__Trash">
          <SVG
            src={Trash}
            onClick={() =>
              setFraisSupp((prevState) => {
                const newState = [...prevState];
                const indexFoundObject = newState.findIndex(
                  (obj) => obj.product_name === extraCharge.product_name
                );
                if (indexFoundObject !== -1) {
                  newState.splice(indexFoundObject, 1);
                }
                return newState;
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
