import moment from "moment"; // require

//Interface import
import { IConsumptionUnit } from "../interfaces/generalInterfaces";

//moment setting
moment.updateLocale("fr", {
  months: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  weekdays: [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ],
});

//Local interface
interface IGroup {
  [key: string]: number[];
}

//Function to search date min et date max from data set
export const findeDateMinMax = (ConsumptionTable: IConsumptionUnit[]) => {
  const allDate = ConsumptionTable.map((singleConsuptionData) => {
    return moment(singleConsuptionData.datetime);
  });
  return {
    dateMin: moment.min(allDate).toDate() ?? null,
    dateMax: moment.max(allDate).toDate() ?? null,
  };
};

//Function to filter data setbetween two date
export const filterBetweenTwoDate = (
  ConsumptionTable: IConsumptionUnit[],
  dateMin: Date,
  dateMax: Date
) => {
  const ConsumptionFiltered: IConsumptionUnit[] = ConsumptionTable.filter(
    (singleConsumption) =>
      moment(singleConsumption.datetime)?.isBetween(
        dateMin,
        dateMax,
        undefined,
        "[]"
      )
  );
  ConsumptionFiltered.map((item) => {
    return (item.electric_consumption = +item.electric_consumption.toFixed(2));
  });

  return ConsumptionFiltered;
};

//Function to group dataset by day.
export const groupByDay = (
  ConsumptionTable: IConsumptionUnit[]
): IConsumptionUnit[] => {
  //Groups all consuptions from a same day inside an Array.
  //This array is the value of an object whith the day as key => {day 1 :[consumtionDay1 1, consumtionDay1 2, consumtionDay1 X], dayN :[consumtionDayN 1, consumtionDayN 2, consumtionDayN Y] }
  const groupeDay: IGroup = ConsumptionTable.reduce(
    (groupeDay: IGroup, singleConsumption) => {
      const DayKey = moment(singleConsumption.datetime).format("YYYY-MM-DD");

      //Check if a day allready exist, if not new key [day] is created then consumption is push inside value array,
      if (!groupeDay[DayKey]) {
        groupeDay[DayKey] = [];
      }
      groupeDay[DayKey].push(singleConsumption.electric_consumption);
      return groupeDay;
    },
    {}
  );

  //transform previous object in an array of object.
  const ConsumptionGroupByDay: IConsumptionUnit[] = [];
  for (const key in groupeDay) {
    ConsumptionGroupByDay.push({
      datetime: key,
      electric_consumption: +groupeDay[key]
        .reduce((a, b) => a + b, 0)
        .toFixed(2),
    });
  }
  return ConsumptionGroupByDay;
};

//Function to group data set by month
export const groupByMonth = (
  ConsumptionTable: IConsumptionUnit[]
): IConsumptionUnit[] => {
  //Groups all consuptions from a same month inside an Array.
  //This array is the value of an object whith the month as key => {month 1 :[consumtionMonth1 1, consumtionMonth1 2, consumtionMonth1 X], monthN :[consumtionMonthN 1, consumtionMonthN 2, consumtionMonthN Y] }
  const groupeMonth: IGroup = ConsumptionTable.reduce(
    (groupeMonth: IGroup, singleConsumption) => {
      const MonthKey = singleConsumption.datetime.slice(0, 7);

      //Check if a day allready exist, if not new key [month] is created then consumption is push inside value array,
      if (!groupeMonth[MonthKey]) {
        groupeMonth[MonthKey] = [];
      }
      groupeMonth[MonthKey].push(singleConsumption.electric_consumption);
      return groupeMonth;
    },
    {}
  );

  const ConsumptionGroupByMonth: IConsumptionUnit[] = [];
  for (const key in groupeMonth) {
    ConsumptionGroupByMonth.push({
      datetime: moment(key, "YYYY-MM").format("YYYY-MM-DD"),
      electric_consumption: +groupeMonth[key]
        .reduce((a, b) => a + b, 0)
        .toFixed(2),
    });
  }
  return ConsumptionGroupByMonth;
};

//Function to group data set by week
export const groupByWeek = (
  ConsumptionTable: IConsumptionUnit[]
): IConsumptionUnit[] => {
  //Groups all consuptions from a same month inside an Array.
  //This array is the value of an object whith the week as key => {week 1 :[consumtionWeek1 1, consumtionWeek1 2, consumtionWeek1 X], weekN :[consumtionWeekN 1, consumtionWeekN 2, consumtionWeekN Y] }
  const groupeWeek: IGroup = ConsumptionTable.reduce(
    (groupeWeek: IGroup, singleConsumption) => {
      const WeekKey = moment(singleConsumption.datetime).week();

      //Check if a day allready exist, if not new key [week] is created then consumption is push inside value array,
      if (!groupeWeek[WeekKey]) {
        groupeWeek[WeekKey] = [];
      }
      groupeWeek[WeekKey].push(singleConsumption.electric_consumption);
      return groupeWeek;
    },
    {}
  );
  const ConsumptionGroupByWeek: IConsumptionUnit[] = [];
  for (const key in groupeWeek) {
    ConsumptionGroupByWeek.push({
      datetime: moment(key, "W").format("YYYY-MM-DD"),
      electric_consumption: +groupeWeek[key]
        .reduce((a, b) => a + b, 0)
        .toFixed(2),
    });
  }
  return ConsumptionGroupByWeek;
};

//Get the interval between all single consumption from data.
//The base unit is hours. If interval = 1, data is set with one hour interval
export const getTimeFromDataSet = (ConsumptionTable: IConsumptionUnit[]) => {
  const firstDateTime = moment(ConsumptionTable[0].datetime);
  const secondDateTime = moment(ConsumptionTable[1].datetime);
  const interval = secondDateTime.diff(firstDateTime, "hour", true);
  return interval;
};

//Get the last week consuption from data with one hour interval
export const getLastWeekHourly = (
  ConsumptionTable: IConsumptionUnit[]
): IConsumptionUnit[] => {
  return ConsumptionTable.slice(ConsumptionTable.length - 168);
};

//Get the last week consuption from data with one hour interval
export const getLastYearDaily = (
  ConsumptionTable: IConsumptionUnit[]
): IConsumptionUnit[] => {
  if (ConsumptionTable.length >= 8760)
    return ConsumptionTable.slice(ConsumptionTable.length - 8760);
  return ConsumptionTable;
};
