import "./LoadStudyDataHOC.scss";

// Libraries import
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Component import
import { LoadingScreen } from "../../containers/LoadingScreen/LoadingScreen";

// Services import
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { useGetStudy } from "../../services/Studies/useGetStudy";
import { resetAllStudyInformationAction } from "../../redux/appActions";

// Interfaces import
import {
  IUserAuthInfo,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  children: JSX.Element;
  goToLastPageVisited: ({
    last_page_visited,
  }: {
    last_page_visited?: string | null;
    studyInformation?: IStudyInformation;
    userAuthInfo: IUserAuthInfo;
  }) => void;
}

export const LoadStudyDataHOC = ({ children, goToLastPageVisited }: Props) => {
  const { userAuthInfo } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const { study_id } = useParams();
  const { getStudy } = useGetStudy();

  const [isGetStudyLoading, setIsGetStudyLoading] = useState(false);

  const fetchData = async () => {
    setIsGetStudyLoading(true);
    const studyInformation = await getStudy({
      userToken: userAuthInfo.userToken,
      study_id,
    });

    if (!studyInformation) return;

    studyInformation.last_page_visited !== "my_scenario" &&
      (await new Promise((resolve) => setTimeout(resolve, 2000)));

    setIsGetStudyLoading(false);

    goToLastPageVisited({
      userAuthInfo,
      studyInformation: studyInformation,
    });
  };

  useEffect(() => {
    // When we start a study we reset all cutomer data from redux
    dispatch(resetAllStudyInformationAction(null));
    study_id && study_id !== "new" && fetchData(); // If study id=new it means it is the creation of a new study -> we don't have yet study_id neither study information to get from backend
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isGetStudyLoading ? (
        <div className="LoadStudyDataHoc__LoadingContainer">
          <LoadingScreen sentenceOne="Chargement des données de l'étude ..." />
        </div>
      ) : (
        children
      )}
    </>
  );
};
