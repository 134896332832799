import { useState } from "react";

//Library import
import { useNavigate } from "react-router-dom";
import { resetAllStudyInformationAction } from "../../redux/appActions";
import { useAppDispatch } from "../../redux/store/hook";

//Style import
import { Layout } from "../Layout/Layout";
import "./NavBarRG.scss";

//Images import
import burgerMenu from "../../assets/images/burgerMenu.svg";
import home from "../../assets/images/Navbar/home.svg";
import start from "../../assets/images/Navbar/start.svg";
import study from "../../assets/images/Navbar/study.svg";
import source from "../../assets/images/Navbar/source.svg";
import burgerMenuClose from "../../assets/images/burgerMenuClose.svg";
import goBackTo from "../../assets/images/goBackTo.svg";

//Local interface
interface Props {
  allTitles?: string[];
  currentStepIndex?: number;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: {
    newCurrentStepIndex: number;
    newCurrentSubStepIndex?: number;
    isGoforwardAllowed?: boolean;
  }) => void;
  isBurgerMenuVisible?: boolean;
  isGoBackToVisible?: boolean;
  isOngletVisible?: boolean;
}

export const NavBarRG = ({
  allTitles,
  currentStepIndex,
  goTo,
  isBurgerMenuVisible,
  isGoBackToVisible,
  isOngletVisible,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [menuBurgerShown, setMenuBurgerShown] = useState(false);

  const handleOnClickGoBackToMenu = () => {
    if (currentStepIndex === 0) {
      dispatch(resetAllStudyInformationAction(null));
      navigate("/oree-home");
    } else {
      goTo &&
        currentStepIndex &&
        goTo({
          newCurrentStepIndex: currentStepIndex - 1,
          newCurrentSubStepIndex: 0,
          isGoforwardAllowed: false,
        });
    }
  };

  return (
    <>
      <Layout>
        <div className="NavigationBar__Container">
          {isGoBackToVisible && (
            <div
              onClick={() => handleOnClickGoBackToMenu()}
              className="NavigationBar__goBackto"
            >
              <img src={goBackTo} alt="gobackto menu" />
            </div>
          )}
          <div
            className={
              !isOngletVisible
                ? "NavigationBar NavigationBarNoBG"
                : "NavigationBar"
            }
          >
            {allTitles && currentStepIndex !== undefined && goTo
              ? allTitles.map((title, index) => {
                  const isStepPassed = index < currentStepIndex;
                  const isStepActive = index <= currentStepIndex;
                  return (
                    <div
                      className={`NavigationBar__TitleSection ${
                        isStepActive && "TitleActive"
                      } ${isStepPassed && "TitlePassed"}`}
                      key={index}
                      onClick={() => {
                        goTo({
                          newCurrentStepIndex: index,
                          newCurrentSubStepIndex: 0,
                          isGoforwardAllowed: false,
                        });
                      }}
                    >
                      <h1>{title}</h1>
                      <div
                        className={`NavigationBar__Underlined ${
                          isStepActive && "UnderlinedActive"
                        } ${isStepPassed && "UnderlinePassed"}`}
                      ></div>
                    </div>
                  );
                })
              : null}
          </div>

          {isBurgerMenuVisible && (
            <div
              onClick={() => {
                setMenuBurgerShown(true);
              }}
              className="NavigationBar__burgerMenu"
            >
              <img src={burgerMenu} alt="burger menu" />
            </div>
          )}
        </div>
      </Layout>

      <div
        className={
          menuBurgerShown
            ? "NavigationBar__containerMenu opened"
            : "NavigationBar__containerMenu"
        }
      >
        <div className="NavigationBar__containerMenu__menu">
          <div onClick={() => navigate("/mon-compte")} className="Profile">
            <img src={home} alt="profil" />
            <h2>Orée</h2>
          </div>
          <li className="Separate"></li>
          <ul>
            <li onClick={() => navigate("/oree-home")}>
              <img src={start} alt="profil" />
              <p>Nouvelle étude</p>
            </li>
            <li onClick={() => navigate("/mes-etudes")}>
              <img src={study} alt="profil" />
              <p>Mes études</p>
            </li>
            <li onClick={() => navigate("/ressources")}>
              <img src={source} alt="profil" />
              <p>Ressources</p>
            </li>
          </ul>

          <img
            onClick={() => {
              setMenuBurgerShown(false);
            }}
            src={burgerMenuClose}
            alt=" close"
            className="close"
          />
        </div>
      </div>
    </>
  );
};
