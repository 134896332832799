import "./ProductTotalPrice.scss";

// Components
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

// Services import
import { productPrices } from "../../../services/RenovationGlobale/calculateProductPrice";

// Interfaces import
import {
  TFraisSupp,
  IDataProduct,
} from "../../../interfaces/renoglobaleInterface";
import { euroLongFormat } from "../../../services/euroFormatter";

// Local interface declaration
interface IProps {
  productSelected: IDataProduct;
  fraisSupp: TFraisSupp[];
  addProductToMySelection: () => void;
  isUpdateproduct: boolean;
  isEligibleRenoGlobal: boolean;
}

export const ProductTotalPrice = ({
  productSelected,
  isUpdateproduct,
  addProductToMySelection,
  isEligibleRenoGlobal,
}: IProps) => {
  const { totalPriceWithTax } = productPrices({
    product: productSelected,
    isEligibleRenoGlobal,
    isAdditionalProducts: true,
  });

  return (
    <section className="ProductTotalPrice">
      <div className="ProductTotalPrice__Description">
        <p className="ProductTotalPrice__Price">
          <strong>{euroLongFormat(totalPriceWithTax)}</strong>{" "}
        </p>
        {productSelected?.help.length > 0 ? (
          <p>Aides maximum potentielles :</p>
        ) : null}
        {productSelected?.help?.map((help, index) => {
          return (
            <p
              className="ProductTotalPrice__Subventions"
              key={help.name + index}
            >
              {help.name + " : "}
              <strong>
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(help.value)}
              </strong>
            </p>
          );
        })}
      </div>
      <div className="ProductTotalPrice_ButtonContainer">
        <CTAButton
          name={
            isUpdateproduct ? "Modifier le produit" : "Ajouter à ma sélection"
          }
          onClick={addProductToMySelection}
        />
      </div>
    </section>
  );
};
