import React, { useState } from "react";

import "./potentielSolaireImage.scss";

// interface imports
import { TRoofSections } from "../../../interfaces/generalInterfaces";
import { PotentielSolairePanInformation } from "../PotentielSolairePanInformation/PotentielSolairePanInformation";

// Local interface declaration

interface IProps {
  potentielSolaireImageBase64?: string | null;
  satelliteVueImageBase64?: string | null;
  roofSections?: TRoofSections[];
  isFilterClicked?: boolean;
  isForPdf?: boolean;
}

export const PotentielSolaireImage = ({
  potentielSolaireImageBase64,
  satelliteVueImageBase64,
  roofSections,
  isFilterClicked = true,
  isForPdf = false,
}: IProps) => {
  const [clickedPan, setClickedPan] = useState<number | null>(
    isForPdf ? null : 0
  );

  const onClickTabs = (index: number, panIndex: number | null) => {
    if (index === panIndex) {
      setClickedPan(null);
    } else {
      setClickedPan(index);
    }
  };

  return (
    <div className="PotentielSolaireImage">
      {isFilterClicked &&
        roofSections?.map((section, index) => {
          return (
            <div
              key={index}
              className={`panIndexMarker ${
                index === clickedPan ? "clickedPanIndexMarker" : ""
              }`}
              style={{
                top: `calc(${section?.centroid_pixel_coordinates?.y}% - 12px)`,
                left: `calc(${section?.centroid_pixel_coordinates?.x}% - 12px)`,
              }}
              onClick={() => onClickTabs(index, clickedPan)}
            >
              <p>{index + 1}</p>
              <div className="whiteOutline"></div>
            </div>
          );
        })}
      {isFilterClicked && roofSections && clickedPan !== null && (
        <div className="potentielSolairePanInformationContainer">
          <PotentielSolairePanInformation
            panSurface={roofSections[clickedPan]?.area_m2}
            panOrientation={roofSections?.[clickedPan]?.orientation}
            panSlope={roofSections?.[clickedPan]?.slope_degrees}
            panIndex={clickedPan}
            setClickedPan={setClickedPan}
            roofAzimuth={roofSections?.[clickedPan].azimuth_degrees}
          />
        </div>
      )}
      {potentielSolaireImageBase64 && (
        <img
          src={`data:image/jpeg;base64,${
            isFilterClicked
              ? potentielSolaireImageBase64
              : satelliteVueImageBase64
          }`}
          alt="Les photos de la maison avec un filtre solaire ou une vue satellite"
          // For width & height, Leeroy will update API so he sends it to us
        />
      )}
    </div>
  );
};
