import "./EnergyNewLabel.scss";

// Interfaces import
import { IPerformanceRG } from "../../interfaces/renoglobaleInterface";

//Local interface
interface IProps {
  performanceRGData?: IPerformanceRG | null;
}

export const EnergyNewLabel = ({ performanceRGData }: IProps) => {
  const oldLabelImage = performanceRGData?.old_label
    ? require(`../../assets/images/energyLabel/label/DPE ${performanceRGData?.old_label}.svg`)
    : null;
  const newLabelImage = performanceRGData?.new_label
    ? require(`../../assets/images/energyLabel/label/DPE ${performanceRGData?.new_label}.svg`)
    : null;

  return (
    <div className="EnergyNewLabel">
      <div className="EnergyNewLabel__Container">
        <section className="EnergyNewLabel__Title">
          <h3>Changement d'étiquette énergétique</h3>
        </section>
        <div className="EnergyNewLabel__Subtitle">
          <p>
            Grâce à vos travaux de rénovation, vous améliorez la performance
            énergétique de votre logement et changez ainsi d'étiquette.
          </p>
        </div>
        {performanceRGData && oldLabelImage && newLabelImage ? (
          <>
            <section className="EnergyNewLabel__LabelsContainer">
              <div className="EnergyNewLabel__Label">
                <div className="EnergyNewLabel__LabelInformation">
                  <p>
                    <strong>AVANT</strong>
                  </p>
                  <p>
                    {performanceRGData.old_energy_value?.toFixed(0) +
                      " kWh/m²/an"}
                  </p>
                  <p>
                    {performanceRGData.old_ges_emissions?.toFixed(0) +
                      " kg CO2/m²/an"}
                  </p>
                </div>
                <div className="EnergyNewLabel__LabelImage">
                  <img
                    src={oldLabelImage}
                    alt={`label énergie ${performanceRGData.old_label}`}
                  />
                </div>
              </div>
              <div className="EnergyNewLabel__Label">
                <div className="EnergyNewLabel__LabelInformation EnergyNewLabel__LabelInformationAfter">
                  <p>
                    <strong>APRÈS</strong>
                  </p>
                  <p>
                    {performanceRGData.new_energy_value?.toFixed(0) +
                      " kWh/m²/an"}
                  </p>
                  <p>
                    {performanceRGData.new_ges_emissions?.toFixed(0) +
                      " kg CO2/m²/an"}
                  </p>
                </div>
                <div className="EnergyNewLabel__LabelImage">
                  <img
                    src={newLabelImage}
                    alt={`label énergie ${performanceRGData.new_label}`}
                  />
                </div>
              </div>
            </section>
            <section className="EnergyNewLabel__Juridique">
              <p>
                Ce bilan n’a pas de valeur juridique et ne peut remplacer un DPE
                réalisé par un professionnel agréé.
              </p>
            </section>
          </>
        ) : (
          <p className="EnergyNewLabel__NoDataAvailable">
            Pas de données disponibles
          </p>
        )}
      </div>
    </div>
  );
};
