// Styles import
import "./monPotentielSolaire.scss";

// Libraries import
import { useEffect, useRef } from "react";

// redux imports
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import {
  setSolarPotentialStepsStatusAction,
  setStudyInfoAction,
} from "../../redux/appActions";

// services import
import { useSavePotentielSolaire } from "../../services/PotentielSolaire/useSavePotentielSolaire";
import { useSaveLastPageVisited } from "../../services/Oree2/useSaveLastPageVisited";

// components import
import { Layout } from "../../components/Layout/Layout";
import { SubHeader } from "../../containers/SubHeader/SubHeader";
import { MonPotentielSolaireDesktop } from "../../containers/MonPotentielSolaireContainers/MonPotentielSolaireDesktop/MonPotentielSolaireDesktop";
import { PotentielSolaireKOForm } from "../../containers/PotentielSolaireKOForm/PotentielSolaireKOForm";
import { SolarPotentialLoadingScreenWrapper } from "../../containers/PotentialSolarLoadingScreen/SolarPotentialLoadingScreenWrapper";

// Interfaces import
import { IGoTo } from "../../interfaces/generalInterfaces";
import { INavigateData } from "../../services/useNavigation/useNavigation";

// Local interface declaration
interface Props {
  goNext: (data?: INavigateData<any>) => void;
  goBack?: () => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
}

export const MonPotentielSolaire = ({ goNext }: Props) => {
  const { userAuthInfo, studyInformation, solarPotentialStepsStatus } =
    useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const {
    savePotentielSolaire,
    isLoading: isLoadingSavePotentialSolar,
    errorMessage: errorMessageSaveSolarPotential,
  } = useSavePotentielSolaire();
  const { saveLastPageVisited } = useSaveLastPageVisited();
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  const onGoNextPotentielSolaireKO = () => {
    // On click on next we submit the form -> allow to check all required fields
    buttonSubmitRef.current?.click();
  };

  const onGoNext = () => {
    savePotentielSolaire({
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id,
      payload: {
        building_id: studyInformation.building_id,
        roof_sections: studyInformation.roof_sections,
        solar_potential_image: studyInformation.solar_potential_image,
        building_image: studyInformation.building_image,
        roof_area_to_equip: studyInformation.roof_area_to_equip,
        roof_type: studyInformation.roof_type,
        latitude: studyInformation.latitude,
        longitude: studyInformation.longitude,
        isFormValues: false,
      },
    });
    saveLastPageVisited({
      last_page_visited: "my_solar_potential",
      study_id: studyInformation?.study_id || null,
      userToken: userAuthInfo.userToken,
    });
    goNext && goNext();
  };

  const handleHeaderGoNextButtonClick = () => {
    // First click on button disable loading screen and display solar potential or form
    if (solarPotentialStepsStatus.isLoadingScreenDisplayed) {
      dispatch(
        setSolarPotentialStepsStatusAction({
          solarPotentialStepsStatus: {
            isLoadingScreenDisplayed: false,
          },
        })
      );
      return;
    }

    // Second click on button send form or go next
    solarPotentialStepsStatus.isSolarPotentialKOForm
      ? onGoNextPotentielSolaireKO()
      : onGoNext();
  };
  useEffect(() => {
    // Display solar potential ko form if it was previously sent
    studyInformation.solar_potential_ko &&
      dispatch(
        setSolarPotentialStepsStatusAction({
          solarPotentialStepsStatus: {
            isLoadingScreenDisplayed: false,
            isSolarPotentialKOForm: true,
            isCheckSolarPotentialAvailabilityLoading: false,
            isGetSolarPotentialLoading: false,
          },
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="MonPotentielSolaire">
      <SubHeader
        title="Mon potentiel solaire"
        goNext={handleHeaderGoNextButtonClick}
        isLoading={
          solarPotentialStepsStatus.isCheckSolarPotentialAvailabilityLoading ||
          solarPotentialStepsStatus.isGetSolarPotentialLoading ||
          isLoadingSavePotentialSolar
        }
      />
      {!studyInformation.solar_potential_ko &&
      solarPotentialStepsStatus.isLoadingScreenDisplayed ? (
        <div className="MonInstallationScenarios__LoaderContainer">
          <SolarPotentialLoadingScreenWrapper />
        </div>
      ) : solarPotentialStepsStatus.isSolarPotentialKOForm ? (
        <PotentielSolaireKOForm
          goNext={() => {
            dispatch(
              setStudyInfoAction({
                studyInformation: { solar_potential_ok: false },
              })
            );
            goNext();
          }}
          buttonSubmitRef={buttonSubmitRef}
          userToken={userAuthInfo.userToken}
          savePotentielSolaire={savePotentielSolaire}
          isLoadingSavePotentialSolar={isLoadingSavePotentialSolar}
          errorMessage={errorMessageSaveSolarPotential}
        />
      ) : (
        <>
          <div className="MonPotentielSolaire__Desktop">
            <Layout>
              <MonPotentielSolaireDesktop studyInformation={studyInformation} />
            </Layout>
          </div>
        </>
      )}
    </div>
  );
};
