//Style import
import "./dimensionnementComponent.scss";

// Libraries import
import { useEffect, useState } from "react";

//Component import
import { Layout } from "../../../components/Layout/Layout";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { TextLink } from "@web/shared/dist/components/DesignSystem/TextLink/TextLink";
import filetext from "@web/shared/dist/assets/images/DesignSystemIcons/file-text.svg";
import { PanelsDrawing } from "../../../components/PanelsDrawing/PanelsDrawing";

// Interfaces import
import { IScenario } from "../../../interfaces/generalInterfaces";

//Service import
import { useGetDimensionnement } from "../../../services/OptionChosen/useGetDimensionnement";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { setSAGEItemsInfoAction } from "../../../redux/appActions";
import { useGetSAGEArticleInfo } from "../../../services/Recapitulatif/useGetSAGEArticleInfo";
import { useGetSAGEItemInfo } from "../../../services/Recapitulatif/useGetSAGEItemInfo";

// Local interface declaration
interface Props {
  building_id?: string | null;
  household_id: string | null;
  scenarioChosen: IScenario;
}

export const DimensionnementComponent = ({
  building_id,
  household_id,
  scenarioChosen,
}: Props) => {
  const { monInstallation, userAuthInfo, studyInformation, SAGEItemsInfo } =
    useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const { getDimensionnement, isLoading, errorMessage } =
    useGetDimensionnement();
  const { getSAGEArticleInfo } = useGetSAGEArticleInfo();
  const { getSAGEItemInfo } = useGetSAGEItemInfo();

  const [isGetSageKitPVinfoLoading, setIsGetSageKitPVinfoLoading] =
    useState(false);

  const getSageKitPVinfo = async (monScenario: IScenario) => {
    setIsGetSageKitPVinfoLoading(true);
    dispatch(setSAGEItemsInfoAction({ SAGEItemsInfo: [] }));
    const HKMSageReference = monScenario.kit_sage_reference;
    await getSAGEItemInfo({
      userToken: userAuthInfo.userToken,
      SAGE_reference: monScenario.sage_reference_PV,
      study_id: studyInformation?.study_id || "",
    });

    (monScenario.number_of_batteries || 0) > 0 &&
      (await getSAGEArticleInfo({
        userToken: userAuthInfo.userToken,
        SAGE_reference: monScenario.battery_sage_reference, // Get battery info
        study_id: studyInformation?.study_id || "",
      }));

    if (HKMSageReference) {
      const response = await getSAGEItemInfo({
        userToken: userAuthInfo.userToken,
        SAGE_reference: HKMSageReference, // Get home management
        study_id: studyInformation?.study_id || "",
      });
      !response &&
        (await getSAGEArticleInfo({
          userToken: userAuthInfo.userToken,
          SAGE_reference: HKMSageReference,
          study_id: studyInformation?.study_id || "",
        })); // Get home management info
    }
    setIsGetSageKitPVinfoLoading(false);
  };

  useEffect(() => {
    getSageKitPVinfo(scenarioChosen);
    if (
      monInstallation.isPersonalizedScenario ||
      monInstallation.indexOptionChosen !==
        monInstallation.dimensionnement?.indexScenarioChosen
    ) {
      getDimensionnement({
        userToken: userAuthInfo.userToken,
        number_of_panels: scenarioChosen?.number_of_panels ?? null,
        building_id: building_id,
        household_id: household_id,
        kit_home_management: scenarioChosen?.kit_home_management ?? null,
        indexScenarioChosen: monInstallation.indexOptionChosen,
        study_id: studyInformation?.study_id || "",
        panel_indices: scenarioChosen.calepinage_chosen_indexes,
      });
      return;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="DimensionComponent">
      <Layout>
        {isLoading || isGetSageKitPVinfoLoading ? (
          <div className="ProfitabilityComponent__LoadingContainer">
            <LoadingComponent diameter={60} />
          </div>
        ) : errorMessage ? (
          <ErrorMessage errorMessage={errorMessage} />
        ) : (
          <div className="DimensionComponent__Container">
            <div className="DimensionComponent__ImageContainer">
              <PanelsDrawing
                imageHeight={500}
                imageWidth={500}
                panelsCoordinatesOrtho={
                  scenarioChosen.calepinage_chosen_ortho_coordinates
                }
                panelsCoordinatesSolarPotential={
                  scenarioChosen.calepinage_chosen_solar_coordinates
                }
                imageOrthoBase64={
                  "data:image/png;base64, " + scenarioChosen?.calepinage_image
                }
                imageSolarPotentialBase64={
                  "data:image/png;base64, " +
                  scenarioChosen?.solar_potential_image
                }
                calepinage={scenarioChosen.calepinage_chosen_indexes}
                scale={1.5}
                isSolarPotential={true}
              />
            </div>
            <div className="DimensionComponent__InfosSection">
              <div className="DimensionComponent__Table">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>Type d’installation</p>
                      </td>
                      <td>
                        <p>
                          <strong>Surimposition</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Calepinage</p>
                      </td>
                      <td>
                        <p>
                          <strong>
                            {monInstallation.isPersonalizedScenario
                              ? "Personnalisé"
                              : "Portrait " +
                                monInstallation.dimensionnement?.panel_decomposition
                                  .map((decomposition) => {
                                    return `${decomposition[0]}x${decomposition[1]}`;
                                  })
                                  .join(" et ")}
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Toiture utilisée</p>
                      </td>
                      <td>
                        <p>
                          <strong>
                            {monInstallation.dimensionnement?.panels_area ??
                              "Information indisponible"}{" "}
                            m²
                          </strong>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="DimensionComponent__Table">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>Puissance de la centrale</p>
                      </td>
                      <td>
                        <p>
                          <strong>
                            {(scenarioChosen?.power_needed &&
                              scenarioChosen?.power_needed / 1000) ||
                              "Information indisponible"}{" "}
                            kWc
                          </strong>
                        </p>
                      </td>
                    </tr>
                    {SAGEItemsInfo.filter(
                      (item) =>
                        item.NO_RefDet === "THOMSON400" ||
                        item.NO_RefDet === "THOMSON500"
                    ).map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="DimensionComponent__ProductDetails">
                              <h3>{item.AR_Design}</h3>
                              <div>
                                {item?.Puissance && (
                                  <p>Puissance : {item.Puissance}</p>
                                )}
                                {item?.Dimensions && (
                                  <p>Dimensions : {item.Dimensions}</p>
                                )}
                                {item?.Poid && <p>Poids : {item.Poid}</p>}
                              </div>
                              {item["Lien fiche produit"] && (
                                <div className="DimensionComponent__TechnicalSheet">
                                  <img src={filetext} alt="file text picto" />
                                  <TextLink
                                    href={item["Lien fiche produit"]}
                                    name="Fiche technique"
                                    target="_blank"
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <p>
                              <strong>{item.NO_Qte}</strong>
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                    {(scenarioChosen?.number_of_batteries || 0) > 0
                      ? SAGEItemsInfo.filter(
                          (item) =>
                            item.FA_CodeFamille?.toLowerCase() === "batterie"
                        ).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="DimensionComponent__ProductDetails">
                                  <h3>{item.AR_Design}</h3>
                                  <div>
                                    {item?.Puissance && (
                                      <p>
                                        Capacité totale :{" "}
                                        {item["Capacité de la batterie"]}
                                      </p>
                                    )}
                                    {item?.Dimensions && (
                                      <p>Dimensions : {item.Dimensions}</p>
                                    )}
                                    {item?.Poid && <p>Poids : {item.Poid}</p>}
                                  </div>
                                  {item["Lien fiche produit"] && (
                                    <div className="DimensionComponent__TechnicalSheet">
                                      <img
                                        src={filetext}
                                        alt="file text picto"
                                      />
                                      <TextLink
                                        href={item["Lien fiche produit"]}
                                        name="Fiche technique"
                                        target="_blank"
                                      />
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <p>
                                  <strong>
                                    {scenarioChosen.number_of_batteries +
                                      " x " +
                                      scenarioChosen.single_battery_capacity +
                                      " kWh"}
                                  </strong>
                                </p>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                    {scenarioChosen.kit_home_management
                      ? SAGEItemsInfo.filter(
                          (item) =>
                            item.FA_CodeFamille?.toLowerCase() === "domotique"
                        ).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="DimensionComponent__ProductDetails">
                                  <h3>{item.AR_Design}</h3>
                                  {item["Lien fiche produit"] && (
                                    <div className="DimensionComponent__TechnicalSheet">
                                      <img
                                        src={filetext}
                                        alt="file text picto"
                                      />
                                      <TextLink
                                        href={item["Lien fiche produit"]}
                                        name="Fiche technique"
                                        target="_blank"
                                      />
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <p>
                                  <strong>1</strong>
                                </p>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                    {SAGEItemsInfo.filter(
                      (item) =>
                        item.FA_CodeFamille?.toLowerCase() === "onduleur"
                    ).map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="DimensionComponent__ProductDetails">
                              <h3>{item.AR_Design}</h3>
                              {item["Lien fiche produit"] && (
                                <div className="DimensionComponent__TechnicalSheet">
                                  <img src={filetext} alt="file text picto" />
                                  <TextLink
                                    href={item["Lien fiche produit"]}
                                    name="Fiche technique"
                                    target="_blank"
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <p>
                              <strong>{item.NO_Qte}</strong>
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                    {SAGEItemsInfo.filter(
                      (item) =>
                        item.FA_CodeFamille?.toLowerCase() === "optimiseur"
                    ).map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="DimensionComponent__ProductDetails">
                              <h3>{item.AR_Design}</h3>
                              {item["Lien fiche produit"] && (
                                <div className="DimensionComponent__TechnicalSheet">
                                  <img src={filetext} alt="file text picto" />
                                  <TextLink
                                    href={item["Lien fiche produit"]}
                                    name="Fiche technique"
                                    target="_blank"
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <p>
                              <strong>{item.NO_Qte}</strong>
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </div>
  );
};
