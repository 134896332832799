import {
  IDailySelfConsumptionData,
  IDailySelfConsumptionDataFormated,
} from "../../interfaces/generalInterfaces";

export const dailySelfconsumptionDataFormat = (
  dataToFormat: IDailySelfConsumptionData | null
) => {
  const dataFormat: IDailySelfConsumptionDataFormated[] = [];

  if (!dataToFormat) return [];

  for (const key in dataToFormat) {
    dataFormat.push({
      date: key,
      network_consumption: dataToFormat[key].network_consumption * 100,
      solar_consumption: dataToFormat[key].solar_consumption * 100,
    });
  }

  return dataFormat;
};
