import "./login.scss";

// Components import
import { AuthenticationWindow } from "../../containers/Authentication/AuthenticationWindow/AuthenticationWindow";

export const Login = () => {
  return (
    <div className="login">
      <AuthenticationWindow />
    </div>
  );
};
