//Interface import
import { IHourlyConsumptionProductionSingleDataFormated } from "../../interfaces/generalInterfaces";

//local interface
interface IDataWithBattery {
  hour: number;
  consumption_shifted: number;
  consumption: number;
  production: number;
  autoconso: number;
  batteryStorage: number;
}

export const addBatteryData = (
  data: IHourlyConsumptionProductionSingleDataFormated[],
  batteryQuantity: number
) => {
  const batteryCapacity = 10; //battery capacity in kWh sold by Tuco

  let batteryCapacityRemaining = 0;

  const dataWithBatterie: IDataWithBattery[] = [];

  data.map((singleData) => {
    // If production is upper than consumption, and battery capacity is not at max, battery storage is possible. Battery charges with production surplus
    if (
      singleData.production > singleData.consumption_shifted &&
      batteryCapacityRemaining < batteryQuantity * batteryCapacity
    ) {
      dataWithBatterie.push({
        ...singleData,
        batteryStorage: singleData.production,
      });
      batteryCapacityRemaining =
        batteryCapacityRemaining + singleData.production <
        batteryQuantity * batteryCapacity
          ? batteryCapacityRemaining + singleData.production
          : batteryQuantity * batteryCapacity;
      return null;
    }

    // If production is lower than consumption, and battery capacity is not at 0, we can use battery for consumption.
    if (
      singleData.production < singleData.consumption_shifted &&
      batteryCapacityRemaining > 0
    ) {
      dataWithBatterie.push({
        ...singleData,
        batteryStorage: singleData.consumption_shifted,
      });
      batteryCapacityRemaining =
        batteryCapacityRemaining - singleData.consumption_shifted > 0
          ? batteryCapacityRemaining - singleData.consumption_shifted
          : 0;
      return null;
    }

    dataWithBatterie.push({ ...singleData, batteryStorage: 0 });

    return null;
  });

  return dataWithBatterie;
};
