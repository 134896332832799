// Service import
import {
  INavigateData,
  useNavigationHook,
} from "../../services/useNavigation/useNavigation";

//Component Data import
import { LoadStudyDataHOC } from "../../containers/LoadStudyDataHOC/LoadStudyDataHOC";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import React from "react";
import { IGoTo, IOreeStudyType } from "../../interfaces/generalInterfaces";

interface ComponentProps {
  goNext: (data?: INavigateData<any>) => void;
  goBack: () => void;
  goTo: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
  setIsGoToFunctionActive: React.Dispatch<React.SetStateAction<boolean>>;
  study_type: IOreeStudyType;
  navigationData?: INavigateData<any> | null;
}

interface Props {
  componentsData: {
    Title: string;
    Component: ({
      goNext,
      goBack,
      goTo,
      setIsGoToFunctionActive,
      navigationData,
    }: ComponentProps) => JSX.Element;
  }[][];
  study_type: IOreeStudyType;
}

export const NavigationContainer = ({ componentsData, study_type }: Props) => {
  const allComponentDataMutable = componentsData;

  const {
    currentStepIndex,
    goNext,
    goBack,
    goTo,
    allTitles,
    currentSubStepIndex,
    setIsGoToFunctionActive,
    goToLastPageVisited,
    navigationData,
    // @ts-ignore-next-line
  } = useNavigationHook(allComponentDataMutable);

  const Component =
    allComponentDataMutable[currentStepIndex].length > 1
      ? allComponentDataMutable[currentStepIndex][currentSubStepIndex].Component
      : allComponentDataMutable[currentStepIndex][0].Component;

  return (
    <>
      <NavigationBar
        allTitles={allTitles}
        currentStepIndex={currentStepIndex}
        currentSubStepIndex={currentSubStepIndex}
        goTo={goTo}
        isBurgerMenuVisible
        isGoBackToVisible
        isOngletVisible={true}
        study_type={study_type}
      />
      <LoadStudyDataHOC goToLastPageVisited={goToLastPageVisited}>
        <Component
          goNext={goNext}
          goBack={goBack}
          goTo={goTo}
          setIsGoToFunctionActive={setIsGoToFunctionActive}
          study_type={study_type}
          navigationData={navigationData ?? undefined}
        />
      </LoadStudyDataHOC>
    </>
  );
};
