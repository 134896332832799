//Style import
import "./repartitionEnergetique.scss";

//Service import
import { IConsumptionsDataDonuts } from "../../services/RenovationGlobale/MaSituationEnergetique/useGetConsumptionDonuts";
import { euroShortFormat } from "../../services/euroFormatter";

//Components import
import { RepartitionEnergetiqueDonut } from "../RepartitionEnergetiqueDonut/RepartitionEnergetiqueDonut";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";

//Local interface
interface LocalProps {
  consumptionDonutsData: IConsumptionsDataDonuts | null;
  errorMessageGetConsumptionDonuts: string;
  isLoadingGetConsumptionDonuts: boolean;
}

export const RepartitionEnergetique = ({
  consumptionDonutsData,
  errorMessageGetConsumptionDonuts,
  isLoadingGetConsumptionDonuts,
}: LocalProps) => {
  return (
    <div className="RepartitionEnergetique">
      <h2>Répartition de ma consommation énergétique</h2>

      {isLoadingGetConsumptionDonuts ? (
        <LoadingComponent diameter={60} />
      ) : errorMessageGetConsumptionDonuts ? (
        <ErrorMessage errorMessage={errorMessageGetConsumptionDonuts} />
      ) : !consumptionDonutsData?.inner_ring ? (
        <ErrorMessage errorMessage={"Pas de données disponibles"} />
      ) : (
        <div className="RepartitionEnergetique__TextContainer">
          <p>Montant total de la facture énergétique :</p>
          <div className="RepartitionEnergetique__GraphContainer">
            <RepartitionEnergetiqueDonut
              consumptionDonutsData={consumptionDonutsData}
            />
          </div>
          <span>
            {euroShortFormat(consumptionDonutsData?.total_bill || 0)}
            /an
          </span>
        </div>
      )}
    </div>
  );
};
