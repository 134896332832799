//Style import
import "./greenValueComponent.scss";

//Libraries import
import { useState } from "react";

//Component import
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";

//Image import
import greenValueIMG from "../../../assets/images/greenValue/greenValue.svg";

//Service import
import { euroShortFormat } from "../../../services/euroFormatter";

export const GreenValueComponent = ({
  greenValue,
}: {
  greenValue: number[];
}) => {
  const [houseValue, setHouseValue] = useState<number | null>(0);

  return (
    <div className="GreenValueComponent">
      <p>
        La valeur verte représente la plus-value immobilière réalisée par une
        meilleure performance énergétique et environnementale de votre logement.
      </p>
      <h2>Estimez la valeur de votre maison grâce au simulateur</h2>
      <div className="GreenValueComponent__CalculusContainer">
        <div className="GreenValueComponent__InputsContainer">
          <div className="GreenValueComponent__InputContainer">
            <p>Valeur actuelle du bien :</p>
            <TextInput
              value={houseValue?.toString()}
              iconHidden={false}
              iconType="euros"
              onChange={(e) => {
                setHouseValue(Number(e.target.value) || 0);
              }}
            />
          </div>
          <div className="GreenValueComponent__InputContainer">
            <p>Valeur verte :</p>
            <TextInput
              isDisabled
              value={`Entre ${greenValue[0]}% et ${greenValue[1]}%`}
            />
          </div>
        </div>
        <div className="GreenValueComponent__ImageContainer">
          <img src={greenValueIMG} alt="green value illustration" />
        </div>
        <div className="GreenValueComponent__ResultContainer">
          <div className="GreenValueComponent__Result">
            <p>Plus-value estimée</p>
            <h3>
              Entre {euroShortFormat(((houseValue || 0) * greenValue[0]) / 100)}{" "}
              et {euroShortFormat(((houseValue || 0) * greenValue[1]) / 100)}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
