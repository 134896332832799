//css import
import "./maSituationEnergetiqueRG.scss";

//library import
import React, { useEffect, useState } from "react";

//lnterfaces import
import { IGoTo } from "../../interfaces/generalInterfaces";

//redux import
import { useAppSelector } from "../../redux/store/hook";

//services import
import {
  IConsumptionsDataDonuts,
  useGetConsumptionDonuts,
} from "../../services/RenovationGlobale/MaSituationEnergetique/useGetConsumptionDonuts";
import { useGetHeatLoss } from "../../services/RenovationGlobale/MaSituationEnergetique/useGetHeatLosses";
import { useGetEnergyLabel } from "../../services/RenovationGlobale/MaSituationEnergetique/useGetEnergyLabel";
import { useSaveLastPageVisited } from "../../services/Oree2/useSaveLastPageVisited";
import { INavigateData } from "../../services/useNavigation/useNavigation";
import { useGetPredictedBills } from "../../services/RenovationGlobale/MaSituationEnergetique/useGetPredictedBills";

//components import
import { PotentielSolaireRG } from "../../components/PotentielSolaireRG/PotentielSolaireRG";
import { SubHeader } from "../../containers/SubHeader/SubHeader";
import { EnergyLabel } from "../../components/EnergyLabel/EnergyLabel";
import { Layout } from "../../components/Layout/Layout";
import { RepartitionEnergetique } from "../../components/RepartitionEnergetique/RepartitionEnergetique";
import { HeatLosses } from "../../components/HeatLosses/HeatLosses";
import { PredictedBills } from "../../components/PredictedBills/PredictedBills";

// Interfaces import
import {
  IHeatLoss,
  IPretictedBills,
} from "../../interfaces/renoglobaleInterface";
import { IEnergyLabel } from "../../interfaces/renoglobaleInterface";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

//interfaces declarations
interface Props {
  goNext: (data?: INavigateData<any>) => void;
  goBack: () => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
  setIsGoToFunctionActive: React.Dispatch<React.SetStateAction<boolean>>;
}
export const MaSituationEnergetiqueRG = ({ goNext }: Props) => {
  const { studyInformation, userAuthInfo, solarPotentialStepsStatus } =
    useAppSelector((state) => state);

  const {
    getConsumptionDonuts,
    errorMessage: errorMessageGetConsumptionDonuts,
    isLoading: isLoadingGetConsumptionDonuts,
  } = useGetConsumptionDonuts();
  const {
    getHeatLoss,
    isLoading: isLoadingGetHeatLoss,
    errorMessage: errorMessageGetHeatLoss,
  } = useGetHeatLoss();
  const {
    getEnergyLabel,
    isLoading: isLoadingGetEnergyLabel,
    errorMessage: errorMessageGetEnergyLabel,
  } = useGetEnergyLabel();

  const { getPredictedBills, errorMessage: errorMessageGetPredictedBills } =
    useGetPredictedBills();

  const { saveLastPageVisited } = useSaveLastPageVisited();

  const [consumptionDonutsData, setConsumptionDonutsData] =
    useState<IConsumptionsDataDonuts | null>(null);
  const [heatLossesData, setHeatLossesData] = useState<IHeatLoss[]>([]);
  const [energyLabelData, setEnergyLabelData] = useState<IEnergyLabel | null>();
  const [predictedBills, setPredictedBills] = useState<IPretictedBills | null>(
    null
  );

  const fetchHeatLossData = async () => {
    const data = await getHeatLoss({
      userToken: userAuthInfo.userToken || "",
      study_id: studyInformation.study_id,
    });

    data && setHeatLossesData(data);
  };

  const fetchEnergyLabelData = async () => {
    const data = await getEnergyLabel({
      userToken: userAuthInfo.userToken || "",
      study_id: studyInformation.study_id,
    });

    data && setEnergyLabelData(data);
  };

  const [isLoadingGetPredictedBills, setIsLoadingGetPredictedBills] =
    useState(true);

  const fetchPredictedBills = async () => {
    const data = await getPredictedBills({
      userToken: userAuthInfo.userToken || "",
      study_id: studyInformation.study_id,
    });

    data && setPredictedBills(data);
    setIsLoadingGetPredictedBills(false);
  };

  const fetchConsumptionDonutsData = async () => {
    const consumptionDonutsResponse = await getConsumptionDonuts({
      userToken: userAuthInfo.userToken || "",
      study_id: studyInformation.study_id,
    });

    consumptionDonutsResponse &&
      setConsumptionDonutsData(consumptionDonutsResponse);
  };

  // Data backend constraint : we need to launch a first call that will save the dpe inputs in their database before sending in parallel the other calls
  const getMaSituationEnergetiqueInformation = async () => {
    await fetchConsumptionDonutsData();
    await Promise.all([fetchHeatLossData(), fetchEnergyLabelData()]);
    //It seams that getPredictedBills needs the other calls to be done to run correctly
    fetchPredictedBills();
  };

  const handleGoNextHeaderButtonClick = () => {
    saveLastPageVisited({
      last_page_visited: "my_energetic_situation",
      study_id: studyInformation?.study_id || null,
      userToken: userAuthInfo.userToken,
    });
    goNext();
  };

  const canGoNext = (): boolean => {
    const isEverythingLoaded =
      !solarPotentialStepsStatus.isCheckSolarPotentialAvailabilityLoading &&
      !solarPotentialStepsStatus.isGetSolarPotentialLoading &&
      solarPotentialStepsStatus.isSolarPotentialKOForm === false;

    const isPotentielSolaireKOFormCompleted =
      studyInformation.roof_type &&
      studyInformation.roof_area_to_equip &&
      studyInformation.roof_inclinaison != null &&
      studyInformation.roof_exposition &&
      studyInformation.roof_solar_mask != null;

    if (isEverythingLoaded || isPotentielSolaireKOFormCompleted) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // fetchGetUserInformation();
    getMaSituationEnergetiqueInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"MaSituationEnergetiqueRG"}>
      <Layout>
        <SubHeader
          title="Ma situation énergétique"
          goNext={() =>
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            })
          }
          clientInfo={`${
            studyInformation.lastname &&
            studyInformation.lastname?.charAt(0).toUpperCase() +
              studyInformation.lastname?.slice(1)
          } ${
            studyInformation.firstname &&
            studyInformation.firstname?.charAt(0).toUpperCase() +
              studyInformation.firstname?.slice(1)
          }, ${studyInformation.address || ""}`}
        />
        <div className={"MaSituationEnergetiqueRG__body"}>
          <div className={"MaSituationEnergetiqueRG__TwoBlocks"}>
            <div className={"MaSituationEnergetiqueRG__block"}>
              <RepartitionEnergetique
                consumptionDonutsData={consumptionDonutsData}
                errorMessageGetConsumptionDonuts={
                  errorMessageGetConsumptionDonuts
                }
                isLoadingGetConsumptionDonuts={isLoadingGetConsumptionDonuts}
              />
            </div>
            <div className={"MaSituationEnergetiqueRG__block"}>
              <HeatLosses
                heatLossesData={heatLossesData}
                errorMessage={errorMessageGetHeatLoss}
                isLoading={
                  isLoadingGetHeatLoss || isLoadingGetConsumptionDonuts
                }
              />
            </div>
          </div>
          <div className={"MaSituationEnergetiqueRG__block"}>
            <EnergyLabel
              energyLabelData={energyLabelData}
              errorMessage={errorMessageGetEnergyLabel}
              isLoading={
                isLoadingGetEnergyLabel || isLoadingGetConsumptionDonuts
              }
            />
          </div>
          <div className={"MaSituationEnergetiqueRG__block"}>
            <PredictedBills
              predictedBillsData={predictedBills}
              errorMessage={errorMessageGetPredictedBills}
              isLoading={isLoadingGetPredictedBills}
            />
          </div>
          <div className={"MaSituationEnergetiqueRG__block"}>
            <PotentielSolaireRG
              studyInformation={studyInformation}
              userAuthInfo={userAuthInfo}
              goNext={handleGoNextHeaderButtonClick}
            />
          </div>
          {solarPotentialStepsStatus.isSolarPotentialKOForm === false ? (
            <div className="MaSituationEnergetiqueRG__ButtonContainer">
              <CTAButton
                name="Suivant"
                onClick={handleGoNextHeaderButtonClick}
                isDisabled={!canGoNext()}
              />
            </div>
          ) : null}
        </div>
      </Layout>
    </div>
  );
};
