import styled from "styled-components";
import { FC } from "react";

// Styles import
import { DSColors } from "../../../../styles/variables";

// Local interface declaration
export interface DotProps {
  isActive?: boolean;
}

const Div = styled.div<{ isActive?: boolean }>`
  background-color: ${(props) =>
    props.isActive ? DSColors.Mint : DSColors.MintLight02};
  width: ${(props) => (props.isActive ? "1.25rem" : "0.7rem")};
  height: 0.7rem;
  border-radius: ${(props) => (props.isActive ? "80px" : "50%")};
`;

export const Dot: FC<DotProps> = ({ isActive = false }) => {
  return <Div isActive={isActive}></Div>;
};
