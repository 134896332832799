import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  container: {
    fontSize: 10,
    color: "#525360",
    padding: "0 5 0 0",
    marginBottom: 10,
    flexWrap: "wrap",
  },
  title: {
    fontFamily: "DMSansBold",
    marginBottom: 2.5,
    fontSize: 8,
  },
  ul: {
    fontFamily: "DMSansRegular",
  },
  li: {
    marginBottom: 1.5,
    flexWrap: "wrap",
    fontSize: 8,
  },
});

interface IProps {
  title: string;
  list: React.ReactElement[];
}

export const PdfTitreListe = ({ title, list }: IProps) => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.ul}>
        {list.map((item, index) => {
          return (
            <Text key={s4() + index} style={styles.li}>
              {item}
            </Text>
          );
        })}
      </View>
    </View>
  );
};
