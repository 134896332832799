import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useDisconnectApp } from "../../services/useDisconnectApp";

// Set react query error to be AxiosError by default
declare module "@tanstack/react-query" {
  interface Register {
    defaultError: AxiosError;
  }
}

type GenericErrorMessage = {
  genericErrorMessage: string;
};

type UseQueryResultExtra<ReturnType, ErrorResponse> = UseQueryResult<
  ReturnType,
  AxiosError<ErrorResponse>
> &
  GenericErrorMessage;

/**
 * Custom hook for making authenticated queries.
 * If the query returns a 401 error, it will disconnect the app.
 */
export function useAuthQuery<ReturnType, ErrorResponse>(
  options: UseQueryOptions<ReturnType, AxiosError<ErrorResponse>>
): UseQueryResultExtra<ReturnType, ErrorResponse> {
  const { disconnectApp } = useDisconnectApp();

  const query = useQuery(options);

  let genericErrorMessage = "";
  // See Axios doccumentation on error handling : https://axios-http.com/docs/handling_errors
  if (query.isError) {
    if (query.error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (query.error.response?.status === 401) {
        genericErrorMessage = "Non autorisé";
        disconnectApp();
      }
      console.error(query.error.response);
    } else if (query.error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js

      genericErrorMessage = "Serveur injoignable.";
      console.error(query.error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      genericErrorMessage = query.error.message || "Erreur inconnue";
      console.error(query.error);
    }
  }

  return { ...query, genericErrorMessage };
}
