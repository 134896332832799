// Styles import
import "./subscriptionModale.scss";

// Libraries import
import { useEffect } from "react";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { Switch } from "@web/shared/dist/components/DesignSystem/Switch/Switch";
import { SofincoSimulator } from "../../SofincoSimulator/SofincoSimulator";

// Services import
import { interestRateOptions } from "@web/shared/dist/datas/dataHypotethis";
import { useAppSelector } from "../../../redux/store/hook";
import { useSaveFinancingInformation } from "../../../services/RenovationGlobale/MonProjet/useSaveFinancingInformation";

// Interfaces import
import {
  HelpsOptions,
  IOreeStudyType,
  LoanSimulationResult,
} from "../../../interfaces/generalInterfaces";

// Loacal interfaces
interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  devisOptions: HelpsOptions;
  setDevisOptions: React.Dispatch<React.SetStateAction<HelpsOptions>>;
  subscribeFunction: () => Promise<void>;
  isSubscribeFunctionLoading: boolean;
  restToPay: number;
  study_type: IOreeStudyType;
  fundingPeriod: number;
  setFundingPeriod: React.Dispatch<React.SetStateAction<number>>;
  interestRate: string;
  setInterestRate: React.Dispatch<React.SetStateAction<string>>;
  personalContributionAmount: number;
  setPersonalContributionAmount: React.Dispatch<React.SetStateAction<number>>;
  isDeferralOption: boolean;
  setIsDeferralOption: React.Dispatch<React.SetStateAction<boolean>>;
  hasBorrowerInsurance: boolean;
  setHasBorrowerInsurance: React.Dispatch<React.SetStateAction<boolean>>;
  hasCoBorrower: boolean;
  setHasCoBorrower: React.Dispatch<React.SetStateAction<boolean>>;
  loanSimulationResult: LoanSimulationResult;
  setLoanSimulationResult: React.Dispatch<
    React.SetStateAction<LoanSimulationResult>
  >;
  isLoanOptionChosen: boolean;
  setIsLoanOptionChosen: React.Dispatch<React.SetStateAction<boolean>>;
  totalMPRHelp: number;
  totalCEEHelp: number;
  bdcStatus: string | undefined;
}

export const SubscriptionModale = ({
  isModalOpen,
  setIsModalOpen,
  devisOptions,
  setDevisOptions,
  subscribeFunction,
  isSubscribeFunctionLoading,
  restToPay,
  study_type,
  fundingPeriod,
  setFundingPeriod,
  interestRate,
  setInterestRate,
  personalContributionAmount,
  setPersonalContributionAmount,
  isDeferralOption,
  setIsDeferralOption,
  hasBorrowerInsurance,
  setHasBorrowerInsurance,
  hasCoBorrower,
  setHasCoBorrower,
  loanSimulationResult,
  setLoanSimulationResult,
  isLoanOptionChosen,
  setIsLoanOptionChosen,
  totalMPRHelp,
  totalCEEHelp,
  bdcStatus,
}: IProps) => {
  const { studyInformation, userAuthInfo } = useAppSelector((state) => state);
  const { saveFinancingInformation } = useSaveFinancingInformation();

  const isBDCToReplace =
    !!studyInformation.last_bdc_id && bdcStatus?.toLowerCase() !== "annulé"; // If we have a last_bdc_id, it means that we have already generated a BDC so we have to replace it

  const onClickSubscribe = async () => {
    // First we save asynchronoulsy the financing information in the database to save the helps_options modified by the user
    // We don't wait for the answer as it is not mandatory for the rest of the process
    saveFinancingInformation({
      deposit: isLoanOptionChosen ? personalContributionAmount : 0,
      type_of_payment: isLoanOptionChosen
        ? "Organisme de financement"
        : "Comptant",
      interest_rate: isLoanOptionChosen ? interestRate : interestRateOptions[0],
      is_deferral_option: isLoanOptionChosen ? isDeferralOption : false,
      loan_duration: isLoanOptionChosen ? fundingPeriod : 15,
      hasBorrowerInsurance: isLoanOptionChosen ? hasBorrowerInsurance : false,
      hasCoBorrower: isLoanOptionChosen ? hasCoBorrower : false,
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id || null,
      helps_options: devisOptions,
    });
    await subscribeFunction();
    setIsModalOpen(false);
  };

  // Disable background scroll when modal is open. Enable it on modal unmount
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Modale
      isModaleOpen={isModalOpen}
      title={
        isBDCToReplace ? "Annuler et remplacer" : "Générer le bon de commande"
      }
      text={
        isBDCToReplace
          ? "Vous avez déjà généré un bon de commande. En continuant, celui-ci sera annulé et remplacé par un nouveau."
          : ""
      }
      setShowModal={setIsModalOpen}
      maxWidth="50rem"
      maxHeight="95%"
      width="60%"
    >
      <div className="SubscriptionModale__PDFOptionsContainer">
        <div className="SubscriptionModale__PDFOptionsSection">
          <h3>Option de financement</h3>
          <div className="SubscriptionModale__Option">
            <Switch
              onClick={() => setIsLoanOptionChosen((state) => !state)}
              value={!isLoanOptionChosen}
              name="cashButton"
            />
            <p>Comptant</p>
          </div>
          <div className="SubscriptionModale__Option">
            <Switch
              onClick={() => setIsLoanOptionChosen((state) => !state)}
              name="loanButton"
              value={isLoanOptionChosen}
            />
            <p>Organisme de financement</p>
          </div>
          {isLoanOptionChosen ? (
            <SofincoSimulator
              fundingPeriod={fundingPeriod}
              setFundingPeriod={setFundingPeriod}
              interestRate={interestRate}
              setInterestRate={setInterestRate}
              personalContributionAmount={personalContributionAmount}
              setPersonalContributionAmount={setPersonalContributionAmount}
              isDeferralOption={isDeferralOption}
              setIsDeferralOption={setIsDeferralOption}
              loanSimulationResult={loanSimulationResult}
              setLoanSimulationResult={setLoanSimulationResult}
              loanAmount={restToPay}
              study_id={studyInformation.study_id || ""}
              totalProjectPrice={restToPay}
              userToken={userAuthInfo.userToken || ""}
              hasBorrowerInsurance={hasBorrowerInsurance}
              setHasBorrowerInsurance={setHasBorrowerInsurance}
              hasCoBorrower={hasCoBorrower}
              setHasCoBorrower={setHasCoBorrower}
              helpsOptions={devisOptions}
            />
          ) : null}
        </div>

        <div className="SubscriptionModale__PDFOptionsSection">
          <h3>Option du devis</h3>
          <div className="SubscriptionModale__Option">
            <Switch
              onClick={() => {
                setDevisOptions((state) => {
                  // If we don't display the helps on the documents, we must not deduct them
                  if (state.isHelpsDisplayed) {
                    return {
                      ...state,
                      isHelpsDisplayed: !state.isHelpsDisplayed,
                      isCEEHelpsDeducted: false,
                      isMPRHelpsDeducted: false,
                    };
                  }
                  return {
                    ...state,
                    isHelpsDisplayed: !state.isHelpsDisplayed,
                  };
                });
              }}
              value={devisOptions.isHelpsDisplayed}
              name="helpButton"
            />
            <p>
              Afficher les aides sur le devis, l'étude et le bon de commande
            </p>
          </div>

          {devisOptions.isHelpsDisplayed && study_type !== "photovoltaique" ? (
            <>
              {totalCEEHelp ? (
                <div className="SubscriptionModale__Option">
                  <Switch
                    onClick={() =>
                      setDevisOptions((state) => {
                        return {
                          ...state,
                          isCEEHelpsDeducted: !state.isCEEHelpsDeducted,
                        };
                      })
                    }
                    value={devisOptions.isCEEHelpsDeducted}
                    name="toggle2"
                  />
                  <p>Déduire les aides CEE du reste à charge</p>
                </div>
              ) : null}

              {totalMPRHelp ? (
                <div className="SubscriptionModale__Option">
                  <Switch
                    onClick={() =>
                      setDevisOptions((state) => {
                        return {
                          ...state,
                          isMPRHelpsDeducted: !state.isMPRHelpsDeducted,
                        };
                      })
                    }
                    value={devisOptions.isMPRHelpsDeducted}
                    name="toggle3"
                  />
                  <p>Déduire les aides MPR du reste à charge</p>
                </div>
              ) : null}
            </>
          ) : null}
        </div>

        <div className="SubscriptionModale__ButtonContainer">
          <CTAButton
            name={
              isBDCToReplace ? "Annuler et remplacer" : "Souscrire à l’offre"
            }
            onClick={onClickSubscribe}
            isLoading={isSubscribeFunctionLoading}
            isDisabled={isLoanOptionChosen && !loanSimulationResult}
          />
        </div>
      </div>
    </Modale>
  );
};
