import { Reducer, AnyAction } from "redux";

// Other imports
import * as types from "./constants";
import { initialState } from "./initialState";

// Services import
import {
  setUserAuthInfo,
  setInitalState,
  setCalepinagesInfo,
  setIndexScenariosOptionChosen,
  setDimensionnement,
  setPerformance,
  setImpactEnvironnemental,
  setProfitability,
  setCommercialUserInformation,
  resetAllStudyInformation,
  setStudyInfo,
  setPersonalizedScenario,
  setIsPersonalizedScenario,
  setSAGEItemsInfo,
  setScenarioChosenInfo,
  setCartProducts,
  setRGProjectKPI,
  setSolarPotentialStepsStatus,
} from "./reducerFunctions/sharedReducerFunctions";

// Interfaces import
import { IAppStates } from "../interfaces/generalInterfaces";

// REDUCER FUNCTIONS : UPDATE STATE IN REDUX STORE

// Redux reducer
export const appReducer: Reducer<IAppStates, AnyAction> = (
  state = initialState,
  action
): IAppStates => {
  switch (action.type) {
    case types.SET_USER_AUTH_INFO:
      return setUserAuthInfo(state, action);
    case types.SET_COMMERCIAL_USER_INFORMATION:
      return setCommercialUserInformation(state, action);
    case types.SET_STUDY_INFO:
      return setStudyInfo(state, action);
    case types.SET_MES_SCENARIOS_INFO:
      return setCalepinagesInfo(state, action);
    case types.SET_INDEX_SCENARIOS_OPTION_CHOSEN:
      return setIndexScenariosOptionChosen(state, action);
    case types.SET_PERSONALIZED_SCENARIO:
      return setPersonalizedScenario(state, action);
    case types.SET_IS_PERSONALIZED_SCENARIO:
      return setIsPersonalizedScenario(state, action);
    case types.SET_DIMENSIONNEMENT:
      return setDimensionnement(state, action);
    case types.SET_PERFORMANCE:
      return setPerformance(state, action);
    case types.SET_PROFITABILITY:
      return setProfitability(state, action);
    case types.SET_IMPACTENVIRONNEMENTAL:
      return setImpactEnvironnemental(state, action);
    case types.SET_SAGE_ITEMS_INFO:
      return setSAGEItemsInfo(state, action);
    case types.RESET_ALL_STUDY_INFO:
      return resetAllStudyInformation(state, action);
    case types.SET_INITAL_STATE:
      return setInitalState();
    case types.SET_SCENARIO_CHOSEN:
      return setScenarioChosenInfo(state, action);
    case types.SET_CART_PRODUCTS:
      return setCartProducts(state, action);
    case types.SET_RG_PROJECT_KPI:
      return setRGProjectKPI(state, action);
    case types.SET_SOLAR_POTENTIAL_STEPS_STATUS:
      return setSolarPotentialStepsStatus(state, action);
    default:
      return state;
  }
};
