import { Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  titleMentions: {
    fontSize: 8,
    padding: "0 5 0 20",
    color: "#525360",
    fontFamily: "DMSansBold",
    lineHeight: "1.7px",
    marginBottom: 2,
  },
  paragraphMentions: {
    fontSize: 8,
    padding: "0 10 0 20",
    color: "#525360",
    fontFamily: "DMSansRegular",
    lineHeight: "1.7px",
    marginBottom: 2,
  },
});
interface IProps {
  title: string;
  paragraph: JSX.Element[];
}

export const PdfComplexeParagraphMentions = ({ title, paragraph }: IProps) => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    <View>
      <Text style={styles.titleMentions}>{title}</Text>
      <View style={styles.paragraphMentions}>
        {paragraph.map((elem, _key) => {
          return <View key={s4()}>{elem}</View>;
        })}
      </View>
    </View>
  );
};
