import { Text, StyleSheet, Font, View, Image } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../../../assets/pdf/fonts/DMSansBold.ttf";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  containerEncadre: {
    border: "1px solid #e9eaeb",
    width: "48%",
    backgroundColor: "#f8f8f8",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderRadius: 5,
  },
  containerEncadre__image: {
    width: "35",
    marginRight: 10,
  },
  containerEncadre__containerText: {
    display: "flex",
    fontSize: 8,
    flexDirection: "column",
  },
  containerEncadre__text: {
    marginTop: 8,
    fontSize: 11,
    fontFamily: "DMSansBold",
  },
});

interface Props {
  logo: string;
  title: string;
  text: string | undefined | null | 0;
}

export const PdfEncadreAvecLogo = ({ logo, title, text }: Props) => {
  return (
    <View style={styles.containerEncadre}>
      <Image style={styles.containerEncadre__image} src={logo} />
      <View style={styles.containerEncadre__containerText}>
        <Text>{title}</Text>
        <Text style={styles.containerEncadre__text}>{text}</Text>
      </View>
    </View>
  );
};
