import React from "react";
import {
  Bar,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  BarChart,
} from "recharts";
import { NameType } from "recharts/types/component/DefaultTooltipContent";
import "./SavingsGraphRG.scss";

interface IProps {
  savingsGraphData: {
    annee: string;
    heating: number;
    ecs: number;
    autoconsumption: number;
    resale: number;
  }[];
}

interface IPayload {
  dataKey: string;
  fill: string;
  barSize: number;
  stackId: string;
  xAxisId: number;
  yAxisId: number;
  legendType: string;
  minPointSize: number;
  hide: boolean;
  data: any[];
  layout: string;
  isAnimationActive: boolean;
  animationBegin: number;
  animationDuration: number;
  animationEasing: string;
  radius?: number[];
}

export const SavingsGraphRG = ({ savingsGraphData }: IProps) => {
  const isHeating =
    savingsGraphData.reduce((acc, prev) => acc + prev.heating, 0) !== 0;
  const isEcs = savingsGraphData.reduce((acc, prev) => acc + prev.ecs, 0) !== 0;
  const isAutoconsumption =
    savingsGraphData.reduce((acc, prev) => acc + prev.autoconsumption, 0) !== 0;
  const isResale =
    savingsGraphData.reduce((acc, prev) => acc + prev.resale, 0) !== 0;

  const renderLegend = (props: any) => {
    const payload: IPayload[] = props.payload;

    const legendDictionnary = {
      heating: "Chauffage",
      ecs: "ECS",
      autoconsumption: "autoconsommation",
      resale: "Revente",
    };

    type keyOfDictionnary = keyof typeof legendDictionnary;

    return (
      <ul className="legendContainer">
        {payload &&
          payload
            .filter((elem) => {
              if (isHeating && elem.dataKey === "heating") return elem;
              if (isEcs && elem.dataKey === "ecs") return elem;
              if (isAutoconsumption && elem.dataKey === "autoconsumption")
                return elem;
              if (isResale && elem.dataKey === "resale") return elem;
              return null;
            })
            .map((entry: any, index: any) => (
              <li
                key={`item-${index}`}
                className={`legendItem`}
                style={{ color: entry.color }}
              >
                <div
                  className="colorPastille"
                  style={{ backgroundColor: entry.color }}
                />
                <p>
                  {legendDictionnary?.[
                    entry.value as keyOfDictionnary
                  ].toUpperCase()}
                </p>
              </li>
            ))
            .reverse()}
      </ul>
    );
  };

  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<number, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className="customTooltip">
          {isHeating ? (
            <div className="labelPriceContainer">
              <div
                className="label colorPastille"
                style={{ backgroundColor: payload[3].color }}
              />
              <p className="price">{`${payload[0].payload.heating} €`}</p>
            </div>
          ) : null}
          {isEcs ? (
            <div className="labelPriceContainer">
              <div
                className="label colorPastille"
                style={{ backgroundColor: payload[2].color }}
              />
              <p className="price">{`${payload[0].payload.ecs} €`}</p>
            </div>
          ) : null}
          {isAutoconsumption ? (
            <div className="labelPriceContainer">
              <div
                className="label colorPastille"
                style={{ backgroundColor: payload[1].color }}
              />
              <p className="price">{`${payload[0].payload.autoconsumption} €`}</p>
            </div>
          ) : null}
          {isResale ? (
            <div className="labelPriceContainer">
              <div
                className="label colorPastille"
                style={{ backgroundColor: payload[0].color }}
              />
              <p className="price">{`${payload[0].payload.resale} €`}</p>
            </div>
          ) : null}
          <div>
            <p className="label">
              {`Année ${payload[0].payload.annee}`} :{" "}
              <strong>
                {payload.reduce((acc, prev) => acc + (prev.value || 0), 0)} €
              </strong>
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="SavingsGraphRG">
      <p className="SavingsGraphRG__Title">
        Économies sur les dépenses d'énergie
      </p>
      <section className="SavingsGraphRG__GraphContainer">
        <ResponsiveContainer>
          <BarChart width={100} height={350} data={savingsGraphData}>
            <XAxis
              dataKey="annee"
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#191B1E", fontSize: "0.6875rem" }}
            />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={renderLegend}
              verticalAlign="top"
              align="center"
              height={50}
            />

            <Bar dataKey="resale" fill="#46D1CA" barSize={24} stackId="a" />
            <Bar
              dataKey="autoconsumption"
              fill="#F0A14E"
              barSize={24}
              stackId="a"
            />
            <Bar dataKey="ecs" fill="#80B9F0" barSize={24} stackId="a" />
            <Bar
              dataKey="heating"
              fill="#BA95D7"
              barSize={24}
              stackId="a"
              radius={[4, 4, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </section>
      <section className="SavingsGraphRG__GraphDescription">
        <p className="Title">Évolution du prix de l’énergie</p>
        <p className="Description">
          Nous nous basons sur une hypothèse d'augmentation des prix de 5% par
          an pour les différentes énergies
          {isAutoconsumption || isResale
            ? ", tandis que votre kWh solaire reste fixe pendant toute la durée de vie de votre installation."
            : "."}
        </p>
      </section>
    </div>
  );
};
