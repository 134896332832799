// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";

// Services imports
import { DataPersonalizedScenarioPvExtended } from "../../interfaces/generalInterfaces";
import { TExtraFee } from "../../interfaces/renoglobaleInterface";

interface IProps {
  requestPayload: {
    buildingId: string | null;
    householdId: string | null;
    numberOfPanels: number | null;
    numberOfBatteries: number | null;
    kitHomeManagement: boolean | null;
    inverter_brand: string | null;
    single_battery_capacity: number | null;
    panel_indices?: number[] | null;
    extraFees: TExtraFee[];
  };
  study_id: string;
  userToken: string;
}

export const useGetSinglePersonalizedScenario = () => {
  const {
    axiosPostRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getSinglePersonalizedScenario = async ({
    requestPayload,
    userToken,
    study_id,
  }: IProps): Promise<DataPersonalizedScenarioPvExtended | void> => {
    setErrorMessage("");

    const response = await axiosPostRequest<DataPersonalizedScenarioPvExtended>(
      `${process.env.REACT_APP_EERO_URL}/oree2/pv/single_personalized_scenario/${study_id}`,
      { payload: requestPayload },
      { headers: { Authorization: `Bearer ${userToken}` } }
    );

    if (!response) {
      setErrorMessage("Pas de scenario personnalisé disponible");
      return;
    }

    return response?.data;
  };

  return {
    getSinglePersonalizedScenario,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
