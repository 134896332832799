// Libraries imports
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Components imports
import { Login } from "../pages/Login/Login";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";
import { NavigationContainer } from "../containers/NavigationContainer/NavigationContainer";
import { StartEtude } from "../pages/StartEtude/StartEtude";
import { Home } from "../pages/Home/Home";
import { MonCompte } from "../pages/MonCompte/MonCompte";
import { MesEtudes } from "../pages/MesEtudes/MesEtudes";
import { MesEtudesDetailsEtude } from "../pages/MesEtudesDetailsEtude/MesEtudesDetailsEtude";
import { Oree2Home } from "../pages/Oree2Home/Oree2Home";
import { StartEtudeRG } from "../pages/StartEtudeRG/StartEtudeRG";
import { ProjectsMap } from "../pages/ProjectsMap/ProjectsMap";
import { Ressources } from "../pages/Ressources/Ressources";

// Services imports
import { useGetUserAuthInfo } from "../services/Login/useGetUserAuthInfo";

//Other imports
import { useAppSelector } from "../redux/store/hook";
import {
  photovoltaiqueComponentsData,
  renovationGlobaleComponentsData,
} from "../data/navigationData";

export const Navigation: React.FC = () => {
  const { getUserAuthInfo } = useGetUserAuthInfo();

  const { userAuthInfo } = useAppSelector((states) => states);

  // At the opening of the portail
  useEffect(() => {
    // Get user authentication information from local storage if they exist.
    getUserAuthInfo();
    // Adding getUserAuthInfo as a dependency creates an infinit iteration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !userAuthInfo?.userToken ? (
    <Router>
      <Routes>
        <Route path="/mot-de-passe-oublie" element={<ResetPassword />} />
        <Route path="/*" element={<Login />} />
      </Routes>
    </Router>
  ) : (
    <Router>
      {/* You don't need to use an exact prop on <Route path="/"> anymore. 
      This is because all paths match exactly by default. 
      If you want to match more of the URL because you have child routes use a trailing * as in <Route path="users/*">. */}
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/oree-home" element={<Oree2Home />} />
        <Route path="/start-etude-pv" element={<StartEtude />} />
        <Route path="/start-etude-rg" element={<StartEtudeRG />} />
        {/*container for the PV path*/}
        <Route
          path="/etude/photovoltaique/:study_id"
          element={
            <NavigationContainer
              componentsData={photovoltaiqueComponentsData}
              study_type="photovoltaique"
            />
          }
        />
        {/*container for the RG path*/}
        <Route
          path="/etude/renovation-globale/:study_id"
          element={
            <NavigationContainer
              componentsData={renovationGlobaleComponentsData}
              study_type="renovation-globale"
            />
          }
        />
        <Route path="/mes-etudes" element={<MesEtudes />} />
        <Route
          path="/projetGedeon"
          element={
            userAuthInfo.companyId ===
            "b997f1a6-1bf0-e911-a812-000d3a4aabe1" ? (
              // b997f1a6-1bf0-e911-a812-000d3a4aabe1 => tucoenergie
              <ProjectsMap />
            ) : (
              <Home />
            )
          }
        />
        <Route path="/ressources" element={<Ressources />} />
        <Route
          path="/mes-etudes/:study_id"
          element={<MesEtudesDetailsEtude />}
        />
        <Route path="/mon-compte" element={<MonCompte />} />
      </Routes>
    </Router>
  );
};
