// Style import
import "./ProjectsMarkersContainer.scss";

// Libraries import
import { SwiperRef, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";

// Components import
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { CarrouselWrapper } from "@web/shared/dist/containers/CarrouselWrapper/CarrouselWrapper";
import { ProjectMarker } from "../../../pages/ProjectsMap/ProjectMarker/ProjectMarker";
import { Switch } from "@web/shared/dist/components/DesignSystem/Switch/Switch";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { DropdownV2 } from "@web/shared/dist/components/DesignSystem/Dropdowns/DropdownV2/DropdownV2";

// Services import
import { IProjectImages } from "../../../services/Oree2/ProjectsMap/useGetProjectImage";
import { useGetProjects } from "../../../services/Oree2/ProjectsMap/useGetProjects";

// Interfaces import
import {
  IChantier,
  IUserAuthInfo,
} from "../../../interfaces/generalInterfaces";

// Images import
import cross from "@web/shared/dist/assets/images/DesignSystemIcons/cross.svg";

// Local interfaces declaration
interface Props {
  userAuthInfo: IUserAuthInfo;
  swiperRef: React.RefObject<SwiperRef>;
}

export const ProjectsMarkersContainer = ({
  userAuthInfo,
  swiperRef,
}: Props) => {
  // Hooks declaration
  const { getProjects } = useGetProjects();

  // States declaration
  const [projects, setProjects] = useState<IChantier[]>([]);
  const [projectImages, setProjectImages] = useState<IProjectImages[]>([]);
  const [isModaleOpen, setIsModaleOpen] = useState(false);
  const isMarkerClicked = useRef(false);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isDisplayProjects, setIsDisplayProjects] = useState(true);
  const [isFetchingLoading, setIsFetchingLoading] = useState(false);

  const listProducts = [
    {
      label: "Batterie",
      value: "batterie",
    },
    {
      label: "Chauffe eau thermodynamique",
      value: "thermodynamique",
    },
    {
      label: "Isolation des combles perdus",
      value: "combles",
    },
    {
      label: "Isolation des planchers bas",
      value: "planchers",
    },
    {
      label: "Isolation des rampants",
      value: "rampants",
    },
    {
      label: "Isolation Thermique par l'exterieur",
      value: "isolation thermique",
    },
    {
      label: "Kit home management",
      value: "home management",
    },
    {
      label: "Panneaux photovoltaïques",
      value: "centrale",
    },
    {
      label: "Pompe à chaleur air/air",
      value: "pacairair",
    },
    {
      label: "Pompe à chaleur air/eau",
      value: "pompe à chaleur air/eau",
    },

    {
      label: "VMC",
      value: "vmc",
    },
  ];

  const fetchProjects = async () => {
    setIsFetchingLoading(true);
    const projects = await getProjects({ userToken: userAuthInfo.userToken });
    setProjects(projects);
    setIsFetchingLoading(false);
  };

  const projectsFiltered = projects
    .filter((project) => {
      if (selectedProducts.length === 0) return true;
      return selectedProducts.some((product) =>
        project.listProduit.includes(product)
      );
    })
    .filter((project) => {
      if (selectedCategories.length === 0) return true;
      return selectedCategories.some((category) =>
        project.listProduit
          .toLocaleLowerCase()
          .includes(category.toLocaleLowerCase())
      );
    });

  const listProductsFromCRM = projects
    .reduce((acc: string[], project) => {
      project.listProduit.split("\r\n").forEach((product) => {
        if (!acc.includes(product)) {
          acc.push(product);
        }
      });
      return acc;
    }, [])
    .filter((product) => product !== "")
    .sort()
    .map((product) => {
      return { label: product, value: product };
    });

  useEffect(() => {
    projects.length === 0 && fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ProjectsMarkersContainer">
      {projectImages.length > 0 ? (
        <Modale
          isModaleOpen={isModaleOpen}
          maxWidth="70rem"
          maxHeight="70rem"
          height="90vh"
        >
          <div className="ProjectsMarkersContainer__ModaleContainer">
            <div className="ProjectsMarkersContainer__ExitModalPicto">
              <img
                src={cross}
                alt="exit"
                onClick={() => {
                  setIsModaleOpen(false);
                }}
              />
            </div>
            <div className="ProjectsMarkersContainer__ModaleContent">
              <CarrouselWrapper
                numberOfElements={projectImages.length}
                isMobile={false}
                slidePerViewDesktop={1}
                isDisplayChevrons={true}
                swiperStyle={{
                  height: "100%",
                  width: "100%",
                }}
                swiperRef={swiperRef}
                isDisplayPaginationDesktop={true}
              >
                {projectImages.map((projectImage, index) => {
                  return (
                    <SwiperSlide
                      data-hash={`slideModale${index}`}
                      key={index}
                      className={"ProjectsMarkersContainer__ModaleSwiperSlide"}
                    >
                      <div className="ProjectsMarkersContainer__ModaleImageContainer">
                        <img
                          src={`data:image/png;base64,${projectImage.base64EncodedContent}`}
                          alt="projectImage"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </CarrouselWrapper>
            </div>
          </div>
        </Modale>
      ) : null}
      <div className="ProjectsMarkersContainer__Switch">
        {isFetchingLoading ? (
          <div className="ProjectsMarkersContainer__Loader">
            <LoadingComponent diameter={20} />
            <p>Chargement des projets...</p>{" "}
          </div>
        ) : (
          <>
            <Switch
              value={isDisplayProjects}
              onClick={() => setIsDisplayProjects((state) => !state)}
              name="projects"
            />
            <p>Afficher les projets</p>
          </>
        )}
      </div>
      <div>
        <div className="ProjectsMarkersContainer__FiltersContainer">
          <div className="ProjectsMarkersContainer__Dropdown">
            <DropdownV2
              options={listProducts}
              placeholder="Filtrer par catégorie(s)"
              values={selectedCategories}
              onSelect={(option) => {
                if (
                  option.value &&
                  selectedCategories.includes(option.value as string)
                ) {
                  setSelectedCategories(
                    selectedCategories.filter(
                      (product) => product !== option.value
                    )
                  );
                  return;
                }
                option.value &&
                  setSelectedCategories([
                    ...selectedCategories,
                    option.value as string,
                  ]);
                return;
              }}
              multipleChoices
              searchable
              maxHeight="60vh"
            />
          </div>
          <div className="ProjectsMarkersContainer__Dropdown">
            <DropdownV2
              options={listProductsFromCRM}
              placeholder="Filtrer par produit(s)"
              values={selectedProducts}
              onSelect={(option) => {
                if (
                  option.value &&
                  selectedProducts.includes(option.value as string)
                ) {
                  setSelectedProducts(
                    selectedProducts.filter(
                      (product) => product !== option.value
                    )
                  );
                  return;
                }
                option.value &&
                  setSelectedProducts([
                    ...selectedProducts,
                    option.value as string,
                  ]);
                return;
              }}
              multipleChoices
              searchable
              maxHeight="60vh"
            />
          </div>
        </div>
        {isDisplayProjects
          ? projectsFiltered.map((project, index) => {
              return (
                <ProjectMarker
                  project={project}
                  index={index}
                  userToken={userAuthInfo?.userToken || ""}
                  key={"MarkerWhitInfoWindow" + index}
                  setProjectImages={setProjectImages}
                  setIsModaleOpen={setIsModaleOpen}
                  projectImages={projectImages}
                  swiperRef={swiperRef}
                  isMarkerClicked={isMarkerClicked}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};
