// Style import
import "./ProfitabilityCashFlow.scss";

// Libraries import
import { useEffect, useState } from "react";

// images import
import pictoTirelire from "@web/shared/dist/assets/images/DesignSystemIllustrations/tirelire.svg";
import pictoResteACharge from "../../../assets/images/picto-main-euros.svg";
import pictoTotalPrice from "../../../assets/images/picto-main-pouce-etoiles.svg";

// components imports
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { FinancingGraphContainer } from "../../FinancingGraphContainer/FinancingGraphContainer";
import { Layout } from "../../Layout/Layout";
import { SofincoSimulator } from "../../SofincoSimulator/SofincoSimulator";

// services import
import { useAppSelector } from "../../../redux/store/hook";
import { profitabilityGraphDataCalculation } from "../../../services/OptionChosen/profitabilityGraphDataCalculation";
import { useGetProfitability } from "../../../services/OptionChosen/useGetProfitability";
import { calculatePVpathPrices } from "../../../services/PV/calculatePVpathPrices";
import { euroShortFormat } from "../../../services/euroFormatter";

//Interface import
import {
  HelpsOptions,
  IScenario,
  LoanSimulationResult,
} from "../../../interfaces/generalInterfaces";

interface IProps {
  totalPrice?: number | null;
  building_id?: string | null;
  household_id: string | null;
  setIsGoToFunctionActive?: React.Dispatch<React.SetStateAction<boolean>>;
  fundingPeriod: number;
  setFundingPeriod: React.Dispatch<React.SetStateAction<number>>;
  interestRate: string;
  setInterestRate: React.Dispatch<React.SetStateAction<string>>;
  personalContributionAmount: number;
  setPersonalContributionAmount: React.Dispatch<React.SetStateAction<number>>;
  isDeferralOption: boolean;
  setIsDeferralOption: React.Dispatch<React.SetStateAction<boolean>>;
  hasBorrowerInsurance: boolean;
  setHasBorrowerInsurance: React.Dispatch<React.SetStateAction<boolean>>;
  hasCoBorrower: boolean;
  setHasCoBorrower: React.Dispatch<React.SetStateAction<boolean>>;
  loanSimulationResult: LoanSimulationResult;
  setLoanSimulationResult: React.Dispatch<
    React.SetStateAction<LoanSimulationResult>
  >;
  financingOptionsList: IOption[];
  financingOptions: IOption;
  setfinancingOptions: React.Dispatch<React.SetStateAction<IOption>>;
  helpsOptions: HelpsOptions;
  setHelpsOptions: React.Dispatch<React.SetStateAction<HelpsOptions>>;
  scenarioChosen: IScenario;
}

// Local interface declaration
interface IOption {
  label: string | null;
  value: string | null;
}

export const ProfitabilityCashFlow = ({
  totalPrice,
  building_id,
  household_id,
  fundingPeriod,
  setFundingPeriod,
  interestRate,
  setInterestRate,
  personalContributionAmount,
  setPersonalContributionAmount,
  isDeferralOption,
  setIsDeferralOption,
  hasBorrowerInsurance,
  setHasBorrowerInsurance,
  hasCoBorrower,
  setHasCoBorrower,
  loanSimulationResult,
  setLoanSimulationResult,
  financingOptionsList,
  financingOptions,
  setfinancingOptions,
  helpsOptions,
  scenarioChosen,
}: IProps) => {
  const { monInstallation, userAuthInfo, studyInformation } = useAppSelector(
    (state) => state
  );
  const { getProfitability, isLoading } = useGetProfitability();

  const [isCashFlowGraphOpen, setIsCashFlowGraphOpen] = useState(false);

  const { totalRefundableHelps, totalProjectCost, totalDeductibleHelps } =
    calculatePVpathPrices({
      scenarioChosen,
    });

  useEffect(() => {
    if (
      monInstallation.isPersonalizedScenario ||
      monInstallation.indexOptionChosen !==
        monInstallation.profitability?.indexScenarioChosen
    ) {
      getProfitability({
        userToken: userAuthInfo.userToken,
        number_of_panels: scenarioChosen.number_of_panels ?? null,
        building_id: building_id,
        household_id: household_id,
        kit_home_management: scenarioChosen.kit_home_management ?? null,
        number_of_batteries: scenarioChosen.number_of_batteries || 0,
        indexScenarioChosen: monInstallation.indexOptionChosen,
        inverter_brand: scenarioChosen.inverter_brand || null,
        study_id: studyInformation?.study_id || "",
        single_battery_capacity: scenarioChosen.single_battery_capacity || -1,
        panel_indices: scenarioChosen.calepinage_chosen_indexes,
        extra_fees: scenarioChosen.extra_fees,
      });
      return;
    }
    // eslint-disable-next-line
  }, []);

  const { cashflowGraphData, totalCashEffort } =
    profitabilityGraphDataCalculation({
      economiesData: monInstallation?.profitability?.economies || [],
      fundingPeriod: fundingPeriod,
      helpAmount: monInstallation?.profitability?.total_help || 0,
      surplusResale:
        monInstallation?.profitability?.extra_electricity_sales.yearly_sales ||
        [],
      isDeferralOption: isDeferralOption,
      monthlyPayment: loanSimulationResult.monthlyPayment || 0,
    });

  return (
    <div className="ProfitabilityCashFlow">
      <Layout>
        <section className="ProfitabilityCashFlow__OptionsDeFinancement">
          <div className="ProfitabilityCashFlow__TitleContainer">
            <p className="ProfitabilityCashFlow__Title">
              Option de financement
            </p>
          </div>
        </section>

        {isLoading ? (
          <div
            className="ProfitabilityCashFlow__LoadingContainer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <LoadingComponent diameter={60} />
          </div>
        ) : (
          monInstallation.profitability && (
            <section className="ProfitabilityCashFlow__FormContainer">
              <div className="BoxesContainer">
                <ChoixMultiple
                  gap={false}
                  options={financingOptionsList}
                  onClick={(option) => option && setfinancingOptions(option)}
                  value={[financingOptions]}
                />
              </div>

              {financingOptions?.value === "Comptant" ? (
                <section className="ProfitabilityCashFlow__SectionComptant">
                  <div className="ProfitabilityCashFlow__DataContainer">
                    <img
                      src={pictoTotalPrice}
                      alt="picto Total Price"
                      width={42}
                      height={45}
                    />
                    <p className="ProfitabilityCashFlow__Value">
                      {totalProjectCost && euroShortFormat(totalProjectCost)}
                    </p>
                    <p className="ProfitabilityCashFlow__Subtitle">PRIX TTC</p>
                    {totalDeductibleHelps ? (
                      <p className="ProfitabilityCashFlow__Text">
                        (hors aides)
                      </p>
                    ) : null}
                  </div>
                  <div className="ProfitabilityCashFlow__Trait" />
                  <div className="ProfitabilityCashFlow__DataContainer">
                    <img
                      src={pictoResteACharge}
                      alt="picto Reste A Charge"
                      width={42}
                      height={45}
                    />

                    <p className="ProfitabilityCashFlow__Value">
                      {totalProjectCost && euroShortFormat(totalProjectCost)}
                    </p>
                    <p className="ProfitabilityCashFlow__Subtitle">
                      MONTANT À FINANCER
                    </p>
                    {totalDeductibleHelps ? (
                      <p className="ProfitabilityCashFlow__Text">
                        (aides déduites si éligibles)
                      </p>
                    ) : null}
                  </div>
                  <div className="ProfitabilityCashFlow__DataContainer">
                    <img
                      src={pictoTirelire}
                      alt="Tirelire"
                      width={42}
                      height={45}
                    />

                    <p className="ProfitabilityCashFlow__Value">
                      {totalRefundableHelps &&
                        euroShortFormat(totalRefundableHelps)}
                    </p>
                    <p className="ProfitabilityCashFlow__Subtitle">
                      {totalDeductibleHelps ? "AUTRES AIDES" : "AIDES"}
                    </p>
                    <p className="ProfitabilityCashFlow__Text">
                      {" "}
                      (en remboursement)
                    </p>
                  </div>
                </section>
              ) : (
                <>
                  <SofincoSimulator
                    fundingPeriod={fundingPeriod}
                    setFundingPeriod={setFundingPeriod}
                    interestRate={interestRate}
                    setInterestRate={setInterestRate}
                    personalContributionAmount={personalContributionAmount}
                    setPersonalContributionAmount={
                      setPersonalContributionAmount
                    }
                    isDeferralOption={isDeferralOption}
                    setIsDeferralOption={setIsDeferralOption}
                    loanSimulationResult={loanSimulationResult}
                    setLoanSimulationResult={setLoanSimulationResult}
                    loanAmount={totalPrice || 0}
                    study_id={studyInformation.study_id || ""}
                    totalCashEffort={totalCashEffort}
                    totalProjectPrice={totalPrice || 0}
                    userToken={userAuthInfo.userToken}
                    hasBorrowerInsurance={hasBorrowerInsurance}
                    setHasBorrowerInsurance={setHasBorrowerInsurance}
                    hasCoBorrower={hasCoBorrower}
                    setHasCoBorrower={setHasCoBorrower}
                    helpsOptions={helpsOptions}
                  />

                  {loanSimulationResult.monthlyPayment ? (
                    <section>
                      <FinancingGraphContainer
                        fundingPeriod={fundingPeriod}
                        isCashFlowGraphOpen={isCashFlowGraphOpen}
                        setIsCashFlowGraphOpen={setIsCashFlowGraphOpen}
                        graphData={cashflowGraphData}
                        isDeferralOption={isDeferralOption}
                        displayHelps={totalRefundableHelps > 0}
                      />
                    </section>
                  ) : null}
                </>
              )}
            </section>
          )
        )}
      </Layout>
    </div>
  );
};
