import "./TopSectionMainInformationRG.scss";

// images import
import pictoResteACharge from "@web/shared/dist/assets/images/DesignSystemIllustrations/euro-main.svg";
import pictoHelp from "@web/shared/dist/assets/images/DesignSystemIllustrations/partenaires.svg";
import pictoGain from "@web/shared/dist/assets/images/DesignSystemIllustrations/tirelire.svg";

import { useAppSelector } from "../../../redux/store/hook";
import { calculateRGDevisPrices } from "../../../services/RenovationGlobale/calculateRGDevisPrices";
import { euroShortFormat } from "../../../services/euroFormatter";

interface IProps {
  topSectionData: {
    remaining_amount_to_pay: number | undefined;
    total_net_gain: number | undefined;
  };
}

export const TopSectionMainInformationRG = ({ topSectionData }: IProps) => {
  const { cartProducts, RGProjectKPI } = useAppSelector((state) => state);

  const finalCartProducts = cartProducts.filter(
    (product) => product.isSelected
  );

  const { totalHelp, totalProjectCostHelpsDeducted } = calculateRGDevisPrices({
    finalCartProducts,
    projectKPI: RGProjectKPI,
  });

  return (
    <div className="TopSectionMainInformationRG">
      <div className="TopSectionMainInformationRG__SingleInfoBlock">
        <section className="TopSectionMainInformationRG__ImageContainer">
          <img src={pictoGain} alt="gains cumulés" width={39} height={37} />
        </section>
        <section className="TopSectionMainInformationRG__TextContainer">
          <p className="value">
            {topSectionData.total_net_gain &&
              euroShortFormat(topSectionData.total_net_gain)}
          </p>
          <p className="title">{"Gains cumulés"}</p>
        </section>
      </div>
      <div className="TopSectionMainInformationRG__SingleInfoBlock">
        <section className="TopSectionMainInformationRG__ImageContainer">
          <img src={pictoHelp} alt={"Aides"} width={36} height={35} />
        </section>
        <section className="TopSectionMainInformationRG__TextContainer">
          <p className="value">
            {topSectionData.remaining_amount_to_pay &&
              euroShortFormat(totalHelp)}
          </p>
          <p className="title">{"Total des aides estimé"}</p>
        </section>
      </div>
      <div className="TopSectionMainInformationRG__SingleInfoBlock">
        <section className="TopSectionMainInformationRG__ImageContainer">
          <img
            src={pictoResteACharge}
            alt={"Reste à charge"}
            width={36}
            height={35}
          />
        </section>
        <section className="TopSectionMainInformationRG__TextContainer">
          <p className="value">
            {topSectionData.remaining_amount_to_pay &&
              euroShortFormat(totalProjectCostHelpsDeducted)}
          </p>
          <p className="title">{"Total aides déduites"}</p>
        </section>
      </div>
    </div>
  );
};
