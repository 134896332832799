// Style import
import "./DepotMarker.scss";

// Libraries import
import { useState } from "react";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";

// Interfaces import
import { ISageDepot } from "../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface IProps {
  depot: ISageDepot;
  userToken: string;
  index: number;
  isMarkerClicked: React.MutableRefObject<boolean>;
}

export const DepotMarker = ({ depot, index, isMarkerClicked }: IProps) => {
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

  const position = {
    lat: Number(depot.latitude),
    lng: Number(depot.longitude),
  };

  const onMouseOverMarker = async () => {
    isMarkerClicked.current === false && setIsInfoWindowOpen(() => true);
  };

  const onMouseOutMarker = () => {
    isMarkerClicked.current === false && setIsInfoWindowOpen(() => false);
  };

  const onClickOpenModal = () => {
    isMarkerClicked.current = true;
    setIsInfoWindowOpen(() => true);
  };

  const onClickCloseModal = () => {
    isMarkerClicked.current = false;
    setIsInfoWindowOpen(() => false);
  };

  return (
    <>
      <MarkerF
        position={position}
        options={{
          optimized: true,
          icon: {
            path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
            scale: 10,
            fillColor: "#0073e0",
            fillOpacity: 1,
            strokeWeight: 0,
          },
        }}
        key={"Marker" + index}
        clickable={true}
        onMouseOver={onMouseOverMarker}
        onMouseOut={onMouseOutMarker}
        onClick={onClickOpenModal}
      >
        {isInfoWindowOpen ? (
          <InfoWindowF onCloseClick={onClickCloseModal}>
            <div className="DepotMarker__windowContainer">
              <div className="DepotMarker__Installations">
                <h2>Entrepot Tuco </h2>
                <p>
                  {depot.DE_Adresse +
                    " " +
                    depot.DE_CodePostal +
                    " " +
                    depot.DE_Ville}
                </p>
              </div>
            </div>
          </InfoWindowF>
        ) : null}
      </MarkerF>
    </>
  );
};
