import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./pageDeSortieSouscrire.scss";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { Layout } from "../../../components/Layout/Layout";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { useCreateOree2Study } from "../../../services/MesInformations/useCreateOree2Study";
import { useSaveLastPageVisited } from "../../../services/Oree2/useSaveLastPageVisited";
import { switchToRenoGlobaleStudy } from "../../../services/Recapitulatif/switchToRenoGlobaleStudy";
import { useSaveCartProducts } from "../../../services/RenovationGlobale/MonProjet/useSaveCartProducts";

// Images import
import footerImg from "../../../assets/images/footer/footerHomeDesktop.svg";

// Interfaces import
import { IGoTo } from "../../../interfaces/generalInterfaces";
import { INavigateData } from "../../../services/useNavigation/useNavigation";

interface Props {
  goNext: (data?: INavigateData<any>) => void;
  goBack?: () => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
}

export const PageDeSortieSouscrire = ({ goTo }: Props) => {
  const navigate = useNavigate();

  const { scenarioChosen, studyInformation, userAuthInfo } = useAppSelector(
    (state) => state
  );

  // Custom hook import
  const {
    createOree2Study,
    errorMessage: errorMessageSaveCustomerInformation,
  } = useCreateOree2Study();
  const { saveCartProducts, errorMessage: errorMessageSaveCartProducts } =
    useSaveCartProducts();
  const { saveLastPageVisited } = useSaveLastPageVisited();

  // Local state declaration
  const [
    isLoadingSwitchToRenoGlobaleStudy,
    setIsLoadingSwitchToRenoGlobaleStudy,
  ] = useState<boolean>(false);

  // Events handlers
  const handleSwitchToRenoGlobaleStudy = async () => {
    setIsLoadingSwitchToRenoGlobaleStudy(true);
    await switchToRenoGlobaleStudy({
      saveCartProducts,
      createOree2Study,
      userAuthInfo,
      studyInformation,
      scenarioChosen,
      saveLastPageVisited,
    });
    setIsLoadingSwitchToRenoGlobaleStudy(false);
  };

  const isPvStudy = studyInformation?.study_type === "photovoltaique";

  return (
    <>
      <div className={"pageDeSortieSouscrire"}>
        <Layout>
          <div className={"pageDeSortieSouscrire__Container"}>
            <div className={"Title"}>Bon de commande envoyé</div>
            <div className={"Text"}>
              Le bon de commande, le devis et l'étude ont été envoyés par mail
              au client.
            </div>
            <div className={"pageDeSortieSouscrire__ButtonsContainer"}>
              <div className={"pageDeSortieSouscrire__Button"}>
                <CTAButton
                  name={"Terminer"}
                  onClick={() => {
                    navigate(`/mes-etudes/${studyInformation.study_id}`);
                  }}
                />
              </div>
              {isPvStudy ? (
                <div className={"pageDeSortieSouscrire__Button"}>
                  <CTAButton
                    name={"Poursuivre vers une rénovation globale"}
                    onClick={handleSwitchToRenoGlobaleStudy}
                    isLoading={isLoadingSwitchToRenoGlobaleStudy}
                    category="tertiary"
                  />
                </div>
              ) : null}
            </div>
            <div className={"pageDeSortieSouscrire__ErrorMessage"}>
              <ErrorMessage
                errorMessage={
                  errorMessageSaveCustomerInformation ||
                  errorMessageSaveCartProducts
                }
              />
            </div>
            {isPvStudy ? (
              <div className={"LinkText"}>
                <p
                  onClick={() =>
                    goTo &&
                    goTo({
                      newCurrentStepIndex: 3,
                      newCurrentSubStepIndex: 0,
                      isGoforwardAllowed: false,
                    })
                  }
                >
                  {`< Voir les autres formules`}
                </p>
              </div>
            ) : null}
          </div>
        </Layout>
        <img
          src={footerImg}
          alt="Des belles colines vertes"
          width="1745"
          height="262"
        />
      </div>
    </>
  );
};
