// Libraries import
import { useState } from "react";

// Interface delcaration
interface IFunctionReturn {
  isResetPasswordInputValid: (
    invitationCode: string | null,
    userPassword: string,
    confirmPassword: string
  ) => boolean;
  errorMessage: string;
}

// useIsResetPasswordInputValid : return tools to check reset password inputs format
export const useIsResetPasswordInputValid = (): IFunctionReturn => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  // isResetPasswordInputValid : function to check reset password inputs format
  const isResetPasswordInputValid = (
    resetCode: string | null,
    userPassword: string,
    confirmPassword: string
  ): boolean => {
    setErrorMessage("");
    if (userPassword && confirmPassword) {
      if (resetCode) {
        if (userPassword === confirmPassword) {
          return true;
        } else {
          setErrorMessage("Vos mots de passe doivent être identiques.");
        }
      } else {
        setErrorMessage("Le lien n'est pas valide.");
      }
    } else {
      setErrorMessage("Veuillez renseigner tous les champs.");
    }
    return false;
  };

  return { isResetPasswordInputValid, errorMessage };
};
