//Libraries import
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import "./monProjetRG.scss";

// components import
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { IncomeModale } from "../../components/IncomeModale/IncomeModale";
import { Layout } from "../../components/Layout/Layout";
import { KPIComponent } from "../../containers/MonProjetRG/KPIComponent/KPIComponent";
import { ProductCard } from "../../containers/MonProjetRG/ProductCard/ProductCard";
import { SubHeaderRG } from "../../containers/SubHeaderRG/SubHeaderRG";

// Image import
import plus from "@web/shared/dist/assets/images/DesignSystemIcons/plus.svg";

// Interfaces import
import {
  IDataProduct,
  IProjectKPI,
  TProjectPages,
} from "../../interfaces/renoglobaleInterface";

// import images
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

// Services import
import { setCartProductsAction } from "../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { useSaveLastPageVisited } from "../../services/Oree2/useSaveLastPageVisited";
import { useSaveCartProducts } from "../../services/RenovationGlobale/MonProjet/useSaveCartProducts";
import { useSendSelectedCartProducts } from "../../services/RenovationGlobale/MonProjet/useSendSelectedCartProducts";
import { INavigateData } from "../../services/useNavigation/useNavigation";

// Local interfaces declaration
interface IProps {
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  setProductCategorySelected: React.Dispatch<React.SetStateAction<string>>;
  setSelectedProductIndex: React.Dispatch<React.SetStateAction<number | null>>;
  isLoadingGetProductsSavedInCart: boolean;
  isLoadingGetProductsRecommendations: boolean;
  errorMessageFetchProducts: string;
  fetchProducts: () => Promise<void>;
  fetchProjectKPI: ({
    cartProductsToDisplay,
  }: {
    cartProductsToDisplay?: IDataProduct[] | undefined;
  }) => Promise<void>;
  projectKPI?: IProjectKPI | null;
  isLoadingGetProjectKPI: boolean;
  setIsModalRGEligibleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalRGEligibleOpen: boolean;
  goNext: (data?: INavigateData<any>) => void;
  isIncomeModaleOpen: boolean;
  setIsIncomeModaleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingGetMesScenarios: boolean;
}

export const MonProjetRG = ({
  setScreenSelected,
  setProductCategorySelected,
  setSelectedProductIndex,
  isLoadingGetProductsSavedInCart,
  isLoadingGetProductsRecommendations,
  errorMessageFetchProducts,
  fetchProducts,
  fetchProjectKPI,
  projectKPI,
  isLoadingGetProjectKPI,
  setIsModalRGEligibleOpen,
  isModalRGEligibleOpen,
  goNext,
  isIncomeModaleOpen,
  setIsIncomeModaleOpen,
  isLoadingGetMesScenarios,
}: IProps) => {
  const cartProductsToDisplay = useAppSelector((state) => state.cartProducts);
  const dispatch = useAppDispatch();
  const { studyInformation, userAuthInfo } = useAppSelector((state) => state);
  const { saveCartProducts, isLoading: isLoadingSaveCartProducts } =
    useSaveCartProducts();
  const { sendSelectedCartProducts, isLoadingSendCartProducts } =
    useSendSelectedCartProducts();
  const { saveLastPageVisited } = useSaveLastPageVisited();

  //Needed to not open the income modal every time we reach Mon project back

  const [isManageCartActive, setIsManageCartActive] = useState(false);

  const validateCart = async () => {
    fetchProjectKPI({
      cartProductsToDisplay: cartProductsToDisplay,
    });
    setIsManageCartActive(false);
  };

  const onClickOnNext = async () => {
    // #1 Save cart's product in Eero
    await saveCartProducts({
      cartProducts: cartProductsToDisplay.filter(
        (product) => product.isSelected
      ),
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id,
    });

    // #2 Send cart to data backend
    const response = await sendSelectedCartProducts({
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id,
      building_id: studyInformation.building_id || null,
    });

    if (response && response.status === 200) {
      saveLastPageVisited({
        last_page_visited: "my_project",
        study_id: studyInformation?.study_id || null,
        userToken: userAuthInfo.userToken,
      });

      dispatch(
        setCartProductsAction({
          cartProducts: cartProductsToDisplay.filter(
            (product) => product.isSelected
          ),
        })
      );

      goNext && goNext();
    }
  };

  const SubHeaderRGButtons = () => {
    return (
      <div className="MonProjetRG__ButtonsContainer">
        <CTAButton
          category="secondary"
          name={isManageCartActive ? "Annuler" : "Modifier ma sélection"}
          onClick={() => {
            setIsManageCartActive(!isManageCartActive);
          }}
          isLoading={
            isLoadingGetProductsSavedInCart ||
            isLoadingGetProductsRecommendations ||
            isLoadingGetProjectKPI ||
            isLoadingSendCartProducts ||
            isLoadingSaveCartProducts ||
            isLoadingGetMesScenarios
          }
        />

        {isManageCartActive ? (
          <CTAButton
            category="primary"
            name="Confirmer ma sélection"
            onClick={() => {
              validateCart();
            }}
          />
        ) : (
          <CTAButton
            category="primary"
            name="Continuer"
            isLoading={
              isLoadingGetProductsSavedInCart ||
              isLoadingGetProductsRecommendations ||
              isLoadingGetProjectKPI ||
              isLoadingSendCartProducts ||
              isLoadingSaveCartProducts ||
              isLoadingGetMesScenarios
            }
            onClick={() => onClickOnNext()}
            isDisabled={
              cartProductsToDisplay.filter((product) => product.isSelected)
                .length === 0
            }
          />
        )}
      </div>
    );
  };

  return (
    <div className="MonProjetRG">
      <Modale
        isModaleOpen={isIncomeModaleOpen}
        title="Calcul des aides financières"
        text="Les travaux de rénovation énergétique sont subventionnés par l'Etat. 
      Vérifiez votre éligibilité aux aides :"
        maxWidth={"710px"}
        textAlignMiddle
      >
        <IncomeModale
          setIsIncomeModaleOpen={setIsIncomeModaleOpen}
          fetchProducts={async () => {
            fetchProducts();
          }}
        />
      </Modale>
      <Modale
        isModaleOpen={isModalRGEligibleOpen}
        textAlignMiddle
        title={
          projectKPI?.is_eligible
            ? "Projet éligible à MaPrimeRénov' Rénovation d'ampleur !"
            : "Projet non éligible à MaPrimeRénov' Rénovation d'ampleur !"
        }
        text={
          projectKPI?.is_eligible
            ? "En sélectionnant ces produits, votre projet est éligible à la prime MaPrimeRénov' Rénovation d'ampleur."
            : "En sélectionnant ces produits vous n'êtes plus éligible à la prime MaPrimeRénov' Rénovation d'ampleur."
        }
      >
        <div className="MonProjetRG__ModalRGEligible">
          <CTAButton
            category="primary"
            name={"C’est compris !"}
            onClick={() => setIsModalRGEligibleOpen(false)}
          />
        </div>
      </Modale>
      <SubHeaderRG
        title="Mon projet de rénovation"
        studyInformation={studyInformation}
        SubHeaderRGButtons={SubHeaderRGButtons}
      />

      <div className="MonProjetRG__Content">
        <Layout>
          {isLoadingGetProductsSavedInCart ? (
            <div className="MonProjetRG__LoadingContainer">
              <p>Chargement du panier...</p>
              <LoadingComponent diameter={60} />
            </div>
          ) : isLoadingGetProductsRecommendations ? (
            <div className="MonProjetRG__LoadingContainer">
              <p>Chargement des recommandations produits...</p>
              <LoadingComponent diameter={60} />
            </div>
          ) : isLoadingGetMesScenarios ? (
            <div className="MonProjetRG__LoadingContainer">
              <p>Calcul des scenarios...</p>
              <LoadingComponent diameter={60} />
            </div>
          ) : cartProductsToDisplay.length > 0 ? (
            <>
              <div className="MonProjetRG__ContainerParent">
                <KPIComponent
                  isLoadingGetProjectKPI={isLoadingGetProjectKPI}
                  isManageCartActive={isManageCartActive}
                  cartProductsToDisplay={cartProductsToDisplay}
                  projectKPI={projectKPI}
                  setIsIncomeModaleOpen={setIsIncomeModaleOpen}
                />

                <div className="MonProjetRG__ContainerChildren">
                  <div className="MonProjetRG__ProductsContainer">
                    {cartProductsToDisplay.map((product, index) => {
                      return (
                        <React.Fragment key={index + product.product_name}>
                          <ProductCard
                            productInfo={product}
                            setScreenSelected={setScreenSelected}
                            setProductCategorySelected={
                              setProductCategorySelected
                            }
                            setSelectedProductIndex={setSelectedProductIndex}
                            product_index={index}
                            isManageCartActive={isManageCartActive}
                            isActive={product.isSelected || false}
                            isRecommendedProduct={
                              product.isProductRecommended || false
                            }
                            isEligibleRenoGlobal={projectKPI?.is_eligible}
                            isLoadingGetProjectKPI={isLoadingGetProjectKPI}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                className="MonProjetRG__Content__containerMore"
                onClick={() => {
                  setScreenSelected("product_catalogue");
                }}
              >
                <SVG src={plus} />
                <p>Ajouter un produit du catalogue.</p>
              </div>
            </>
          ) : (
            <>
              <p className="MonProjetRG__UserMessage">
                {errorMessageFetchProducts || "Panier vide"}
              </p>
              <div
                className="MonProjetRG__Content__containerMore"
                onClick={() => {
                  setScreenSelected("product_catalogue");
                }}
              >
                <SVG src={plus} />
                <p>Ajouter un produit du catalogue.</p>
              </div>
            </>
          )}
        </Layout>
      </div>
    </div>
  );
};
