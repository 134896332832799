// Services import
import { colorsInIDF, colorsOutOfIDF } from "../../data/plafondsDeRessources";
import { isDepartmentInIDF } from "../isDepartmentInIDF";
import {
  MoreThan5OccupantsInIDF,
  MoreThan5OccupantsOutOfIDF,
} from "../MoreThan5Occupants";

/**
 * Return the income range of a user. Ex : "Inférieur ou  égale à 23 541 €".
 * Return null if no income range found for the specified parameters.
 */
export const calculateHouseholdIncomeRange = ({
  number_of_occupants,
  zipcode,
  income_color,
}: {
  number_of_occupants?: number | null;
  zipcode?: string | null;
  income_color?: string | null;
}) => {
  const formattedNumberOfOccupants = number_of_occupants || 1;
  const colorRanges = isDepartmentInIDF({
    zipcode: zipcode || null,
  })
    ? formattedNumberOfOccupants > 5
      ? MoreThan5OccupantsInIDF(formattedNumberOfOccupants)
      : colorsInIDF[formattedNumberOfOccupants.toString()]
    : formattedNumberOfOccupants > 5
    ? MoreThan5OccupantsOutOfIDF(formattedNumberOfOccupants)
    : colorsOutOfIDF[formattedNumberOfOccupants.toString()];

  return (
    colorRanges.find((colorRange) => colorRange.value === income_color)
      ?.label || null
  );
};
