//Style import
import "./mesConsommations.scss";

// Libraries import
import { useState } from "react";

// Conponents import
import { MesConsommationsSubHeader } from "../../components/MesConsommations/MesConsommayionsSubHeader/MesConsommationsSubHeader";
import { MesConsommationsChart } from "../../components/MesConsommations/MesConsommationsChart/MesConsommationsChart";
import { MesInfosSupplementaires } from "../../components/MesConsommations/MesInfoSupplementaire/MesInfosSupplementaires";
import { SubHeader } from "../../containers/SubHeader/SubHeader";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingScreen } from "../../containers/LoadingScreen/LoadingScreen";
import { ButtonInternalLink } from "../../components/ButtonInternalLink/ButtonInternalLink";

// Services import
import { useGetMesConsommations } from "../../services/MesConsommations/useGetMesConsommations";
import { useAppSelector } from "../../redux/store/hook";
import { useEffect } from "react";
import { useSaveLastPageVisited } from "../../services/Oree2/useSaveLastPageVisited";

// Interfaces import
import { IGoTo } from "../../interfaces/generalInterfaces";
import { INavigateData } from "../../services/useNavigation/useNavigation";

// Local interface declaration
interface Props {
  goNext: (data?: INavigateData<any>) => void;
  goBack?: () => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
}

export const MesConsommations = ({ goNext, goBack, goTo }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { getMesConsommations, errorMessage, allConsumptionData } =
    useGetMesConsommations();
  const { saveLastPageVisited } = useSaveLastPageVisited();

  const { userAuthInfo, studyInformation } = useAppSelector((state) => state);

  const fetchGetMesConsommations = async () => {
    const mesConsommation = await getMesConsommations({
      userToken: userAuthInfo.userToken,
      household_id: studyInformation.household_id || null,
      building_id: studyInformation.building_id || "",
      study_id: studyInformation.study_id,
    });
    if (mesConsommation) {
      setIsLoading(false);
      return;
    }

    // If no consommation data we direclty go to mes consommations KO form
    goNext && goNext();
    setIsLoading(false);
    return;
  };

  useEffect(() => {
    fetchGetMesConsommations();

    // eslint-disable-next-line
  }, []);

  const dataSubheader = allConsumptionData && {
    tarification_option: allConsumptionData.tarification_option,
    subscribed_power: allConsumptionData.subscribed_power,
    annual_electric_consumption: allConsumptionData.annual_electric_consumption,
    monthly_electric_consumption:
      allConsumptionData.monthly_electric_consumption,
  };

  const dataSupplementaires = allConsumptionData && {
    CO2_emissions_associated_to_consumptions:
      allConsumptionData.CO2_emissions_associated_to_consumptions,
    average_consumption_peak: allConsumptionData.average_consumption_peak,
  };

  return (
    <div className="MesConsommation">
      <SubHeader
        title="Mes Consommations"
        goNext={() => {
          saveLastPageVisited({
            last_page_visited: "my_consumptions",
            study_id: studyInformation?.study_id || null,
            userToken: userAuthInfo.userToken,
          });
          goTo && goTo({ newCurrentStepIndex: 2, isGoforwardAllowed: true });
        }}
        errorMessage={errorMessage}
      />
      {isLoading ? (
        <LoadingScreen
          sentenceOne="Nous sommes en train de récupérer"
          sentenceTwo="vos données de consommation..."
        />
      ) : dataSubheader && dataSupplementaires ? (
        <>
          <MesConsommationsSubHeader data={dataSubheader} />
          <MesConsommationsChart
            data={allConsumptionData?.hourly_consumption}
          />
          <MesInfosSupplementaires data={dataSupplementaires} />
        </>
      ) : (
        <div className="MesConsommation__ErrorMessage">
          <ErrorMessage errorMessage={errorMessage.toString()} />
          <div className="MesConsommation__ExitLink">
            <ButtonInternalLink
              text="Renseigner à la main mes données de consommation"
              onClick={() =>
                goTo &&
                goTo({ newCurrentStepIndex: 2, newCurrentSubStepIndex: 1 })
              } // Goes to Consomations form
            />
          </div>
        </div>
      )}
    </div>
  );
};
