import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfSubTitleProduct } from "../../components/Pdf/Pdf-Devis/PdfSubTitleProduct/PdfSubTitleProduct";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
  ISageProduct,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  ISORampants: IDataProduct | undefined;
  ISORampantsSageProduct: ISageProduct | undefined;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  commercialUserInformation: ICommercialUserInformation;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
}

export const PdfISORampantsPage = ({
  documentId,
  ISORampants,
  ISORampantsSageProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
  devisOptions,
}: IProps) => {
  const {
    extraChargeWithoutTax: ISORampantsExtraChargeWithoutTax,
    totalPriceWhithoutTax: ISORampantsTotalPriceWhithoutTax,
    totalTVA: ISORampantsTotalTVA,
    totalPriceWithTax: ISORampantsTotalPriceWithTax,
    totalHelp: ISORampantsTotalHelp,
    totalResteACharge: ISORampantsTotalResteACharge,
    extraChargeWithTax: ISORampantsExtraChargeWithTax,
    installationPriceWithTax: ISORampantsInstallationPriceWithTax,
    managementFeesTTC: ISORampantsManagementFeesTTC,
    managementFeesHT: ISORampantsManagementFeesHT,
    totalDeductibleHelps: ISORampantsTotalDeductibleHelps,
    totalRefundableHelps: ISORampantsTotalRefundableHelps,
  } = productPrices({
    product: ISORampants,
    isEligibleRenoGlobal,
    devisOptions,
  });

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              ISORampants?.product_alias ||
              ISORampants?.product_name ||
              "Produit NC"
            }
          />
          <PdfSubTitleProduct
            title={
              "Référence fabricant : " +
                ISORampantsSageProduct?.["Référence fabricant"] || "NC"
            }
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={
                ISORampants?.selected_characteristics?.surface_to_isolate || 0
              }
              prixUnitaire={ISORampants?.product_price_without_tax || 0}
              TVA={ISORampants?.TVA_percentage || 0}
              montantTTC={
                ISORampantsTotalPriceWithTax -
                ISORampantsInstallationPriceWithTax
              }
            />

            {/* ISO */}
            {ISORampantsSageProduct ? (
              <>
                <Text style={{ marginBottom: 5 }}>
                  Mise en place d’une isolation thermique de rampants
                </Text>
                <PdfItemDetail
                  key={ISORampantsSageProduct.AR_Ref}
                  title={
                    "Isolant " +
                    ISORampantsSageProduct.Marque +
                    " " +
                    ISORampantsSageProduct.Gamme +
                    " " +
                    ISORampantsSageProduct.Dimensions
                  }
                  description={[
                    <>
                      {ISORampants?.product_name && (
                        <Text>{ISORampants?.product_name}</Text>
                      )}
                    </>,
                    <>
                      {ISORampantsSageProduct[
                        "Classe energétique (Froid/Chaud"
                      ] && (
                        <Text>
                          {ISORampantsSageProduct[
                            "Classe energétique (Froid/Chaud"
                          ] &&
                          ISORampantsSageProduct[
                            "Classe energétique (Froid/Chaud"
                          ].length > 0
                            ? `${ISORampantsSageProduct["Classe energétique (Froid/Chaud"]}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ISORampantsSageProduct.Puissance && (
                        <Text>
                          {ISORampantsSageProduct.Puissance &&
                          ISORampantsSageProduct.Puissance.length > 0
                            ? `${ISORampantsSageProduct.Puissance}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ISORampantsSageProduct.Description && (
                        <Text>
                          {ISORampantsSageProduct.Description &&
                          ISORampantsSageProduct.Description.length > 0
                            ? `${ISORampantsSageProduct.Description}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ISORampantsSageProduct.Conditionnement && (
                        <Text>
                          {ISORampantsSageProduct.Conditionnement &&
                          ISORampantsSageProduct.Conditionnement.length > 0
                            ? `Dimensions : ${ISORampantsSageProduct.Conditionnement}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,

                    <>
                      {ISORampantsSageProduct["Norme et Certification"] &&
                        ISORampantsSageProduct["Norme et Certification"]
                          ?.length > 0 && (
                          <Text>
                            {ISORampantsSageProduct["Norme et Certification"]
                              ? `Certifications : ${ISORampantsSageProduct["Norme et Certification"]}`
                              : null}
                          </Text>
                        )}
                    </>,
                    <>
                      {ISORampantsSageProduct["Résistance Thermique "] && (
                        <Text>
                          {ISORampantsSageProduct["Résistance Thermique "] &&
                          ISORampantsSageProduct["Résistance Thermique "]
                            .length > 0
                            ? `R = ${ISORampantsSageProduct["Résistance Thermique "]}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                  ]}
                />
              </>
            ) : null}
            <View wrap={false}>
              <PdfRowBackgroundProduit
                name="Forfait installation"
                quantity={
                  ISORampants?.selected_characteristics?.surface_to_isolate || 0
                }
                prixUnitaire={ISORampants?.installation_price_without_tax || 0}
                TVA={ISORampants?.TVA_percentage || 0}
                montantTTC={ISORampantsInstallationPriceWithTax}
              />
              <View style={styles.inlineComponents}>
                <Text>Déplacement</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Mise en place d'une isolation thermique de rampants</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Fourniture des équipements nécessaires à la pose des matériels
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Gestion des déchets</Text>
              </View>
            </View>
            {(ISORampants?.extra_charges_added?.length || 0) > 0 && (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Spécificité du chantier"
                  quantity={ISORampants?.extra_charges_added?.length}
                  prixUnitaire={ISORampantsExtraChargeWithoutTax || 0}
                  TVA={ISORampants?.TVA_percentage || 0}
                  montantTTC={ISORampantsExtraChargeWithTax || 0}
                />
                {ISORampants?.extra_charges_added?.map((extraCharge) => {
                  return (
                    <PdfRowProduit
                      key={extraCharge.product_name}
                      name={extraCharge.product_name}
                      quantity={1}
                      prixUnitaire={extraCharge.price_without_tax}
                      TVA={extraCharge.TVA_percentage}
                      montantTTC={extraCharge.price_with_tax}
                      description={extraCharge.description}
                    />
                  );
                })}
              </View>
            )}
            <View wrap={false}>
              {ISORampantsManagementFeesTTC !== 0 ? (
                <>
                  <PdfRowBackgroundProduit
                    name="Frais de gestion"
                    quantity={1}
                    prixUnitaire={ISORampantsManagementFeesHT}
                    TVA={ISORampants?.installation_tva_percentage}
                    montantTTC={ISORampantsManagementFeesTTC}
                  />
                  <View style={styles.inlineComponents}>
                    <Text>
                      Prise en charge des démarches administratives et gestion
                      de projet/SAV
                    </Text>
                  </View>
                  <View style={styles.inlineComponents}>
                    <Text style={{ marginBottom: 10 }}>
                      Constitution des dossiers d’aides financières
                      éventuellement liées au projet
                    </Text>
                  </View>

                  <PdfRowBackgroundProduit
                    name="Frais de gestion offerts"
                    montantTTC={-ISORampantsManagementFeesTTC}
                  />
                </>
              ) : null}
            </View>
            {ISORampantsTotalHelp && devisOptions.isHelpsDisplayed ? (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Aides"
                  aides={ISORampants?.help
                    .filter((help) => help.is_eligible)
                    .map((help) => {
                      return {
                        aide: help.name,
                        montant: help.value,
                        isDeducted:
                          help.help_type === "mpr"
                            ? devisOptions.isMPRHelpsDeducted
                            : help.help_type === "cee"
                            ? devisOptions.isCEEHelpsDeducted
                            : false,
                      };
                    })}
                />
              </View>
            ) : null}
            <PdfTotalContainer
              devisOptions={devisOptions}
              totalHT={ISORampantsTotalPriceWhithoutTax}
              totalTVA={ISORampantsTotalTVA}
              totalTTC={ISORampantsTotalPriceWithTax}
              totalHelp={ISORampantsTotalHelp}
              resteACharge={ISORampantsTotalResteACharge}
              deductibleHelp={ISORampantsTotalDeductibleHelps}
              refundableHelp={ISORampantsTotalRefundableHelps}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>

      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
