// Styles import
import styled, { css } from "styled-components";
import { DSColors } from "../../../styles/variables";

// Source : https://uiverse.io/ozgeozkaraa01/evil-cheetah-7

export interface SwitchProps {
  dimension?: "S" | "M" | "L";
  onClick?: () => void;
  value: boolean;
  name?: string;
  color?: string;
  isDisabled?: boolean;
}

const Toggle = styled.div<{ dimension?: string }>`
  position: relative;
  display: inline-block;
  ${(props) =>
    props.dimension === "S" &&
    css`
      width: 32px;
      height: 18px;
    `}
  ${(props) =>
    props.dimension === "M" &&
    css`
      width: 46px;
      height: 24px;
    `}
    ${(props) =>
    props.dimension === "L" &&
    css`
      width: 60px;
      height: 32px;
    `}
`;

const Input = styled.input<{ dimension?: string; color?: string }>`
  display: none;

  &:checked + .toggle-label {
    background-color: ${(props) => props.color};
    color: ${(props) => props.color};
  }

  &:checked + .toggle-label:before {
    transform: translateX(
      ${(props) => props.dimension === "S" && "12px"}
        ${(props) => props.dimension === "M" && "19px"}
        ${(props) => props.dimension === "L" && "30px"}
    );
  }
`;

const Label = styled.label<{ dimension?: string; isDisabled: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  ${(props) =>
    props.dimension === "S" &&
    css`
      width: 32px;
      height: 18px;
    `}
  ${(props) =>
    props.dimension === "M" &&
    css`
      width: 46px;
      height: 24px;
    `}
    ${(props) =>
    props.dimension === "L" &&
    css`
      width: 60px;
      height: 32px;
    `}
  border-radius: 17px;
  background-color: ${DSColors.SGLight03};
  cursor: ${(props) => (props.isDisabled ? "auto" : "pointer")};
  transition: background-color 0.4s ease;

  &:before {
    content: "";
    position: absolute;
    ${(props) =>
      props.dimension === "L"
        ? css`
            top: 4px;
          `
        : css`
            top: 2px;
          `}

    left: 3px;

    ${(props) =>
      props.dimension === "S" &&
      css`
        width: 14px;
        height: 14px;
      `}
    ${(props) =>
      props.dimension === "M" &&
      css`
        width: 20px;
        height: 20px;
      `}
    ${(props) =>
      props.dimension === "L" &&
      css`
        width: 24px;
        height: 24px;
      `}

    border-radius: 50%;
    background-color: ${DSColors.white};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.4s ease;
  }
`;

export const Switch = ({
  onClick,
  dimension = "S",
  value,
  name = "togglebutton",
  color = DSColors.Mint,
  isDisabled = false,
}: SwitchProps) => {
  return (
    <Toggle dimension={dimension}>
      <Input
        dimension={dimension}
        className="toggle-input"
        id={name}
        name={name}
        type="checkbox"
        checked={value}
        onChange={onClick}
        color={color}
      />
      <Label
        dimension={dimension}
        className="toggle-label"
        htmlFor={name}
        isDisabled={isDisabled}
      ></Label>
    </Toggle>
  );
};
