import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfSubTitleProduct } from "../../components/Pdf/Pdf-Devis/PdfSubTitleProduct/PdfSubTitleProduct";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
  ISageProduct,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  ISOComble: IDataProduct | undefined;
  ISOCombleSageProduct: ISageProduct | undefined;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  commercialUserInformation: ICommercialUserInformation;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
}

export const PdfISOComblePage = ({
  documentId,
  ISOComble,
  ISOCombleSageProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
  devisOptions,
}: IProps) => {
  const {
    extraChargeWithoutTax: ISOCombleExtraChargeWithoutTax,
    totalPriceWhithoutTax: ISOCombleTotalPriceWhithoutTax,
    totalTVA: ISOCombleTotalTVA,
    totalPriceWithTax: ISOCombleTotalPriceWithTax,
    totalHelp: ISOCombleTotalHelp,
    totalResteACharge: ISOCombleTotalResteACharge,
    extraChargeWithTax: ISOCombleExtraChargeWithTax,
    installationPriceWithTax: ISOCombleInstallationPriceWithTax,
    managementFeesTTC: ISOComblemanagementFeesTTC,
    managementFeesHT: ISOCombleManagementFeesHT,
    totalDeductibleHelps: ISOCombleTotalDeductibleHelps,
    totalRefundableHelps: ISOCombleTotalRefundableHelps,
  } = productPrices({
    product: ISOComble,
    isEligibleRenoGlobal,
    devisOptions,
  });

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              ISOComble?.product_alias ||
              ISOComble?.product_name ||
              "Produit NC"
            }
          />
          <PdfSubTitleProduct
            title={
              "Référence fabricant : " +
                ISOCombleSageProduct?.["Référence fabricant"] || "NC"
            }
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={
                ISOComble?.selected_characteristics?.surface_to_isolate || 0
              }
              prixUnitaire={ISOComble?.product_price_without_tax || 0}
              TVA={ISOComble?.TVA_percentage || 0}
              montantTTC={
                ISOCombleTotalPriceWithTax - ISOCombleInstallationPriceWithTax
              }
            />

            {/* ISO */}
            {ISOCombleSageProduct ? (
              <>
                <Text style={{ marginBottom: 5 }}>
                  Mise en place d’une isolation thermique de combles
                </Text>
                <PdfItemDetail
                  key={ISOCombleSageProduct.AR_Ref}
                  title={
                    "Isolant " +
                    ISOCombleSageProduct.Marque +
                    " " +
                    ISOCombleSageProduct.Gamme +
                    " " +
                    ISOCombleSageProduct.Dimensions
                  }
                  description={[
                    <>
                      {ISOComble?.product_name && (
                        <Text>{ISOComble?.product_name}</Text>
                      )}
                    </>,
                    <>
                      {ISOCombleSageProduct[
                        "Classe energétique (Froid/Chaud"
                      ] && (
                        <Text>
                          {ISOCombleSageProduct[
                            "Classe energétique (Froid/Chaud"
                          ] &&
                          ISOCombleSageProduct[
                            "Classe energétique (Froid/Chaud"
                          ].length > 0
                            ? `${ISOCombleSageProduct["Classe energétique (Froid/Chaud"]}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ISOCombleSageProduct.Puissance && (
                        <Text>
                          {ISOCombleSageProduct.Puissance &&
                          ISOCombleSageProduct.Puissance.length > 0
                            ? `${ISOCombleSageProduct.Puissance}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ISOCombleSageProduct.Description && (
                        <Text>
                          {ISOCombleSageProduct.Description &&
                          ISOCombleSageProduct.Description.length > 0
                            ? `${ISOCombleSageProduct.Description}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ISOCombleSageProduct.Conditionnement && (
                        <Text>
                          {ISOCombleSageProduct.Conditionnement &&
                          ISOCombleSageProduct.Conditionnement.length > 0
                            ? `Dimensions : ${ISOCombleSageProduct.Conditionnement}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,

                    <>
                      {ISOCombleSageProduct["Norme et Certification"] &&
                        ISOCombleSageProduct["Norme et Certification"]?.length >
                          0 && (
                          <Text>
                            {ISOCombleSageProduct["Norme et Certification"]
                              ? `Certifications : ${ISOCombleSageProduct["Norme et Certification"]}`
                              : null}
                          </Text>
                        )}
                    </>,
                    <>
                      {ISOCombleSageProduct["Résistance Thermique "] && (
                        <Text>
                          {ISOCombleSageProduct["Résistance Thermique "] &&
                          ISOCombleSageProduct["Résistance Thermique "].length >
                            0
                            ? `R = ${ISOCombleSageProduct["Résistance Thermique "]}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                  ]}
                />
              </>
            ) : null}
            <View wrap={false}>
              <PdfRowBackgroundProduit
                name="Forfait installation"
                quantity={
                  ISOComble?.selected_characteristics?.surface_to_isolate || 0
                }
                prixUnitaire={ISOComble?.installation_price_without_tax || 0}
                TVA={ISOComble?.TVA_percentage || 0}
                montantTTC={ISOCombleInstallationPriceWithTax}
              />
              <View style={styles.inlineComponents}>
                <Text>Déplacement</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Mise en place d'une isolation thermique de combles perdus
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Isolation par soufflage </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Fourniture des équipements nécessaires à la pose des matériels
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Gestion des déchets</Text>
              </View>
            </View>
            {(ISOComble?.extra_charges_added?.length || 0) > 0 && (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Spécificité du chantier"
                  quantity={ISOComble?.extra_charges_added?.length}
                  prixUnitaire={ISOCombleExtraChargeWithoutTax || 0}
                  TVA={ISOComble?.TVA_percentage || 0}
                  montantTTC={ISOCombleExtraChargeWithTax || 0}
                />
                {ISOComble?.extra_charges_added?.map((extraCharge) => {
                  return (
                    <PdfRowProduit
                      key={extraCharge.product_name}
                      name={extraCharge.product_name}
                      quantity={1}
                      prixUnitaire={extraCharge.price_without_tax}
                      TVA={extraCharge.TVA_percentage}
                      montantTTC={extraCharge.price_with_tax}
                      description={extraCharge.description}
                    />
                  );
                })}
              </View>
            )}
            <View wrap={false}>
              {ISOComblemanagementFeesTTC !== 0 ? (
                <>
                  <PdfRowBackgroundProduit
                    name="Frais de gestion"
                    quantity={1}
                    prixUnitaire={ISOCombleManagementFeesHT}
                    TVA={ISOComble?.installation_tva_percentage}
                    montantTTC={ISOComblemanagementFeesTTC}
                  />
                  <View style={styles.inlineComponents}>
                    <Text>
                      Prise en charge des démarches administratives et gestion
                      de projet/SAV
                    </Text>
                  </View>
                  <View style={styles.inlineComponents}>
                    <Text style={{ marginBottom: 10 }}>
                      Constitution des dossiers d’aides financières
                      éventuellement liées au projet
                    </Text>
                  </View>

                  <PdfRowBackgroundProduit
                    name="Frais de gestion offerts"
                    montantTTC={-ISOComblemanagementFeesTTC}
                  />
                </>
              ) : null}
            </View>
            {ISOCombleTotalHelp && devisOptions.isHelpsDisplayed ? (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Aides"
                  aides={ISOComble?.help
                    .filter((help) => help.is_eligible)
                    .map((help) => {
                      return {
                        aide: help.name,
                        montant: help.value,
                        isDeducted:
                          help.help_type === "mpr"
                            ? devisOptions.isMPRHelpsDeducted
                            : help.help_type === "cee"
                            ? devisOptions.isCEEHelpsDeducted
                            : false,
                      };
                    })}
                />
              </View>
            ) : null}
            <PdfTotalContainer
              devisOptions={devisOptions}
              totalHT={ISOCombleTotalPriceWhithoutTax}
              totalTVA={ISOCombleTotalTVA}
              totalTTC={ISOCombleTotalPriceWithTax}
              totalHelp={ISOCombleTotalHelp}
              resteACharge={ISOCombleTotalResteACharge}
              deductibleHelp={ISOCombleTotalDeductibleHelps}
              refundableHelp={ISOCombleTotalRefundableHelps}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>

      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
