import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfSubTitleProduct } from "../../components/Pdf/Pdf-Devis/PdfSubTitleProduct/PdfSubTitleProduct";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
  ISageProduct,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  CETProduct: IDataProduct | undefined;
  CETSageProduct: ISageProduct | undefined;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  commercialUserInformation: ICommercialUserInformation;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
}

export const PdfCETPage = ({
  documentId,
  CETProduct,
  CETSageProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
  devisOptions,
}: IProps) => {
  const {
    extraChargeWithoutTax: CETExtraChargeWithoutTax,
    totalPriceWhithoutTax: CETTotalPriceWhithoutTax,
    totalTVA: CETTotalTVA,
    totalPriceWithTax: CETTotalPriceWithTax,
    totalHelp: CETTotalHelp,
    totalResteACharge: CETTotalResteACharge,
    extraChargeWithTax: CETExtraChargeWithTax,
    installationPriceWithoutTax: CETInstallationPriceWithoutTax,
    installationPriceWithTax: CETInstallationPriceWithTax,
    managementFeesTTC: CETManagementFeesTTC,
    managementFeesHT: CETManagementFeesHT,
    totalDeductibleHelps: CETTotalDeductibleHelps,
    totalRefundableHelps: CETTotalRefundableHelps,
  } = productPrices({
    product: CETProduct,
    isEligibleRenoGlobal,
    devisOptions,
  });

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              CETProduct?.product_alias ||
              CETProduct?.product_name ||
              "Produit NC"
            }
          />
          <PdfSubTitleProduct
            title={
              "Référence fabricant : " +
                CETSageProduct?.["Référence fabricant"] || "NC"
            }
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={1}
              prixUnitaire={CETProduct?.product_price_without_tax || 0}
              TVA={CETProduct?.TVA_percentage || 0}
              montantTTC={CETProduct?.product_price_with_tax || 0}
            />

            {/* CET */}
            {CETSageProduct ? (
              <PdfItemDetail
                key={CETSageProduct.AR_Ref}
                title={
                  (CETProduct?.product_alias || CETProduct?.product_name) &&
                  CETSageProduct.NO_Qte
                    ? CETSageProduct.NO_Qte +
                      " " +
                      (CETProduct?.product_alias || CETProduct?.product_name)
                    : ""
                }
                description={[
                  <>
                    {CETProduct?.product_name && (
                      <Text>{CETProduct?.product_name}</Text>
                    )}
                  </>,
                  <>
                    {CETProduct?.product_characteristics
                      ?.ecs_storage_volume && (
                      <Text>{`Volume : ${CETProduct?.product_characteristics.ecs_storage_volume} + L`}</Text>
                    )}
                  </>,
                  <>
                    {CETSageProduct.Dimensions && (
                      <Text>
                        {CETSageProduct.Dimensions &&
                        CETSageProduct.Dimensions.length > 0
                          ? `Taille : ${CETSageProduct.Dimensions}`
                          : null}{" "}
                      </Text>
                    )}
                  </>,
                  <>
                    {CETSageProduct["Ref fluide frigorigène"] && (
                      <Text>
                        {CETSageProduct["Ref fluide frigorigène"].length > 0
                          ? `Référence fluide frigorigène : ${CETSageProduct["Ref fluide frigorigène"]}`
                          : null}
                      </Text>
                    )}
                  </>,
                  <>
                    {CETSageProduct["Profil de soutirage"] && (
                      <Text>
                        {CETSageProduct["Profil de soutirage"].length > 0
                          ? `Profil de soutirage : ${CETSageProduct["Profil de soutirage"]}`
                          : null}
                      </Text>
                    )}
                  </>,
                  <>
                    {CETSageProduct["Norme et Certification"] &&
                      CETSageProduct["Norme et Certification"]?.length > 0 && (
                        <Text>
                          {CETSageProduct["Norme et Certification"]
                            ? `Normes et certifications : ${CETSageProduct["Norme et Certification"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {CETProduct?.product_characteristics?.scop && (
                      <Text>
                        {`SCOP : ${CETProduct.product_characteristics.scop}`}
                      </Text>
                    )}
                  </>,
                  <>
                    {CETProduct?.product_characteristics?.etas && (
                      <Text>
                        {`Etas : ${CETProduct?.product_characteristics?.etas}`}
                      </Text>
                    )}
                  </>,
                  <>
                    {CETSageProduct["Classe energétique (Froid/Chaud"] &&
                      CETSageProduct["Classe energétique (Froid/Chaud"]
                        ?.length > 0 && (
                        <Text>
                          {CETSageProduct["Classe energétique (Froid/Chaud"]
                            ? `Classe énergétique : ${CETSageProduct["Classe energétique (Froid/Chaud"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                  <>
                    {CETSageProduct["Garantie Fabricant"] &&
                      CETSageProduct["Garantie Fabricant"]?.length > 0 && (
                        <Text>
                          {CETSageProduct["Garantie Fabricant"]
                            ? `Garantie Fabriquant : ${CETSageProduct["Garantie Fabricant"]}`
                            : null}
                        </Text>
                      )}
                  </>,
                ]}
              />
            ) : null}
            <View wrap={false}>
              <PdfRowBackgroundProduit
                name="Forfait installation"
                quantity={1}
                prixUnitaire={CETInstallationPriceWithoutTax}
                TVA={CETProduct?.TVA_percentage || 0}
                montantTTC={CETInstallationPriceWithTax}
              />
              <View style={styles.inlineComponents}>
                <Text>Déplacement</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Dépose du matériel existant (mais n’inclut pas l’enlèvement et
                  l’évacuation de celui-ci)
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Mise en place d'un ballon thermodynamique{" "}
                  <Text style={{ fontFamily: "DMSansBold" }}>
                    à accumulation
                  </Text>
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Adaptation de la plomberie existante afin de raccorder la
                  nouvelle installation
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Mise en service de l’installation</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text style={{ marginBottom: 10 }}>
                  Fournitures des équipements nécessaires à la pose des
                  matériels
                </Text>
              </View>
            </View>
            {(CETProduct?.extra_charges_added?.length || 0) > 0 ? (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Spécificité du chantier"
                  quantity={CETProduct?.extra_charges_added?.length}
                  prixUnitaire={CETExtraChargeWithoutTax || 0}
                  TVA={CETProduct?.TVA_percentage || 0}
                  montantTTC={CETExtraChargeWithTax || 0}
                />
                {CETProduct?.extra_charges_added?.map((extraCharge) => {
                  return (
                    <PdfRowProduit
                      key={extraCharge.product_name}
                      name={extraCharge.product_name}
                      quantity={1}
                      prixUnitaire={extraCharge.price_without_tax}
                      TVA={extraCharge.TVA_percentage}
                      montantTTC={extraCharge.price_with_tax}
                      description={extraCharge.description}
                    />
                  );
                })}
              </View>
            ) : null}
            <View wrap={false}>
              {CETManagementFeesTTC !== 0 ? (
                <>
                  <PdfRowBackgroundProduit
                    name="Frais de gestion"
                    quantity={1}
                    prixUnitaire={CETManagementFeesHT}
                    TVA={CETProduct?.installation_tva_percentage}
                    montantTTC={CETManagementFeesTTC}
                  />
                  <View style={styles.inlineComponents}>
                    <Text>
                      Prise en charge des démarches administratives et gestion
                      de projet/SAV
                    </Text>
                  </View>
                  <View style={styles.inlineComponents}>
                    <Text style={{ marginBottom: 10 }}>
                      Constitution des dossiers d’aides financières
                      éventuellement liées au projet
                    </Text>
                  </View>

                  <PdfRowBackgroundProduit
                    name="Frais de gestion offerts"
                    montantTTC={-CETManagementFeesTTC}
                  />
                </>
              ) : null}
            </View>
            {CETTotalHelp && devisOptions.isHelpsDisplayed ? (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Aides"
                  aides={CETProduct?.help
                    .filter((help) => help.is_eligible)
                    .map((help) => {
                      return {
                        aide: help.name,
                        montant: help.value,
                        isDeducted:
                          help.help_type === "mpr"
                            ? devisOptions.isMPRHelpsDeducted
                            : help.help_type === "cee"
                            ? devisOptions.isCEEHelpsDeducted
                            : false,
                      };
                    })}
                />
              </View>
            ) : null}
            <PdfTotalContainer
              devisOptions={devisOptions}
              totalHT={CETTotalPriceWhithoutTax}
              totalTVA={CETTotalTVA}
              totalTTC={CETTotalPriceWithTax}
              totalHelp={CETTotalHelp}
              resteACharge={CETTotalResteACharge}
              deductibleHelp={CETTotalDeductibleHelps}
              refundableHelp={CETTotalRefundableHelps}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
