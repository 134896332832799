import { Text, StyleSheet, Font } from "@react-pdf/renderer";

// Fonts
import DMSansMedium from "../../../../assets/pdf/fonts/DMSansMedium.ttf";

// Register font
Font.register({
  family: "DMSansMedium",
  src: DMSansMedium,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 12,
    color: "#50545f",
    fontFamily: "DMSansMedium",
    marginBottom: 10,
  },
});

interface Props {
  title: string;
}

export const PdfTitreGris = ({ title }: Props) => {
  return <Text style={styles.title}>{title}</Text>;
};
