// Libraries import
import SVG from "react-inlinesvg";

// imgs import
import Search from "../../../../assets/images/DesignSystemIcons/search.svg";
// import React from "react";
import React from "react";
// Styles import
import styled from "styled-components";
import { DSColors } from "../../../../styles/variables";

export interface ChampDeRechercheProps {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  placeholder?: string;
  autoComplete?: string;
  searchIcnSrc?: string;
}

export interface ChampDeRechercheWrapperProps {
  value?: string;
}

const InputWrapper = styled.div<ChampDeRechercheWrapperProps>`
  background-color: ${DSColors.white};
  border: 1px solid
    ${(props) => (props.value ? DSColors.Mint : DSColors.SGLight02)};

  border-radius: 4px;
  display: flex;
  align-items: center;
  min-height: 2.8125rem;

  svg {
    height: 0.9rem;
    path {
      fill: ${(props) =>
        props.value ? DSColors.OffBlack : DSColors.SGLight01};
    }
  }

  &:hover {
    border: 1px solid ${DSColors.Mint};
    svg {
      path {
        fill: ${DSColors.OffBlack};
      }
    }
  }

  &:focus-within {
    border: 1px solid ${DSColors.Mint};
    svg {
      path {
        fill: ${DSColors.OffBlack};
      }
    }
  }
`;
const Input = styled.input<ChampDeRechercheProps>`
  background-color: ${DSColors.white};
  margin-left: 12px;
  font-family: "DM sans", sans-serif;
  width: 100%;
  height: 100%;
  font-weight: 400;
  box-sizing: border-box;
  border: none;
  color: ${DSColors.StableGrey};
  font-size: 1rem;
  caret-color: ${DSColors.SGLight01};
  &:focus {
    ::placeholder {
      color: transparent;
    }
  }

  text-align: left;

  &::placeholder {
    color: ${DSColors.SGLight01};
    font-family: "DM sans", sans-serif;
    font-size: 1rem;
    line-height: 140%;
  }
`;

const Img = styled.div`
  margin-left: 1rem;
`;

export const ChampDeRecherche: React.FC<ChampDeRechercheProps> = ({
  value = "",
  onChange,
  placeholder = "Recherche",
  autoComplete = "off",
  searchIcnSrc = Search,
}) => {
  return (
    <InputWrapper value={value}>
      <Img>
        <SVG src={searchIcnSrc} />
      </Img>
      <Input
        placeholder={placeholder}
        type="text"
        value={value}
        autoComplete={autoComplete}
        onChange={onChange}
      />
    </InputWrapper>
  );
};
