//Style import
import "./selfSufficiencyChartComponent.scss";

import {
  Area,
  AreaChart,
  Label,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

//Service import
import { dailySelfconsumptionDataFormat } from "../../../services/OptionChosen/dailySelfconsumptionDataFormat";

//Interface import
import { IDailySelfConsumptionData } from "../../../interfaces/generalInterfaces";
import moment from "moment";

//Local intefrace
interface Props {
  dataChart: IDailySelfConsumptionData | null;
}

export const SelfSufficiencyChartComponent = ({ dataChart }: Props) => {
  const dataFormated = dailySelfconsumptionDataFormat(dataChart);

  const formatXAxis = (tickItem: number) => {
    return moment(tickItem).format("MM-YYYY");
  };

  return (
    <div className="SelfSufficiencyChartComponent">
      <ResponsiveContainer>
        <AreaChart
          data={dataFormated}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 20,
          }}
        >
          <XAxis
            dataKey="date"
            tickLine={false}
            padding={{ left: 10, right: 10 }}
            tickFormatter={formatXAxis}
            minTickGap={60}
            interval="preserveStartEnd"
          >
            <Label
              value="Consommation sur le réseau sur une année"
              dy={20}
              className="XaxisLabel"
            />
          </XAxis>
          <YAxis
            padding={{ top: -10 }}
            ticks={[100]}
            tickLine={false}
            unit={"%"}
            label={{
              value: "Consommation électrique",
              angle: -90,
              position: "insideLeft",
              dy: 100,
              dx: 5,
            }}
          />
          <Legend
            verticalAlign="top"
            height={36}
            payload={[
              {
                value: "PROD. SOLAIRE STOCKÉE",
                type: "circle",
                color: "#44BA82",
              },
              {
                value: "CONSO RÉSEAU",
                type: "circle",
                color: "#D3D4D7",
              },
            ]}
          />
          <Area
            type="monotone"
            dataKey="network_consumption"
            stackId="1"
            fillOpacity={1}
            stroke="#D3D4D7"
            fill="#D3D4D7"
          />
          <Area
            type="monotone"
            dataKey="solar_consumption"
            stackId="1"
            fillOpacity={1}
            stroke="#44BA82"
            fill="#44BA82"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
