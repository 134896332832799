import React from "react";
import "./App.scss";

// Component import
import { Navigation } from "./navigation/Navigation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Wrap the app with QueryClientProvider to enable
// react-query's features (cache, config, etc.) at a single point in the app
const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Navigation />
      </div>
    </QueryClientProvider>
  );
};

export default App;
