import { MesConsommations } from "../pages/MesConsomations/MesConsommations";
import { MesConsommationsForm } from "../pages/MesConsommationsForm/MesConsommationsForm";

import { MonPotentielSolaire } from "../pages/MonPotentielSolaire/MonPotentielSolaire";
import { MesInformationsForm } from "../pages/MesInformations/MesInformationsForm/MesInformationsForm";
import { MesInformationsMap } from "../pages/MesInformations/MesInformationsMap/MesInformationsMap";

import { MonInstallationScenarios } from "../pages/MonInstallation/MonInstallationScenarios/MonInstallationScenarios";
import { MonInstallationDetailFormule } from "../pages/MonInstallation/MonInstallationDetailFormule/MonInstallationDetailFormule";

import { Recapitulatif } from "../pages/Recapitulatif/Recapitulatif";
import { PageDeSortie } from "../pages/Recapitulatif/PageDeSortie/PageDeSortie";
import { PageDeSortieSouscrire } from "../pages/Recapitulatif/PageDeSortieSouscrire/PageDeSortieSouscrire";
import { MesInformationsRGForm } from "../pages/MesInformationsRG/MesInformationsRGForm/MesInformationsRGForm";
import { MonLogementRG } from "../pages/MonLogementRG/MonLogementRG";
import { MesConsommationsAndEquipementsRG } from "../pages/MesConsommationsAndEquipmentsRG/MesConsommationsAndEquipmentsRG";
import { MaSituationEnergetiqueRG } from "../pages/MaSituationEnergetiqueRG/MaSituationEnergetiqueRG";
import { MonProjetRGPagesWrapper } from "../containers/MonProjetRG/MonProjetRGPagesWrapper/MonProjetRGPagesWrapper";
import { MonProjetRecapWrapper } from "../containers/MonProjetRG/MonProjetRecapWrapper/MonProjetRecapWrapper";
import { RecapitulatifRG } from "../pages/RecapitulatifRG/RecapitulatifRG";

export const photovoltaiqueComponentsData = [
  [
    { Title: "Informations", Component: MesInformationsForm },
    { Title: "Informations", Component: MesInformationsMap },
  ],
  [
    { Title: "Consommations", Component: MesConsommations },
    { Title: "Consommations", Component: MesConsommationsForm },
  ],
  [{ Title: "Potentiel solaire", Component: MonPotentielSolaire }],
  [
    { Title: "Installation", Component: MonInstallationScenarios },
    { Title: "Installation", Component: MonInstallationDetailFormule },
  ],
  [
    { Title: "Récapitulatif", Component: Recapitulatif },
    { Title: "Récapitulatif", Component: PageDeSortie },
    { Title: "Récapitulatif", Component: PageDeSortieSouscrire },
  ],
];

// todo: update allRGComponentsData every time a new step is dev
export const renovationGlobaleComponentsData = [
  [
    { Title: "Informations", Component: MesInformationsRGForm },
    { Title: "Informations", Component: MesInformationsMap },
  ],
  [{ Title: "Logement", Component: MonLogementRG }],

  [
    {
      Title: "Consommations & équipements",
      Component: MesConsommationsAndEquipementsRG,
    },
  ],
  [{ Title: "Situation énergétique", Component: MaSituationEnergetiqueRG }],
  [
    { Title: "Projet", Component: MonProjetRGPagesWrapper },
    { Title: "Projet", Component: MonProjetRecapWrapper },
  ],
  [
    { Title: "Récapitulatif", Component: RecapitulatifRG },
    { Title: "Récapitulatif", Component: PageDeSortieSouscrire },
  ],
];
