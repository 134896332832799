import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfSubTitleProduct } from "../../components/Pdf/Pdf-Devis/PdfSubTitleProduct/PdfSubTitleProduct";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
  ISageProduct,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  ITEProduct: IDataProduct | undefined;
  ITESageProduct: ISageProduct | undefined;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  commercialUserInformation: ICommercialUserInformation;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
}

export const PdfITEPage = ({
  documentId,
  ITEProduct,
  ITESageProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
  devisOptions,
}: IProps) => {
  const {
    extraChargeWithoutTax: ITEExtraChargeWithoutTax,
    totalPriceWhithoutTax: ITETotalPriceWhithoutTax,
    totalTVA: ITETotalTVA,
    totalPriceWithTax: ITETotalPriceWithTax,
    totalHelp: ITETotalHelp,
    totalResteACharge: ITETotalResteACharge,
    extraChargeWithTax: ITEExtraChargeWithTax,
    installationPriceWithTax: ITEInstallationPriceWithTax,
    managementFeesTTC: ITEmanagementFeesTTC,
    managementFeesHT: ITEManagementFeesHT,
    totalDeductibleHelps: ITETotalDeductibleHelps,
    totalRefundableHelps: ITETotalRefundableHelps,
  } = productPrices({
    product: ITEProduct,
    isEligibleRenoGlobal,
    devisOptions,
  });

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              ITEProduct?.product_alias ||
              ITEProduct?.product_name ||
              "Produit NC"
            }
          />
          <PdfSubTitleProduct
            title={
              "Référence fabricant : " +
                ITESageProduct?.["Référence fabricant"] || "NC"
            }
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={
                ITEProduct?.selected_characteristics?.surface_to_isolate || 0
              }
              prixUnitaire={ITEProduct?.product_price_without_tax || 0}
              TVA={ITEProduct?.TVA_percentage || 0}
              montantTTC={ITETotalPriceWithTax - ITEInstallationPriceWithTax}
            />

            {/* ITE */}
            {ITESageProduct ? (
              <>
                <Text style={{ marginBottom: 5 }}>
                  Mise en place d’une isolation de mur par l’extérieur
                </Text>
                <PdfItemDetail
                  key={ITESageProduct.AR_Ref}
                  title={
                    "Isolant " +
                    ITESageProduct.Marque +
                    " " +
                    ITESageProduct.Gamme +
                    " " +
                    ITESageProduct.Dimensions
                  }
                  description={[
                    <>
                      {ITEProduct?.product_name && (
                        <Text>{ITEProduct?.product_name}</Text>
                      )}
                    </>,
                    <>
                      {ITESageProduct["Classe energétique (Froid/Chaud"] && (
                        <Text>
                          {ITESageProduct["Classe energétique (Froid/Chaud"] &&
                          ITESageProduct["Classe energétique (Froid/Chaud"]
                            .length > 0
                            ? `${ITESageProduct["Classe energétique (Froid/Chaud"]}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ITESageProduct.Puissance && (
                        <Text>
                          {ITESageProduct.Puissance &&
                          ITESageProduct.Puissance.length > 0
                            ? `${ITESageProduct.Puissance}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ITESageProduct.Description && (
                        <Text>
                          {ITESageProduct.Description &&
                          ITESageProduct.Description.length > 0
                            ? `${ITESageProduct.Description}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                    <>
                      {ITESageProduct.Conditionnement && (
                        <Text>
                          {ITESageProduct.Conditionnement &&
                          ITESageProduct.Conditionnement.length > 0
                            ? `Dimensions : ${ITESageProduct.Conditionnement}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,

                    <>
                      {ITESageProduct["Norme et Certification"] &&
                        ITESageProduct["Norme et Certification"]?.length >
                          0 && (
                          <Text>
                            {ITESageProduct["Norme et Certification"]
                              ? `Certifications : ${ITESageProduct["Norme et Certification"]}`
                              : null}
                          </Text>
                        )}
                    </>,
                    <>
                      {ITESageProduct["Résistance Thermique "] && (
                        <Text>
                          {ITESageProduct["Résistance Thermique "] &&
                          ITESageProduct["Résistance Thermique "].length > 0
                            ? `R = ${ITESageProduct["Résistance Thermique "]}`
                            : null}{" "}
                        </Text>
                      )}
                    </>,
                  ]}
                />
                <PdfItemDetail
                  key={ITESageProduct.AR_Ref}
                  title={"Autres composants :"}
                  description={[
                    <>
                      <Text>
                        Colles & Sous enduits - Fixation - Enduit - Armature
                        pour la façade - Profilés de départ - Stop enduit -
                        Baguette d'angle - Profilés goutte d'eau - Profil
                        d'arrêt
                      </Text>
                    </>,
                  ]}
                />
              </>
            ) : null}
            <View wrap={false}>
              <PdfRowBackgroundProduit
                name="Forfait installation"
                quantity={
                  ITEProduct?.selected_characteristics?.surface_to_isolate || 0
                }
                prixUnitaire={ITEProduct?.installation_price_without_tax || 0}
                TVA={ITEProduct?.TVA_percentage || 0}
                montantTTC={ITEInstallationPriceWithTax}
              />
              <View style={styles.inlineComponents}>
                <Text>Déplacement</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Mise en place d'une isolation thermique des murs par
                  l’extérieur (avec respect des temps de séchage)
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Fourniture des équipements nécessaires à la pose des matériels
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Montage et démontage de l’échafaudage</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Nettoyage des façades</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Gestion des déchets</Text>
              </View>
            </View>
            {(ITEProduct?.extra_charges_added?.length || 0) > 0 && (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Spécificité du chantier"
                  quantity={ITEProduct?.extra_charges_added?.length}
                  prixUnitaire={ITEExtraChargeWithoutTax || 0}
                  TVA={ITEProduct?.TVA_percentage || 0}
                  montantTTC={ITEExtraChargeWithTax || 0}
                />
                {ITEProduct?.extra_charges_added?.map((extraCharge) => {
                  return (
                    <PdfRowProduit
                      key={extraCharge.product_name}
                      name={extraCharge.product_name}
                      quantity={1}
                      prixUnitaire={extraCharge.price_without_tax}
                      TVA={extraCharge.TVA_percentage}
                      montantTTC={extraCharge.price_with_tax}
                      description={extraCharge.description}
                    />
                  );
                })}
              </View>
            )}
            <View wrap={false}>
              {ITEmanagementFeesTTC !== 0 ? (
                <>
                  <PdfRowBackgroundProduit
                    name="Frais de gestion"
                    quantity={1}
                    prixUnitaire={ITEManagementFeesHT}
                    TVA={ITEProduct?.installation_tva_percentage}
                    montantTTC={ITEmanagementFeesTTC}
                  />
                  <View style={styles.inlineComponents}>
                    <Text>
                      Prise en charge des démarches administratives et gestion
                      de projet/SAV
                    </Text>
                  </View>
                  <View style={styles.inlineComponents}>
                    <Text style={{ marginBottom: 10 }}>
                      Constitution des dossiers d’aides financières
                      éventuellement liées au projet
                    </Text>
                  </View>

                  <PdfRowBackgroundProduit
                    name="Frais de gestion offerts"
                    montantTTC={-ITEmanagementFeesTTC}
                  />
                </>
              ) : null}
            </View>
            {ITETotalHelp && devisOptions.isHelpsDisplayed ? (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Aides"
                  aides={ITEProduct?.help
                    .filter((help) => help.is_eligible)
                    .map((help) => {
                      return {
                        aide: help.name,
                        montant: help.value,
                        isDeducted:
                          help.help_type === "mpr"
                            ? devisOptions.isMPRHelpsDeducted
                            : help.help_type === "cee"
                            ? devisOptions.isCEEHelpsDeducted
                            : false,
                      };
                    })}
                />
              </View>
            ) : null}
            <PdfTotalContainer
              devisOptions={devisOptions}
              totalHT={ITETotalPriceWhithoutTax}
              totalTVA={ITETotalTVA}
              totalTTC={ITETotalPriceWithTax}
              totalHelp={ITETotalHelp}
              resteACharge={ITETotalResteACharge}
              deductibleHelp={ITETotalDeductibleHelps}
              refundableHelp={ITETotalRefundableHelps}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>

      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
