// Libraries import
import { useState } from "react";
import {
  LoanSimulationApiResponse,
  SofincoLoanData,
} from "../../../interfaces/generalInterfaces";
import { useAxiosPost } from "../../useAxiosPost";

export const useGetSofincoLoanSimulation = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const {
    axiosPostRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();

  const getSofincoLoanSimulation = async ({
    userToken,
    amount,
    rateCode,
    fundingPeriod,
    study_id,
    personalContributionAmount,
    hasBorrowerInsurance,
    hasCoBorrower,
  }: {
    userToken: string | null;
    amount: number;
    fundingPeriod: number;
    rateCode: string;
    study_id: string;
    personalContributionAmount: number;
    hasBorrowerInsurance: boolean;
    hasCoBorrower: boolean;
  }) => {
    setErrorMessage("");

    const response = await axiosPostRequest<LoanSimulationApiResponse>(
      `${BACKEND_URL}/oree2/SofincoLoanSimulation/${study_id}`,
      {
        SofincoLoanSimulationPayload: {
          amount,
          fundingPeriod,
          scaleCode: rateCode,
          personalContributionAmount,
          hasBorrowerInsurance,
          hasCoBorrower,
        },
      },
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    const sofincoLoanData: SofincoLoanData = response?.data.sofincoLoanData || {
      TAEG: null,
      monthlyAmountWithInsuranceAndServices: null,
      annualPercentageRate: null,
      loanAmount: 0,
      deferralDurationMonth: 0,
    };

    return sofincoLoanData;
  };
  return {
    getSofincoLoanSimulation,
    isLoading,
    errorMessage: errorMessage || axiosErrorMessage,
  };
};
