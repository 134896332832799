import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Style import
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfTitleWithLign } from "../../components/Pdf/Pdf-Devis/PdfTitleWithLign/PdfTitleWithLign";
import { PdfTitreListe } from "../../components/Pdf/Pdf-Devis/PdfTitreListe/PdfTitreListe";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces import
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
  ISageProduct,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  isEligibleRenoGlobal: boolean;
  commercialUserInformation: ICommercialUserInformation;
  capitalize: (str: string | null) => string | null;
  emailInputValue: string;
  firstNameInputValue: string;
  lastNameInputValue: string;
  phoneNumberInputValue: string;
  studyInformation: IStudyInformation;
  finalCartProducts: IDataProduct[];
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  kitHomeManagementSageProduct: ISageProduct | undefined;
  batterySageProduct: ISageProduct | undefined;
  coupons: { label: string; type: string; value: number }[];
  PVProduct: IDataProduct | undefined;
  SolarPannelsSageProduct: ISageProduct | undefined;
  OnduleurSageProduct: ISageProduct | undefined;
  OptimiseurSageProduct: ISageProduct | undefined;
  CoffretACDCSageProduct: ISageProduct | undefined;
  totalValueCoupon?: number | null;
  totalDevisWithoutTax: number;
  totalTVADevis: number;
  totalDevisWithTaxes: number;
  totalHelp: number;
  restToPay: number;
  ITEProduct?: IDataProduct;
  PACAirEauProduct?: IDataProduct;
  PACAirAirProducts?: IDataProduct[];
  CETProduct?: IDataProduct;
  ISOCombleProduct?: IDataProduct;
  ISORampantsProduct?: IDataProduct;
  ISOPlancherProduct?: IDataProduct;
  toalMPRHelp: number;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
  totalDeductibleHelps: number;
  totalRefundableHelps: number;
}

export const PdfSummaryPage = ({
  documentId,
  isEligibleRenoGlobal,
  finalCartProducts,
  commercialUserInformation,
  studyInformation,
  emailInputValue,
  firstNameInputValue,
  lastNameInputValue,
  phoneNumberInputValue,
  capitalize,
  formatNumberWithSpacesAndDecimal,
  kitHomeManagementSageProduct,
  batterySageProduct,
  coupons,
  PVProduct,
  SolarPannelsSageProduct,
  OnduleurSageProduct,
  OptimiseurSageProduct,
  CoffretACDCSageProduct,
  totalValueCoupon,
  totalDevisWithoutTax,
  totalTVADevis,
  totalDevisWithTaxes,
  totalHelp,
  toalMPRHelp,
  restToPay,
  ITEProduct,
  PACAirEauProduct,
  CETProduct,
  ISOCombleProduct,
  ISORampantsProduct,
  ISOPlancherProduct,
  isDemoVersion,
  devisOptions,
  totalDeductibleHelps,
  totalRefundableHelps,
  PACAirAirProducts,
}: IProps) => {
  const isPoseoProducts =
    PVProduct ||
    PACAirEauProduct ||
    CETProduct ||
    (PACAirAirProducts?.length || 0) !== 0;
  const isISOProducts =
    ITEProduct || ISOCombleProduct || ISORampantsProduct || ISOPlancherProduct;

  const battery = PVProduct?.product_characteristics.available_batteries?.find(
    (battery) =>
      battery.sage_reference ===
      PVProduct?.selected_characteristics.battery_sage_reference
  );

  return (
    <Page size="A4" style={(styles.page, { paddingBottom: 40 })}>
      <View fixed>
        <PdfBanner isFondBleu />
      </View>

      <PdfLayout isDemoVersion={isDemoVersion}>
        <PdfHeader
          commercialUserInformation={commercialUserInformation}
          logoBase64={commercialUserInformation.company_logo_base_64}
        />
        <View>
          <PdfTitleWithLign devisNumero={documentId} />
          <View style={styles.containerInfos}>
            <View style={styles.columnInfos}>
              <PdfTitreListe
                title={
                  capitalize(firstNameInputValue) +
                  " " +
                  capitalize(lastNameInputValue)
                }
                list={[
                  <>{phoneNumberInputValue}</>,
                  <>{emailInputValue.toLowerCase()}</>,
                ]}
              />
            </View>
            <View style={styles.columnInfos}>
              <PdfTitreListe
                title="Adresse de l'installation :"
                list={[
                  <>{studyInformation.address?.toLowerCase()}</>,
                  <>{studyInformation.zipcode},</>,
                ]}
              />
            </View>

            <View style={styles.columnInfos}>
              <PdfTitreListe
                title="Conseiller :"
                list={[
                  <>
                    {capitalize(commercialUserInformation.userFirstName) +
                      " " +
                      capitalize(commercialUserInformation.userLastName)}
                  </>,
                  <>{commercialUserInformation.userPhoneNumber}</>,
                  <>{commercialUserInformation.userEmail?.toLowerCase()}</>,
                ]}
              />
            </View>
            <View style={styles.columnInfos}>
              <PdfTitreListe
                title="Service client :"
                list={[
                  <>{commercialUserInformation.company_service_client_phone}</>,
                  <>{commercialUserInformation.company_service_client_email}</>,
                ]}
              />
            </View>

            {/* Installer for PV/CET/PAC product. Only if there is the product(s) */}
            {isPoseoProducts ? (
              isDemoVersion ? (
                <View style={styles.columnInfos}>
                  <PdfTitreListe
                    title="Installateur 1 : ABCDE FGH"
                    list={[
                      <>Siret : 00000000000000</>,
                      <>Qual. QUALIFELEC RGE</>,
                      <>PV 00-AA-00000-000</>,
                    ]}
                  />
                </View>
              ) : (
                <View style={styles.columnInfos}>
                  <PdfTitreListe
                    title="Installateur 1 : POSEO ENR"
                    list={[
                      <>Siret : 80945456400026</>,
                      <>QUALIFELEC RGE PV & PAC</>,
                      <>N° 108796 & N° 108798</>,
                    ]}
                  />
                </View>
              )
            ) : null}

            {/* Installer for isolation product. Only if there is an isolation product */}
            {isISOProducts ? (
              isDemoVersion ? (
                <View style={styles.columnInfos}>
                  <PdfTitreListe
                    title={`Installateur${
                      isPoseoProducts ? " 2" : ""
                    } : ABCDE FGH`}
                    list={[
                      <>SIRET : 00000000000000</>,
                      <>QUALIBAT RGE</>,
                      <>N° A-A0000000</>,
                    ]}
                  />
                </View>
              ) : (
                <View style={styles.columnInfos}>
                  <PdfTitreListe
                    title={`Installateur${
                      isPoseoProducts ? " 2" : ""
                    } : EUROPISOL`}
                    list={[
                      <>SIRET : 85259202100012</>,
                      <>QUALIBAT RGE ITE</>,
                      <>N° E-E180813</>,
                    ]}
                  />
                </View>
              )
            ) : null}
            <View style={styles.columnInfos}>
              <PdfTitreListe
                title="Date du devis:"
                list={[<>{moment().format("DD/MM/YYYY")}</>]}
              />
            </View>
          </View>
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>
                Description produit
              </Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            {finalCartProducts
              .filter((product) => product.isSelected)
              .map((product, index) => {
                const { totalPriceWhithoutTax, totalPriceWithTax } =
                  productPrices({ product, isEligibleRenoGlobal });
                return (
                  <View key={index}>
                    <View style={styles.itemAbscisse}>
                      <View style={styles.descriptionProduits}>
                        <View>
                          <Text style={styles.itemDescriptionProduits}>
                            {/* If the product has an alias from the price list we prefer to display it over the product name which comes from Sage */}
                            {product.product_alias || product.product_name}
                          </Text>
                          {product.product_type === "PV" ? (
                            <>
                              <Text
                                key={product.sage_reference}
                                style={styles.itemDetailDescriptionProduits}
                              >
                                {SolarPannelsSageProduct?.NO_Qte +
                                  " modules " +
                                  SolarPannelsSageProduct?.Marque}
                              </Text>
                              {OnduleurSageProduct ? (
                                <Text
                                  key={product.sage_reference}
                                  style={styles.itemDetailDescriptionProduits}
                                >
                                  {(OnduleurSageProduct?.NO_Qte || 1) +
                                    " onduleur " +
                                    OnduleurSageProduct?.Marque}
                                </Text>
                              ) : null}
                              {OptimiseurSageProduct ? (
                                <Text
                                  key={product.sage_reference}
                                  style={styles.itemDetailDescriptionProduits}
                                >
                                  {(OptimiseurSageProduct?.NO_Qte ||
                                    SolarPannelsSageProduct?.NO_Qte) +
                                    " optimiseurs " +
                                    OptimiseurSageProduct?.Marque +
                                    ", Kit de fixation"}
                                </Text>
                              ) : null}
                              {CoffretACDCSageProduct ? (
                                <Text
                                  key={product.sage_reference}
                                  style={styles.itemDetailDescriptionProduits}
                                >
                                  {CoffretACDCSageProduct?.AR_Design}
                                </Text>
                              ) : null}
                            </>
                          ) : null}

                          {/* In case of a PAC air/air product we add the interior unit(s) information */}
                          {product.selected_characteristics?.rooms_information
                            ?.flatMap((room) => room.interior_units)
                            .map((interiorUnit, index) => {
                              return (
                                <Text
                                  key={product.sage_reference + index}
                                  style={styles.itemDetailDescriptionProduits}
                                >
                                  {"+ " +
                                    (interiorUnit.alias ||
                                      interiorUnit.name)}{" "}
                                  {/* If the product has an alias from the price list we prefer to display it over the product name which comes from Sage */}
                                </Text>
                              );
                            })}
                        </View>
                      </View>
                      <Text style={[styles.itemQuantite, styles.quantite]}>
                        1
                      </Text>
                      <Text
                        style={[
                          styles.itemPrixUnitaireHT,
                          styles.prixUnitaireHT,
                        ]}
                      >
                        <>
                          {formatNumberWithSpacesAndDecimal(
                            totalPriceWhithoutTax,
                            2
                          )}{" "}
                          €
                        </>
                      </Text>
                      <Text style={[styles.itemTva, styles.tva]}>
                        {product.TVA_percentage} %
                      </Text>
                      <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
                        {formatNumberWithSpacesAndDecimal(totalPriceWithTax, 2)}{" "}
                        €
                      </Text>
                    </View>{" "}
                    {product.extra_charges_added?.map((extraCharge, index) => {
                      return (
                        <View style={styles.itemAbscisse} key={index}>
                          <View
                            style={styles.descriptionProduits}
                            key={extraCharge.product_name}
                          >
                            <Text
                              style={[styles.ExtraCharge, { marginLeft: 20 }]}
                            >
                              {extraCharge?.product_name
                                ?.charAt(0)
                                .toUpperCase() +
                                extraCharge?.product_name?.slice(1)}
                            </Text>
                          </View>
                          <Text
                            style={[
                              styles.itemQuantite,
                              styles.quantite,
                              styles.ExtraCharge,
                            ]}
                          >
                            1
                          </Text>
                          <Text
                            style={[
                              styles.itemPrixUnitaireHT,
                              styles.prixUnitaireHT,
                              { color: "#A8AAAF" },
                            ]}
                          >
                            {formatNumberWithSpacesAndDecimal(
                              Number(extraCharge.price_without_tax),
                              2
                            ) + " €"}
                          </Text>
                          <Text
                            style={[
                              styles.itemTva,
                              styles.tva,
                              { color: "#A8AAAF" },
                            ]}
                          >
                            {extraCharge.TVA_percentage + " %"}
                          </Text>
                          <Text
                            style={[
                              styles.itemMontantTTC,
                              styles.montantTTC,
                              styles.itemAbscisseExtraCharge,
                            ]}
                          >
                            {formatNumberWithSpacesAndDecimal(
                              Number(extraCharge.price_with_tax),
                              2
                            ) + " €"}
                          </Text>
                        </View>
                      );
                    })}
                    {(!isEligibleRenoGlobal || product.product_type === "PV") &&
                    devisOptions.isHelpsDisplayed ? (
                      <View style={styles.itemHelpContainer}>
                        {product.help
                          .filter((help) => help.is_eligible)
                          .map((help, index) => {
                            const isDeducted =
                              help.help_type === "mpr"
                                ? devisOptions.isMPRHelpsDeducted
                                : help.help_type === "cee"
                                ? devisOptions.isCEEHelpsDeducted
                                : false;
                            return (
                              <View style={styles.itemHelp} key={index}>
                                <View
                                  style={styles.descriptionProduits}
                                  key={help.name}
                                >
                                  <Text style={[styles.AidePrime]}>
                                    {help.name}
                                  </Text>
                                </View>
                                <Text
                                  style={[styles.itemQuantite, styles.quantite]}
                                ></Text>
                                <Text
                                  style={[
                                    styles.itemPrixUnitaireHT,
                                    styles.prixUnitaireHT,
                                  ]}
                                ></Text>
                                <Text
                                  style={[styles.itemTva, styles.tva]}
                                ></Text>
                                <Text
                                  style={[
                                    styles.itemMontantTTC,
                                    styles.montantTTC,
                                    styles.itemAbscisseAidePrime,
                                  ]}
                                >
                                  {(isDeducted ? "-" : "") +
                                    formatNumberWithSpacesAndDecimal(
                                      Number(help.value),
                                      2
                                    ) +
                                    " €"}
                                </Text>
                              </View>
                            );
                          })}
                      </View>
                    ) : null}
                  </View>
                );
              })}

            {PVProduct?.selected_characteristics.kit_home_management && (
              <View style={styles.itemAbscisse}>
                <View style={styles.descriptionProduits}>
                  <View key={kitHomeManagementSageProduct?.AR_Ref}>
                    <Text style={styles.itemDescriptionProduits}>
                      {PVProduct.product_characteristics.kit_home_management
                        ?.alias || kitHomeManagementSageProduct?.AR_Design}
                    </Text>
                  </View>
                </View>
                <Text style={[styles.itemQuantite, styles.quantite]}>1</Text>
                <Text
                  style={[styles.itemPrixUnitaireHT, styles.prixUnitaireHT]}
                >
                  {formatNumberWithSpacesAndDecimal(
                    (PVProduct.product_characteristics.kit_home_management
                      ?.price_without_tax || 0) +
                      (PVProduct.product_characteristics.kit_home_management
                        ?.installation_price_without_tax || 0),
                    2
                  )}{" "}
                  €
                </Text>
                <Text style={[styles.itemTva, styles.tva]}>
                  {
                    PVProduct.product_characteristics.kit_home_management
                      ?.tva_percentage
                  }{" "}
                  %
                </Text>
                <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
                  {formatNumberWithSpacesAndDecimal(
                    (PVProduct.product_characteristics.kit_home_management
                      ?.price_with_tax || 0) +
                      (PVProduct.product_characteristics.kit_home_management
                        ?.installation_price_with_tax || 0),
                    2
                  )}{" "}
                  €
                </Text>
              </View>
            )}
            {(PVProduct?.selected_characteristics?.n_batteries || 0) > 0 && (
              <View style={styles.itemAbscisse}>
                <View style={styles.descriptionProduits}>
                  <View key={batterySageProduct?.AR_Ref}>
                    <Text style={styles.itemDescriptionProduits}>
                      {batterySageProduct?.AR_Design}
                    </Text>
                  </View>
                </View>
                <Text style={[styles.itemQuantite, styles.quantite]}>
                  {PVProduct?.selected_characteristics?.n_batteries}
                </Text>
                <Text
                  style={[styles.itemPrixUnitaireHT, styles.prixUnitaireHT]}
                >
                  {formatNumberWithSpacesAndDecimal(
                    (battery?.price_without_tax || 0) +
                      (battery?.installation_price_without_tax || 0),
                    2
                  )}{" "}
                  €
                </Text>
                <Text style={[styles.itemTva, styles.tva]}>
                  {battery?.tva_percentage} %
                </Text>
                <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
                  {formatNumberWithSpacesAndDecimal(
                    (battery?.price_with_tax || 0) +
                      (battery?.installation_price_with_tax || 0) *
                        (PVProduct?.selected_characteristics.n_batteries || 0),
                    2
                  )}{" "}
                  €
                </Text>
              </View>
            )}
            {coupons.length !== 0 && (
              <View break style={{ marginTop: 40 }}>
                {coupons.map((item, index) => {
                  return (
                    <View
                      key={item.label + item.value + index}
                      style={[styles.itemAbscisse, { marginBottom: 10 }]}
                    >
                      <View style={styles.descriptionProduits}>
                        <Text style={styles.itemDescriptionProduits}>
                          Remise exceptionelle : {item.label}
                        </Text>
                      </View>
                      <Text
                        style={[styles.itemQuantite, styles.quantite]}
                      ></Text>
                      <Text
                        style={[
                          styles.itemPrixUnitaireHT,
                          styles.prixUnitaireHT,
                        ]}
                      >
                        -{" "}
                        {formatNumberWithSpacesAndDecimal(
                          (item.value * 100) / (100 + 20),
                          2
                        )}{" "}
                        €
                      </Text>
                      <Text style={[styles.itemTva, styles.tva]}>20 %</Text>
                      <Text style={[styles.itemMontantTTC, styles.montantTTC]}>
                        - {formatNumberWithSpacesAndDecimal(item.value, 2)} €
                      </Text>
                    </View>
                  );
                })}
              </View>
            )}
            {isEligibleRenoGlobal && devisOptions.isHelpsDisplayed && (
              <View
                style={[
                  styles.itemAbscisse,
                  styles.itemAbscisseAidePrime,
                  { marginTop: 10, marginBottom: 10 },
                ]}
              >
                <View style={styles.descriptionProduits}>
                  <Text style={styles.itemDescriptionProduits}>
                    Aide MaPrimeRénov’ Rénovation d'ampleur
                  </Text>
                </View>
                <Text style={styles.quantite}></Text>
                <Text style={styles.prixUnitaireHT}></Text>
                <Text style={styles.tva}></Text>
                <View>
                  <Text
                    style={[
                      styles.itemMontantTTC,
                      styles.montantTTC,
                      styles.itemAbscisseAidePrime,
                    ]}
                  >
                    {(devisOptions.isMPRHelpsDeducted ? "-" : "") +
                      formatNumberWithSpacesAndDecimal(toalMPRHelp, 2)}{" "}
                    €
                  </Text>
                </View>
              </View>
            )}

            <View wrap={false} style={styles.containerTotal}>
              <View style={styles.containerDelay}>
                <Text>Délai de validité : </Text>
                <Text style={styles.delayDate}>
                  {moment().add(30, "days").format("DD/MM/YYYY")}
                </Text>
              </View>
              <PdfTotalContainer
                devisOptions={devisOptions}
                totalHT={totalDevisWithoutTax}
                totalTVA={totalTVADevis}
                totalTTC={totalDevisWithTaxes}
                totalHelp={totalHelp}
                resteACharge={restToPay}
                deductibleHelp={totalDeductibleHelps}
                refundableHelp={totalRefundableHelps}
                totalValueCoupon={totalValueCoupon}
              />
            </View>
          </View>
          <View wrap={false} style={styles.prochainesEtapes__containerCode}>
            <View style={styles.prochainesEtapes__containerCodeLeft}>
              <Text style={styles.prochainesEtapes__containerCodeTextOne}>
                {"Parrainez un proche !"}
              </Text>
              <Text style={styles.prochainesEtapes__containerCodeTextTwo}>
                {
                  "Vous connaissez quelqu'un qui envisage un projet de rénovation énergétique ?"
                }
              </Text>
              <Text style={styles.prochainesEtapes__containerCodeTextThree}>
                {
                  "Partagez votre code parrainage et bénéficiez de 200 euros après son installation !"
                }
              </Text>
            </View>
            <View style={styles.prochainesEtapes__containerCodeRight}>
              <View style={styles.prochainesEtapes__containerCodeRightBorder}>
                <Text style={styles.prochainesEtapes__containerCodeTextRight}>
                  {"VOTRE CODE"}
                </Text>
                <Text style={styles.prochainesEtapes__containerCodeRightCode}>
                  {studyInformation.membership_code
                    ? studyInformation.membership_code
                    : "Demandez à votre conseiller"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </PdfLayout>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
