// import React from "react";
import React from "react";
// Styles import
import styled from "styled-components";
import { DSColors } from "../../../../styles/variables";
// interface declaration
interface IOption {
  label: string | null;
  value: string | null;
}

export interface AtomMultiChoiceBtnProps {
  /** single label/value option represented in the item **/
  option: IOption | null;
  /** function to be called on the item click **/
  onClick: (option: IOption | null) => void;
  color?: string;
  iconSrc?: string;
  isClicked?: boolean;
  gap?: boolean;
  index: number;
  lastIndex: number;
}
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonWrapper = styled.div<{
  color?: string;
  option: IOption | null;
  clicked?: boolean;
  iconSrc?: string;
  gap?: boolean;
  index: number;
  lastIndex: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 164px;
  min-height: ${(props) => (!!props.iconSrc ? "104px" : "61px")};
  border: ${(props) => (props.clicked ? "2px" : "1px")} solid
    ${(props) => (props.clicked ? props.color : DSColors.SGLight03)};
  border-radius: ${(props) =>
    props.gap
      ? "4px"
      : props.index === 0
      ? "4px 0px 0px 4px"
      : props.index === props.lastIndex
      ? "0px 4px 4px 0px"
      : "Opx"};
  gap: 5px;
  cursor: pointer;
  background-color: white;
`;
const P = styled.p<{
  option: IOption | null;
  color?: string;
  clicked?: boolean;
}>`
  font-size: 14px;
  font-family: "DM Sans", serif;
  color: ${(props) =>
    props.clicked
      ? props.color
        ? props.color
        : DSColors.Mint
      : DSColors.Black};
  font-weight: ${(props) => (props.clicked ? 500 : 400)};
  width: 140px;
  text-align: center;
  line-height: 20px;
`;
const Icon = styled.img<{
  iconSrc?: string;
}>`
  display: ${(props) => (!!props.iconSrc ? "unset" : "none")};
`;

export const AtomeChoixMultiple: React.FC<AtomMultiChoiceBtnProps> = ({
  option,
  onClick,
  iconSrc = "",
  color = DSColors.Mint,
  isClicked = false,
  gap = true,
  index,
  lastIndex,
}) => {
  return (
    <Container>
      <ButtonWrapper
        iconSrc={iconSrc}
        clicked={isClicked}
        onClick={() => onClick(option)}
        color={color}
        option={option}
        gap={gap}
        index={index}
        lastIndex={lastIndex}
      >
        <Icon src={iconSrc} iconSrc={iconSrc} />
        <P clicked={isClicked} color={color} option={option}>
          {option && option.label}
        </P>
      </ButtonWrapper>
    </Container>
  );
};
