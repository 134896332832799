// Styles import
import "./MesConsommationsAndEquipmentsRG.scss";

// Components import
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import { Quantite } from "@web/shared/dist/components/DesignSystem/Boutons/Quantite/Quantite";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";

// Images import
import bois from "@web/shared/dist/assets/images/DesignSystemIllustrations/bois1.svg";

// Data import
import { mesConsommationsQuestionsData } from "./MesConsommationsQuestionsData";

// Interfaces import
import { IStudyInformation } from "../../interfaces/generalInterfaces";

// Local interfaces declaration
interface IProps {
  studyInformation: IStudyInformation;
  boisConsommationEuros: string | null;
  isBois: boolean;
  setBoisConsommationEuros: React.Dispatch<React.SetStateAction<string | null>>;
  boisSurfaceChauffee: string | null;
  setBoisSurfaceChauffee: React.Dispatch<React.SetStateAction<string | null>>;
  boisTemperatureConfort: number | null;
  setBoisTemperatureConfort: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  boisTypeChauffage: IOption[];
  setBoisTypeChauffage: React.Dispatch<React.SetStateAction<IOption[]>>;
  boisNombreEmetteur: number | null;
  setBoisNombreEmetteur: React.Dispatch<React.SetStateAction<number | null>>;
  boisTypeEmetteur: IOption[];
  setBoisTypeEmetteur: React.Dispatch<React.SetStateAction<IOption[]>>;
  sourceEnergie: IOption[];
}

interface IOption extends IImage {
  label: string | null;
  value: string | null;
}

interface IImage {
  imgSrc?: string;
  imgAltTxt?: string;
}

export const BoisPartForm = ({
  studyInformation,
  boisConsommationEuros,
  isBois,
  setBoisConsommationEuros,
  boisSurfaceChauffee,
  setBoisSurfaceChauffee,
  boisTemperatureConfort,
  setBoisTemperatureConfort,
  boisTypeChauffage,
  setBoisTypeChauffage,
  boisNombreEmetteur,
  setBoisNombreEmetteur,
  boisTypeEmetteur,
  setBoisTypeEmetteur,
  sourceEnergie,
}: IProps) => {
  return (
    <section
      className={`MesConsommationsAndEquipementsRG__SectionContent ${
        !sourceEnergie.some((item) => item.value === "bois") && "Hidden"
      }`}
      id="caracteristiques_du_logement"
    >
      <div className="MesConsommationsAndEquipementsRG__TitleOne">
        <img src={bois} alt="bois" />
        <h3>Bois</h3>
      </div>
      <section>
        <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
          <label className="label" htmlFor="consommationElec">
            Consommation de bois annuelle :{" "}
          </label>
          <div style={{ maxWidth: "102px" }}>
            <TextInput
              inputLableHidden
              type="number"
              inputDescriptionHidden
              iconType="euros"
              placeholder="XXXX"
              value={boisConsommationEuros ?? undefined}
              onChange={(e) => {
                setBoisConsommationEuros(e.currentTarget.value);
              }}
              required={isBois}
              iconHidden={false}
            />
          </div>
        </div>
      </section>
      <section>
        <div className="MesConsommationsAndEquipementsRG__Trait" />
        <div className="MesConsommationsAndEquipementsRG__TitleTwo">
          <h3>Chauffage</h3>
        </div>
        <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
          <label className="label" htmlFor="surfaceChauffee">
            Surface chauffée :{" "}
          </label>
          <div style={{ maxWidth: "102px" }}>
            <TextInput
              inputLableHidden
              type="number"
              inputDescriptionHidden
              iconType="squaredMeters"
              placeholder="xxxx"
              value={
                boisSurfaceChauffee ??
                studyInformation.bois_surface_chauffee?.toString() ??
                undefined
              }
              onChange={(e) => {
                setBoisSurfaceChauffee(e.currentTarget.value);
              }}
              required={isBois}
              iconHidden={false}
            />
          </div>
        </div>
        <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
          <label className="label" htmlFor="temperatureConfort">
            Température de confort (°C):{" "}
          </label>
          <Quantite
            value={
              boisTemperatureConfort ??
              studyInformation.bois_temperature_confort ??
              0
            }
            onPlusMinusClick={(e) => setBoisTemperatureConfort(e)}
          />
        </div>
        <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
          <label className="label" htmlFor="typeChauffage">
            Type de chauffage :{" "}
          </label>
          <ChoixMultiple
            options={mesConsommationsQuestionsData.bois.typeDeChauffage.options}
            value={boisTypeChauffage}
            onClick={(e) => {
              e &&
                setBoisTypeChauffage((state) => {
                  const newState = [...state];
                  const foundIndex = newState.findIndex(
                    (option) => option.label === e.label
                  );
                  if (foundIndex !== -1) {
                    newState.splice(foundIndex, 1);
                  } else {
                    return [e];
                  }
                  return newState;
                });

              //handle deselect option equipment
              setBoisNombreEmetteur(1);
              setBoisTypeEmetteur([]);
            }}
            isRequired={isBois}
          />
        </div>
        {boisTypeChauffage.find((elem) => elem.value === "chaudiereABois") ? (
          <div className="MesConsommationsAndEquipementsRG__MultiChoiceContainer">
            <label className="label" htmlFor="typeEmetteur">
              Type d’émetteur :{" "}
            </label>
            <ChoixMultiple
              options={mesConsommationsQuestionsData.bois.typeDEmetteur.options}
              value={boisTypeEmetteur}
              onClick={(e) => {
                if (!e) return;

                setBoisTypeEmetteur((state) => {
                  const newState = [...state];
                  const foundIndex = newState.findIndex(
                    (option) => option.label === e.label
                  );
                  if (foundIndex !== -1) {
                    newState.splice(foundIndex, 1);
                  } else {
                    return [e];
                  }
                  return newState;
                });

                if (e.value === "plancherChauffant") {
                  setBoisNombreEmetteur(1);
                }
              }}
              isRequired={
                isBois &&
                !!boisTypeChauffage.find(
                  (elem) => elem.value === "chaudiereABois"
                )
              }
            />
          </div>
        ) : null}
        {boisTypeEmetteur.length &&
        boisTypeEmetteur[0].value !== "plancherChauffant" ? (
          <div className="MesConsommationsAndEquipementsRG__QuestionContainer">
            <label className="label" htmlFor="nombreEmetteurs">
              Nombre d’émetteur(s) :{" "}
            </label>
            <Quantite
              value={boisNombreEmetteur ?? 0}
              onPlusMinusClick={(e) => setBoisNombreEmetteur(e)}
            />
          </div>
        ) : null}
      </section>
    </section>
  );
};
