import "./TotalGainContainer.scss";
// Libraries import
import { useState } from "react";

// Components import
import { TotalGainSummary } from "../TotalGainSummary/TotalGainSummary";
import { TotalGainGraphs } from "../TotalGainGraphs/TotalGainGraphs";
import { SelectDropdown } from "@web/shared/dist/components/Old/FormComponents/SelectDropdown/SelectDropdown";

// Interfaces import
import { IProfitability } from "../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface IProps {
  profitability: IProfitability;
  setFilterYearsFilter: React.Dispatch<
    React.SetStateAction<{
      label: string | null;
      value: string | number | null;
    }>
  >;
  filterYearsFilter: {
    label: string | null;
    value: string | number | null;
  };
}

export const TotalGainContainer = ({
  profitability,
  setFilterYearsFilter,
  filterYearsFilter,
}: IProps) => {
  const [areTabsOpen, setAreTabsOpen] = useState<boolean[]>([false, false]);

  const onTabClick = (index: number) => {
    setAreTabsOpen((prevState) => {
      const newState = prevState.map(() => false);
      newState[index] = !prevState[index];
      return newState;
    });
  };

  const formattedSavingsGraphData = () => {
    let newArray = [];
    for (var key in profitability?.economies) {
      newArray.push({
        annee: key,
        economies: profitability?.economies[key].economy,
        prix_kwh_reseau:
          profitability?.economies[key].electricity_network_price,
        prix_kwh_solaire:
          Math.round(
            ((Object.values(profitability?.economies || {})?.reduce(
              (totalPrice, yearPrice) =>
                totalPrice + yearPrice.electricity_solar_price,
              0
            ) || 0) /
              (Number(filterYearsFilter.value) || 0)) *
              100
          ) / 100,
      });
    }
    const filteredArray = newArray.slice(0, Number(filterYearsFilter.value));

    return filteredArray;
  };

  const formattedExtraElectricityGraphData = () => {
    let newArray = [];
    for (var key in profitability?.extra_electricity_sales.yearly_sales) {
      newArray.push({
        annee: key,
        reventeSurplus:
          profitability?.extra_electricity_sales.yearly_sales[key].euros,
        reventeEnergy:
          profitability?.extra_electricity_sales.yearly_sales[key].energy,
        prixReventeEDF:
          profitability?.extra_electricity_sales.yearly_sales[key]
            .kWh_resale_price,
      });
    }
    const filteredArray = newArray.slice(0, Number(filterYearsFilter.value));
    return filteredArray;
  };

  const onFilterYearsClick = (event: {
    label: string | null;
    value: string | number | null;
  }) => {
    event.label && event.value && setFilterYearsFilter(event);
  };

  return (
    <div className="TotalGainContainer">
      <div className="TotalGainContainer__FilterButton">
        <p>Sur&nbsp;: </p>
        <SelectDropdown
          options={[
            { label: "10 ans", value: "10" },
            { label: "15 ans", value: "15" },
            { label: "20 ans", value: "20" },
            { label: "25 ans", value: "25" },
          ]}
          onSelect={(event) => onFilterYearsClick(event)}
          value={filterYearsFilter.label || ""}
        />
      </div>
      <section className="TotalGainContainer__Summary">
        <TotalGainSummary
          economies_on_bills={Object.values(formattedSavingsGraphData()).reduce(
            (t, { economies }) => t + economies,
            0
          )}
          total_sale_euros={Object.values(
            formattedExtraElectricityGraphData()
          ).reduce((t, { reventeSurplus }) => t + reventeSurplus, 0)}
          numberOfYears={filterYearsFilter.value}
        />
      </section>
      <section className="TotalGainContainer__TotalGainGraphs">
        {formattedSavingsGraphData && (
          <TotalGainGraphs
            areTabsOpen={areTabsOpen}
            onTabClick={onTabClick}
            savingsGraphData={formattedSavingsGraphData()}
            extraElectricityGraphData={formattedExtraElectricityGraphData()}
          />
        )}
      </section>
    </div>
  );
};
