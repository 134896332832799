import { IGetStudiesResponse } from "../../interfaces/generalInterfaces";
import { SingleEtudeLine } from "../../containers/MesEtudesArray/SingleEtudeLine/SingleEtudeLine";

export const mesEtudesSortAndFilter = ({
  mesEtudesArray,
  sortedColumn = "modified_at",
  sortType = "ascendant",
  searchTextInput,
}: {
  mesEtudesArray: IGetStudiesResponse[];
  sortedColumn: "lastname" | "created_at" | "modified_at";
  sortType: "ascendant" | "descendant" | "default";
  searchTextInput: string;
}) => {
  const filteredArray = mesEtudesArray
    .sort(
      // we sort by clicked column (date is default value)
      (singleVisitA, singleVisitB) => {
        const aLabel = singleVisitA[sortedColumn];
        const bLabel = singleVisitB[sortedColumn];

        if (aLabel == null || bLabel == null) {
          return 0;
        }
        if (sortType === "descendant") {
          return aLabel > bLabel ? 1 : -1;
        }
        if (sortType === "ascendant") {
          return aLabel > bLabel ? -1 : 1;
        }
        return 0;
      }
    )
    .filter((singleEtude) => {
      if (
        singleEtude.firstname
          ?.toLowerCase()
          .includes(searchTextInput.toLowerCase()) ||
        singleEtude.lastname
          ?.toLowerCase()
          .includes(searchTextInput.toLowerCase()) ||
        singleEtude.address
          ?.toLowerCase()
          .includes(searchTextInput.toLowerCase()) ||
        singleEtude.zipcode
          ?.toLowerCase()
          .includes(searchTextInput.toLowerCase())
      ) {
        return singleEtude;
      }
      return null;
    })
    .map((singleEtude, index) => {
      return (
        <div className="mesVisites__SingleVisitContainer" key={index}>
          <SingleEtudeLine singleEtudeData={singleEtude} />
        </div>
      );
    });

  return filteredArray;
};
