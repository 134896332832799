import "./ExtraChargesCarrousel.scss";
import "swiper/css";

// Libraries import

import { SwiperSlide } from "swiper/react";

// Components import
import { CarrouselWrapper } from "@web/shared/dist/containers/CarrouselWrapper/CarrouselWrapper";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

// Images import
import Plus from "@web/shared/dist/assets/images/DesignSystemIcons/plus-circle.svg";
import ParametreMaison from "@web/shared/dist/assets/images/DesignSystemIllustrations/parametres-maison.svg";

// Interfaces import
import { TFraisSupp } from "../../../interfaces/renoglobaleInterface";

// Local interfaces declaration
interface IProps {
  filteredListFraisSupp: TFraisSupp[];
  setFraisSupp: React.Dispatch<React.SetStateAction<TFraisSupp[]>>;
}

export const ExtraChargesCarrousel = ({
  filteredListFraisSupp,
  setFraisSupp,
}: IProps) => {
  return (
    <section className="ExtraChargesCarrousel">
      <div className="ExtraChargesCarrousel__FraisSuppTitle">
        <h3>Spécificité du chantier : </h3>
      </div>
      {filteredListFraisSupp.length > 0 ? (
        <div className="ExtraChargesCarrousel__Carrousel">
          {filteredListFraisSupp.length > 1 ? (
            <CarrouselWrapper
              numberOfElements={filteredListFraisSupp.length}
              isMobile={false}
              slidePerViewDesktop={2}
              isDisplayChevrons={true}
              swiperStyle={{ width: "100%" }}
            >
              {filteredListFraisSupp.map((frais, index) => {
                return (
                  <SwiperSlide
                    data-hash={`slide${index}`}
                    key={index}
                    className={"ExtraChargesCarrousel__SwiperSlide"}
                  >
                    <div className="ExtraChargesCarrousel__SwiperSlideContent">
                      <div className="ExtraChargesCarrousel__SwiperContentTop">
                        <div className="ExtraChargesCarrousel__SwiperImageContainer">
                          <img
                            src={ParametreMaison}
                            alt="frais supplémentaire"
                          />
                        </div>
                        <article className="ExtraChargesCarrousel__SwiperTextContent">
                          <h3>{frais.product_name}</h3>
                          {frais.description ? (
                            <p>{frais.description}</p>
                          ) : (
                            <p>Pas de description disponible</p>
                          )}
                        </article>
                      </div>

                      <div className="ExtraChargesCarrousel__SwiperPriceRecap">
                        <p>{frais.price_with_tax}&nbsp;€</p>
                        <CTAButton
                          name="Ajouter"
                          category="secondary"
                          size="M"
                          iconSrc={Plus}
                          onClick={() => {
                            setFraisSupp((prevState) => {
                              const newState = [...prevState];
                              const indexFoundObject = newState.findIndex(
                                (obj) => obj.product_name === frais.product_name
                              );

                              if (indexFoundObject === -1) {
                                newState.push({
                                  price_with_tax: frais.price_with_tax,
                                  product_name: frais.product_name,
                                  sage_reference: frais.sage_reference,
                                  price_without_tax: frais.price_without_tax,
                                  TVA_percentage: frais.TVA_percentage,
                                  description: frais.description,
                                });
                              }
                              return newState;
                            });
                          }}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </CarrouselWrapper>
          ) : (
            <div className="ExtraChargesCarrousel__SwiperSlideContent">
              <div className="ExtraChargesCarrousel__SwiperContentTop">
                <div className="ExtraChargesCarrousel__SwiperImageContainer">
                  <img src={ParametreMaison} alt="frais supplémentaire" />
                </div>
                <article className="ExtraChargesCarrousel__SwiperTextContent">
                  <h3>{filteredListFraisSupp?.[0]?.product_name}</h3>
                  {filteredListFraisSupp?.[0]?.description ? (
                    <p>{filteredListFraisSupp?.[0]?.description}</p>
                  ) : (
                    <p>Pas de description disponible</p>
                  )}
                </article>
              </div>

              <div className="ExtraChargesCarrousel__SwiperPriceRecap">
                <p>{filteredListFraisSupp?.[0]?.price_with_tax}&nbsp;€</p>
                <CTAButton
                  name="Ajouter"
                  category="secondary"
                  size="M"
                  iconSrc={Plus}
                  onClick={() => {
                    setFraisSupp((prevState) => {
                      const newState = [...prevState];
                      const indexFoundObject = newState.findIndex(
                        (obj) =>
                          obj.product_name ===
                          filteredListFraisSupp?.[0]?.product_name
                      );

                      if (indexFoundObject === -1) {
                        newState.push({
                          price_with_tax:
                            filteredListFraisSupp?.[0]?.price_with_tax,
                          price_without_tax:
                            filteredListFraisSupp?.[0]?.price_without_tax,
                          TVA_percentage:
                            filteredListFraisSupp?.[0]?.TVA_percentage,
                          product_name:
                            filteredListFraisSupp?.[0]?.product_name,
                          sage_reference:
                            filteredListFraisSupp?.[0]?.sage_reference,
                        });
                      }
                      return newState;
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ) : null}
    </section>
  );
};
