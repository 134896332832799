import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DSColors } from "@web/shared/src/styles/variables";
import moment from "moment";

// Components
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";
import { PdfEtudeHeaderRG } from "./PdfEtudeHeaderRG";

// Services import
import { productsImagesDictionnary } from "../../services/RenovationGlobale/productsImagesDictionnary";

// Interfaces import
import { ISageProduct } from "../../interfaces/generalInterfaces";
import {
  IDataProduct,
  IProjectKPI,
} from "../../interfaces/renoglobaleInterface";

// Images import
import vousEtesIci from "../../assets/pdf/images/fleche.png";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  documentId: string;
  RGProjectKPI: IProjectKPI | null;
  cartProducts: IDataProduct[];
  sageProduct: ISageProduct[];
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  containerRecapitulatif: {
    height: "100%",
  },
  pageTitleContainer: {
    textAlign: "left",
    height: 30,
  },
  pageTitleContent: {
    position: "relative",
  },
  pageTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },

  pageSubTitleContent: {
    display: "flex",
    flexDirection: "row",
    objectFit: "contain",
    alignItems: "center",
  },
  pageSubTitle: {
    fontSize: 12,
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },
  pageSubTitleImage: {
    width: 25,
    marginRight: 5,
  },
  pageTitleUnderline: {
    position: "absolute",
    width: 190,
    height: "45%",
    bottom: 0,
    backgroundColor: DSColors.ZOLight02,
  },
  containerKPIs: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    marginTop: 10,
    marginBottom: 20,
  },
  containerKPI: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #D3D4D7",
    padding: 15,
    borderRadius: 5,
    gap: 10,
  },
  KPITitle: {
    fontFamily: "DMSansBold",
    fontSize: 10,
  },
  containerKPIValue: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: 10,
  },
  KPILabel: {
    fontSize: 9,
  },
  KPIValue: {
    fontSize: 12,
    fontFamily: "DMSansBold",
  },
  yourProductsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
    marginTop: 10,
  },
  productContainer: {
    border: "1px solid #D3D4D7",
    borderRadius: 5,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    width: 152,
  },
  productName: {
    fontSize: 9,
    fontFamily: "DMSansBold",
    marginBottom: 10,
  },
  productKPI: {
    fontSize: 8,
    marginBottom: 5,
  },
  produtImageContainer: {
    marginBottom: 10,
  },
  produtImage: {},
});

export const PdfEtudeRGPageRecapitulatif = ({
  firstNameInputValue,
  lastNameInputValue,
  documentId,
  RGProjectKPI,
  cartProducts,
  sageProduct,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Page size="A4" style={styles.page}>
      <PdfEtudeHeaderRG />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.containerRecapitulatif}>
          <View style={localStyle.pageTitleContainer}>
            <View style={localStyle.pageTitleContent}>
              <View style={localStyle.pageTitleUnderline} />
              <Text style={localStyle.pageTitle}>
                Récapitulatif de votre projet
              </Text>
            </View>
          </View>
          <View style={localStyle.containerKPIs}>
            <View style={localStyle.containerKPI}>
              <Text style={localStyle.KPITitle}>Économies sur 25 ans</Text>
              <View style={localStyle.containerKPIValue}>
                <Text style={localStyle.KPIValue}>
                  {RGProjectKPI?.savings_on_bill + " €" || "NA"}
                </Text>
              </View>
            </View>
            <View style={localStyle.containerKPI}>
              <Text style={localStyle.KPITitle}>Efficacité énergétique</Text>
              <View style={localStyle.containerKPIValue}>
                <Text style={localStyle.KPIValue}>
                  {RGProjectKPI?.energy_gain + " %" || "NA"}
                </Text>
              </View>
            </View>
          </View>
          <View style={localStyle.pageTitleContainer}>
            <View style={localStyle.pageSubTitleContent}>
              <Image
                style={localStyle.pageSubTitleImage}
                source={vousEtesIci}
              />
              <Text style={localStyle.pageSubTitle}>Vos produits</Text>
            </View>
          </View>
          <View style={localStyle.yourProductsContainer}>
            {cartProducts.map((product, index) => {
              // Product characteristics
              const surfaceAIsoler =
                product.selected_characteristics.surface_to_isolate;
              const capacite = sageProduct.find(
                (sageProduct) => sageProduct.AR_Ref === product.sage_reference
              )?.["Capacité (l)"];
              const brand = sageProduct.find(
                (sageProduct) => sageProduct.AR_Ref === product.sage_reference
              )?.Marque;
              const dimensions = sageProduct.find(
                (sageProduct) => sageProduct.AR_Ref === product.sage_reference
              )?.Dimensions;
              const gamme = sageProduct.find(
                (sageProduct) => sageProduct.AR_Ref === product.sage_reference
              )?.Gamme;
              const garantieFabricant = sageProduct.find(
                (sageProduct) => sageProduct.AR_Ref === product.sage_reference
              )?.["Garantie Fabricant"];
              const garantieMainDoeuvre = sageProduct.find(
                (sageProduct) => sageProduct.AR_Ref === product.sage_reference
              )?.["Garantie main d'oeuvre"];
              const inverter = sageProduct.find(
                (sageProduct) => sageProduct.AR_Ref === product.sage_reference
              )?.inverter_technology;
              const poid = sageProduct.find(
                (sageProduct) => sageProduct.AR_Ref === product.sage_reference
              )?.Poid;
              const isBattery = !!product.selected_characteristics.n_batteries;
              const batteryInfo = sageProduct.find(
                (sageProduct) =>
                  sageProduct.AR_Ref ===
                  product.selected_characteristics.battery_sage_reference
              )?.AR_Design;
              const isKitHomeManagement =
                product.selected_characteristics.kit_home_management;
              const kitHomeManagementInfo =
                product.product_characteristics.kit_home_management?.alias ||
                sageProduct.find(
                  (sageProduct) =>
                    sageProduct.AR_Ref ===
                    product.product_characteristics.kit_home_management
                      ?.sage_reference
                )?.AR_Design;
              const freeProductDescription =
                product.selected_characteristics.free_product_description;
              const interiorUnits =
                product.selected_characteristics.rooms_information?.flatMap(
                  (room) => room.interior_units
                );

              // Product image
              const productImage =
                sageProduct.find(
                  (sageProduct) => sageProduct.AR_Ref === product.sage_reference
                )?.["Image produit"] ||
                productsImagesDictionnary({
                  product_type: product.product_type,
                  image_type: "png",
                });
              return (
                <View style={localStyle.productContainer} key={index}>
                  {productImage && (
                    <View style={localStyle.produtImageContainer}>
                      <Image
                        style={localStyle.produtImage}
                        src={productImage}
                      />
                    </View>
                  )}
                  <Text style={localStyle.productName}>
                    {product.product_alias || product.product_name}
                  </Text>

                  {capacite && (
                    <Text style={localStyle.productKPI}>
                      {"Capacité : " + capacite}
                    </Text>
                  )}
                  {surfaceAIsoler && (
                    <Text style={localStyle.productKPI}>
                      {"Surface à isoler : " + surfaceAIsoler + " m²"}
                    </Text>
                  )}
                  {brand && (
                    <Text style={localStyle.productKPI}>
                      {"Marque : " + brand}
                    </Text>
                  )}
                  {gamme && (
                    <Text style={localStyle.productKPI}>
                      {"Gamme : " + gamme}
                    </Text>
                  )}
                  {dimensions && (
                    <Text style={localStyle.productKPI}>
                      {"Dimensions : " + dimensions}
                    </Text>
                  )}
                  {poid && (
                    <Text style={localStyle.productKPI}>
                      {"Poids : " + poid}
                    </Text>
                  )}
                  {garantieFabricant && (
                    <Text style={localStyle.productKPI}>
                      {"Garantie fabricant : " + garantieFabricant}
                    </Text>
                  )}
                  {garantieMainDoeuvre && (
                    <Text style={localStyle.productKPI}>
                      {"Garantie main d'oeuvre : " + garantieMainDoeuvre}
                    </Text>
                  )}
                  {inverter && (
                    <Text style={localStyle.productKPI}>
                      {"Onduleur : " + inverter}
                    </Text>
                  )}

                  {isBattery && (
                    <Text style={localStyle.productKPI}>
                      {product.selected_characteristics.n_batteries +
                        " x " +
                        batteryInfo}
                    </Text>
                  )}
                  {isKitHomeManagement && (
                    <Text style={localStyle.productKPI}>
                      {kitHomeManagementInfo}
                    </Text>
                  )}
                  {(interiorUnits?.length || 0) > 0 &&
                    interiorUnits?.map((interiorUnit, index) => (
                      <Text style={localStyle.productKPI} key={index}>
                        {"+ " + (interiorUnit.alias || interiorUnit.name)}
                      </Text>
                    ))}
                  {freeProductDescription && (
                    <Text style={localStyle.productKPI}>
                      {"Description : " + freeProductDescription}
                    </Text>
                  )}
                </View>
              );
            })}
          </View>
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType="rénovation globale"
      />
    </Page>
  );
};
