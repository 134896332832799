import { useEffect, useState } from "react";
import "./MesEtudes.scss";

// component imports
import { useAppDispatch } from "../../redux/store/hook";
import { ChampDeRecherche } from "@web/shared/dist/components/DesignSystem/Inputs/ChampDeRecherche/ChampDeRecherche";
import { ArrayHeader } from "../../containers/MesEtudesArray/ArrayHeader/ArrayHeader";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { NavigationBarOutsideStudy } from "../../components/NavigationBarOutsideStudy/NavigationBarOutsideStudy";

// services import
import { mesEtudesSortAndFilter } from "../../services/Studies/mesEtudesSortAndFilter";
import { resetAllStudyInformationAction } from "../../redux/appActions";
import { useGetStudiesListRq } from "../../api/hooks/useGetStudiesListRq";

export const MesEtudes = () => {
  const dispatch = useAppDispatch();

  const {
    isLoading,
    isSuccess,
    data: studiesList,
    isError,
    errorMessage,
  } = useGetStudiesListRq();

  useEffect(() => {
    // Reset all customer study information
    dispatch(resetAllStudyInformationAction(null));
  }, [dispatch]);

  const [searchTextInput, setSearchTextInput] = useState<string>("");

  const [sortedColumn, setSortedColumn] = useState<
    "lastname" | "created_at" | "modified_at"
  >("modified_at");
  const [sortType, setSortType] = useState<"ascendant" | "descendant">(
    "ascendant"
  );

  return (
    <div className="MesEtudes">
      <NavigationBarOutsideStudy title={"Mes études"} />
      <div className="MesEtudes__Layout">
        <section className="MesEtudes__SearchContainer">
          <ChampDeRecherche
            value={searchTextInput}
            onChange={(e) => setSearchTextInput(e.currentTarget.value)}
          />
        </section>
        <ArrayHeader
          setSortedColumn={setSortedColumn}
          setSortType={setSortType}
          sortType={sortType}
        />
        <section className="MesEtudes__Container">
          {isLoading ? (
            <div className="loadingContainer">
              <LoadingComponent diameter={140} />
            </div>
          ) : isSuccess && studiesList.length > 0 ? (
            mesEtudesSortAndFilter({
              mesEtudesArray: studiesList,
              sortedColumn,
              sortType,
              searchTextInput,
            })
          ) : isError ? (
            <ErrorMessage errorMessage={errorMessage} />
          ) : (
            <p className="MesEtudes__NoStudies">
              Aucune étude n'a été trouvée.
            </p>
          )}
        </section>
      </div>
    </div>
  );
};
