import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  ICommercialUserInformation,
  ISageProduct,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  PVProduct: IDataProduct | undefined;
  commercialUserInformation: ICommercialUserInformation;
  batterySageProduct: ISageProduct | undefined;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  isDemoVersion: boolean;
}

export const PdfBatteryPage = ({
  documentId,
  PVProduct,
  batterySageProduct,
  commercialUserInformation,
  isDemoVersion,
}: IProps) => {
  const battery = PVProduct?.product_characteristics.available_batteries?.find(
    (battery) =>
      battery.sage_reference ===
      PVProduct?.selected_characteristics.battery_sage_reference
  );

  const totalBatteryWithoutTax =
    (battery?.price_without_tax || 0) +
    (battery?.installation_price_without_tax || 0) *
      (PVProduct?.selected_characteristics.n_batteries || 0);
  const totalBatteryWithTaxes =
    (battery?.price_with_tax || 0) +
    (battery?.installation_price_with_tax || 0) *
      (PVProduct?.selected_characteristics.n_batteries || 0);
  const totalBatteryTVA = totalBatteryWithTaxes - totalBatteryWithoutTax;

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              battery?.alias || batterySageProduct?.AR_Design || "Batterie"
            }
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={PVProduct?.selected_characteristics?.n_batteries}
              prixUnitaire={battery?.price_without_tax || 0}
              TVA={battery?.tva_percentage || 0}
              montantTTC={
                (battery?.price_with_tax || 0) *
                (PVProduct?.selected_characteristics?.n_batteries || 0)
              }
            />

            <View key={batterySageProduct?.AR_Ref}>
              {batterySageProduct?.AR_Design && (
                <PdfRowProduit
                  isMargeBottom={false}
                  name={batterySageProduct?.AR_Design}
                />
              )}
              {batterySageProduct?.Puissance && (
                <PdfRowProduit
                  isMargeBottom={false}
                  name={"Énergie totale  : " + batterySageProduct?.Puissance}
                />
              )}
              {batterySageProduct?.Dimensions && (
                <PdfRowProduit
                  isMargeBottom={false}
                  name={"Taille : " + batterySageProduct?.Dimensions}
                />
              )}

              {batterySageProduct?.["Garantie Fabricant"] && (
                <PdfRowProduit
                  isMargeBottom={false}
                  name={
                    "Garantie fabriquant : " +
                    batterySageProduct?.["Garantie Fabricant"]
                  }
                />
              )}
              {batterySageProduct?.["Norme et Certification"] && (
                <PdfRowProduit
                  isMargeBottom={false}
                  name={
                    "Normes et certifications : " +
                    batterySageProduct?.["Norme et Certification"]
                  }
                />
              )}

              <PdfRowProduit name={"Montage : sur socle ou support mural"} />
            </View>

            <PdfRowBackgroundProduit
              name="Forfait installation"
              quantity={PVProduct?.selected_characteristics?.n_batteries}
              prixUnitaire={battery?.installation_price_without_tax || 0}
              TVA={battery?.installation_tva_percentage || 0}
              montantTTC={
                (battery?.price_with_tax || 0) *
                (PVProduct?.selected_characteristics?.n_batteries || 0)
              }
            />
            <PdfTotalContainer
              totalHT={totalBatteryWithoutTax}
              totalTVA={totalBatteryTVA}
              totalTTC={totalBatteryWithTaxes}
              resteACharge={totalBatteryWithTaxes}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
