// Libraries import
import { useEffect, useState } from "react";

// Components import
import { MonProjetRG } from "../../../pages/MonProjetRG/MonProjetRG";
import { ProductsCatalogue } from "../../../pages/ProductsCatalogue/ProductsCatalogue";
import { ProductPagesWrapper } from "../../ProductPageRG/ProductPagesWrapper/ProductPagesWrapper";

//Services import
import {
  setCartProductsAction,
  setRGProjectKPIAction,
} from "../../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { useGetMesScenarios } from "../../../services/MonInstallation/useGetMesScenarios";
import { useGetProductsRecommendations } from "../../../services/RenovationGlobale/MonProjet/useGetProductsRecommendations";
import { useGetProductsSavedInCart } from "../../../services/RenovationGlobale/MonProjet/useGetProductsSavedInCart";
import { useGetProjectKPI } from "../../../services/RenovationGlobale/MonProjet/useGetProjectKPI";
import { INavigateData } from "../../../services/useNavigation/useNavigation";

// Interfaces import
import {
  IDataProduct,
  TProjectPages,
} from "../../../interfaces/renoglobaleInterface";

//Local interface
interface Props {
  goNext: (data?: INavigateData<any>) => void;
  navigationData?: INavigateData<boolean> | null;
}

export const MonProjetRGPagesWrapper = ({ goNext, navigationData }: Props) => {
  //Redux
  const { studyInformation, userAuthInfo, RGProjectKPI, cartProducts } =
    useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  //Local state
  const [screenSelected, setScreenSelected] =
    useState<TProjectPages>("project");

  const [productCategorySelected, setProductCategorySelected] =
    useState<string>("");

  const [selectedProductIndex, setSelectedProductIndex] = useState<
    number | null
  >(null);

  const [isModalRGEligibleOpen, setIsModalRGEligibleOpen] = useState(false);

  // We open the income modale only if we don't yet have the user's income information
  const isModaleOpen = studyInformation.income_color
    ? false
    : navigationData?.isIncomeModaleOpen ?? true;

  const [isIncomeModaleOpen, setIsIncomeModaleOpen] =
    useState<boolean>(isModaleOpen);

  //Custom hooks
  const {
    getProductsRecommendations,
    isLoading: isLoadingGetProductsRecommendations,
    errorMessage: errorMessageGetProductsRecommendations,
  } = useGetProductsRecommendations();

  const { getProjectKPI, isLoading: isLoadingGetProjectKPI } =
    useGetProjectKPI();

  const { isLoading: isLoadingGetMesScenarios, getMesScenarios } =
    useGetMesScenarios();

  const {
    getProductsSavedInCart,
    isLoading: isLoadingGetProductsSavedInCart,
    errorMessage: errorMessageGetProductSavedInCart,
  } = useGetProductsSavedInCart();

  const fetchProjectKPI = async ({
    cartProductsToDisplay,
  }: {
    cartProductsToDisplay?: IDataProduct[];
  }) => {
    // Get KPIs related to the cart
    const projectKPIResponse = await getProjectKPI({
      cartProducts: (cartProductsToDisplay || [])
        .filter((product) => product.isSelected === true)
        .map((product) => {
          const productCopy = { ...product };
          delete productCopy.isSelected;
          delete productCopy.isProductRecommended;
          return productCopy;
        }),
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id,
      building_id: studyInformation.building_id || null,
    });

    // Update the cart's products with the help information from the project KPI response
    // As sometimes the help of one product depends on the other products in the cart
    const productToDisplayUpdated =
      projectKPIResponse &&
      cartProductsToDisplay &&
      cartProductsToDisplay.map((cartProduct) => {
        return {
          ...cartProduct,
          help:
            projectKPIResponse.updated_products?.find(
              (updatedProduct) =>
                updatedProduct.sage_reference === cartProduct.sage_reference
            )?.help || cartProduct.help,
        };
      });
    productToDisplayUpdated &&
      dispatch(
        setCartProductsAction({
          cartProducts: productToDisplayUpdated,
        })
      );

    if (
      RGProjectKPI &&
      RGProjectKPI.is_eligible !== projectKPIResponse?.is_eligible
    ) {
      // We open the modal :
      // Only if we already have project KPI to not open it at the first load
      // Only if the reno_global eligibility has changed
      setIsModalRGEligibleOpen(true);
    }

    projectKPIResponse &&
      dispatch(
        setRGProjectKPIAction({
          projectKPI: projectKPIResponse || null,
        })
      );

    // projectKPIResponse && dispatch();
  };

  const fetchGetProductsSavedInCart = async () => {
    // Note : products are updated at data side before beeing returned
    const productsSavedInCart = await getProductsSavedInCart({
      userToken: userAuthInfo.userToken,
      study_id: studyInformation?.study_id || null,
    });

    if (!productsSavedInCart || productsSavedInCart.length === 0) {
      return null;
    }

    dispatch(
      setCartProductsAction({
        cartProducts: productsSavedInCart,
      })
    );

    return productsSavedInCart;
  };

  const fetchRecommendedProducts = async () => {
    const productsRecommendation = await getProductsRecommendations({
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id,
    });

    if (!productsRecommendation || productsRecommendation.length === 0) {
      dispatch(
        setCartProductsAction({
          cartProducts: [],
        })
      );
      return [];
    }

    dispatch(
      setCartProductsAction({
        cartProducts: productsRecommendation,
      })
    );

    return productsRecommendation;
  };
  const fetchScenariosAvailability = async () => {
    await getMesScenarios({
      building_id: studyInformation.building_id || "",
      household_id: studyInformation.household_id || "",
      userToken: userAuthInfo.userToken || "",
      study_id: studyInformation.study_id || "",
    });
  };

  // We try to get the cart's products saved in Eero. If there is no product we get them from the recommended products at data backend side.
  const fetchProducts = async () => {
    //Before fetching the products we need to check if data have finished calculating
    await fetchScenariosAvailability();

    const productsInCart = await fetchGetProductsSavedInCart();

    if (!productsInCart) {
      const productsRecommendation = await fetchRecommendedProducts();

      productsRecommendation &&
        productsRecommendation.length > 0 &&
        (await fetchProjectKPI({
          cartProductsToDisplay: productsRecommendation,
        }));
      return;
    }

    productsInCart.length > 0 &&
      (await fetchProjectKPI({
        cartProductsToDisplay: productsInCart,
      }));
  };

  useEffect(() => {
    // If the help modale is closed and we don't have yet cart's products we can fetch them directly when landing on the page
    !isModaleOpen && (cartProducts || []).length === 0 && fetchProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectScreenToDisplay = ({
    screenSelected,
    productCategorySelected,
  }: {
    screenSelected: string;
    productCategorySelected: string;
  }) => {
    switch (screenSelected) {
      case "product_page_from_catalogue":
        return (
          <ProductPagesWrapper
            setScreenSelected={setScreenSelected}
            productCategorySelected={productCategorySelected}
            setProductCategorySelected={setProductCategorySelected}
            previousScreen="product_catalogue"
            fetchProjectKPI={fetchProjectKPI}
            isEligibleRenoGlobal={RGProjectKPI?.is_eligible ?? true}
          />
        );
      case "product_page_from_project_page":
        return (
          <ProductPagesWrapper
            setScreenSelected={setScreenSelected}
            productCategorySelected={productCategorySelected}
            setProductCategorySelected={setProductCategorySelected}
            selectedProductIndex={selectedProductIndex}
            fetchProjectKPI={fetchProjectKPI}
            isEligibleRenoGlobal={RGProjectKPI?.is_eligible ?? true}
          />
        );
      case "product_catalogue":
        return (
          <ProductsCatalogue
            setScreenSelected={setScreenSelected}
            setProductCategorySelected={setProductCategorySelected}
          />
        );
      case "project":
        return (
          <MonProjetRG
            setScreenSelected={setScreenSelected}
            setProductCategorySelected={setProductCategorySelected}
            setSelectedProductIndex={setSelectedProductIndex}
            isLoadingGetProductsSavedInCart={isLoadingGetProductsSavedInCart}
            isLoadingGetProductsRecommendations={
              isLoadingGetProductsRecommendations
            }
            errorMessageFetchProducts={
              errorMessageGetProductSavedInCart ||
              errorMessageGetProductsRecommendations
            }
            fetchProducts={fetchProducts}
            fetchProjectKPI={fetchProjectKPI}
            isLoadingGetProjectKPI={isLoadingGetProjectKPI}
            projectKPI={RGProjectKPI}
            setIsModalRGEligibleOpen={setIsModalRGEligibleOpen}
            isModalRGEligibleOpen={isModalRGEligibleOpen}
            goNext={goNext}
            isIncomeModaleOpen={isIncomeModaleOpen}
            setIsIncomeModaleOpen={setIsIncomeModaleOpen}
            isLoadingGetMesScenarios={isLoadingGetMesScenarios}
          />
        );
      default:
        return (
          <MonProjetRG
            setScreenSelected={setScreenSelected}
            setProductCategorySelected={setProductCategorySelected}
            setSelectedProductIndex={setSelectedProductIndex}
            isLoadingGetProductsSavedInCart={isLoadingGetProductsSavedInCart}
            isLoadingGetProductsRecommendations={
              isLoadingGetProductsRecommendations
            }
            errorMessageFetchProducts={
              errorMessageGetProductSavedInCart ||
              errorMessageGetProductsRecommendations
            }
            fetchProducts={fetchProducts}
            fetchProjectKPI={fetchProjectKPI}
            isLoadingGetProjectKPI={isLoadingGetProjectKPI}
            projectKPI={RGProjectKPI}
            setIsModalRGEligibleOpen={setIsModalRGEligibleOpen}
            isModalRGEligibleOpen={isModalRGEligibleOpen}
            goNext={goNext}
            isIncomeModaleOpen={isIncomeModaleOpen}
            setIsIncomeModaleOpen={setIsIncomeModaleOpen}
            isLoadingGetMesScenarios={isLoadingGetMesScenarios}
          />
        );
    }
  };

  return selectScreenToDisplay({ screenSelected, productCategorySelected });
};
