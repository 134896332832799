//Libraries import
import { AxiosResponse } from "axios";
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";

//Local interface
interface IProps {
  userToken: string | null;
  study_id: string | null;
  fileToUploadB64: string | null;
  fileName: string | null;
  fileType: string;
  document_id: string;
  project_id: string;
}

export const useSaveFileInABS = () => {
  const {
    axiosPostRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const saveFileInABS = async ({
    userToken,
    study_id,
    fileToUploadB64,
    fileName,
    fileType,
    document_id,
    project_id,
  }: IProps): Promise<string | AxiosResponse | void> => {
    setErrorMessage("");

    const formData = new FormData();
    fileToUploadB64 && formData.append("fileBase64", fileToUploadB64);
    fileName && formData.append("fileName", fileName);
    study_id && formData.append("study_id", study_id);
    fileType && formData.append("fileType", fileType);
    document_id && formData.append("document_id", document_id);
    project_id && formData.append("project_id", project_id);

    const response = await axiosPostRequest(
      `${process.env.REACT_APP_EERO_URL}/oree2/azureBlobStorage/postFileFromAzure`,
      formData,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
          "content-type": "multipart/form-data",
        },
      }
    );

    if (!response) {
      setErrorMessage("Impossible d'envoyer les données du pdf.");
      return;
    }

    return response;
  };

  return {
    saveFileInABS,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
