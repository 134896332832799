import "./KWhPriceComponent.scss";

// Libraries import
import SVG from "react-inlinesvg";

// Components import
import { Tooltip } from "@web/shared/dist/components/DesignSystem/Tooltip/Tooltip/Tooltip";

// Images import
import tirelire from "../../../assets/images/tirelire.svg";
import questionMark from "@web/shared/dist/assets/images/DesignSystemIcons/question-mark-circle.svg";
import pictoResteACharge from "../../../assets/images/picto-main-euros.svg";

// Interfaces import
import { IProfitability } from "../../../interfaces/generalInterfaces";
import { calculatePrixMoyenKwHReseau } from "../../../services/MonInstallation/calculatePrixMoyenKwHReseau";

// Local interface declaration
interface IProps {
  profitability: IProfitability | null;
  filterYearsFilter: {
    label: string | null;
    value: string | number | null;
  };
}

export const KWhPriceComponent = ({
  profitability,
  filterYearsFilter,
}: IProps) => {
  const solarKWhPrice =
    (Object.values(profitability?.economies || {})?.reduce(
      (totalPrice, yearPrice) => totalPrice + yearPrice.electricity_solar_price,
      0
    ) || 0) / (Number(filterYearsFilter.value) || 0);
  const networkKWhPrice = calculatePrixMoyenKwHReseau({
    economies: profitability?.economies,
    number_of_years: Number(filterYearsFilter.value) || 0,
  });
  return (
    <section className="KWhPriceComponent">
      <div className="KWhPriceComponent__DataContainer">
        <div className="KWhPriceComponent__Tooltip">
          <SVG src={questionMark} />
          <div className="icon">
            <Tooltip
              content={`Prix moyen du kWh solaire sur ${filterYearsFilter.value} ans`}
              backgroundColor="black"
              color="white"
              position="top"
              sizeOfParent={22}
            />
          </div>
        </div>

        <img src={tirelire} alt="" width={42} height={45} />

        <p className="KWhPriceComponent__NumbersValue KWhPriceComponent__NumbersValueSolar">
          {(Math.round(solarKWhPrice * 100) / 100).toFixed(2) + "€"}
        </p>
        <p className="KWhPriceComponent__NumbersTitle">
          PRIX MOYEN DU KWH SOLAIRE SUR {filterYearsFilter.value} ANS
        </p>
      </div>
      <div className="KWhPriceComponent__DataContainer">
        <div className="KWhPriceComponent__Tooltip">
          <SVG src={questionMark} />
          <div className="icon">
            <Tooltip
              content={`Prix moyen du kWh réseau sur ${filterYearsFilter.value} ans`}
              backgroundColor="black"
              color="white"
              position="top"
              sizeOfParent={22}
            />
          </div>
        </div>

        <img src={pictoResteACharge} alt="" width={42} height={45} />

        <p className="KWhPriceComponent__NumbersValue KWhPriceComponent__NumbersValueNetwork">
          {(Math.round(networkKWhPrice * 100) / 100).toFixed(2) + "€"}
        </p>
        <p className="KWhPriceComponent__NumbersTitle">
          PRIX MOYEN DU KWH RÉSEAU SUR {filterYearsFilter.value} ANS
        </p>
      </div>
    </section>
  );
};
