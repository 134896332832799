import "./PanelsDrawing.scss";

// Libraries import
import Konva from "konva";
import { useRef, useEffect, useState } from "react";
import { Stage, Layer, Image } from "react-konva";
import useImage from "use-image";

// Components import
import { PanelElement } from "../PanelElement/PanelElement";
import { CTACopyPaste } from "@web/shared/dist/components/DesignSystem/CTACopyPaste/CTACopyPaste";
import { Switch } from "@web/shared/dist/components/DesignSystem/Switch/Switch";

// Interface declaration
interface PanelsDrawingProps {
  imageHeight: number;
  imageWidth: number;
  panelsCoordinatesOrtho?: {
    [key: string]: number[][];
  } | null;
  panelsCoordinatesSolarPotential?: {
    [key: string]: number[][];
  } | null;
  imageOrthoBase64: string;
  imageSolarPotentialBase64?: string;
  calepinage?: number[];
  updatePanelsIndexes?: ({ panel_index }: { panel_index: number }) => void;
  resetForm?: () => void;
  scale?: number;
  isZoomable?: boolean;
  isSolarPotential?: boolean;
}

export const PanelsDrawing = ({
  imageHeight = 500,
  imageWidth = 500,
  panelsCoordinatesOrtho,
  panelsCoordinatesSolarPotential,
  imageOrthoBase64,
  imageSolarPotentialBase64,
  calepinage,
  updatePanelsIndexes,
  resetForm,
  scale = 1,
  isZoomable = true,
  isSolarPotential = false,
}: PanelsDrawingProps) => {
  const [scaleState, setScaleState] = useState(scale);
  const [isSolarPotentialImage, setIsSolarPotentialImage] = useState(false);
  const [loadedImage] = useImage(
    isSolarPotentialImage && imageSolarPotentialBase64
      ? imageSolarPotentialBase64
      : imageOrthoBase64
  );

  const isSolarPotentialImageButton =
    isSolarPotential && imageSolarPotentialBase64;

  const coordinates = isSolarPotentialImage
    ? panelsCoordinatesSolarPotential
    : panelsCoordinatesOrtho;

  const imageRef = useRef<Konva.Image>(null);
  const stageRef = useRef<Konva.Stage>(null);

  const onClickZoomIn = () => {
    setScaleState((state) => state + 0.1);
  };
  const onclickZoomOut = () => {
    setScaleState((state) => state - 0.1);
  };
  const reset = () => {
    setScaleState(scale);
    resetForm && resetForm();
  };

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.cache();
      imageRef.current.getLayer()?.batchDraw();
    }
  }, [loadedImage]);

  const panels_data = coordinates
    ? Object.values(coordinates).map((coordinates) => {
        return coordinates.map((elem) => {
          return [
            elem[0] * imageWidth * scaleState,
            (1 - elem[1]) * imageHeight * scaleState,
          ];
        });
      })
    : null;

  return (
    <div className="PanelsDrawing">
      <div className="PanelsDrawing__Container">
        {isZoomable ? (
          <div className="PanelsDrawing__ZoomButtons">
            <CTACopyPaste
              name="Zoom -"
              onClick={onclickZoomOut}
              disabled={scaleState <= 1}
            />
            <CTACopyPaste
              name="Zoom +"
              onClick={onClickZoomIn}
              disabled={scaleState >= 4}
            />
            {calepinage?.length || scaleState !== scale ? (
              <div className="last-item">
                <CTACopyPaste name="Réinitialiser" onClick={reset} />
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="PanelsDrawing__StageContainer">
          <Stage width={imageWidth} height={imageHeight} ref={stageRef}>
            <Layer
              x={
                ((stageRef.current?.width() || 0) - imageWidth * scaleState) / 2
              }
              y={
                ((stageRef.current?.height() || 0) - imageHeight * scaleState) /
                2
              }
            >
              <Image
                image={loadedImage}
                ref={imageRef}
                height={imageHeight}
                width={imageWidth}
                scaleX={scaleState}
                scaleY={scaleState}
              />
              {coordinates &&
                calepinage &&
                panels_data &&
                panels_data.map((coordinate, panel_index) => {
                  const index = Number(Object.keys(coordinates)[panel_index]); // Index are the keys of the coordinates object ("1", "2", etc.). It should be egal to the index of the array (panel_index).
                  return (
                    <PanelElement
                      key={coordinate.toString()}
                      coordinates={[
                        coordinate[0][0],
                        coordinate[0][1],
                        coordinate[1][0],
                        coordinate[1][1],
                        coordinate[2][0],
                        coordinate[2][1],
                        coordinate[3][0],
                        coordinate[3][1],
                      ]}
                      stageRef={stageRef}
                      isSelected={calepinage.includes(index)}
                      updatePanelsIndexes={updatePanelsIndexes}
                      index={index}
                      isSolarPotential={isSolarPotentialImage}
                    />
                  );
                })}
            </Layer>
          </Stage>
        </div>
      </div>
      {isSolarPotentialImageButton ? (
        <div className="PanelsDrawing__SwitchContainer">
          <Switch
            dimension="S"
            onClick={() => {
              setIsSolarPotentialImage((state) => !state);
            }}
            value={isSolarPotentialImage}
            name="solarPotentialView"
          />
          <p style={isSolarPotentialImage ? { color: "#08B77F" } : {}}>
            Potentiel Solaire
          </p>
        </div>
      ) : null}
    </div>
  );
};
