import { IYearlySavings } from "../../interfaces/generalInterfaces";

interface IProps {
  number_of_years: number;
  economies?: IYearlySavings;
}

export const calculatePrixMoyenKwHReseau = ({
  economies,
  number_of_years,
}: IProps) => {
  const result =
    (Object.values(economies || {})
      ?.filter((elem, index) => index < (Number(number_of_years) || 0))
      ?.reduce(
        (totalPrice, yearPrice) =>
          totalPrice + yearPrice.electricity_network_price,
        0
      ) || 0) / (Number(number_of_years) || 0);

  return result;
};
