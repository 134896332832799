import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Interfaces imoport
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  ICommercialUserInformation,
  ISageProduct,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  PVProduct: IDataProduct | undefined;
  studyInformation: IStudyInformation;
  formatNumberWithSpacesAndDecimal: (
    number: number,
    decimalPlaces: number
  ) => string;
  kitHomeManagementSageProduct: ISageProduct | undefined;
  commercialUserInformation: ICommercialUserInformation;
  isDemoVersion: boolean;
}

export const PdfKitHomeManagementPage = ({
  documentId,
  PVProduct,
  studyInformation,
  kitHomeManagementSageProduct,
  commercialUserInformation,
  isDemoVersion,
}: IProps) => {
  const totalKitHomeManagementWithoutTax =
    (PVProduct?.product_characteristics?.kit_home_management
      ?.price_without_tax || 0) +
    (PVProduct?.product_characteristics?.kit_home_management
      ?.installation_price_without_tax || 0);
  const totalKitHomeManagementWithTax =
    (PVProduct?.product_characteristics?.kit_home_management?.price_with_tax ||
      0) +
    (PVProduct?.product_characteristics?.kit_home_management
      ?.installation_price_with_tax || 0);
  const totalKitHomeManagementTVA =
    totalKitHomeManagementWithTax - totalKitHomeManagementWithoutTax;

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              PVProduct?.product_characteristics?.kit_home_management?.alias ||
              kitHomeManagementSageProduct?.AR_Design ||
              ""
            }
          />

          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={1}
              prixUnitaire={
                PVProduct?.product_characteristics?.kit_home_management
                  ?.price_without_tax || 0
              }
              TVA={
                PVProduct?.product_characteristics?.kit_home_management
                  ?.tva_percentage || 0
              }
              montantTTC={
                PVProduct?.product_characteristics?.kit_home_management
                  ?.price_with_tax || 0
              }
            />

            <PdfItemDetail
              key={kitHomeManagementSageProduct?.AR_Ref}
              title={`1 compteur ${kitHomeManagementSageProduct?.Marque} ${kitHomeManagementSageProduct?.Gamme}`}
              description={[
                <>
                  {kitHomeManagementSageProduct?.AR_Design && (
                    <PdfRowProduit
                      isMargeBottom={false}
                      name={kitHomeManagementSageProduct?.AR_Design}
                    />
                  )}
                </>,
                <>
                  {kitHomeManagementSageProduct?.["Garantie Fabricant"] && (
                    <PdfRowProduit
                      isMargeBottom={false}
                      name={
                        "Garantie fabriquant : " +
                        kitHomeManagementSageProduct?.["Garantie Fabricant"]
                      }
                    />
                  )}
                </>,
                <>
                  {kitHomeManagementSageProduct?.Dimensions && (
                    <PdfRowProduit
                      isMargeBottom={false}
                      name={
                        "Taille : " + kitHomeManagementSageProduct?.Dimensions
                      }
                    />
                  )}
                </>,
              ]}
            />

            <PdfItemDetail
              key={kitHomeManagementSageProduct?.AR_Ref}
              title={`2 prises CPL ${kitHomeManagementSageProduct?.Marque} ${kitHomeManagementSageProduct?.Gamme}`}
              description={[<></>]}
            />
            <PdfItemDetail
              key={kitHomeManagementSageProduct?.AR_Ref}
              title={`${
                studyInformation.electric_installation_type === "Monophasé"
                  ? "1"
                  : "3"
              } pince(s) ampèremétrique(s) ${
                kitHomeManagementSageProduct?.Marque
              } ${kitHomeManagementSageProduct?.Gamme}`}
              description={[<></>]}
            />

            <PdfRowBackgroundProduit
              name="Forfait installation"
              quantity={1}
              prixUnitaire={
                PVProduct?.product_characteristics?.kit_home_management
                  ?.installation_price_without_tax || 0
              }
              TVA={
                PVProduct?.product_characteristics?.kit_home_management
                  ?.installation_tva_percentage || 0
              }
              montantTTC={
                PVProduct?.product_characteristics?.kit_home_management
                  ?.installation_price_with_tax || 0
              }
            />
            <PdfTotalContainer
              totalHT={totalKitHomeManagementWithoutTax}
              totalTVA={totalKitHomeManagementTVA}
              totalTTC={totalKitHomeManagementWithTax}
              resteACharge={totalKitHomeManagementWithTax}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
