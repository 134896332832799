import React from "react";
import {
  Area,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import "./ExtraElectricitySalesGraph.scss";

interface IProps {
  extraElectricityGraphData: {
    annee: string;
    reventeSurplus: number;
    reventeEnergy: number;
    prixReventeEDF: number;
  }[];
}

export const ExtraElectricitySalesGraph = ({
  extraElectricityGraphData,
}: IProps) => {
  // we find the year with the highest Revente surplus value
  const maximumReventeSurplusValue = extraElectricityGraphData.reduce(
    (prev, current) => {
      return prev.reventeSurplus > current.reventeSurplus ? prev : current;
    }
  );

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <ul className="legendContainer">
        <li
          key={`item-1`}
          className={`legendItem`}
          style={{ color: payload[0].color }}
        >
          <div
            className="colorPastille"
            style={{ backgroundColor: payload[0].color }}
          />
          <p>REVENTE DU SURPLUS</p>
        </li>
        <li
          key={`item-2`}
          className={`legendItem`}
          style={{ color: payload[1].color }}
        >
          <div
            className="colorPastille"
            style={{ backgroundColor: payload[1].color }}
          />
          <p>PRIX DE REVENTE OA SOLAIRE</p>
        </li>
      </ul>
    );
  };

  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className="customTooltip">
          <div className="labelPriceContainer">
            <div
              className="label colorPastille"
              style={{ backgroundColor: payload[0].color }}
            />
            <p className="price">{`${payload[0].payload.reventeSurplus} €`}</p>
          </div>
          <div className="labelPriceContainer">
            <div
              className="label colorPastille"
              style={{ backgroundColor: payload[1].color }}
            />
            <p className="price">{`${payload[0].payload.prixReventeEDF} €`}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="ExtraElectricitySalesGraph">
      <section className="ExtraElectricitySalesGraph__GraphContainer">
        <ResponsiveContainer>
          <ComposedChart
            width={100}
            height={300}
            data={extraElectricityGraphData}
          >
            <defs>
              <linearGradient
                id="gradientOrangeColor"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="15%"
                  stopColor="rgba(240, 161, 78, 0.51)"
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor="rgba(240, 161, 78, 0.1)"
                  stopOpacity={0.1}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="annee"
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#191B1E", fontSize: "0.6875rem" }}
            />
            <YAxis
              dataKey="prixReventeEDF"
              yAxisId="prixReventeEDF"
              orientation="right"
              domain={[0, extraElectricityGraphData[0].prixReventeEDF * 3]}
              scale="linear"
              unit="€"
            />
            <YAxis
              dataKey="reventeSurplus"
              yAxisId="reventeSurplus"
              orientation="left"
              domain={[0, maximumReventeSurplusValue.reventeSurplus * 1.3]}
              scale="linear"
              unit="€"
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={renderLegend}
              verticalAlign="top"
              align="center"
              height={50}
            />

            <Area
              dataKey="reventeSurplus"
              yAxisId="reventeSurplus"
              fill="url(#gradientOrangeColor)"
              stroke="#ED8C00"
              strokeWidth={"4px"}
            />

            <Line
              type="monotone"
              dataKey="prixReventeEDF"
              stroke="#0073E0"
              strokeWidth={"2px"}
              yAxisId="prixReventeEDF"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </section>
      <section className="ExtraElectricitySalesGraph__GraphDescription">
        <p className="Title">Revente du surplus</p>
        <p className="Description">
          Le prix de revente de vos kWh qui est fixé au moment de votre
          contractualisation et reste inchangé pendant 20 ans malgré les
          fluctuations que peuvent subir les prix de l&apos;énergie. C&apos;est
          la Commission de Régulation de l&apos;Énergie (CRE) qui fixe ce prix
          de rachat.
        </p>
      </section>
    </div>
  );
};
