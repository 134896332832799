import { View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  paragraphMentions: {
    fontSize: 8,
    padding: "0 10",
    color: "#525360",
    fontFamily: "DMSansRegular",
    lineHeight: "1.7px",
    marginBottom: 0,
  },
});

interface IProps {
  paragraph: JSX.Element[];
}

export const PdfParagraphMentions = ({ paragraph }: IProps) => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    <View style={styles.paragraphMentions}>
      {paragraph.map((item, _key) => {
        return <View key={s4()}>{item}</View>;
      })}
    </View>
  );
};
