// Librairies import
import { Stage } from "konva/lib/Stage";
import { useState } from "react";
import { Line } from "react-konva";

// Local intercace declaration
interface PanelElementProps {
  coordinates: [number, number, number, number, number, number, number, number];
  stageRef: React.RefObject<Stage>;
  index: number;
  isSelected: boolean;
  updatePanelsIndexes?: ({ panel_index }: { panel_index: number }) => void;
  isSolarPotential?: boolean;
}

export const PanelElement = ({
  coordinates,
  stageRef,
  index,
  isSelected = false,
  updatePanelsIndexes,
  isSolarPotential = false,
}: PanelElementProps) => {
  const [hoverColor, setHoverColor] = useState("");
  const isUpdatable = !!updatePanelsIndexes;
  const baseColor = isSolarPotential ? "rgba(0, 0, 0, 0.2)" : "#000000";

  const panelColor =
    hoverColor && isSelected
      ? "rgba(66, 112, 45, 0.6)"
      : hoverColor
      ? hoverColor
      : isSelected
      ? baseColor
      : "";

  return (
    <Line
      points={coordinates}
      closed
      stroke="#3b3c3e"
      strokeWidth={1}
      fill={panelColor}
      onMouseEnter={() => {
        if (!isUpdatable) return;
        setHoverColor("rgba(103, 208, 55, 0.4)");
        if (stageRef.current) {
          stageRef.current.container().style.cursor = "pointer";
        }
      }}
      onMouseLeave={() => {
        if (!isUpdatable) return;
        setHoverColor("");
        if (stageRef.current) {
          stageRef.current.container().style.cursor = "default";
        }
      }}
      onClick={() => isUpdatable && updatePanelsIndexes({ panel_index: index })}
    />
  );
};
