import { View, Text, StyleSheet, Font } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../../../assets/pdf/fonts/DMSansBold.ttf";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  containerInfos__containerPerson: {
    marginBottom: 10,
  },
  containerInfos__containerPerson__title: {
    fontSize: 9,
    fontFamily: "DMSansBold",
    marginBottom: 2.5,
  },
  containerInfos__containerPerson__containerContent: {},
  containerInfos__containerPerson__containerContent__text: {
    fontSize: 9,
  },
});

interface Props {
  title: string;
  data: JSX.Element[];
}

export const PdfPerson = ({ title, data }: Props) => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    <View style={styles.containerInfos__containerPerson}>
      <Text style={styles.containerInfos__containerPerson__title}>{title}</Text>
      <View style={styles.containerInfos__containerPerson__containerContent}>
        {data.map((oneData, _key) => {
          return (
            <Text
              key={s4()}
              style={
                styles.containerInfos__containerPerson__containerContent__text
              }
            >
              {oneData}
            </Text>
          );
        })}
      </View>
    </View>
  );
};
