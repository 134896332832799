import { useState } from "react";

// Interfaces import
import {
  IGoTo,
  IStudyInformation,
  IUserAuthInfo,
} from "../../interfaces/generalInterfaces";

//Local interface
interface Props {
  goNext: (data?: INavigateData<any>) => void;
  goBack?: (data?: INavigateData<any>) => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
  setIsGoToFunctionActive: React.Dispatch<React.SetStateAction<boolean>>;
}

interface navigatioData {
  Title: string;
  Component: ({
    goNext,
    goBack,
    setIsGoToFunctionActive,
  }: Props) => JSX.Element;
}

export interface INavigateData<T> {
  [key: string]: T;
}

export const useNavigationHook = (allComponentData: navigatioData[][]) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentSubStepIndex, setCurrentSubStepIndex] = useState(0);
  const [navigationData, setNavigattionData] =
    useState<INavigateData<any> | null>(null); // Data that can be pass from one screen to another when navigating with the next() function
  const [_isGoToFunctionActive, setIsGoToFunctionActive] = useState(true);

  //Function to go to the nex step. It manages sub steps (navigation inside component)
  const goNext = (data?: INavigateData<any>) => {
    setNavigattionData(data || null);
    setCurrentStepIndex((i) => {
      if (i >= allComponentData.length - 1) return i;
      if (
        allComponentData[i].length > 1 &&
        currentSubStepIndex < allComponentData[i].length - 1
      )
        return i;
      return i + 1;
    });

    setCurrentSubStepIndex((i) => {
      if (
        allComponentData[currentStepIndex].length > 1 &&
        i < allComponentData[currentStepIndex].length - 1
      )
        return i + 1;
      if (currentSubStepIndex >= allComponentData[currentStepIndex].length - 1)
        return 0;
      return i;
    });
  };

  //Function to go to the previous step. It manages sub steps (navigation inside component)
  const goBack = (data?: INavigateData<any>) => {
    setNavigattionData(data || null);
    setCurrentStepIndex((i) => {
      if (i <= 0) return i;
      if (
        allComponentData[currentStepIndex].length > 0 &&
        currentSubStepIndex !== 0
      )
        return i;
      return i - 1;
    });

    setCurrentSubStepIndex((i) => {
      if (
        currentStepIndex <= allComponentData[currentStepIndex].length - 1 &&
        i > 0
      )
        return i - 1;
      if (currentStepIndex === 0) return 0;
      return allComponentData[currentStepIndex - 1].length - 1;
    });
  };

  //Function to jump to a specific step
  const goTo = ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: {
    newCurrentStepIndex: number;
    newCurrentSubStepIndex?: number;
    isGoforwardAllowed?: boolean;
  }) => {
    setCurrentSubStepIndex(() => 0);

    if (isGoforwardAllowed) {
      setCurrentStepIndex(() => newCurrentStepIndex);
      if (newCurrentSubStepIndex != null)
        setCurrentSubStepIndex(newCurrentSubStepIndex);
      return;
    }

    if (newCurrentStepIndex <= currentStepIndex)
      setCurrentStepIndex(() => newCurrentStepIndex);
    return;
  };

  // Function to jump to the page saved in the study information. We check if we have the right params to get the data before going to the page. Otherwise we go to the frilst page.
  const goToLastPageVisited = ({
    studyInformation,
    userAuthInfo,
  }: {
    studyInformation?: IStudyInformation;
    userAuthInfo: IUserAuthInfo;
  }) => {
    switch (studyInformation?.last_page_visited) {
      //Becqe && RG case
      case "my_informations":
        if (
          userAuthInfo.userToken &&
          studyInformation?.commercial_id &&
          studyInformation?.study_id &&
          studyInformation?.latitude &&
          studyInformation?.longitude &&
          studyInformation?.household_id
        )
          setCurrentStepIndex(1);
        break;

      //Becqe cases
      case "my_consumptions":
        if (
          userAuthInfo.userToken &&
          studyInformation?.building_id &&
          studyInformation?.household_id &&
          studyInformation?.commercial_id
        )
          setCurrentStepIndex(2);
        break;

      case "my_solar_potential":
        if (
          userAuthInfo.userToken &&
          studyInformation?.building_id &&
          studyInformation?.household_id &&
          studyInformation?.commercial_id
        )
          setCurrentStepIndex(3);
        break;

      case "my_scenario":
        if (userAuthInfo.userToken && studyInformation?.study_id)
          setCurrentStepIndex(4);
        break;

      //RG cases
      case "my_housings_form":
        if (
          userAuthInfo.userToken &&
          studyInformation?.building_id &&
          studyInformation?.household_id &&
          studyInformation?.commercial_id
        )
          setCurrentStepIndex(2);
        break;

      case "my_consumptions_equipments":
        if (userAuthInfo.userToken && studyInformation.study_id)
          setCurrentStepIndex(3);
        break;

      case "my_energetic_situation":
        if (userAuthInfo.userToken && studyInformation.study_id)
          setCurrentStepIndex(4);
        break;
      case "my_project":
        if (userAuthInfo.userToken && studyInformation.study_id)
          setCurrentStepIndex(4); //Waiting to know what we want to do when user quite at recapitulatif
        break;

      default:
        setCurrentStepIndex(0);
        break;
    }
  };

  const allTitles = allComponentData.map(
    (ComponentData) => ComponentData[0].Title
  );

  return {
    currentStepIndex,
    currentSubStepIndex,
    goNext,
    goBack,
    goTo,
    allTitles,
    setIsGoToFunctionActive,
    goToLastPageVisited,
    navigationData,
  };
};
