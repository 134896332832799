//Libraries import
import { useState } from "react";
import { IDataProduct } from "../../../interfaces/renoglobaleInterface";
import { useAxiosGet } from "../../useAxiosGet";

//Local interface
interface LocalProps {
  userToken: string | null;
  study_id?: string | null;
}

export const useGetProductsRecommendations = () => {
  const { isLoading, axiosGetRequest } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getProductsRecommendations = async ({
    userToken,
    study_id,
  }: LocalProps) => {
    setErrorMessage("");

    const response = await axiosGetRequest<{
      recommended_product: IDataProduct[];
    }>(
      `${process.env.REACT_APP_EERO_URL}/oree2/rg/getProductsRecommendations/${study_id}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response && response.recommended_product) {
      return response.recommended_product;
    }
    setErrorMessage("Impossible de récupérer les produits recommandés");
    return null;
  };

  return {
    getProductsRecommendations,
    isLoading,
    errorMessage: errorMessage,
  };
};
