//Styles import
import "./EnergyLabel.scss";

//Interfaces import
import { IEnergyLabel } from "../../interfaces/renoglobaleInterface";

// images import
import loupe from "@web/shared/dist/assets/images/DesignSystemIcons/loupe.svg";

//Services import
import { energyLabelImageNameFormatter } from "../../services/RenovationGlobale/MaSituationEnergetique/energyLabelImageNameFormatter";

//Component import
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";

//Local interface
interface IProps {
  energyLabelData?: IEnergyLabel | null;
  errorMessage: string;
  isLoading: boolean;
}

export const EnergyLabel = ({
  energyLabelData,
  errorMessage,
  isLoading,
}: IProps) => {
  const imagePath =
    energyLabelData?.energy_label && energyLabelData?.ges_label
      ? energyLabelImageNameFormatter({
          energy_label: energyLabelData.energy_label,
          ges_label: energyLabelData.ges_label,
        }).labelDPEImageName
      : null;
  const labelImage = imagePath
    ? require(`../../assets/images/energyLabel/${imagePath}.svg`)
    : null;

  const wortsLabel = [
    energyLabelData?.energy_label,
    energyLabelData?.ges_label,
  ].sort()[1];

  return (
    <div className="EnergyLabel">
      <div className="EnergyLabel__container">
        <section className="EnergyLabel__title">
          <h3>Etiquette énergétique estimée</h3>
        </section>
        {isLoading ? (
          <div className="EnergyLabel__loader">
            <LoadingComponent diameter={60} />
          </div>
        ) : errorMessage ? (
          <ErrorMessage errorMessage={errorMessage} />
        ) : energyLabelData && labelImage ? (
          <>
            <section className="EnergyLabel__labels_container">
              <div className="label">
                <div className="label_image">
                  <img
                    src={labelImage}
                    alt={`label énergie ${energyLabelData.energy_label}`}
                  />
                </div>
              </div>

              <div className="bars">
                <div className="bar">
                  <p>Performance énergétique</p>
                  <img
                    src={require(`../../assets/images/energyLabel/${
                      energyLabelImageNameFormatter({
                        energy_label: energyLabelData.energy_label,
                        ges_label: energyLabelData.ges_label,
                      }).barDPEImageName
                    }.svg`)}
                    alt={`Barre énergie ${energyLabelData.energy_label}`}
                  />
                  <div className="EnergyLabel__Details">
                    <p>
                      {Math.round(energyLabelData.primary_energy_consumption)}{" "}
                      kWh/m<sup>2</sup>/an
                    </p>
                  </div>
                </div>
                <div className="bar">
                  <p>Gaz à effets de serre</p>
                  <img
                    src={require(`../../assets/images/energyLabel/${
                      energyLabelImageNameFormatter({
                        energy_label: energyLabelData.energy_label,
                        ges_label: energyLabelData.ges_label,
                      }).GESImageName
                    }.svg`)}
                    alt={`Barre gaz à effets de serre ${energyLabelData.ges_label}`}
                  />
                  <div className="EnergyLabel__Details">
                    <p>
                      {Math.round(energyLabelData.greenhouse_gaz_emissions)}{" "}
                      kgCO
                      <sup>2</sup>/m<sup>2</sup>/an
                    </p>
                  </div>
                </div>
              </div>

              {(wortsLabel === "G" || wortsLabel === "F") && (
                <section
                  className={`EnergyLabel__good_to_know ${
                    wortsLabel === "F" ? "labelF" : "labelG"
                  } EnergyLabel__good_to_know__desktop`}
                >
                  <div className="image_container">
                    <img src={loupe} alt="" />
                  </div>
                  <article className="text_content">
                    <p className="title">Bon à savoir</p>
                    <p className="content">
                      Considérés comme des passoires thermiques, à partir de
                      2028 tous les logements avec une étiquette énergétique F
                      seront interdits à la location.
                    </p>
                  </article>
                </section>
              )}
            </section>
            <section className="EnergyLabel__juridique">
              <p>
                Le calcul de votre étiquette énergétique est basé sur la méthode
                3CL (Calcul de Consommations Conventionnelles des Logements). La
                méthode 3CL s'appuie sur une utilisation standardisée du
                bâtiment pour des conditions climatiques moyennes du lieu et
                s'affranchit des comportements de consommation des occupants.
              </p>
              <p>
                Ce bilan n’a pas de valeur juridique et ne peut remplacer un DPE
                réalisé par un professionnel agréé. La lettre globale retenue
                pour votre étiquette énergie est la plus mauvaise des deux.
              </p>
            </section>
          </>
        ) : (
          <p className="EnergyLabel__NoDataAvailable">
            Pas de données disponibles
          </p>
        )}
      </div>
    </div>
  );
};
