export const generateOree2ProjectID = ({ type }: { type: string }) => {
  // Get current date
  var currentDate = new Date();

  // Extract year, month, and day components
  var year = currentDate.getFullYear().toString().slice(-2);
  var month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  var day = currentDate.getDate().toString().padStart(2, "0");

  // Concatenate the components
  var formattedDate = year + month + day;

  var random = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var numbers = "0123456789";

  for (var i = 0; i < 4; i++) {
    // Generate a random letter
    var randomLetter = characters.charAt(
      Math.floor(Math.random() * characters.length)
    );

    // Generate a random number
    var randomNumber = numbers.charAt(
      Math.floor(Math.random() * numbers.length)
    );

    // Append the letter and number to the result
    random += randomLetter + randomNumber;
  }

  let result =
    type.toUpperCase() + "-" + formattedDate + "-" + random.toUpperCase();

  return result;
};
