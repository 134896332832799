// styles import
import "./mesInformationsPopup.scss";

// component imports
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

// Interfaces import
import { INavigateData } from "../../services/useNavigation/useNavigation";

// service imports
import { useAppSelector } from "../../redux/store/hook";
import { useSaveLastPageVisited } from "../../services/Oree2/useSaveLastPageVisited";

// Image import
import PictoReference from "@web/shared/src/assets/images/DesignSystemIllustrations/référence.svg";

type MesInformationsPopupProps = {
  goNext: (data?: INavigateData<any>) => void;
};

export const MesInformationsPopup = ({ goNext }: MesInformationsPopupProps) => {
  const { studyInformation, userAuthInfo } = useAppSelector((state) => state);
  const { saveLastPageVisited } = useSaveLastPageVisited();

  const onClick = () => {
    saveLastPageVisited({
      last_page_visited: "my_informations",
      study_id: studyInformation?.study_id || null,
      userToken: userAuthInfo.userToken,
    });
    goNext();
  };

  return (
    <div className="MesInformationsPopup">
      <div className="MesInformationsPopup__Modal">
        <div className="MesInformationsPopup__ModalTitle">
          <p>Renseignement de vos données de consommation</p>
          <img src={PictoReference} alt="maison"></img>
        </div>
        <p className="MesInformationsPopup__ThanksMessage">
          Lors de la prochaine étape vous serez invité à renseigner vos données
          de consommation pour que <strong>{"Orée"}</strong> puisse vous
          proposer l'installation la plus
          <strong> adaptée à vos besoins</strong>.
        </p>
        <div className="MesInformationsPopup__ButtonsContainer">
          <CTAButton name="Suivant" onClick={onClick} />
        </div>
      </div>
    </div>
  );
};
