//Libraries import
import { useState } from "react";
import { setStudyInfoAction } from "../../../redux/appActions";
import { useAppDispatch } from "../../../redux/store/hook";
import { useAxiosPut } from "../../useAxiosPut";

// Interfaces import
import { IFinancingInformation } from "../../../interfaces/generalInterfaces";

//Local interface
interface LocalProps extends IFinancingInformation {
  userToken: string | null;
  study_id: string | null;
}

export const useSaveFinancingInformation = () => {
  const dispatch = useAppDispatch();
  const { isLoading, axiosPutRequest } = useAxiosPut();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const saveFinancingInformation = async ({
    userToken,
    study_id,
    type_of_payment,
    loan_duration,
    deposit,
    interest_rate,
    is_deferral_option,
    hasBorrowerInsurance,
    hasCoBorrower,
    helps_options,
  }: LocalProps) => {
    setErrorMessage("");

    // Even if the call to the eero database fails, the information will be saved in the redux store.
    dispatch(
      setStudyInfoAction({
        studyInformation: {
          type_of_payment,
          loan_duration,
          deposit,
          interest_rate,
          is_deferral_option,
          has_borrower_insurance: hasBorrowerInsurance,
          has_co_borrower: hasCoBorrower,
          helps_options,
        },
      })
    );

    // Save information in eero database
    const response = await axiosPutRequest(
      `${process.env.REACT_APP_EERO_URL}/oree2/financingScenario/${study_id}`,
      {
        type_of_payment,
        loan_duration,
        deposit,
        interest_rate,
        is_deferral_option,
        hasBorrowerInsurance,
        hasCoBorrower,
        helps_options,
      },
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response && response.status !== 200) {
      setErrorMessage(
        "La sauvegarde des informations de financement a échoué."
      );
    }

    return response;
  };

  return {
    saveFinancingInformation,
    isLoading,
    errorMessage: errorMessage,
  };
};
